export const SideBarMenu = {
  fr: {
    surveys: "Mes questionnaires",
    templates: "Mes templates",
    Dashboard: "Tableau de bord",
    teleconsultations: "Mes  Télé-consultations",
    agenda: "Mon agenda",
    Patients: "Mes patients",
    Spcialists: "Mon Annuaire",
    Profile: "Mon compte",
    Extensions: "Mes extensions",
    sentMessage: "vous a envoyé un message",
    AnsweredYourSurvey: "répondu à votre questionnaire",
    disconnect: "Se déconnecter",
    ConfirmLogout: "Souhaitez-vous vous déconnecter",
    Confirm: "Confirmer",
    Cancel: "Annuler",
    DashboardPatient: "Accueil",
    Annuaire: "Annuaire",
    MyDoctors: "Mes médecins",
    MyFolder: "Mon dossier",
    Mysurveys: "Mes Questionnaires",
    hello: "Bonjour",
    Find: "Rechercher",
    ResultFor: "Résultats pour",
    Documents: "Documents",
    Etude: "Études",
    Equipe: "Mon équipe",
    ResultsReportOf: "Résultats compte rendu du :",

    UpdatedOn: "Mise à jour le",

    NoResults: "Aucun résultat n'existe.",
    upcomingAppointment: "rendez-vous à venir",
    Pattern: "Motif :",
    NoUpcomingAppointments: "N'existe aucun rendez-vous à venir.",
    NopastAppointments: "N'existe aucun rendez-vous passés.",
    pastAppointments: "rendez-vous passés",
    NoDoctorMatches: "Aucun médecin ne correspond à",
    FindADoctor: "Rechercher un médecin",
    Report: "Rendez-vous",
    Doctor: "Médecins",
  },
  en: {
    surveys: "My Surveys",
    templates: "My templates",
    Dashboard: "Dashboard",
    teleconsultations: "My  Tele-consultations",
    agenda: "My agenda",
    Patients: "My Patients",
    Spcialists: "Spcialists",
    Profile: "Profile",
    Extensions: "My Extensions",
    sentMessage: " sent you a message",
    AnsweredYourSurvey: "answered your survey",
    disconnect: "disconnect",
    ConfirmLogout: "Do you want to log out",
    Confirm: "Confirm",
    Cancel: "Cancel",
    DashboardPatient: "Dashboard",
    Annuaire: "Annuaire",
    MyDoctors: "My doctors",
    MyFolder: "My files",
    Mysurveys: "My surveys",
    Bonjour: "Hello",
    Find: "Find",
    ResultFor: "Results for",
    Documents: "Documents",
    ResultsReportOf: "Results report of:",
    UpdatedOn: "Updated on",
    Etude: "Studies",
    Equipe: "Team",
    Exports: "Exports",
    NoResults: "No results exist.",
    upcomingAppointment: "upcoming appointment",
    Pattern: "Pattern :",
    NoUpcomingAppointments: "There are no upcoming appointments.",
    NopastAppointments: "No past appointments exist.",
    pastAppointments: "past appointments",
    NoDoctorMatches: "No doctor matches",
    FindADoctor: "Find a doctor",
    Report: "Report",
    Doctor: "Doctor",
  },
};
