import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAllrProFormsPagination } from "../../api/study";
import Pagination from "../../components/PaginationTable";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
//import Table from "./eproTable";
import Progress from "../../components/NProgress";
import { studyParticipantConfigAtom } from "../../state/studyParticipantConfig";
import Table from "./eproTable";
import "./styles.scss";
import { testModeAtom } from "../../state/testModeAtom";

const MyEPRO = () => {
  const [user] = useAtom(userAtom) as any;
  const [testMode, setTestMode] = useAtom(testModeAtom);

  const [studyParticipantConfig, setStudyParticipantConfig] = useAtom(
    studyParticipantConfigAtom
  );

  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [loading, setLoading] = useState(false);
  // const [hasCenterAcess, setHasCenterAcess] = useState(false);

  const [listEproFroms, setListEproForms] = useState([]);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const params = useParams() as any;
  const { studyId } = params;
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [textSearch, setTextSearch] = useState("");
  const { t } = useTranslation() as any;

  const [filter] = useState(
    studyParticipantConfig?.isResponsable ? "byCenter" : "byMe"
  );

  async function fetchStudyParticipants(): Promise<any> {
    try {
      setLoading(true);
      if (studyId && studyId !== null) setStudySelectedId(studyId);

      let res = await getAllrProFormsPagination(
        studyId,
        page,
        limit,
        testMode,
        textSearch
      );

      if (res) {
        console.log("🚀 ~ fetchStudyParticipants ~ res:", res);
        console.log(
          "🚀 ~ file: index.tsx:57 ~ fetchStudyParticipants ~ res:",
          res
        );
        setListEproForms(res?.eProFormList);
        setStudyParticipantConfig(res?.participantStudy);
        setTotalResult(res?.formDataListCount);
        setLoading(false);
      } else {
        setStudyParticipantConfig(null);
        setLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:30 ~ fetchStudyConfig ~ error", error);
    }
  }

  useEffect(() => {
    setPath(`/eproForms/${studyId}`);
    fetchStudyParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, textSearch, testMode]);

  return (
    <>
      <Progress isAnimating={loading} key={0} />
      <header className="flex justify-between items-center w-full flex-wrap">
        <div className="title-container">
          <h1 className="pageTitle">ePRO Forms </h1>
        </div>
      </header>

      {studyParticipantConfig?.studyId.status === "END" && (
        <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2  ">
          <span className="warn warning ">
            ⚠️{t("missingWording.bannerEnd")}.
          </span>
        </div>
      )}
      <div>
        <Table
          data={listEproFroms}
          setListEproForms={setListEproForms}
          userType={user?.type}
          //hasCenterAcess={studyWriterConfig?.studyId.centerAcess ? true : false}
          loading={false}
          setTextSearch={setTextSearch}
          //showName={studyWriterConfig?.studyId.anonym ? false : true}
          //isResponsable={studyWriterConfig?.isResponsable}
        />
        {totalResult > limit && (
          <Pagination
            totalResults={totalResult}
            resultsPerPage={limit}
            currentPage={page}
            onPageChange={function (page: number): void {
              setPage(page);
              localStorage.setItem("paginate", page.toString());
            }}
            entriesName={"Formulaires"}
          />
        )}
      </div>
    </>
  );
};

export default MyEPRO;
