import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAtom } from "jotai";
import { DeleteIcon } from "@chakra-ui/icons";
import {InputGroup,Box,IconButton,VStack,Grid,GridItem} from "@chakra-ui/react";

import Spinner from "../../../Spinner";
import ButtonUI from "../../../ButtonUI";
import { FormGroup } from "../../Elements/FormGroup";
import { FieldSelect } from "../../Elements/FieldSelect";
import { FieldInput } from "../../Elements/FieldInput";
import { SwitchInput } from "../../Elements/FieldSwitcher";

import { elementAtom } from "../../../../state/FormGeneratorState/elmentAtom";
import { filterInputs } from "../../../../helper/FormGeneratorHelper/conditionalLogic";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import { conditionsListExport } from "../PropertyPageEditor/conditionList";
import { useTranslation } from "react-i18next";

const ConditionLogic = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const [atomElment, ] = useAtom(elementAtom);
  const{t}=useTranslation()
  const intl = useIntl();
  const arr = filterInputs(props.elements, atomElment);
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const options = arr.map((elem, i) => {
    if (
      props.value.operator === "all" &&
      props.value.conditions.find((e) => e.filedTocompare === elem.field_name)
    ) {
      return {
        label: `${elem.label + "-" + i}`,
        value: elem.field_name,
        disabled: true,
      };
    } else {
      return { label: `${elem.label + "-" + i}`, value: elem.field_name };
    }
  });
  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };
  const verifOption = () => {
    let i = 0;
    options.map((opt) => {
      if (opt.disabled) {
        i++;
      }
    });
    if (
      i == options.length ||
      (props.value.operator === "all" &&
        props.form.values["conditionLogic"].conditions.length >= options.length)
    ) {
      return true;
    } else return false;
  };

  const collection = useRepeater({
    name: "conditionLogic.conditions",
    form: props.form,
    initialValues: props.value.conditions,
  });
  const conditionsList = conditionsListExport(intl)

  useEffect(() => {
    setValue(props.value);
  }, []);
  useEffect(() => {
    setValue((val) => {
      return { ...val, conditions: value.conditions };
    });
  }, [collection.length]);
  return (
    <>
      {value && value.conditions ? (
        <>
          <FormGroup {...formGroupProps}>
            <InputGroup name="conditionLogic">
              <VStack
                spacing={4}
                align="start"
                className="border p-4 rounded-lg w-full ConditionLogic_1 "
              >
                <Grid
                  width={"60%"}
                  templateColumns="repeat(2, 1fr)"
                  alignItems={"baseline"}
                  gap={1}
                >
                  <GridItem w="170px" h="10">
                    {" "}
                    <span style={{ fontSize: "16px" }} className="font-bold ">
                   {t('missingWording.condLogic')}
                    </span>
                  </GridItem>
                  <GridItem
                    w="100%"
                    h="10"
                    className="flex  text-xs text-gray-500  "
                  >
                    <GridItem>
                      <span className="p-2 card-subtitle">{t('missingWording.desactive')}</span>
                    </GridItem>
                    <GridItem>
                      {" "}
                      <SwitchInput
                        value={props.value.status}
                        name="conditionLogic.status"
                      />
                    </GridItem>
                    <GridItem>
                      <span className="p-2 card-subtitle">{t('missingWording.activer')}</span>
                    </GridItem>
                  </GridItem>
                </Grid>
                {props.form.values.conditionLogic &&
                  props.form.values.conditionLogic.status && (
                    <div className="ConditionLogic_2 mb-1">
                      <Grid
                        templateColumns="repeat(5, 0.6fr)"
                        alignItems={"baseline"}
                        gap={0}
                      >
                        {" "}
                        <GridItem w={16} h="10">
                          <span>Action </span>
                        </GridItem>
                        <GridItem w="100%" h="10">
                          {" "}
                          <FieldSelect
                            w={32}
                            name="conditionLogic.action"
                            value={
                              Array.isArray(props.value.action)
                                ? "S"
                                : props.value.action
                            }
                            // placeholder="action"
                            style={{ borderRadius: "10px", height: "auto" }}
                            dropdownStyle={{
                              borderRadius: "10px",
                              padding: "4%",
                            }}
                            className="border rounded-lg h-full  "
                            options={[
                              {
                                label: intl.formatMessage({ id: "show" }),
                                value: "S",
                              },
                              {
                                label: intl.formatMessage({ id: "hide" }),
                                value: "H",
                              },
                              {
                                label: intl.formatMessage({ id: "enable" }),
                                value: "E",
                              },
                              {
                                label: intl.formatMessage({ id: "Disable" }),
                                value: "D",
                              },
                            ]}
                          />
                        </GridItem>
                        <GridItem mx={1} h="10">
                          {" "}
                          <span className="mb-4 flexCenter ">
                            {intl.formatMessage({ id: "when" })}{" "}
                          </span>
                        </GridItem>
                        <GridItem w={32} h="10">
                          {" "}
                          <FieldSelect
                            name="conditionLogic.operator"
                            value={
                              Array.isArray(props.value.operator)
                                ? "S"
                                : props.value.operator
                            }
                            style={{ borderRadius: "10px", height: "auto" }}
                            dropdownStyle={{
                              borderRadius: "10px",
                              padding: "4%",
                            }}
                            className="border rounded-lg h-full"
                            options={[
                              {
                                label: intl.formatMessage({ id: "all" }),
                                value: "all",
                              },
                              {
                                label: intl.formatMessage({ id: "one" }),
                                value: "one",
                              },
                            ]}
                          />
                        </GridItem>
                        <GridItem w={56} h="10">
                          {" "}
                          <p className="ml-3">
                            {" "}
                            {intl.formatMessage({ id: "conditionsAreValid" })}
                          </p>
                        </GridItem>
                      </Grid>
                      <Grid
                        templateColumns="repeat(3, 1fr)"
                        gap={1}
                        className="text-xs"
                      >
                        {collection.keys.map((key, index) => (
                          <>
                            <GridItem>
                              <Box flex="1">
                                <FieldSelect
                                  name={`conditionLogic.conditions[${index}].fieldToCompare`}
                                  required
                                  value={
                                    props.value.conditions[index]?.[
                                      "fieldToCompare"
                                    ]
                                  }
                                  label={t('missingWording.fieldToComp')}
                                  options={options}
                                  m="0"
                                />
                              </Box>
                            </GridItem>
                            <GridItem>
                              {" "}
                              <Box flex="1">
                                <FieldSelect
                                  name={`conditionLogic.conditions[${index}].ifCondition`}
                                  required
                                  value={
                                    props.value.conditions[index]?.[
                                      "ifCondition"
                                    ]
                                  }
                                  label="Condition"
                                  m="0"
                                  options={conditionsList}
                                />
                              </Box>
                            </GridItem>
                            <GridItem className="flex">
                              <Box flex="1">
                                <FieldInput
                                  name={`conditionLogic.conditions[${index}].value`}
                                  required
                                  value={
                                    props.value.conditions[index]?.["value"]
                                  }
                                  isPreview={true}
                                  placeholder="Valeur"
                                  w={32}
                                  label={t('missingWording.value')}
                                  m="0"
                                />
                              </Box>{" "}
                              <Box pt="1.8rem" mx={0.5}>
                                <IconButton
                                  size="sm"
                                  aria-label="Delete"
                                  icon={<DeleteIcon />}
                                  onClick={() => {
                                    collection.remove(index);

                                    setValue((current) => {
                                      return {
                                        ...current,
                                        conditions: current.conditions.splice(
                                          index,
                                          1
                                        ),
                                      };
                                    });
                                  }}
                                />
                              </Box>
                            </GridItem>
                          </>
                        ))}

                        <Box>
                          {collection.length <= 20 && (
                            <>
                              {!(options.length < 1 || verifOption()) && (
                                <ButtonUI
                                  status="secondary"
                                  className="p-2 text-xs w-48"
                                  type="button"
                                  onClick={() => collection.append()}
                                >
                                  {" "}
                                  {t('missingWording.addCondition')}
                                </ButtonUI>
                              )}
                            </>
                          )}
                        </Box>
                      </Grid>
                    </div>
                  )}
              </VStack>
            </InputGroup>
          </FormGroup>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ConditionLogic;
