import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBarPARTICIPANT from "../components/SideBarPARTICIPANT";

import "./styles-layout-doctor.scss";

const LayoutPARTICIPANT = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/firstLogin" ||
      /^\/eproForms\/\w+\/\w+$/.test(location.pathname)
    ) {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  }, [location.pathname]);

  return (
    <div className="layout-doctor flex">
      {showSideBar && <SideBarPARTICIPANT />}
      <div className="w-full md:pl-4">{children}</div>
    </div>
  );
};

export default LayoutPARTICIPANT;
