import { useEffect, useState } from "react";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { FormGroup } from "./FormGroup";

export const TableField = (props) => {
  const { errorMessage, id, setValue, value, isValid, otherProps,valueDebounced } =
    useField(props);

  const { required, name } = props;
  const { children, label, options, helper, validMessage, ...rest } =
    otherProps;
  const showError = required;
  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: required,
    label,
    showError,
    name,
    ...rest,
  };
  const[preValue,setPreValue] = useState(false)

  useEffect(() => {
    const objResult = new Object();
    props.lignes.map((l) => {
      objResult[`${l.label}`] = "";
    });

    setValue(objResult);
  }, [props.lignes || props.options || value == null,props.value]);


  return (
    <FormGroup {...formGroupProps} >
      <table onMouseLeave ={()=>{
          console.log("🚀 ~ file: FieldTable.tsx:40 ~ TableField ~ valueDebounced:", valueDebounced)
          console.log("🚀 ~ file: FieldTable.tsx:40 ~ TableField ~ value:", value)
         if (props?.onSumbit && preValue) {
        
          props?.onSumbit({
            fieldName: name,
            value: JSON.stringify(value),
            isValid: true,
          });
          setPreValue(false)
        }
      }}className="self-center shadow-md bg-white rounded-lg  ">
        <th
          className="bg-gray-400 border px-2 py-2    color-white w-96"
          key={"patient"}
        ></th>
        {props.options.map((item) => {
          return (
            <th
              className="bg-gray-400 border px-1 text-center py-2 color-white font-medium w-96 "
              key={item.label}
            >
              {item.label}
            </th>
          );
        })}

        {props.lignes.map((item, k) => {
          return (
            <tr className="border py-2  ">
              <td
                style={{ width: "130px" }}
                className="font-bold p-2 bg-gray-200  "
              >
                {item.label}
              </td>
              {props.options.map((opt, i) => {
                return (
                  <td className="  px-1 py-2 border text-center ">
                    <input
                      type={"radio"}
                      value={opt.value}
                      name={`${name}.${item.label}`}
                      onChange={async (e) => {
                        await setValue((current) => {
                          if (current && current != null) {
                            current[`${item.label}`] = e.target.value;
                           
                            return current;
                          } else {
                            const objResult = new Object();
                            props.lignes.map((l) => {
                              objResult[`${l.label}`] = "";
                            });
                           
                            return objResult;
                          }
                        });
                        setPreValue(true)
                       
                      }}
                      // onBlur={(e) => {
                      //   if (value && props?.onSumbit) {
                      //   }
                      // }}
                      disabled={props.disabled ? true : false}
                      required={required}
                    ></input>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>

      {children}
    </FormGroup>
  );
};
