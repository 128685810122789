import {
  Box,
  Button as ChButton,
  Heading,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fomDataStatusUpdate } from "../../../../api/study";
import moment from "../../../../helper/moment";
import { userAtom } from "../../../../state/auth";
import { studyWriterConfigAtom } from "../../../../state/studyWriterConfig";
import { visteAtom } from "../../../../state/visteAtom";
import Button from "../../../ButtonUI";
import DropdownComponent from "../../../DropDown";
import Modal from "../../../ModalUI";
import makeToast from "../../../Snackbar";
import { socketAtom } from "../../../../state/socket";
export const PageHeader = ({
  children,
  setShouldUpdate,
  isEvent = false,
  isPreview = false,
}) => {
  const [user] = useAtom(userAtom);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [showModalSingin, setShowModalSingin] = useState(false);
  const [showModalNoFrezze, setShowModalNoFrezze] = useState(false);
  const [showModalFrezze, setShowModalFrezze] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalInValidation, setOpenModalInValidation] = useState(false);
  const [formData, setFormData] = useAtom(visteAtom);

  const [studyWriterConfig] = useAtom(studyWriterConfigAtom) as any;
  const [socket, setSocket] = useAtom(socketAtom) as any;

  const { t } = useTranslation();
  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      if (formData) setLoader(false);
      else setLoader(false);
    }, 500);
  }, [formData]);
  const isValidSteps = (sections: any[]) => {
    let flag = sections?.every((s) => s.status === "VALID_BY_WRITER");
    return flag;
  };
  const validateFormData = async (visteId) => {
    try {
      // const res = await fomDataStatusUpdate({ status: "APPROVED" }, visteId);
      if (socket) {
        socket.emit("updateFormStatus", {
          roomId: visteId,
          status: "APPROVED",
        });

        // setFormData(res.newForm);
        if (setShouldUpdate) setShouldUpdate((up) => !up);
        setShowModalValidation(false);
        if (socket)
          socket.emit("validateFormData", {
            roomId: visteId,
            message: { status: "APPROVED" },
          });
        makeToast("success", t("missingWording.pht1"));
      } else {
        makeToast("error", t("missingWording.pht2"));
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: PageHeader.tsx:22 ~ validateFormData ~ error",
        error
      );
      makeToast("error", t("missingWording.pht2"));
    }
  };
  const invalidateFormData = async (visteId) => {
    try {
      // const res = await fomDataStatusUpdate(
      //   {
      //     status: isValidSteps(formData?.sections)
      //       ? "VALID_BY_WRITER"
      //       : "PROGRESS",
      //   },
      //   visteId
      // );
      if (socket) {
        socket.emit("updateFormStatus", {
          roomId: visteId,

          status: isValidSteps(formData?.sections)
            ? "VALID_BY_WRITER"
            : "PROGRESS",
        });

        // setFormData(res.newForm);
        if (setShouldUpdate) setShouldUpdate((up) => !up);
        if (socket)
          socket.emit("validateFormData", {
            roomId: visteId,
            message: {
              status: isValidSteps(formData?.sections)
                ? "VALID_BY_WRITER"
                : "PROGRESS",
            },
          });
        setOpenModalInValidation(false);
        makeToast("success", t("missingWording.pht3"));
      } else {
        makeToast("error", t("missingWording.pht2"));
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: PageHeader.tsx:22 ~ validateFormData ~ error",
        error
      );
      makeToast("error", t("missingWording.pht2"));
    }
  };
  const signFormDataByWriter = async (visteId) => {
    try {
      // const res = await fomDataStatusUpdate(
      //   {
      //     status: "SIGNED",
      //   },
      //   visteId
      // );
      if (socket) {
        socket.emit("updateFormStatus", {
          roomId: visteId,
          status: "SIGNED",
        });

        // setFormData(res.newForm);
        if (setShouldUpdate) setShouldUpdate((up) => !up);
        setShowModalSingin(false);
        makeToast("success", t("missingWording.sign1"));
      } else {
        makeToast("error", t("missingWording.pht2"));
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: PageHeader.tsx:22 ~ validateFormData ~ error",
        error
      );
      makeToast("error", t("missingWording.pht2"));
    }
  };
  const blockFormData = async (visteId,block) => {
    try {

 if(block){
  if (socket) {
    socket.emit("updateFormStatus", {
      roomId: visteId,
      status: "LOCKED",
    });

    // setFormData(res.newForm);
    if (setShouldUpdate) setShouldUpdate((up) => !up);
    setShowModalFrezze(false);
    makeToast("success", t("missingWording.blockMsg"));
  } else {
    makeToast("error", t("missingWording.pht2"));
  }
 }else {
  if (socket) {
    socket.emit("updateFormStatus", {
      roomId: visteId,
      status:isValidSteps(formData?.sections)
      ? "VALID_BY_WRITER"
      : "PROGRESS",
    });

    // setFormData(res.newForm);
    if (setShouldUpdate) setShouldUpdate((up) => !up);
    setShowModalNoFrezze(false);
    makeToast("success", t("missingWording.deblockMsg"));
  } else {
    makeToast("error", t("missingWording.pht2"));
  }

 }
    } catch (error) {
      console.log(
        "🚀 ~ file: PageHeader.tsx:22 ~ validateFormData ~ error",
        error
      );
      makeToast("error", t("missingWording.pht2"));
    }
  };
  return (
    <>
      <Box mb="6" className="relative" data-test="header">
        <Heading alignItems="center">
          {loader ? (
            <div className="flex items-center">
              <Spinner />
              <>{children}</>{" "}
            </div>
          ) : (
            <div className="flex items-center">
              <p
                className={`w-4 h-4  rounded-full mx-2 ${
                  formData?.status === "INIT"
                    ? "bg-gray-400"
                    : formData?.status === "PROGRESS"
                    ? " bg-yellow-500"
                    : "bg-green-500"
                }`}
              ></p>
              <> {children}</>
            </div>
          )}

          {!isPreview && formData?.status === "APPROVED" && !isEvent && (
            <div className="text-xs flex flex-row justify-between w-full rounded-lg absolute -bottom-10 p-2  bg-gray-50 ">
              <span className="text-green-400 cursor-none  ">
                {t("missingWording.phtSpan")}
                {moment(formData?.validationAt).format("DD-MM-YYYY") +
                  t("DashboardCro.at") +
                  moment(formData?.validationAt).format("HH:mm")}
              </span>
              {user?.type !== "writer" && (
                <p
                  className="text-green-800 cursor-pointer"
                  onClick={() => setOpenModalInValidation(true)}
                >
                  {t("missingWording.cancel")}
                </p>
              )}
            </div>
          )}
          {!isPreview && formData?.status === "SIGNED" && !isEvent && (
            <div className="text-xs flex flex-row justify-between w-full rounded-lg absolute -bottom-10 p-2  bg-gray-50 ">
              <span className="text-purple-600 cursor-none  ">
                {t("missingWording.phtSpanSgin")}
                {moment(formData?.signedAt).format("DD-MM-YYYY") +
                  t("DashboardCro.at") +
                  moment(formData?.signedAt).format("HH:mm")}
              </span>

              {user?.type === "writer" && studyWriterConfig?.isResponsable && (
                <p
                  className="text-purple-800 cursor-pointer"
                  // onClick={() => setOpenModalInValidation(true)}
                >
                  {t("missingWording.cancel")}
                </p>
              )}
            </div>
          )}
        </Heading>
     
        <ChButton disabled ml="auto" size="sm" className="text-black">
          {t("missingWording.MAJle")}
          {moment(formData?.updatedAt).format("DD-MM-YYYY") +
            t("DashboardCro.at") +
            moment(formData?.updatedAt).format("HH:mm")}
        </ChButton>

        {!isPreview &&
          user?.type !== "writer" &&
          user?.type !== "sponsor" &&
          !isEvent && (
            <div className="absolute right-0 top-0 ">
              <DropdownComponent
                setupIcon={true}
                options={[
                  {
                    name: t("missingWording.phtOp1"),
                    action: () => setShowModalValidation(true),
                  },
                  {
                    name: t("missingWording.phtOp2"),
                    action: () => setShowModalSingin(true),
                  },
                  {
                    name:formData?.status==='LOCKED'?t("missingWording.phtOp4") :t("missingWording.phtOp3"),
                    action: () => formData?.status==='LOCKED'?setShowModalNoFrezze(true) :setShowModalFrezze(true),
                  },
                ]}
              />
            </div>
          )}

        {!isPreview &&
          user?.type === "writer" &&
          !isEvent &&
          studyWriterConfig?.isResponsable && (
            <div className="absolute right-0 top-0 ">
              <DropdownComponent
                setupIcon={true}
                options={[
                  {
                    name: t("missingWording.phtOp2"),
                    action: () => setShowModalSingin(true),
                  },
                ]}
              />
            </div>
          )}
      </Box>

      <Modal
        showModal={showModalValidation}
        closeModal={() => setShowModalValidation(false)}
      >
        <div className="bg-white p-4" style={{ minWidth: "500px" }}>
          <span className="text-justify flex justify-center font-semibold ">
            {t("missingWording.phtSpan2")}
            <p className="text-black font-semibold ml-1"> {formData?.name}</p>?
          </span>
          <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}>
            {!isValidSteps(formData?.sections) && (
              <>
                <span className="text-sm text-red-400">
                  {t("missingWording.phtSpan3")}
                </span>
                <p className="flex flex-col mt-6">
                  <label className="text-xs ">
                    {" "}
                    {t("missingWording.phtLabel1")}
                  </label>
                  <Textarea
                    isRequired
                    labelText={t("missingWording.phtLabel1")}
                  ></Textarea>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => setShowModalValidation(false)}
          >
            {t("missingWording.cancel")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              await validateFormData(formData?._id);
            }}
          >
            {t("missingWording.validate")}
          </Button>
        </div>
      </Modal>
      <Modal
        showModal={modalInValidation}
        closeModal={() => setOpenModalInValidation(false)}
      >
        <div className="bg-white p-4" style={{ minWidth: "500px" }}>
          <span className="text-justify flex justify-center font-semibold ">
            {t("missingWording.phtSpan4")}
            <p className="text-black font-semibold ml-1"> {formData?.name}</p>?
          </span>
          <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}>
            {isValidSteps(formData?.sections) && (
              <>
                <span className="text-sm text-red-400">
                  {t("missingWording.phtSpan5")}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => setOpenModalInValidation(false)}
          >
            {t("missingWording.no")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              await invalidateFormData(formData?._id);
            }}
          >
            {t("missingWording.yes")}
          </Button>
        </div>
      </Modal>
      <Modal
        showModal={showModalSingin}
        closeModal={() => setShowModalSingin(false)}
      >
        <div className="bg-white p-4" style={{ minWidth: "500px" }}>
          <span className="text-justify flex justify-center font-semibold ">
            {t("missingWording.sign2")}
            <p className="text-black font-semibold ml-1"> {formData?.name}</p>?
          </span>
          <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}>
            {formData?.status === "PROGRESS" && (
              <>
                <span className="text-sm text-red-400">
                  {t("missingWording.sign3")}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => setShowModalSingin(false)}
          >
            {t("missingWording.cancel")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              await signFormDataByWriter(formData?._id);
            }}
          >
            {t("missingWording.sign")}
          </Button>
        </div>
      </Modal>

      <Modal
        showModal={showModalFrezze}
        closeModal={() => setShowModalFrezze(false)}
      >
        <div className="bg-white p-4" style={{ minWidth: "500px" }}>
          <span className="text-justify flex justify-center font-semibold ">
            {t("missingWording.blockMsg1")}
            <p className="text-black font-semibold ml-1"> {formData?.name}</p>?
          </span>
          <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}>
  </div>
        </div>
        <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => setShowModalFrezze(false)}
          >
            {t("missingWording.cancel")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              await blockFormData(formData?._id,true);
            }}
          >
            {t("missingWording.validate")}
          </Button>
        </div>
      </Modal>
      <Modal
        showModal={showModalNoFrezze}
        closeModal={() => setShowModalNoFrezze(false)}
      >
        <div className="bg-white p-4" style={{ minWidth: "500px" }}>
          <span className="text-justify flex justify-center font-semibold ">
            {t("missingWording.blockMsg2")}
            <p className="text-black font-semibold ml-1"> {formData?.name}</p>?
          </span>
          <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}>
         
          </div> 
        </div>
        <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => setShowModalNoFrezze(false)}
          >
            {t("missingWording.no")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              await blockFormData(formData?._id,false);
            }}
          >
            {t("missingWording.yes")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
