import { Box, Button, Grid, Textarea } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fomDataStatusUpdate } from "../../../../api/study";
import { useForm } from "../../../../hooks/FormGeneratorHooks";
import { selectedSectionAtom } from "../../../../state/SelectedSection";
import { userAtom } from "../../../../state/auth";
import { visteAtom } from "../../../../state/visteAtom";
import ButtonUI from "../../../ButtonUI";
import Modal from "../../../ModalUI";
import makeToast from "../../../Snackbar";
import Tooltip from "../../../toolTip";
import { PageLayout } from "./PageLayout";
import { socketAtom } from "../../../../state/socket";

export const MultiStepsLayout = ({
  children,
  submitLabel = "Submit",
  isEvent = false,
  isEpro = false,
  isEproActive = false,
  ...props
}) => {
  const { t } = useTranslation() as any;
  const form = useForm({ subscribe: "form" });
  const topRef = useRef<HTMLDivElement>(null);
  const hasSteps = !!form?.steps?.length;
  const [selectedSection, setSelectedSection] = useAtom(selectedSectionAtom);
  const [user] = useAtom(userAtom);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [showModalValidationWriter, setShowModalValidationWriter] =
    useState(false);
  const [formData, setFormData] = useAtom(visteAtom);
  const isValidSteps = (sections: any[]) => {
    let flag = sections?.every((s) => s.status === "VALID_BY_WRITER");
    return flag;
  };
  const validateFormData = async (visteId) => {
    try {
      // const res = await fomDataStatusUpdate({ status: "APPROVED" }, visteId);
      if (socket) {
        socket.emit("updateFormStatus", {
          roomId: visteId,
          status: "APPROVED",
        });
        setShowModalValidation(false);
        makeToast("success", `${t("Survey.validVisit")}`);
      } else {
        makeToast("error", `${t("Survey.erreurProd")}`);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: PageHeader.tsx:22 ~ validateFormData ~ error",
        error
      );
      makeToast("error", `${t("Survey.erreurProd")}`);
    }
  };
  useEffect(() => {
    if (selectedSection && form) {
      form.goToStep(
        `${selectedSection.sectionName + "-" + selectedSection.stepNbr}`
      );
    }
  }, [selectedSection, setSelectedSection]);

  return (
    <PageLayout {...props} isEproActive={isEproActive}>
      <form noValidate onSubmit={hasSteps ? form.submitStep : form.submit}>
        <div ref={topRef}></div>
        {children}

        {hasSteps && (
          <>
            <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
              {!form.isFirstStep && (
                <Button
                  rounded="lg"
                  bg={"white"}
                  className="mr-3 px-4 py-2 w-auto btn-secondary "
                  onClick={() => form.prevStep()}
                >
                  <p className="font-normal">{t("Survey.Previous")}</p>
                </Button>
              )}
              <Box
                gridColumn="2"
                textAlign="center"
                fontSize="sm"
                color="gray.500"
              ></Box>
              {submitLabel === t("missingWording.submitLabel2") &&
              form.isLastStep ? (
                <>
                  <ButtonUI
                    status="primary"
                    className="mr-3 px-4 py-2 w-56 "
                    type={"button"}
                    onClick={() => {
                      setShowModalValidation(true);
                    }}
                  >
                    {" "}
                    {t("Survey.Confirm")}
                  </ButtonUI>
                </>
              ) : (
                <>
                  {(form.isLastStep ? !form.isValid : !form.isStepValid) &&
                  user?.type === "writer" ? (
                    <Tooltip
                      tooltipText={t("missingWording.noValidField")}
                      position={"top"}
                    >
                      <ButtonUI
                        status="primary"
                        className="mr-3 px-4 py-2 w-56 SaveBtn"
                        type={"submit"}
                        disabled={
                          (form.isLastStep
                            ? !form.isValid
                            : !form.isStepValid) &&
                          form.isStepSubmitted &&
                          user?.type === "writer"
                        }
                      >
                        {form.isLastStep
                          ? submitLabel
                          : t("missingWording.next")}
                      </ButtonUI>
                    </Tooltip>
                  ) : !isEpro ? (
                    <>
                      {formData &&
                      !props?.isEditing &&
                      formData.submited &&
                      user?.type === "writer" ? (
                        <Tooltip
                          tooltipText={t("missingWording.dejaSubmit")}
                          position={"top"}
                        >
                          <ButtonUI
                            status="primary"
                            className="mr-3 px-4 py-2 w-56"
                            type={"button"}
                            disabled={
                              ((form.isLastStep
                                ? !form.isValid
                                : !form.isStepValid) &&
                                form.isStepSubmitted &&
                                (user?.type === "writer" ||
                                  user?.type === "sponsor")) ||
                              (formData &&
                                !props?.isEditing &&
                                formData.submited &&
                                user?.type === "writer")
                            }
                            onClick={() => {
                              if (topRef.current) {
                                topRef.current.scrollIntoView({
                                  behavior: "smooth",
                                });
                              }
                              form.isLastStep
                                ? form.isValid
                                  ? setShowModalValidationWriter(true)
                                  : makeToast(
                                      "warning",
                                      `${t("missingWording.invalidForm")}`
                                    )
                                : form.nextStep();
                            }}
                          >
                            {form.isLastStep
                              ? submitLabel
                              : `${t("Survey.Next")}`}
                          </ButtonUI>
                        </Tooltip>
                      ) : (
                        <ButtonUI
                          status="primary"
                          className="mr-3 px-4 py-2 w-56"
                          type={"button"}
                          disabled={
                            (form.isLastStep
                              ? !form.isValid
                              : !form.isStepValid) &&
                            form.isStepSubmitted &&
                            (user?.type === "writer" ||
                              user?.type === "sponsor")
                          }
                          onClick={() => {
                            if (topRef.current) {
                              topRef.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                            form.isLastStep
                              ? form.isValid
                                ? setShowModalValidationWriter(true)
                                : makeToast(
                                    "warning",
                                    `${t("missingWording.invalidForm")}`
                                  )
                              : form.nextStep();
                          }}
                        >
                          {form.isLastStep
                            ? submitLabel
                            : `${t("Survey.Next")}`}
                        </ButtonUI>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </Grid>
            <Box
              gridColumn="2"
              textAlign="center"
              fontSize="sm"
              color="gray.500"
              mt={4}
            >
              Page {(form.currentStep?.index ?? 0) + 1} / {form.steps?.length}
            </Box>
          </>
        )}
      </form>
      <Modal
        showModal={showModalValidation}
        closeModal={() => setShowModalValidation(false)}
      >
        <div className="bg-white p-4" style={{ minWidth: "500px" }}>
          <span className="text-justify flex justify-center font-semibold ">
            {t("Survey.NextVisit")}
            <p className="text-black font-semibold ml-1"> {formData?.name}</p>?
          </span>
          <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}>
            {!isValidSteps(formData?.sections) && (
              <>
                <p className="flex flex-col mt-6">
                  <Textarea
                    isRequired
                    placeholder={t("Survey.RaisValid")}
                  ></Textarea>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => setShowModalValidation(false)}
          >
            {t("Survey.Cancel")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              await validateFormData(formData?._id);
            }}
          >
            {t("Survey.Confirm")}
          </Button>
        </div>
      </Modal>
      <Modal
        showModal={showModalValidationWriter}
        closeModal={() => setShowModalValidationWriter(false)}
        className="px-10 py-10"
      >
        <div className="p-4">
          <h3 className="mb-8  " style={{ fontSize: "20px" }}>
            {t("Survey.WriterVisit")} {formData?.name}?
          </h3>
          {/* <div className={true ? "mb-20 mt-0" : "mb-24 mt-6"}></div> */}
          <div className="flex justify-between">
            <ButtonUI
              status="secondary"
              width="100px"
              height="50px"
              className="float-right mb-4 mx-2 btn-delete"
              onClick={() => setShowModalValidationWriter(false)}
            >
              {t("Survey.Cancel")}
            </ButtonUI>
            <ButtonUI
              status="secondary"
              width="100px"
              height="50px"
              className="float-left mb-4 mx-2"
              onClick={async () => {
                setShowModalValidationWriter(false);

                await form.submit();
              }}
            >
              {t("Survey.Confirm")}
            </ButtonUI>
          </div>
        </div>
      </Modal>
    </PageLayout>
  );
};
