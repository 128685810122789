export const SignIn = {
  fr: {
    welcome: "Bienvenue",
    login: "Se connecter",
    step1: "Informations personnelles",
    step2: "Abonnement",
    step3: "Vérification",
    periodicity: "Mensuel",
    btnAcheter: "Souscrire",
    succPay: "Paiement réussi",
    im: "",
    doctor: "Ksearch",
    patient: "a patient",
    email: "Email",
    psw: "Mot de passe",
    voir: "voir",
    forgetPassword: "Mot de passe oublié ?",
    rememberMe: "Se souvenir de moi",
    haventCompte: "Vous n'avez pas de compte ?",
    createCompte: "Créer un compte",
    ErrorMsgConfirmedEmail: "Veuillez confirmer votre adresse e-mail",
    SuccessConnectedMsg: "Vous êtes maintenant connecté",
    ErrorMsgIncorrectCredential: "Votre email ou mot de passe sont incorrects.",
    compteActivated: "Votre compte a été activé avec succès",
    IncorrectCode: "Le code saisi est invalide",
    ErrorSendingCode:
      "Un problème est survenu lors de l'envoi du code de vérification",
    EmailCodeSended: "un email contenant un code de validation a été envoyé",
    CheckMail: "Vérifier votre email",
    EnterVerificationCode:
      "Saisissez le code de vérification que vous avez reçu par email",
    VerificationCode: "Code de vérification",
    Confirm: "Confirmer",
    ResendVerificationCode: "Renvoyer le code de vérification",
    ResetYourPassword: "Réinitialisez votre mot de passe",
    EnterMailToReset:
      "Afin de recevoir le lien de réinitialisation de votre mot de passe, veuillez saisir l'adresse e-mail avec laquelle votre compte a été créé.",
    EnterValidEmail: "Veuillez entrer un email valide",
    CheckYourEmail: "Veuillez Verifier votre email",
    confirmationCodeSent:
      "Un code de confirmation a étè envoyé vers votre compte",
    pswConstraint:
      "Le mot de passe doit contenir au minimum 10 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre",
    pswConfirmedConstraint: "Les mots de passe ne correspondent pas",
    SuccessReset: "Votre mot de passe a été réinitialisée",
    ErrorSendEmail: "Un problème est survenu lors d'envoie de l'email",
    YourVerificationCode: "Votre code de vérification",
    NewPSW: "Votre nouveau mot de passe",
    ConfirmNewPSW: "Confimer votre nouveau mot de passe",
    YourMail: "Votre email",
  },
  en: {
    welcome: "Welcome",
    login: "Login",
    step1: "Personal informations",
    step2: "Subscription",
    step3: "Verification",
    periodicity: "Monthly",
    btnAcheter: "Subscribe",
    succPay: "Payment successful",
    im: "I'm",
    doctor: "Ksearch",
    patient: "a patient",
    email: "Email",
    psw: "Password",
    voir: "see",
    forgetPassword: "Forget password",
    rememberMe: "Remember me",
    haventCompte: "You do not have an account ?",
    createCompte: "Create an account",
    ErrorMsgConfirmedEmail: "Please confirm your email address",
    SuccessConnectedMsg: "You are now connected",
    ErrorMsgIncorrectCredential: "Your email or password are incorrect.",
    compteActivated: "Your account has been successfully activated",
    IncorrectCode: "Le code saisi est invalide",
    ErrorSendingCode: "There was a problem sending the verification code",
    EmailCodeSended: "an email containing a validation code has been sent",
    CheckMail: "Check your email",
    EnterVerificationCode: "Enter the verification code you received by email",
    VerificationCode: "Verification code",
    Confirm: "Confirm",
    ResendVerificationCode: "Resend verification code",
    ResetYourPassword: "Reset your password",
    EnterMailToReset:
      "To receive your password reset link, please enter the email address with which your account was created",
    EnterValidEmail: "Please enter a valid email",
    CheckYourEmail: "Please check your email",
    confirmationCodeSent: "A confirmation code has been sent to your account",
    pswConstraint:
      "The password must contain at least 10 characters, namely: at least one lowercase letter and one uppercase letter, one special character and one number",
    pswConfirmedConstraint: "Passwords do not match",
    SuccessReset: "Your password has been reset",
    ErrorSendEmail: "A problem occurred while sending the email",
    YourVerificationCode: "Your verification code",
    NewPSW: "Your new password",
    ConfirmNewPSW: "Confirm your new password",
    YourMail: "Your email",
  },
};
