import { useEffect, useState } from "react";
import { Spinner, Textarea } from "@chakra-ui/react";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import { MdErrorOutline } from "react-icons/md";
import { HiCheckCircle } from "react-icons/hi";

export const FieldTextarea = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessages,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const { children, label, placeholder, helper, ...rest } = otherProps;
  const [isValidation, setIsValidation] = useState(false);
  const [user, setUser] = useAtom(userAtom);

  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      {user?.type !== "writer" && (
        <p className="text-xs text-red-300">
          {" "}
          {!isValid &&
            !isValidation &&
            errorMessages &&
            errorMessages.map((er) => er)}
        </p>
      )}
      <Textarea
        borderWidth={props.readOnly ? 2 : 1}
        id={id}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          setIsValidation(true);
        }}
        onBlur={(e) => {
          setIsTouched(true);
          setIsValidation(false);
          if (props?.onSumbit)
            props.onSumbit({ fieldName: name, value: e.target.value,isValid:isValid });
        }}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : undefined}
        placeholder={placeholder}
        disabled={props.readOnly ? props.readOnly : false}
        fontSize="14px"
        _invalid={{ borderColor: "Red" }}
        className="border border-gray-200 focus:border-blue-500 focus:shadow-sm w-full "
      />
      {!props?.isPreview && user?.type === "writer" && (
        <div className="absolute -left-8 top-1/2">
          {" "}
          {isValidation && (
            <p>
              <Spinner size="sm" flex="none" color="blue.200" />
            </p>
          )}
          <p>
            {" "}
            {isValid && !isValidation && (
              <HiCheckCircle color="#67d428" width={20} height={20} />
            )}
            {!isValid && !isValidation && (
              <MdErrorOutline color="#ff0000" width={20} height={20} />
            )}
          </p>
        </div>
      )}
      {children}
    </FormGroup>
  );
};
