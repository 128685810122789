import React, { useState } from 'react';
import SelectComp from '../Select';
import { useTranslation } from 'react-i18next';

const DynamicTable = ({ data, columns ,listGroups=[],onUpdateGroupId}) => {
    const { t } = useTranslation() as any;
    const [updatedData, setUpdatedData] = useState(data);
    const handleGroupIdChange = (index, groupId) => {
      const newData = [...updatedData];
      newData[index].groupId = groupId;
      setUpdatedData(newData);
      onUpdateGroupId(newData); // Call the parent component's function to update the groupId in the parent state if needed
    };
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {columns.includes('ID') && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Participant.IdntfPublic')}</th>}
          {columns.includes('E-mail') && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Participant.mail')}</th>}
          {columns.includes('Full name') && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Participant.fullName')}</th>}
          {columns.includes('Groupe') && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Participant.group')}</th>}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {updatedData.map((item, index) => (
          <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            {columns.includes('ID') && <td className="px-6 py-4 whitespace-nowrap text-xs">{item.publicId}</td>}
            {columns.includes('E-mail') && <td className="px-6 py-4 whitespace-nowrap text-xs">{item.email}</td>}
            {columns.includes('Full name') && <td className="px-6 py-4 whitespace-nowrap text-xs">{item.lastName+" "+item.firstName}</td>}
            {columns.includes('Groupe') && <td className="px-6 py-4 whitespace-nowrap text-xs"><SelectComp text={"............"} className='w-1/2 max-h-8' options={listGroups} onChange={(e) => handleGroupIdChange(index, e.target.value)}/></td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;