import classNames from "classnames";
import { useAtom } from "jotai";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getDashboardData_03 } from "../../api/study";
import Query from "../../assets/query.jpg";
import Update from "../../assets/update.jpg";
import moment from "../../helper/moment";
import { testModeAtom } from "../../state/testModeAtom";
const ParticipantList = ({ setSelectedQuery, shouldUpdate }) => {
  const { t } = useTranslation() as any;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({}) as any;
  const [testMode] = useAtom(testModeAtom);
  const params = useParams() as any;
  const { studyId } = params;
  useEffect(() => {
    setIsLoading(true);
    getDashboardData_03(studyId, testMode).then((result) => {
      if (result && result?.status === "success") {
        setStats(result?.response);
        setIsLoading(false);
      }
    });
  }, [studyId, shouldUpdate, testMode]);

  return isLoading ? (
    <div className="mt-10 w-1/3 p-2">
      <h3 className="uppercase"> {t("missingWording.card4")} </h3>
      <div className="relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-2 mb-3 mt-3 bg-white cursor-pointer animate-pulse">
        <div className="flex items-center h-14">
          <div className="flexCenter mx-5 my-auto relative">
            <div className="bg-gray-300 w-11 h-11"></div>
          </div>
          <div className="">
            <div className="flex-grow">
              <div className="bg-gray-300 h-4 w-36 mb-1 rounded-sm"></div>
              <div className="bg-gray-300 h-4 w-40 rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-2 mb-3 bg-white cursor-pointer animate-pulse">
        <div className="flex items-center h-14">
          <div className="flexCenter mx-5 my-auto relative">
            <div className="bg-gray-300 w-11 h-11"></div>
          </div>
          <div className="">
            <div className="flex-grow">
              <div className="bg-gray-300 h-4 w-36 mb-1 rounded-sm"></div>
              <div className="bg-gray-300 h-4 w-40 rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-2 mb-3 bg-white cursor-pointer animate-pulse">
        <div className="flex items-center h-14">
          <div className="flexCenter mx-5 my-auto relative">
            <div className="bg-gray-300 w-11 h-11"></div>
          </div>
          <div className="">
            <div className="flex-grow">
              <div className="bg-gray-300 h-4 w-36 mb-1 rounded-sm"></div>
              <div className="bg-gray-300 h-4 w-40 rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="uppercase mt-20">{t("missingWording.card5")}</h3>
      <div className="relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-4 mb-3 mt-3 bg-white cursor-pointer animate-pulse">
        <div className="flex items-center h-12">
          <div className="flexCenter mx-5 my-auto relative">
            <div className="bg-gray-300 w-14 h-14 rounded-full"></div>
          </div>
          <div className="">
            <div className="flex-grow">
              <div className="bg-gray-300 h-4 w-36 mb-2 rounded-sm"></div>
              <div className="bg-gray-300 h-3 w-40 mb-4 rounded-sm"></div>
              <div className="bg-gray-300 h-2 w-24 mb-1 rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-4 mb-3 bg-white cursor-pointer animate-pulse">
        <div className="flex items-center h-12">
          <div className="flexCenter mx-5 my-auto relative">
            <div className="bg-gray-300 w-14 h-14 rounded-full"></div>
          </div>
          <div className="">
            <div className="flex-grow">
              <div className="bg-gray-300 h-4 w-36 mb-2 rounded-sm"></div>
              <div className="bg-gray-300 h-3 w-40 mb-4 rounded-sm"></div>
              <div className="bg-gray-300 h-2 w-24 mb-1 rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-4 mb-3 bg-white cursor-pointer animate-pulse">
        <div className="flex items-center h-12">
          <div className="flexCenter mx-5 my-auto relative">
            <div className="bg-gray-300 w-14 h-14 rounded-full"></div>
          </div>
          <div className="">
            <div className="flex-grow">
              <div className="bg-gray-300 h-4 w-36 mb-2 rounded-sm"></div>
              <div className="bg-gray-300 h-3 w-40 mb-4 rounded-sm"></div>
              <div className="bg-gray-300 h-2 w-24 mb-1 rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="mt-10 w-1/3 p-2  ">
      <h3 className="uppercase"> {t("missingWording.card4")} </h3>
      {_.isEmpty(stats?.latestUpdates) ? (
        <div className="rounded-lg bg-white  shadow-md p-4 mt-3  flex flex-col gap-4">
          <span className="card-subtitle text-center">
            {t("missingWording.updateEmpty")}
          </span>
        </div>
      ) : (
        <div className="mt-3">
          {stats?.latestUpdates?.map((el) => (
            <div
              className={classNames(
                ` relative shadow rounded-xl flex items-center justify-between hover:shadow-xl py-2 mb-3 bg-white cursor-pointer`
              )}
              onClick={() => {
                history.push(`/participant/${studyId}/${el._id}`);
              }}
              style={{ zIndex: 0 }}
              key={el.id}
            >
              <div className="flex items-center patient-info h-14">
                <div className=" flexCenter mx-5 my-auto relative">
                  <img src={Update} className="w-11 h-11" />
                </div>

                <div className="">
                  <div className="flex-grow  ">
                    <p className="font-bold text-md">
                      Participant : {el?.publicId}
                    </p>
                    <p className="card-subtitle">
                      {t("missingWording.subCard4")}{" "}
                      {moment(el?.updatedAt).format(`DD/MM/YYYY`)}{" "}
                      {t("missingWording.timeCard")}{" "}
                      {moment(el?.updatedAt).format("HH:mm")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <span
              className="cursor-pointer color-primary-blue text-sm normal-case pl-3"
              onClick={() => {
                history.push(`/participant/${studyId}`);
              }}
            >
              Voir plus
            </span>
          </div>
        </div>
      )}

      <h3 className="uppercase mt-10">{t("missingWording.card5")}</h3>
      {_.isEmpty(stats?.latestPendingQueries) ? (
        <div className="rounded-lg bg-white  shadow-md p-4 mt-3  flex flex-col gap-4">
          <span className="card-subtitle text-center">
            {t("missingWording.subCard5")}
          </span>
        </div>
      ) : (
        <div className=" mt-3">
          {stats?.latestPendingQueries?.map((el) => (
            <div
              className={classNames(
                `cursor-pointer relative shadow rounded-xl  flex items-center justify-between hover:shadow-xl py-2 mb-3 bg-white`
              )}
              style={{ zIndex: 0 }}
              onClick={() => {
                setSelectedQuery(el);
              }}
              key={el.id}
            >
              <div className="flex items-center patient-info">
                <div className="flexCenter mx-5 my-auto relative">
                  <img src={Query} className="w-11 h-11" />
                </div>

                <div className="">
                  <div className="flex-grow  ">
                    <p className="font-bold text-md">
                      Participant : {el?.participantId?.publicId}
                    </p>
                    <p className="card-subtitle">Champ : {el?.fieldName}</p>
                    <p className="card-subtitle">
                      {el?.formDataId?.name +
                        " - " +
                        el?.formDataId?.sections[el?.sectionNbr]
                          ?.sectionName}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <span
              className="cursor-pointer color-primary-blue text-sm normal-case pl-3"
              onClick={() => {
                history.push(`/query/${studyId}`);
              }}
            >
              Voir plus
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ParticipantList;
