import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import Button from "../../../ButtonUI";
import { useAtom } from "jotai";
import { socketAtom } from "../../../../state/socket";

const NewQueryFieldModal = (props) => {
  const {
    setShowQuery,
    selectedElment,
    setTexteMsg,
    loadingQuery,
    setSelectedElment,
    participant,
    formDataId,
    addQueryToField,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Button
        status="icon-secondary"
        onClick={() => setShowQuery(false)}
        className="absolute right-1 top-1"
      >
        <Close />
      </Button>

      <div className="space-y-4 xs:mt-8 md:mt-8" style={{}}>
        <div>
          <h2 className=" text-lg font-semibold">
            {t("Survey.AddQuer")} {selectedElment?.label}
          </h2>
        </div>

        <div className="flex flex-col border-2 rounded-3xl p-4">
          <div className="flex">
            <div className="w-64">
              <p className="subTitle"> Participant :</p>
            </div>
            <div>
              <p>{participant?.publicId}</p>
            </div>
          </div>
          <div className="flex mt-4 mb-4">
            <div className="w-64">
              <p className="subTitle">{t("Survey.FieldName")} :</p>
            </div>
            <div>
              <p>{selectedElment ? selectedElment.label : "N/A"}</p>
            </div>
          </div>
          <div className="flex">
            <div className="w-64">
              <p className="subTitle">{t("Survey.valField")} :</p>
            </div>
            <div>
              <p>
                {selectedElment &&
                  (selectedElment.value !== null
                    ? selectedElment.value
                    : "N/A")}
              </p>
            </div>
          </div>
        </div>
        <div>
          <textarea
            name="remark"
            rows={4}
            placeholder={`${t("Survey.remarque")}`}
            className="border-2 rounded-3xl p-4 w-full"
            onChange={(e) => setTexteMsg(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-left mb-4 btn-delete"
            onClick={() => {
              setShowQuery(false);
              setSelectedElment();
            }}
          >
            {t("Survey.Cancel")}
          </Button>
          {loadingQuery ? (
            <div className="flex flex-row-reverse">
              <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          ) : (
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="float-right mb-4"
              onClick={async (e) => {


                await addQueryToField(selectedElment, formDataId);
                setShowQuery(false);
              }}
            >
              {t("Survey.Save")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default NewQueryFieldModal;
