import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { updateSurveyOder } from "../../api/survey";
import { ReactComponent as Survery } from "../../assets/illustrations/survey.svg";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";

const SortableList = (props) => {
  const moveArrayItem = (array: any[], from: number, to: number) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
    return array;
  };

  const [items, setItems] = useState(props.list);
  const [payload, setPayload] = useState<any>();
  const [isSetup, setIsSetup] = useState(false);
  const { t } = useTranslation();
  const SortableItemx = SortableElement(({ value, index }) => (
    <div
      className={`relative cursor-move rounded-lg my-2  py-1 shadow-md hover:shadow-lg  w-full  justify-between bg-gray-50  z-50`}
    >
      <div className="flex ">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
          style={{
            backgroundColor: "#ccd2ff",
          }}
        >
          <Survery width={37} />
        </div>
        <div className="details">
          <div className="flex-grow ">
            <p className="card-title capitalize">{value?.name}</p>
            <p className="card-subtitle">
              <span>
                <p className="box max-w-350 text-xs mt-1">
                  {t("missingWording.role") + ` ${value?.roleId?.name}`}
                </p>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  ));

  const SortableContainerx = SortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let sortedArray = moveArrayItem(items, oldIndex, newIndex);
    let outputArray: any[] = [];
    setItems((items) => sortedArray);
    sortedArray.map((el, i) => {
      outputArray.push({ formId: el?._id, orderForm: i });
    });
    setPayload(outputArray);
    setIsSetup((i) => !i);
  };

  useEffect(() => {
    // console.log(items);
    // console.log(payload);
  }, [isSetup]);

  return (
    <div id="formaddformmodal">
      <h1>{t("missingWording.orderVisit")}</h1>
      <div className="max-h-full overflow-y-auto p-5 mt-10 relative ">
        <SortableContainerx lockAxis="y" onSortEnd={onSortEnd}>
          {items.map((value, index) => (
            <SortableItemx key={`item-${value}`} index={index} value={value} />
          ))}
        </SortableContainerx>
        <div className="mt-5 mb-5 ml-72">
          <Button
            status="secondary"
            width="130px"
            height="50px"
            className="ml-3 px-4 mb-4 py-2  float-left   "
            onClick={async () => {
              props.closeModal();
            }}
          >
            {t("missingWording.cancel")}
          </Button>
          <Button
            disabled={!payload || !items || items?.length < 2}
            status="primary"
            width="130px"
            height="50px"
            className="ml-3 px-4 mb-4 py-2  float-left   "
            onClick={async () => {
              if (payload) {
                setPayload(null);
                await updateSurveyOder(payload);
                makeToast("success", t("missingWording.orderSucess"));
                props.closeModal();
              }
            }}
          >
            {t("missingWording.save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SortableList;
