import { useAtom } from "jotai";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { BiDownload } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Icon from "../../components/Icon";
import Pagination from "../../components/PaginationTable";
import { checkArcAdmin } from "../../helper";
import { userAtom } from "../../state/auth";
import { BsEye, BsArchive } from "react-icons/bs";
import { useState } from "react";
import ArchivageModal from "../../pages/DashboardCRO/Modals/archivageAlert";
import Modal from "../ModalUI";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import DropdownComponent from "../DropDown";
import EndStudyModal from "../../pages/DashboardCRO/Modals/endStudyAlert";
import Tooltip from "../toolTip";
import { AiFillLock } from "react-icons/ai";
import DownloadModal from "../../pages/DashboardCRO/Modals/downloadSelectorModal";
interface IProps {
  datahlist: any[] | any;
  setStudySelectedId: any;
  setSelectedStudyBoolean: any;
  setPage: any;
  totalResult: number;
  page: any;
  handleDownloadExcel?: any;
  isDisabled?: boolean;
  setStep?: any;
  setUpdate?: any;
}
const RenderDivs = (props: IProps) => {
  const {
    datahlist,
    setStudySelectedId,
    setSelectedStudyBoolean,
    setPage,
    totalResult,
    page,
    handleDownloadExcel,
    isDisabled = false,
    setStep = null,
    setUpdate = null,
  } = props;
  const history = useHistory();
  const [user] = useAtom(userAtom);
  // const [studySelectedId, setStudySelectedId] = useAtom(StudySelectedIdAtom);

  const [modalArchivage, setModalArchivage] = useState(false);
  const [modalEndStudy, setModalEndStudy] = useState(false);
  const [modalDownloadData, setModalDownloadData] = useState(false);
  const { t } = useTranslation();
  const colorAvatar = ["#a6a9c81c"];
  const checkActionAllowed = (action, role, arcAdmins) => {
    switch (role) {
      case "arc":
        let flag: boolean = checkArcAdmin(user?._id, arcAdmins);
        switch (action) {
          case "CONFIG":
            return flag;

          case "EXTARCT":
            console.log(
              "🚀 ~ file: index.tsx:47 ~ checkActionAllowed ~ EXTARCT:",
              "EXTARCT",
              flag
            );

            return flag;
          case "DATA":
            return true;
        }
        break;
      case "writer":
        switch (action) {
          case "CONFIG":
            return false;

          case "EXTARCT":
            return false;
          case "DATA":
            return true;
        }
        break;
      case "cro":
        return true;

      case "sponsor":
        return true;
      default:
        break;
    }
  };
  return (
    <div className="relative ">
      <div className="mb-4 font-semibold justify-between  relative xs:text-xs sm:text-sm md:text-sm lg:text-md grid grid-cols-4 gap-2">
        <div className="flex items-center ">
          <div>
            <div>
              <p
                className=" boxName xs:px-4 xs:py-2 md:px-6 md:py-4 capitalize font-bold"
                style={{ background: "transparent" }}
              ></p>
            </div>
          </div>
          <div className="capitalize xs:ml-4 md:ml-12 ">
            {" "}
            {t("missingWording.Sname")}
          </div>
        </div>
        <div className="flex items-center ">
          <p>{t("missingWording.studyResp")}</p>
        </div>
        <div className="flex items-center ">
          {" "}
          <p>{t("missingWording.MAJ")}</p>
        </div>
      </div>
      {datahlist.map((p, i) => {
        return (
          <div className="relative ">
            <div
              className=" justify-between  relative xs:text-xs sm:text-sm md:text-sm lg:text-md grid grid-cols-4 gap-2"
              onClick={(e) => {
                if (
                  user?.type === "writer" ||
                  (user?.type === "arc" &&
                    !checkArcAdmin(user._id, p?.arcAdmins))
                ) {
                  history.push(`/participant/${p?._id}`);
                  setSelectedStudyBoolean(true);
                  setStudySelectedId(p?._id);
                  e.stopPropagation();
                  e.preventDefault();
                } else {
                  e.stopPropagation();
                  e.preventDefault();
                  return;
                }
              }}
            >
              <div className="flex items-center ">
                <div>
                  <div>
                    <p
                      className=" boxName xs:px-4 xs:py-2 md:px-6 md:py-4 capitalize font-bold"
                      style={{
                        backgroundColor: colorAvatar[i % colorAvatar.length],
                      }}
                    >
                      {p.name[0]}
                    </p>
                  </div>
                </div>
                <div className="capitalize xs:ml-4 md:ml-12 ">{p.name}</div>
              </div>
              <div className="flex items-center ">
                <p>{user?.lastName + " " + user?.firstName}</p>
              </div>
              <div className="flex items-center ">
                {" "}
                <p>
                  {moment(p.createdAt).format("DD-MM-YYYY") +
                    t("DashboardCro.at") +
                    moment(p.createdAt).format("HH:mm")}
                </p>
              </div>

              {!isDisabled && (
                <div className=" flex felx-row justify-end items-center ">
                  {p.status === "END" && (
                    <Tooltip
                      tooltipText={`${t("missingWording.toolTipStudyEnd")}`}
                      position={"left"}
                      clsText={{ width: "240px" }}
                    >
                      <AiFillLock
                        color="#82B6D9"
                        className="mx-2 cursor-pointer w-5 h-5"
                      />
                    </Tooltip>
                  )}
                  {checkActionAllowed("DATA", user?.type, p?.arcAdmins) && (
                    <Tooltip
                      tooltipText={`${t("missingWording.browse")}`}
                      position={"top"}
                    >
                      <BsEye
                        className="mx-2 cursor-pointer w-5 h-5"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          history.push(`/dashboard/${p?._id}`);
                          setSelectedStudyBoolean(true);
                          setStudySelectedId(p?._id);
                        }}
                      />
                    </Tooltip>
                  )}
                  {checkActionAllowed("CONFIG", user?.type, p?.arcAdmins) && (
                    <Tooltip
                      tooltipText={`${t("missingWording.configure")}`}
                      position={"top"}
                    >
                      <Icon
                        name="reglage-haut_droite"
                        isIcon={true}
                        className=" cursor-pointer self-center"
                        onClick={async (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          history.push(`/study/${p._id}`);
                        }}
                      />
                    </Tooltip>
                  )}

                {checkActionAllowed("CONFIG", user?.type, p?.arcAdmins) &&   <Tooltip
                    tooltipText={`${t("missingWording.actionsPlus")}`}
                    clsText={{ width: "120px" }}
                    position={"left"}
                  >
                    <DropdownComponent
                      setupIcon={true}
                      options={
                        user?.type === "cro"
                          ? [
                              {
                                name: t("missingWording.phtOp101"),
                                action: (e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedStudyBoolean(true);
                                  setStudySelectedId(p?._id);
                                  //handleDownloadExcel(p._id);
                                  setModalDownloadData(true)
                                },
                              },
                              {
                                name: t("missingWording.phtOp202"),
                                action: () => {
                                  setSelectedStudyBoolean(true);
                                  setStudySelectedId(p?._id);
                                  setModalArchivage(true);
                                },
                              },
                              {
                                name: t("missingWording.phtOp303"),
                                action: () => {
                                  setSelectedStudyBoolean(true);
                                  setStudySelectedId(p?._id);
                                  setModalEndStudy(true);
                                },
                              },
                            ]
                          : [
                              {
                                name: t("missingWording.phtOp101"),
                                action: (e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedStudyBoolean(true);
                                  setStudySelectedId(p?._id);
                                  //handleDownloadExcel(p._id);
                                  setModalDownloadData(true)
                                },
                              },
                            ]
                      }
                    />
                  </Tooltip>}
                </div>
              )}
            </div>

            <div className="line"></div>
          </div>
        );
      })}
      <div>
        {totalResult > 8 && (
          <Pagination
            entriesName={`${t("missingWording.paginationS")}`}
            totalResults={totalResult}
            resultsPerPage={8}
            currentPage={page}
            onPageChange={function (page: number): void {
              setPage(page);
            }}
          />
        )}
      </div>
      <Modal showModal={modalArchivage} className="px-10 py-16">
        <ArchivageModal setOpenModal={setModalArchivage} setStep={setStep} />
      </Modal>
      <Modal showModal={modalEndStudy} className="px-12 py-16">
        <EndStudyModal
          setOpenModal={setModalEndStudy}
          setStep={setStep}
          setUpdate={setUpdate}
        />
      </Modal>
      <Modal showModal={modalDownloadData} className="px-10 py-16">
        <DownloadModal
          setOpenModal={setModalDownloadData}
          downloadApi={handleDownloadExcel}
    
        />
      </Modal>
    </div>
  );
};

export default RenderDivs;
