import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/ButtonUI";
import "./styles.scss";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  setOpenModalAddDoctor: Function;
}

export const ConfirmAddPatient: React.FC<Props> = ({
  openModal,
  setOpenModal,
  setOpenModalAddDoctor,
}) => {
  const { t } = useTranslation() as any;

  return (
    <div className="px-2">
      <h3 className=" mb-8">{t("ArcPage.NewAcc")}</h3>
      <div className="flex justify-between mt-6 buttons">
        <Button
          status="secondary"
          width={"100px"}
          height={"50px"}
          className="btn-delete"
          onClick={() => setOpenModal(!openModal)}
        >
          {t("ArcPage.Cancel")}
        </Button>
        <Button
          status="secondary"
          width={"100px"}
          height={"50px"}
          onClick={() => {
            setOpenModal(false);
            setOpenModalAddDoctor(true);
          }}
        >
          {t("ArcPage.AddArc")}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmAddPatient;
