/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSort } from "react-icons/bi";
import { useHistory, useParams } from "react-router-dom";
import {
  getRandomisationConfig,
  getStudyEproForms,
  getStudyEventsConfig,
  getStudyForms,
} from "../../api/study";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import Button from "../../components/ButtonUI";
import CreationSteps from "../../components/CreationSteps";
import Modal from "../../components/ModalUI";
import Spinner from "../../components/Spinner";
import Tooltip from "../../components/toolTip";
import { userAtom } from "../../state/auth";
import { listFormsAtom } from "../../state/listForms";
import EventConfig from "./EventConfigModal";
import SortableList from "./OrderEdition";
import CardSurvey from "./SurveyCard";
import makeToast from "../../components/Snackbar";
import MyModal from "../../components/New-Modal";
import AddEproForm from "./AddEproFormModal";

type Props = any;
const Forms: React.FC<Props> = ({
  readOnly,
  studyConfig,
  openTutoFormModal,
}) => {
  console.log("🚀 ~ file: index.tsx:23 ~ studyConfig:", studyConfig);
  const [loading, setLoading] = useState(false);
  const [randomConfig, setRandomConfig] = useState() as any;
  const { t } = useTranslation() as any;
  const [step, setStep] = useState(0);
  const [tuto, setTuto] = useState(true);
  const history = useHistory();
  const [user] = useAtom(userAtom);
  const [creationModalActive, setCreationModalActive] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [eventCreationModal, setEventCreationModal] = useState(false);
  const [eproFormCreationModal, setEproFormCreationModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [surveyList, setSurveyList] = useState([] as any[]);
  const [eventList, setEventList] = useAtom(listFormsAtom);
  const [surveyEproList, setSurveyEproList] = useState([] as any[]);
  const [surveyListProps, setSurveyListProps] = useState([] as any[]);
  const params: any = useParams();
  const { studyId } = params;
  const [, setWidthPage] = useState(document.body.clientWidth);
  const [showModalMobile, setShowModalMobile] = useState<boolean | Function>(
    false
  );
  const typeForms = studyConfig?.ePro
    ? [
        t("missingWording.formVisits"),
        t("missingWording.formevents"),
        t("missingWording.formEpro"),
      ]
    : [t("missingWording.formVisits"), t("missingWording.formevents")];
  async function fetchMyAPI(): Promise<void> {
    try {
      setLoading(true);
      const randomisationConfig = await getRandomisationConfig(
        studyConfig.randomisationConfig
      );
      if (randomisationConfig && randomisationConfig.config) {
        setRandomConfig(randomisationConfig.config);

        setLoading(false);
      } else {
        setRandomConfig(null);
        makeToast("error", "");
        setLoading(false);
      }
    } catch (error) {
      makeToast("error", "err");
      setLoading(false);
    }
  }
  useEffect(() => {
    if (studyConfig && studyConfig.randomisation) fetchMyAPI();
  }, []);
  const getAllSurveys = async () => {
    try {
      if (step === 0) {
        const res = await getStudyForms(studyId);
        if (res) {
          setSurveyList(res);
          setSurveyListProps(res);
          setLoader(false);
        }
      } else if (step === 1) {
        const res = await getStudyEventsConfig(studyId);
        if (res) {
          setEventList(res);
          setLoader(false);
        }
      } else if (step === 2) {
        const res = await getStudyEproForms(studyId);
        if (res) {
          setSurveyEproList(res);
          setLoader(false);
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllSurveys();
  }, [refresh, step]);

  useEffect(() => {
    setWidthPage(document.body.clientWidth);
    document.body.clientWidth < 900 && setShowModalMobile(true);
  }, [document.body.clientWidth]);
  useEffect(() => {
    let section = localStorage.getItem("F/E");
    switch (section) {
      case "F":
        setStep(0);
        break;
      case "E":
        setStep(1);
        break;
      case "EP":
        setStep(2);
        break;

      default:
        setStep(0);
        break;
    }
  }, []);

  useEffect(() => {
    if (openTutoFormModal && tuto) {
      setCreationModalActive(true);
      setTuto(false);
    }
  }, [openTutoFormModal]);

  return loader ? (
    <Spinner />
  ) : document.body.clientWidth < 900 ? (
    <Modal
      showModal={showModalMobile !== false}
      closeModal={() => setShowModalMobile(false)}
      width={"300px"}
    >
      <div className="m-4">
        <span>{t("missingWording.toastSurvey")}</span>
        <div className="w-full mt-8 flex justify-center items-center">
          <Button
            status="primary"
            width="50%"
            height="37px"
            className=""
            onClick={() => {
              history.push("/");
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  ) : (
    <>
      <div className="flex justify-between">
        <div className="menu cursor-pointer">
          {typeForms.map((m, idx) => {
            return (
              <div
                onClick={() => {
                  setStep(idx);
                  localStorage.setItem(
                    "F/E",
                    idx === 0 ? "F" : idx === 1 ? "E" : "EP"
                  );
                }}
              >
                {" "}
                <p
                  id={`formVisitTab${idx}`}
                  className={`${step === idx ? "selected" : "unselected"}`}
                >
                  {" "}
                  <span className="">{m}</span>{" "}
                </p>{" "}
              </div>
            );
          })}
        </div>
        {step === 0 ? (
          <div className="flex flex-row-reverse items-center relative">
            <div className="flex justify-between items-center w-full mt-5 ">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <Tooltip position="top" tooltipText={`${t("Study.order")}`}>
                  <Button
                    status="icon-primary"
                    onClick={() => setOrderModal(true)}
                    className={`p-2 bg-white border border-blue-500 hover:bg-white ${
                      !surveyList || surveyList.length === 0
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    disabled={
                      !surveyList || surveyList.length === 0 || readOnly
                    }
                  >
                    <BiSort color="#4565f6" size={28} />
                  </Button>
                </Tooltip>

                <Button
                  id="AddFormvisitbtn"
                  status="secondary"
                  onClick={() => setCreationModalActive(true)}
                  className="py-3 px-5"
                  disabled={readOnly}
                >
                  <span className="mr-2">🧾</span>
                  {t("Study.NewVisit")}
                </Button>
              </div>
            </div>
          </div>
        ) : step === 1 ? (
          <div className="flex flex-row-reverse justify-between items-center">
            <div className="flex justify-between items-center w-full mt-5 ">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="flex gap-2 flex-wrap "></div>
                <Button
                  status="secondary"
                  onClick={() => setEventCreationModal(true)}
                  className="py-3 px-5"
                >
                  <span className="mr-2">🧾</span>
                  {t("missingWording.newForm")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row-reverse justify-between items-center">
            <div className="flex justify-between items-center w-full mt-5 ">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="flex gap-2 flex-wrap "></div>
                <Button
                  status="secondary"
                  onClick={() => setEproFormCreationModal(true)}
                  className="py-3 px-5"
                >
                  <span className="mr-2">🧾</span>
                  {t("missingWording.newEproForm")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="my-patients px-2 md:px-0 ">
        {step === 0 && (
          <div className={`patient-list flex flex-wrap gap-y-10 gap-x-32 `}>
            {surveyList && surveyList.length !== 0 ? (
              surveyList.map((el, i) => (
                <CardSurvey
                  visteList={surveyList}
                  patientDetails={el}
                  openModal={false}
                  surveyDetails={el}
                  user={user}
                  i={i}
                  setRefresh={setRefresh}
                  randomConfig={randomConfig}
                />
              ))
            ) : (
              <div className="w-full flexCenter mt-24">
                <h3>{t("Study.VisitStudy")} </h3>
              </div>
            )}
            {/* <SortableList list={[...surveyListProps]} /> */}
          </div>
        )}
        {step == 1 && (
          <div className={`patient-list flex flex-wrap gap-y-10 gap-x-32 `}>
            {eventList && eventList.length !== 0 ? (
              eventList.map((el, i) => (
                <CardSurvey
                  visteList={[]}
                  patientDetails={el.formTemplateId}
                  openModal={false}
                  surveyDetails={el.formTemplateId}
                  user={user}
                  i={i}
                  setRefresh={setRefresh}
                  isEvent
                  eventType={el.type}
                />
              ))
            ) : (
              <div className="w-full flexCenter mt-24">
                <h3>{t("Study.eventStudy")} </h3>
              </div>
            )}
          </div>
        )}
        {step === 2 && (
          <div className={`patient-list flex flex-wrap gap-y-10 gap-x-32 `}>
            {surveyEproList && surveyEproList.length !== 0 ? (
              surveyEproList.map((el, i) => (
                <CardSurvey
                  visteList={surveyList}
                  patientDetails={el}
                  openModal={false}
                  surveyDetails={el}
                  user={user}
                  i={i}
                  setRefresh={setRefresh}
                  randomConfig={randomConfig}
                  ePro={true}
                />
              ))
            ) : (
              <div className="w-full flexCenter mt-24">
                <h3>{t("Study.eproNoforms")} </h3>
              </div>
            )}
            {/* <SortableList list={[...surveyListProps]} /> */}
          </div>
        )}

        <MyModal
          showModal={creationModalActive}
          closeModal={() => {
            // When close modal reset component
            setCreationModalActive(false);
          }}
        >
          <CreationSteps
            visteList={surveyList}
            randomConfig={randomConfig}
            setCreationModalActive={setCreationModalActive}
          />
        </MyModal>
        <Modal
          showModal={orderModal}
          className="p-4"
          closeModal={() => {
            // When close modal reset component
            setOrderModal(false);
          }}
        >
          <Button
            status="icon-secondary"
            onClick={() => setOrderModal(false)}
            className="absolute right-1 top-1 "
            type="button"
          >
            <Close />
          </Button>{" "}
          <SortableList
            list={[...surveyListProps]}
            closeModal={() => {
              setRefresh((r) => !r);
              setOrderModal(false);
            }}
          />
        </Modal>
      </div>
      <Modal
        showModal={eventCreationModal}
        slidemodal={false}
        className="p-4"
        closeModal={() => {
          // When close modal reset component
          setEventCreationModal(false);
        }}
      >
        <EventConfig
          closeModal={() => setEventCreationModal(false)}
          setRefresh={setRefresh}
        />
      </Modal>
      <MyModal
        showModal={eproFormCreationModal}
        closeModal={() => {
          // When close modal reset component
          setEproFormCreationModal(false);
        }}
      >
        <AddEproForm
          visteList={surveyList}
          randomConfig={randomConfig}
          setCreationModalActive={setEproFormCreationModal}
        />
      </MyModal>
    </>
  );
};

export default Forms;
