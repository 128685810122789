import { Center, Divider, FormLabel, Stack } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import {
  updateEventDataPerField,
  updateFomDataPerField,
} from "../../../api/study";
import { validitionRules } from "../../../helper/FormGeneratorHelper/Utils";
import { randomPics } from "../../../helper/FormGeneratorHelper/randomListPhotos";
import {
  isEmail,
  isNumber,
  isRequired,
} from "../../../hooks/FormGeneratorHooks/validitions";
import { visteAtom } from "../../../state/visteAtom";
import ConditionLogic from "../FormBuilder/PropertyEditor/LogicConditional";
import { CalculatedInput } from "./CalculatedInput";
import FieldCheckbox from "./FieldCheckBoxs/index";
// import { FullAdress } from "./FieldFullAdress";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import Button from "../../ButtonUI";
import Modal from "../../ModalUI";
import ChildFieldsConfig from "../FormBuilder/PropertyEditor/ChildFieldsConfig";
import { FullIdentity } from "./FieldFullIdentity";
import { FieldInput } from "./FieldInput";
import { FieldPhoneInput } from "./FieldPhoneNumber";
import { FieldPickIdenticalImages } from "./FieldPickIdenticalImages";
import FieldRadio from "./FieldRadio";
import { FieldRating } from "./FieldRating";
import { FieldSelect } from "./FieldSelect";
import Signature from "./FieldSignature";
import { FieldSlider } from "./FieldSlider";
import { TableField } from "./FieldTable";
import { FieldTextarea } from "./FieldTextarea";
import { FieldUpload } from "./FieldUpload";
import { FieldUploadBase64 } from "./FieldUploadBase64";
import { RepeaterField } from "./RepeaterField";
import TextEditor from "./TextEditor";
import { socketAtom } from "../../../state/socket";
import { userAtom } from "../../../state/auth";
import { User } from "../../../types/user";
import { KATOMI_API_ENDPOINT } from "../../../config/api";
const FormElement = ({
  stepNbr = null,
  isPreview = false,
  formDataId = null,
  element,
  readOnly,
  fieldOnSubmit = false,
  isEvent = false,
  form,
  formValue = null as any,
  translation = null as any,
  isEpro = false,
  ...props
}) => {
  const [url, setUrl] = useState(null) as any;
  const pattern =
  /tenant_(.*?)\/study_(.*?)\/formAssests\/surveysMedia\/SurveyId_(.*?)\/.(.*?)$/;
const secondPattern = /\/(\d+_.*?)$/;
  function extractInformationFromString(string) {
    const matches = string.match(pattern);
    const secondaryMatches = string.match(secondPattern);
    if (matches) {
      const [
        ,
        tenant,
        studyId,
      
        surveyId,
    
        sanitizedFileName,
        fileExtension,
      ] = matches;
      return {
        tenant,
        studyId,
        surveyId,
        sanitizedFileName,
        fileExtension,
        fullInfo:
          secondaryMatches && secondaryMatches.length > 1
            ? secondaryMatches[1]
            : null,
      };
    }
    return null;
  }
  const getFileMedia = (value: any) => {
    try {
    
      const urlExtractResult = extractInformationFromString(value);
      console.log("🚀 ~ constgetFileMedia:any= ~ urlExtractResult:", urlExtractResult)
      const result:string = `${KATOMI_API_ENDPOINT}/media/surveyMedia/${urlExtractResult?.tenant}/${urlExtractResult?.studyId}/${urlExtractResult?.surveyId}/${urlExtractResult?.fullInfo}`;
      setUrl(result);
      console.log("🚀 ~ file: FieldUpload.tsx:107 ~ constgetFileMedia:any= ~ result:", result)
      // setValue(value);
      return result ?? '';
    } catch (error) {
      console.log(
        "🚀 ~ file: FieldUpload.tsx:54 ~ getFileMedia ~ error:",
        error
      );
      return ''
    }
  };
  function checkInputEquality(inputValue, sectionsArray) {
   console.log("🚀 ~ checkInputEquality ~ sectionsArray:", sectionsArray)
   console.log("🚀 ~ checkInputEquality ~ inputValue:", inputValue)
   
    return sectionsArray.some((section) => {
      console.log("🚀 ~ returnsectionsArray.some ~ section:", section)
      return (
        section?.conditionLogic?.conditions?.some(
          (condition) =>
            section?.status !== "INIT" && condition?.fieldToCompare === inputValue
        ) ||
        section?.fields.some((field) =>
          field?.conditionLogic?.conditions?.some(
            (condition) =>
              field?.value && condition?.fieldToCompare === inputValue
          )
        )
      );
    });
  }
  const { dropEffect, type, label, field_name, id, rules, ...data } = element;
  const [formData, setFormData] = useAtom(visteAtom);
  const [conditionalLogicAlert, setConditionalLogicAlert] = useState(false);
  const [payloadTarget, setPayloadTarget] = useState(false) as any;
  const [socket] = useAtom(socketAtom) as any;
  const [user] = useAtom<User | null>(userAtom);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const mapLabelToTranslation = (label) => {
    return translation && translation.map
      ? translation.map[label] || label
      : label;
  };

  const updateFiled = async (e) => {
    if (fieldOnSubmit) {
      console.log(
        "🚀 ~ file: FormElement.tsx:88 ~ updateFiled ~ formData:",
        formData
      );

      if (
        !isEvent &&
        checkInputEquality(e.fieldName, formData?.sections) &&
        element.value !== null
      ) {
        try {
          form.setFieldsValues({ [e.fieldName]: element.value });
          setConditionalLogicAlert(true);
          return;
        } catch (error) {
          console.log(
            "🚀 ~ file: FormElement.tsx:87 ~ updateFiled ~ error:",
            error
          );
        }
      }

      // const res = isEvent
      //   ? await updateEventDataPerField({ ...e, stepNbr: stepNbr }, formDataId)
      //   : null;
      if (isEvent) {
        socket.emit("updateEventFormFields", {
          eventId: formDataId,
          message: {
            ...e,
            stepNbr: stepNbr,
            sharedCalculVars: props.sharedCalculVars ?? false,
            sharedStatsVars: props.sharedStatsVars ?? false,
            eventId: formDataId,
          },
          userId: user?._id,
        });
      } else {
        socket.emit("updateFormPerField", {
          roomId: formDataId,
          message: {
            ...e,
            stepNbr: stepNbr,
            sharedCalculVars: props.sharedCalculVars ?? false,
            sharedStatsVars: props.sharedStatsVars ?? false,
          },
          userId: user?._id,
        });
      }

      // if (res) {
      props.setShouldUpdate((up) => !up);

      //makeToast("success", "Valeur soumise avec succès !");
      // }
    } else {
      return;
    }
  };

  const [selectedElm, setSelectedElm] = useState(
    props.selectedElm ? props.selectedElm : null
  );
  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", (data: { message: string }) => {
        // Update the receivedMessage state with the received message
        console.log("faz", data.message);
      });
    }
  }, []);
  useEffect(() => {
    setSelectedElm(props.selectedElm);
  }, [id]);

  const renderElement = () => {
    if(element && !(user?.type==='writer') &&
      !element?.content &&
      !element?.isVisible &&
      !isPreview)
      return <div className="flex flex-col w-full">
        <FormLabel fontSize={"16px"} htmlFor={id}>
        {translation ? mapLabelToTranslation(label) : label}
        </FormLabel>
       
        <p>{t("Survey.confidentiality")}</p>
      </div>
    switch (type) {
      case "Header":
        let htmlData: String = data.content;
        console.log(
          "🚀 ~ renderElement ~ htmlData:",
          ReactHtmlParser(htmlData)
        );
        htmlData = htmlData.replaceAll("&lt;", "<");
        htmlData = htmlData.replaceAll("&gt;", ">");
        return <h1 className="w-full">{ReactHtmlParser(htmlData)}</h1>;

      case "Paragraph":
        let htmlDataParagraph: String = data.content;
        htmlDataParagraph = htmlDataParagraph.replaceAll("&lt;", "<");
        htmlDataParagraph = htmlDataParagraph.replaceAll("&gt;", ">");
        return <p>{ReactHtmlParser(htmlDataParagraph)}</p>;
      case "LineBreak": {
        const { content, ...props } = data;
        let htmlDataLineBreak: String = content;
        htmlDataLineBreak = htmlDataLineBreak.replaceAll("&lt;", "<");
        htmlDataLineBreak = htmlDataLineBreak.replaceAll("&gt;", ">");
        return (
          <Stack direction="row" p={2}>
            <Divider variant="solid" orientation="horizontal" />
            <Center>{ReactHtmlParser(htmlDataLineBreak)}</Center>
            <Divider orientation="horizontal" />
          </Stack>
        );
      }
      case "Email":
        return (
          <FieldInput
            {...data}
            disabled={readOnly ? true : false}
            required={rules && rules[0] && rules[0].required ? true : false}
            value={props.value}
            element={element}
            form={form}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                    { rule: isEmail(), message: "Email non Valide" },
                  ]
                : [{ rule: isEmail(), message: "Email non Valide" }]
            }
          />
        );
      case "PhoneNumber":
        return (
          <FieldPhoneInput
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            type="text"
            value={props?.value}
            element={element}
            form={form}
            isPreview={isPreview}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            className="w-1/2 "
            //helper="hello"
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            validations={
              (rules || field_name === "label") && !readOnly
                ? validitionRules(rules)
                : []
            }
            {...data}
          />
        );

      case "Image": {
        if (data.src === "" || !data)
          data.src =
            "https://via.placeholder.com/728x90.png?text=Example+Image+";
            // let val = getFileMedia(data.src)
            // console.log("🚀 ~ renderElement ~ val:", val)
        return (
          <div className="m-4" style={{ textAlign: "center" }}>
            <img
              src={`${data.src}`}
              alt={data.alt}
            />
          </div>
        );
      }
      case "TextInput":
        console.log(
          "🚀 ~ file: FormElement.tsx:274 ~ renderElement ~ label:",
          element
        );
        return (
          <FieldInput
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            type="text"
            value={props?.value}
            element={element}
            form={form}
            isPreview={isPreview}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            className={`w-1/2 ${element.field_name}`}
            //helper="hello"
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            validations={
              (rules || field_name === "label") && !readOnly
                ? validitionRules(rules)
                : []
            }
            {...data}
          />
        );
      case "RepeaterField":
        return (
          <RepeaterField
            key={field_name}
            form={form}
            value={props?.value}
            withOption={
              selectedElm?.withOption ? selectedElm.withOption : false
            }
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            type="text"
            element={element}
            isPreview={isPreview}
            isEvent={isEvent}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            name={field_name}
            rules={readOnly ? [] : rules}
            className="w-1/2 "
            formDataId={formDataId}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            stepNbr={stepNbr}
            validations={
              (rules || field_name === "label") && !readOnly
                ? validitionRules(rules)
                : []
            }
            {...data}
          />
        );
      case "captcha":
        return (
          <FieldPickIdenticalImages
            mb="12"
            name={field_name}
            label="Champ d'images identiques"
            helper="Sélectionnez deux images identiques"
            validMessage="Parfait, les deux images sont identiques !"
            required="Vous devez sélectionner 2 images"
            validations={[
              {
                rule: (val) => (val || {}).selectedCount === 2,
                message: "Vous devez sélectionner une deuxième image",
              },
              {
                rule: (val) => (val || {}).isIdentical,
                message: "Les images ne sont pas identiques",
              },
            ]}
            options={randomPics}
          />
        );

      case "NumberInput":
        return (
          <FieldInput
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            value={props?.value}
            type="number"
            element={element}
            form={form}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            isPreview={isPreview}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules
                ? !readOnly
                  ? validitionRules(rules)
                  : []
                : [
                    {
                      rule: isNumber(),
                      message: "Nombre invalide",
                    },
                  ]
            }
            {...data}
          />
        );
      case "CalculatedInput":
        let dependencys: any[] = [];
        let copyConfig = Array.isArray(data?.calculationConfig) ? (data?.calculationConfig || []) : (Array.isArray(data?.calculationConfig?.calculationConfig) ? (data?.calculationConfig?.calculationConfig || []):[]);
        console.log("🚀 ~ renderElement ~ data:", data)
        copyConfig?.map((d) => {
          if (form && form.values) {
            if (form.values[`${d.value}`]) {
              dependencys.push({
                label: d.value,
                value: form.values[`${d.value}`],
              });
            }
          }
        });
        return (
          <CalculatedInput
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            dependencys={dependencys}
            value={props?.value}
            type="number"
            element={element}
            form={form}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            isPreview={isPreview}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules
                ? !readOnly
                  ? validitionRules(rules)
                  : []
                : [
                    {
                      rule: isNumber(),
                      message: "Nombre invalide",
                    },
                  ]
            }
            {...data}
          />
        );

      case "Dropdown":
        return (
          <FieldSelect
            label={translation ? mapLabelToTranslation(label) : label}
            key={field_name}
            name={field_name}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            value={props?.value}
            readOnly={readOnly}
            required={!readOnly && rules && rules[0].required ? true : false}
            validations={rules && !readOnly ? validitionRules(rules) : []}
            {...data}
          />
        );

      case "Checkboxes":
        return (
          <FieldCheckbox
            label={translation ? mapLabelToTranslation(label) : label}
            key={field_name}
            name={field_name}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            disabled={readOnly ? true : false}
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            validations={
              rules && !readOnly ? validitionRules(rules, null, true) : []
            }
            value={props?.value}
            isPreview={isPreview}
            {...data}
          />
        );
      case "RadioButtons":
        return (
          <FieldRadio
            label={translation ? mapLabelToTranslation(label) : label}
            key={field_name}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            name={field_name}
            disabled={readOnly ? true : false}
            validations={!readOnly && rules ? validitionRules(rules) : []}
            value={props?.value}
            isPreview={isPreview}
            // direction={"column"}
            {...data}
          />
        );
      case "Cities":
        return (
          <FieldRadio
            label={translation ? mapLabelToTranslation(label) : label}
            key={field_name}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            name={field_name}
            disabled={readOnly ? true : false}
            validations={!readOnly && rules ? validitionRules(rules) : []}
            value={props?.value}
            // direction={"column"}
            {...data}
          />
        );
      case "TableField":
        return (
          <TableField
            label={translation ? mapLabelToTranslation(label) : label}
            key={field_name}
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            name={field_name}
            disabled={readOnly ? true : false}
            validations={!readOnly && rules ? validitionRules(rules) : []}
            value={props?.value}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            // direction={"column"}
            {...data}
          />
        );
      case "DatePicker":
        //error
        return (
          <FieldInput
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            type="date"
            element={element}
            form={form}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            isPreview={isPreview}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              rules
                ? !readOnly
                  ? validitionRules(rules, t("missingWording.dateFormat"))
                  : []
                : []
            }
            format={data?.format}
            {...data}
          />
        );
      case "ident":
        return (
          <FullIdentity
            mb="12"
            name={field_name}
            label={translation ? mapLabelToTranslation(label) : label}
            rules={rules}
            helper="Renseignements personnels"
            {...data}
          />
        );

      case "TimePicker":
        return (
          <FieldInput
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            type="time"
            element={element}
            form={form}
            readOnly={readOnly}
            isPreview={isPreview}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={!readOnly && rules ? validitionRules(rules) : []}
            {...data}
          />
        );
      case "conditionLogic":
        return (
          <ConditionLogic
            key={field_name}
            name={field_name}
            disabled={readOnly ? true : false}
            value={props.value}
            {...data}
            selectedElm={selectedElm}
            elements={props.elements}
            form={form}
          />
        );
      case "childFieldsConfig":
        return (
          <ChildFieldsConfig
            key={field_name}
            name={field_name}
            disabled={readOnly ? true : false}
            value={props.value}
            {...data}
            selectedElm={selectedElm}
            elements={props.elements}
            form={form}
          />
        );
      case "TextArea":
        return (
          <FieldTextarea
            element={element}
            required={!readOnly && rules && rules[0].required ? true : false}
            form={form}
            value={props?.value}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            {...data}
            validations={!readOnly && rules ? validitionRules(rules) : []}
          />
        );
      case "Rating":
        return (
          <FieldRating
            element={element}
            required={!readOnly && rules && rules[0].required ? true : false}
            form={form}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            {...data}
            validations={!readOnly && rules ? validitionRules(rules) : []}
          />
        );
      case "Editor":
        return (
          <TextEditor
            el={selectedElm}
            defaultValue={props.value}
            name={field_name}
            {...data}
          />
        );
      case "Range":
        return (
          <FieldSlider
            required={
              !readOnly && rules && rules[0] && rules[0].required ? true : false
            }
            element={element}
            form={form}
            readOnly={readOnly}
            label={translation ? mapLabelToTranslation(label) : label}
            labelCol={{ className: "mx-2" }}
            onSumbit={async (e) => {
              setPayloadTarget(e);
              await updateFiled(e);
            }}
            key={field_name}
            name={field_name}
            rules={readOnly ? [] : rules}
            validations={
              !readOnly && rules && rules[0]?.required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
          />
        );

      case "Signature":
        return (
          <Signature
            readOnly={readOnly}
            disabled={readOnly ? true : false}
            key={field_name}
            name={field_name}
            label={translation ? mapLabelToTranslation(label) : label}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
          />
        );

      case "File":
        return (
          <FieldUpload
            disabled={readOnly ? true : false}
            label={translation ? mapLabelToTranslation(label) : label}
            isPreview={isPreview}
            key={field_name}
            name={field_name}
            readOnly={readOnly}
            isEpro={isEpro}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            formId={formDataId}
            stepNbr={stepNbr}
            {...data}
            listType="picture-card"
          >
            <div>
              <div style={{ marginTop: 8 }}>
                <i className="far fa-images"></i> &nbsp;Upload
              </div>
            </div>
          </FieldUpload>
        );
      case "Photo2":
        return (
          <FieldUploadBase64
            disabled={readOnly ? true : false}
            label={translation ? mapLabelToTranslation(label) : label}
            key={field_name}
            name={field_name}
            readOnly={readOnly}
            validations={
              rules && rules[0].required
                ? [
                    {
                      rule: isRequired(),
                      message: rules[0].message,
                    },
                  ]
                : []
            }
            {...data}
            listType="picture-card"
          >
            <div>
              <div style={{ marginTop: 8 }}>
                <i className="far fa-images"></i> &nbsp;Upload
              </div>
            </div>
          </FieldUploadBase64>
        );

      default:
        return <div>Not yet ⌛⌛....</div>;
    }
  };
  return (
    <>
      <Modal
        showModal={conditionalLogicAlert}
        className={"p-3 overflow-y-hidden"}
        closeModal={() => {
          setConditionalLogicAlert(false);
        }}
      >
        <Button
          status="icon-secondary"
          onClick={() => {
            setConditionalLogicAlert(false);
          }}
          className="absolute right-1 top-1"
        >
          <Close />
        </Button>
        <div className="bg-white mt-8 p-4 xs:w-auto " style={{}}>
          <span className="flex justify-center text-lg font-semibold ">
            {" "}
            {t("Survey.alertCondinal")}
          </span>
        </div>
        <div className="flex flex-row justify-between">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className="float-right mb-4 mx-2"
            onClick={() => {
              setConditionalLogicAlert(false);

              setPayloadTarget(null);
            }}
          >
            {t("Survey.Cancel")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className="float-left mb-4 mx-2"
            onClick={async () => {
              if (payloadTarget) {
                form.setFieldsValues({
                  [payloadTarget.fieldName]: payloadTarget.value,
                });

                const res = await updateFomDataPerField(
                  {
                    isValid: payloadTarget.isValid,
                    value: payloadTarget.value,
                    fieldName: payloadTarget.fieldName,
                    stepNbr: stepNbr,
                  },
                  formDataId
                );
                socket.emit("updateFormPerField", {
                  roomId: formDataId,
                  message: {
                    isValid: payloadTarget.isValid,
                    value: payloadTarget.value,
                    fieldName: payloadTarget.fieldName,
                    stepNbr: stepNbr,
                    sharedCalculVars: props.sharedCalculVars ?? false,
                    sharedStatsVars: props.sharedStatsVars ?? false,
                  },
                  userId: user?._id,
                });
                if (res) {
                  setFormData(res?.newForm);
                }
                setConditionalLogicAlert(false);
                setPayloadTarget(null);
              }
            }}
          >
            {t("Survey.Confirm")}
          </Button>
        </div>
      </Modal>
      {renderElement()}
    </>
  );
};

export default FormElement;
