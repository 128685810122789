import { useState, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAtom } from "jotai";
import { Socket, io } from "socket.io-client";
import {
  Flex,
  Stack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import {
  EditIcon,
  ViewIcon,
  ArrowRightIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import { BiSort } from "react-icons/bi";
//Components
import ButtonUI from "../../ButtonUI";
import ModalUI from "../../ModalUI";
import FormRenderer from "../FormRenderer";
import FormViewer from "../FormViewer";
import Toolbar from "./ToolBar";
import StepperWrapper from "./StepperWrapper";
import { MultiStepsLayout, PageHeader } from "./Lyaout";
import { ControlledTabs } from "./DropZone";
import { Formiz, FormizStep } from "./StepperWrapper/Formiz";
//Hooks & Helpers
// import useDrivejs from "../../../hooks/driverjs/index";
import { useForm } from "../../../hooks/FormGeneratorHooks";
import { resolver } from "../../../helper/FormGeneratorHelper/conditionalLogic";
import { loadLocaleData } from "../../../helper/FormGeneratorHelper/Utils";
//Atoms
import { stepAtom } from "../../../state/currentStep";
import { formAtom } from "../../../state/formAtom";
//Assets
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import { useTranslation } from "react-i18next";
import SortableListSection from "./OrderEditionSection";
import Tooltip from "../../toolTip";
import makeToast from "../../Snackbar";
import { socketAtom } from "../../../state/socket";
import { BASE_URL } from "../../../config/api";
import {
  closePageSettingTutoAtom,
  pageSettingTutoAtom,
} from "../../../state/tutorialMode";

const FormBuilder = ({
  fields,
  onUpdate,
  fieldProps,
  onSave,
  formProps,
  showPreviewTab,
  formSetup,
  simulation,
  surveyId,
  translation,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedSec, setSelectedSec] = useState() as any;
  const [popUp, setPopUp] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formConfig, setFormConfig] = useAtom(formAtom);
  const [openPageSettingTuto] = useAtom(pageSettingTutoAtom);
  const [closePageSettingTuto] = useAtom(closePageSettingTutoAtom);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const topRef = useRef<HTMLDivElement>(null);
  const [, setStAtom] = useAtom(stepAtom);
  const form = useForm();
  const [preview, setPreview] = useState(false);
  const messages = loadLocaleData();
  const { t } = useTranslation() as any;
  // const {} = useDrivejs({ stepNumber: 2 });
  useEffect(() => {
    setFormConfig(formSetup);
  }, [formSetup]);

  const updateFormElement = (elem: any) => {
    let newSection = formConfig.sections;
    newSection[currentIndex].task_data = [...elem];
    setFormConfig({ ...formConfig, sections: newSection });
    onUpdate?.({ ...formConfig, sections: newSection });
  };

  const getAllFields = (sectionOrdre) => {
    let allFields = [] as any[];
    formConfig.sections.map((section) => {
      if (section.stepNbr < sectionOrdre)
        section.task_data.map((f) => {
          allFields.push(f);
        });
    });
    return allFields;
  };
  const addStep = () => {
    let formConfigx = {
      task_data: [],
      stepNbr: formConfig.sections.length,
      sectionName:
        "Page " +
        (formConfig.sections.length ? formConfig.sections.length + 1 : "0"),
    };
    let steps = formConfig.sections ? formConfig.sections : [];
    let data = {
      name: formConfig.name || "",
      description: formConfig.description || "",
      sections: [...steps, formConfigx],
    };
    setFormConfig(data);
    onUpdate?.(data);
  };
  const editStepConfig = (newConfig, index) => {
    let copy = formConfig;
    copy.sections[index].sectionName = newConfig.name;

    if (
      (newConfig.conditionLogic && newConfig.conditionLogic.status == false) ||
      (newConfig.conditionLogic.conditions &&
        newConfig.conditionLogic.conditions.length == 0) ||
      !newConfig.conditionLogic.conditions
    ) {
      copy.sections[index].conditionLogic = {
        status: false,
        action: "S",
        operator: "all",
        conditions: [],
      };
    } else {
      copy.sections[index].conditionLogic = newConfig.conditionLogic;
    }

    setFormConfig(copy);
    onUpdate?.(copy);
  };
  const editStepOrder = (newConfig) => {
    let copy = formConfig;
    copy.sections = newConfig;

    setFormConfig(copy);
    onUpdate?.(copy);
    makeToast("success", t("missingWording.orderSectionSucess"));
  };
  const removeStep = () => {
    const index = formConfig.sections.indexOf(selectedSec);
    if (index == 0 && formConfig.sections.length == 1) {
    } else {
      let newarray = formConfig.sections.filter(function (element) {
        return element != selectedSec;
      });
      newarray.forEach((element, k) => {
        element.stepNbr = k;
      });
      setCurrentIndex(0);
      setFormConfig({ ...formConfig, sections: newarray });
      onUpdate?.({ ...formConfig, sections: newarray });
    }
  };
  const handleSubmit = (values) => {
    setPreview(true);
  };

  useEffect(() => {
    if (!socket) {
      const mySocket: Socket = io(BASE_URL, {
        // forceNew: true,
        reconnectionAttempts: 100,
        timeout: 10000,
        transports: ["websocket"],
        auth: {
          token: localStorage.getItem("token"),
        },
      });

      setSocket(mySocket);
    } else {
      if (!socket.connected) socket.connect();

      return () => {
        if (socket) {
          socket.disconnect();
          socket.removeAllListeners("");
          socket.close();
          console.log("[DEBUG] Socket was closed!");
        }
      };
    }
  }, [socket]);
  useEffect(() => {
    if (openPageSettingTuto) {
      setCurrentIndex(1);
      setPopUp(true);
    }
    if (closePageSettingTuto) {
      setPopUp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPageSettingTuto, closePageSettingTuto]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        ref={topRef}
        className="layout-doctor relative "
        style={{
          height: "100%",
          display: "flex",
          alignItems: "stretch",
          borderRadius: "10px",
        }}
      >
        {process.env.NODE_ENV === "development" && (
          <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 fixed bottom-0 left-0 z-50 ">
            <ButtonUI
              status="primary"
              // onClick={() => socket.emit("resetChallenge3", {})}
              className="text-xs px-2 py-1"
            >
              Start Tour
            </ButtonUI>
          </div>
        )}
        <Flex
          justifyContent={"flex-start"}
          px="6"
          pt="8"
          className="absolute z-40 -top-10 right-0"
        >
          <Stack direction="row" align="center" mb="1">
            <ButtonUI
              width="130px"
              height="47px"
              status="primary"
              className="p-2 saveFormTuto"
              onClick={() => onSave?.(formConfig)}
            >
              <CheckIcon color={"gray-50"} mx={1} size="14px" opacity={"0.8"} />
              {t("Survey.Save")}
            </ButtonUI>
            <ButtonUI
              status={"secondary"}
              height="47px"
              width="130px"
              className="p-2 mx-1 previewFormTuto"
              onClick={() => setShowPreview(!showPreview)}
            >
              {showPreview ? (
                <EditIcon size="14px" opacity={"0.8"} />
              ) : (
                <ViewIcon size="14px" opacity={"0.8"} />
              )}{" "}
              {showPreview ? t("Survey.Edit") : t("Survey.Preview")}
            </ButtonUI>
          </Stack>
        </Flex>
        {!showPreview ? (
          <>
            <div
              className="  bg-white flex shadow-md  py-4 rounded-lg px-4"
              style={{ width: "100%" }}
            >
              <div
                className="w-1/4  py-4 px-4 rounded-lg mt-12 "
                id="toolbarForm"
              >
                <Toolbar
                  messages={messages}
                  fieldProps={fieldProps}
                  fields={undefined}
                />
              </div>

              <div className=" w-3/4 flex flex-row ">
                <>
                  {" "}
                  {ControlledTabs(
                    currentIndex,
                    setStAtom,
                    setCurrentIndex,
                    formConfig,
                    setPopUp,
                    setConfirmModal,
                    setSelectedSec,
                    addStep,
                    formProps,
                    updateFormElement,
                    removeStep,
                    confirmModal,
                    editStepConfig,
                    getAllFields,
                    popUp,
                    t,
                    surveyId,
                    translation,
                    openPageSettingTuto,
                    closePageSettingTuto
                  )}
                </>
                <div className="justify-start items-center w-16">
                  {" "}
                  <Tooltip
                    position="top"
                    tooltipText={`${t("missingWording.editPageOrder")}`}
                  >
                    <ButtonUI
                      status="icon-secondary"
                      onClick={() => setOpenOrderModal(true)}
                      className="setPageOrder"
                    >
                      <BiSort color="#4565f6" size={28} />
                    </ButtonUI>
                  </Tooltip>
                </div>
              </div>
              <Modal
                key={`${currentIndex}`}
                isOpen={openOrderModal}
                onClose={() => setOpenOrderModal(false)}
                size="2xl"
              >
                <ModalOverlay />
                <ModalContent bg={"transparent"}>
                  <div className="rounded-2xl bg-white">
                    <ModalHeader>{t("missingWording.orderPage")}</ModalHeader>
                    <ButtonUI
                      status="icon-secondary"
                      onClick={() => setOpenOrderModal(false)}
                      className="absolute right-1 top-1 "
                      type="button"
                    >
                      <Close />
                    </ButtonUI>
                    <ModalBody pb={6}>
                      <SortableListSection
                        list={[...formConfig.sections]}
                        editStepOrder={editStepOrder}
                        closeModal={() => {
                          setOpenOrderModal(false);
                        }}
                      />
                    </ModalBody>
                  </div>
                </ModalContent>
              </Modal>
            </div>
          </>
        ) : (
          <Formiz connect={form} onValidSubmit={handleSubmit}>
            <MultiStepsLayout submitLabel={t("missingWording.submitLabel")}>
              <PageHeader setShouldUpdate={undefined} isPreview={true}>
                <div className="flex flex-col mb-2">
                  <p>{formConfig.name ? formConfig.name : ""}</p>
                  <p className="text-xs max-w-xl text-gray-500">{formConfig?.description}</p>
                </div>
              </PageHeader>

              <div className="border  shadow-lg  p-4 w-full rounded-lg mb-4 ">
                <StepperWrapper title="Dots stepper" dir="row"></StepperWrapper>

                {formConfig.type === "Simulation" ? (
                  <>
                    {formConfig.sections[0].task_data.map((el, i) => {
                      return (
                        resolver(el.conditionLogic, form.values) && (
                          <FormizStep
                            label={el.label}
                            name={`${el.field_name}`}
                          >
                            <FormRenderer
                              key={i}
                              elements={[el]}
                              values={form.values}
                              form={form}
                              isPreview={true}
                              surveyId={surveyId}
                            ></FormRenderer>
                          </FormizStep>
                        )
                      );
                    })}
                  </>
                ) : (
                  formConfig.sections.map((step, i) => {
                    return (
                      <FormizStep
                        label={step.sectionName}
                        name={`${step.sectionName + "-" + step.stepNbr}`}
                        isEnabled={
                          step.conditionLogic && step.conditionLogic.status
                            ? resolver(step.conditionLogic, form.values)
                            : true
                        }
                      >
                        <FormRenderer
                          key={i}
                          elements={step.task_data}
                          values={form.values}
                          form={form}
                          isPreview={true}
                        ></FormRenderer>
                      </FormizStep>
                    );
                  })
                )}
              </div>
            </MultiStepsLayout>
            <ModalUI
              className="mb-8 w-64"
              showModal={preview}
              closeModal={() => setPreview(false)}
            >
              <div className=" bg-white  p-12 mt-4   ">
                <ButtonUI
                  status="icon-secondary"
                  onClick={() => setPreview(false)}
                  className="absolute right-1 top-1 "
                  type="button"
                >
                  <Close />
                </ButtonUI>
                <h1 className="flexCenter text-3xl font-bold  rounded-t-lg p-4 ">
                  {t("missingWording.dit2")}
                </h1>

                {formConfig?.sections.map((s) => (
                  <FormViewer elements={s.task_data} values={form.values} />
                ))}
              </div>
            </ModalUI>
          </Formiz>
        )}
      </div>
    </DndProvider>
  );
};

export default FormBuilder;
