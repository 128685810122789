import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFilter } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { getAllEvents } from "../../api/study";
import EventTable from "../../components/EventTab";
import SelectComp from "../../components/Select";
import { userAtom } from "../../state/auth";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import "./styles.scss";
import { testModeAtom } from "../../state/testModeAtom";
import TestModeTogButton from "../../components/TestModeShared";
import { studyWriterConfigAtom } from "../../state/studyWriterConfig";

export const Events = ({}) => {
  const params = useParams() as any;
  const { studyId } = params;
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [eventsList, setEventsList] = useState<any[]>([]);
  const [typeQuery, setTypeQuery] = useState("") as any;
  const [filterdEventsList, setFilterdEventsList] = useState<any[]>([]);
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );

  const fetchListEvents = async () => {
    try {
      let res: any = [];
      setLoading(true);
      switch (user?.type) {
        default:
          res = await getAllEvents(studyId, testMode);
          if (res && res.events) {
            setEventsList(res.events);
            setLoading(false);
          }
          break;
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:22 ~ fetchListEvents ~ error", error);
    }
  };
  useEffect(() => {
    if (studyId) setStudySelectedId(studyId);
    fetchListEvents();
  }, [shouldUpdate, setShouldUpdate, testMode]);
  const memoizedQueriesList = useMemo(() => eventsList, [eventsList]);

  useEffect(() => {
    let newList: any[];
    switch (typeQuery) {
      case "CALL":
        newList = memoizedQueriesList.filter((q) => q.type === "CALL");
        setFilterdEventsList(newList);
        break;
      case "ADVERSE":
        newList = memoizedQueriesList.filter((q) => q.type === "ADVERSE");
        setFilterdEventsList(newList);
        break;

      case "ALL":
        newList = memoizedQueriesList;
        setFilterdEventsList(newList);
        break;
      default:
        newList = memoizedQueriesList;
        setFilterdEventsList(newList);
        break;
    }
  }, [setTypeQuery, typeQuery]);

  return (
    <>
      <header className="flex justify-between items-center w-full flex-wrap ">
        <div className="title-container">
          <h1 className="pageTitle">{t("missingWording.events")}</h1>
        </div>
      </header>
      {studyWriterConfig?.studyId.status === "END" && (
        <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2  ">
          <span className="warn warning ">
            ⚠️{t("missingWording.bannerEnd")}.
          </span>
        </div>
      )}
      <div className="flex justify-between py-3 pl-2">
        <div className="flex flex-row items-center relative max-w-xs mt-4">
          <p className="mr-4">Filtrer</p>
          <SelectComp
            value={typeQuery}
            options={[
              {
                label: t("missingWording.all"),
                value: "ALL",
              },
              {
                label: t("missingWording.adverseevents"),
                value: "ADVERSE",
              },
              {
                label: t("missingWording.phoneCallEvent"),
                value: "CALL",
              },
            ]}
            onChange={(e) => setTypeQuery(e.target.value)}
            className="w-full eventTypeList"
            style={{ height: "38px", width: "230px" }}
            setText={false}
          />
          {/* <div className="absolute inset-y-0 right-8 flex items-center pl-4 pointer-events-none">
            <AiOutlineFilter />
          </div> */}
        </div>
        <TestModeTogButton />
      </div>

      <div className="mt-15">
        <EventTable
          data={typeQuery ? filterdEventsList : memoizedQueriesList}
          loading={loading}
          setShouldUpdate={setShouldUpdate}
          setTypeQuery={setTypeQuery}
        />
      </div>
    </>
  );
};

export default Events;
