import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBarARC from "../components/SideBarARC";

import "./styles-layout-doctor.scss";

const LayoutARC = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if ( location.pathname.slice(0, 7)==="/study/" || location.pathname === "/firstLogin")
    setShowSideBar(false);

    else setShowSideBar(true);

  }, [location.pathname]);



  return (
    <div className="layout-doctor flex">
      {showSideBar && <SideBarARC />}
      <div className="w-full md:pl-4">{children}</div>
    </div>
  );
};

export default LayoutARC;
