import { useTranslation } from "react-i18next";
import { IValues } from ".";
import Input from "../../../components/FloatInput";
import SelectComp from "../../../components/Select";

type Props = {
  handleChange: React.ChangeEvent<any> | any;
  values: IValues | any;
  errors: any;
};
export const AddOrganisationForm: React.FC<Props | any> = ({
  handleChange,
  values,
  errors,
}) => {
  const { t } = useTranslation() as any;

  return (
    <>
      <div className="flex flex-col">
        <Input
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.NameOrg")}
          name="name"
          onChange={handleChange}
          error={errors.name}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.name}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.DescOrg")}
          name="description"
          onChange={handleChange}
          error={errors.description}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.description}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.AdressOrg")}
          name="adressOrganisation"
          onChange={handleChange}
          error={errors.adressOrganisation}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.adressOrganisation}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.CodeOrg")}
          name="postalCodeOrganisation"
          onChange={handleChange}
          error={errors.postalCodeOrganisation}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.postalCodeOrganisation}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.VilleOrg")}
          name="villeOrganisation"
          onChange={handleChange}
          error={errors.villeOrganisation}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.villeOrganisation}
        </span>
      </div>
      <div className="flex flex-col">
        <SelectComp
          id="paysOrganisation"
          // error={errors.country}
          value={values.pays}
          options={[
            {
              label: t("missingWording.france"),
              value: t("missingWording.france"),
            },
            {
              label: t("missingWording.tunisia"),
              value: t("missingWording.tunisia"),
            },
          ]}
          onChange={handleChange}
          text={t("Organisation.PaysOrg")}
          className="w-full mb-2.5"
          error={errors.pays}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.pays}
        </span>
      </div>
    </>
  );
};

export default AddOrganisationForm;
