import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  IconButton,
  InputGroup,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import ButtonUI from "../../../ButtonUI";
import Spinner from "../../../Spinner";
import { FieldInput } from "../../Elements/FieldInput";
import { FieldSelect } from "../../Elements/FieldSelect";
import { SwitchInput } from "../../Elements/FieldSwitcher";
import { FormGroup } from "../../Elements/FormGroup";

const ChildFieldsConfig = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const { t } = useTranslation();

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const suppottedField = [
    {
      label: `${t("missingWording.fieldConfig_number")}`,
      value: "number",
    },
    {
      label: `${t("missingWording.fieldConfig_text")}`,
      value: "text",
    },
    {
      label:`${t("missingWording.fieldConfig_date")}`,
      value: "date",
    },
    {
      label: `${t("missingWording.fieldConfig_time")}`,
      value: "time",
    },
    {
      label: `${t("missingWording.radio")}`,
      value: "radio",
    },
    {
      label: `${t("missingWording.checkBox")}`,
      value: "checkBox",
    },
  ];
  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };
  const collection = useRepeater({
    name: "childFieldsConfig.fields",
    form: props.form,
    initialValues: props.value.fields,
  });

  return (
    <>
      {value ? (
        <>
          <FormGroup {...formGroupProps}>
            <InputGroup name="childFieldsConfig">
              <VStack
                spacing={4}
                align="start"
                className="border p-4 rounded-lg w-full "
              >
                <Grid
                  width={"60%"}
                  templateColumns="repeat(2, 1fr)"
                  alignItems={"baseline"}
                  gap={1}
                >
                  <GridItem w="320px" h="16">
                    {" "}
                    <span style={{ fontSize: "16px" }} className="font-bold ">
                      {t("missingWording.REP")}
                    </span>
                  </GridItem>
                </Grid>
                {true && true && (
                  <>
                    <Grid
                      templateColumns="repeat(3, 1fr)"
                      rowGap={3}
                      columnGap={0.5}
                      className="text-xs"
                    >
                      {collection.keys.map((key, index) => (
                        <>
                          <GridItem>
                            <Box flex="1">
                              <FieldInput
                                name={`childFieldsConfig.fields[${index}].name`}
                                required
                                value={props.value.fields[index]?.["name"]}
                                isPreview={true}
                                placeholder={t(
                                  "missingWording.fieldConfig_fname"
                                )}
                                label={t("missingWording.fieldConfig_fname")}
                              />
                            </Box>{" "}
                          </GridItem>

                          <GridItem>
                            <Box flex="1" ml={4}>
                              <FieldSelect
                                name={`childFieldsConfig.fields[${index}].type`}
                                required
                                placeholder={t(
                                  "missingWording.fieldConfig_ftype"
                                )}
                                value={props.value.fields[index]?.["type"]}
                                label={t("missingWording.fieldConfig_ftype")}
                                options={suppottedField}
                              />
                            </Box>
                          </GridItem>
                          <GridItem className="flex items-center     ">
                            <Box flex="1">
                              <GridItem className="  flex flex-col items-center  justify-center gap-y-1   ">
                                <label className="text-sm text-center ">
                                  {t("missingWording.fieldConfig_required")}
                                </label>
                                <GridItem>
                                  <SwitchInput
                                    value={
                                      props.value.fields[index]?.["required"]
                                    }
                                    name={`childFieldsConfig.fields[${index}].required`}
                                  />
                                </GridItem>
                              </GridItem>
                            </Box>
                            <Box mx={0.5}>
                              <IconButton
                                size="sm"
                                aria-label="Delete"
                                icon={<DeleteIcon />}
                                onClick={() => {
                                  collection.remove(index);

                                  setValue((current) => {
                                    return {
                                      ...current,
                                      fields: current.fields.splice(index, 1),
                                    };
                                  });
                                }}
                              />
                            </Box>
                          </GridItem>
                        </>
                      ))}

                      <Box>
                        {collection.length <= 20 && (
                          <>
                            {true && (
                              <ButtonUI
                                status="secondary"
                                className="p-2 text-xs w-48"
                                type="button"
                                onClick={() => collection.append()}
                              >
                                {" "}
                                {t("missingWording.addField")}
                              </ButtonUI>
                            )}
                          </>
                        )}
                      </Box>
                    </Grid>
                  </>
                )}
              </VStack>
            </InputGroup>
          </FormGroup>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ChildFieldsConfig;
