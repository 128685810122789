import { Switch, Route, useLocation } from "react-router-dom";

import { User, UserRole } from "../../types/user";
import routes from "../../routes";
import LayoutCRO from "../../Layouts/LayoutCro";
import LayoutWRITER from "../../Layouts/LayoutWriter";
import LayoutPARTICIPANT from "../../Layouts/LayoutParticipant";
import LayoutARC from "../../Layouts/LayoutArc";
import SubscriptionModal from "../../pages/DashboardCRO/Modals/SubscriptionModal";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { isEConsultPath } from "../../helper";

type Props = {
  role: UserRole;
  subscription: boolean;
};
const Navigator: React.FC<Props> = ({ role, subscription }) => {

  console.log("🚀 ~ file: index.tsx:18 ~ subscription:", subscription);
  const [modalSubcription, setModalSubcription] = useState<boolean>(true);
  const [user] = useAtom<User | any>(userAtom) as any;
  const location = useLocation();
  useEffect(() => {
    if (isEConsultPath(location.pathname) && !user && role === UserRole.Guest)
      localStorage.setItem("econsultPath", location.pathname);
  }, [location]);
  const Routes = () =>
    modalSubcription &&
    subscription &&
    user?.tenantId !== "cro" &&
    role === "cro" ? (
      <SubscriptionModal setOpenModal={setModalSubcription} />
    ) : (
      <Switch>
        {routes(role).map((routeProps, index) => (
          <Route key={index} {...routeProps} />
        ))}
      </Switch>
    );

  switch (role) {
    case UserRole.Guest:
      return Routes();
    case UserRole.cro:
      return <LayoutCRO>{Routes()}</LayoutCRO>;
    case UserRole.sponsor:
      return <LayoutCRO>{Routes()}</LayoutCRO>;
    case UserRole.writer:
      return <LayoutWRITER>{Routes()}</LayoutWRITER>;
    case UserRole.arc:
      return <LayoutARC>{Routes()}</LayoutARC>;
    case UserRole.participant:
      return <LayoutPARTICIPANT>{Routes()}</LayoutPARTICIPANT>;
    default:
      return Routes();
  }
};

export default Navigator;
