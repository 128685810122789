import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { SelectedStudyAtom } from "../../state/selectedStudyAtom";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import "./styles.scss";
import Input from "../../components/FloatInput";
import Fuse from "fuse.js";
import Tooltip from "../../components/toolTip";
import { AiFillLock } from "react-icons/ai";
import Pagination from "../../components/PaginationTable";
import { getStudiesForParticipant } from "../../api/study";
import history from "../../history";
import { studyParticipantConfigAtom } from "../../state/studyParticipantConfig";
import { isEConsultPath } from "../../helper";

const DashboardPARTICIPANT = () => {
  const [user] = useAtom(userAtom);
  const [, setPath] = useAtom(ActiveRouteAtom);

  const typingTimeoutRef = useRef(null) as any;
  const [studiesConfigList, setStudiesConfigList] = useState([]) as any[];
  const [searchValue, setSearchValue] = useState("");
  const [, setSelectedStudyBoolean] = useAtom(SelectedStudyAtom);
  const [step, setStep] = useState(0);
  const [dateNow] = useState(moment());
  const [page, setPage] = useState(1);
  const [, setStudyParticipantConfig] = useAtom(studyParticipantConfigAtom);
  const { t } = useTranslation() as any;
  const [totalResult, setTotalResult] = useState(0);
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [textSearch, setTextSearch] = useState("");

  const menu = [t("SideBar.ActiveStudy")];
  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    const eConsultPath = localStorage.getItem("econsultPath")
    if(eConsultPath && isEConsultPath(eConsultPath) ){
      history.push(eConsultPath)
      localStorage.removeItem("econsultPath")
    }
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);

  const fetchStudies = async () => {
    const res = await getStudiesForParticipant(
      user?.email,
      page,
      8,
      textSearch
    );
    setStudiesConfigList(res?.participantPopulated?.studies);
    setTotalResult(res?.participantPopulated?.count);
    return res;
  };

  useEffect(() => {
    setPath("/");
    setStudySelectedId(null);
    fetchStudies();
  }, [page, textSearch]);

  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };

  const renderDivs = (datahlist) => {
    return datahlist && datahlist.length > 0 ? (
      <div>
        <div className="flex justify-between cursor-pointer relative xs:text-xs sm:text-sm md:text-sm lg:text-md mb-4  font-semibold">
          <div className="flex items-center w-1/3">
            <div>
              <div>
                <p className=" xs:px-4 xs:py-2 md:px-6 md:py-4 capitalize font-bold"></p>
              </div>
            </div>
            <div className="capitalize xs:ml-4 md:ml-12 cursor-pointer">
              {t("missingWording.Sname")}
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3">
            <p>{t("missingWording.Cname")}</p>
          </div>
          <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3">
            <p> {t("missingWording.MAJ")}</p>
          </div>
        </div>
        {datahlist?.map((p) => {
          return (
            <>
              <div
                className="flex justify-between cursor-pointer relative xs:text-xs sm:text-sm md:text-sm lg:text-md"
                onClick={() => {
                  history.push(`/eproForms/${p?.studyId._id}`);
                  setSelectedStudyBoolean(true);
                  setStudyParticipantConfig(p);
                  setStudySelectedId(p?.studyId?._id);
                }}
              >
                <div className="flex items-center w-1/3">
                  <div>
                    <div>
                      <p className="boxName xs:px-4 xs:py-2 md:px-6 md:py-4 capitalize font-bold">
                        {p?.studyId?.name[0]}
                      </p>
                    </div>
                  </div>

                  <div className="capitalize xs:ml-4 md:ml-12 cursor-pointer relative flex">
                    {p?.studyId?.name}
                    {p?.studyId?.status === "END" && (
                      <div className="">
                        <Tooltip
                          tooltipText={`${t("missingWording.toolTipStudyEnd")}`}
                          position={"left"}
                          clsText={{ width: "240px" }}
                        >
                          <AiFillLock
                            color="#000"
                            className="mx-2 cursor-pointer w-5 h-5"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3">
                  <p>{p?.centerId?.name}</p>
                </div>
                <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3">
                  <p>
                    {" "}
                    {moment(p?.createdAt).format("DD-MM-YYYY") +
                      t("DashboardCro.at") +
                      moment(p?.createdAt).format("HH:mm")}
                  </p>
                </div>
              </div>
              <div className="line"></div>
            </>
          );
        })}
        <div>
          {totalResult > 8 && (
            <Pagination
              entriesName={`${t("missingWording.paginationS")}`}
              totalResults={totalResult}
              resultsPerPage={8}
              currentPage={page}
              onPageChange={function (page: number): void {
                setPage(page);
              }}
            />
          )}
        </div>
      </div>
    ) : (
      <p className="flexCenter mt-16 menuInput">
        {t("missingWording.Loading")}...
      </p>
    );
  };
  return (
    <>
      <div className="dashboard-doctor w-full">
        <header className="flex justify-between  pl-4 lg:pl-0 md:items-center w-full flex-col lg:flex-row">
          <div className="mb-4 lg:mb-auto relative">
            <h1>{t("SideBar.title")}</h1>{" "}
            <h3 className="card-subtitle">
              {t("DashboardCro.WeAreOn")}{" "}
              {dateNow.format("DD-MM-YYYY") +
                t("DashboardCro.at") +
                dateNow.format("HH:mm")}
            </h3>
            <div className="relative max-w-xs">
              <input
                type="text"
                name="hs-table-search"
                id="hs-table-search"
                onChange={onSearch}
                value={searchValue}
                className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
                placeholder={t("SideBar.FilterStudy")}
                style={{ border: "none" }}
                onKeyDown={handleKeyDown}
              />

              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <svg
                  className="h-3.5 w-3.5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
            </div>
          </div>
        </header>
        <div className="flex mt-16">
          <div className="menu">
            {menu.map((m, idx) => {
              return (
                <div onClick={() => setStep(idx)} className="cursor-pointer">
                  <p className={`${step === idx ? "selected" : "unselected"}`}>
                    <span className="capitalize">{m}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {
          <div className="mt-8">
            {studiesConfigList && studiesConfigList?.length > 0 ? (
              renderDivs(studiesConfigList)
            ) : (
              <p>no study</p>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default DashboardPARTICIPANT;
