import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex, FormLabel, IconButton, Stack } from "@chakra-ui/react";
import { t } from "i18next";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import Button from "../../../ButtonUI";
import Input from "../../../FloatInput/useFieldInput";
const DEFAULT_VALUES = {
  collection: [{ label: "Default label" }],
};

export const RepeaterGroups = (props) => {
  const { disabled } = props;
  console.log(
    "🚀 ~ file: RepeaterGroups.tsx:18 ~ RepeaterGroups ~ props.value:",
    props.value
  );

  const collection = useRepeater({
    name: "groups",
    form: props.form,
    initialValues: props.value.map((p: any) => p),
  });

  return (
    <Stack
      justifyContent={"flex-start "}
      spacing={2}
      align="start"
      className="mb-4 w-2/3 "
    >
      <Flex
      className=" w-full "
        // align="center"
        justify="space-between"
        flexDirection="column"
        mb={4}
      >
        <h2 className="mb-6">{t("missingWording.groupListTitle")}</h2>
        {collection.length <= 20 && (
          <div className="w-full flex flex-row justify-center">
            <Button
              status="secondary"
              type="button"
              className="md:w-48 md:text-sm sm:px-4  xs:w-16 xs:text-xs xs:truncate xs:px-0 "
              height="40px"
              onClick={() => collection.append()}
              disabled={disabled}
            >
              {t("missingWording.addGrp")}
            </Button>
          </div>
        )}
        <FormLabel className="cssFormLabel" mt={6}>
          <span style={{ fontSize: "16px" }} className="font-bold">
            {t("missingWording.grp")}
          </span>
        </FormLabel>
      </Flex>

      <Box
        className="h-80 overflow-y-auto px-4"
      >
        {collection.keys.map((key, index) => (
          <Stack
            key={key}
            direction="row"
            spacing="4"
            // mb="6"
            data-test={`repeater-item[${index}]`}
            className="w-full"
          
          >
            <Box flex="1" className="w-full">
              <Input
                type="text"
                name={`groups[${index}]`}
                disabled={disabled}
                // value={groupNames[index] || ""}
                // onChange={(e) => props.form.setFieldsValues({ [`groups[${index}]`]: e.target.value })}
                labelText={`${t("missingWording.Grp") + " " + (index + 1)}`}
                className="text-sm"
                style={{ maxHeight: "40px"}}
                form={props.form}
              />
            </Box>

            <Box>
              <IconButton
                disabled={disabled}
                aria-label="Delete"
                background={"#ffffff"}
                className=" my-0.5 "
                icon={<DeleteIcon />}
                onClick={() => collection.remove(index)}
              />
            </Box>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};
