import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import Icon from "../../components/Icon";
import "./styles.scss";
import Modal from "../../components/ModalUI";
import MyModal from "../../components/New-Modal";
import ModifyDataUserProfile from "./modal/ModifyDataUserProfile";
import moment from "moment";
import {
  activateMfa,
  associateUserToAuthenticatorSoftware,
  updateUserLang,
  verifySoftwareToken,
  getUserInfo,
} from "../../api/auth";
import makeToast from "../../components/Snackbar";
import Invoices from "./Invoices";
import cashIcon from "../../assets/images/espece.png";
import padLock from "../../assets/images/pad-lock.png";
import QRCode from "qrcode.react";
import Button from "../../components/ButtonUI";
import Spinner from "../../components/Spinner";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import Input from "../../components/FloatInput";
import { CgOrganisation } from "react-icons/cg";

import WorkSpaceSelection from "../WorkSpaceSelection/WorkSpaceSelection";

const lngs = {
  en: { nativeName: "English" },
  fr: { nativeName: "Français" },
};

const Index = (props) => {
  const { t, i18n } = useTranslation() as any;
  const [user, setUser] = useAtom<User | null>(userAtom) as any;
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [loading, setLoading] = useState(true);
  const [userCode, setUserCode] = useState("");
  const [userInfo, setUserInfo] = useState({}) as any;

  const [mfaEnabled, setMfaEnabled] = useState(false);

  const getUserData = async () => {
    const email = user?.email;
    try {
      const response = await getUserInfo({ email });
      if (response) {
        setUserInfo(response.result);
        setLoading(false);
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  const verifyUserSoftware = async () => {
    try {
      const verifyUserResponse = await verifySoftwareToken({ userCode });
      if (verifyUserResponse.status === "success") {
        const enableMfa = mfaEnabled;
        const activateMfaResponse = await activateMfa({ enableMfa });
        if (activateMfaResponse.status === "success") {
          makeToast("success", t("Profile.mfaEnabledSucc"));
          setShowQrCodeModal(false);
        } else {
          makeToast("error", t("Profile.mfaEnabledErr"));
        }
      } else {
        makeToast("error", t("Profile.mfaEnabledErr"));
      }
    } catch (error) {
      makeToast("error", t("Profile.mfaEnabledErr"));
    }
  };
  const handleMfaToggle = async () => {
    setMfaEnabled(!mfaEnabled);
    try {
      if (mfaEnabled) {
        const activateMfaResponse = await activateMfa({ enableMfa: false });
        if (activateMfaResponse.status === "success") {
          makeToast("success", t("Profile.mfaDisabled"));
        } else {
          makeToast("error", t("Profile.mfaDisabledErr"));
        }
      } else {
        const associateResponse = await associateUserToAuthenticatorSoftware(
          user
        );
        if (associateResponse.status === "success") {
          setShowQrCodeModal(true);
          const otpAuthUrl = `otpauth://totp/${user?.email}?secret=${associateResponse.result.SecretCode}&issuer=K-Search`;
          setQrCodeData(otpAuthUrl);
        } else {
          makeToast("error", t("Profile.mfaEnabledErr"));
        }
      }
    } catch (error) {
      makeToast("error", t("Profile.mfaEnabledErr") + error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userInfo && userInfo?.PreferredMfaSetting !== undefined) {
      setMfaEnabled(userInfo?.PreferredMfaSetting);
    }
  }, [userInfo]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="profile-doctor">
        <div className="flex justify-between info ">
          <div className="flex col-span-2 mb-16 ">
            <div className="doctorContainer profile-picture mr-1 md:mr-2">
              <div className="photo-profile relative">
                {user?.gender === "female" ? (
                  <WomanPatient className=" rounded-full  bg-pink-200 w-3/4 h-3/4" />
                ) : (
                  <ManPatient className=" rounded-full bg-yellow-100 w-3/4 h-3/4" />
                )}
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <div className=" flex flex-row w-full doctorNames self-center">
                <p className="doctorTitle">
                  {user?.lastName && user?.lastName.toUpperCase()}{" "}
                  {user?.firstName && user?.firstName}
                </p>
              </div>
              <div>
                <p className="doctorTitle">
                  {user.type === "arc"
                    ? "ARC"
                    : user.type === "cro"
                    ? t("Profile.admin")
                    : user.type === "participant"
                    ? "Participant"
                    : t("Profile.investigateur")}
                </p>
              </div>
            </div>
          </div>
          <div>
            {Object.keys(lngs).map((lng) => (
              <button
                key={lng}
                style={{
                  fontWeight: i18n?.resolvedLanguage?.includes(lng)
                    ? "bold"
                    : "normal",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                type="submit"
                onClick={async () => {
                  i18n.changeLanguage(lng);
                  console.log("🚀 ~ file: index.tsx:72 ~ Index ~ lng:", lng);
                  localStorage.setItem("local", lng);
                  const res = await updateUserLang(user._id, {
                    lang: lng,
                    type: user?.type,
                  });
                  if (res) {
                    console.log(
                      "🚀 ~ file: index.tsx:77 ~ onClick={ ~ res:",
                      res
                    );
                    setUser(res);
                    makeToast(
                      "success",
                      lng === "en"
                        ? "Language updated with sucess"
                        : "Langue mise à jour avec succès"
                    );
                  }
                }}
              >
                {lngs[lng].nativeName}
              </button>
            ))}
          </div>
        </div>

        <div>
          {showQrCodeModal && (
            <Modal
              showModal={true}
              className="px-10 py-1 relative overflow-y-auto flex items-center justify-center"
            >
              <div className="max-w-sm p-4">
                {[
                  t("Profile.inst1"),
                  t("Profile.inst2"),
                  t("Profile.inst3"),
                ].map((step, index) => (
                  <p
                    key={index}
                    className="mb-4 text-justify"
                    dangerouslySetInnerHTML={{
                      __html: `(${index + 1}) ${step}`,
                    }}
                  />
                ))}
                <div className="flex justify-center mb-4">
                  <QRCode value={qrCodeData} size={160} />
                </div>
                <p className="mb-4 text-justify">(4) {t("Profile.inst4")}</p>
                <Input
                  type="text"
                  placeholder={t("Profile.placeholderCode")}
                  onChange={(e) => setUserCode(e.target.value)}
                  labelText={t("Profile.placeholderCode")}
                />
                <Button
                  status="primary"
                  width={"119px"}
                  height={"50px"}
                  className="mx-auto justify-center block "
                  onClick={verifyUserSoftware}
                  disabled={!userCode}
                >
                  {t("Profile.Save")}
                </Button>
              </div>
            </Modal>
          )}

          <div className="PresentationBox pb-3 EditAccount_PresentationPanel">
            <div className="headerContainer flex items-center relative">
              <Icon isIcon={true} name="doctor-repo" className="svgClass" />
              <p className="header">{t("Profile.TitleDesc")}</p>
              <p
                className="absolute right-0	modifier"
                onClick={() => setOpenModalInfo(true)}
                id="editAcccountBtn"
              >
                {t("Profile.Update")}
              </p>
            </div>

            <div className="textClassContainer p-6">
              <div className="flex">
                <div className="w-64">{t("Profile.name")}</div>
                <div className="ml-4">
                  <p>{user.firstName}</p>
                </div>
              </div>
              <div className="flex">
                <div className="w-64">{t("Profile.lastName")}</div>
                <div className="ml-4">
                  <p>{user.lastName}</p>
                </div>
              </div>
              <div className="flex">
                <div className="w-64">Email</div>
                <div className="ml-4">
                  <p>{user.email}</p>
                </div>
              </div>

              <div className="flex">
                <div className="w-64">{t("Profile.Country")}</div>
                <div className="ml-4">
                  <p>{user.pays ? user.pays : "N/A"}</p>
                </div>
              </div>
           
            </div>
          </div>
          <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-1 gap-6">
            {user?.type === "cro" && user?.freeAccount !== true && (
              <div className="PresentationBox pb-3 mt-10">
                <div className="headerContainer flex items-center relative">
                  <img src={cashIcon} className="svgClass" alt="paiement" />
                  <p className="header">Facturation</p>
                  {/* <p
                    className="absolute right-0	modifier"
                    onClick={() => setOpenModalInfo(true)}
                  >
                    {t("Profile.Update")}
                  </p> */}
                </div>
                <Invoices />
              </div>
            )}

            <div className="PresentationBox pb-3 mt-10 EditAccount_SecurityPanel">
              <div className="headerContainer flex items-center relative">
                <img src={padLock} className="svgClass" alt="paiement" />
                <p className="header">{t("Profile.TitleSec")}</p>
              </div>
              <div className="p-4 mt-2">
                <div className="title-container">
                  <p className="title mb-1">{t("Profile.Mfa")} </p>
                </div>
                <div className="flex ">
                  <div className="flexCenter sub-values">
                    <p className="name">{t("Profile.mtp")}</p>
                    <ToggleSwitch
                      id="mfaToggle"
                      small
                      checked={mfaEnabled}
                      onChange={handleMfaToggle}
                      optionLabels={["Disable", "Enable"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          
           
           
              <WorkSpaceSelection currentTeneant={user?.tenantId} />
            
           
          </div>
        </div>
      </div>
      {openModalInfo && (
        <MyModal
          closeModal={() => setOpenModalInfo(false)}
          showModal={openModalInfo}
        >
          <ModifyDataUserProfile
            openModal={openModalInfo}
            setOpenModal={setOpenModalInfo}
          />
        </MyModal>
      )}
    </>
  );
};

export default Index;
