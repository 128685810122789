import React, { useEffect, useState } from "react";
import { InputGroup, InputRightElement, Spinner } from "@chakra-ui/react";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";
import StarRatings from "react-star-ratings";
export const FieldRating = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    isValidating,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name } = props;

  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  useEffect(() => {
    setValue(parseInt(props.value));
  }, [props.value]);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper: isTouched ? helper : "",
    id,
    isRequired: !!required,
    label,
    showError,
    name,

    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        <StarRatings
          rating={value ? value : 0}
          starRatedColor="#feb409"
          changeRating={
            props.readOnly
              ? undefined
              : (e) => {
                  setValue(e);
                  setIsTouched(true);

                  if (props?.onSumbit)
                    props.onSumbit({
                      fieldName: name,
                      value: e,
                      isValid: isValid,
                    });
                }
          }
          numberOfStars={parseInt(props?.count)}
          name={name}
          starHoverColor="#feb409"
          starDimension="40px"
          starSpacing="5px"
        />
        {(isTouched || isSubmitted) && isValidating && (
          <InputRightElement>
            <Spinner size="sm" flex="none" />
          </InputRightElement>
        )}
      </InputGroup>
      {children}
    </FormGroup>
  );
};
