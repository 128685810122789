import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgOrganisation } from "react-icons/cg";
import { ImEnter } from "react-icons/im";
import { VscVmActive } from "react-icons/vsc";
import { useLocation } from "react-router-dom";
import { getWorkingSpaces } from "../../api/auth";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { socketAtom } from "../../state/socket";
const WorkSpaceSelection = (props) => {
  const { currentTeneant } = props;
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [user, setUser] = useAtom(userAtom) as any;
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [data, setData] = useState() as any;
  const location = useLocation() as any;
  const { t } = useTranslation() as any;
  useEffect(() => {
    // Retrieve the state from the location object
    const { state } = location;
    console.log("🚀 ~ file: index.tsx:16 ~ useEffect ~ state:", state);
    if (state) setData(JSON.parse(JSON.stringify(state.data)));
    else {
      getWorkingSpaces()
        .then((res) => {
          console.log("🚀 ~ file: index.tsx:24 ~ verify ~ e:", res);
          setData(JSON.parse(JSON.stringify(res.data)));
        })
        .catch((e) => {
          console.log("🚀 ~ file: index.tsx:24 ~ verify ~ e:", e);
        });
    }
  }, []);
  useEffect(() => {
    if (selectedTenant) {
      history.push({
        pathname: `/`,
      });
    }
  }, [selectedTenant]);
  return data && data.user ? (
    <div className="PresentationBox pb-3 mt-10 EditAccount_OrganisationPanel">
      <div className="headerContainer flex items-center relative">
        <CgOrganisation size={34} className="text-blue-400 ml-4" />
        <p className="header">{t("missingWording.organisation")}</p>
      </div>
      <div className="p-4">
        <div className="container px-4  ">
          <div className="flex items-center justify-center self-center">
            <table className="min-w-full    border-b border-gray-500 ">
              <tbody className="divide-y divide-gray-500   ">
                {data.user
                  .filter((e) => e && e !== null)
                  .map((z) => {
                    return (
                      <tr
                        className={" cursor-pointer h-14 "}
                        onClick={() => {
                          localStorage.setItem(
                            "tenantId",
                            z.organisationId.tenant
                          );
                          setUser(z);
                          setSocket(null);
                          setSelectedTenant(z.organisationId.tenant);
                        }}
                      >
                        <td
                          className={
                            currentTeneant === z.organisationId.tenant
                              ? " px-2 font-semibold  text-black bg-gray-50 shadow-md"
                              : " px-2 font-semibold text-gray-500 hover:text-black hover:bg-gray-100"
                          }
                        >
                          <div className="flex justify-between gap-x-4 items-center">
                            <p> {z?.organisationId?.name}</p>
                            <p className="rounded-full flex font-normal items-center  text-center bg-gray-50  px-2 py-2  shadow-md text-xs">
                              {currentTeneant === z.organisationId.tenant ? (
                                <VscVmActive
                                  className="text-blue-500"
                                  size={24}
                                />
                              ) : (
                                <ImEnter className="" size={24} />
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default WorkSpaceSelection;
