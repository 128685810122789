import React, { useEffect, useState } from "react";
import Input from "../Input";
import { FcPrevious, FcNext } from "react-icons/fc";
import { BsSearch } from "react-icons/bs";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
interface Participant {
  email: string;
  publicId: string;
  _id: string;
  // Add other attributes here
}

interface ParticipantFilterProps {
  participants: Participant[];
  totalResult: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTextSearch: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setFieldValue: any;
  participantId?: any;
}

const ParticipantFilter: React.FC<ParticipantFilterProps> = ({
  participants,
  totalResult,
  page,
  setPage,
  setTextSearch,
  loading,
  setFieldValue,
  participantId,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState(
    participantId
  ) as any;
  const { t } = useTranslation() as any;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    setTextSearch(value); // Update text search in parent component
    setPage(1); // Reset page to 1 when a new search term is entered
  };

  const handleNextPage = () => {
    const maxPageNbr = Math.ceil(totalResult / 10);
    if (page >= maxPageNbr) return null;
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    if (!isEmpty(participantId)) {
      setSelectedParticipant(participantId);
      setInputValue(`${participantId?.publicId}`);
      setFieldValue("participantId", participantId?._id);
    }
  }, []);

  return (
    <div className="flex flex-col gap-y-4 h-full">
      <label htmlFor="InputSearch" className="text-sm px-2">
        Participant:
      </label>
      <div className="relative h-12">
        <Input
          type="text"
          id="InputSearch"
          // labelText={"Type to filter by email or public ID"}
          placeholder={t("eConsult.tapez")}
          value={inputValue}
          onChange={handleInputChange}
          className="mb-1 -mt-2 h-12  w-full px-4 rounded text-sm "
        />
        <BsSearch className="absolute right-2 top-2" />
      </div>
      <div className="flex flex-row justify-between text-sm z-50 -mt-4">
        {" "}
        {true&&<p className="text-xs text-gray-400 text-center">
          {t("eConsult.total")} {totalResult}
        </p>}
       {true && <div className="flex items-center ">
          <button onClick={handlePrevPage}>
            <FcPrevious />
          </button>
        {Math.ceil(totalResult / 10) > 0 &&  <span className="text-xs text-gray-400 text-center"> {page}/{Math.ceil(totalResult / 10)}</span>}
          <button onClick={handleNextPage}>
            <FcNext />
          </button>
        </div>}
      </div>

      <div className="flex items-center justify-center overflow-y-auto relative -mt-3  ">
        {loading ? (
          <h3 className="text-center text-sm h-40 w-full  bg-gray-100"><p className="mt-16">{t("eConsult.load")}</p></h3>
        ) : (
          <div className="text-center  text-sm h-40 w-full bg-gray-100">{participants.length>0 ? (<ul className=" z-50 left-0 -top-1 w-full h-40 max-h-40 overflow-y-scroll rounded-md shadow-lg bg-gray-100 cursor-pointer">
          {participants.map((suggestion, index) => (
            <li
              key={suggestion.email}
              className={`px-4 py-2 hover:bg-blue-300 hover:text-white text-xs text-justify leading-2 flex-col 
           ${
             suggestion._id === selectedParticipant?._id
               ? "bg-blue-600 text-white"
               : ""
           } `}
              onClick={() => {
                setSelectedParticipant(suggestion);
                setInputValue(`${suggestion.publicId}`);
                setFieldValue("participantId", suggestion._id);
              }}
            >
              <p> {suggestion.publicId} </p>
              <p className="mt-0.5">{suggestion.email}</p>
            </li>
          ))}
        </ul>) : ( <p className="mt-16">{t("Participant.aucunPart")}</p>)}
            
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipantFilter;
