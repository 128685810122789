import { useAtom } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillEdit } from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import { FcLeave } from "react-icons/fc";
import { useParams } from "react-router-dom";
import { getTranslationBySurveyId } from "../../api/translation";
import { ReactComponent as Survery } from "../../assets/illustrations/survey.svg";
import Button from "../../components/ButtonUI";
import DropdownComponent from "../../components/DropDown";
import Modal from "../../components/ModalUI";
import { findObjectsByDependency } from "../../helper";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import AssignModal from "./AssignModal";
import DuplicationEproModal from "./DuplicationEproModal";
import DuplicationModal from "./DuplicationModal";
import EditEproSurvey from "./EditEproSurvey";
import SaveTemplate from "./SaveTemplate";
import DeleteSurveyModal from "./deleteSurveyModal";
import "./styles.scss";
const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
const CardSurvey: React.FC<any> = ({
  patientDetails,
  surveyDetails,
  i,
  visteList,
  setRefresh,
  isEvent = false,
  eventType = null,
  randomConfig = null,
  ePro = false,
}) => {
  console.log("🚀 ~ file: SurveyCard.tsx:43 ~ visteList:", visteList)
  const params: any = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModalEpro, setShowModalEpro] = useState(false);
  const [showDuplicationModal, setShowDuplicationModal] = useState(false);
  const [showDuplicationModalEpro ,setShowDuplicationModalEpro ]= useState(false)
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState() as any;
  const { studyId } = params;
  const [user] = useAtom<User | null>(userAtom) as any;
  const { t } = useTranslation() as any;
  const [translation, setTranslation] = useState() as any;
  const languageGroup = (
    localStorage.getItem("i18nextLng") || navigator.language
  )
    .toString()
    .startsWith("fr")
    ? "Français"
    : "Anglais";
  const getTranslationFormData = async () => {
    try {
      const translatedSurvery = await getTranslationBySurveyId(
        surveyDetails._id,
        languageGroup
      );
      if (translatedSurvery && translatedSurvery.translation) {
        setTranslation(translatedSurvery?.translation[0]);
      } else {
        setTranslation(null);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  useEffect(() => {
    if (surveyDetails._id !== null) {
      getTranslationFormData();
    } else {
      setTranslation(null);
    }
  }, [surveyDetails._id, languageGroup, user?.type]);
  return (
    <>
      <Modal showModal={showModal} closeModal={() => setShowModal(false)}>
        <AssignModal
          survey={selectedSurvey}
          closeModal={() => setShowModal(false)}
          visteList={visteList}
          setRefresh={setRefresh}
          randomConfig={randomConfig}
        />
      </Modal>
      <Modal
        showModal={showModalEpro && ePro}
        closeModal={() => setShowModalEpro(false)}
      >
        <EditEproSurvey
          survey={selectedSurvey}
          closeModal={() => setShowModalEpro(false)}
          visteList={visteList}
          setRefresh={setRefresh}
          randomConfig={randomConfig}
        />
      </Modal>
      <Modal
        showModal={showDuplicationModal}
        // slidemodal
        closeModal={() => setShowDuplicationModal(false)}
        className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
      >
        <DuplicationModal
          visteList={visteList}
          surveyDetails={surveyDetails}
          setShowDuplicationModal={setShowDuplicationModal}
          setRefresh={setRefresh}
          randomConfig={randomConfig}
          className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
        />
      </Modal>
      <Modal
        showModal={showDuplicationModalEpro}
        // slidemodal
        closeModal={() => setShowDuplicationModalEpro(false)}
        className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
      >
        <DuplicationEproModal
          visteList={visteList}
          surveyDetails={surveyDetails}
          setShowDuplicationModal={setShowDuplicationModalEpro}
          setRefresh={setRefresh}
          className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
        />
      </Modal>
      <Modal
        showModal={showSaveAsTemplateModal}
        // slidemodal
        closeModal={() => setShowSaveAsTemplateModal(false)}
      >
        <SaveTemplate
          visteList={visteList}
          surveyDetails={surveyDetails}
          setShowSaveAsTemplateModal={setShowSaveAsTemplateModal}
          setRefresh={setRefresh}
          className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
        />
      </Modal>
      <Modal
        showModal={showModalDelete}
        className=" "
        closeModal={() => setShowModalDelete(false)}
      >
        <DeleteSurveyModal
          surveyDetails={surveyDetails}
          hasDependecy={findObjectsByDependency(visteList,surveyDetails._id)}
          setShouldUpdate={setRefresh}
          closeModal={() => setShowModalDelete(false)}
        />
      </Modal>

      <div
        className={`relative cursor-pointer hover:shadow card-patient justify-between bg-white `}
        onClick={() => {}}
      >
        <div
          className="flex "
          onClick={() => {
            if (surveyDetails._id) {
              history.push({
                pathname: `/study/${studyId}/${surveyDetails._id}`,
                state: {
                  translation: translation,
                },
              });
            }
          }}
        >
          <div
            className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
            style={{
              backgroundColor: patientDetails?.photo
                ? ""
                : colorAvatar[i % colorAvatar.length],
            }}
          >
            {isEvent ? <FcLeave size={"38"} /> : <Survery width={37} />}
          </div>
          <div className="details">
            <div className="flex-grow ">
              <div className="flex justify-between">
                {" "}
                <p className="card-title capitalize">{surveyDetails.name}</p>
              </div>

              <p className="card-subtitle">
                {`${t("DashboardCro.lastUpdate")}`}{" "}
                <span>
                  {moment(surveyDetails.updatedAt).format("DD-MM-YYYY") +
                    t("DashboardCro.at") +
                    moment(surveyDetails.updatedAt).format("HH:mm")}
                </span>
              </p>

              {surveyDetails.description && surveyDetails.description !== "" ? (
                <p className="box-motif max-w-350 ">
                  {surveyDetails.description}
                </p>
              ) : (
                ""
              )}

              {isEvent && eventType && (
                <p className="box-motif max-w-350 ">
                  {eventType === "ADVERSE"
                    ? t("missingWording.adverseevents")
                    : t("missingWording.phoneCallEvent")}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className=" gap-0.5 mr-2 mt-2 mb-2"
          style={{
            // justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!isEvent && (
            <>
              <Button
                status="icon-secondary"
                withToolTip
                toolTipText={`${t("missingWording.duplicate")}`}
                className="w-10 h-10"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                 ePro ? setShowDuplicationModalEpro(true) : setShowDuplicationModal(true);
                  setSelectedSurvey(surveyDetails);
                }}
              >
                <BiDuplicate />
              </Button>

              <Button
                status="icon-secondary"
                className="w-10 h-10"
                withToolTip
                toolTipText={`${t("missingWording.editVisitToolTip")}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  ePro ? setShowModalEpro(true) : setShowModal(true);
                  setSelectedSurvey(surveyDetails);
                }}
              >
                <AiFillEdit />
              </Button>

              <DropdownComponent
                setupIcon={true}
                options={[
                  {
                    name: `${t("missingWording.saveAsTemplate")}`,
                    action: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowSaveAsTemplateModal(true);
                      setSelectedSurvey(surveyDetails);
                    },
                  },
                  {
                    name: t("missingWording.traduction"),
                    action: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedSurvey(surveyDetails);
                      if (surveyDetails._id) {
                        history.push({
                          pathname: `/translate/${studyId}/${surveyDetails._id}`,
                          state: { translateMode: true },
                        });
                      }
                    },
                  },
                  {
                    name: t("missingWording.deleteVisitToolTip"),
                    action: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedSurvey(surveyDetails);
                      setShowModalDelete(true);
                    },
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CardSurvey;
