export const Moment = {
  fr: {
    Z: "DD MMMM YYYY à HH:mm",
    Y: "DD-MM-YYYY à HH:mm",
    W: "DD MMMM à : HH:mm",
    V: "LL à HH:mm",
  },
  en: {
    Z: "LLL",
    Y: "lll",
    W: "lll",
    V: "lll",
  },
};
