import { ArrowRightIcon } from "@chakra-ui/icons";
import { ChakraProvider, Heading, extendTheme } from "@chakra-ui/react";
import { t } from "i18next";
import { useAtom } from "jotai";
import jwt from "jsonwebtoken";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useParams } from "react-router-dom";
import { getEproFormadataById, submitEproForm } from "../../api/eproForms";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import {
  MultiStepsLayout,
  PageHeader,
} from "../../components/SurveyGenerator/FormBuilder/Lyaout";
import StepperWrapper from "../../components/SurveyGenerator/FormBuilder/StepperWrapper";
import {
  Formiz,
  FormizStep,
} from "../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import FormRenderer from "../../components/SurveyGenerator/FormRenderer";
import { default as frMsg } from "../../config/constants/locales/fr-fr.json";
import { resolver } from "../../helper/FormGeneratorHelper/conditionalLogic";
import history from "../../history";
import { useForm } from "../../hooks/FormGeneratorHooks";
import { visteAtom } from "../../state/visteAtom";

const EproForms = () => {
  const params = useParams() as any;
  const form = useForm();
  const { formId, tenantId, token } = params;
  const [eproForm, setEproForm] = useState() as any;
  const [formData, setFormData] = useAtom(visteAtom);
  const [isEproActive, setIsEproActive] = useState(false);
  const theme = extendTheme({
    colors: {
      transparent: "transparent",
      black: "#000",
      white: "#fff",
      blue: "#4565f6",
    },
  });
  const getEproFormById = async () => {
    try {
      //setLoading(true);
      const res = await getEproFormadataById(formId, tenantId);
      console.log("🚀 ~ file: index.tsx:35 ~ getEproFormById ~ res:", res);
      if (res) {
        setEproForm(res);
        setFormData(res);
        setIsEproActive(true);
        //setLoading(false);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };

  const decodeToken = (token: string) => {
    try {
      const decodedToken = jwt.decode(token);
      if (decodedToken) {
        console.log(decodedToken.expiresIn);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        // Check if the token has not expired
        if (
          decodedToken.expiresIn &&
          decodedToken.expiresIn > currentTimestamp
        ) {
          console.log("Token is still valid.");
          // You can proceed with using the token.
        } else {
          console.log("Token has expired.");
          makeToast("error", "Token has expired.");
          history.push("/");
        }
        // Add more claims as needed
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    localStorage.setItem("eproToken", token);
    localStorage.setItem("tenantId", tenantId);
    decodeToken(token);
    getEproFormById();
  }, []);
  const handleSubmit = async (values) => {
    try {
      let reska: any[] = formData?.sections;
      console.log('reska',reska)
      reska.map((section) => {
          section.fields.map((field) => {
            if (values.hasOwnProperty(`${field.field_name}`) && field.type === "Checkboxes" &&  Array.isArray(values[`${field.field_name}`]))
              values[`${field.field_name}`] =  values[`${field.field_name}`].join("||")
              if(field.type ==='RepeaterField'){
                field.value =JSON.stringify( values[`${field.field_name}`])
                values[`${field.field_name}`]=JSON.stringify( values[`${field.field_name}`])
              }
            section.status = "VALID_BY_WRITER";
          });
        });
  
      const res = await submitEproForm(formId, tenantId, token, values);
      if (res) {
        makeToast("success", t("missingWording.submitForm"));
        history.push('/thankyou')
      }
    } catch (error) {}
  };
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // Empty string to suppress the default browser dialog.

      // Display a confirmation message to the user.
      const confirmationMessage =
        "You have unsaved changes. Are you sure you want to leave?";
      e.returnValue = confirmationMessage;
      const userChoice = window.confirm(confirmationMessage);
      console.log("🚀 ~ file: index.tsx:103 ~ handleBeforeUnload ~ userChoice:", userChoice)
   
        e.preventDefault(); // Prevent the default action (leaving the page)
        handleSubmit(form.values); // Perform the save action
     // Reset the dirty state after saving
      
      // You can also perform other actions here, like saving data to the server.
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component is unmounted.
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [form]);
  console.log("EPRO", eproForm);

  return !eproForm ? (
    <Spinner />
  ) : (
    <ChakraProvider  theme={theme}>
      <IntlProvider locale={"fr"} defaultLocale="fr" messages={frMsg}>
        <Formiz connect={form} key={form.resetKey} onValidSubmit={handleSubmit}>
          <div className="flex  h-full w-full mt-4 relative justify-center">
            <MultiStepsLayout
              submitLabel={t("missingWording.submitLabel")}
              isEproActive={isEproActive}
            >
              <PageHeader setShouldUpdate={undefined} isPreview={true}>
                {eproForm?.name ? eproForm?.name : "#Titre de questionnaire"}
                <Heading fontSize="lg" mb={4} mt={4}>
                  {eproForm?.description && (
                    <>
                      <ArrowRightIcon
                        height={"12px"}
                        width={"12px"}
                        mr={2}
                        mb={1}
                        color={"#4565f6"}
                      />
                      <>{eproForm?.description}</>
                    </>
                  )}
                </Heading>
              </PageHeader>
              <div className="border  shadow-lg  p-4 w-full rounded-lg mb-4">
                <StepperWrapper title="Dots stepper" dir="row"></StepperWrapper>

                {eproForm.sections.map((step, i) => {
                  return (
                    <FormizStep
                      label={step.sectionName}
                      name={`${step.sectionName + "-" + step.stepNbr}`}
                      isEnabled={
                        step.conditionLogic && step.conditionLogic.status
                          ? resolver(step.conditionLogic, form.values)
                          : true
                      }
                    >
                      <FormRenderer
                        key={i}
                        elements={step.fields}
                        values={form.values}
                        form={form}
                        isPreview={true}
                        isEpro={true}
                        formDataId={formData?._id || eproForm?._id}
                        stepNbr={step.stepNbr}
                      ></FormRenderer>
                    </FormizStep>
                  );
                })}
              </div>
            </MultiStepsLayout>
          </div>
        </Formiz>
      </IntlProvider>
    </ChakraProvider>
  );
};
export default EproForms;
