export enum UserRole {
  cro = "cro",
  arc = "arc",
  writer = "writer",
  Guest = "GUEST",
  sponsor = "sponsor",
  participant = "participant",
}

export interface User {
  type: UserRole;
  tenantId: string;
  accountVerified: boolean;
  activated: boolean;
  address: string;
  invited: boolean;
  firstLogin: boolean;
  createdAt: Date;
  organisationId: any;
  email: string;
  lang: string;
  studies: any[];
  firstName: string;
  imagesNumber: number;
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  lastName: string;
  phoneNumber: string;
  updatedAt: Date;
  token: string;
  centerId?: string;
  _id: string;
  __v?: number;
  roles: any;
  studiesConfig: any[];
}
