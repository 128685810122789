import { useAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFilter } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { getQuerysARC, getQuerysWriter } from "../../api/queries";
import { getStudyConfig } from "../../api/study";
import Pagination from "../../components/PaginationTable";
import SelectComp from "../../components/Select";
import Table from "../../components/Tab";
import { userAtom } from "../../state/auth";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import { studyWriterConfigAtom } from "../../state/studyWriterConfig";
import "./styles.scss";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import { testModeAtom } from "../../state/testModeAtom";
import TestModeTogButton from "../../components/TestModeShared";

export const Query = ({}) => {
  const params = useParams() as any;
  const { studyId } = params;
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  ) as any;
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [queriesList, setQueriesList] = useState<any[]>([]);
  const [typeQuery, setTypeQuery] = useState("OPEN") as any;
  const [filterWord, setFilterWord] = useState("byMe");
  const [searchText, setTextSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterdQueriesList, setFilterdQueriesList] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const typingTimeoutRef = useRef(null) as any;
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const fetchWriterConfig = async () => {
    try {
      let writerConfig = await getStudyConfig(
        studyId,
        1,
        10,
        "ByMe",
        "",
        testMode
      );
      return writerConfig?.studyConfig;
    } catch (error) {
      console.log(
        "🚀 ~ file: index.tsx:69 ~ fetchWriterConfig ~ error:",
        error
      );
    }
  };

  const fetchListQuery = async (studyWriterConfig: any) => {
    try {
      let res: any = [];
      setLoading(true);
      switch (user?.type) {
        case "writer":
          if (!studyWriterConfig) {
            let config = await fetchWriterConfig();

            res = await getQuerysWriter(
              studyId,
              config?.centerId?._id,
              config?.roleId?._id,
              page,
              10,
              config.isResponsable ? filterWord : "byMe",
              typeQuery,
              searchText,
              testMode
            );
            setStudyWriterConfig(config);

            if (res && res.queryList && res.queryList.query) {
              setQueriesList(res.queryList.query);
              setFilterdQueriesList(res.queryList.query);

              setTotalResult(res.queryList.count);
              setLoading(false);
            }
            break;
          } else {
            res = await getQuerysWriter(
              studyId,
              studyWriterConfig?.centerId?._id,
              studyWriterConfig?.roleId?._id,
              page,
              10,
              studyWriterConfig.isResponsable ? filterWord : "byMe",
              typeQuery,
              searchText,
              testMode
            );

            if (res && res.queryList && res.queryList.query) {
              setQueriesList(res.queryList.query);
              setFilterdQueriesList(res.queryList.query);
              setTotalResult(res.queryList.count);
              setLoading(false);
            }
            break;
          }

        default:
          res = await getQuerysARC(
            studyId,
            page,
            10,
            typeQuery,
            searchText,
            testMode
          );
          if (res && res.queryList && res.queryList.query) {
            setQueriesList(res.queryList.query);
            setFilterdQueriesList(res.queryList.query);
            setTotalResult(res.queryList.count);
            setLoading(false);
          }

          break;
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:22 ~ fetchListQuery ~ error", error);
    }
  };

  useEffect(() => {
    if (studyId) setStudySelectedId(studyId);
    if (searchText || searchText === "") {
      setFilterdQueriesList(queriesList);
    }
    fetchListQuery(studyWriterConfig);
    
  }, [
    shouldUpdate,
    setShouldUpdate,
    page,
    filterWord,
    typeQuery,
    testMode,
    searchText,
  ]);

  useEffect(() => {
    setFilterdQueriesList(queriesList);
  }, [queriesList]);

  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length > 0) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };
  return (
    <>
      <header className="flex justify-between items-center w-full flex-wrap">
        <div className="title-container">
          <h1 className="pageTitle">{t("Query.TitleQuery")}</h1>
          <h3 className="card-subtitle"></h3>
        </div>
      </header>
      <TestModeTogButton />
      <div className="flex justify-between py-3 pl-2 ">
        <div className="flex flex-row">
          <div className="flex flex-row items-center relative max-w-xs mt-4">
            <p className="mr-4">Filtrer</p>
            <SelectComp
              value={typeQuery}
              options={[
                {
                  value: "ALL",
                  label: `${t("Query.All")}`,
                },
                {
                  value: "OPEN",
                  label: `${t("Query.Waiting")}`,
                },

                {
                  value: "SYSTEM",
                  label: `${t("missingWording.systQuery")}`,
                },
                {
                  value: "BYARC",
                  label: `${t("missingWording.arcQuery")}`,
                },
                {
                  value: "CLOSED",
                  label: `${t("Query.Closed")}`,
                },
              ]}
              onChange={(e) => setTypeQuery(e.target.value)}
              className="w-full queryTypeList"
              style={{ height: "38px", width: "230px" }}
              setText={false}
            />
            {/* <div className="absolute inset-y-0 right-8 flex items-center pl-4 pointer-events-none">
            <AiOutlineFilter />
          </div> */}
          </div>
          <div className="flex flex-row items-center relative max-w-xs ml-8 mt-4">
            <p className="mr-4">Rechercher</p>
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              value={searchValue}
              onChange={onSearch}
              placeholder={t("Query.searchby")}
              className="block w-full p-3 pl-10 rounded-md border-none ring-none "
              style={{ height: "38px", width: "230px" }}
              onKeyDown={handleKeyDown}
            />

            <div className="absolute inset-y-0 right-2 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="absolute right-4 flex flex-row items-center text-sm font-semibold mt-8 TestMode_param">
          {user?.type === "writer" &&
            studyWriterConfig &&
            studyWriterConfig.isResponsable && (
              <div className="flex flex-row items-center">
                <p> {t("missingWording.queriesCenterAceess")}</p>
                <ToggleSwitch
                  id="newsletter"
                  disabled={false}
                  small
                  checked={filterWord === "byCenter"}
                  onChange={() => {
                    setFilterWord((prev) =>
                      prev === "byMe" ? "byCenter" : "byMe"
                    );
                  }}
                />{" "}
              </div>
            )}
        </div>
      </div>
      {studyWriterConfig?.studyId.status === "END" && (
        <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2  ">
          <span className="warn warning ">
            ⚠️{t("missingWording.bannerEnd")}.
          </span>
        </div>
      )}

      <div>
        <Table
          data={filterdQueriesList}
          loading={loading}
          setShouldUpdate={setShouldUpdate}
          setTypeQuery={setTypeQuery}
        />
        {totalResult > 10 && (
          <Pagination
            totalResults={totalResult}
            resultsPerPage={10}
            currentPage={page}
            onPageChange={function (page: number): void {
              setPage(page);
            }}
            entriesName={`${t("missingWording.paginationQ")}`}
          />
        )}
      </div>
    </>
  );
};

export default Query;
