import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUpdateRole, getStudyRoles } from "../../../api/study";
import RoleImg from "../../../assets/illustrations/role.png";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import { userAtom } from "../../../state/auth";
import { listRoleAtom } from "../../../state/listRoles";
import { tutoFirstCreateProfilAtom } from "../../../state/tutoFirstCreateCenter";
import Card from "./CardRole";

interface CardProps {
  id: string;
  icon: string;
  name: string;
  writers: any[];
}

const Roles = ({ studyId, readOnly, roleTuto }) => {
  const [firstCreate, setFirstCreate] = useAtom(tutoFirstCreateProfilAtom);

  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [listRoles, setListRoles] = useAtom(listRoleAtom);

  const [loadingPage, setLoadinPage] = useState(false);
  const [user] = useAtom(userAtom);
  const [newCard, setNewCard] = useState<CardProps>({
    id: "new-card",
    icon: "/path/to/icon.png",
    name: "",
    writers: [],
  });
  const [isAddingCard, setIsAddingCard] = useState(false);

  const updateCreateRole = async (name = "") => {
    const res = await createUpdateRole({
      name: name || newCard?.name,
      roleId: null,
      createdBy: user?._id,
      docModel: user?.type,
      studyId,
    });
    if (res.message === "success") {
      makeToast("success", t("missingWording.rt1"));
      return res.data.roles;
    } else {
      makeToast("danger", t("missingWording.pht2"));
    }
  };
  async function updateCardName(cardId: string, name: string) {
    const res = await createUpdateRole({
      name: name,
      roleId: cardId,
      createdBy: user?._id,
      docModel: user?.type,
      studyId,
    });
    if (res.message === "success") {
      makeToast("success", `${t("Role.MajS")}`);
      setNewCard({
        id: "new-card",
        icon: "/path/to/icon.png",
        name: "",
        writers: [],
      });
    } else {
      makeToast("danger", `${t("Role.Err")}`);
    }
    setListRoles(res?.data?.roles);
  }

  function handleNewCardNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewCard({ ...newCard, name: event.target.value });
  }

  async function handleAddCard(name = "") {
    setLoading(true);
    const resu = await updateCreateRole(name);
    if (resu) {
      setIsAddingCard(false);
      setListRoles(resu);
      setNewCard({ name: "", id: "", writers: [], icon: "" });
      setLoading(false);
    } else {
      setLoading(false);
      return;
    }
  }

  async function fetchMyAPI(): Promise<void> {
    // setLoadinPage(true);
    const roles = await getStudyRoles(studyId);

    setTimeout(() => setListRoles(roles?.roles), 100);
    setLoadinPage(false);
  }
  useEffect(() => {
    fetchMyAPI();
  }, [shouldUpdate]);

  useEffect(() => {
    if (roleTuto && firstCreate) {
      handleAddCard("profil name").then((res) => {
        setShouldUpdate((x) => !x);
        setFirstCreate(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleTuto]);

  return loadingPage ? (
    <Spinner />
  ) : (
    <div className="w-full centresEtude">
      <div className="flex md:flex-row xs:flex-col relative w-full gap-x-2">
        <div className="md:w-2/5 xs:w-full  md:mx-2 xs:mx-0 px-3 ">
          <h3 className="text-center">{t("Role.NewRole")}</h3>
          <div
            id="AddProfilCard"
            className="bg-white w-full shadow-md rounded-lg p-4 max-h-screen relative mt-4 flex flex-col "
          >
            <img
              src={RoleImg}
              alt="Card Icon"
              className="w-20 h-20 rounded-full mt-1 mb-6  bg-yellow-200 self-center "
            />
            {!readOnly && (
              <div className="py-8 ">
                <Input
                  id="AddProfilCardInput"
                  value={newCard.name}
                  onChange={handleNewCardNameChange}
                  className="text-sm"
                  labelText={t("Role.RName")}
                  style={{ maxHeight: "40px" }}
                />
                <div className={"absolute right-1 bottom-3 flex-row"}>
                  <Button
                    id="AddProfilCardSave"
                    status="primary"
                    className="px-2 py-1 mx-2"
                    onClick={() => handleAddCard()}
                    disabled={newCard.name === "" || !newCard.name || loading}
                  >
                    + {t("Role.Add")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="md:w-3/5 xs:w-full xs:mt-4 md:mt-0">
          <h3 className="text-center">{t("missingWording.listOfProfil")}</h3>
          <div
            className={` flex flex-wrap max-h-screen min-h-96   border-l-4  justify-center px-6 overflow-y-scroll`}
          >
            {(!listRoles || listRoles.length === 0) && !isAddingCard ? (
              <div className="w-full flexCenter mt-24">
                <h3> {t("Role.NoProfilForCeRole")}</h3>
              </div>
            ) : (
              <>
                {listRoles?.map((card) => (
                  <Card
                    key={card._id}
                    {...card}
                    onUpdateName={(name) => updateCardName(card._id, name)}
                    setShouldUpdate={setShouldUpdate}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
