import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import birthday from "../../assets/images/birthday.png";
import home from "../../assets/images/home.png";
import mail from "../../assets/images/mail.png";
import phone from "../../assets/images/phone.png";
import moment from "../../helper/moment";
import Button from "../ButtonUI";
import Spinner from "../Spinner";
import { colorAvatar } from "./fakeData";
interface IProps {
  openModal: Boolean;
  setOpenModal: Function;
  message?: String;
  type?: String;
  url?: string;
  arcData?: any;
  antecedentsList?: [] | any;
  antecedentsChrirugicalList?: [] | any;
  setDocumentsList?: [] | any;
  documentsList?: [] | any;
  consultaionPage?: boolean;
  colorIndex?: number;
  setUpdateList?: Function;
}

const ArcInfo: React.FC<IProps> = ({
  arcData,
  setOpenModal,
  consultaionPage,
  colorIndex,
}) => {
  const { t } = useTranslation() as any;

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      {arcData?._id ? (
        <div className="absolute  inset-y-0 right-0 z-40  patient-modal-info">
          <div
            className={`sub-container ${
              true ? "overflow-hidden" : "max-h-content"
            }`}
          >
            <div>
              {!consultaionPage && (
                <Button
                  status="icon-secondary"
                  onClick={() => setOpenModal(false)}
                  className="absolute top-1 right-1 "
                >
                  <Close className="absolute top-1 right-1 " />
                </Button>
              )}
            </div>
            <div className="flex header justify-between	mt-2">
              <div className="infos flex w-full justify-between mr-2  pb-4">
                <div className="flex sub-infos">
                  <div
                    className="photo md:w-16 md:h-16 xs:w-10 xs:h-10"
                    style={{
                      backgroundColor:
                        colorAvatar[(colorIndex || 1) % colorAvatar.length],
                    }}
                  >
                    {arcData?.gender === "male" ? (
                      <ManPatient className="md:w-16 xs:w-10" />
                    ) : (
                      <WomanPatient className="md:w-16 xs:w-10" />
                    )}
                  </div>
                  <div className="details mb-2 ">
                    <div className="flex justify-between">
                      <div>
                        <p className="font-bold text-lg capitalize mb-2">
                          {arcData.firstName} {arcData.lastName}
                        </p>

                        <div className="flex birth ">
                          <div className="flexCenter  img">
                            <img
                              src={mail}
                              alt="mail"
                              width="16.5px"
                              height="16.5px"
                            />
                          </div>
                          <div>{arcData.email}</div>
                        </div>
                        {arcData.birthDate && (
                          <div className="flex birth  ">
                            <div className="sub-birth flexCenter img">
                              <img
                                src={birthday}
                                alt="birthday"
                                width="16.5px"
                                height="16.5px"
                              />
                            </div>
                            <div className="">
                              <p className="">
                                {arcData?.birthDate
                                  ? moment(arcData?.birthDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {arcData.phone && (
                      <div className="flex birth">
                        <div className="flexCenter img">
                          <img
                            src={phone}
                            alt="phone"
                            width="16.5px"
                            height="16.5px"
                          />
                        </div>
                        <div>{arcData.phone ? arcData.phone : "N/A"}</div>
                      </div>
                    )}

                    {arcData.adresse && (
                      <div className="flex">
                        <div className="flexCenter img">
                          <img
                            src={home}
                            alt="home"
                            width="16.5px"
                            height="16.5px"
                          />
                        </div>
                        <div>{arcData.adresse}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="relative tabs-content mt-12 md:text-base xs:text-sm">
              <div className="tabs-container">
                <div className="sub-item">
                  <div className="title-container">
                    <span className="card-subtitle">
                      {t("ArcPage.MemberSince")}{" "}
                      {moment(arcData.createdAt).format("DD-MM-YYYY") +
                        t("DashboardCro.at") +
                        moment(arcData.createdAt).format("HH:mm")}
                    </span>
                  </div>
                  {/* <div className="flex values-patient">
                    <div className="flexCenter sub-values">
                      {arcData?.activated ? (
                        <p className="name">
                          {t("ArcPage.StatusAccount")}{" "}
                          <span className="rounded-lg text-xs border bg-green-400 p-1">
                            {t(`ArcPage.activate`)}
                          </span>
                        </p>
                      ) : (
                        <p className="name">
                          {t("ArcPage.StatusAccount")} :
                          <span className="rounded-lg text-xs border bg-red-400 p-1">
                            {t(`ArcPage.desactivate`)}
                          </span>
                        </p>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute  inset-y-0 right-0 z-40  transition ease-in duration-700 patient-modal-info">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ArcInfo;
