import Fuse from "fuse.js";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUserDelete } from "react-icons/ai";
import { FcApproval, FcInvite } from "react-icons/fc";
import { acceptInvitation } from "../../../api/sponsor";
import { getStudyWriters } from "../../../api/study";
import Button from "../../../components/ButtonUI";
import Modal from "../../../components/ModalUI";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import Tooltip from "../../../components/toolTip";
import { listInvestigatorAtom } from "../../../state/listInvstigateurs";
import DeleteWriterModal from "../MyInvestigator/deleteWriterModal";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

type Props = {
  studyId: string;
};

const MesInvitations: React.FC<Props | any> = ({ studyId }) => {
  const [data] = useState<any>(null);
  const [approvalModal, setApprovalModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState() as any;
  const [loading] = useState(false);
  const [, setFilteredData] = React.useState<any>([]);
  const [strFilter, setStrFilter] = React.useState<string>("");
  const [listWriter, setListWriter] = useAtom(listInvestigatorAtom);
  const [listInvitations, setListInvitations] = React.useState<any>([]);
  const [loadList, setLoadList] = React.useState<any>(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectionAccept, setSelectionAccept] = useState(false);
  const { t } = useTranslation() as any;

  useEffect(() => {
    fetchMyAPI();
  }, [loadList]);
  const handleListFiltering = () => {
    const fuse = new Fuse(listWriter, {
      threshold: 0,
      shouldSort: true,
      keys: ["slug", "firstName", "lastName"],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };

  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, data]);

  async function fetchMyAPI(): Promise<void> {
    let response: any = await getStudyWriters(studyId);

    // let res = await getWritersOutStudy(studyId);

    if (!response) {
      makeToast("error", `${t("Investigator.LoadEch")}`);
    }
    setListWriter(response);
  }

  useEffect(() => {
    fetchMyAPI();
  }, [loadList]);
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const writerId = e.target.value;
    const isChecked = listInvitations.includes(writerId);

    if (isChecked) {
      setListInvitations((prevList: any) =>
        prevList.filter((id: string) => id !== writerId)
      );
    } else {
      setListInvitations((prevList: any) => prevList.concat(writerId));
    }
  };
  const handleSubmit = async () => {
    let payload = { usersId: [selectedDoctor.writerId._id] };
    const res = await acceptInvitation(payload);
    console.log("🚀 ~ file: index.tsx:90 ~ handleSubmit ~ res:", res);
  };
  const handleSubmitSelectedList = async () => {
    let payload = { usersId: listInvitations };
    const res = await acceptInvitation(payload);
    console.log("🚀 ~ file: index.tsx:90 ~ handleSubmit ~ res:", res);
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className=" my-patients mt-12 relative ">
        {listInvitations && listInvitations.length > 0 && (
          <Button
            status="secondary"
            className="absolute -top-14 left-2 px-6 py-2.5"
            style={{
              width: "200px",
            }}
            onClick={() => setSelectionAccept(true)}
          >
            {t('missingWording.acceptSelction')}
          </Button>
        )}

        <div className={`patient-list flex flex-wrap justify-between w-full "`}>
          {listWriter &&
          listWriter.filter((x) => !x?.writerId?.activated).length > 0 ? (
            listWriter
              .sort(
                (a: any, b: any) =>
                  Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
              )
              .filter((x) => !x?.writerId?.activated)
              .map((e, i) => {
                return (
                  <div
                    className={`card-patient justify-between bg-white p-4 relative ${
                      selectedDoctor === e?.writerId?._id
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedDoctor(e);
                    }}
                  >
                    <div className="flex patient-info">
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        value={e?.writerId?._id}
                        onChange={(e) => handleCheckboxChange(e)}
                        checked={listInvitations.includes(e?.writerId?._id)}
                      />
                      <div
                        className="rounded-full h-14 w-14 flexCenter mx-5 my-auto"
                        style={{
                          backgroundColor: colorAvatar[5],
                          cursor: "pointer",
                        }}
                      >
                        <FcInvite size="52" />
                      </div>
                      <div className="details " style={{ cursor: "pointer" }}>
                        <div className="flex-grow ">
                          <p
                            className="card-title capitalize "
                            style={{ cursor: "pointer" }}
                          >
                            {e.writerId.firstName} {e.writerId.lastName}
                          </p>
                          <p className="card-subtitle flex flex-row items-center gap-x-8">
                            <span>{e.writerId.email}</span>{" "}
                          </p>
                        </div>
                        <div className="flex flex-row gap-x-2 text-xs items-center ">
                          <p className="text-xs rounded-lg bg-gray-200 px-2 py-1 ">
                            {e.roleId?.name}
                          </p>
                          <p className="rounded-lg bg-gray-200 px-2 py-1 ">
                          {t('missingWording.center')}{e.centerId?.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex md:flex-row xs:flex-col md:gap-x-2 xs:gap-y-2 absolute right-0">
                        <Tooltip
                          tooltipText={t('missingWording.acceptlInvit')}
                          position={"bottomLeft"}
                          clsText={{ fontSize: "10px", padding: "5" }}
                        >
                          <FcApproval
                            className={
                              "text-green-400 cursor-pointer mr-4 p-0.5 border border-green-500 rounded-full"
                            }
                            onClick={() => {
                              setSelectedDoctor(e?._id);
                              setApprovalModal(true);
                            }}
                            size="33"
                          />
                        </Tooltip>
                        <Tooltip
                          tooltipText={t('missingWording.cancelInvit')}
                          position={"bottomLeft"}
                          clsText={{ fontSize: "10px", padding: "5" }}
                        >
                          <AiOutlineUserDelete
                            className="text-red-400 cursor-pointer mr-4 p-1 border border-red-400 rounded-full"
                            onClick={() => {
                              setSelectedDoctor(e);
                              setModalDelete(true);
                            }}
                            size="32"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("SideBar.NoinvitationFound")}</h3>
            </div>
          )}
        </div>
      </div>
      {listInvitations && listInvitations.length > 0 && (
        <Modal
          closeModal={() => setSelectionAccept(false)}
          showModal={selectionAccept}
          className="p-6 "
        >
          <h4 className="text-lg mb-4">
          {t('missingWording.confirmInvitMsg1')}
          </h4>
          <div className="flexCenter mt-6 gap-x-8">
            <Button
              status="secondary"
              className="border-red-300 text-red-300 hover:border-red-300 hover:text-red-300"
              width={"119px"}
              height={"50px"}
              onClick={async () => {
                setSelectionAccept(false);
              }}
            >
              {t("ArcPage.Cancel")}
            </Button>
            <Button
              status="primary"
              width={"119px"}
              height={"50px"}
              onClick={async () => {
                await handleSubmitSelectedList();
                setLoadList((prev) => !prev);
                setSelectionAccept(false);
              }}
            >
             {t('missingWording.accept')}
            </Button>
          </div>
        </Modal>
      )}
      {selectedDoctor && selectedDoctor.writerId && (
        <>
          <Modal
            closeModal={() => setApprovalModal(false)}
            showModal={approvalModal}
            className="p-8"
          >
            <h4 className="text-lg mb-4">
            {t('missingWording.confirmInvitMsg2')}
              {"  " +
                selectedDoctor.writerId?.lastName +
                "  " +
                selectedDoctor.writerId?.firstName +
                " "}{" "}
              ?
            </h4>

            <div className="flexCenter mt-6 gap-x-8">
              <Button
                status="secondary"
                className="border-red-300 text-red-300 hover:border-red-300 hover:text-red-300"
                width={"119px"}
                height={"50px"}
                onClick={async () => {
                  setApprovalModal(false);
                }}
              >
                {t("ArcPage.Cancel")}
              </Button>
              <Button
                status="primary"
                width={"119px"}
                height={"50px"}
                onClick={async () => {
                  let response: any = await handleSubmit();
                  setLoadList((prev) => !prev);
                  // if (response) {
                  //   makeToast("success", t("missingWording.dwt"));
                  //   // setShouldUpdate((up: boolean) => !up);
                  // }
                  setApprovalModal(false);
                }}
              >
          {t('missingWording.accept')}
              </Button>
            </div>
          </Modal>
          {modalDelete && (
            <DeleteWriterModal
              setDeleteArcModal={setModalDelete}
              setShouldUpdate={setLoadList}
              writerId={selectedDoctor?.writerId?._id}
              writresConfigId={selectedDoctor?._id}
              studyId={studyId}
            />
          )}
        </>
      )}
    </>
  );
};
export default MesInvitations;
