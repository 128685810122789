import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { addCro } from "../../api/cro";
import { createCustomerId } from "../../api/stripe";
import { ReactComponent as Pay } from "../../assets/illustrations/pay.svg";
import steto from "../../assets/images/steto.png";
import voir from "../../assets/images/voir.png";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import { FormikWizard } from "../../components/FormikWizard";
import InputOld from "../../components/Input";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import StripePaymentMethod from "../../components/StripePaymentMethod";
import Tooltip from "../../components/toolTip";
import { loadLocaleLang } from "../../helper";
import Signin from "../ConfirmEmail";
import "./styles.scss";

const Signup: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation() as any;
  const [signupMode, setSignupMode] = useState(false);
  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");
  const ProgressBar = (progress: number) => {
    const progressPercentage = (100 / 2) * (progress + 1);

    return (
      <div className=" h-2 w-full bg-gray-300 border rounded-md">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full ${
            progressPercentage < 75 ? "bg-indigo-500" : "bg-green-300"
          }`}
        ></div>
      </div>
    );
  };
  const createCustomerIdReq = async (values) => {
    try {
      const res = await createCustomerId(values);
      if (res) {
        return res.data.stripeCustomerId;
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="doctor-signup flexColCenter min-h-screen flex justify-center">
          <div className=" z-40 top-8 flexColCenter firstModal2 ">
            <h2 className="text-4xl">{t("Organisation.TitleWelcome")} 👋 </h2>
            <div className="m-2 w-full">
              <ul className="stepper " data-mdb-stepper="stepper">
                <FormikWizard
                
                  initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                    lastName: "",
                    firstName: "",
                    lang: loadLocaleLang(),
                    subscriptionId: "",
                    stripeSubscriptionId: "",
                    stripeCustomerId: "",
                    constCGU: false,
                    cguModalIsOpen: false,
                  }}
                  onSubmit={async (values) => {
                    values.constCGU = true;
                    values.stripeSubscriptionId = values.subscriptionId;
                    if (values.subscriptionId === "") {
                      makeToast(
                        "error",
                        "Échec de l'enregistrement de l'abonnement !"
                      );
                    } else {
                      setSignupMode(true);
                      const response = await addCro(values);
                      console.log(
                        "🚀 ~ file: index.tsx:103 ~ onSubmit={ ~ response:",
                        response
                      );
                      if (response && response.statusCode === 201) {
                        makeToast("success", `${t("SignUp.SuccessMsg")}`);
                        
                      }
                      // else if (response && response.statusCode === 422) {
                      //   return makeToast("error", `${t("SignUp.croExist")}`);
                      // }
                    }
                  }}
                  activeStepIndex={0}
                  steps={[
                    {
                      component: ({
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched,
                        
                      
                      }) => (
                        <>
                          <form className="p-2">
                            <div className="flexRowCenter img-top">
                              <img
                                src={steto}
                                width="46px"
                                height="46px"
                                alt="steto"
                              />
                            </div>
                            <div className="title-container flexColCenter mb-5">
                              <p> {t("SignUp.im")}</p>
                              <span className="flexRowCenter text-black font-black text-center text-2xl">
                                {t("SignUp.doctor")}
                              </span>
                            </div>
                            <Input
                              id="lastName"
                              labelText={t("SignUp.nLastName")}
                              placeholder={t("SignUp.nLastName")}
                              type="text"
                              // error={errors.email}
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // require
                            />

                            <Input
                              id="firstName"
                              labelText={t("SignUp.nFirstName")}
                              placeholder={t("SignUp.nFirstName")}
                              type="text"
                              // error={errors.email}
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              // require
                            />

                            <Input
                              id="email"
                              labelText={t("SignUp.email")}
                              placeholder={t("SignUp.email")}
                              type="email"
                              // error={errors.email}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              // require
                            />

                            <div className="relative">
                              <Input
                                id="password"
                                labelText={t("SignUp.psw")}
                                placeholder="********"
                                type={passwordFormType1}
                                // error={errors.password}
                                className="pr-10"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                }}

                                // require
                              />
                              <div className="absolute right-10 top-5">
                                <Tooltip
                                  position="top"
                                  tooltipText={t("SignUp.pswConstraint")}
                                  clsText={{ width: "220px", bottom: "200%" }}
                                >
                                  <span className=" text-white text-xs font-bold bg-blue-500 rounded-full  w-5 h-5 cursor-pointer flexCenter">
                                    i
                                  </span>
                                </Tooltip>
                              </div>
                              <img
                                src={voir}
                                alt="voir"
                                className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPasswordFormType1(
                                    passwordFormType1 === "password"
                                      ? "text"
                                      : "password"
                                  );
                                }}
                              />
                            </div>
                            <div className="relative">
                              <Input
                                id="confirmPassword"
                                labelText={t("SignUp.confirmPSW")}
                                placeholder="********"
                                type={passwordFormType2}
                                className="pr-10"
                                // error={errors.password}
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // require
                              />
                              <img
                                src={voir}
                                alt="voir"
                                className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPasswordFormType2(
                                    passwordFormType2 === "password"
                                      ? "text"
                                      : "password"
                                  );
                                }}
                              />
                            </div>

                            <div className="mt-8 ">
                              <InputOld
                                id="constCGU"
                                labelText={t("SignUp.CGU")}
                                placeholder="Enter your address"
                                type="checkbox"
                                // error={errors.constCGU}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="mr-2"
                              />
                            </div>
                            {/* <div className="flexRowCenter">
                              <Button
                                type="submit"
                                // disabled={isSubmitting}
                                status="primary"
                                className="btn mb-6"
                                ///onClick={() => handleSubmit()}
                              >
                                {t("SignUp.register")}
                              </Button>
                            </div> */}
                          </form>
                        </>
                      ),
                    },
                    {
                      component: ({
                        errors,
                        values,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                    
                      }) =>
                        values.subscriptionId === "" ? (
                          <>
                            <StripePaymentMethod
                              setFieldValue={setFieldValue}
                              auth={false}
                              values={values}
                              handleSubmitGlobal={handleSubmit}
                            />
                          </>
                        ) : (
                          <div>
                           <>
                            <Signin
                              email={values.email}
                              signupMode={signupMode}
                            />
                          </>
                          </div>
                        ),
                      validationSchema: Yup.object().shape({
                        subscriptionId: Yup.string().required("required"),
                      }),
                    },
                    // {
                    //   component: ({
                    //     errors,
                    //     values,
                    //     handleChange,
                    //     setFieldValue,
                    //     handleSubmit,
                    //   }) =>
                    //     values.subscriptionId && (
                    //       <>
                    //         <Signin
                    //           email={values.email}
                    //           signupMode={signupMode}
                    //         />
                    //       </>
                    //     ),
                    // },
                  ]}
                >
                  {({
                    renderComponent,
                    handlePrev,
                    handleNext,
                    isPrevDisabled,
                    isLastStep,
                    currentStepIndex,
                    values,
                    setErrors,
                    setFieldValue,
                    isNextDisabled,
                  }) => (
                    <>
                      {currentStepIndex
                        ? ProgressBar(currentStepIndex)
                        : ProgressBar(0)}
                    
                      <ol className="c-stepperInsc">
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 0
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            {t("SignIn.step1")}
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li>

                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 1
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            {t("SignIn.step2")}
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li>
                        {/* <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 2
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            {t("SignIn.step3")}
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li> */}
                      </ol>
                      {renderComponent()}
                      {(currentStepIndex !== 2 && currentStepIndex !== 1) && (
                        <Button
                          status="primary"
                          className="float-right flexCenter mx-5 my-5"
                          width="100px"
                          height="50px"
                          type="button"
                          onClick={
                            currentStepIndex == 0
                              ? async () => {
                                  const regexPassword =
                                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
                                  if (!values.email)
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.fillEmail")}`
                                    );
                                  if (!values.password)
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.fillPSW")}`
                                    );
                                  if (!values.lastName)
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.fillLastName")}`
                                    );
                                  if (!values.firstName)
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.fillName")}`
                                    );
                                  if (!values.confirmPassword)
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.fillConfirmedPSW")}`
                                    );

                                  if (!regexPassword.test(values.password))
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.pswConstraint")}`
                                    );

                                  if (
                                    values.password !== values.confirmPassword
                                  )
                                    return makeToast(
                                      "warning",
                                      `${t("SignUp.pswConfirmedConstraint")}`
                                    );
                                  const CustomerId = await createCustomerIdReq({
                                    email: values.email,
                                    lastName: values.lastName,
                                    firstName: values.firstName,
                                  });
                                  if (CustomerId && CustomerId !== null) {
                                    setFieldValue(
                                      "stripeCustomerId",
                                      CustomerId
                                    );
                                    handleNext();
                                  } else if (CustomerId == null) {
                                    makeToast(
                                      "error",
                                      "Email d'utilisateur existe déjà. Vérifiez vos informations !"
                                    );
                                    setErrors({ email: "email alredy Used" });
                                  }
                                }
                              : handleNext
                          }
                          disabled={isNextDisabled}
                        >
                          {isLastStep
                            ? `${t("Organisation.Finish")}`
                            : `${t("Organisation.Next")}`}
                        </Button>
                      )}

                      {/* <Button
                        status="secondary"
                        className="float-left flexCenter my-5"
                        width="100px"
                        height="50px"
                        type="button"
                        onClick={handleLogout}
                      >
                        {t("Organisation.Cancel")}
                      </Button> */}
                      {/* {!isPrevDisabled && (
                        <>
                          <Button
                            status="primary"
                            className="float-right flexCenter mx- 5 my-5"
                            width="100px"
                            height="50px"
                            type="button"
                            onClick={handlePrev}
                            hidden={isPrevDisabled}
                          >
                            {t("Organisation.Precedent")}
                          </Button>
                        </>
                      )} */}
                    </>
                  )}
                </FormikWizard>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
