import axios from "axios";
import makeToast from "../components/Snackbar";

import {
  DOCTOR_LOGOUT_URL,
  USER_VERIFY_URL,
  PATIENT_LOGOUT_URL,
  USER_UPDATE_PASSWORD,
  GET_USER_BY_ID,
  REFRESH_TOKEN,
  CONFIRM_EMAIL,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  RESEND_CONFIRMATION_CODE,
  AUTH_REGISTER_URL,
  UPDATE_DATA__USER,
  GET_DATA_USER,
  UPDATE_LANG__USER,
  ASSOCIATE_USER_TO_AUTHENTICATOR,
  VERIFY_USER_SOFTWARE,
  VERIFY_USER_MFA,
  ACTIVATE_MFA_USER,
  CRO_UPDATE_DATA,
} from "../config/api";
import { get, post } from "../helper/apiMethods";

export async function login(credentials: {
  email: string;
  password: string;
  rememberMe?: boolean;
  timezoneId: string;
}) {
  try {
    const res = (await axios.post(AUTH_REGISTER_URL, credentials)) as any;

    if (res?.data?.data?.message === "user is blocked") {
      makeToast(
        "warning",
        "Ce compte est bloqué veuillez contacter notre support"
      );
    }
    if (res?.data?.data?.token?.accessToken) {
      localStorage.setItem("token", res?.data?.data?.token?.accessToken);
      // localStorage.setItem("idToken",res?.data?.data?.token?.idToken);
    }
    if (res?.data?.data?.token?.refreshToken) {
      localStorage.setItem(
        "refreshToken",
        res?.data?.data?.token?.refreshToken
      );
      // localStorage.setItem("idToken",res?.data?.data?.token?.idToken);
    }

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function resendVerificationCode(payload) {
  console.log(
    "🚀 ~ file: auth.ts:51 ~ resendVerificationCode ~ payload:",
    payload
  );
  try {
    const res = await axios.post(RESEND_CONFIRMATION_CODE, payload);
    console.log("🚀 ~ file: auth.ts:53 ~ resendVerificationCode ~ res:", res);
    return res.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: auth.ts:55 ~ resendVerificationCode ~ error:",
      error
    );
    return Promise.reject(error);
  }
}

export async function verify() {
  try {
    const res = await get(USER_VERIFY_URL);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getWorkingSpaces() {
  try {
    const res = await get(`${USER_VERIFY_URL}/getWorkingSpaces`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function logout() {
  try {
    const res = await post(DOCTOR_LOGOUT_URL, {});

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logoutPatient() {
  try {
    const res = await axios.post(PATIENT_LOGOUT_URL, {});

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatePassword(payload) {
  try {
    const res = await axios.post(USER_UPDATE_PASSWORD, {
      password: payload.password,
      newpassword: payload.confirmpassword,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUserById(userId) {
  try {
    const res = await axios.get(`${GET_USER_BY_ID}/${userId}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getUserInfo(values) {
  try {
    const res = await post(`${GET_DATA_USER}`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function refreshToken() {
  try {
    const res = await axios.create().get(`${REFRESH_TOKEN}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function confirmEmail(payload) {
  try {
    const res = await axios.post(`${CONFIRM_EMAIL}`, payload);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const forgetPassword = async (values) => {
  try {
    const res = await axios.post(FORGET_PASSWORD, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetPassword = async (values) => {
  try {
    const res = await axios.post(RESET_PASSWORD, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDataUserAPI = async (values, userId) => {
  try {
    const res = await post(`${UPDATE_DATA__USER}/${userId}`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateUserLang = async (userId, values) => {
  try {
    const res = await post(`${UPDATE_LANG__USER}/${userId}`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const firstUpdatePassword = async (values) => {
  try {
    const res = await post(`${USER_VERIFY_URL}/update-password`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const associateUserToAuthenticatorSoftware = async (values) => {
  try {
    const res = await post(`${ASSOCIATE_USER_TO_AUTHENTICATOR}`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const activateMfa = async (values) => {
  try {
    const res = await post(`${ACTIVATE_MFA_USER}`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const verifySoftwareToken = async (values) => {
  try {
    const res = await post(`${VERIFY_USER_SOFTWARE}`, values);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyMFA = async (values) => {
  try {
    const res = await post(`${VERIFY_USER_MFA}`, values);
    console.log("🚀 ~ file: auth.ts:224 ~ verifyMFA ~ res:", res);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateCroData = async (payload) => {
  try {
    const res = await post(`${CRO_UPDATE_DATA}`, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
