export const Organisation = {
  fr: {
    errorSubmit: "Une erreur s'est produite ",
    TitleWelcome: "Bienvenue sur Ksearch",
    CompleteProfile: "Compléter votre profil",
    ConfigOrg: "Configurer votre organisation",
    Precedent: "Précédent",
    Cancel: "Annuler",
    Finish: "Terminer",
    Next: "Suivant ",
    NameOrg:"Nom",
    DescOrg:"Description",
    AdressOrg:"Adresse postale",
    PaysOrg:"Pays",
    VilleOrg:"Ville",
    CodeOrg:"code postal",
    successAddCentre:"Centre crée avec succès",

    Name: "Nom",
    Last: "Prénom",
    birth: "Date de naissance (JJ/MM/AAAA)",
    gender: "gender",
    phone: "Numéro de téléphone",
    country: "Votre pays",
    city: "Votre ville",
    codePost: "Votre code postal",

    Vs: "Autre",
    Fs: "Femme",
    Hs: "Homme",
  },
  en: {
    Vs: "Other",
    Fs: "Female",
    Hs: "Male",

    errorSubmit: "Une erreur s'est produite ",
    TitleWelcome: "Welcome to Ksearch",
    CompleteProfile: "Complete your profile",
    ConfigOrg: "Configure your organization",
    Precedent: "Previous",
    Cancel: "Cancel",
    Finish: "Finish",
    Next: "Next ",

    NameOrg: "Name",
    DescOrg: "Description",
    AdressOrg: "Postal address",
    PaysOrg: "Country",
    VilleOrg: "City",
    CodeOrg: "Postal code",
    successAddCentre: "Centre created successfully",
    Name: "Name",
    Last: "First name",
    birth: "Date of birth (DD/MM/YYYY)",
    gender: "gender",
    phone: "Phone number",
    country: "Your country",
    city: "Your city",
    codePost: "Your postal code",
  },
};
