import { MouseEventHandler } from 'react';
import Icon from '../Icon';

type Props = {
  toggle: MouseEventHandler<HTMLOrSVGElement>;
  absolute?: boolean;
};
const CloseModal = (props: Props) => {
  const { toggle, absolute } = props;
  return absolute ? (
    <div className='absolute cursor-pointer top-1 right-1 '>
      <Icon
        className='bg-gray-200 bg-opacity-50 rounded-lg  hover:shadow-2xl '
        isIcon={true}
        name='close'
        onClick={toggle}
      />
    </div>
  ) : (
    <div className='flex justify-end cursor-pointer mt-3 mr-3 '>
      <Icon
        className='bg-gray-200 bg-opacity-50 rounded-lg  hover:shadow-2xl '
        isIcon={true}
        name='close'
        onClick={toggle}
      />
    </div>
  );
};

export default CloseModal;
