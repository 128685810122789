import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteSurveyById } from "../../api/survey";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";
import Input from "../../components/Input";

export const DeleteSurveyModal: React.FC<any> = ({
  surveyDetails,
  setShouldUpdate,
  closeModal,
  hasDependecy,
}) => {
  console.log(
    "🚀 ~ file: deleteSurveyModal.tsx:13 ~ hasDependecy:",
    hasDependecy
  );
  const { t } = useTranslation() as any;
  const [stateFormName, setStateFormName] = useState("");
  return (
    <div className="w-full h-auto px-5 pb-5 pt-10  ">
      <h4 className="text-lg mb-4">{t("missingWording.fddh")}</h4>
      {hasDependecy && hasDependecy.length > 0 && (
        <>
          <h3 className="mb-4 text-sm font-semibold text-justify border-red-400 text-red-400 hover:border-red-500 hover:text-red-500 ">
            {t("missingWording.hasDependecy")}
          </h3>
       
        </>
      )}
         <Input
            labelProps={{
              className: "color-primary-grey-title text-sm font-semibold",
            }}
            autoFocus={true}
            //  labelText={t("Centre.nameCenter")}
            labelText={`${t("missingWording.centerDelete_1") } "${surveyDetails?.name}" ${t("missingWording.centerDelete_1_1")}`}
            className=" mt-1 w-full border border-gray-300 hover:border-gray-400 focus:border-gray-400 rounded-md shadow-sm px-2 py-2"
            placeholder=""
            onChange={(e) => setStateFormName(e.target.value)}
          />
      <div className="flex justify-between mt-8 ">
        <Button
          status="secondary"
          width={"119px"}
          height={"50px"}
          onClick={async () => {
            closeModal();
          }}
        >
          {t("ArcPage.Cancel")}
        </Button>
        <Button
          status="secondary"
          width={"119px"}
          className={`${
            surveyDetails?.name === stateFormName
              ? "border-red-300 text-red-300 hover:border-red-400 hover:text-red-400"
              : "border-gray-400 text-gray-400 hover:border-gray-500 hover:text-gray-500"
          }`}
          height={"50px"}
          onClick={async () => {
            if (surveyDetails?.name !== stateFormName) {
              if (surveyDetails?.name === "" || !stateFormName)
                makeToast(
                  "warning",
                  `${t("missingWording.formDelete_2") }`
                );
              else
                makeToast("warning", `${t("missingWording.formDelete_3") }`);
              return null;
            }
            let response: any = await deleteSurveyById(surveyDetails._id);
            if (response) {
              makeToast("success", t("missingWording.fddt1"));
              setShouldUpdate((up: boolean) => !up);
              closeModal();
            } else {
              makeToast("error", t("missingWording.fddt2"));
            }
          }}
        >
          {t("ArcPage.Delete")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteSurveyModal;
