

import {  ORG_CREATE_URL } from "../config/api";
import { post } from "../helper/apiMethods";

export const addOrg = async (values) => {
    try {  

      const res = await post(ORG_CREATE_URL, values);
  
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };