import { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideBarWRITER from "../components/SideBarWriter";

import "./styles-layout-doctor.scss";

const LayoutWRITER = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/firstLogin") setShowSideBar(false);

    else setShowSideBar(true);

  }, [location.pathname]);
  return (
    <div className="layout-doctor flex">
      {showSideBar && <SideBarWRITER />}
      <div className="w-full md:pl-4 Studies_ParticipantSection">{children}</div>
    </div>
  );
};

export default LayoutWRITER;
