import {
  MASS_WRITER_REGISTER_URL,
  WRITER_LIST_URL,
  WRITER_PUBLIC_REGISTER_URL,
  WRITER_REGISTER_URL,
  WRITER_STUSIES_URL,
} from "../config/api";
import { post, get } from "../helper/apiMethods";

export const addWriter = async (values) => {
  let res;
  try {
    let payload = { ...values };
    res = await post(WRITER_REGISTER_URL, payload);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addWriterMass = async (values, stuudyId) => {
  let res;
  try {
    res = await post(`${MASS_WRITER_REGISTER_URL}/${stuudyId}`, values);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllWriters = async () => {
  let res;
  try {
    res = await get(WRITER_LIST_URL);
    return res.writerList;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getWritersOutStudy = async (stuudyId) => {
  let res;
  try {
    res = await get(WRITER_LIST_URL + stuudyId);
    return res.writerList;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getStudiesForWriter = async (writerId, page, limit, filter) => {
  let res;
  try {
    res = await get(
      `${
        WRITER_STUSIES_URL + "/" + writerId
      }?page=${page}&limit=${limit}&filter=${filter}`
    );
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
