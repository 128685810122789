import { Box, HStack } from "@chakra-ui/react";
import { ViewElement } from "../Elements";

const FormViewer = ({ elements, values }) => {
  const displayObject = elements.reduce((acc, element, index) => {
    if (
      element.type === "Header" ||
      element.type === "Paragraph" ||
      element.type === "LineBreak" ||
      element.type === "Image"
    )
      acc.push(element);
    else {
      const val = acc[acc.length - 1];
      if (Array.isArray(val)) {
        val.push(element);
        acc[acc.length - 1] = val;
      } else {
        acc.push([element]);
      }
    }
    return acc;
  }, []);
  const displayElement = () => {
    return displayObject.map((element) => {
      if (Array.isArray(element)) {
        
        return (
          <div className="w-full min-w-max ">
            {element.map((e, index) => {
              let value = values?.[e.field_name];
              console.log("🚀 ~ file: index.tsx:27 ~ returndisplayObject.map ~ element:", value)
              if(Array.isArray(value)){
                if(value.length > 0 && typeof (value[0]) === 'object')
                {
                 value =  JSON.stringify(value)
                }

              }
              return (
                <Box
                  className="border p-2 px-4 rounded-lg my-1 border-black  "
                  style={{ minWidth: "450px" }}
                >
                  <HStack spacing={4}>
                    <span className="w-1/2 font-bold " key={e.id}>
                      {e.label}{" "}
          
                    </span>
                    <ViewElement key={e.id} element={e} value={value} />
                  </HStack>
                </Box>
              );
            })}
          </div>
        );
      } else {
        const value = values?.[element.field_name];
        return <ViewElement key={element.id} element={element} value={value} />;
      }
    });
  };

  return displayElement();
};

FormViewer.defaultProps = {
  descriptionProps: {
    bordered: true,
    size: "small",
    column: { sm: 1, xs: 1 },
  },
  descriptionItemProps: {
    labelStyle: { width: "20%" },
  },
};

export default FormViewer;
