import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCheckCircle } from "react-icons/fa";
import './stylesThxPage.scss'
const ThanksPageForm = () => {
   
    const { t } = useTranslation() as any;
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        // Delay the animation to allow the component to render first
        const timeout = setTimeout(() => {
            setMounted(true);
        }, 100);

        return () => clearTimeout(timeout);
    }, []);
    return (
        <div className="flex h-screen w-full flex-col items-center justify-center bg-gray-100 p-4 text-center">
        <FaRegCheckCircle size={68}  className={`text-green-500 ${mounted ? 'animate-stamp' : 'hidden'}`} />
        <h1 className={`my-4 text-5xl font-bold ${mounted ? 'stamp-animation' : 'hidden'}`}>{t('ThanksPage.thankYou')}</h1>
        <p className={`mt-4 text-2xl ${mounted ? 'stamp-animation' : 'hidden'}`}>{t('ThanksPage.msg01')}</p>
        <p className="mt-1 text-sm text-gray-600">{t('ThanksPage.msg02')}</p>
      </div>
    )
}
export default ThanksPageForm;