import moment from "../../../helper/moment";

/* eslint-disable no-restricted-globals */
type Value = any;

/**
 * Check if the value is a string.
 */
const testIsString = (value: Value): boolean =>
  typeof value === "string" || value instanceof String;

/**
 * Check if the value is a number.
 */
const testIsNumber = (value: Value): boolean =>
  typeof parseFloat(value) === "number" &&
  !isNaN(parseFloat(value)) &&
  !isNaN(value);

/**
 * Check if the value is null or undefined.
 */
const testIsNullOrUndefined = (value: Value): boolean =>
  value === null || value === undefined;

/**
 * Check if the value is empty.
 */
const testIsEmpty = (value: Value): boolean =>
  value === "" || testIsNullOrUndefined(value);

// const testIsObject = x => x && typeof x === 'object' && x.constructor === Object;
const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

/**
 * Check if the value exists.
 * @returns {function} function that accept a value and
 * return true if the value exists, false instead.
 */
export const isRequired =
  () =>
  (value: Value): boolean => {
    if (Array.isArray(value)){
      return value.length > 0 ? true : false
    }
    const res = !!value || value === 0;
    return res;
  };
/**
 * Check if the value is a string and is not empty.
 */
export const isNotEmptyString =
  () =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return true;
    if (!testIsString(value)) return false;
    

    return !!(value || "").match(/^(?!\s*$).+/);
  };

/**
 * Check if the value is an array and is not empty.
 */
export const isNotEmptyArray =
  () =>
  (value: Value): boolean => {
if (Array.isArray(value) && value.length > 0) return false;
    else return true;
  };

/**
 * Check if the value is a valid email.
 */
export const isEmail =
  () =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return true;
    if (!testIsString(value)) return false;

    return !value || emailRegex.test(value);
  };

/**
 * Check if the value is a number.
 */
export const isNumber =
  () =>
  (value: Value): boolean =>
    testIsEmpty(value) || testIsNumber(value);

/**
 * Check if the value is greater than the given minimum.
 */
export const isMinNumber =
  (min: number) =>
  (value: Value): boolean =>
    testIsEmpty(value) || (testIsNumber(value) && parseFloat(value) >= min);

/**
 * Check if the value is lower than the given maximum.
 */
export const isMaxNumber =
  (max: number) =>
  (value: Value): boolean =>
    testIsEmpty(value) || (testIsNumber(value) && parseFloat(value) <= max);

/**
 * Check if the value is between the minimum and the maximum.
 */
export const isInRangeNumber =
  (min: number, max: number) =>
  (value: Value): boolean =>
    testIsEmpty(value) ||
    (testIsNumber(value) &&
      parseFloat(value) >= min &&
      parseFloat(value) <= max);

/**
 * Check if the value is a percentage, between 0 and 100.
 */
export const isPercentage =
  () =>
  (value: Value): boolean =>
    testIsEmpty(value) ||
    (testIsNumber(value) && parseFloat(value) >= 0 && parseFloat(value) <= 100);

/**
 * Check if the value has the given length.
 */
export const isLength =
  (length: number) =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return true;

    return (
      (testIsString(value) || Array.isArray(value)) && value.length === length
    );
  };

/**
 * Check if the value has a length greater than the given minimum.
 */
export const isMinLength =
  (min: number) =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return true;

    return (testIsString(value) || Array.isArray(value)) && value.length >= min;
  };

/**
 * Check if the value has a length lower than the given maximum.
 */
export const isMaxLength =
  (max: number) =>
  (value: Value): boolean =>
    testIsEmpty(value) ||
    ((testIsString(value) || Array.isArray(value)) && value.length <= max);

/**
 * Check if the value has match a REGEX pattern.
 */
export const isPattern =
  (pattern: string | RegExp) =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return false;
    if (Array.isArray(value)) {
      const newStr = value.join(",");
      const regex = new RegExp(pattern, "g");
      const result = testIsString(newStr) && regex.test(newStr);
      return result;
    } else {
      const regex = new RegExp(pattern, "g");
      return testIsString(value) && regex.test(value);
    }
  };
  function createRegex(pattern: string): RegExp {
    try {
    return new RegExp(pattern,'i');
    } catch (error:any) {
      console.log("🚀 ~ createRegex ~ error:", error)
      throw new Error(error)
    }
  }
  export const isRegExp =
  (pattern:string) =>
  (value: any): boolean => {
    try {
      if (!value || (Array.isArray(value) && value.length === 0)) return false;
    
      if (Array.isArray(value)) {
        console.log("🚀 ~ X:")
        const newStr = value.join(",");
        return typeof newStr === 'string' && createRegex(pattern).test(newStr);
      } else {
        console.log("🚀 ~ Y:")
        console.log("🚀 ~ YX:",createRegex(pattern).test(value))
        return typeof value === 'string' && createRegex(pattern).test(value);
      }
    } catch (error:any) {
         console.log("🚀 ~ error:", error)
         throw new Error(error)
       }
   
  
    
   
  };
   
export const onlyFutureDate =
  () =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return false;

    // For future dates

    return moment(value).isAfter(moment(new Date()), "day");
  };
export const onlyPastDate =
  () =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return false;

    // For future dates

    return moment(value).isSameOrBefore(moment(new Date()), "day");
  };
export const dateRange =
  (minDate, maxDate, dateFormat) =>
  (value: Value): boolean => {
    if (testIsEmpty(value)) return false;
    let dateToCheck = moment(
      value,
      dateFormat === "DD-MM-YYYY" ? "YYYY-MM-DD" : "YYYY/MM/DD"
    );
    // For future dates

    return dateToCheck.isBetween(
      moment(minDate, dateFormat),
      moment(maxDate, dateFormat)
    );
  };

export const minInfuturYears =
  (years: number) =>
  (value: Value): boolean => {
    // Parse the date string into a Moment.js date object
    const dateToCheck = moment(value);
    // Make sure the date string was correctly parsed into a Moment.js date object
    if (!dateToCheck.isValid()) {
      console.error("Invalid date format");
      return false;
    }
    // Get the current date
    const currentDate = moment(new Date());
    // Calculte the date in the future a certain number of years from now
    const dateLimit = currentDate.add(years, "years");
    // Make sure the date limit is a valid Moment.js date object
    if (!dateLimit.isValid()) {
      console.error("Invalid date limit");
      return false;
    }
    // Check if the date to check is in the future a certain number of years from now
    return dateToCheck.isAfter(dateLimit);
  };
export const maxInfuturYears =
  (years: number) =>
  (value: Value): boolean => {
    // Parse the date string into a Moment.js date object
    const dateToCheck = moment(value);
    // Get the current date
    const currentDate = moment(new Date());
    // Check if the date to check is in the future a certain number of years from now
    const dateLimit = currentDate.add(years, "years");
    // Make sure the date limit is a valid Moment.js date object
    if (!dateLimit.isValid()) {
      console.error("Invalid date limit");
      return false;
    }
    return dateToCheck.isBefore(dateLimit);
  };
export const maxInPastYears =
  (years: number) =>
  (value: Value): boolean => {
    // Parse the date string into a Moment.js date object
    const dateToCheck = moment(value);
    const currentDate = moment(new Date());
    const dateLimit = currentDate.subtract(years, "years");
    // Check if the date to check is in the future a certain number of years from now
    return dateToCheck.isAfter(dateLimit);
  };
export const minInPastYears =
  (years: number) =>
  (value: Value): boolean => {
    const dateToCheck = moment(value);
    const currentDate = moment(new Date());
    const dateLimit = currentDate.subtract(years, "years");
    return dateToCheck.isBefore(dateLimit);
  };
