import { Form, Formik } from "formik";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { firstUpdatePassword, logout } from "../../api/auth";
import voir from "../../assets/images/voir.png";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import makeToast from "../../components/Snackbar";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { validation } from "./validation";

const FirstLogin = () => {
  const [user, setUser] = useAtom(userAtom);
  const [passwordFormType, setPasswordFormType] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");
  const { t } = useTranslation();
  const handleLogout = async () => {
    try {
      await logout();

      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };
  return (
    <div className="flex h-screen w-full justify-center items-center">
      <div className="p-8 bg-white rounded-lg w-3/4">
        <h1
          className="text-center mt-2 mb-12"
          style={{
            color: "#a6a9c8",
          }}
        >
          {t("missingWording.flh1")}
        </h1>
        <Formik
          initialValues={{ newPasswordConfirm: "", newPassword: "" }}
          onSubmit={async (values) => {
            try {
              if (
                values.newPassword &&
                values.newPassword !== "" &&
                values.newPassword === values.newPasswordConfirm
              ) {
                const res = await firstUpdatePassword({
                  password: values.newPassword,
                  username: user?.email,
                });
                if (res && res?.data) {
                  setUser({ ...res?.data });

                  makeToast("success", t("missingWording.flt1"));
                  history.push("/");
                } else {
                  makeToast("error", t("missingWording.pht2"));
                }
              } else {
                if (
                  !values.newPasswordConfirm ||
                  values.newPasswordConfirm === ""
                ) {
                  makeToast("warning", t("missingWording.flt2"));
                } else if (!values.newPassword || values.newPassword !== "") {
                  makeToast("warning", t("missingWording.flt3"));
                } else if (values.newPassword !== values.newPasswordConfirm) {
                  makeToast("warning", t("missingWording.flt4"));
                }
              }
            } catch (err) {
              console.log("[error] add Patient -on Submit :", err);
            }
          }}
          validationSchema={validation(t)}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit } = props;
            console.log(
              "🚀 ~ file: index.tsx:75 ~ FirstLogin ~ errors",
              errors
            );

            return (
              <>
                <Form className="max-w-1/2  flex flex-col justify-center items-center ">
                  <div className="flex flex-col justify-center gap-y-0.5 max-w-1/2 min-w-1/2">
                    <div className="relative">
                      <Input
                        onChange={handleChange}
                        value={values.newPassword}
                        required
                        type={passwordFormType}
                        className="min-w-full"
                        name="newPassword"
                        style={{ maxWidth: "100%" }}
                        labelText={t("missingWording.fli1")}
                        error={errors.newPassword}
                      />
                      <img
                        src={voir}
                        alt={"voir mot de passe"}
                        className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setPasswordFormType(
                            passwordFormType === "password"
                              ? "text"
                              : "password"
                          );
                        }}
                      />
                    </div>
                   
                  </div>
                  {errors?.newPassword && (
                      <span className="text-xs text-gray-400 -mt-2 mb-2">
                        {errors?.newPassword}
                      </span>
                    )}
                  <div className="flex flex-col justify-center gap-y-0.5 max-w-1/2 min-w-1/2">
                    <div className="relative">
                      <Input
                        onChange={handleChange}
                        value={values.newPasswordConfirm}
                        required
                        type={passwordFormType2}
                        error={errors.newPasswordConfirm}
                        className=" "
                        name="newPasswordConfirm"
                        style={{ maxWidth: "100%" }}
                        labelText={t("missingWording.fli2")}
                      />
                      <img
                        src={voir}
                        alt={"voir mot de passe"}
                        className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setPasswordFormType2(
                            passwordFormType2 === "password"
                              ? "text"
                              : "password"
                          );
                        }}
                      />
                    </div>
                  
                  </div>
                  {errors?.newPasswordConfirm && (
                      <span className="text-xs text-gray-400 -mt-2 mb-2">
                        {errors?.newPasswordConfirm}
                      </span>
                    )}
                </Form>
                <div className="flex justify-between mt-10 pr-8 pl-8">
                  <Button
                    status="secondary"
                    width="150px"
                    height="50px"
                    className="float-left mb-4 "
                    onClick={handleLogout}
                  >
                    {t("missingWording.flb1")}
                  </Button>
                  <Button
                    status="primary"
                    width="150px"
                    height="50px"
                    className="float-right mb-4"
                    type="submit"
                    onClick={async () => {
                      handleSubmit();
                    }}
                  >
                    {t("missingWording.flb2")}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default FirstLogin;
