import Fuse from "fuse.js";
import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllArchivedStudies, getAllStudies } from "../../api/cro";
import Input from "../../components/FloatInput";
import RenderDivs from "../../components/StudyList";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { SelectedStudyAtom } from "../../state/selectedStudyAtom";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import "./styles.scss";
import makeToast from "../../components/Snackbar";
import { downloadExcel } from "../../api/study";

const DashboardARC = () => {
  const [user] = useAtom(userAtom);
  const [, setSelectedStudyBoolean] = useAtom(SelectedStudyAtom);
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [loading, setLoading] = useState(false);
  const [dateNow] = useState(moment());
  const [studiesList, setStudiesList] = useState([]) as any[];
  const [step, setStep] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [projectsListFiltred, setProjectsListFiltered] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [, setTotalPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const { t } = useTranslation() as any;
  const typingTimeoutRef = useRef(null) as any;
  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);
  const fetchStudies = async () => {
    setLoading(true);
    const res =
      step === 1
        ? await getAllArchivedStudies(page, 8, textSearch)
        : await getAllStudies(page, 8, textSearch);
    if (res && res?.studies) {
      setStudiesList(res?.studies);
      setTotalResult(res?.count);
      setTotalPage(Math.ceil(res.length / 10) - 1);
      setLoading(false);
    } else {
      setLoading(false);
    }
    return res;
  };

  const menu = [t("SideBar.ActiveStudy"), t("SideBar.Archive")];

  useEffect(() => {
    setPath("/");
    setStudySelectedId(null);
    localStorage.setItem("step", "0");
    if (user?.type !== "cro" && user?.firstLogin && !user?.invited) {
      history.push("/firstLogin");
    } else fetchStudies();
  }, [page, textSearch,step]);
  const _handleDownloadExcel = async (studyId, type) => {
    try {
      await downloadExcel(studyId, type);
      makeToast("success", t("DashboardCro.downloadEXCELSuccess"));
      history.push("/exports");
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        if (
          errorMessage === "Study already has a pending export being processed."
        ) {
          makeToast("warning", t("DashboardCro.downloadEXCELPending"));
          history.push("/exports");
        } else {
          makeToast("error", t("DashboardCro.downloadEXCELError"));
        }
        // TODO: Handle the specific error message as needed
      } else {
        console.log("Generic Error:", error);
        // TODO: Handle the generic error case
        makeToast("error", t("DashboardCro.downloadEXCELError"));
      }
    }
  };
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };
  return (
    <>
      <div className="dashboard-doctor w-full">
        <header className="flex justify-between  pl-4 lg:pl-0 md:items-center w-full flex-col lg:flex-row">
          <div className="mb-4 lg:mb-auto relative">
            <h1>{t("DashboardCro.title")}</h1>{" "}
            <h3 className="card-subtitle">
              {t("DashboardCro.WeAreOn")}{" "}
              {dateNow.format("DD-MM-YYYY") +
                t("DashboardCro.at") +
                dateNow.format("HH:mm")}
            </h3>
            <div className="relative max-w-xs">
              <input
                type="text"
                name="hs-table-search"
                id="hs-table-search"
                onChange={onSearch}
                value={searchValue}
                className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
                placeholder={t("SideBar.FilterStudy")}
                style={{ border: "none" }}
                onKeyDown={handleKeyDown}
              />

              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <svg
                  className="h-3.5 w-3.5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
            </div>
          </div>
        </header>
        <main></main>
      </div>

      <div className="flex mt-16 ">
        <div className="menu">
          {menu.map((m, idx) => {
            return (
              <div onClick={() => setStep(idx)} className="cursor-pointer">
                <p className={`${step === idx ? "selected" : "unselected"}`}>
                  <span className="capitalize">{m}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        {step === 0 && loading ? (
          <p className="flexCenter mt-16 menuInput">
            {t("missingWording.Loading")}...
          </p>
        ) : step === 0 && !loading ? (
          <div className="mt-8 flex flex-col-reverse">
            {studiesList && studiesList.length > 0 ? (
              <RenderDivs
                datahlist={studiesList}
                setStudySelectedId={setStudySelectedId}
                setSelectedStudyBoolean={setSelectedStudyBoolean}
                setPage={setPage}
                totalResult={totalResult}
                page={page}
                handleDownloadExcel={_handleDownloadExcel}
              />
            ) : (
              <p className="flexCenter mt-16">
                {t("DashboardCro.noActiveStudy")}
              </p>
            )}
          </div>
        ):(<></>)}
       {step === 1 && loading ? (
          <p className="flexCenter mt-16 menuInput">
            {t("missingWording.Loading")}...
          </p>
        ) : step === 1 && !loading ? (
          <div className="mt-8 flex flex-col-reverse">
            {studiesList && studiesList.length > 0 ? (
               <RenderDivs
               isDisabled={true}
                 datahlist={studiesList}
                 setStudySelectedId={setStudySelectedId}
                 setSelectedStudyBoolean={setSelectedStudyBoolean}
                 setPage={setPage}
                 totalResult={totalResult}
                 page={page}
                 handleDownloadExcel={_handleDownloadExcel}
               />
            ) : (
              <p className="flexCenter mt-16">
                {t("DashboardCro.noActiveStudy")}
              </p>
            )}
          </div>
        ):(<></>)}
      </div>
    </>
  );
};

export default DashboardARC;
