import  { useState } from "react";
import { Box, chakra } from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { FormGroup } from "./FormGroup";
import { FieldInput } from "./FieldInput";
import { KATOMI_API_ENDPOINT } from "../../../config/api";
import { useParams } from "react-router-dom";

export const FieldUploadBase64 = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const showError = !isValid && isSubmitted;
  const [showInput, setShowIn] = useState("");
  const params = useParams() as any;
  const { studyId,surveryId, } = params;
  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };
  function encodeImageFileAsURL(element) {
    var file = element.files[0];
    var reader = new FileReader();
    let str: any;

    reader.onloadend = function () {
      setValue(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = ({ target }) => {
    const file = target.files[0];
    const base64 = encodeImageFileAsURL(target);
    setShowIn(file.name);

    if (!file) {
      setValue(null);
      return;
    }
  };

  const uploadToS3 = async (file) => {
    let token = await localStorage.getItem("token");
  if (!token) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("surveryId", surveryId);
    formData.append("studyId", studyId);

    try {
      const response = await fetch( `${KATOMI_API_ENDPOINT}/media/survey/${studyId}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${token}`
},
      });
      console.log("🚀 ~ uploadToS3 ~ response:", response)

      if (response.ok) {
        const data = await response.json();
        console.log("🚀 ~ uploadToS3 ~ data:", data)
        const res = `${KATOMI_API_ENDPOINT}/media/surveyMedia/${data.dirctUrl}`
        return res; // Assuming your API returns the URL of the uploaded file

      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleChangeX = async ({ target }) => {
    const file = target.files[0];

    if (!file) {
      setValue(null);
      setShowIn("");
      return;
    }

    const imageUrl = await uploadToS3(file);
    console.log("🚀 ~ handleChangeX ~ imageUrl:", imageUrl)
    if (imageUrl) {
      setValue(imageUrl);
      setShowIn(file.name);
    }
  };
  return (
    <FormGroup {...formGroupProps}>
      <Box border={2}>
        <FieldInput
          value={value.includes("data:image/") ? "" : value}
          name={name}
          placeholder="www.example-Image.com"
          isPreview={true}
        />
        <span className="card-subtitle p-4 m-4">
          ou téléchargez une image :{" "}
        </span>
        <chakra.label
          position="relative"
          bg="gray.50"
          alignItems="center"
          px="4"
          py="2"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          color="gray.600"
          transition="0.2s"
          _hover={{
            bg: "brand.50",
            borderColor: "brand.600",
          }}
          _focusWithin={{
            bg: "brand.50",
            borderColor: "brand.600",
          }}
        >
          <chakra.input
            opacity={0}
            position="absolute"
            accept="image/jpeg, image/png, image/gif"
            top={0}
            left={0}
            width={0}
            type="file"
            id={id}
            onChange={handleChangeX}
            disabled={props.readOnly ? props.readOnly : false}
          />
          <AttachmentIcon mr="0" /> {showInput || "Selection image"}
        </chakra.label>
      </Box>
    </FormGroup>
  );
};
