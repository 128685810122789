import * as Yup from "yup";

export default Yup.object().shape({
    firstName: Yup.string().min(2)
    .max(50)
    .required('Required'),
    lastName: Yup.string().min(2)
    .max(50)
    .required('Required'),
    email: Yup.string().email('Merci de vérifier votre email').required('Required'),
});