import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { DragSource } from "react-dnd";
import ID from "../../../../helper/FormGeneratorHelper/UUID";
import classes from "./css/toolbar-draggable-item.module.css";
import ItemTypes from "./ItemTypes";

const cardSource = {
  beginDrag(props) {
    return {
      ...props.data.fieldProps,
      id: ID.uuid().toLowerCase(),
      type: props.data.key,
      field_name: "field_" + ID.uuid().toLowerCase(),
    };
  },
};

const ToolbarItem = (props) => {
  const { connectDragSource, data, onClick } = props;
  if (!connectDragSource) return null;
  return (
    <div ref={connectDragSource} className={classes.item} onClick={onClick}>
      <i className={data.icon}></i>{" "}
      <div style={{ fontSize: "16px" }}>{data.name}</div>
      <Tooltip
        rounded={"md"}
        placement="right-start"
        label={data.info ? data.info : data.name}
        fontSize="12px"
      >
        <InfoOutlineIcon className="absolute right-2 self-center " />
      </Tooltip>
    </div>
  );
};

export default DragSource(ItemTypes.CARD, cardSource, (connect) => ({
  connectDragSource: connect.dragSource(),
}))(ToolbarItem);
