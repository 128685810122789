import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import Icon from "../Icon";
import { screenBreakpoints } from "../../config/theme";
import classNames from "classnames";
import history from "../../history";
import { Link, useLocation, useParams } from "react-router-dom";
import Logout from "../Logout";
import "../SideBar/styles.scss";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import { useTranslation } from "react-i18next";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";

import _ from "lodash";
import { checkUnpaidInvoices } from "../../api/cro";

export const SiderBarCRO = () => {
  const { t, i18n } = useTranslation() as any;

  const [user] = useAtom(userAtom) as any;
  const [path, setPath] = useAtom(ActiveRouteAtom);
  const [studySelectedId] = useAtom(StudySelectedIdAtom);
  const location = useLocation();
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [menus, setMenu] = useState([]) as any;
  //const [hasUnpaidInvoices, setHasUnpaidInvoices] = useState(false);

  // const checkUnpaid = async () => {
  //   try {
  //     if (user?.freeAccount !== true) {
  //       const res = await checkUnpaidInvoices();
  //       setHasUnpaidInvoices(res.hasUnpaidInvoices);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   checkUnpaid();
  //   if (hasUnpaidInvoices) {
  //     history.push("/profile");
  //   }
  // }, [hasUnpaidInvoices]);

  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });
  const menuCro =
    user.type === "cro"
      ? [
          {
            icon: "studies",
            text: t("SideBar.title"),
            url: "/",
          },
          {
            icon: "exports",
            text: t("SideBar.exports"),
            url: "/exports",
          },
          {
            icon: "cro",
            text: t("SideBar.team"),
            url: "/mesArc",
          },
          {
            icon: "document",
            text: t("SideBar.template"),
            url: "/templates",
          },
          {
            icon: "monCompte",
            text: t("SideBar.monCompte"),
            url: "/profile",
          },
        ]
      : [
          {
            icon: "studies",
            text: t("SideBar.title"),
            url: "/",
          },
          {
            icon: "monCompte",
            text: t("SideBar.monCompte"),
            url: "/profile",
          },
        ];

  const menuCroSelectedStudy = (studyId) =>
    user.type === "cro" || user.type === "arc"
      ? [
          {
            icon: "dashboard",
            text: t("SideBar.Dashboard"),
            url: `/dashboard/${studyId}`,
          },
          {
            icon: "participants",
            text: t("SideBar.Participants"),
            url: `/participant/${studyId}`,
          },
          {
            icon: "query",
            text: t("SideBar.Queries"),
            url: `/query/${studyId}`,
          },
          {
            icon: "calendar",
            text: t("missingWording.events"),
            url: `/events/${studyId}`,
          },
          {
            icon: "eproform",
            text: t("SideBar.EproForms"),
            url: `/eproForms/${studyId}`,
          },
        ]
      : [
          {
            icon: "participants",
            text: t("SideBar.Participants"),
            url: `/participant/${studyId}`,
          },
        ];

  useEffect(() => {
    let routeArray = location.pathname.split("/").filter((e) => e && e !== "");

    if (_.isEmpty(routeArray)) {
      setMenu(menuCro);
    } else if (routeArray.length === 2 && routeArray[0] === "dashboard") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "participant") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "query") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "events") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "eproForms") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 3 && routeArray[0] === "participant") {
      setMenu([]);
    } else {
      setMenu(menuCro);
    }
  }, [location, i18n.language]);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    if (forceMenuOpen) {
      setForceMenuOpen(false);
    }
  }, [path]);

  return !_.isEmpty(menus) ? (
    <div className="sidebar-doctor  z-40 ">
      <div className="flex justify-between md:hidden absolute mobile-menu-burger">
        <button
          className="p-4 focus:outline-none"
          onClick={() => {
            setForceMenuOpen(!forceMenuOpen);
          }}
        >
          <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
        </button>
      </div>
      <div
        className={`swipe-events-container ${
          !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
        }`}
        {...swipeEventsHandlers}
      />
      <div
        className={`sidebar-container  transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
          menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
        } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
      >
        <div className="relative">
          <div className="flex items-center space-x-3 relative notification ">
            {user?.business_photo ? (
              <div className="avatar-doctor1 relative"></div>
            ) : (
              <div className="avatar-doctor1 relative">
                {user?.gender === "female" ? (
                  <WomanPatient className=" rounded-full  bg-pink-200 w-3/4 h-3/4" />
                ) : (
                  <ManPatient className=" rounded-full bg-yellow-100 w-3/4 h-3/4" />
                )}
              </div>
            )}

            <div>
              <span className="menu capitalize">
                {user?.firstName} {user?.lastName}
              </span>
              <p className="capitalize docter-center">
                {user?.organisationId?.name}
              </p>
            </div>
          </div>

          {studySelectedId && (
            <div className="icon link cursor-pointer flex items-center">
              <Icon
                name="direction-gauche"
                width={50}
                height={50}
                isIcon={true}
                onClick={() => {
                  history.push("/");
                }}
              />
              <div className="">
                <p
                  onClick={() => {
                    history.push("/");
                  }}
                  className="mb-2"
                >
                  {t("SideBar.Back")}
                </p>
              </div>
            </div>
          )}

          <nav className={classNames(" flex flex-col  justify-center ")}>
            {menus.map((menu) => {
              return (
                <div
                  className={
                    //hasUnpaidInvoices
                    false
                      ? "cursor-not-allowed"
                      : path === menu.url
                      ? "selected-menu"
                      : ""
                  }
                >
                  <Link
                    to={menu.url}
                    className={classNames(
                      "flex items-center space-x-3 md:py-3 -py-1"
                      // ,hasUnpaidInvoices && "pointer-events-none"
                    )}
                    onClick={() => {
                      setPath(menu.url);
                    }}
                  >
                    <div className="w-12 h-12 flexCenter ">
                      {menu.icon === "template" ? (
                        <img
                          src={require("../../assets/templates.png").default}
                          width={25}
                          alt="template"
                        />
                      ) : menu.icon === "list" ? (
                        <img
                          src={require("../../assets/list.svg").default}
                          width={25}
                          alt="template"
                        />
                      ) : (
                        <Icon isIcon={true} name={menu.icon} width={32} height={32} />
                      )}
                    </div>
                    <span className="my-auto menu">{menu.text}</span>
                  </Link>
                </div>
              );
            })}
            <Logout />
          </nav>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SiderBarCRO;
