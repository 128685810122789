import React from "react";
import { useTranslation } from "react-i18next";
import { removeWriterFromStudy, setWriterResponsable } from "../../../api/cro";
import Button from "../../../components/ButtonUI";
import makeToast from "../../../components/Snackbar";

interface Props {
  setDeleteArcModal: Function;
  setShouldUpdate: Function;
  writerId: any;
  studyId: string;
  writresConfigId: any;
}

export const ResponsableWriterModal: React.FC<Props> = ({
  setDeleteArcModal,
  writerId,
  writresConfigId,
  studyId,
  setShouldUpdate,
}) => {
  const { t } = useTranslation() as any;

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
        {writresConfigId?.isResponsable ? (
          <h4 className="text-lg mb-4">
            {t("ArcPage.writerResponsable01") +
              writerId?.lastName +
              t("ArcPage.writerResponsable02")}
          </h4>
        ) : (
          <h4 className="text-lg mb-4">
            {t("ArcPage.writerResponsable1") +
              writerId?.lastName +
              t("ArcPage.writerResponsable2") +
              writresConfigId?.centerId?.name +
              t("ArcPage.writerResponsable3") +
              writresConfigId?.roleId?.name}{" "}
            ?
          </h4>
        )}
        <div className="flexCenter mt-6 buttons">
          <Button
            status="secondary"
            width={"100px"}
            height={"50px"}
            className="btn-delete"
            onClick={async () => {
              setDeleteArcModal(false);
            }}
          >
            {t("ArcPage.Cancel")}
          </Button>
          <Button
            status="secondary"
            width={"100px"}
            height={"50px"}
            onClick={async () => {
              let response: any = await setWriterResponsable(
                writresConfigId?._id,
                {
                  isResponsable: !writresConfigId?.isResponsable,
                }
              );
              if (response) {
                makeToast(
                  "success",
                  writresConfigId?.isResponsable
                    ? t("missingWording.resNoInvToast")
                    : t("missingWording.resInvToast")
                );
                setShouldUpdate((up: boolean) => !up);
              }
              setDeleteArcModal(false);
            }}
          >
            {t("ArcPage.Confirm")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResponsableWriterModal;
