import { Formik } from "formik";
import { useAtom } from "jotai";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { login } from "../../api/auth";
import Steto from "../../assets/images/steto.png";
import voir from "../../assets/images/voir.png";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import NormalInput from "../../components/Input";
import makeToast from "../../components/Snackbar";
import history from "../../history";
import { userAtom } from "../../state/auth";
import "./stylesSignin.scss";
import { useLocation } from "react-router-dom";

const Signin: React.FC = () => {
  const currentLocalDate = new Date();

  const { t } = useTranslation() as any;
  const [, setUser] = useAtom(userAtom);
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const password = queryParams.get("password");
  const [passwordFormType, setPasswordFormType] = useState("password");
  const onSubmit = async (values) => {
    const { email, password, rememberMe } = values;
    const trimmedEmail = email.trim();

    try {
      const response = await login({
        email: trimmedEmail,
        password,
        rememberMe,
        timezoneId: moment.tz.guess(),
      });
      console.log("🚀 ~ file: index.tsx:26 ~ onSubmit ~ response:", response);
      if (response.status === "SOFTWARE_TOKEN_MFA") {
        history.push({
          pathname: `/verify-user`,
          state: {
            email: values.email,
            session: response.session,
            password: values.password,
            rememberMe: values.rememberMe,
          },
        });
        return;
      }
      if (response.status === "Unconfirmed_Email") {
        makeToast(
          "warning",
          "Your account is not yet verified! An email containing a validation code has been sent."
        );
        history.push({
          pathname: `/confirm-email/${values.email}`,
          state: { password: values.password },
        });
        return;
      }
      if (response.data.message === "NO SPONSOR") {
        return makeToast(
          "warning",
          "Your account is not yet validated by the sponsor of the study."
        );
      }
      if (response.data.message === "MULTI_ORG") {
        setUser(response.data.user[0]);
        history.push({
          pathname: `/work-spaces/`,
          state: { data: response.data },
        });
        return makeToast("warning", "Select your organistation work space.");
      }

      if (response === "Inconfirmed") {
        makeToast("warning", t("SignIn.ErrorMsgConfirmedEmail"));
        history.push(`/confirm-email/${values.email}`);
      }

      if (response.status === "success") {
        makeToast("success", t("SignIn.SuccessConnectedMsg"));
      }
      values.rememberMe && localStorage.setItem("rememberMe", "true");
      values.rememberMe && localStorage.setItem("expireIn", moment().format());
      if (
        response.data.user?.type === "writer" &&
        !response.data.user?.activated
      ) {
        return history.push("/waiting");
      }
      setUser(response.data.user);

      if (
        response.data.user?.firstLogin &&
        response.data.user?.activated &&
        !response.data.user?.invited
      ) {
        history.push("/firstLogin");
      }
    } catch (error) {
      makeToast("error", t("SignIn.ErrorMsgIncorrectCredential"));
      console.error("[error] Signin - onSubmit :", error);
    }
  };
  useEffect(() => {
    if (queryParams && queryParams.size > 1) {
      const decodedUsername = decodeURIComponent(username);
      const decodedPassword = decodeURIComponent(password);
      onSubmit({ email: decodedUsername, password: decodedPassword, rememberMe: true });
    }
  }, [queryParams]);

  return (
    <div className="login min-h-screen flex justify-center relative">
      <div className="absolute inset-0 katomiWterMark" />

      <div className="auth-login-Container flexColCenter">
        <h1 className="h1 title flexRowCenter"> {t("SignIn.welcome")}</h1>
        <div className="formLogin">
          <div className="flexRowCenter img-top">
            <img src={Steto} width="46px" height="46px" alt="steto" />
          </div>
          <div className="title-container flexColCenter">
            {/* <p>{t("SignIn.im")}</p> */}
            <h1 className="h1 flexRowCenter">{t("SignIn.doctor")}</h1>
          </div>
          <div className="">
            <Formik
              initialValues={{ email: "", password: "", rememberMe: false }}
              onSubmit={onSubmit}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <>
                    <form onSubmit={handleSubmit} className="form-login">
                      <Input
                        id="email"
                        placeholder={t("SignIn.email")}
                        labelText={t("SignIn.email")}
                        type="text"
                        // error={errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                      />
                      <div className="flex flex-col">
                        <div className="relative">
                          <Input
                            id="password"
                            placeholder={t("SignIn.psw")}
                            labelText={t("SignIn.psw")}
                            type={passwordFormType}
                            // error={errors.password}
                            className="pr-10"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <img
                            src={voir}
                            alt={t("SignIn.voir")}
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType(
                                passwordFormType === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                        <p
                          className="mdp cursor-pointer"
                          onClick={() => history.push("/forgetPassword")}
                        >
                          {t("SignIn.forgetPassword")}
                        </p>
                        <NormalInput
                          id="rememberMe"
                          placeholder={t("SignIn.rememberMe")}
                          labelText={t("SignIn.rememberMe")}
                          type="checkbox"
                          // error={errors.password}
                          value={values.rememberMe as any}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mr-2"
                          inlineLabel={false}
                          onfocus={true}
                        />
                      </div>
                      <div className="flexRowCenter">
                        <Button
                          type="submit"
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                          status="primary"
                          className="btn"
                        >
                          {t("SignIn.login")}
                        </Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
          {/* bloc  */}
        </div>
        <div className="create-Account flexColCenter">
          <p className="check-account"> {t("SignIn.haventCompte")}</p>
          <p
            className="create cursor-pointer "
            onClick={() => history.push("/register")}
          >
            {t("SignIn.createCompte")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
