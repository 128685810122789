import { useState } from "react";

export const TelephonePrefixSelector = (props) => {
  const [selectedPrefix, setSelectedPrefix] = useState(props.value); // Default selected prefix

  const handlePrefixChange = (event) => {
    setSelectedPrefix(event.target.value);
    props.setValue((val: any) => {
      val.prefix = event.target.value;
      return val;
    });
  };

  const prefixOptions = [
    { value: "+33", label: "+33  France", flag: "🇫🇷" },
    { value: "+216", label: "+216 Tunisie", flag: "🇹🇳" },
    { value: '+1', label: '+1  États-Unis', flag: '🇺🇸' },
    { value: '+86', label: '+86  Chine', flag: '🇨🇳' },
    { value: '+91', label: '+91  Inde', flag: '🇮🇳' },
    { value: '+7', label: '+7  Russie', flag: '🇷🇺' },
    { value: '+81', label: '+81  Japon', flag: '🇯🇵' },
    { value: '+44', label: '+44  Royaume-Uni', flag: '🇬🇧' },
    { value: '+49', label: '+49  Allemagne', flag: '🇩🇪' },
    { value: '+39', label: '+39  Italie', flag: '🇮🇹' },
    { value: '+34', label: '+34  Espagne', flag: '🇪🇸' },
    { value: '+61', label: '+61  Australie', flag: '🇦🇺' },
    { value: '+971', label: '+971  Émirats Arabes Unis', flag: '🇦🇪' },
    { value: '+966', label: '+966  Arabie Saoudite', flag: '🇸🇦' },
    { value: '+65', label: '+65  Singapour', flag: '🇸🇬' },
    { value: '+82', label: '+82  Corée du Sud', flag: '🇰🇷' },

    // Add more prefixes as needed...
  ];

  return (
    <div className="relative">
      <select
        name={props.name}
        className="border-secondary-grey border-2 w-full h-10 rounded-md pl-10 pr-4"
        value={selectedPrefix}
        placeholder={props.placeholder}
        onChange={handlePrefixChange}
      >
        <option value={""}>+ . . .</option>
        {prefixOptions.map((option) => (
          <option key={option.value} value={option.value}>
          {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
        {prefixOptions.map((option) => (
          <span
            key={option.value}
            className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none"
          >
            {option.value === selectedPrefix && option.flag}
          </span>
        ))}
      </div>
    </div>
  );
};
