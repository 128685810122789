import axios from "axios";
import {
  ADD_CENTERS_URL,
  ADD_ROLES_URL,
  ARCHIVE_STUDY,
  CREATE_EVENTS_URL,
  CREATE_SURVEYS_URL,
  CREATE_UPDATE_CENTRE_URL,
  CREATE_UPDATE_ROLE_URL,
  DELETE_CENTERS_URL,
  DELETE_MEDIA_URL,
  DELETE_ROLES_URL,
  DOWNLOAD_EXPORT_FILE,
  DOWNLOAD_STUDY_RESULT,
  ENDING_STUDY,
  GET_CENTERS_URL,
  GET_CENTER_INFO_URL,
  GET_EVENTS_ARC_LIST,
  GET_EVENT_URL,
  GET_EXPORTS_LIST,
  GET_MEMBERS_URL,
  GET_ROLES_URL,
  GET_SPONSORS_URL,
  GET_STUDY_WRITERS_URL,
  GET_SURVEYS_URL,
  KATOMI_API_ENDPOINT,
  PARTICIPANT_CREATE_URL,
  PARTICIPANT_ENDPOINT,
  STUDY_ENDPOINT,
  STUDY_WRITERS_CONFIG_URL,
  STUSIE_CREATE_URL,
  GET_ALL_STATS_DATA,
  GET_ALL_DASHBOARD_DATA,
  GET_NBR_SHAREDSTATSVALUES,
  RANDOM_GONFIG_ENDPOINT,
  PARTICIPANT_RANDO_ENDPOINT,
  PARTICIPANT_NEXT_RANDO_ENDPOINT,
  CREATE_EPRO_SURVEYS_URL,
  GET_SURVEYS_EPRO_URL,
  PARTICIPANT_STUDIES_URL,
  GET_ALL_EPRO_FORMS_PAGINATION,
  EPRO_URL,
  CREATE_ECONSULT_URL,
  GET_ECONSULT_URL_BYWRITER,
  UPDATE_ECONSULT_BY_WRITER,
  GET_PAST_ECONSULT_URL_BYWRITER,
  GET_ECONSULT_URL_BYPARTICIPANT,
  STUDY_WRITERS_CONFIG_GET_URL,
  GET_ALL_DASHBOARD_DATA_01,
  GET_ALL_DASHBOARD_DATA_02,
  GET_ALL_DASHBOARD_DATA_03,
} from "../config/api";
import { get, post } from "../helper/apiMethods";
import { Socket } from "socket.io-client";

export const createUpdateCentre = async (values) => {
  try {
    const res = await post(CREATE_UPDATE_CENTRE_URL, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createUpdateRole = async (values) => {
  try {
    const res = await post(CREATE_UPDATE_ROLE_URL, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createStudy = async (values) => {
  try {
    const res = await post(STUSIE_CREATE_URL, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateStudy = async (values, studyId) => {
  try {
    const res = await post(STUDY_ENDPOINT + `/${studyId}`, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const retriveStudy = async (id) => {
  try {
    const res = await get(STUDY_ENDPOINT + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRandomisationConfig = async (id) => {
  try {
    const res = await get(RANDOM_GONFIG_ENDPOINT + `/${id}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateRandomisationConfig = async (values, id) => {
  try {
    const res = await post(RANDOM_GONFIG_ENDPOINT + `/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addCenterToStudy = async (id, values) => {
  try {
    const res = await post(ADD_CENTERS_URL + `/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyCenters = async (id) => {
  try {
    const res = await get(GET_CENTERS_URL + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyCenterInfo = async (id, centerId, testMode = false) => {
  try {
    const res = await get(
      GET_CENTER_INFO_URL + `/${id}/${centerId}?testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addRoleToStudy = async (id, values) => {
  try {
    const res = await post(ADD_ROLES_URL + `/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyRoles = async (id) => {
  try {
    const res = await get(GET_ROLES_URL + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyMembers = async (id) => {
  try {
    const res = await get(GET_MEMBERS_URL + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudySponsors = async (id) => {
  try {
    const res = await get(`${GET_SPONSORS_URL}${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyForms = async (id) => {
  try {
    const res = await get(GET_SURVEYS_URL + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyEproForms = async (id) => {
  try {
    const res = await get(GET_SURVEYS_EPRO_URL + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyEventsConfig = async (id) => {
  try {
    const res = await get(GET_EVENT_URL + `/${id}`);
    return res?.eventsConfig;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteStudyCenters = async (idStudy, idCentre) => {
  try {
    const res = await post(
      DELETE_CENTERS_URL + `/${idStudy}/${idCentre}`,
      null
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteStudyRoles = async (idStudy, idCentre) => {
  try {
    const res = await post(DELETE_ROLES_URL + `/${idStudy}/${idCentre}`, null);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addFormToStudy = async (id, values) => {
  try {
    const res = await post(CREATE_SURVEYS_URL + `/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addEproFormToStudy = async (id, values) => {
  try {
    const res = await post(CREATE_EPRO_SURVEYS_URL + `/${id}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addEventoStudy = async (values) => {
  try {
    const res = await post(CREATE_EVENTS_URL, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyWriters = async (id) => {
  try {
    const res = await get(GET_STUDY_WRITERS_URL + `/${id}`);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createParicipant = async (
  values,
  socket: Socket | null = null
) => {
  try {
    if (!socket) {
      const res = await post(PARTICIPANT_CREATE_URL, values);
      return res?.data;
    } else {
      socket.emit("participants.add", values);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createParicipantsEpro = async (
  values,
  socket: Socket | null = null
) => {
  try {
    if (!socket) {
      const res = await post(PARTICIPANT_CREATE_URL, values);
      return res?.data;
    } else {
      socket.emit("participants.EproAdd", values);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllPatientByStudyId = async (studyId) => {
  try {
    const res = await get(`${PARTICIPANT_ENDPOINT}/${studyId}`);
    return res?.participants;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllPatientByStudyIdPaginate = async (
  studyId,
  page,
  limit,
  searchText = "",
  testMode = false
) => {
  try {
    const res = await get(
      searchText && searchText !== ""
        ? `${PARTICIPANT_ENDPOINT}/${studyId}?page=${page}&limit=${limit}&searchText=${searchText}&testMode=${testMode}`
        : `${PARTICIPANT_ENDPOINT}/${studyId}?page=${page}&limit=${limit}&testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllrProFormsPagination = async (
  studyId,
  page,
  limit,
  testMode,
  filter
) => {
  try {
    const res = await get(
      `${GET_ALL_EPRO_FORMS_PAGINATION}/${studyId}?page=${page}&limit=${limit}&testMode=${testMode}&filter=${filter}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getParticpantBycenter = async (centerId) => {
  let res;
  try {
    res = await get(`${PARTICIPANT_ENDPOINT + "/center/" + centerId}`);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getParticipantByStudyId = async (studyId, participantId) => {
  try {
    const res = await get(
      `${PARTICIPANT_ENDPOINT}/${studyId}/${participantId}`
    );
    return res?.participants;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getParticipantDataStatics = async (participantId) => {
  try {
    const data = await get(
      `${PARTICIPANT_ENDPOINT}/dataToPdf/${participantId}`
    );
    const href = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "file.pdf");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getParticipantDataStaticsURL = (participantId) => {
  return `${PARTICIPANT_ENDPOINT}/dataToPdf/${participantId}`;
};

export const getParticipantByStudyId2 = async (
  studyId,
  participantId,
  roleId
) => {
  try {
    const res = await post(
      `${PARTICIPANT_ENDPOINT}/${studyId}/${participantId}`,
      { roleId: roleId }
    );

    return res?.data.participants;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyConfig = async (
  studyId,
  page,
  limit,
  filter,
  searchText = "",
  testMode = false
) => {
  try {
    const res = await get(
      searchText && searchText !== ""
        ? `${STUDY_WRITERS_CONFIG_URL}/${studyId}?page=${page}&limit=${limit}&filter=${filter}&searchText=${searchText}&testmode=${testMode}`
        : `${STUDY_WRITERS_CONFIG_URL}/${studyId}?page=${page}&limit=${limit}&filter=${filter}&testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudyConfigSimple = async (
  studyId,
) => {
  try {
    const res = await get(`${STUDY_WRITERS_CONFIG_GET_URL}/${studyId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateFomDataSection = async (values, id, isEvent) => {
  try {
    let url = isEvent
      ? KATOMI_API_ENDPOINT + "/participantEvent/" + `${id}`
      : KATOMI_API_ENDPOINT + "/formData/" + `${id}`;
    const res = await post(url, values);
    if (res && res?.data) {
      return res?.data;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFomDataPerField = async (values, id) => {
  try {
    const res = await post(
      KATOMI_API_ENDPOINT + "/formData/perField/" + `${id}`,
      values
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateEventDataPerField = async (values, id) => {
  try {
    const res = await post(
      KATOMI_API_ENDPOINT + "/participantEvent/perField/" + `${id}`,
      values
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const autoQueringVerif = async (id, centerId) => {
  try {
    const res = await get(
      KATOMI_API_ENDPOINT +
        "/formData/autoQueringVerif/" +
        `${id}` +
        `/${centerId}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFileMediaApi = async (params: any) => {
  try {
    const res = await get(
      `${KATOMI_API_ENDPOINT}/media/formData/${params?.tenant}/${params?.studyId}/${params?.participantId}/${params?.formId}/${params?.fieldName}/${params?.fullInfo}`
    );

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const freezeField = async (values, id, isEvent) => {
  try {
    let url = isEvent
      ? KATOMI_API_ENDPOINT + "/participantEvent/freeze/" + `${id}`
      : KATOMI_API_ENDPOINT + "/formData/freeze/" + `${id}`;
    const res = await post(url, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sdvField = async (values, id) => {
  try {
    const res = await post(
      KATOMI_API_ENDPOINT + "/formData/sdv/" + `${id}`,
      values
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addQueryPerField = async (values, id, isEvent) => {
  try {
    let url = isEvent
      ? KATOMI_API_ENDPOINT + "/participantEvent/addQuery/" + `${id}`
      : KATOMI_API_ENDPOINT + "/formData/addQuery/" + `${id}`;
    const res = await post(url, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getHistoryPerField = async (values, id, isEvent) => {
  try {
    let url = isEvent
      ? KATOMI_API_ENDPOINT + "/participantEvent/history/perField/" + `${id}`
      : KATOMI_API_ENDPOINT + "/formData/history/perField/" + `${id}`;
    const res = await post(url, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadExcel = async (surveyId, type) => {
  try {
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    const config: any = {
      method: "GET",
      url: `${DOWNLOAD_STUDY_RESULT}/${surveyId}/${type}`,
      headers,
    };
    // const res = await getBlob(`${DOWNLOAD_STUDY_RESULT}/${surveyId}`);
    const res2 = await axios(config);

    return res2;
  } catch (error) {
    JSON.parse(JSON.stringify(error));

    return Promise.reject(error);
  }
};

export const getExports = async (surveyId = "all", page, limit) => {
  console.log("🚀 ~ file: study.ts:443 ~ getExports ~ page:", page);

  try {
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    const config: any = {
      method: "GET",
      url: `${GET_EXPORTS_LIST}/${surveyId}?page=${page}&limit=${limit}`,
      headers,
    };

    const res = await axios(config);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadExport = async (filepath) => {
  try {
    const token = localStorage.getItem("token");

    const headers = { "Content-Type": "blob", Authorization: `${token}` };
    const config: any = {
      method: "GET",
      url: `${DOWNLOAD_EXPORT_FILE}/${encodeURIComponent(filepath)}`,
      responseType: "arraybuffer",
      headers,
    };

    const res = await axios(config);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadParticipantPdf = async (participantId) => {
  try {
    const token = localStorage.getItem("token");

    const headers = { "Content-Type": "blob", Authorization: `${token}` };
    const config: any = {
      method: "GET",
      url: `${PARTICIPANT_ENDPOINT}/dataToPdf/${participantId}`,
      responseType: "arraybuffer",
      headers,
    };
    // const res = await getBlob(`${DOWNLOAD_STUDY_RESULT}/${surveyId}`);
    const res2 = await axios(config);

    return res2;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadEProFormPdf = async (eProFormId) => {
  try {
    const token = localStorage.getItem("token");
    const headers = { "Content-Type": "blob", Authorization: `${token}` };
    const config: any = {
      method: "GET",
      url: `${EPRO_URL}/dataToPdf/${eProFormId}`,
      responseType: "arraybuffer",
      headers,
    };
    // const res = await getBlob(`${DOWNLOAD_STUDY_RESULT}/${surveyId}`);
    const res2 = await axios(config);

    return res2;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fomDataStatusUpdate = async (values, id) => {
  try {
    const res = await post(
      KATOMI_API_ENDPOINT + "/formData/fomDataStatusUpdate/" + `${id}`,
      values
    );
    if (res && res?.data) {
      return res?.data;
    } else {
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createParticipantEvent = async (values) => {
  try {
    const res = await post(
      PARTICIPANT_ENDPOINT + "/createParticipantEvent",
      values
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllEvents = async (studyId, testMode) => {
  try {
    const res = await get(
      `${GET_EVENTS_ARC_LIST}/${studyId}?testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const archiveStudyApi = async (studyId) => {
  try {
    const res = await get(`${ARCHIVE_STUDY}/${studyId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getNbrOfSharedStatsValuesApi = async (studyId) => {
  try {
    const res = await get(`${GET_NBR_SHAREDSTATSVALUES}/${studyId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const endingStudyApi = async (studyId) => {
  try {
    const res = await get(`${ENDING_STUDY}/${studyId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteMedia = async (payload) => {
  try {
    const res = await get(
      `${DELETE_MEDIA_URL}/${payload.formId}/${payload.stepNbr}/${payload.fieldName}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getformulaValues = async (studyId) => {
  try {
    let url = KATOMI_API_ENDPOINT + "/formulaValues/" + `${studyId}`;

    const res = await get(url);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllStatsData = async (studyId) => {
  try {
    const res = await get(`${GET_ALL_STATS_DATA}/${studyId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const randomizeParticipant = async (values) => {
  try {
    const res = await post(PARTICIPANT_RANDO_ENDPOINT, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const nextRandomizeParticipant = async (values) => {
  try {
    const res = await post(PARTICIPANT_NEXT_RANDO_ENDPOINT, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllDashboardData = async (studyId, testMode) => {
  try {
    const res = await get(
      `${GET_ALL_DASHBOARD_DATA}/${studyId}?testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDashboardData_01 = async (studyId, testMode) => {
  try {
    const res = await get(
      `${GET_ALL_DASHBOARD_DATA_01}/${studyId}?testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDashboardData_02 = async (studyId, testMode) => {
  try {
    const res = await get(
      `${GET_ALL_DASHBOARD_DATA_02}/${studyId}?testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDashboardData_03 = async (studyId, testMode) => {
  try {
    const res = await get(
      `${GET_ALL_DASHBOARD_DATA_03}/${studyId}?testMode=${testMode}`
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getStudiesForParticipant = async (email, page, limit, filter) => {
  let res;
  try {
    res = await get(
      `${
        PARTICIPANT_STUDIES_URL + "/" + email
      }?page=${page}&limit=${limit}&filter=${filter}`
    );
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const addEConsult = async (values) => {
  try {
    const res = await post(CREATE_ECONSULT_URL, values);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEconsultByWriter = async (parmas: any) => {
  let res;
  try {
    res = await get(`${GET_ECONSULT_URL_BYWRITER}${parmas.studyId}`);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getEconsultByParticipant = async (parmas: any) => {
  let res;
  try {
    res = await get(`${GET_ECONSULT_URL_BYPARTICIPANT}${parmas.studyId}`);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getPastEconsultByWriter = async (
  parmas: any,
  page,
  limit,
  filter
) => {
  let res;
  try {
    res = await get(
      `${GET_PAST_ECONSULT_URL_BYWRITER}${parmas.studyId}?page=${page}&limit=${limit}&filter=${filter}`
    );
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const updateEconsultByWriter = async (eConsultId, payload) => {
  let res;
  try {
    res = await post(`${UPDATE_ECONSULT_BY_WRITER}${eConsultId}`, payload);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
