import { useEffect, useState } from "react";
import { AddIcon, DeleteIcon, EditIcon, SettingsIcon } from "@chakra-ui/icons";
import classNames from "classnames";
import {
  Icon as Iconx,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import ButtonUI from "../../../ButtonUI";
import {
  pageSettingTutoAtom,
  closePageSettingTutoAtom,
} from "../../../../state/tutorialMode";
import ConditionLogicForPage from "../PropertyPageEditor";
import { DropZone } from "./DropZone";

export function ControlledTabs(
  currentIndex,
  setStAtom,
  setCurrentIndex,
  formConfig,
  setPopUp,
  setConfirmModal,
  setSelectedSec,
  addStep,
  formProps,
  updateFormElement,
  removeStep,
  confirmModal,
  editStepConfig,
  getAllFields,
  popUp,
  t,
  surveyId,
  translation,
  openPageSettingTuto,
closePageSettingTuto
) {
  // const [openPageSettingTuto] = useAtom(pageSettingTutoAtom);
  // const [closePageSettingTuto] = useAtom(closePageSettingTutoAtom);
  // useEffect(() => {
  //   if (openPageSettingTuto) {
  //     setCurrentIndex(1);
  //     setPopUp(true);
  //   }
  //   if (closePageSettingTuto) {
  //     setPopUp(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [openPageSettingTuto, closePageSettingTuto]);

  return (
    <>
      <Tabs
        variant=""
        className="w-full  "
        onChange={(e) => {
          setStAtom(e);
          setCurrentIndex(e);
        }}
        index={currentIndex}
      >
        <TabList tabIndex={currentIndex} className="flex flex-wrap -mb-1 ml-2 ">
          {formConfig.sections.map((tab, index) => (
            <>
              <Tab
                className={classNames(
                  "relative p-4 rounded-t-lg border -mx-1 bg-white shadow-md",
                  `page${index}`
                )}
                _selected={{
                  color: "white",
                  bg: "#4565f6",
                }}
                _focus={{}}
                outline="none"
                key={index}
                tabIndex={currentIndex}
              >
                <Menu>
                  <MenuButton aria-label="Options">
                    <SettingsIcon className={`page${index}setting`} />
                  </MenuButton>
                  <MenuList color={"GrayText"}>
                    <MenuItem
                      onClick={() => setPopUp(true)}
                      icon={<EditIcon />}
                    >
                      {t("Survey.ParPage")}
                    </MenuItem>

                    <MenuItem
                      color={"Red"}
                      icon={<DeleteIcon />}
                      isDisabled={
                        index == 0 && formConfig.sections.length == 1
                          ? true
                          : false
                      }
                      onClick={() => {
                        setSelectedSec(tab);
                        setConfirmModal(true);
                      }}
                    >
                      {t("Survey.Remove")}
                    </MenuItem>
                  </MenuList>
                </Menu>

                <p className="mx-2">
                  {" "}
                  {formConfig.type === "Simulation"
                    ? formConfig.name
                    : tab.sectionName}
                </p>
              </Tab>

              {index + 1 == formConfig.sections.length &&
                formConfig.type !== "Simulation" && (
                  <Tooltip
                    label={t("Survey.AddSectionN")}
                    aria-label="A tooltip"
                  >
                    <div
                      id="addStep"
                      onClick={() => addStep()}
                      style={{ backgroundColor: "transparent" }}
                      className="mx-2 cursor-pointer border-r border-l border-t border-blue-400 rounded-t-lg   flexCenter mt-1 bg-white p-1  w-10 h-10"
                    >
                      <Iconx as={AddIcon} w={5} h={5} color="#4565f6" />
                    </div>
                  </Tooltip>
                )}
            </>
          ))}
        </TabList>
        <TabPanels>
          {formConfig.sections.map((tab, index) => (
            <TabPanel p={0} key={index}>
              <div
                className=" flex rounded-lg relative h-screen  "
                style={{
                  margin: 2,
                  minHeight: 280,
                  backgroundColor: "#fff",
                }}
              >
                <DropZone
                  key={index}
                  elements={tab.task_data}
                  onUpdate={updateFormElement}
                  formProps={formProps}
                  formConfig={formConfig}
                  stepNbr={currentIndex}
                  surveyId={surveyId}
                  translation={translation}
                />
              </div>
            </TabPanel>
          ))}
          <Modal
            key={`${currentIndex}`}
            isOpen={popUp}
            onClose={() => setPopUp(false)}
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent bg={"transparent"}>
              <div className="rounded-2xl bg-white  " id="pagesettingmodal">
                <ModalHeader>{t("Survey.ParPage")}</ModalHeader>
                <ButtonUI
                  status="icon-secondary"
                  onClick={() => setPopUp(false)}
                  className="absolute right-1 top-1 "
                  type="button"
                >
                  <Close />
                </ButtonUI>
                <ModalBody pb={6}>
                  <ConditionLogicForPage
                    elements={getAllFields(currentIndex)}
                    step={formConfig.sections[currentIndex]}
                    closeModal={() => setPopUp(false)}
                    editStepConfig={editStepConfig}
                    totalSection={formConfig.sections.length || 0}
                  />
                </ModalBody>
              </div>
            </ModalContent>
          </Modal>
          <Modal
            key={`${currentIndex}`}
            isOpen={confirmModal}
            onClose={() => setConfirmModal(false)}
          >
            <ModalOverlay />
            <ModalContent bg={"transparent"}>
              <div className="rounded-2xl bg-white">
                <ModalHeader className="flexCenter">
                  {t("Survey.DeletePage")}
                </ModalHeader>
                <ButtonUI
                  status="icon-secondary"
                  onClick={() => setConfirmModal(false)}
                  className="absolute right-1 top-1 "
                >
                  <Close />
                </ButtonUI>

                <ModalFooter justifyContent={"center"}>
                  <ButtonUI
                    status="secondary"
                    className="mr-3 px-4 py-2 w-auto float-left mb-4 btn-delete"
                    onClick={() => setConfirmModal(false)}
                  >
                    {t("Survey.Cancel")}
                  </ButtonUI>
                  <ButtonUI
                    status="primary"
                    className="mr-3 px-4 py-2 mb-4 w-auto "
                    height="42px"
                    onClick={() => {
                      removeStep();
                      setConfirmModal(false);
                    }}
                  >
                    {t("Survey.Confirm")}
                  </ButtonUI>
                </ModalFooter>
              </div>
            </ModalContent>
          </Modal>
        </TabPanels>
      </Tabs>
    </>
  );
}
