/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import Button from "../../../components/ButtonUI";
import history from "../../../history";
import { updateStudy } from "../../../api/study";
import makeToast from "../../../components/Snackbar";
import { driver } from "driver.js";
import { Socket, io } from "socket.io-client";
import { BASE_URL } from "../../../config/api";
import { socketAtom } from "../../../state/socket";
import { userAtom } from "../../../state/auth";
interface Props {
  openModal: boolean;
  setOpenModal: Function;
  studyId: string;
}
export const AnonymazationModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  studyId,
}) => {
  const { t } = useTranslation() as any;
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [user] = useAtom(userAtom) as any;
  const updateStudyConfig = async (values: { anonym: boolean }) => {
    try {
      const res = await updateStudy(values, studyId);
      if (res && res.study) {
        setOpenModal(false);
        history.push(`/study/${studyId}`);
      } else {
        makeToast("error", `${t("DashboardCro.errorH")}`);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AnomysationModal.tsx:20 ~ updateStudyConfig ~ error:",
        error
      );
    }
  };

  // useEffect(() => {
  //   if (!socket) {
  //     const mySocket: Socket = io(BASE_URL, {
  //       // forceNew: true,
  //       reconnectionAttempts: 100,
  //       timeout: 10000,
  //       transports: ["websocket"],
  //       auth: {
  //         token: localStorage.getItem("token"),
  //       },
  //     });

  //     setSocket(mySocket);
  //   }
  //   if (!socket.connected) socket.connect();
  //   socket.emit("checkTutoChallenge1_2", {});
  //   socket.on("tutoCroDataChallenge1_2", (res) => {
  //     if (!res.user.firstChallenge.done && !res.user.firstChallenge.skipped) {
  //       // setTimeout(() => {
  //         // const driverObj = driver({
  //         //   allowClose: false,
  //         //   showProgress: true,
  //         //   showButtons: ["next", "previous", "close"],
  //         //   nextBtnText: t("DriverJs.btnNext"),
  //         //   prevBtnText: t("DriverJs.btnSkip"),
  //         //   doneBtnText: t("DriverJs.btnDone"),
  //         //   steps: [
  //         //     {
  //         //       element: ".studyAnomnymModal",
  //         //       popover: {
  //         //         title: t("DriverJs.t_ch1_6"),
  //         //         description: t("DriverJs.d_ch1_6"),
  //         //         align: "start",
  //         //       },
  //         //     },
  //         //     {
  //         //       element: ".anonymBtn",
  //         //       popover: {
  //         //         title: t("DriverJs.t_ch1_7"),
  //         //         description: t("DriverJs.d_ch1_7"),
  //         //         align: "start",
  //         //         onNextClick: async () => {
  //         //           let payload;
  //         //           payload = {
  //         //             ...res?.user,
  //         //           };
  //         //           payload.firstChallenge.done = true;
  //         //           console.log("🚀 ~ onNextClick: ~ payload:", payload);

  //         //           if (socket) socket.emit("tutoSaveProgress", payload);
  //         //           setTimeout(() => {
  //         //             driverObj.destroy();
  //         //           }, 200);
  //         //           await updateStudyConfig({ anonym: true });
  //         //           // driverObj.destroy();
  //         //         },
  //         //       },
  //         //     },
  //         //   ],
  //         // });

  //         // driverObj.drive();
  //       // }, 800);
  //     }
  //   });

  //   socket.on("tutoError", (res) => {
  //     makeToast("warning", "socket error");
  //     return;
  //   });

  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //       socket.removeAllListeners("");
  //       socket.close();
  //       console.log("[DEBUG] Socket was closed!");
  //     }
  //   };
  // }, [socket]);
  return (
    <div className=" fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div
        id="studyAnomnymModal"
        className="studyAnomnymModal absolute z-40 top-1/3 flexColCenter  bg-white px-10 py-8 rounded-xl "
      >
        <h1 className="mb-6 mt-2">{t("missingWording.anonymizationConfig")}</h1>
        <h3 className="text-lg mb-4">{t("missingWording.anonymMsg")}</h3>

        <div className="flex flex-row justify-between  mt-6  w-full">
          <Button
            status="secondary"
            className="anonymBtn px-2 py-2  w-64"
            height={"50px"}
            onClick={async () => {
              await updateStudyConfig({ anonym: true });
              if (
                user?.tutoConfig &&
                !user?.tutoConfig.firstChallenge.done &&
                !user?.tutoConfig.firstChallenge.skipped
              ) {
                // if (!socket) {
                //   const mySocket: Socket = io(BASE_URL, {
                //     // forceNew: true,
                //     reconnectionAttempts: 100,
                //     timeout: 10000,
                //     transports: ["websocket"],
                //     auth: {
                //       token: localStorage.getItem("token"),
                //     },
                //   });

                //   setSocket(mySocket);
                // }
                // if (!socket.connected) socket.connect();
                // socket.emit("checkTutoChallenge1_2", {});
                // socket.on("tutoCroDataChallenge1_2", (res) => {
                //   if (
                //     !res.user.firstChallenge.done &&
                //     !res.user.firstChallenge.skipped
                //   ) {
                //     let payload;
                //     payload = {
                //       ...res?.user,
                //     };
                //     payload.firstChallenge.done = true;
                //     console.log("🚀 ~ onNextClick: ~ payload:", payload);

                //     if (socket) socket.emit("tutoSaveProgress", payload);
                //     setTimeout(() => {}, 200);

                //     // driverObj.destroy();
                //   }
                // });

              
              }
              // setOpenModal(false);
              // history.push(`/study/${studyId}`);
            }}
          >
            {t("missingWording.anonymizationConfig")}
          </Button>

          <Button
            status="secondary"
            className="px-2 py-2   w-64"
            height={"50px"}
            onClick={async () => {
              await updateStudyConfig({ anonym: false });
            }}
          >
            {t("missingWording.noAnonymizationConfig")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnonymazationModal;
