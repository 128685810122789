import {
  EPRO_URL,
  GET_ALL_EPROFRORM_BY_STUDYID_DATA,
  GET_ALL_EPROFRORM_DATA,
  KATOMI_API_ENDPOINT,
  RESEND_ACCESS_TOEKN,
} from "../config/api";
import { get, post } from "../helper/apiMethods";

export const getEproFormadataById = async (formDataId, tenantId) => {
  try {
    const res = await get(
      `${GET_ALL_EPROFRORM_DATA}/${formDataId}/${tenantId}`
    );
    return res.formData;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitEproForm = async (formId, tenantId, token, dataToSubmit) => {
  try {
    let url = EPRO_URL + `/${formId}`;
    const res = await post(url, { dataToSubmit, formId, tenantId, token });
    if (res && res?.data) {
      return res?.data;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEproFormadataByStudyId = async (studyId) => {
  try {
    const res = await get(`${GET_ALL_EPROFRORM_BY_STUDYID_DATA}/${studyId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resendInvitation = async (payload) => {
  try {
    const res = await post(RESEND_ACCESS_TOEKN, payload);
    if (res && res?.data) {
      return res?.data;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
