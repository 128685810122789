import { InputGroup, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { FormGroup } from "../../Elements/FormGroup";
import { useTranslation } from "react-i18next";
import { FieldInput } from "../../Elements/FieldInput";
const customStyles = {
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "lightgray", // Change the background color of the gray cards
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "black", // Change the text color of the labels on the gray cards
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    color: "transparent", // Change the color of the remove button on the gray cards
    width: "0",
    ":hover": {
      backgroundColor: "#ef4444", // Change the background color of the remove button on hover
      color: "white",
      width: "16px", // Change the text color of the remove button on hover
    },
  }),
};
const CalculConfig = (props) => {
  console.log("🚀 ~ CalculConfig ~ props:", props)
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { t } = useTranslation();
  const { name, listOfAllowedField } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const isOptionUnique = () => {
    return false;
  };
  const isNumber = (string) => !isNaN(parseFloat(string)) && isFinite(string);
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const isValidArray = (array) => {
    if (array.length === 0) {
      return true;
    }
    let previousItem: any = null;
    let previousIsOperator = false;
    let isFirstItem = true;
    for (const item of array) {
      if (item.isOperator) {
        if (previousIsOperator || (isFirstItem && item.value !== "-")) {
          return false;
        }

        previousIsOperator = true;
      } else {
        if (
          previousIsOperator === false &&
          isFirstItem === false &&
          previousItem?.value !== "(" &&
          item?.isOperator
        ) {
          return false;
        }
        if (item.__isNew__) {
          if (isNaN(Number(item.value)) && typeof item.value !== "string") {
            return false;
          }
        }
        previousIsOperator = false;
      }
      previousItem = item;
      isFirstItem = false;
    }
    return true;
  };

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    label,
    showError,
    name,
    ...rest,
  };

  return (
    <>
      <FormGroup {...formGroupProps}>
        <InputGroup name="">
          <VStack
            spacing={2}
            align="start"
            className="border p-4 rounded-lg w-full "
          >
            <span className=" text-xs">{t("missingWording.mathExp")}</span>
            <div className="flex flx-row p-2 rounded-lg">
              {" "}
              {value &&
                Array.isArray(value) &&
                value?.map((e) => <p className=" ">{e.label}</p>)}
            </div>
            <Creatable
              isOptionSelected={isOptionUnique}
              isValidNewOption={(inputValue) => isNumber(inputValue)}
              isMulti
              hideSelectedOptions={true}
              isSearchable
              name={name}
              value={value}
              options={listOfAllowedField}
              openMenuOnClick={false}
              noOptionsMessage={() => null}
              className="autoComplete min-w-full "
              placeholder="Séléctionner"
              formatCreateLabel={(userInput) => `Ajouter  ${userInput}`}
              styles={customStyles}
              onChange={(value, action) => {
                if (isValidArray(value)) {
                  setValue(value);
                } else {
                  // errorMessage("Invalid input. Please check the order and types of the selected or created items.");
                }
              }}
            />
          </VStack>
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default CalculConfig;
