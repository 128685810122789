import { Redirect, RouteProps } from "react-router";
import DashboardCro from "../pages/DashboardCRO";
import Dragable from "../pages/DragableInterface";
import TranslationInterface from "../pages/TranslationInterface";
import Events from "../pages/Events";
import FirstLogin from "../pages/FirstLogin";
import Arc from "../pages/MyArc";
import Templates from "../pages/Templates";
import Exports from "../pages/Exports";
import MyParticipant from "../pages/MyParticipant";
import MyParticipantForms from "../pages/MyParticipant/ParticipantForms";
import AddOrganisation from "../pages/Organisation/add";
import Profile from "../pages/Profile";
import Query from "../pages/Query";
import ConfigStudy from "../pages/StudyConfig";
import Statistics from "../pages/Statistics";
import InvitationPage from "../pages/InvaitationPage";
import MyEPRO from "../pages/MyEPRO";
import MyEproForms from "../pages/MyEPRO/EproForms";
import Econsult from "../pages/Econsult";
import EconsultTeleconsultation from "../pages/Econsult/SimplePeer";

const croRoutes: RouteProps[] = [
  {
    path: "/add/organisation",
    component: AddOrganisation,
    exact: true,
  },

  {
    path: "/",
    component: DashboardCro,
    exact: true,
  },
  {
    path: "/exports",
    component: Exports,
    exact: true,
  },
  {
    path: "/mesArc",
    component: Arc,
    exact: true,
  },
  {
    path: "/eproForms/:studyId",
    component: MyEPRO,
    exact: true,
  },
  // {
  //   path: "/eproForms/:studyId/:eproFormId",
  //   component: MyEproForms,
  //   exact: true,
  // },
  {
    path: "/templates",
    component: Templates,
    exact: true,
  },
  {
    path: "/study/:studyId",
    component: ConfigStudy,
    exact: true,
  },
  {
    path: "/events/:studyId",
    component: Events,
    exact: true,
  },
  {
    path: "/study/:studyId/:surveryId",
    component: Dragable,
    exact: true,
  },
  {
    path: "/translate/:studyId/:surveryId",
    component: TranslationInterface,
    exact: true,
  },

  {
    path: "/template/:surveryId",
    component: Dragable,
    exact: true,
  },
  {
    path: "/dashboard/:studyId",
    component: Statistics,
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/participant/:studyId",
    component: MyParticipant,
    exact: true,
  },
  {
    path: "/participant/:studyId/:participantId",
    component: MyParticipantForms,
    exact: true,
  },
  {
    path: "/query/:studyId",
    component: Query,
    exact: true,
  },
  {
    path: "/firstLogin",
    component: FirstLogin,
    exact: true,
  },
  {
    path: "/eConsult/:studyId",
    component: Econsult,
    exact: true,
  },
  {
    path: "/eConsult/:studyId/:eConsultId",
    component: EconsultTeleconsultation,
    exact: true,
  },
];

croRoutes.push({ render: () => <Redirect to="/" /> });

export default croRoutes;
