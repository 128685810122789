import React from "react";
import moment from "moment";
import { useAtom } from "jotai";
import type { Stripe } from "stripe";
import ContentLoader from "react-content-loader";

import cashIcon from "../../assets/images/espece.png";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import StripePaymentMethodChange from "../../components/StripePaymentMethodChange";
import { ReactComponent as Download } from "../../assets/illustrations/6421887411543238876.svg";

import {
  invoicesListAtom,
  invoicesListIsLoadingAtom,
  asyncSetInvoicesListAtom,
  defaultPaymentMethodAtom,
  asyncSetDefaultPaymentMethodAtom,
} from "../../state/invoices";
import { useTranslation } from "react-i18next";

const Invoices: React.FC = () => {
  const [paymentMethodModalIsVisible, setPaymentMethodModalIsVisible] =
    React.useState(false);

  const [invoices] = useAtom<Stripe.Invoice[]>(invoicesListAtom);
  const [invoicesLoading] = useAtom<boolean>(invoicesListIsLoadingAtom);
  const [myCard] = useAtom<Stripe.PaymentMethod | null>(
    defaultPaymentMethodAtom
  );
  const [, loadInvoices] = useAtom(asyncSetInvoicesListAtom);
  const [, loadDefaultPaymentMethod] = useAtom(
    asyncSetDefaultPaymentMethodAtom
  );
  const { t } = useTranslation();

  React.useEffect(() => {
    loadInvoices();
    loadDefaultPaymentMethod();
  }, []);

  const renderInvoicesList = () =>
    invoices.map((invoice) => {
      let status = "";
      switch (invoice.status) {
        case "paid":
          status = invoice.total === 0 ? t("Profile.free") : t("Profile.paied");
          break;
        case "open":
          status = "Impayée";
          break;
        default:
          status = "N/A";
          break;
      }

      // TODO replace actions with icons
      const action = invoice.paid ? (
        <a href={invoice.invoice_pdf as string | undefined} target="blank">
          <Download className="w-4 ml-3" />
        </a>
      ) : (
        <a
          href={invoice.hosted_invoice_url as string | undefined}
          target="blank"
        >
          ...
        </a>
      );

      return (
        <div
          className={`invoice-row flex flex-row ${
            invoice.paid && invoice.total === 0 ? "free" : ""
          }`}
        >
          <div className="w-3/12">
            <p>{invoice.number}</p>
          </div>
          <div className="w-3/12">
            <p>{moment(invoice.created * 1000).format("DD/MM/YYYY")}</p>
          </div>
          <div className="w-2/12 price">
            <p>{(invoice.total / 100).toFixed(2)} €</p>
          </div>
          <div className="w-2/12 price">
            <p>{status}</p>
          </div>
          <div className="w-2/12">{action}</div>
        </div>
      );
    });

  const renderPaymentCard = () => {
    return (
      myCard && (
        <div
          className={`credit-card ${myCard.card?.brand || ""} selectable`}
          onClick={() => setPaymentMethodModalIsVisible(true)}
        >
          <div className="credit-card-last4">
            {myCard.card?.last4 || "XXXX"}
          </div>
          <div className="credit-card-expiry">
            {myCard.card?.exp_month?.toString().padStart(2, "0") || "XX"}/
            {myCard.card?.exp_year?.toString().slice(-2) || "XX"}
          </div>
        </div>
      )
    );
  };

  const renderInvoiceItemLoader = () => (
    <div className="invoice-row flex flex-row">
      <div className="w-3/12">
        <ContentLoader
          style={{ height: "25px" }}
          backgroundColor="#a6a9c822"
          foregroundColor="#a6a9c8aa"
        >
          <rect x="0" y="6" rx="4" ry="4" width="115" height="16" />
        </ContentLoader>
      </div>
      <div className="w-3/12">
        <ContentLoader
          style={{ height: "25px" }}
          backgroundColor="#a6a9c822"
          foregroundColor="#a6a9c8aa"
        >
          <rect x="0" y="6" rx="4" ry="4" width="85" height="16" />
        </ContentLoader>
      </div>
      <div className="w-2/12">
        <ContentLoader
          style={{ height: "25px" }}
          backgroundColor="#a6a9c822"
          foregroundColor="#a6a9c8aa"
        >
          <rect x="0" y="6" rx="4" ry="4" width="40" height="16" />
        </ContentLoader>
      </div>
      <div className="w-2/12">
        <ContentLoader
          style={{ height: "25px" }}
          backgroundColor="#a6a9c822"
          foregroundColor="#a6a9c8aa"
        >
          <rect x="0" y="6" rx="4" ry="4" width="50" height="16" />
        </ContentLoader>
      </div>
      <div className="w-2/12">
        <ContentLoader
          style={{ height: "25px" }}
          backgroundColor="#a6a9c822"
          foregroundColor="#a6a9c8aa"
        >
          <rect x="0" y="6" rx="4" ry="4" width="30" height="16" />
        </ContentLoader>
      </div>
    </div>
  );

  return (
    <div className="invoices h-fit pb-4">
      {/* <div className="headerContainer flex items-center relative">
        <img src={cashIcon} className="svgClass" alt="paiement" />
        <p className="header">FACTURATION</p>
      </div> */}
      <div className="card-wrapper">{renderPaymentCard()}</div>
      <div className="invoices-wrapper">
        <div className="invoice-row flex flex-row header">
          <div className="w-3/12">
            <p>N° Facture</p>
          </div>
          <div className="w-3/12">
            <p>Date</p>
          </div>
          <div className="w-2/12">
            <p>{t("Profile.montant")}</p>
          </div>
          <div className="w-2/12">
            <p> {t("Profile.statut")}</p>
          </div>
          <div className="w-2/12">
            <p>Action</p>
          </div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden max-h-96">
          {renderInvoicesList()}
          {invoicesLoading ? (
            renderInvoiceItemLoader()
          ) : invoices.length === 0 ? (
            <p className="w-full my-4">Aucune facture.</p>
          ) : null}
        </div>
      </div>

      <Modal
        closeModal={() => setPaymentMethodModalIsVisible(false)}
        showModal={paymentMethodModalIsVisible}
        className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
      >
        {" "}
        <StripePaymentMethodChange
          onSave={() => {
            loadDefaultPaymentMethod();
            setPaymentMethodModalIsVisible(false);
          }}
          setPaymentMethodModalIsVisible={setPaymentMethodModalIsVisible}
        />
        {/* <Button
          status="secondary"
          className="float-left flexCenter my-5"
          width="100px"
          height="50px"
          type="button"
          onClick={() => setPaymentMethodModalIsVisible(false)}
        >
          Quitter
        </Button> */}
      </Modal>
    </div>
  );
};

export default Invoices;
