import { LiveChatWidget, WidgetConfig } from "@livechat/widget-react";
import { useAtom } from "jotai";

import { User } from "../../types/user";
import { userAtom } from "../../state/auth";

export default function LiveChat() {
  const [user] = useAtom<User | null>(userAtom) as any;

  const languageGroup = (localStorage.getItem("i18nextLng") || navigator.language)
    .toString()
    .startsWith("fr")
    ? "4"
    : "3";

  const chatProps: WidgetConfig = {
    license: "15640587",
    group: languageGroup,
    visibility: "minimized"
  };

  if (user?.email) chatProps.customerEmail = user.email;
  if (user?.lastName)
    chatProps.customerName = `${user.firstName ? user.firstName + " " : ""}${
      user.lastName
    }`;

  console.log("[DEBUG] LiveChat - ", { chatProps });

  return <LiveChatWidget {...chatProps} />;
}
