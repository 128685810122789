import React, { useEffect, useRef } from "react";

const DropdownComponent: React.FC<any> = ({ options ,setupIcon=false}) => {
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const dropdownRef = useRef(null) as any;
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  };

  useEffect(() => {
    if (openDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropDown]);
  return (
    <div
      className="inline-flex bg-transparent border rounded-md relative " 
      ref={dropdownRef}
      onClick={() => setOpenDropDown((prev) => !prev)}
    >
     {!setupIcon &&  <span className="  px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-l-md">
        Options
      </span>}

      <div >
        <button
          type="button"
          className="inline-flex items-center justify-center h-full px-2 text-gray-600 border-l border-gray-100 hover:text-gray-700 rounded-r-md hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
       <div className="relative z-50">
       {openDropDown && (
          <div className="absolute    bg-white border border-gray-100 rounded-md shadow-lg" style={{right:0 ,minWidth:150,zIndex:40}}>
            <div className="p-2 relative ">
              {options.map((opt) => (
                <span
                  onClick={async (e) =>opt.action(e)}
                  className="block px-4 py-2 text-xs text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                >
                  {opt.name}
                </span>
              ))}
            </div>
          </div>
        )}
       </div>
      </div>
    </div>
  );
};
export default DropdownComponent;
