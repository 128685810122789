import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";
import requireInput from "../../assets/images/require.png";
import "./styles.scss";
import { useField } from "../../hooks/FormGeneratorHooks/useField";
import { FormGroup } from "../SurveyGenerator/Elements/FormGroup";
import { InputGroup, InputRightElement } from "@chakra-ui/react";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  labelText?: string;
  inlineLabel?: boolean;
  onfocus?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  stylesDiv?: string;
  icon?: string;
  iconColor?: string;
  image?: string;
  require?: boolean;
  withoutMB?: boolean;
};

const Input: React.FC<any> = ({
  error,
  labelText,
  labelProps,
  inlineLabel,
  stylesDiv,
  iconColor,
  icon,
  image,
  withoutMB,
  require,
  ...props
}) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    isValidating,
    resetKey,
    setValue,
    value,
    errorMessages,

    otherProps,
  } = useField(props);
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
 const formGroupProps = {
    errorMessage,
    helper:   helper?? "",
    id,
    isRequired: !!require,
    label,
    showError:false,
    name:props.name,

    ...rest,
  };

  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;
  console.log("🚀 ~ file: useFieldInput.tsx:102 ~ e:", value)
  return (
    <FormGroup {...formGroupProps}>
    { !props.isPreview && (
      <p className="text-xs text-red-300">
        {" "}
        {!isValid  && errorMessages && errorMessages[0]}
      </p>
    )}
    <InputGroup >
     

    <div
      className={`${stylesDiv} floating-input relative ${
        withoutMB ? "" : "mb-3"
      }`}
    >
      <input
        className={classNames(
          "border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14 ",
          inputClassName,
          error ? " border-red-300" : null,
          props.disabled ? "cursor-not-allowed" : ""
        )}
        disabled={ false}
        type={ "text"}
        id={id}
        name={props.name}
        value={value ?? ""}
        onChange={(e) => {
          
          // setIsValidation(true);
          setValue(e.target.value);
        }}
        {...inputProps}
      />
      {labelText ? (
        <label
          htmlFor={inputProps.id}
          className={classNames(
            "card-subtitle absolute top-0 left-0 px-3 py-5 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out ",
            labelClassName
          )}
          {...restOfLabelProps}
        >
          {labelText}
        </label>
      ) : null}

      {require ? (
        <div className={`absolute right-2 top-3 ${props.className}`}>
          <img src={requireInput} alt="require" className="w-2 h-2" />
        </div>
      ) : null}
      {/* {error && (
        <span className=" absolute -bottom-10 right-0 p-2  text-xs -mt-2 mb-2 text-gray-400">
          {error}
        </span>
      )} */}
    </div>
    </InputGroup>
</FormGroup>
  );
};
Input.defaultProps = {
  labelProps: {},
};

export default Input;
