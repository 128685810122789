import {
  ARC_LIST_URL,
  ARC_REGISTER_URL,
  CHECK_CRO_UNPAID_INVOICES,
} from "../config/api";
import { post, get } from "../helper/apiMethods";

export const addArc = async (values) => {
  let res;
  try {
    let payload = { ...values };
    res = await post(ARC_REGISTER_URL, payload);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllArc = async () => {
  let res;
  try {
    res = await get(ARC_LIST_URL);
    return res?.arcList;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getArcLength = async () => {
  let res;
  try {
    res = await get(ARC_LIST_URL);
    return res?.arcList.length;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const checkCroUnpaidInvoices = async () => {
  try {
    const response = await get(CHECK_CRO_UNPAID_INVOICES);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
