import { useAtom } from "jotai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { logout } from "../../api/auth";
import { ActiveRouteAtom } from "../../state/route";
import Button from "../ButtonUI";

export const Logout = () => {
  const { t } = useTranslation() as any;
  function clearAppData() {
    // Clear cache
    caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
            caches.delete(cacheName);
        });
    });

    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();

    // Optionally, you can also clear cache responses from the API
    // This is usually done on the server-side by setting appropriate cache control headers
}
  const [tryToLogout, setTryToLogout] = useState(false);
  const [path, setPath] = useAtom(ActiveRouteAtom);

  const handleLogout = async () => {
    try {
    
      await logout();
      setPath("/");
      window.location.href = "/";
      clearAppData()
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      clearAppData()
      console.error(error);
    }
  };
  return (
    <>
      <div className={""}>
        <span
          className="flex items-center space-x-3 md:py-3 py-1 cursor-pointer"
          onClick={() => {
            setTryToLogout(true);
          }}
        >
          <div className="w-12 h-12 flexCenter">
            <img
              src={require("../../assets/images/logout.png").default}
              width="22"
              alt="logout"
            />
          </div>
          <span className="my-auto menu">{t("SideBar.signout")}</span>
        </span>
      </div>
      {tryToLogout && (
        <div className="w-full  bg-white mt-1 md:mt-3 shadow p-2 rounded-lg slide-left">
          <p className="card-subtitle text-center mb-3">
            {t("SideBar.signoutMessage")}
          </p>
          <div className="flexCenter space-x-3">
            <Button
              status="secondary"
              width="100px"
              height="35px"
              onClick={() => setTryToLogout(false)}
            >
              {t("SideBar.Cancel")}
            </Button>
            <Button width="100px" height="35px" onClick={handleLogout}>
              {t("SideBar.confirm")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Logout;
