import { useTranslation } from "react-i18next";
import Icon from "../../components/Icon";
import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import history from "../../history";
import { useParams } from "react-router-dom";
import { getSurveyById } from "../../api/survey";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./styles.scss";
import Input from "../../components/Input";
import SelectComp from "../../components/Select";
import {
  createTranslation,
  getTranslationBySurveyId,
} from "../../api/translation";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import ButtonUI from "../../components/ButtonUI";
import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import Radiobox from "../../components/RadioButton";
import React from "react";
import MyModal from "../../components/New-Modal";

const TranslationInterface = () => {
  const params: any = useParams();
  const { surveryId, studyId } = params;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [intialForm, setIntialForm] = useState() as any;
  const topRef = useRef<HTMLDivElement>(null);
  const [languageToTranslate, setLanguageToTranslate] = useState("") as any;
  const [openModal, setOpenModal] = useState(false);

  const [map, setMap] = useState({}) as any;
  const languages = [
    { code: "Français", name: "Français" },
    { code: "Anglais", name: "Anglais" },
    { code: "Espagnol", name: "Espagnol" },
    { code: "Allemand", name: "Allemand" },
    { code: "Arabe", name: "Arabe" },
  ] as any;
  const languageGroup = (localStorage.getItem("i18nextLng") || navigator.language)
    .toString()
    .startsWith("fr")
    ? "4"
    : "3";

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageGroup === "3" ? "Anglais" : "Français"
  );
  const [translation, setTranslation] = useState() as any;

  const getTranslationFormData = async () => {
    try {
      setLoading(true);
      let targetLanguage;
      if (selectedLanguage === "Français") {
        targetLanguage = "Anglais";
      } else {
        targetLanguage = "Français";
      }
      const translatedSurvery = await getTranslationBySurveyId(
        surveryId,
        targetLanguage
      );

      if (translatedSurvery && translatedSurvery.translation) {
        setTranslation(translatedSurvery.translation[0]);
        setLoading(false);
      } else {
        setTranslation(null);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };

  const handleTranslationChange = (fieldName, translation) => {
    setMap((prevMap) => ({
      ...prevMap,
      [fieldName]: translation,
    }));
  };
  const getFormData = async () => {
    try {
      const updatedSurvery = await getSurveyById(surveryId);
      if (updatedSurvery) {
        if (updatedSurvery.survey) {
          setIntialForm(updatedSurvery.survey);
          setLoading(false);
        }
      } else setLoading(false);
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  const handleSubmitTranslation = async () => {
    const translationData = {
      surveyId: surveryId,
      language: languageToTranslate,
      map: map,
    };

    try {
      await createTranslation(translationData, surveryId);
      makeToast("success", t("missingWording.successTranslation"));
      history.push(`/study/${studyId}`);
    } catch (error) {
      console.error("Erreur lors de la création de la traduction :", error);
    }
  };
  const surveyData = intialForm?.sections?.flatMap((section) =>
    section.task_data.map((field) => ({
      label: field.label,
      fieldName: field.field_name,
      translations: map[field.label] || {},
    }))
  );
  useEffect(() => {
    if (surveryId !== null) {
      getTranslationFormData();
    } else {
      setTranslation(null);
      setLoading(false);
    }
  }, [languageGroup, surveryId]);

  useEffect(() => {
    setSelectedLanguage(languageGroup === "3" ? "Anglais" : "Français");
  }, [languageGroup]);

  useEffect(() => {
    getFormData();
    
  }, []);

  useEffect(() => {}, [languageToTranslate]);
  return loading ? (
    <Spinner />
  ) : (
    <>
      <Flex justifyContent={"flex-end"} px="6">
        <Stack direction="row" align="center" mb="1">
          <ButtonUI
            width="130px"
            height="47px"
            status="primary"
            className="p-2  "
            onClick={handleSubmitTranslation}
          >
            <CheckIcon color={"gray-50"} mx={1} size="14px" opacity={"0.8"} />
            {t("Survey.Save")}
          </ButtonUI>
        </Stack>
      </Flex>

      <div className="icon cursor-pointer flex flex-row ">
        <Icon
          name="direction-gauche"
          width={50}
          height={50}
          isIcon={true}
          onClick={() => history.push(`/study/${studyId}`)}
        />

        <Box mb="6">
          <Heading size="md" alignItems="center">
            {t("missingWording.visit")} : {intialForm?.name}
          </Heading>
          <span className="cursor-pointer p-1 bg-gray-200 mt-1 rounded-lg text-xs">
            {t("missingWording.MAJle")}{" "}
            {moment(intialForm?.updatedAt).format("DD-MM-YYYY") +
              t("DashboardCro.at") +
              moment(intialForm?.updatedAt).format("HH:mm")}
          </span>
        </Box>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div
          ref={topRef}
          className="layout-doctor"
          style={{
            height: "100%",
            display: "flex",
            alignItems: "stretch",
            borderRadius: "10px",
          }}
        >
          <div className="bg-white  shadow-md py-4 rounded-lg px-4 w-full">
            <div className="flex  flex-row w-full items-center justify-start">
              <div className="flex-row p-6 mt-5 w-1/3">
                {languageToTranslate || translation ? (
                  <input
                    className="flex-grow h-12 px-4 border border-gray-200 rounded-lg  text-center w-full"
                    type="text"
                    value={translation?.language || languageToTranslate}
                    onChange={(e) => setLanguageToTranslate(e.target.value)}
                  ></input>
                ) : (
                  <div className="flex items-center justify-center">
                    <div
                      className="w-7 h-7 rounded-full flex items-center justify-center text-center -mt-3 cursor-pointer"
                      style={{ backgroundColor: "#4565f6" }}
                      onClick={() => setOpenModal(true)}
                    >
                      <AddIcon className="w-6 h-6" color={"white"} />
                    </div>
                  </div>
                )}
                {surveyData?.map((fieldData) => (
                  <>
                    <div
                      key={fieldData.fieldName}
                      className="flex  justify-start  items-center  mt-5 w-full"
                    >
                      <div className="mr-2 w-1/4">{fieldData.label} :</div>
                      <input
                        className="flex-grow h-12 px-4 border border-gray-200 rounded-lg  w-full"
                        type="text"
                        value={
                          map[fieldData.label] ||
                          translation?.map[fieldData.label]
                        }
                        onChange={(e) =>
                          handleTranslationChange(
                            fieldData.label,
                            e.target.value
                          )
                        }
                      ></input>
                    </div>
                  </>
                ))}
              </div>
              <div
                style={{ height: `${surveyData?.length * 80}px` }}
                className="w-0.5 bg-gray-300"
              ></div>
            </div>
          </div>
        </div>
      </DndProvider>

      <MyModal closeModal={() => setOpenModal(false)} showModal={openModal}>
        <div className="flex-auto py-7 px-8 min-w-full flex flex-col items-center justify-center">
          <h4 className="text-lg mb-4 ">{t("missingWording.addLang")}</h4>
          <div className="flex flex-col gap-1">
            {languages.map((lang) => (
              <div key={lang.code}>
                <Radiobox
                  value={lang.code}
                  checked={languageToTranslate === lang.code}
                  onChange={() => {
                    setLanguageToTranslate(lang.code);
                  }}
                  labelText={lang.name}
                  disabled={selectedLanguage === lang.code}
                />
              </div>
            ))}
          </div>
          <div className="flexCenter mt-6 buttons">
            <Button
              status="secondary"
              width={"100px"}
              height={"50px"}
              onClick={() => setOpenModal(false)}
            >
              {t("missingWording.validate")}
            </Button>
          </div>
        </div>
      </MyModal>
    </>
  );
};
export default TranslationInterface;
