import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import {
  createParticipantEvent,
  getStudyEventsConfig,
} from "../../../api/study";
import Button from "../../../components/ButtonUI";
import SelectComp from "../../../components/Select";
import makeToast from "../../../components/Snackbar";
import frMsg from "../../../config/constants/locales/fr-fr.json";
import enMsg from "../../../config/constants/locales/en-us.json";
const EventForm = ({
  studyId,
  participantId,
  closeModal,
  centerId,
  setSelectedEvent,
}) => {
  const { t } = useTranslation();
  const [eventList, setEventList] = useState<any[]>([]);
  const [selectedEventConfig, setSelectedEventConfig] = useState() as any;
  const eventTypes = {
    ADVERSE: t("missingWording.adverseevents"),
    CALL: t("missingWording.phoneCallEvent"),
  };

  const fetchMyEvents = async () => {
    try {
      const res = await getStudyEventsConfig(studyId);
      console.log("🚀 ~ file: EventForms.tsx:31 ~ fetchMyEvents ~ res:", res);
      if (res) {
        setEventList(res);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: EventForms.tsx:35 ~ fetchMyEvents ~ error:",
        error
      );
    }
  };
  useEffect(() => {
    fetchMyEvents();
  }, []);

  return (
    <IntlProvider locale={"en"} messages={enMsg}>
      <div className="w-full md:max-w-full xs:max-w-xs p-4 flex flex-col gap-2 h-80 ">
        <h1
          style={{ fontSize: "36px", fontWeight: "bold" }}
          className="text-center mx-2 mb-6 "
        >
          {t("missingWording.efh1")}
        </h1>
        <div className="mt-2 px-2 mb-8 w-full">
          <label className="text-justify text-gray-500">
            {t("missingWording.efl1")}
          </label>
          <SelectComp
            className="sm:w-full xs:w-72"
            divProps="mt-2"
            onChange={(e) => {
              setSelectedEventConfig(
                eventList.find((f) => f._id === e.target.value)
              );
            }}
            options={
              eventList && eventList.length > 0
                ? eventList.map((e) => {
                    return {
                      label: eventTypes[`${e.type}`] + " - " + e.name,
                      value: e._id,
                    };
                  })
                : []
            }
            text={`${t("missingWording.efl2")}`}
          ></SelectComp>
        </div>
        <div className="flex justify-between px-2">
          <Button
            status="secondary"
            width="100px"
            height="50px"
            className=" float-right bottom-4 btn-delete "
            onClick={closeModal}
          >
            {t("Survey.Cancel")}
          </Button>
          <Button
            status="primary"
            width="100px"
            height="50px"
            className=" float-left bottom-4"
            onClick={async () => {
              let payload = {
                name: selectedEventConfig.name,
                eventConfigId: selectedEventConfig._id,
                participantId: participantId,
                studyId: studyId,
                centerId: centerId,
              };

              const res = await createParticipantEvent(payload);
              if (res) {
                setSelectedEvent(res?.participant);
                makeToast("success", t("missingWording.eft1"));
                closeModal();
              } else {
                makeToast("error", t("missingWording.pht2"));
              }
            }}
            disabled={selectedEventConfig ? false : true}
          >
            {" "}
            {t("missingWording.add")}
          </Button>
        </div>
      </div>
    </IntlProvider>
  );
};
export default EventForm;
