import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type DateRangePickerProps = {
  onChange: (startDate: Date | null, endDate: Date | null) => void;
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({ onChange }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-x-2 mt-2">
      <div>
        <label className=" text-sm text-gray-400">
          {t("missingWording.dateStart")}
        </label>
        <input
          type="date"
          value={startDate ? startDate.toISOString().substr(0, 10) : ""}
          onChange={(event) => {
            const value = event.target.value;
            setStartDate(value ? new Date(value) : null);
            onChange(value ? new Date(value) : null, endDate);
          }}
          className="form-input  border border-gray-200 rounded-md shadow-sm h-12 w-48 mr-3 p-2"
        />
      </div>
      <div>
        <label className=" text-sm text-gray-400">
          {t("missingWording.dateEnd")}
        </label>
        <input
          type="date"
          value={endDate ? endDate.toISOString().substr(0, 10) : ""}
          onChange={(event) => {
            const value = event.target.value;
            setEndDate(value ? new Date(value) : null);
            onChange(startDate, value ? new Date(value) : null);
          }}
          className="form-input border border-gray-200  rounded-md h-12 shadow-sm w-48 p-2"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
