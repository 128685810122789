import axios from "axios";
import makeToast from "../components/Snackbar";
import { KATOMI_API_ENDPOINT } from "../config/api";
const jwt = require("jsonwebtoken");
export const baseURL = KATOMI_API_ENDPOINT;

const Client = axios.create({
  baseURL,
});

export const config = async (token): Promise<any> => {
  try {
    let token = localStorage.getItem("token");

    // Check if token exists and not expired
    if (token) {
      const decodedToken = jwt.decode(token) as { exp?: number };
      let refreshToken = localStorage.getItem("refreshToken");

      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        throw new Error("Token expired");
      }

      const fifteenMinutesFromNow = Date.now() + 15 * 60 * 1000;
      if (
        decodedToken.exp &&
        decodedToken.exp * 1000 < fifteenMinutesFromNow &&
        Date.now() <= decodedToken.exp &&
        decodedToken.exp * 1000
      ) {
        const res = await axios.get(
          `${baseURL}/auth/refresh-token`,
          {
            headers: {
              Authorization: `${token}||${refreshToken}`,
            },
          }
        );
        if (res && res.data) {
          token = res.data.token
          refreshToken =res.data.refreshToken
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          
        }
      }

      return {
        headers: {
          Authorization: `${token}||${refreshToken}`,
        },
      };
    }

    return { headers: {} };
  } catch (err) {
    console.error({ err });
    makeToast("error", "Failed to verify token validity");
    // redirect to login page or logout user
    localStorage.removeItem("token");
    return { headers: {} };
  }
};
export const configBlob = (token: string | null) => {
  return {
    headers: {
      Authorization: `${token}`,
      responseType: "arraybuffer",
    },
  };
};
export const config2 = (token: string | null) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent: { loaded: any }) =>
      console.log(progressEvent.loaded),
  };
};

export const configExcel = (token: string | null) => {
  return {
    headers: {
      Authorization: `${token}`,
      responseType: "arraybuffer",
      //"Content-Type": "blob"
    },
  };
};
// POST action
const post = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");

    let response;
    if (token) response = await Client.post(url, data, await config(token));
    else response = await Client.post(url, data);

    return response;
  } catch (err) {
    console.error({ err });
  }
};
//
const postExcel = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");

    let response;
    if (token) response = await Client.post(url, data, configExcel(token));
    else response = await Client.post(url, data);

    return response;
  } catch (err) {
    console.error({ err });
  }
};
// POST action
const post2 = async (url: string, data: any, onUploadProgress: any) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token) {
      response = await Client.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    } else response = await Client.post(url, data);

    return response;
  } catch (err) {
    console.error({ err });
  }
};

// GET action
const get = async (url: string) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token) response = await Client.get(url, await config(token));
    else response = await Client.get(url, await config(token));

    return response.data;
  } catch (err) {
    console.error({ err });
  }
};

const getBlobFile = async (url: string) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token)
      response = await Client.get(url, {
        headers: {
          Authorization: `${token}`,
          responseType: "blob",
        },
      });
    else
      response = await Client.get(url, {
        headers: {
          Authorization: `${token}`,
          responseType: "blob",
        },
      });
    return response.data;
  } catch (err) {
    console.error({ err });
  }
};

const getBlob = async (url: string) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token) response = await Client.get(url, configBlob(token));
    else response = await Client.get(url, configBlob(token));
    return response.data;
  } catch (err) {
    console.error({ err });
  }
};

// PATCH action
const patch = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token) response = await Client.patch(url, data, await config(token));
    else response = await Client.patch(url, data);
    return response.data;
  } catch (err) {
    console.error({ err });
  }
};
const update = async (url: string, data: any) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token) response = await Client.put(url, data, await config(token));
    else response = await Client.put(url, data);
    return response.data;
  } catch (err) {
    console.error({ err });
  }
};

// DELETE action
const remove = async (url: string) => {
  try {
    const token = localStorage.getItem("token");
    //const tokenId = localStorage.getItem("idToken");
    let response;
    if (token) response = await Client.delete(url, await config(token));
    else response = await Client.delete(url);
    return response.data;
  } catch (err) {
    console.error({ err });
  }
};



export {
  Client,
  get,
  post,
  remove,
  patch,
  update,
  post2,
  getBlob,
  getBlobFile,
  postExcel,
};
