import { atom } from "jotai";
import { getExports } from "../api/study";

export const listExportsAtom = atom<any[] | []>([]);
export const selectedStudyIdForExportsAtom = atom<string>("all");
export const exportPage = atom<any>(1);
export const totalResults = atom<any>(0);
export const asyncGetExportsList = atom(null, async (get, set) => {
  try {
    const res = await getExports(get(selectedStudyIdForExportsAtom),get(exportPage),10);
    set(totalResults,res?.data?.pagination?.totalCount)
    set(listExportsAtom, res?.data?.exports || []);
  } catch (error) {
    console.error(error);
  }
});
