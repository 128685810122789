import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiSend } from "react-icons/fi";
import { BiFileFind } from "react-icons/bi";
import { GoIssueClosed, GoIssueReopened } from "react-icons/go";
import { SiConvertio, SiOpsgenie, SiProbot } from "react-icons/si";
import { updateQuery } from "../../api/queries";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import moment from "../../helper/moment";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { default as Button, default as ButtonUI } from "../ButtonUI";
import Modal from "../ModalUI";
import SelectComp from "../Select";
import makeToast from "../Snackbar";
import Tooltip from "../toolTip";
import Progress from "../NProgress";
import QueryModal from "./QueryModal";


export function renderNumberOfMsgNotSee(messages,userId) {
  try {
    const filteredData = messages.filter(
      (item) => item.vu === false && item.sentTo === userId
    );
    return filteredData.length;
  } catch (error) {
    return 0 
  }
}
interface TableProps {
  data: any;
  loading: boolean;
  setShouldUpdate: any;
  setTypeQuery: any;
}

const Table: React.FC<TableProps> = ({
  data,
  loading,
  setShouldUpdate,
  setTypeQuery,
}) => {
  const [user] = useAtom(userAtom);
  const { t } = useTranslation();
  const [selctedQuery, setSelectedQuery] = useState() as any;
  const [textMsg, setTextMsg] = useState() as any;
  const [selectedStatusQuery, setSelectedStatusQuery] = useState() as any;

  useEffect(() => {
    setSelectedStatusQuery(selctedQuery?.status);
    console.log(
      "🚀 ~ useEffect ~     selctedQuery?.formDataId?:",
      selctedQuery
    );

    setTextMsg();
  }, [selctedQuery]);


  return (
    <>
      <Progress isAnimating={loading} key={0} />
      <div className="p-1.5 w-full inline-block align-middle">
        <div className="overflow-hidden border rounded-lg querytable">
          <table className=" xs:w-auto md:w-full ">
            <thead className="bg-gray-50 ">
              {/* Refactor */}
              <tr>
                <th
                  scope="col"
                  className="md:px-6 sm:px-2 xs:px-0 py-3 text-xs font-bold text-center text-gray-500 uppercase w-4 "
                >
                  {t("missingWording.participant")}
                </th>

                <th
                  scope="col"
                  className="md:px-6 sm:px-2 xs:px-0 py-3 text-xs xs:pl-1  font-bold text-center text-gray-500 uppercase "
                >
                  {t("missingWording.tableViste")}
                </th>
                <th
                  scope="col"
                  className="md:px-6 sm:px-2 xs:px-0 py-3 text-xs xs:pl-1 font-bold text-center text-gray-500 uppercase "
                >
                  {t("missingWording.fieldName")}
                </th>
                <th
                  scope="col"
                  className="md:px-6 sm:px-2 xs:px-0 py-3 text-xs xs:pl-1 font-bold text-center text-gray-500 uppercase "
                >
                  {t("Participant.Statut")}
                </th>
                <th
                  scope="col"
                  className="md:px-6 sm:px-2 xs:px-0.5 py-3 text-xs xs:pl-1 font-bold text-center text-gray-500 uppercase "
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="md:px-6 sm:px-2 xs:px-0 py-3 text-xs xs:pl-1 font-bold text-center text-gray-500 uppercase w-auto "
                >
                  {t("missingWording.maj")}
                </th>
              </tr>
            </thead>
            {data.length > 0 &&
              (loading ? (
                <tbody className="h-96 "></tbody>
              ) : (
                <tbody className="divide-y divide-gray-200">
                  {data.map((p) => {
                    console.log("🚀 ~ {data.map ~ p:", p.messages);
                    return (
                      <tr
                        className="cursor-pointer  md:text-sm xs:text-xs lg:text-md"
                        onClick={() => {
                          setSelectedQuery(p);
                        }}
                      >
                        <td className="md:px-6 py-4 xs:px-0 xs:text-xs sm:text-sm md:text-md  font-medium text-gray-800  text-center min-w-16 ">
                          {p?.participantId?.publicId}
                        </td>
                        <td className="md:px-6 py-4 xs:px-1 xs:text-xs sm:text-sm md:text-md font-medium text-gray-800 text-center">
                          {p?.formDataId && p?.formDataId?._id && (
                            <>
                              {" "}
                              {p?.formDataId?.name +
                                " - " +
                                p?.formDataId?.sections[p?.sectionNbr]
                                  ?.sectionName}
                            </>
                          )}
                        </td>
                        <td className="md:px-6 py-4 xs:px-1 xs:text-xs sm:text-sm md:text-md font-medium text-gray-800 text-center ">
                          {p?.fieldName}
                        </td>
                        {/* Refactor */}
                        <td className="md:px-6 py-4 xs:px-1 xs:text-xs sm:text-sm md:text-md font-medium text-gray-800 text-center">
                          <span className="flexCenter">
                            {p?.status === "OPEN" ? (
                              <Tooltip
                                tooltipText={t("missingWording.open")}
                                position={"left"}
                              >
                                <GoIssueReopened className="text-yellow-500 w-4 h-4 xs:ml-2 md:ml-4 relative" />
                                {renderNumberOfMsgNotSee(p.messages,user?._id) > 0 && (
                                  <span
                                    style={{ fontSize: "10px" }}
                                    className="-right-1 -top-2 md:absolute xs:relative inline-block py-0.5 px-0.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded ml-2"
                                  >
                                    {renderNumberOfMsgNotSee(p.messages,user?._id)}
                                  </span>
                                )}
                              </Tooltip>
                            ) : (
                              <Tooltip
                                tooltipText={t("missingWording.closed")}
                                position={"left"}
                              >
                                <GoIssueClosed className="text-green-500 w-4 h-4 xs:ml-2 md:ml-4" />
                              </Tooltip>
                            )}
                          </span>
                        </td>
                        <td className="md:px-6 py-4 xs:px-1 xs:text-xs sm:text-sm md:text-md font-medium text-gray-800  ">
                          <span className="flexCenter">
                            {" "}
                            {p?.isSystemGenerated ? (
                              <Tooltip
                                tooltipText={t("missingWording.auto")}
                                position={"left"}
                              >
                                <SiConvertio className="text-yellow-400 w-4 h-4  xs:ml-2 md:ml-1" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                tooltipText={t("missingWording.arcQuery")}
                                position={"left"}
                              >
                                <SiOpsgenie className="text-yellow-600 w-4 h-4 xs:ml-2 md:ml-1" />
                              </Tooltip>
                            )}
                          </span>
                        </td>
                        <td className="md:px-6 py-4 xs:px-1 xs:text-xs sm:text-sm md:text-md font-medium text-gray-800 text-center ">
                          {moment(p?.updatedAt).format("DD-MM-YYYY") +
                            t("DashboardCro.at") +
                            moment(p?.updatedAt).format("HH:mm")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ))}
          </table>
          {((data.length > 0 && loading) || (loading && data.length === 0)) && (
            <div className="flex justify-center text-center items-center w-full mt-8 mb-8">
              <p className="menuInput"> {t("missingWording.loading")}...</p>
            </div>
          )}
          {!loading && data.length === 0 && (
            <div className="flex justify-center items-center w-full mt-8 mb-8 ">
              <p className="menuInput"> {t("Query.Message")}</p>
            </div>
          )}
        </div>
        {/* Refactor */}
      </div>{" "}
      {selctedQuery && (
        <Modal
          showModal={true}
          className=" md:px-10 xs:px-2 py-1 relative overflow-hidden"
          style={{ minWidth: "50%" }}
        >
          <ButtonUI
            status="icon-secondary"
            onClick={() => setSelectedQuery()}
            className="absolute right-2 top-2  cursor-pointer"
          >
            <Close />
          </ButtonUI>

          <QueryModal
            selectedStatusQuery={selectedStatusQuery}
            setSelectedStatusQuery={setSelectedStatusQuery}
            setShouldUpdate={setShouldUpdate}
            setTypeQuery={setTypeQuery}
            selctedQuery={selctedQuery}
            setSelectedQuery={setSelectedQuery}
            setTextMsg={setTextMsg}
            textMsg={textMsg}
          />
        </Modal>
      )}
    </>
  );
};

export default Table;
