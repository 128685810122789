import { Grid, GridItem, InputGroup, VStack } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { elementAtomData } from "../../../../state/FormGeneratorState/elmentAtomdata";
import { FieldInput } from "../../Elements/FieldInput";
import { SwitchInput } from "../../Elements/FieldSwitcher";
import { FormGroup } from "../../Elements/FormGroup";

const UnitConfig = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const intl = useIntl();
  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [atomElment] = useAtom(elementAtomData);
  const allowedInputs = ["NumberInput", "TextInput"];
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const { t } = useTranslation();
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  return (
    <>
      <FormGroup {...formGroupProps} >
        <InputGroup name="hasUnit" className="hasUnit">
          <VStack
            spacing={2}
            align="start"
            className="border p-4 rounded-lg w-full "
          >
            <Grid
              width={"100%"}
              templateColumns="repeat(2, 1fr)"
              alignItems={"baseline"}
              gap={0}
            >
              <GridItem w="170px" h="10">
                {" "}
                <span style={{ fontSize: "16px" }} className="font-bold ">
                  {t("missingWording.unit")}
                </span>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(2, 1fr)"
              alignItems={"center"}
              className="mb-6 hasUnit_0"
              gap={0}
            >
              {" "}
              <span className="mx-0 p-2 text-sm ">
              {t("missingWording.unitField")}{" "}
              </span>
              <Grid templateColumns="repeat(2, 1fr)" w="100%" h="10"    >
                <Grid
                  templateColumns="repeat(4, 1fr)"
                  w="100%"
                  h="10"
                  className="px-2 py-3  card-subtitle "
                >
                  <GridItem>
                    <span className="p-2  card-subtitle">{t("missingWording.desactive")}</span>
                  </GridItem>
                  <GridItem>
                    {" "}
                    <SwitchInput
                      value={props.value?.active}
                      name="hasUnit.active"
                    />
                  </GridItem>
                  <GridItem>
                    <span className="p-2 card-subtitle">{t("missingWording.activer")}</span>
                  </GridItem>
                </Grid>
              </Grid>
              {props.form.values?.hasUnit?.active && (
                <Grid templateColumns="repeat(1, 1fr)" width={"100%"} gap={0}>
                  <GridItem
                    className="px-2 py-3  card-subtitle "
                    w="100%"
                    h="10"
                  >
                    <span className=" text-xs">{t("missingWording.unit0")}</span>
                    <FieldInput
                      placeholder={t("missingWording.unit0")}
                      name="hasUnit.unit"
                      value={props.value?.unit}
                      isPreview={true}
                      className="hasUnit_1"
                    />
                  </GridItem>
                </Grid>
              )}
            </Grid>
          </VStack>
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default UnitConfig;
