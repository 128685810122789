export const Role = {
    fr: {
        MajS:"La mise à jour du profil a été effectuée avec succès.",
        Err:"Un erreur c'est produite.",
        MajSucc:"La mise à jour du profil a été effectuée avec succès.",
        NewRole:"Nouveau profil",
        ManageRole:"Gestion des profils",
        Cancel:"Annuler",
        Add:"Ajouter",
        Save:"Enregister",
        Srole:"Voulez-vous supprimer ce profil ?",
        ExRole:"Tous les investigateurs ayant ce profil seront par conséquent exclus de cette étude.",
        Delete:"Supprimer",
        MsgDelete:"Le profil a été supprimé avec succès. ",
        CErr:"Chargement de la configuration à échouer.",
        RName:"Nom du profil",
        AInes:"0 investigateur pour ce profil",
        Ines:"investigateur(s)",
        NoProfilForCeRole:"Aucun profil pour cette étude.",
    },
    en: {
        NoProfilForCeRole: "No role for this study",
        AInes: "0 investigator for this profile",
        Ines: "investigator(s)",
        RName: "Name of the profile",
        CErr: "Configuration loading failed",
        MsgDelete: "The profile has been successfully deleted",
        Srole: "Do you want to delete this role?",
        ExRole: "All investigators with this profile will therefore be excluded from this study.",
        Delete: "Delete",
        MajS: "Update successfully completed",
        Err: "An error has occurred",
        ShiftSucc: "Update performed successfully.",
        NewRole: "+ New profile",
        ManageRole: "Profile management",
        Cancel: "Cancel",
        Add: "Add",
        Save:"Save",
    },
  };
  