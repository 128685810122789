export const DashboardCro = {
  fr: {
    title: "Mes études",
    WeAreOn: "Nous sommes le ",
    Rest: "Réinitialiser",
    NewStudy: "+ Nouvelle étude",
    NewStudyModalTitle: "Nouvelle étude",
    ConsultationOf: "Consultation du",
    Report: "Compte rendu",
    Prescription: "Prescription",
    CardCPSTitle: "Ma carte CPS",
    Info: "Mes informations",
    nationalId: "Identifiant national",
    cardId: "Identifiant de la carte",
    Name: "Nom",
    FirstName: "Prénom",
    IssuedOn: "Émise le",
    Survey: "Enquêtes",
    NoSurveyFound: "Aucun questionnaire trouvé",
    lastUpdate: "Dernière modification apportée le",
    taskOfDay: "Taches du jour",
    Agenda: "Agenda",
    today: "Aujourd'hui",
    EmptyList: "La liste de rendez-vous est vide",
    EmptyTask: "Aucune tâche aujourd'hui",
    EmptyConsultation: "Aucune consultation aujourd'hui",
    ptientofDay: "Patients du jour",
    connected: "Vous êtes maintenant connecté",
    noActiveStudy: "Aucune étude trouvée",
    noArchiveStudy: "Aucune étude archivée trouvée",
    ActiveStudy: "étude actifs",
    Netu: "Nom de l'étude",
    dStudy: "Description de l'étude",
    AddSuccessStuy: "L'ajout d'étude a été effectué avec succès",
    editSuccessStuy: "L'édition de l'étude a été effectuée avec succès",
    errorH: "Une erreur s'est produite",
    Cancel: "Annuler",
    Save: "Enregistrer",
    DResp:
      "Voulez-vous désigner un responsable d'étude parmis les membres de votre équipe ?",
    Des: "Désigner",
    Cont: "continuer",
    Larc: "Liste des utilisateurs",
    at: " à ",
    downloadEXCELSuccess:
      "Votre demande d'extraction de données a bien été prise en charge.",
    downloadEXCELPending:
      "Une demande d'extraction est en cours de traitement sur cette étude, merci d'en attendre la finalisation.",
    downloadEXCELError:
      "Une erreur est survenue lors de l'extraction des données. Merci de réessayer plus tard.",
    bienvenuTuto:
      "Bienvenue sur Ksearch ! Nous sommes ravis de vous accueillir. Prêt à commencer votre tutoriel ?",
    start: "Commencer",
  },
  en: {
    start: "Start",
    bienvenuTuto:
      "Welcome to Ksearch! We are delighted to have you. Ready to start your tutorial?",
    downloadEXCELSuccess: "Your data extraction request is being processed.",
    downloadEXCELPending:
      "An extraction request is currently being processed for this study, so please wait until it has been finalized.",
    downloadEXCELError:
      "An error occurred during data extraction. Please try again later.",
    at: " at  ",
    Larc: "List of users",
    DResp: "Do you want to designate a study leader among your team members?",
    Des: "Designate",
    Cont: "continue",
    Cancel: "Cancel",
    Save: "Save",
    AddSuccessStuy: "Study added successfully",
    editSuccessStuy: "Study edited successfully",
    errorH: "An error occurred",
    Netu: "Name of the study",
    dStudy: "Description of the study",
    title: "Studies",
    WeAreOn: "We are on ",
    Reset: "Reset",
    NewStudy: "+ New study",
    NewStudyModalTitle: "New study",
    ConsultationOf: "Consultation of",
    Report: "Report",
    Prescription: "Prescription",
    CardCPSTitle: "My CPS card",
    Info: "My information",
    nationalId: "National ID",
    cardId: "Card ID",
    Name: "Name",
    FirstName: "First name",
    IssuedOn: "Issued on",
    Survey: "Enquêtes",
    NoSurveyFound: "No survey found",
    lastUpdate: "Last modification made on",
    taskOfDay: "Tasks of the day",
    Agenda: "Agenda",
    today: "Today",
    EmptyList: "The meet list is empty",
    EmptyTask: "No task today",
    EmptyConsultation: "No consultation today",
    ptientofDay: "Patients of the day",
    connected: "You are now connected",
    noActiveStudy: "No study found",
    noArchiveStudy: "No archived studies found",
    ActiveStudy: "Actif studys",
  },
};
