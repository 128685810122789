import classNames from "classnames";
import { useAtom } from "jotai";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { screenBreakpoints } from "../../config/theme";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import Icon from "../Icon";
import Logout from "../Logout";
import "../SideBar/styles.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { studyWriterConfigAtom } from "../../state/studyWriterConfig";
import { getStudyConfigSimple } from "../../api/study";

export const SiderBarWRITER = () => {
  const { t, i18n } = useTranslation() as any;
  const fetchStudyWriterConfig = async (studyId) => {
    try {
      const res = await getStudyConfigSimple(studyId);
      if (res && res.studyConfig) {
        setStudyWriterConfig(res.studyConfig);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: index.tsx ~ fetchStudyWriterConfig ~ error:",
        error
      );
    }
  };
  useEffect(() => {
    let routeArray = location.pathname.split("/").filter((e) => e && e !== "");
    if (_.isEmpty(routeArray)) {
      return;
    } else if (routeArray.length >= 2) {
      fetchStudyWriterConfig(routeArray[1]);
    }
  }, []);
  const writerMenus = [
    {
      icon: "studies",
      text: t("SideBar.title"),
      url: "/",
    },

    {
      icon: "monCompte",
      text: t("SideBar.monCompte"),
      url: "/profile",
    },
  ];
  const [user] = useAtom(userAtom) as any;
  const [path, setPath] = useAtom(ActiveRouteAtom);
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const [workSpaces, setWorkSpaces] = useState(false);
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );
  const [studySelectedId, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [menus, setMenu] = useState(writerMenus) as any;
  const location = useLocation();

  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });
  const menuCroSelectedStudy = (studyId) => {
    return studyWriterConfig &&
      studyWriterConfig?.studyId &&
      studyWriterConfig?.studyId?.eConsult
      ? [
          {
            icon: "participants",
            text: t("SideBar.Participants"),
            url: `/participant/${studyId}`,
          },
          {
            icon: "query",
            text: t("SideBar.Queries"),
            url: `/query/${studyId}`,
          },
          {
            icon: "calendar",
            text: t("missingWording.events"),
            url: `/events/${studyId}`,
          },
          {
            icon: "econsult",
            text: t("eConsult.eConsult"),
            url: `/eConsult/${studyId}`,
          },
        ]
      : [
          {
            icon: "participants",
            text: t("SideBar.Participants"),
            url: `/participant/${studyId}`,
          },
          {
            icon: "query",
            text: t("SideBar.Queries"),
            url: `/query/${studyId}`,
          },
          {
            icon: "calendar",
            text: t("missingWording.events"),
            url: `/events/${studyId}`,
          },
        ];
  };

  useEffect(() => {
    let routeArray = location.pathname.split("/").filter((e) => e && e !== "");

    if (_.isEmpty(routeArray)) {
      setMenu(writerMenus);
    } else if (routeArray.length === 2 && routeArray[0] === "participant") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "query") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "events") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "eConsult") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length > 2 && routeArray[0] === "eConsult") {
      setMenu([]);
    } else if (routeArray.length === 3 && routeArray[0] === "participant") {
      setMenu([]);
      setWorkSpaces(false);
    } else if (routeArray.length === 1 && routeArray[0] === "work-spaces") {
      setMenu([]);
      setWorkSpaces(true);
    } else {
      setMenu(writerMenus);
    }
  }, [location, i18n.language, studyWriterConfig]);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);
  useEffect(() => {
    if (forceMenuOpen) {
      setForceMenuOpen(false);
    }
  }, [path]);

  return !_.isEmpty(menus) ? (
    <>
      <div className="sidebar-doctor sidebar z-40" id={"WriterSideBar_menu"}>
        <div className="flex justify-between md:hidden absolute mobile-menu-burger">
          <button
            className="p-4 focus:outline-none"
            onClick={() => {
              setForceMenuOpen(!forceMenuOpen);
            }}
          >
            <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
          </button>
        </div>
        <div
          className={`swipe-events-container ${
            !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
          }`}
          {...swipeEventsHandlers}
        />
        <div
          className={`sidebar-container  transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
            menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
          } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
        >
          <div className="relative">
            <div className="flex items-center space-x-3 relative notification ">
              {user?.business_photo ? (
                <div className="avatar-doctor1 relative"></div>
              ) : (
                <div className="avatar-doctor1 relative">
                  {user?.gender === "female" ? (
                    <WomanPatient className=" rounded-full  bg-pink-200 w-3/4 h-3/4" />
                  ) : (
                    <ManPatient className=" rounded-full bg-yellow-100 w-3/4 h-3/4" />
                  )}
                </div>
              )}

              <div>
                <span className="menu capitalize">
                  {user?.firstName} {user?.lastName}
                </span>
                <p className="capitalize docter-center">
                  {user?.organisationId?.name}
                </p>
              </div>
            </div>

            <nav className={classNames("mt-6")}>
              {studySelectedId && (
                <div className="icon link cursor-pointer flex items-center">
                  <Icon
                    name="direction-gauche"
                    width={50}
                    height={50}
                    isIcon={true}
                    onClick={() => {
                      setStudySelectedId(null);
                      history.push("/");
                    }}
                  />
                  <div className="mb-2">
                    <p
                      className=""
                      onClick={() => {
                        setStudySelectedId(null);
                        history.push("/");
                      }}
                    >
                      {t("SideBar.Back")}
                    </p>
                  </div>
                </div>
              )}
              {menus.map((menu, idx) => {
                return (
                  <div
                    className={path === menu.url ? "selected-menu" : ""}
                    id={`WriterSideBar_menu_${idx}`}
                  >
                    <Link
                      to={menu.url}
                      className={classNames(
                        "flex items-center space-x-3 md:py-3 -py-1",
                        "cursor-pointer"
                      )}
                      onClick={() => {
                        setPath(menu.url);
                      }}
                    >
                      <div className="w-12 h-12 flexCenter ">
                        {menu.icon === "template" ? (
                          <img
                            src={require("../../assets/templates.png").default}
                            width={25}
                            alt="template"
                          />
                        ) : menu.icon === "list" ? (
                          <img
                            src={require("../../assets/list.svg").default}
                            width={25}
                            alt="template"
                          />
                        ) : (
                          <Icon isIcon={true} name={menu.icon} width={32} height={32} />
                        )}
                      </div>
                      <span className="my-auto menu">{menu.text}</span>
                    </Link>
                  </div>
                );
              })}

              <Logout />
            </nav>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {workSpaces ? (
        <div className="sidebar-doctor sidebar z-40 ">
          <div className="flex justify-between md:hidden absolute mobile-menu-burger">
            <button
              className="p-4 focus:outline-none"
              onClick={() => {
                setForceMenuOpen(!forceMenuOpen);
              }}
            >
              <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
            </button>
          </div>
          <div
            className={`swipe-events-container ${
              !menuToggleIsActive || !forceMenuOpen
                ? ""
                : "swipeable-fullscreen"
            }`}
            {...swipeEventsHandlers}
          />
          <div
            className={`sidebar-container  transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
              menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
            } ${
              menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""
            }`}
          >
            <div className="relative">
              <div className="flex items-center space-x-3 relative notification ">
                {user?.business_photo ? (
                  <div className="avatar-doctor1 relative"></div>
                ) : (
                  <div className="avatar-doctor1 relative">
                    {user?.gender === "female" ? (
                      <WomanPatient className=" rounded-full  bg-pink-200 w-3/4 h-3/4" />
                    ) : (
                      <ManPatient className=" rounded-full bg-yellow-100 w-3/4 h-3/4" />
                    )}
                  </div>
                )}

                <div>
                  <span className="menu capitalize">
                    {user?.firstName} {user?.lastName}
                  </span>
                  <p className="capitalize docter-center">
                    {user?.organisationId?.name}
                  </p>
                </div>
              </div>

              <nav className={classNames("mt-2")}>
                <Logout />
              </nav>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SiderBarWRITER;
