import * as React from "react";
import { useTranslation } from "react-i18next";
import Input from "../FloatInput";

interface Props {
  investigators: {
    firstName: string;
    lastName: string;
    email: string;
  }[];
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  errors: any;
  values: any;
  touched: any;
  setFieldValue: any;
  addMargin?: boolean;
}

const InvestigatorAutocompleteInput: React.FunctionComponent<Props> = ({
  investigators,
  handleChange,
  errors,
  values,
  touched,
  setFieldValue,
  addMargin = true,
}) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]) as any[];
  const [selectedSuggestion, setSelectedSuggestion] = React.useState<
    number | null
  >(null);
  const [selectedSuggestionObject, setSelectedSuggestionObject] =
    React.useState<any>();
  const { t } = useTranslation() as any;
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    handleChange(event);
    if (name === "firstName") {
      setFieldValue("firstName", value);
      setFirstName(value);
    } else if (name === "lastName") {
      setFieldValue("lastName", value);
      setLastName(value);
    } else if (name === "email") {
      setFieldValue("email", value);
      setEmail(value);
      updateSuggestions(value);
    }
    if (
      name === "email" &&
      selectedSuggestionObject &&
      selectedSuggestionObject !== null
    ) {
      setSelectedSuggestion(null);
      setSelectedSuggestionObject(null);
      setFieldValue("firstName", "");
      setFieldValue("lastName", "");
      setFieldValue("writerId", "");
    }
  };

  const updateSuggestions = (value: string) => {
    const suggestions = investigators.filter((investigator) =>
      // investigator.firstName.toLowerCase().includes(value.toLowerCase()) ||
      // investigator.lastName.toLowerCase().includes(value.toLowerCase()) ||
      investigator.email.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(suggestions);
  };

  const selectSuggestion = (index: number) => {
    setFirstName(suggestions[index].firstName);
    setFieldValue("writerId", suggestions[index].writerId);
    setFieldValue("firstName", suggestions[index].firstName);
    setFieldValue("lastName", suggestions[index].lastName);
    setFieldValue("email", suggestions[index].email);
    setLastName(suggestions[index].lastName);
    setEmail(suggestions[index].email);
    setSuggestions([]);
    setSelectedSuggestion(null);
    setSelectedSuggestionObject(suggestions[index]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (selectedSuggestion === null) {
        setSelectedSuggestion(0);
      } else if (selectedSuggestion < suggestions.length - 1) {
        setSelectedSuggestion(selectedSuggestion + 1);
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      if (selectedSuggestion === null) {
        setSelectedSuggestion(suggestions.length - 1);
      } else if (selectedSuggestion > 0) {
        setSelectedSuggestion(selectedSuggestion - 1);
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (selectedSuggestion !== null) {
        selectSuggestion(selectedSuggestion);
      }
    } else if (event.key === "Escape") {
      setSuggestions([]);
      setSelectedSuggestion(null);
    }
  };

  return (
    <div className="">
      <div className={`${addMargin ? "mb-8" : "mb-0"}`}>
        <div className="mb-4">
          <Input
            id="lastName"
            name="lastName"
            placeholder={t("Investigator.P12")}
            value={values.lastName}
            onChange={handleInputChange}
            onBlur={handleInputChange}
            inlineLabel={false}
            onfocus={true}
            className="w-full"
            labelText={t("Investigator.P13")}
            disabled={
              selectedSuggestionObject && selectedSuggestionObject?.writerId
            }
          />
          {errors.lastName && touched.lastName && (
            <p className="text-red-300  text-sm ml-4">
              {t("Investigator.P14")}
            </p>
          )}
        </div>
        <div className="mb-4">
          <Input
            id="firstName"
            name="firstName"
            placeholder={t("Investigator.P9")}
            value={values.firstName}
            onChange={handleInputChange}
            onBlur={handleInputChange}
            inlineLabel={false}
            onfocus={true}
            className="w-full "
            labelText={t("Investigator.P10")}
            disabled={
              selectedSuggestionObject && selectedSuggestionObject?.writerId
            }
          />
          {errors.firstName && touched.firstName && (
            <p className="text-red-300  text-sm ml-4">
              {t("Investigator.P11")}
            </p>
          )}
        </div>

        <div className="mb-4 relative">
          <Input
            id="email"
            name="email"
            placeholder={t("Investigator.P15")}
            value={values.email}
            onChange={handleInputChange}
            onBlur={(e) => {
              handleInputChange(e);
            }}
            onKeyDown={handleKeyDown}
            inlineLabel={false}
            onfocus={true}
            className="w-full"
            labelText="Email"
          />
          {errors.email && touched.email && (
            <p className="text-red-300  text-sm ml-4 ">
              {t("Investigator.P16")}
            </p>
          )}
          {suggestions.length > 0 && (
            <ul className="absolute z-50 left-0 top-16 w-full rounded-md shadow-lg bg-white cursor-pointer">
              {suggestions.map((suggestion, index) => (
                <li
                  key={suggestion.email}
                  className={`px-4 py-2 hover:bg-blue-600 hover:text-white text-xs text-justify leading-2 flex-col ${
                    index === selectedSuggestion
                      ? "bg-indigo-500 text-white"
                      : ""
                  }`}
                  onClick={() => selectSuggestion(index)}
                >
                  <p>
                    {" "}
                    {suggestion.firstName} {suggestion.lastName}{" "}
                  </p>
                  <p className="mt-0.5">{suggestion.email}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export default InvestigatorAutocompleteInput;
