import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getStudyRoles } from "../../api/study";
import { saveSurveyAsTemplate } from "../../api/survey";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import makeToast from "../../components/Snackbar";

type Props = any;
const SaveSurveyAsTemplateModal: React.FC<Props> = ({
  visteList,
  surveyDetails,
  setShowSaveAsTemplateModal,
  setRefresh,
}) => {
  const params: any = useParams();
  const { studyId } = params;
  const { t } = useTranslation();
  const [step] = useState(1);
  const [type] = useState("Classic");
  const [name, setName] = useState(
    `${surveyDetails?.name} ${t("missingWording.template")}`
  );
  const [desc, setDesc] = useState("");
  const [role, setRole] = useState("");
  const [dependency, setDependency] = useState("");

  const [listRolesProps, setListRolesProps]: any[] = useState([]);

  async function fetchMyAPI(): Promise<void> {
    // setLoading(true)
    const roles = await getStudyRoles(studyId);
    setListRolesProps(
      roles
        ? roles.roles?.map((r) => {
            return { value: r?._id, label: r?.name };
          })
        : []
    );
  }

  useEffect(() => {
    fetchMyAPI();
  }, []);

  const duplicateSurveyCall = async () => {
    try {
      // if (role === "") {
      //   return makeToast("warning", t("missingWording.fdt1"));
      // }
      if (name === "") {
        return makeToast("warning", t("missingWording.fdt2"));
      }

      let payload = {
        //roleId: role,
        type: type,
        //dependency: dependency,
        name: name,
        description: desc,
        surveyId: surveyDetails?._id,
      };
      const duplictatedFom = await saveSurveyAsTemplate(payload);

      if (duplictatedFom && duplictatedFom?.newSurvey) {
        makeToast("success", t("missingWording.fdt3"));
        setShowSaveAsTemplateModal(false);
        setRefresh((refresh) => !refresh);
      } else {
        makeToast("error", "");
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  return (
    <>
      {type !== "" && step == 1 && (
        <>
          <div className="flex-auto py-7 px-8 min-w-full	">
            <div className="flex flex-wrap items-baseline">
              <h1 className="mb-10 text-center">
                {t("missingWording.save")} {surveyDetails?.name}{" "}
                {t("missingWording.asTemplate")}
              </h1>
            </div>
            <div className="my-3 mx-4 items-center  ">
              <Input
                id="name"
                className="w-full"
                placeholder={t("missingWording.visitName")}
                labelText={t("missingWording.visitName")}
                type="text"
                value={name}
                onBlur={(e) => setName(e.target.value)}
                onChange={(e) => setName(e.target.value)}
                inlineLabel={false}
                onfocus={true}
                style={{ maxHeight: "50px" }}
              />
            </div>
            <div className="mx-4 my-3 items-center  ">
              <Input
                id="desc"
                className="w-full"
                placeholder={t("missingWording.fdi1")}
                labelText={t("missingWording.fdi1")}
                type="text"
                style={{ maxHeight: "50px" }}
                onBlur={(e) => setDesc(e.target.value)}
                onChange={(e) => setDesc(e.target.value)}
                inlineLabel={false}
                onfocus={true}
              />
            </div>

            <div className="flex  justify-between  items-center mx-4 mt-8">
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setShowSaveAsTemplateModal(false)}
              >
                {t("Investigator.Cancel")}
              </Button>
              <Button
                disabled={name === ""}
                status="primary"
                width="100px"
                height="50px"
                className="px-2 py-2 mt-1 mb-4"
                onClick={async () => {
                  await duplicateSurveyCall();
                }}
              >
                {t("missingWording.save")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SaveSurveyAsTemplateModal;
