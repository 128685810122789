import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
export const FormGroup = ({
  children,
  errorMessage,
  helper,
  id,
  isRequired,

  label,
  showError,
  ...props
}) => {

 
  return (

    <FormControl mb="4" isInvalid={showError} isRequired={isRequired} {...props}>
  
      {!!label && (
        <FormLabel fontSize={"16px"} htmlFor={id}>
          {label}
        </FormLabel>
      )}
   
      {!!helper && (
        <Text color="gray.500" fontSize="xs" mt="0" mb="3">
          {helper}
        </Text>
      )}
      {children}
      <FormErrorMessage id={`${id}-error`}>
        <p className="text-red-400">{errorMessage}</p>
      </FormErrorMessage>
      
    </FormControl>
  );
  
}