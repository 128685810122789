export const Investigator = {
  fr: {
    Cancel: "Annuler",
    AddArc: "+ Créer",
    save: "Enregistrer",
    Delete: "Retirer",

    AddNewInves: "Êtes-vous sûr de vouloir ajouter un nouveau  investigateur ?",
    SelectInves: "Sélectionner un investigateur existant",
    LoadEch: "Échec du chargement de la liste des  investigateurs .",

    P1: "Veuillez saisir l'email",
    P2: "Veuillez saisir le role",
    P3: "Veuillez saisir le center",
    P4: "Veuillez saisir le prénom",
    P5: "Veuillez saisir le nom",
    P6: "Investigateur a été ajouté avec succès.",
    P7: "Investigateur existe déja",
    P8: "Ajouter un investigateur",
    P80: "Membre de l'équipe",
    P9: "Ecrivez votre nom ...",
    P10: "Nom",
    P11: "Merci de vérifier le nom.",
    P12: "Ecrivez votre prénom ...",
    P13: "Prénom",
    P14: "Merci de vérifier le prénom.",
    P15: "Ecrivez votre email ...",
    P16: "Merci de vérifier l'email.",
    P17: "Liste des profils",
    P18: "Merci de choisir un profil.",
    P19: "Liste des centres",
    P20: "Merci de choisir un centre.",
    P21 : "Invetigateur a été ajouté avec succès",
  },
  en: {
    P1: "Please enter the email",
    P2: "Please enter the role",
    P3: "Please enter the center",
    P4: "Please enter the first name",
    P5: "Please enter the name",
    P6: "Investigator added successfully",
    P7: "Investigator already exists",
    P8: "Add an investigator",
    P80: "Team membre",
    P9: "Write your name ...",
    P10: "Name",
    P11: "Please check the name",
    P12: "Write your first name...",
    P13: "First name",
    P14: "Please check the first name.",
    P15: "Write your email ...",
    P16: "Please check the email.",
    P17: "List of profils",
    P18: "Please choose a profile.",
    P19: "List of centers",
    P20: "Please choose a center.",
    P21: "Successful assignment of investigators to the study",

    AddNewInves: "Are you sure you want to add a new investigator?",
    SelectInves: "Select an existing investigator",
    LoadEch: "Load list of investigators to fail.",
    Delete: "Delete",
    Cancel: "Cancel",
    AddArc: "+ Create",
    save: "Save",
  },
};
