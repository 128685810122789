import axios from "axios";
import {
  CRO_REGISTER_URL,
  CRO_STUSIES_URL,
  REMOVE_ARCADMIN_TO_STUDY_URL,
  REMOVE_ARC_TO_STUDY_URL,
  REMOVE_WRITER_TO_STUDY_URL,
  SET_ARC_ADMIN_STUSIES_URL,
  SET_ARC_ADMIN_STUSIES_URL_EXISTARC,
  SET_ARC_TO_STUDY_URL,
  SET_INVS_RESPONSABLE_STUSIES_URL,
  SET_WRITER_TO_STUDY_URL,
  CHECK_UNPAID_INVOICES,
  CRO_ARCHIVED_STUSIES_URL,
  CRO_UPDATE_PROFILE_URL,
} from "../config/api";
import { get, post, update } from "../helper/apiMethods";

export const addCro = async (values) => {
  let res;
  try {
    const {
      email,
      password,
      type,
      tenantId,
      lang,
      firstName,
      lastName,
      stripeSubscriptionId,
      stripeCustomerId,
    } = values;
    let payload = {
      email,
      password,
      type,
      tenantId,
      lang,
      firstName,
      lastName,
      stripeSubscriptionId,
      stripeCustomerId,
    };
    console.log(
      "🚀 ~ file: cro.ts:41 ~ addCro ~ stripeCustomerId:",
      stripeCustomerId
    );
    console.log(
      "🚀 ~ file: cro.ts:41 ~ addCro ~ stripeSubscriptionId:",
      stripeSubscriptionId
    );
    res = await axios.post(CRO_REGISTER_URL, payload);

    return res.data;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 10 ~ addCro ~ axios.isAxiosError()");

    return Promise.reject(error);
  }
};
export const getAllStudies = async (page, limit, filter) => {
  let res;
  try {
    res = await get(
      `${CRO_STUSIES_URL}?page=${page}&limit=${limit}&filter=${filter}`
    );
    return res?.studies;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const getAllArchivedStudies = async (page, limit, filter) => {
  let res;
  try {
    res = await get(
      `${CRO_ARCHIVED_STUSIES_URL}?page=${page}&limit=${limit}&filter=${filter}`
    );
    return res?.studies;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const setArcAdmin = async (id, values) => {
  let res;
  try {
    res = await post(`${SET_ARC_ADMIN_STUSIES_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const setWriterResponsable = async (id, values) => {
  let res;
  try {
    res = await post(`${SET_INVS_RESPONSABLE_STUSIES_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const setArcAdminExist = async (id, values) => {
  let res;
  try {
    res = await post(`${SET_ARC_ADMIN_STUSIES_URL_EXISTARC}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const ignoreArcAdmin = async (id, values) => {
  let res;
  try {
    res = await post(`${REMOVE_ARCADMIN_TO_STUDY_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const setArcToStudy = async (id, values) => {
  try {
    let res;
    res = await post(`${SET_ARC_TO_STUDY_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const setExistWriterToStudy = async (id, values) => {
  try {
    let res;
    res = await post(`${SET_WRITER_TO_STUDY_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const removeArcFromStudy = async (id, values) => {
  try {
    let res;
    res = await post(`${REMOVE_ARC_TO_STUDY_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const removeWriterFromStudy = async (id, values) => {
  try {
    let res;
    res = await post(`${REMOVE_WRITER_TO_STUDY_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};
export const addAsResponsable = async (id, values) => {
  try {
    let res;
    res = await post(`${REMOVE_WRITER_TO_STUDY_URL}/${id}`, values);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: cro.ts ~ line 29 ~ getAllStudies ~ error", error);

    return Promise.reject(error);
  }
};

export const addOrModifyProfileInfo = async (values: any) => {
  try {
    const response = await update(CRO_UPDATE_PROFILE_URL, { ...values });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkUnpaidInvoices = async () => {
  try {
    const response = await get(CHECK_UNPAID_INVOICES);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
