import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import RoleImg from "../../../../assets/illustrations/role.png";
import Button from "../../../../components/ButtonUI";
import Input from "../../../../components/FloatInput";
import Modal from "../../../../components/ModalUI";
import Tooltip from "../../../../components/toolTip";
import DeleteRoleModal from "../modals/deleteRoleModal";

type Props = {
  _id: string;
  name: string;
  writers: string | any;
  onUpdateName: React.ChangeEvent<any> | any;
  studyId: string;
  setShouldUpdate: React.Dispatch<React.SetStateAction<string>>;
};
const Card: React.FC<Props | any> = ({
  _id,
  name,
  writers,
  onUpdateName,
  studyId,
  setShouldUpdate,
}: any) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ name });
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const { t } = useTranslation() as any;

  function handleExpand() {
    setIsExpanded(!isExpanded);
  }

  function handleEdit() {
    setIsEditing(true);
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormData({ name: event.target.value });
  }

  function handleNameSave() {
    setLoading(true);
    onUpdateName(formData.name);
    setIsEditing(false);
    setLoading(false);
  }

  return (
    <div
      id="cardProfil"
      className={`bg-white shadow rounded-lg p-4  relative  cardProfil  card-patient justify-start   mt-4 ${
        !isExpanded && "max-h-24"
      }`}
    >
      <div>
        {isEditing ? (
          <div className="flex flex-row ">
            <img
              src={RoleImg}
              alt="Card Icon"
              className="w-12 h-12 rounded-full mr-4 bg-yellow-200 "
            />
            <Input
              style={{ maxHeight: "45px" }}
              labelText={t("Role.RName")}
              value={formData.name}
              onChange={handleNameChange}
              className="EditProfilCardInput"
              id="EditProfilCardInput"
            />
          </div>
        ) : (
          <div className="cursor-pointer flex flex-col items-start">
            <div className="flex flex-row items-center font-semibold">
              <img
                src={RoleImg}
                alt="Card Icon"
                className="w-12 h-12 rounded-full mr-4 bg-yellow-200 "
              />
              <div className="flex flex-col">
                <h3
                  id="cardProfilName"
                  className="font-bold mb-0.5 cursor-text cardProfilName"
                  onClick={handleEdit}
                >
                  {name}
                </h3>
                <div
                  className={`text-gray-800 text-sm mt-1 font-thin cursor-pointer ${
                    !isExpanded && "  "
                  } `}
                  onClick={() =>
                    writers?.length > 0
                      ? setIsExpanded((t) => !t)
                      : setIsExpanded(false)
                  }
                >
                  {writers?.length > 0
                    ? `${writers?.length} ${t("Role.Ines")}`
                    : `${t("Role.AInes")}`}
                </div>
              </div>
            </div>

            {isExpanded && (
              <ul className="py-2 flex flex-row flex-wrap text-xs justify-start gap-0.5 mt-2 ">
                {writers?.map((person) => (
                  <Tooltip tooltipText={person.email} position={"top"}>
                    {" "}
                    <li
                      key={person.id}
                      className="text-gray-700 box truncate w-36"
                    >
                      {person.email}
                    </li>
                  </Tooltip>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
      <div
        className={"absolute right-4 " + `${isEditing && "bottom-2 flex-row"}`}
      >
        {isEditing ? (
          <>
            <Button
              status="secondary"
              className=" px-2 py-1"
              onClick={() => setIsEditing(false)}
            >
              {t("Role.Cancel")}
            </Button>
            <Button
              status="primary"
              className=" px-2 py-1 mx-2"
              onClick={handleNameSave}
              disabled={loading}
              id="editRolebtn"
            >
              {t("Role.Save")}
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="absolute right-2 top-1">
        <Button
          status="icon-secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setDeleteConfirmModal(true);
          }}
          style={{ height: "46px", width: "48px", padding: 0 }}
        >
          <Close />
        </Button>
        <svg
          className={`w-6 h-6 ml-4 text-gray-600 ${
            (!(writers?.length > 0) || isEditing) && "hidden"
          }`}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={handleExpand}
        >
          {isExpanded ? (
            <path d="M19 9l-7 7-7-7"></path>
          ) : (
            <path d="M9 5l7 7-7 7"></path>
          )}
        </svg>
      </div>

      <Modal showModal={deleteConfirmModal} className="px-10 py-10">
        <DeleteRoleModal
          deleteRoleModal={deleteConfirmModal}
          setDeleteRoleModal={setDeleteConfirmModal}
          setShouldUpdate={setShouldUpdate}
          roleId={_id}
          studyId={studyId}
          hasWriter={writers?.length > 0}
          roleName={name}
        />
      </Modal>
    </div>
  );
};

export default Card;
