import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import classNames from "classnames";

import "./styles.scss";
import Tooltip from "../toolTip";

type BtnStatutes = "primary" | "secondary" | "icon-primary" | "icon-secondary";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  status?: BtnStatutes;
  size?: string;
  width?: string;
  height?: string;
  toolTipText?: string;
  withToolTip?: boolean;
};

const Button: React.FC<Props> = ({
  width,
  height,
  size,
  status,
  className,
  children,
  withToolTip = false,
  toolTipText,
  ...props
}) => {
  let btnStatusStr;
  switch (status) {
    case "primary":
      btnStatusStr = "btn-primary";
      break;
    case "secondary":
      btnStatusStr = "btn-secondary";
      break;
    case "icon-primary":
      btnStatusStr = "icon-primary";
      break;
    case "icon-secondary":
      btnStatusStr = "icon-secondary";
      break;
    default:
      btnStatusStr = "primary";
      break;
  }

  return !withToolTip || !toolTipText ? (
    <button
      className={classNames(
        btnStatusStr,
        size && size,
        "flex-none ",
        className
      )}
      style={{ width, height }}
      {...props}
    >
      {children}
    </button>
  ) : (
    <Tooltip tooltipText={`${toolTipText}`} position={"top"}>
      <button
        className={classNames(
          btnStatusStr,
          size && size,
          "flex-none ",
          className
        )}
        style={{ width, height }}
        {...props}
      >
        {children}
      </button>
    </Tooltip>
  );
};

Button.defaultProps = {
  status: "primary",
};

export default Button;
