import { ACCEPT_REGISTER_URL, SPONSOR_REGISTER_URL } from "../config/api";
import { post } from "../helper/apiMethods";

export const addSponsor  = async (values) => {
    let res;
    try {
     
      let payload = { ...values };
      res = await post(SPONSOR_REGISTER_URL, payload);
  
  
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  export const acceptInvitation  = async (values) => {
    let res;
    try {
     
      let payload = { ...values };
      res = await post(ACCEPT_REGISTER_URL, payload);
  
  
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };