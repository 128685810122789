export const Centre = {
    fr: {
        abreviation: "Abreviation",
        adresse: "Adresse du centre",
        name: `Nom du centre `,

        successAddCentre:"Centre crée avec succès",
        errorP:"Un erreur c'est produit.",
        ManageCenter:"Gestion des centres",
        AddCenter:"+ Nouveau centre",
        NoCenterStudy:"Aucun centre pour cette étude.",

        centreSuccesEdit:"La mise à jour du centre a été effectuée avec succès.",
        nameCenter:"Nom du centre",
        address:"Adresse",
        abrev:"Abréviation",
        save:"Enregister",
        cancel:"Annuler",
        ExCenter:"Tous les investigateurs appartenant à ce centre seront donc exclus de cette étude.",
        deleteCenter:"Voulez-vous supprimer ce centre ?",
        delete:"Supprimer",
        deleteMess:"Le centre a été supprimé avec succès",
    },
    en: {
        abbreviation: "Abreviation",
        adresse: "Address of the center",
        name: `Name of the center `,
        ExCenter: "All investigators  associated with this center will therefore be excluded from this study.",
        successAddCentre: "Centre created successfully",
        errorP: "An error occurred.",
        ManageCenter: "Center management",
        AddCenter: "+ New center",
        NoCenterStudy: "No center for this study",

        centreSuccesEdit: "Centre successfully updated",
        nameCenter: "Name of the center",
        address: "Address",
        abrev: "Abbreviation",
        save: "Save",
        cancel: "Cancel",

        deleteCenter: "Do you want to delete this center?",
        delete: "Delete",
        deleteMess: "The center has been deleted successfully",
    },
  };
  