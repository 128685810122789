import { useAtom } from "jotai";
import { useState, useRef, useEffect, useCallback } from "react";
import io, { Socket } from "socket.io-client";
import jwt from "jsonwebtoken";
import { socketAtom } from "../state/socket";
import makeToast from "../components/Snackbar";
import history from "../history";

type WebRTCUser = {
  id: string;
  user: { [key: string]: unknown };
  stream: MediaStream;
};

const PEER_CONNECTION_CONFIG: RTCConfiguration = {
  iceServers: [
    {
      urls: "turn:turnserv.katomi.co:3478",
      credential: "Katomi2021*",
      username: "turnserver",
    },
    {
      urls: "stun:turnserv.katomi.co:3478",
      credential: "Katomi2021*",
      username: "turnserver",
    },
    {
      urls: "stun:stun.l.google.com:19302",
    },
    {
      urls: "stun:stun1.l.google.com:19302",
    },
    {
      urls: "stun:stun2.l.google.com:19302",
    },
    {
      urls: "stun:stun3.l.google.com:19302",
    },
    {
      urls: "stun:stun4.l.google.com:19302",
    },
  ],
};

interface Props {
  wsUrl: string;
  localUserProfile: { [key: string]: unknown };
  token: string;
  studyId: string;
  eConsultId: string;
  participantId: string;
  tenantId: string;
}

function useWebRTC({
  wsUrl,
  localUserProfile,
  token,
  studyId,
  eConsultId,
  participantId,
  tenantId,
}: Props) {
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const socketRef = useRef<Socket>();
  const peerConnectionsRef = useRef<{ [socketId: string]: RTCPeerConnection }>(
    {}
  );
  const localVideoRef = useRef<any>(null);
  const localStreamRef = useRef<MediaStream>();
  const [users, setUsers] = useState<WebRTCUser[]>([]);
  console.log("🚀 ~ users:", users);
  const [userUpdate, setUserUpdate] = useState([]) as any;
  console.log("🚀 ~ userUpdate:", userUpdate);
  const [audioFlag, setAudioFlag] = useState(true);
  const [videoFlag, setVideoFlag] = useState(true);
  const [audioFlagTemp, setAudioFlagTemp] = useState(true);

  const getLocalStream = useCallback(async () => {
    try {
      const localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      localStreamRef.current = localStream;
      if (localVideoRef.current) localVideoRef.current.srcObject = localStream;
      if (!socketRef.current) return;

      // TODO: change payload in the following call to be dynamic from DB
      socketRef.current.emit("room.join", {
        room: eConsultId,
        user: localUserProfile,
      });
    } catch (error) {
      console.log("[DEBUG] getUserMedia error: ", error);
    }
  }, []);

  const createPeerConnection = useCallback(
    (socketID: string, user: { [key: string]: unknown }) => {
      try {
        console.log("[DEBUG] creating peer connection for : ", {
          socketID,
          user,
        });
        const peerConnection = new RTCPeerConnection(PEER_CONNECTION_CONFIG);

        peerConnection.onicecandidate = (event) => {
          if (!(socketRef.current && event.candidate)) return;

          console.log(
            "[DEBUG] peerConnection.onicecandidate triggered : ",
            event.candidate
          );

          socketRef.current.emit("candidate", {
            candidate: event.candidate,
            candidateSenderID: socketRef.current.id,
            candidateReceiverID: socketID,
          });
        };

        peerConnection.oniceconnectionstatechange = () => {
          console.log(
            "[DEBUG] peerConnection.oniceconnectionstatechange triggered : ",
            peerConnection.iceConnectionState
          );
        };

        peerConnection.onsignalingstatechange = () => {
          console.log(
            "[DEBUG] peerConnection.onsignalingstatechange triggered : ",
            peerConnection.signalingState
          );
        };

        peerConnection.ontrack = (event) => {
          console.log("[DEBUG] peerConnection.ontrack triggered");
          setUsers((previousState) =>
            previousState
              .filter((user) => user.id !== socketID)
              .concat({
                id: socketID,
                user,
                stream: event.streams[0],
              })
          );
        };

        if (localStreamRef.current) {
          console.log("[DEBUG] adding localstream.");
          localStreamRef.current.getTracks().forEach((track) => {
            if (!localStreamRef.current) return;
            peerConnection.addTrack(track, localStreamRef.current);
          });
        } else {
          console.log("[DEBUG] no local stream in reference.");
        }

        return peerConnection;
      } catch (error) {
        console.error(error);
      }
    },
    []
  );
  const decodeToken = (token: string) => {
    try {
      const decodedToken = jwt.decode(token);
      console.log(
        "🚀 ~ decodeToken ~ decodedToken.expiresIn:",
        decodedToken.expiresIn
      );

      if (decodedToken) {
        console.log(decodedToken.expiresIn);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        console.log("🚀 ~ decodeToken ~ currentTimestamp:", currentTimestamp);

        // Check if the token has not expired
        if (
          decodedToken.expiresIn &&
          decodedToken.expiresIn > currentTimestamp
        ) {
          console.log("Token is still valid.");
          // You can proceed with using the token.
        } else {
          console.log("Token has expired.");
          makeToast("error", "Token has expired.");
          history.push("/");
        }
        // Add more claims as needed
      }
    } catch (error) {
      return null;
    }
  };

  function handleControlVideo() {
    if (localVideoRef?.current?.srcObject) {
      localVideoRef.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "video") {
          if (track.enabled) {
            socketRef?.current?.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag: false,
                audioFlag,
              },
            ]);
            track.enabled = false;
            setVideoFlag(false);
          } else {
            socketRef?.current?.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag: true,
                audioFlag,
              },
            ]);
            track.enabled = true;
            setVideoFlag(true);
          }
        }
      });
    }
  }

  function handleControlAudio() {
    if (localVideoRef?.current?.srcObject) {
      localVideoRef.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "audio") {
          if (track.enabled) {
            socketRef?.current?.emit("change", [
              ...userUpdate,
              {
                id: socketRef.current.id,
                videoFlag,
                audioFlag: false,
              },
            ]);
            track.enabled = false;
            setAudioFlag(false);
          } else {
            socketRef?.current?.emit("change", [
              ...userUpdate,
              {
                id: socketRef?.current?.id,
                videoFlag,
                audioFlag: true,
              },
            ]);
            track.enabled = true;
            setAudioFlag(true);
          }
        }
      });
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) decodeToken(token);
    const userToken = localStorage.getItem("token") || token;
    if (!socket) {
      const mySocket: Socket = io(wsUrl, {
        // forceNew: true,
        reconnectionAttempts: 100,
        query: {
          publicConnexion: true, //!localStorage.getItem("token"),
          studyId,
          eConsultId,
          participantId,
          tenantId,
        },
        timeout: 10000,
        transports: ["websocket"],
        auth: {
          token: userToken,
        },
      });

      setSocket(mySocket);
    } else {
      if (!socket.connected) socket.connect();
      socketRef.current = socket;
      getLocalStream();

      socketRef.current!.on(
        "room.users",
        (usersInRoom: { id: string; user: { [key: string]: unknown } }[]) => {
          usersInRoom.forEach(async (item) => {
            if (!localStreamRef.current) return;

            const pc = createPeerConnection(item.id, item.user);
            if (!(pc && socketRef.current)) return;
            peerConnectionsRef.current = {
              ...peerConnectionsRef.current,
              [item.id]: pc,
            };

            try {
              const localSdp = await pc.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
              });
              await pc.setLocalDescription(new RTCSessionDescription(localSdp));
              console.log("[DEBUG] created offer successfully : ", localSdp);

              socketRef.current.emit("offer", {
                sdp: localSdp,
                offerSenderID: socketRef.current.id,
                offerSenderProfile: localUserProfile,

                offerReceiverID: item.id,
              });
            } catch (error) {
              console.error(error);
            }
          });
        }
      );

      socketRef.current!.on(
        "offer.get",
        async (data: {
          sdp: RTCSessionDescription;
          offerSenderID: string;
          offerSenderProfile: { [key: string]: unknown };
        }) => {
          console.log("[DEBUG] offer received : ", data);

          const { sdp, offerSenderID, offerSenderProfile } = data;
          if (!localStreamRef.current) return;
          const pc = createPeerConnection(offerSenderID, offerSenderProfile);
          if (!(pc && socketRef.current)) return;
          peerConnectionsRef.current = {
            ...peerConnectionsRef.current,
            [offerSenderID]: pc,
          };
          try {
            await pc.setRemoteDescription(new RTCSessionDescription(sdp));
            const localSdp = await pc.createAnswer({
              offerToReceiveVideo: true,
              offerToReceiveAudio: true,
            });
            await pc.setLocalDescription(new RTCSessionDescription(localSdp));

            console.log("[DEBUG] created answer successfully");

            socketRef.current.emit("answer", {
              sdp: localSdp,
              answerSenderID: socketRef.current.id,
              answerReceiverID: offerSenderID,
            });
          } catch (error) {
            console.error(error);
          }
        }
      );

      socketRef.current!.on(
        "answer.get",
        (data: { sdp: RTCSessionDescription; answerSenderID: string }) => {
          console.log("[DEBUG] answer received : ", data);

          const { sdp, answerSenderID } = data;
          const pc: RTCPeerConnection =
            peerConnectionsRef.current[answerSenderID];
          if (!pc) return;
          pc.setRemoteDescription(new RTCSessionDescription(sdp));
        }
      );

      socketRef.current!.on(
        "candidate.get",
        async (data: {
          candidate: RTCIceCandidateInit;
          candidateSenderID: string;
        }) => {
          try {
            console.log("[DEBUG] candidate received : ", data);
            const pc: RTCPeerConnection =
              peerConnectionsRef.current[data.candidateSenderID];
            if (!pc) return;
            await pc.addIceCandidate(new RTCIceCandidate(data.candidate));

            console.log("[DEBUG] added ice candidate successfully");
          } catch (error) {
            console.log(error);
          }
        }
      );

      socketRef.current!.on("room.exit", (data: { id: string }) => {
        if (!peerConnectionsRef.current[data.id]) return;
        peerConnectionsRef.current[data.id].close();
        delete peerConnectionsRef.current[data.id];
        setUsers((previousState) =>
          previousState.filter((user) => user.id !== data.id)
        );
      });

      socketRef.current!.on("change", (payload) => {
        setUserUpdate(payload);
      });

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
        users.forEach((user) => {
          if (!peerConnectionsRef.current[user.id]) return;
          peerConnectionsRef.current[user.id].close();
          delete peerConnectionsRef.current[user.id];
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, createPeerConnection, getLocalStream]);

  useEffect(() => {
    console.log("[DEBUG] users mutated : ", users);
  }, [users]);

  return [
    localVideoRef,
    users,
    handleControlVideo,
    handleControlAudio,
    audioFlag,
    videoFlag,
  ] as const;
}

export default useWebRTC;
