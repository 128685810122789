import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Socket, io } from "socket.io-client";
import {
  getAllPatientByStudyIdPaginate,
  getStudyConfig,
} from "../../api/study";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import Pagination from "../../components/PaginationTable";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import { studyWriterConfigAtom } from "../../state/studyWriterConfig";
import AddParticipant from "./AddParticipant";
import Table from "./participantTable";
import "./styles.scss";
import Progress from "../../components/NProgress";
import { testModeAtom } from "../../state/testModeAtom";
import TestModeTogButton from "../../components/TestModeShared";
import { socketAtom } from "../../state/socket";
import { BASE_URL } from "../../config/api";
import makeToast from "../../components/Snackbar";
import MyModal from "../../components/New-Modal";
import { FaFileUpload } from "react-icons/fa";
import EproInvitationModal from "./EproInvitationModal";
import ProgressBar from "../../components/ProgressBar";
import { missingWording } from "../../config/constants/i18n/missingWording";
import { centerAcessAtom } from "../../state/centerAcessAtom";
import AccessCenterButton from "../../components/AccessCenterButton";
// import useDrivejs from "../../hooks/driverjs";
const MyParticipant = () => {
  const [user] = useAtom(userAtom);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showMassInvitationModal, setShowMassInvitationModal] = useState(false);
  const [bluckCreationLoader, setBluckCreationLoader] = useState(false);
  const [bluckCreationProgress, setBluckCreationProgress] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [listParticipants, setListParticipants] = useState([]) as any[];
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const params = useParams() as any;
  const { studyId } = params;
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [textSearch, setTextSearch] = useState("");
  const { t } = useTranslation() as any;
  const [active, setActive] = useState(
    studyWriterConfig?.isResponsable ? true : false
  );
  const [centerAcess, setCenterAcess] = useAtom(centerAcessAtom);
  const [filter, setFilter] = useState(
     centerAcess  ? "byCenter" : "byMe"
  );
  const [nbrOfSkeletonsToDisplay, setNbrOfSkeletonsToDisplay] = useState(0);
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  // const {} = useDrivejs({ stepNumber: 1 });
  async function fetchStudyParticipants(): Promise<any> {
    try {
      let type = user?.type;
      setLoading(true);
      if (studyId && studyId !== null) setStudySelectedId(studyId);
      if (type === "writer") {
        let res = await getStudyConfig(
          studyId,
          page,
          10,
          filter,
          textSearch,
          testMode
        );
        if (res) {
          setNbrOfSkeletonsToDisplay(0);
          setListParticipants(res?.participants?.participants);
          setStudyWriterConfig(res?.studyConfig);
          setTotalResult(res?.participants?.count);
          setLoading(false);
        } else {
          setStudyWriterConfig(null);
          setLoading(false);
        }
      } else {
        let res = await getAllPatientByStudyIdPaginate(
          studyId,
          page,
          10,
          textSearch,
          testMode
        );

        setStudyWriterConfig(null);
        if (res) {
          setNbrOfSkeletonsToDisplay(0);
          setListParticipants(res.participants);
          setTotalResult(res.count);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:30 ~ fetchStudyConfig ~ error", error);
    }
  }


  const activeChange = (checked) => {
    setActive(checked);
    if (checked) {
      setFilter("byCenter");
      setCenterAcess(true)
    } else {
      setFilter("byMe");
      setCenterAcess(false)
    }
  };
  useEffect(() => {
    if (centerAcess) {
      setFilter("byCenter");
    
    } else {
      setFilter("byMe");
      
    }

  }, [centerAcess, setCenterAcess, ]);

  useEffect(() => {
    setPath(`/participant/${studyId}`);
    if ((textSearch && textSearch.length > 0) || textSearch === "")
      fetchStudyParticipants();
    else fetchStudyParticipants();
  }, [shouldUpdate, page, filter, testMode, textSearch]);

  useEffect(() => {
    if (!socket) {
      const mySocket: Socket = io(BASE_URL, {
        forceNew: true,
        reconnectionAttempts: 100,
        timeout: 10000,
        transports: ["websocket"],
        auth: {
          token: localStorage.getItem("token"),
          tenantFormReact: localStorage.getItem("tenantId"),
        },
      });

      setSocket(mySocket);
    } else {
      if (!socket.connected) socket.connect();
      socket.on("participants.add.success", (payload) => {
        setShouldUpdate((up) => !up);
        console.log("[DEBUG] participant added successfully - ", { payload });
        makeToast("success", `${t("Participant.successAdd")}`);
      });
      socket.on("participants.add.surveyError", (payload) => {
        setNbrOfSkeletonsToDisplay(nbrOfSkeletonsToDisplay - 1);
        console.log("[DEBUG] failed to add participant - ", { payload });
        makeToast("warning", `${t("Participant.surveyError")}`);
      });
      socket.on("participants.add.error", (payload) => {
        setNbrOfSkeletonsToDisplay(nbrOfSkeletonsToDisplay - 1);
        console.log("[DEBUG] failed to add participant - ", { payload });
        makeToast("warning", `${t("Participant.errorAdd")}`);
      });
      socket.on("participants.EproAdd.success", (payload) => {
        setShouldUpdate((up) => !up);
        console.log("[DEBUG] participant added successfully - ", { payload });
        setBluckCreationLoader(false);
        makeToast("success", `${t("Participant.successAdd")}`);
      });
      socket.on("participants.EproAdd.bulk", (payload) => {
        console.log("[DEBUG] participant EproAdd.bulk - ", { payload });
        setBluckCreationProgress(
          (payload.currentIndex / payload.totalPatients) * 100
        );
        setBluckCreationLoader(true);
      });
      socket.on("participants.EproAdd.error", (payload) => {
        setNbrOfSkeletonsToDisplay(nbrOfSkeletonsToDisplay - 1);
        console.log("[DEBUG] failed to add participant - ", { payload });
        makeToast("warning", `${t("Participant.errorAdd")}`);
      });
      console.log("[DEBUG] Socket is open!");
    }

    return () => {
      if (socket) {
        socket.removeAllListeners("participants.add.success");
        socket.removeAllListeners("participants.add.error");
        socket.removeAllListeners("participants.EproAdd.success");
        socket.removeAllListeners("participants.EproAdd.error");
      }
    };
  }, [socket, setSocket, t, nbrOfSkeletonsToDisplay]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        socket.removeAllListeners("participants.add.success");
        socket.removeAllListeners("participants.add.error");
        socket.removeAllListeners("participants.EproAdd.success");
        socket.removeAllListeners("participants.EproAdd.error");
        socket.close();
        console.log("[DEBUG] Socket was closed!");
      }
    };
  }, [socket]);

  if (studyWriterConfig && studyWriterConfig.studyId)
    console.log(
      "🚀 ~ file: index.tsx:187 ~ MyParticipant ~   studyWriterConfig.studyId:",
      studyWriterConfig.studyId
    );
  return (
    <>
      <Progress isAnimating={loading} key={0} />
      <header className="flex justify-between items-center w-full flex-wrap">
        <div className="title-container">
          <h1 className="pageTitle">Participants</h1>
        </div>
        {user?.type === "writer" &&
          studyWriterConfig &&
          studyWriterConfig.studyId &&
          studyWriterConfig.studyId.status !== "END" && (
            <div className="flex max-w-max items-center space-x-4 ">
              <div className="relative xs:mr-2 md:mr-0">
                <Button
                  width="150px"
                  height="35px"
                  className="createParticipantBtn"
                  onClick={() => setShowAddModal(true)}
                >
                  + Participant
                </Button>
              </div>
              {studyWriterConfig &&
                studyWriterConfig.studyId &&
                studyWriterConfig.studyId.ePro && (
                  <div className="relative xs:mr-2 md:mr-0">
                    <Button
                      width="180px"
                      height="35px"
                      status="secondary"
                      className="px-2 flex items-center justify-center gap-x-2"
                      onClick={() => setShowMassInvitationModal(true)}
                    >
                      <FaFileUpload /> {t("Participant.BulkCreation")}
                    </Button>
                  </div>
                )}
            </div>
          )}
      </header>

      <Modal
        closeModal={() => setShowAddModal(false)}
        showModal={showAddModal}
        className="md:p-10 xs:p-6 md:m-16 xs:m-2 md:max-w-full xs:max-w-xs"
      >
        <AddParticipant
          setOpenModal={setShowAddModal}
          action={(id: string) => {
            setShouldUpdate((up) => !up);
          }}
          participantsCount={listParticipants.length}
          handleLoadingInclusion={() => {
            setNbrOfSkeletonsToDisplay(nbrOfSkeletonsToDisplay + 1);
          }}
        />
      </Modal>
      <MyModal
        closeModal={() => setShowMassInvitationModal(false)}
        showModal={showMassInvitationModal}
        className="md:p-10 xs:p-6 md:m-16 xs:m-2 md:max-w-full xs:max-w-xs"
      >
        <EproInvitationModal
          setOpenModal={setShowMassInvitationModal}
          action={(id: string) => {
            setShouldUpdate((up) => !up);
          }}
          participantsCount={listParticipants.length}
          handleLoadingInclusion={() => {
            setNbrOfSkeletonsToDisplay(nbrOfSkeletonsToDisplay + 1);
          }}
        />
      </MyModal>
      <MyModal
        closeModal={() => setBluckCreationLoader(false)}
        showModal={bluckCreationLoader}
        className=""
        withOutClose
      >
        <div className="container px-5 py-10">
          <h3 className="text-center mb-8">
            {t("missingWording.loadingParticipants")}
          </h3>
          <ProgressBar progress={bluckCreationProgress} duration={2000} />
          <h3 className="text-sm text-center my-4">
            {t("missingWording.loadingMsg")}
          </h3>
        </div>
      </MyModal>
      <TestModeTogButton />
      
      {studyWriterConfig?.studyId.status === "END" && (
        <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2  ">
          <span className="warn warning ">
            ⚠️{t("missingWording.bannerEnd")}.
          </span>
        </div>
      )}
      <div>
        {user?.type === "writer" &&
          (studyWriterConfig?.studyId.centerAcess ||
            studyWriterConfig?.isResponsable) && (
            // <div className="absolute  mb-4  right-4 flex flex-row items-center text-sm font-semibold mt-7 ">
            <AccessCenterButton />
            // </div> 
          )}
        <Table
          nbrOfSkeletonsToDisplay={nbrOfSkeletonsToDisplay}
          data={listParticipants}
          userType={user?.type}
          hasCenterAcess={studyWriterConfig?.studyId.centerAcess ? true : false}
          loading={false}
          setTextSearch={setTextSearch}
          showName={studyWriterConfig?.studyId.anonym ? false : true}
          isResponsable={studyWriterConfig?.isResponsable}
          showGroup={studyWriterConfig?.studyId.randomisation}
        />
        {totalResult > 10 && (
          <Pagination
            totalResults={totalResult}
            resultsPerPage={10}
            currentPage={page}
            onPageChange={function (page: number): void {
              setPage(page);
              localStorage.setItem("paginate", page.toString());
            }}
            entriesName={"Participants"}
          />
        )}
        {process.env.NODE_ENV === "development" && (
          <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 fixed bottom-0 left-0 z-50  ">
            <Button
              status="primary"
              // onClick={() => socket.emit("resetChallenge2Writer", {})}
              className="text-xs px-2 py-1"
            >
              Start Tour
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default MyParticipant;
