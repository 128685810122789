import { Tag, Image } from "@chakra-ui/react";
import moment from "moment";
import { FcDocument } from "react-icons/fc";
const ViewElement = ({ element, value }) => {
  const { type, ...data } = element;

  const renderElement = () => {
    switch (type) {
      case "TextInput":
      case "NumberInput":
      case "PhoneNumber":
      case "TimePicker":
      case "Dropdown":
      case "RadioButtons":
        return <div>{value ? value : "N/A"}</div>;

      // case "File":
      //   return (
      //     <>
      //       {value?.map((item, index) => (
      //         <div key={index}>
      //           <a href={item.url} target="_blank" rel="noreferrer">
      //             {item.name}
      //           </a>
      //         </div>
      //       ))}
      //     </>
      //   );
      case "File":
        let url = value ? URL.createObjectURL(value) : "null"
        console.log("🚀 ~ file: ViewElement.tsx:30 ~ renderElement ~ url:", data)
        // return <span>{value && value.name ? value.name : "N/A"}
       return data.fileType ==='image'? <img alt ="File" src={url}/>:<div  className=" flex flex-col gap-2-2  mt-8">
         
       <pre className="truncate">{value.name}</pre>
       <FcDocument size={86}/> </div>
      case "Rating":
        return (
          <div className="">{value ? value / parseInt(data.count) : "N/A"}</div>
        );
      case "Signature":
        return value ? <Image width={200} src={value} /> : <p>N/A</p>;
      case "Range":
        const marks = { [data.min]: data.minLabel, [data.max]: data.maxLabel };
        const { minLabel, maxLabel, ...rangeProps } = data;
        return value ? (
          <span>
            {value} / {data.max}
          </span>
        ) : (
          <span>N/A</span>
        );
      case "Checkboxes":
        return <div>{value ? value : "N/A"}</div>;
      case "Tags":
        return value ? (
          <div>
            {value?.map((v, index) => (
              <Tag key={index}>{v}</Tag>
            ))}
          </div>
        ) : (
          <div>N/A</div>
        );
      case "DatePicker":
        return <div>{value ? moment(value).format(data.format) : "N/A"}</div>;

      case "TextArea":
        return value ? <pre className="whitespace-normal">{value}</pre> : <pre>N/A</pre>;
      case "Email":
        return value ? <pre>{value}</pre> : <pre>N/A</pre>;

      case "ident":
        return (
          <span>
            {value && (
              <>
                {" "}
                <p>
                  Nom Prénom :{" "}
                  {value.nom ? value.nom + " " + value.prenom : "N/A"}
                </p>
                <p>Email : {value.email ? value.email : "N/A"}</p>
                <p>
                  N° Tel : {value["téléphone"] ? value["téléphone"] : "N/A"}
                </p>
                <p>
                  N° Sociale :{" "}
                  {value["téléphone"] ? value["N° de sécurité sociale"] : "N/A"}
                </p>
              </>
            )}
          </span>
        );
      case "fullAdress":
        return (
          <span>
            {value && (
              <>
                {" "}
                <p>Adresse Postale : {value.adresse ? value.adresse : "N/A"}</p>
                <p>Code Postal : {value.zipCode ? value.zipCode : "N/A"}</p>
                <p>Ville : {value.ville ? value.ville : "N/A"}</p>
                <p>Pays : {value.pays ? value.pays : "N/A"}</p>
                <p>
                  Département : {value.departement ? value.departement : "N/A"}
                </p>
              </>
            )}
          </span>
        );
      case "TableField":
        return (
          <span>
            {value ? (
              <>
                {Object.entries(value).map((item) => {
                  return (
                    <div className="grid grid-cols-2  gap-2 text-sm  px-2 py-1 border border-0.5 ">
                      <p className="text-left"> {item[0]}</p>
                      <p className="border-l-2 text-center">
                        {" "}
                        {item[1] && item[1] !== "" ? item[1] : "N/A"}
                      </p>
                    </div>
                  );
                })}
              </>
            ) : (
              <p>N/A</p>
            )}
          </span>
        );
      case "RepeaterField":
        const valueToString = JSON.stringify(value);
        console.log(
          "🚀 ~ file: ViewElement.tsx:128 ~ renderElement ~ valueToString:",
          valueToString
        );
        if (!valueToString || valueToString === "") {
          return <p>N/A</p>;
        } else {
          const objectValue = JSON.parse(JSON.parse(valueToString));
          if (objectValue && Array.isArray(objectValue)) {
            return (
              <>
                {objectValue.map((obj) => {
                  return (
                    <>
                      {Object.entries(obj).map((item) => {
                        console.log(
                          "🚀 ~ file: ViewElement.tsx:133 ~ {Object.entries ~ item:",
                          item
                        );
                        return (
                          <div className="grid grid-cols-2  gap-2 text-sm  px-2 py-1 border border-0.5 ">
                            <p className="text-left"> {item[0]}</p>
                            <p className="border-l-2 text-center">
                              {" "}
                              {item[1] && item[1] !== "" ? item[1] : "N/A"}
                            </p>
                          </div>
                        );
                      })}
                      <p className="border border-black"></p>
                    </>
                  );
                })}
              </>
            );
          } else {
            return value ? <p>{value}</p> : <pre>N/A</pre>;
          }
        }

        break;

      default:
        return <></>;
    }
  };
  return <div className="w-1/2">{renderElement()}</div>;
};

export default ViewElement;
