import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUpdateCentre } from "../../../../api/study";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import HospitalImg from "../../../../assets/illustrations/hospital.png";
import Button from "../../../../components/ButtonUI";
import Input from "../../../../components/FloatInput";
import makeToast from "../../../../components/Snackbar";
import { userAtom } from "../../../../state/auth";
import { User } from "../../../../types/user";
import { tutoFirstCreateCenterAtom } from "../../../../state/tutoFirstCreateCenter";

const EditableCard: React.FC<any> = (props) => {
  const {
    centerDetails,
    newCenterId,
    setListCenters,
    setDeleteCentreModalState,
    setSelectedCenter,
    studyId,
    isAdding,
    centerTuto,
    setSelectedCenterName
  } = props;
  const [formData, setFormData] = useState({
    name: centerDetails?.name,
    adresse: centerDetails?.adresse,
    abreviation: centerDetails?.abreviation,
  });
  console.log("🚀 ~ file: index.tsx:32 ~ centerDetails:", centerDetails);

  const [firstCreate, setFirstCreate] = useAtom(tutoFirstCreateCenterAtom);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user] = useAtom<User | any>(userAtom) as any;
  const { t } = useTranslation() as any;

  useEffect(() => {
    if (!centerDetails || centerDetails === null) {
      setIsEditMode(true);
      setFormData({ name: "", adresse: "", abreviation: "" });
    } else {
      setFormData({
        name: centerDetails?.name,
        adresse: centerDetails?.adresse,
        abreviation: centerDetails?.abreviation,
      });

      if (newCenterId === centerDetails?._id) {
        setIsEditMode(true);
      } else setIsEditMode(false);
    }
  }, [centerDetails?._id, newCenterId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = async () => {
    setLoading(true);
    const res = await createUpdateCentre({
      ...formData,
      centerId: centerDetails?._id,
      createdBy: user._id,
      docModel: "cro",
      studyId: studyId,
    });
    setIsEditMode(false);
    if (res.message === "success") {
      makeToast(
        "success",
        isAdding
          ? `${t("Centre.successAddCentre")}`
          : `${t("Centre.centreSuccesEdit")}`
      );
      setListCenters(res.data.centers);
      setFormData({ name: "", adresse: "", abreviation: "" });
      setSelectedCenter();
      setLoading(false);
    } else {
      makeToast("danger", `${t("Centre.errorP")}`);
    }
  };
  const handleBlurTuto = async (formData) => {
    setLoading(true);
    const res = await createUpdateCentre({
      ...formData,
      centerId: centerDetails?._id,
      createdBy: user._id,
      docModel: "cro",
      studyId: studyId,
    });
    setIsEditMode(false);
    if (res.message === "success") {
      makeToast(
        "success",
        isAdding
          ? `${t("Centre.successAddCentre")}`
          : `${t("Centre.centreSuccesEdit")}`
      );
      setListCenters(res.data.centers);
      setFormData({ name: "", adresse: "", abreviation: "" });
      setSelectedCenter();
      setLoading(false);
    } else {
      makeToast("danger", `${t("Centre.errorP")}`);
    }
  };
  const handleExit = () => {
    setIsEditMode(false);
    if (!centerDetails || centerDetails === null) {
      setFormData({ name: "", adresse: "", abreviation: "" });
    }
  };
  useEffect(() => {
    if (centerTuto && firstCreate) {
      // setFormData({
      //   adresse: "Lorem ipeim ",
      //   name: "Medical center 1",
      //   abreviation: "MC001_",
      // });
      console.log(formData);
      debugger;
      handleBlurTuto(formData);

      setFirstCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerTuto]);

  return isAdding ? (
    <div
      className={`bg-white shadow-md rounded-lg p-4 max-h-screen relative mt-4 flex flex-col `}
    >
      <div
        className="rounded-full h-20 w-20 self-center   "
        style={{
          backgroundColor: "#ffecba",
          cursor: "pointer",
        }}
      >
        <img src={HospitalImg} alt="icon" />
      </div>
      <div className="py-8 ">
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          labelText={t("Centre.nameCenter")}
          className="text-sm centerPanelName"
          style={{ maxHeight: "40px" }}
          id="centerPanelName"
        />

        <Input
          className="text-sm centerPanelAdress"
          style={{ maxHeight: "40px" }}
          type="text"
          name="adresse"
          value={formData.adresse}
          onChange={handleChange}
          labelText={t("Centre.address")}
          id="centerPanelAdress"
        />
        <Input
          className="text-sm centerPanelAb"
          style={{ maxHeight: "40px" }}
          type="text"
          name="abreviation"
          value={formData.abreviation}
          onChange={handleChange}
          labelText={t("Centre.abrev")}
          id="centerPanelAb"
        />

        <Button
          onClick={handleBlur}
          id="centerPanelSaveBtn"
          status="primary"
          className="absolute right-4 bottom-3 px-2 py-1"
          disabled={
            !formData.name ||
            formData.name === "" ||
            !formData.adresse ||
            formData.adresse === "" ||
            !formData.abreviation ||
            formData.abreviation === "" ||
            loading
          }
        >
          + {`${t("missingWording.add")}`}
        </Button>
      </div>
    </div>
  ) : (
    <div
      id="centerPanelEditCard"
      className={`bg-white shadow rounded-lg p-4 max-h-56 relative    card-patient justify-start   mt-4 ${
        isEditMode ? "h-56  " : "max-h-28 h-28 centerPanelEditCard"
      }`}
      onClick={() => {
        setSelectedCenter(centerDetails?._id);
        if (!isEditMode) setIsEditMode(true);
      }}
    >
      <div className="flex patient-info ">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 "
          style={{
            backgroundColor: "#ffecba",
            cursor: "pointer",
          }}
        >
          <img src={HospitalImg} alt="icon" />
        </div>
      </div>
      {isEditMode ? (
        <>
          <div className="container mt-1 mb-9 ">
            <Input
              id="centerPanelEditCardInput1"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              labelText={t("Centre.nameCenter")}
              className="text-sm"
              style={{ maxHeight: "40px" }}
            />

            <Input
              id="centerPanelEditCardInput2"
              className="text-sm"
              style={{ maxHeight: "40px" }}
              type="text"
              name="adresse"
              value={formData.adresse}
              onChange={handleChange}
              labelText={t("Centre.address")}
            />
            <Input
              id="centerPanelEditCardInput3"
              className="text-sm"
              style={{ maxHeight: "40px" }}
              type="text"
              name="abreviation"
              value={formData.abreviation}
              onChange={handleChange}
              labelText={t("Centre.abrev")}
            />
          </div>
          <Button
            id="centerPanelEditCardSave"
            onClick={handleBlur}
            status="primary"
            className="absolute  right-4 bottom-2 px-2 py-1"
            disabled={loading}
          >
            {t("Centre.save")}
          </Button>
          <Button
            onClick={handleExit}
            status="secondary"
            className="absolute  right-32 bottom-2 px-2 py-1"
          >
            {t("Centre.cancel")}
          </Button>
        </>
      ) : (
        <div className="flex flex-col max-h-24 h-full  ">
          <h3 className="font-bold mb-0.5">{centerDetails?.name}</h3>
          <p className="text-gray-700 mb-0.5">{centerDetails?.adresse}</p>
          <span className="bg-gray-200 text-gray-400 text-sm rounded-lg px-2 py-1 w-auto">
            {centerDetails?.abreviation}{" "}
          </span>
          <div className="absolute right-2 top-1">
            <Button
              status="icon-secondary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedCenterName(centerDetails?.name);
                setSelectedCenter(centerDetails?._id);
                setIsEditMode(false);
                setDeleteCentreModalState(true);
                setFormData({ name: "", adresse: "", abreviation: "" });
              }}
              style={{ height: "46px", width: "48px", padding: 0 }}
            >
              <Close />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditableCard;
