import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { ReactComponent as Survery } from "../../assets/illustrations/survey.svg";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import history from "../../history";
import DeleteSurveyModal from "./deleteTemplateModal";
import UpdateTemplate from "./UpdateTemplate";
import { FcLeave } from "react-icons/fc";
import "./styles.scss";
const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
const CardSurvey: React.FC<any> = ({
  patientDetails,
  surveyDetails,
  i,
  visteList,
  setRefresh,
  isEvent = false,
  eventType = null,
}) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState() as any;
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);

  const { t } = useTranslation() as any;

  return (
    <>
      <Modal
        showModal={showSaveAsTemplateModal}
        closeModal={() => setShowSaveAsTemplateModal(false)}
        className="px-10 py-16"
      >
        <UpdateTemplate
          visteList={visteList}
          surveyDetails={surveyDetails}
          setShowSaveAsTemplateModal={setShowSaveAsTemplateModal}
          setRefresh={setRefresh}
        />
      </Modal>

      <Modal
        showModal={showModalDelete}
        // slidemodal
        closeModal={() => setShowModalDelete(false)}
        className="px-10 py-16"
      >
        <DeleteSurveyModal
          surveyDetails={surveyDetails}
          setShouldUpdate={setRefresh}
          closeModal={() => setShowModalDelete(false)}
        />
      </Modal>

      <div
        className={`relative cursor-pointer hover:shadow card-patient justify-between bg-white `}
        style={{ zIndex: 0 }}
        onClick={() => {
          if (surveyDetails?.el?._id) {
            history.push(`/template/${surveyDetails.el._id}`);
          }
        }}
      >
        <div className="flex ">
          <div
            className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
            style={{
              backgroundColor: patientDetails?.photo
                ? ""
                : colorAvatar[i % colorAvatar.length],
            }}
          >
            {isEvent ? <FcLeave size={"38"} /> : <Survery width={37} />}
          </div>
          <div className="details">
            <div className="flex-grow ">
              <p className="card-title capitalize">{surveyDetails?.el?.name}</p>
              <p className="card-subtitle">
                {`${t("DashboardCro.lastUpdate")}`}{" "}
                <span>
                  {moment(surveyDetails?.el?.updatedAt).format("DD-MM-YYYY") +
                    t("DashboardCro.at") +
                    moment(surveyDetails?.el?.updatedAt).format("HH:mm")}
                </span>
              </p>

              {surveyDetails?.el?.description &&
              surveyDetails?.el?.description !== "" ? (
                <p className="box-motif max-w-350 ">
                  {surveyDetails?.el?.description}
                </p>
              ) : (
                ""
              )}

              {isEvent && eventType && (
                <p className="box-motif max-w-350 ">
                  {eventType === "ADVERSE"
                    ? t("missingWording.adverseevents")
                    : t("missingWording.phoneCallEvent")}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className=" gap-0.5 mr-2 mt-2 mb-2"
          style={{
            // justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isEvent && (
            <>
              <Button
                status="icon-secondary"
                className="w-10 h-10"
                withToolTip
                toolTipText={`${t("missingWording.editVisitToolTip")}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowSaveAsTemplateModal(true);
                  setSelectedSurvey(surveyDetails?.el);
                }}
              >
                <AiFillEdit />
              </Button>
              <Button
                status="icon-secondary"
                className="w-10 h-10"
                withToolTip
                toolTipText={`${t("missingWording.deleteVisitToolTip")}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedSurvey(surveyDetails?.el);
                  setShowModalDelete(true);
                }}
              >
                <AiFillDelete />
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CardSurvey;
