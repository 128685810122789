import { useAtom } from "jotai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { logout, verify } from "../../../api/auth";
import { addOrg } from "../../../api/organisation";
import Button from "../../../components/ButtonUI";
import { FormikWizard } from "../../../components/FormikWizard";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import { loadLocaleLang } from "../../../helper";
import history from "../../../history";
import { userAtom } from "../../../state/auth";
import AddCroForm from "./addCroForm";
import AddOrganisationForm from "./addOrganisationForm";
import "./styles.scss";

export interface IValues {
  firstName: string | any;
  lastName: string | any;
  birthDate: string | any;
  gender: string | any;
  phone: string | any;
  pays: string | any;
  ville: string | any;
  postalCode: string | any;
  name: string | any;
  adresse: string | any;
  paysOrganisation: string | any;
  villeOrganisation: string | any;
  postalCodeOrganisation: string | any;
  adressOrganisation: string | any;
  phoneOrganisation: string | any;
  croAdmin: string | any;
  email: string | any;
  idOrg: string | any;
}

const CreateOrgansation: React.FC = () => {
  const [user, setUser] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [orgId,] = useState();
  const { t } = useTranslation() as any;

  const submit = async (values) => {
    try {
      setLoading(true);
      await addOrg(values);
      verify().then((res) => {
        setUser({ ...user, ...res?.user });
        if (res?.user?.tenantId === "cro") {
          history.push("/add/organisation");
        } else history.push("/");
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      makeToast("error", `${t("Organisation.errorSubmit")}`);
    }
  };
  const ProgressBar = (progress: number) => {
    const progressPercentage = (100 / 2) * (progress + 1);

    return (
      <div className=" h-2 w-full bg-gray-300 border rounded-md">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full ${
            progressPercentage < 75 ? "bg-indigo-500" : "bg-green-300"
          }`}
        ></div>
      </div>
    );
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className=" flexCenter min-h-screen flex justify-center">
          <div className=" z-40 top-8 flexColCenter firstModal2 ">
            <h2 className="text-4xl">{t("Organisation.TitleWelcome")} 👋 </h2>

            <div className="m-2 w-full">
              <ul className="stepper " data-mdb-stepper="stepper">
                <FormikWizard
                  initialValues={{
                    //cro fields
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    //birthDate: "",
                    lang: loadLocaleLang(),
                    gender: "",
                    phone: "",
                    pays: "",
                    ville: "",
                    postalCode: "",
                    //org fields
                    name: "",
                    adresse: "",
                    paysOrganisation: "",
                    villeOrganisation: "",
                    postalCodeOrganisation: "",
                    adressOrganisation: "",
                    phoneOrganisation: "",
                    croAdmin: user?._id,
                    email: user?.email,
                    idOrg: orgId ? null : orgId,
                  }}
                  onSubmit={async (values) => {
                    console.log(values);
                  }}
                  activeStepIndex={0}
                  steps={[
                    {
                      component: ({
                        values,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        touched,
                      }) => (
                        //createOrgansation
                        <AddCroForm
                          handleChange={handleChange}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          errors={errors}
                          touched={touched}
                        />
                      ),
                      validationSchema: Yup.object().shape({
                        firstName: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        lastName: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        //birthDate: Yup.string().required("required"),
                        gender: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        phone: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        pays: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        ville: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        postalCode: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                      }),
                    },
                    {
                      component: ({ values, handleChange, errors }) => (
                        //Update CroAdmin
                        <>
                          <AddOrganisationForm
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                          />
                        </>
                      ),
                      validationSchema: Yup.object().shape({
                        name: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        adresse: Yup.string().required("required"),
                        paysOrganisation: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        villeOrganisation: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        postalCodeOrganisation: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        adressOrganisation: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                        phoneOrganisation: Yup.string().required(
                          `${t("missingWording.required")}`
                        ),
                      }),
                    },
                  ]}
                >
                  {({
                    renderComponent,
                    handlePrev,
                    handleNext,
                    isPrevDisabled,
                    isLastStep,
                    currentStepIndex,
                    values,
                    setErrors,
                    setFieldValue,
                  }) => (
                    <>
                      {currentStepIndex
                        ? ProgressBar(currentStepIndex)
                        : ProgressBar(0)}
                      {}
                      <ol className="c-stepper">
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 0
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            {t("Organisation.CompleteProfile")}
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li>
                        <li className="c-stepper__item">
                          <h3
                            className={`c-stepper__title ${
                              currentStepIndex == 1
                                ? "text-indigo-500"
                                : "text-gray-300"
                            }`}
                          >
                            {t("Organisation.ConfigOrg")}
                          </h3>
                          <p className="c-stepper__desc mb-3"></p>
                        </li>
                      </ol>
                      {renderComponent()}
                      <Button
                        status="primary"
                        className="float-right flexCenter mx-5 my-5"
                        width="100px"
                        height="50px"
                        type="submit"
                        onClick={() => {
                          isLastStep ? submit(values) : handleNext();
                        }}
                        disabled={false}
                      >
                        {isLastStep
                          ? `${t("Organisation.Finish")}`
                          : `${t("Organisation.Next")}`}
                      </Button>

                      <Button
                        status="secondary"
                        className="float-left flexCenter my-5"
                        width="100px"
                        height="50px"
                        type="button"
                        onClick={handleLogout}
                      >
                        {t("Organisation.Cancel")}
                      </Button>
                      {!isPrevDisabled && (
                        <>
                          <Button
                            status="primary"
                            className="float-right flexCenter mx- 5 my-5"
                            width="100px"
                            height="50px"
                            type="button"
                            onClick={handlePrev}
                            hidden={isPrevDisabled}
                          >
                            {t("Organisation.Precedent")}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </FormikWizard>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateOrgansation;
