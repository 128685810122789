import {  Box,   Grid,  Stack } from "@chakra-ui/react";
import DotsStepper from "./DotsStepper";

  const StepperWrapper = ({ title,  ...rest }) => {

    return (
      <Stack {...rest}>
        <Box p="4" borderRadius="md">
          <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
            <Box>
              <></>
            </Box>
            <DotsStepper dir={rest.dir}/>
            <Box textAlign="right">
              <></>
            </Box>
          </Grid>
        </Box>
      </Stack>
    );
  }
  export default StepperWrapper