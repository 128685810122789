import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Component } from "./config/constants/i18n/Component";
import { DashboardCro } from "./config/constants/i18n/DashboardCro";
import { DashboardDoctor } from "./config/constants/i18n/DashboardDoctor";
import { Moment } from "./config/constants/i18n/Moment";
import { Profile } from "./config/constants/i18n/ProfileI18";
import { SideBarMenu } from "./config/constants/i18n/SideBarMenu";
import { SignIn } from "./config/constants/i18n/SignIn";
import { SignUp } from "./config/constants/i18n/SignUp";
import { Survey } from "./config/constants/i18n/Survey";
import SurveyIntlen from "./config/constants/locales/en-us.json";
import SurveyIntlfr from "./config/constants/locales/fr-fr.json";
import { SideBar } from "./config/constants/i18n/SideBar";
import { Query } from "./config/constants/i18n/Query";
import { Organisation } from "./config/constants/i18n/Organisation";
import { Participant } from "./config/constants/i18n/Participant";
import { Commun } from "./config/constants/i18n/Common";
import { ArcPage } from "./config/constants/i18n/ArcPage";
import { Centre } from "./config/constants/i18n/Centre";
import { Investigator } from "./config/constants/i18n/Investigateur";
import { Role } from "./config/constants/i18n/Role";
import { Visit } from "./config/constants/i18n/Visits";
import { Study } from "./config/constants/i18n/Study";
import { DriverJs } from "./config/constants/i18n/driverjs";
import { missingWording } from "./config/constants/i18n/missingWording";
import { tuttoWording } from "./config/constants/i18n/tuttoWording";
import { eConsult } from "./config/constants/i18n/eConsult";
import { ThanksPage } from "./config/constants/i18n/ThanksPage";
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          Visit: Visit.en,
          Study: Study.en,
          Role: Role.en,
          Investigator: Investigator.en,
          Centre: Centre.en,
          ArcPage: ArcPage.en,
          Participant: Participant.en,
          Commun: Commun.en,
          Organisation: Organisation.en,
          SignIn: SignIn.en,
          SignUp: SignUp.en,
          Query: Query.en,
          DashboardDoctor: DashboardDoctor.en,
          DashboardCro: DashboardCro.en,
          Moment: Moment.en,
          SideBar: SideBar.en,
          Component: Component.en,
          Profile: Profile.en,
          SideBarMenu: SideBarMenu.en,
          missingWording: missingWording.en,
          tuttoWording: tuttoWording.en,
          Survey: Survey.en,
          DriverJs: DriverJs.en,
          eConsult: eConsult.en,
          SurveyIntl: SurveyIntlen,
          ThanksPage:ThanksPage.en
        },
      },
      fr: {
        translation: {
          // here we will place our translations...
          Visit: Visit.fr,
          Study: Study.fr,
          Role: Role.fr,
          Investigator: Investigator.fr,
          Centre: Centre.fr,
          ArcPage: ArcPage.fr,
          Participant: Participant.fr,
          Commun: Commun.fr,
          Organisation: Organisation.fr,
          SideBar: SideBar.fr,
          SignIn: SignIn.fr,
          SignUp: SignUp.fr,
          Query: Query.fr,
          DashboardDoctor: DashboardDoctor.fr,
          DashboardCro: DashboardCro.fr,
          Moment: Moment.fr,
          missingWording: missingWording.fr,
          tuttoWording: tuttoWording.fr,
          Component: Component.fr,
          Profile: Profile.fr,
          SideBarMenu: SideBarMenu.fr,
          Survey: Survey.fr,
          DriverJs: DriverJs.fr,
          eConsult: eConsult.fr,
          SurveyIntl: SurveyIntlfr,
          ThanksPage:ThanksPage.fr
        },
      },
    },
  });

export default i18n;
