import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import { archiveStudyApi, endingStudyApi } from "../../../api/study";
import { StudySelectedIdAtom } from "../../../state/studySelectedIdAtom";
import { useAtom } from "jotai";
import makeToast from "../../../components/Snackbar";

type Props = {
  selectedStudy: any;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  participantsCount: number;
  setStep:any;
  setUpdate?:any;
};

export const EndStudyModal: React.FC<Props | any> = ({
  setOpenModal,
  selectedStudy,
  setStep,
  setUpdate
}) => {
  const { t } = useTranslation() as any;
  const [studySelectedId,setStudySelectedId ] = useAtom(StudySelectedIdAtom);
  const archiveStudyFunc =async () => {
    try {
      const res:any = await endingStudyApi(studySelectedId);
      console.log("🚀 ~ file: EndStudyAlert.tsx:22 ~  ~ res:", res)
      if (res ){
        makeToast('success',t('missingWording.successEnding'))
        setUpdate((maj)=>!maj)
        setOpenModal(false)
        setStudySelectedId(null)
        setStep('0')
      }else {
      makeToast('error',t('missingWording.FailEnding'))

      }

    } catch (error) {
      makeToast('error',t('missingWording.FailEnding'))
      console.log("🚀 ~ file: EndStudyAlert.tsx:23 ~  ~ error:", error)
      
    }
  }
  return (
    <div className="px-4 ">
      <h1 className="mb-4">
      {t("missingWording.endStudyMsg1")}
       </h1>
      <h3 className="mb-8">
      {t("missingWording.endStudyMsg2")}

      </h3>
      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-left  btn-delete"
        onClick={() => setOpenModal(false)}
      >
        {t("Participant.Cancel")}
      </Button>

      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-right"
        type="submit"
        onClick={async () => {
          archiveStudyFunc()
          
        }}
      >
        {t("missingWording.confirm")}
      </Button>
    </div>
  );
};

export default EndStudyModal;
