export const Study = {
  fr: {
    Visit: "Visites",
    NewVisit: "Nouveau formulaire",
    VisitStudy: "Aucun formulaire pour cette étude",
    eventStudy: "Aucun événement pour cette étude",
    eproNoforms:"Aucun formulaire ePRO pour cette étude",
    order: "Ordre d'affichage",
    grp: "Groupe",
    addGrp: "+ Ajouter groupe",
    add2Grp: "+ Ajouter au moins 2 groupes",
    save: "Enregistrer",
    grpName: "nom de groupe",
    addGrpToast: "les groupes sont créés avec succès",
    auto: "Affectation automatique",
    switchable: "Réaffectation en cours d'étude",
    tooltipAuto:
      "Les participants sont attribués automatiquement et de manière aléatoire à un groupe sans intervention des investigateurs.",
    tooltipSwitchable:
      "Les participants seront transférés vers un nouveau groupe à partir d'un formulaire de réaffectation défini.",
    switchingVisit: "Réaffecter à la fin des visites suivantes",
    assignFirstGrp: "Affecter  à un  premier groupe aprés le formulaire",
    configAssignments: "Paramétrage des affectations",
    toolTipAddFormButton: "Vous pouvez ajouter jusqu'à ",
    forms: "formulaire(s)",
    resagmnetFormLabel: "Réaffectation à partir du formulaire",
  },
  en: {
    Visit: "Visits",
    NewVisit: "New form",
    VisitStudy: "No form for this study",
    eproNoforms:"No ePRO form for this study",
    eventStudy: "No event for this study",
    order: "Display order",
    addGrp: "+ Add group",
    grp: "Group",
    add2Grp: "+ Add at least 2 groups",
    save: "Save",
    grpName: "Group name",
    auto: "Automatic assignment",
    addGrpToast: "groups are successfully created",
    switchable: "Mid-study reassignment",
    tooltipAuto:
      "Participants are assigned automatically and randomly to a group without input from the investigators",
    tooltipSwitchable:
      "Participants will be transferred to a new group from the defined reassignment form",
    switchingVisit: "Reassign at the end of the following visits",
    assignFirstGrp: "Assign to first group at",
    configAssignments: "Assignments configuration",
    toolTipAddFormButton: "You can add up to ",
    forms: "form(s)",
    resagmnetFormLabel: "Reassignment from the form",
  },
};
