import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import { ImEnter } from "react-icons/im";
import history from "../../history";
import { getWorkingSpaces, } from "../../api/auth";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import { socketAtom } from "../../state/socket";

const WorkSpaceSelectionPage = () => {
  const [user, setUser] = useAtom(userAtom) as any;
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [loading, setLoading] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [data, setData] = useState() as any;
  const location = useLocation() as any;
  const { t } = useTranslation() as any;
  useEffect(() => {
    // Retrieve the state from the location object
    const { state } = location;
    console.log("🚀 ~ file: index.tsx:16 ~ useEffect ~ state:", state)
    if(state)
    setData(JSON.parse(JSON.stringify(state.data)));
  else {
    getWorkingSpaces().then((res)=>{
      console.log("🚀 ~ file: index.tsx:24 ~ verify ~ e:", res)
      setData(JSON.parse(JSON.stringify(res.data)));
    }).catch((e) => {
      console.log("🚀 ~ file: index.tsx:24 ~ verify ~ e:", e)
    
      
    })
  }
  }, []);
  useEffect(() => {
    if(selectedTenant){
      history.push({
        pathname: `/`,
      });
    }
  }, [selectedTenant]);
  return data && data.user ? (
    <div className="container p-4 h-screen">
      <h1 className="text-center mt-2 mb-8">{t("missingWording.selectOrg")}</h1>
      <div className="flex items-center justify-center self-center">
        <table className="min-w-1/2 divide-y-2 divide-gray-500  border-b border-gray-500 ">
          <thead className=" xs:text-xs sm:text-sm md:text-sm lg:text-md">
            <tr>
              <th
                scope="col"
                className="md:px-6 xs:px-1 py-3 text-lg font-bold  text-gray-500  "
              >
            
                {t("missingWording.workSpaces")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-500 ">
            {data.user
              .filter((e) => e && e !== null)
              .map((z) => {
                return (
                  <tr
                    className="cursor-pointer h-16 bg-transparent  "
                    onClick={() => {
                      localStorage.setItem("tenantId", z.organisationId.tenant);
                      setUser(z)
                      setSelectedTenant(z.organisationId.tenant);
                      setSocket(null)
                    }}
                  >
                    <td className="   px-2 font-semibold text-gray-500 hover:text-black hover:bg-gray-50">
                      <div className="flex justify-between gap-x-4 items-center">
                        <p> {z?.organisationId?.name}</p>
                        <p className="rounded-full flex font-normal items-center  text-center bg-gray-50  px-2 py-2  shadow-md text-xs">
                          <ImEnter className="" size={24} />
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
              
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};
export default WorkSpaceSelectionPage;
