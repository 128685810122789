import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { IValues } from ".";
import Input from "../../../components/FloatInput";
import SelectComp from "../../../components/Select";
import { userAtom } from "../../../state/auth";

type Props = {
  handleChange: React.ChangeEvent<any> | any;
  values: IValues | any;
  setFieldValue: any;
  handleBlur: React.FocusEvent<any, Element> | any;
  touched: any;
};

export const AddCroForm: React.FC<Props | any> = ({
  handleChange,
  values,
  errors,
  handleBlur,
}) => {
  const [user] = useAtom(userAtom);
  const { t } = useTranslation() as any;

  return (
    <>
      <div className="flex flex-col">
        <Input
          id="lastName"
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.Name")}
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          error={errors.lastName}
          disabled={values.lastName}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.lastName}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          id="firstName"
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.Last")}
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          error={errors.firstName}
          disabled={values.firstName}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.firstName}
        </span>
      </div>
      <Input
        type={"text"}
        className="rounded-lg "
        labelText="Email"
        name="email"
        value={user?.email}
        disabled
      />
      <div className="flex flex-col">
        <SelectComp
          id="gender"
          options={[
            { label: `${t("Organisation.Hs")}`, value: "male" },
            { label: `${t("Organisation.Fs")}`, value: "female" },
          ]}
          error={errors.gender}
          text={"Sexe"}
          placeholder={"Sexe"}
          value={values.gender}
          setText={true}
          onChange={handleChange}
          onBlur={handleBlur}
          className="w-full mb-2.5 rounded-lg border-gray-200 outline-none px-3"
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.gender}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          id="phone"
          placeholder={t("Organisation.phone")}
          labelText={t("Organisation.phone")}
          type="text"
          error={errors.phone}
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          className="w-full rounded-lg border-gray-200 outline-none"
          inlineLabel={false}
          // onfocus={true}
        />{" "}
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.phone}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          id="postalCode"
          value={values.postalCode}
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.codePost")}
          name="postalCode"
          onChange={handleChange}
          error={errors.postalCode}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.postalCode}
        </span>
      </div>
      <div className="flex flex-col">
        <Input
          id="ville"
          value={values.ville}
          type={"text"}
          className="rounded-lg "
          labelText={t("Organisation.city")}
          name="ville"
          onChange={handleChange}
          error={errors.ville}
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.ville}
        </span>
      </div>
      <div className="flex flex-col">
        <SelectComp
          id="pays"
          // error={errors.country}
          value={values.pays}
          options={[
            {
              label: t("missingWording.france"),
              value: t("missingWording.france"),
            },
            {
              label: t("missingWording.tunisia"),
              value: t("missingWording.tunisia"),
            },
          ]}
          error={errors.pays}
          onChange={handleChange}
          text={t("Organisation.country")}
          className="w-full mb-2.5"
          // required
        />
        <span className="text-xs text-red-300 ml-4 -mt-2 mb-2 ">
          {errors.pays}
        </span>
      </div>
    </>
  );
};

export default AddCroForm;
