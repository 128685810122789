import React, { useState } from "react";
import { Formik } from "formik";
import { useAtom } from "jotai";
//import presentationSchema from "./schemas/presentationSchema";
import { userAtom } from "../../../state/auth";
import Button from "../../../components/ButtonUI";
import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import "./styles.scss";
import userProfileSchema from "./schemas/userProfileSchema";
import Input from "../../../components/FloatInput";
import { useTranslation } from "react-i18next";
import { updateDataUserAPI } from "../../../api/auth";
import makeToast from "../../../components/Snackbar";
import { User } from "../../../types/user";
import DatePicker from "react-datepicker";
import SelectComp from "../../../components/Select";
import CountryWithPhoneSuffix from "../../../config/constants/paysFr.json";
import CountryWithPhoneSuffixEn from "../../../config/constants/paysEn.json";
import FloatDateTimePicker from "../../../components/FloatDateTimePicker";
import moment from "moment";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  doctorId?: string;
}

export const ModifyDataUserProfile: React.FC<Props> = ({
  openModal,
  setOpenModal,
}) => {
  const { t } = useTranslation() as any;
  const [user, setUser] = useAtom<User | any>(userAtom) as any;
  const [loading, setloading] = useState(false);
  const listCountry = () => {
    let local = localStorage.getItem("i18nextLng");
    return local === "fr" ? CountryWithPhoneSuffix : CountryWithPhoneSuffixEn;
  };

  const updateDataForm = async (values) => {
    setloading(true);
    const response = await updateDataUserAPI(values, user._id);
    setUser(response);
    if (response) {
      makeToast("success", t("Profile.SuccessUpdateData"));
    } else {
      makeToast("error", t("Profile.FailUpdateData"));
    }
    setOpenModal(false);
    setTimeout(() => setloading(false), 1000);
  };
  return (
    <div id="EditAccountModal">
    <Formik
      initialValues={{
        firstName: user?.firstName,
        lastName: user?.lastName,
      
        pays: user?.pays,
        type: user?.type,
      }}
      onSubmit={async (values) => {
        try {
          updateDataForm(values);
        } catch (err) {}
      }}
      validationSchema={userProfileSchema}
    >
      {(props) => {
        const {
          values,
          // errors,
          // isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <form
            onSubmit={handleSubmit}
            className="flex-auto py-7 px-8 min-w-full	"
          >
            <h1 className="text-center mb-10">
              {t("Profile.ModalUpdateProfile")}
            </h1>
            <div className="">
              <Input
                id="firstName"
                labelText={t("Profile.name")}
                //error={values.pricing}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                inlineLabel={false}
                onfocus={true}
              />
            </div>
            <div className="">
              <Input
                id="lastName"
                labelText={t("Profile.lastName")}
                //error={values.pricing}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                inlineLabel={false}
                onfocus={true}
              />
            </div>
    
            <div className="mb-8">
              <SelectComp
                id="pays"
                value={values.pays}
                options={listCountry().map((el) => ({
                  value: el.name,
                  label: el.name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                text={t("Profile.Country")}
                className="w-full"
                // required
              />
            </div>
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="float-left mb-4 btn-delete"
              onClick={() => setOpenModal(false)}
            >
              {t("Profile.Cancel")}
            </Button>
            {loading ? (
              <div className="flex flex-row-reverse">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            ) : (
              <Button
                id="EditFormbtn"
                status="secondary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                onClick={() => handleSubmit()}
              >
                {t("Profile.Save")}
              </Button>
            )}
          </form>
        );
      }}
    </Formik>
    </div>
  );
};

export default ModifyDataUserProfile;
