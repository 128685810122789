export const DriverJs = {
  fr: {
    btnSkip: "Passer",
    btnDone: "Terminer",
    btnNext: "Suivant",
    t_ch1_1: "Nouvelle étude",
    d_ch1_1:
      "Pour commencer, cliquez sur le bouton 'Nouvelle Étude' afin d'initier la première étape.",
    t_ch1_2: "Nouvelle étude",
    d_ch1_2:
      "Vous êtes en train de mettre en place une nouvelle étude et nous vous invitons à compléter ce formulaire.",
    t_ch1_3: "Nom de l'étude",
    d_ch1_3: "Inscrivez le nom de votre étude dans le champ prévu à cet effet.",
    t_ch1_4: "Description de l'étude",
    d_ch1_4:
      "Ajoutez une brève description de l'étude dans le champ correspondant.",
    t_ch1_5: "Enregistrement ",
    d_ch1_5:
      "Cliquez ensuite sur ce bouton pour sauvegarder les informations que vous avez fournies.",
    t_ch1_6: "Anonymiser l'étude",
    t_ch1_7: "Anonymiser l'étude",
    d_ch1_6:
      "L'anonymisation de l'étude signifie que les noms et prénoms des participants ne seront pas consignés dans la base de données, préservant ainsi leur confidentialité.",
    d_ch1_7:
      "Vous avez la possibilité de sélectionner le bouton 'anonymiser' ou 'ne pas anonymiser'. Dans cette démonstration, nous avons opté pour une étude anonymisée.",
    //Step1
    t_ch2_1: "Configuration de l'étude",
    d_ch2_1:
      " La page de configuration de l'étude vous permet de définir et d'ajuster les paramètres essentiels pour votre étude. Utilisez cette page pour personnaliser et optimiser les configurations afin de répondre au mieux à vos besoins",
    t_ch2_2: "Configuration générale",
    d_ch2_2:
      " Dans cette section, vous avez la possibilité de personnaliser les paramètres généraux de votre étude en effectuant des modifications selon vos besoins",
    t_ch2_3: "Détails de l'étude",
    d_ch2_3:
      "Éditer les détails de l'étude en modifiant le nom de l'étude et la description.",
    t_ch2_4: "Anonymisation l'étude",
    d_ch2_4:
      "Anonymisez l'étude en toute simplicité avec le bouton de commutation.",
    t_ch2_5: "Randomisation étude",
    d_ch2_5: "Randomisez les données en un clic avec le bouton de commutation.",
    t_ch2_6: "Accès aux participans",
    d_ch2_6:
      "Activez ou désactivez Accès aux participants ouvert par centre en utilisant le bouton de commutation ",
    t_ch2_7: "E-pro",
    d_ch2_7:
      "Activez ou désactivez la fonctionnalité E-Pro en utilisant le bouton de commutation",
    t_ch2_8: "Centres ",
    d_ch2_8:
      "Dans cette section, vous avez la possibilité de gérer vos centres",
    t_ch2_9: "Nouveau centre",
    d_ch2_9: "Ajoutez un nouveau centre en remplissant le formulaire.",
    t_ch2_10: "Nom du centre",
    d_ch2_10: "Entrer le nom du centre",
    t_ch2_11: "Adresse du centre",
    d_ch2_11: "Entrer l'adresse du centre",
    t_ch2_12: "Abréviation du centre",
    d_ch2_12: "Entrer une abréviation du centre",
    t_ch2_13: "Créer le centre",
    d_ch2_13: "Cliquer maintenant sur 'enregistrer'",
    t_ch2_14: "Détails du Centre",
    d_ch2_14:
      "Dans cette carte, vous pouvez consulter les détails du centre que vous avez saisis",
    t_ch2_15: "Profils",
    d_ch2_15: "Dans cette section, vous pouvez administrer les profils.",
    t_ch2_16: "Création du profil",
    d_ch2_16:
      "Ajoutez un profil pour vos investigateurs en remplissant le formulaire.",
    t_ch2_17: "Nom du profil",
    d_ch2_17: "Entrer le nom du profil",
    t_ch2_18: "Créer le profil",
    d_ch2_18: "Cliquer sur 'enregistrer'",
    t_ch2_19: "Édition du profil",
    d_ch2_19:
      "Modifiez votre profil en cliquant une seule fois sur le nom du profil correspondant.",
    t_ch2_20: "Édition du profil",
    d_ch2_20:
      "Il est démontré qu'il suffit d'un simple clic pour obtenir le changement souhaité.",
    t_ch2_21: "",
    d_ch2_21: "Saisissaisez le nom du profil souhaité",
    t_ch2_22: "",
    d_ch2_22: "Enregistrer vos modifications",
    t_ch2_23: "Gestion des membres ",
    d_ch2_23:
      "Pour ajouter les membres de l'étude, accédez à la section 'Membres' et suivez les instructions pour inclure de nouveaux arcs  ou investigateurs",
    t_ch2_24: "Ajouter un ARC",
    d_ch2_24:
      "Consultez l'onglet 'Mon Équipe'  pour accéder à la liste et aux détails de tous les membres de votre équipe.",
    t_ch2_25: "Ajouter un investigateur",
    d_ch2_25:
      "Accédez à l'onglet 'Investigateur' pour consulter et gérer les membres de l'équipe d'investigation ",
    t_ch2_26: "Ajouter un sponsor",
    d_ch2_26:
      "Consultez l'onglet 'Sponsor' pour accéder aux informations et gérer les membres de l'équipe sponsor de l'étude.",
    t_ch2_27: "Invitations Sponsor",
    d_ch2_27:
      "Explorez l'onglet 'Invitations' pour gérer les invitations aux membres de l'étude et suivre leur statut de participation",
    t_ch2_28: "Nouveau investigateur",
    d_ch2_28:
      "Pour ajouter un investigateur, cliquez sur le bouton 'Ajouter Investigateur'",
    t_ch2_29: "Nouveau investigateur",
    d_ch2_29:
      "Utilisez le formulaire  pour ajouter un investigateur en remplissant les informations requises et en cliquant sur le bouton 'Enregistrer' .",
    t_ch2_30: "Prénom",
    d_ch2_30:
      "Saisissez le prénom de l'investigateur dans le champ prévu à cet effet",
    t_ch2_31: "Nom",
    d_ch2_31:
      "Entrez le nom de l'investigateur dans le champ prévu à cet effet",
    t_ch2_32: "E-mail",
    d_ch2_32:
      "Indiquez l'adresse e-mail de l'investigateur dans le champ dédié",
    t_ch2_33: "Profil",
    d_ch2_33: "Sélectionnez le profil dans la liste déroulante",
    t_ch2_34: "Centre",
    d_ch2_34: "Choisissez le centre dans la liste déroulante.",
    t_ch2_35: "Sauvegarder",
    d_ch2_35:
      "Cliquez sur le bouton 'Enregistrer' pour sauvegarder les informations",
    t_ch2_36: "Visites & formulaires",
    d_ch2_36:
      "Notre plateforme simplifie le processus en trois sections clés : Créez des formulaires personnalisés, planifiez et gérez efficacement vos visite ,analysez les données collectées et les événements ",
    t_ch2_37: "Formulaires de visite",
    d_ch2_37:
      "Optimisez votre processus de visite grâce à notre formulaire intuitif et organisé ",
    t_ch2_38: "Événement récurrent ",
    d_ch2_38:
      "Explorez les événements récurrents en cliquant sur l'onglet dédié.",
    t_ch2_39: "Créer Formulaire",
    d_ch2_39:
      "Créez votre formulaire en cliquant sur le bouton 'Créer Formulaire'",
    t_ch2_40: "Créer Formulaire",
    d_ch2_40:
      "Un modal s'affiche, vous permettant de remplir les champs nécessaires pour ajouter votre formulaire.",
    t_ch2_41: "Nom du formulaire ",
    d_ch2_41: "Nommez votre formulaire (Visite)",
    t_ch2_42: "Description du formulaire",
    d_ch2_42: "Précisez la description de votre formulaire (Visite)",
    t_ch2_43: "Profil attaché",
    d_ch2_43: "Sélectionnez le profil attaché dans la liste déroulante ",
    t_ch2_44: "Sauvegarde du formulaire",
    d_ch2_44: "Sauvegardez votre formulaire en cliquant sur le bouton 'Créer'.",

    //step 3
    t_ch3_1: "Configuration de la page",
    d_ch3_1:
      "Personnalisez la première section de votre formulaire (par défaut intitulée 'Page 1') en ajustant le nom et les paramètres selon vos préférences.",
    t_ch3_2: "Configuration de la page",
    d_ch3_2:
      " En cliquant sur l'onglet (+), vous pouvez facilement ajouter le nombre de sections souhaitées pour une personnalisation optimale.",
    t_ch3_3: "Configuration de la page",
    d_ch3_3:
      "En cliquant sur cette icône de configuration, vous pouvez aisément paramétrer les configurations nécessaires pour votre section selon vos besoins.",
    t_ch3_4: "Configuration de la page",
    d_ch3_4:
      "Après avoir cliqué sur l'icône de configuration, un modal s'affiche avec les configurations par défaut, vous permettant d'apporter les modifications souhaitées en toute simplicité.",
    t_ch3_5: "Nom de la page",
    d_ch3_5: "Entrez le nom souhaité pour votre page.",
    t_ch3_6: "Logique conditionnelle",
    d_ch3_6: "Activez ou désactivez la logique conditionnelle de votre page",
    t_ch3_7: "Enregistrement",
    d_ch3_7:
      "Pour sauvegarder les modifications apportées aux paramètres, cliquez sur le bouton 'Enregistrer' ",
    t_ch3_8: "Ordre des sections",
    d_ch3_8:
      "Personnalisez l'ordre des pages selon vos besoins en cliquant sur cette icône",
    t_ch3_9: "Boite à outils",
    d_ch3_9:
      "Cette section à gauche contient tous les champs nécessaires pour personnaliser votre formulaire. Vous pouvez faire glisser le champ souhaité dans la page et apporter les modifications selon vos besoins",
    t_ch3_10: " Champs de saisies",
    d_ch3_10:
      "La section 'Champs de saisies' dans un formulaire représente les zones dédiées où les utilisateurs peuvent entrer et soumettre des informations spécifiques.",
    t_ch3_11: "Champs informationnels",
    d_ch3_11:
      "Les 'Champs informationnels' désignent les zones destinées à la saisie ou à l'affichage d'informations dans un formulaire ou une interface",
    t_ch3_12: "",
    d_ch3_12:
      "Dans cette section des boîtes à outils, vous trouverez des éléments tels que le titre, le commentaire, le séparateur et l'image que vous pouvez ajouter à votre formulaire personnalisé",
    t_ch3_13: "Titre",
    d_ch3_13:
      "Faites glisser et déposez le champ de titre dans l'espace vide de la page pour poursuivre la démonstration",
    t_ch3_14: "",
    d_ch3_14:
      "Dans ce contenu de texte, vous pouvez rédiger le titre ou le texte souhaité et appliquer le style de texte de votre choix.",
    t_ch3_15: "",
    d_ch3_15: "Cliquez sur 'Enregistrer' pour sauvegarder votre texte.",
    t_ch3_16: "Champ de saisie",
    d_ch3_16:
      "Ajoutez un champ de saisie à votre formulaire en le glissant dans la page, puis personnalisez-le selon vos besoins.",
    t_ch3_17: "Champ de saisie",
    d_ch3_17:
      "Faites glisser et déposez le champ de saisie dans l'espace vide de la page pour poursuivre la démonstration.",
    t_ch3_18: "Modale de champ de saisie",
    d_ch3_18:
      "Configurez facilement votre champ en accédant à la modale, où vous pouvez effectuer les ajustements nécessaires.",
    t_ch3_19: "Titre du champ",
    d_ch3_19:
      "Entrer le titre correspondant au champ que vous souhaitez intégrer dans votre formulaire.",
    t_ch3_20: "Placeholder",
    d_ch3_20:
      "Veuillez entrer le texte de l'indicateur de champ (placeholder).",
    t_ch3_21: "Configurer l'unité de mesure",
    d_ch3_21:
      "Choisissez d'activer ou de désactiver l'ajout d'une unité de mesure pour ce champ, offrant une personnalisation approfondie selon vos besoins spécifiques",
    t_ch3_22: "Configurer l'unité de mesure",
    d_ch3_22: "Activez l'unité de mesure pour poursuivre la démonstration,",
    t_ch3_23: "Configurer l'unité de mesure",
    d_ch3_23: "Veuillez entrer l'unité de mesure pour le champ de saisie.",
    t_ch3_24: "Paramètres du champ",
    d_ch3_24:
      "Configurer les paramètres du champ en ajustant les paramètres spécifiques.",
    t_ch3_25: "Activer/ désativer champ obligatoire",
    d_ch3_25:
      "Définissez si le champ est obligatoire ou facultatif en sélectionnant vos préférences.",
    t_ch3_26: "Message d'erreur",
    d_ch3_26:
      "Entrez le texte du message d'erreur qui s'affichera en cas d'activation de l'obligation de saisie du champ",
    t_ch3_27: "Exclusion du champ",
    d_ch3_27:
      "Activez ou désactivez l'exclusion du champ selon vos préférences. L'exclusion d'un essai clinique écarte certains participants en raison de critères prédéfinis tels que des conditions médicales spécifiques ou d'autres caractéristiques interférant avec les objectifs de l'essai ou la sécurité des participants.",
    t_ch3_28: "Paramètres avancés",
    d_ch3_28:
      "Validation avancée du champ texte: Nous explorerons les diverses options avancées disponibles pour valider les données saisies dans le champ texte. Ces paramètres offrent un contrôle approfondi sur la qualité et la conformité des informations entrées",
    t_ch3_29: "Paramètres avancés",
    d_ch3_29:
      "Pour configurer les paramètres avancés, cliquez sur l'icône (+).",
    t_ch3_30: "Paramètres avancés",
    d_ch3_30:
      "Un modal s'affiche pour vous permettre de configurer vos paramètres avancés",
    t_ch3_31: "Paramètres avancés",
    d_ch3_31:
      "Choisissez les options spécifiques pour personnaliser le champ selon vos besoins.",
    t_ch3_32: "Logique conditionnelle",
    d_ch3_32:
      "Optimisez la flexibilité de vos formulaires en utilisant le Champ Conditionnel. Personnalisez le comportement du champ en fonction de conditions spécifiques, permettant une adaptation dynamique aux besoins de l'utilisateur.",
    t_ch3_33: "Logique conditionnelle",
    d_ch3_33:
      "En utilisant des instructions SI/ALORS, notre système de formulaires intelligents personnalise dynamiquement les interactions, éliminant les saisies non nécessaires et offrant une expérience utilisateur plus fluide et efficace.",
    t_ch3_34: "Sauvegarde",
    d_ch3_34:
      "Après avoir effectué les configurations nécessaires, enregistrez vos paramètres en cliquant sur le bouton 'Enregistrer'",
    t_ch3_35: "Champ numérique",
    d_ch3_35:
      "Capturez des données chiffrées avec précision en utilisant ce champ",
    t_ch3_36: "Champ calculé",
    d_ch3_36:
      "Personnalisez dynamiquement vos données en explorant les méthodes de calcul, les fonctions disponibles, et les avantages pour une gestion de données optimisée et une précision accrue dans votre application.",
    t_ch3_37: "Champ de répétition",
    d_ch3_37:
      "Facilitez la collecte de données avec cette fonctionnalité permettant la saisie répétée",
    //Step4
    t_ch4_1: "",
    d_ch4_1:
      "Dans cette démo, découvrez comment utiliser la plateforme Ksearch en tant qu'investigateur.",
    t_ch4_2: "Section Étude",
    d_ch4_2: "Explorez la liste des études qui vous concernent.",
    t_ch4_3: "Informations sur l'Étude",
    d_ch4_3: "Consultez les informations complètes de l'étude sélectionnée.",
    t_ch4_4: "Mon Compte",
    d_ch4_4: "Gérez facilement votre compte.",
    t_ch4_5: "Informations du compte",
    d_ch4_5:
      "Consultez vos détails et les informations de votre compte en toute simplicité.",
    t_ch4_6: "Modifications",
    d_ch4_6:
      "Effectuez les changements souhaités sur votre compte en cliquant sur 'Modifier'.",
    t_ch4_7: "Formulaire de Modification",
    d_ch4_7:
      "Un formulaire s'affiche avec les informations déjà saisies, vous permettant de modifier et de ressaisir les données selon vos préférences.",
    t_ch4_8: "Prénom ",
    d_ch4_8: "Renseignez à nouveau le prénom souhaité",
    t_ch4_9: "Nom",
    d_ch4_9: "Renseignez à nouveau le nom souhaité.",
    t_ch4_10: "Date de Naissance",
    d_ch4_10: "Vérifiez et modifiez si nécessaire.",
    t_ch4_11: "Pays",
    d_ch4_11: "Modifiez votre pays en toute simplicité.",
    t_ch4_12: "Enregistrement",
    d_ch4_12:
      "Sauvegardez vos modifications en cliquant sur le bouton 'Enregistrer'.",
    t_ch4_13: "Sécurité",
    d_ch4_13: "Protégez votre compte en activant l'authentification à facteurs multiples.",
    t_ch4_14: "Etude",
    d_ch4_14: "Sélectionnez l'étude de votre choix.",
    //Step5
    t_ch5_1: "",
    d_ch5_1:
      "Après avoir sélectionné l'étude, explorez en détail les informations qui lui sont spécifiques",
    t_ch5_2: "Section participants",
    d_ch5_2: "Explorez la liste des participants de l'étude sélectionnée.",
    t_ch5_3: "Section participants",
    d_ch5_3:
      "Explorez la liste des participants que vous avez ajoutés à l'étude sélectionnée. Vous avez également la possibilité d'ajouter un participant directement en utilisant le bouton (+ participant).",
    t_ch5_4: "Test mode",
    d_ch5_4: "Activez le mode test pour pouvoir ajouter un participant.",
    t_ch5_5: "Ajouter un participant",
    d_ch5_5: "Utilisez ce bouton pour créer un nouveau participant.",
    t_ch5_6: "Ajouter un participant ",
    d_ch5_6:
      "Ce formulaire se remplit automatiquement et affiche le nom du centre ainsi que l'ID du participant. Pour ajouter le participant correspondant à l'ID indiqué, cliquez sur 'Enregistrer'.",
    t_ch5_7: "Centre",
    d_ch5_7:
      "Le nom du centre de l'étude sélectionnée est affiché automatiquement.",
    t_ch5_8: "Identifiant public ",
    d_ch5_8: " L'identifiant public est affiché automatiquement",
    t_ch5_9: "Enregistrer",
    d_ch5_9:
      "Pour ajouter le participant correspondant à l'ID indiqué, cliquez sur 'Enregistrer'.",
    t_ch5_10: "Section requêtes",
    d_ch5_10:
      "Dans cette section, vous trouverez les demandes ou questions formulées de manière structurée, généralement émises par le CRO ou l'ARC, visant à obtenir des informations spécifiques dans le cadre de la gestion de l'étude.",
    t_ch5_11: "Type des requêtes",
    d_ch5_11: "Sélectionnez le type de requête souhaité.",
    t_ch5_12: "Liste des requêtes",
    d_ch5_12:
      "La liste des requêtes est affichée en fonction du statut de requête sélectionné.",
    t_ch5_13: "Section événements",
    d_ch5_13:
      "Dans cette section, explorez les événements indésirables et les appels pour examiner les incidents non souhaités ainsi que les occurrences régulières liées à l'étude.",
    t_ch5_14: "Type d'événement",
    d_ch5_14: "Choisissez le type d'événement souhaité.",
    t_ch5_15: "Liste des événements",
    d_ch5_15:
      "La liste des événements est affichée en fonction du statut sélectionné",
    t_ch5_16: "",
    d_ch5_16: "Sélectionner un participant afin de remplir son formulaire",
    //Step6
    t_ch6_1: "",
    d_ch6_1:
      "Aprés avoir selectionné le participant ! Découvrez en détails les formulaires et les événements.",
    t_ch6_2: "Section formulaire",
    d_ch6_2: "Explorez la liste des formulaires/visites du participant.",
    t_ch6_3: "Formulaire/Visite",
    d_ch6_3: "Sélectionner le premier formulaire/visite à remplir",
    t_ch6_4: "Formulaire/Visite",
    d_ch6_4:
      " Le formulaire sélectionné est affiché, veullier le remplir",
    t_ch6_5: "Remplissage du formulaire",
    d_ch6_5:
      "Nous vous montrons un exemple du premier champ à remplir. Vous devrez répéter cette étape pour tous les autres champs vides.",
    t_ch6_6: "Enregistrement",
    d_ch6_6:
      "Après avoir rempli le formulaire, cliquez sur le bouton 'Terminer' pour enregistrer les informations que vous avez saisies.",
    t_ch6_7: "Section Événements",
    d_ch6_7:
      "Dans cette section, vous pouvez ajouter les événements souhaités à votre formulaire.",
    t_ch6_8: "",
    d_ch6_8: "Pour ajouter un nouveau événement cliquez sur l'icone (+)",
  },
  en: {
    btnSkip: "Skip",
    btnDone: "Done",
    btnNext: "Next",
    t_ch1_1: "New study",
    d_ch1_1: "To start, click on  'New Study' button to initiate  first step.",
    t_ch1_2: "New Study",
    d_ch1_2:
      "You are in the process of setting up a new study, and we invite you to complete this form",
    t_ch1_3: "Study name",
    d_ch1_3: "Enter name your study in designated field.",
    t_ch1_4: "Study description",
    d_ch1_4: "Add a brief description   study in  corresponding field.",
    t_ch1_5: "Registration",
    d_ch1_5:
      "Then, click on this button to save  information you have provided.",
    t_ch1_6: "Anonymize  study",
    t_ch1_7: "Anonymize  study",
    d_ch1_6:
      "The anonymization of the study means that the names and first names of the participants will not be recorded in the database, thus preserving their confidentiality.",
    d_ch1_7:
      "You have the option to select the 'anonymize' or 'do not anonymize' button. In this demonstration, we have chosen an anonymized study.",

    //step2

    t_ch2_1: "Study configuration",
    d_ch2_1:
      "The study configuration page allows you to define and adjust essential parameters for your study. Use this page to customize and optimize configurations to best suit your needs.",
    t_ch2_2: "General configuration",
    d_ch2_2:
      "In this section, you have the opportunity to customize the general parameters of your study by making changes according to your needs.",
    t_ch2_3: "Study details",
    d_ch2_3: "Edit study details by modifying study name and description",
    t_ch2_4: "Anonymize study",
    d_ch2_4: "Anonymize the study easily with the switch button",
    t_ch2_5: "Randomize study",
    d_ch2_5: "Randomize data with one click of the switch button",
    t_ch2_6: "Access to participants",
    d_ch2_6:
      "Activate or deactivate Participant Access open by center using the switch button",
    t_ch2_7: "E-pro",
    d_ch2_7: "Enable or disable E-Pro functionality using the switch button",
    t_ch2_8: "Centers",
    d_ch2_8: "In this section, you can manage your centers",
    t_ch2_9: "New center",
    d_ch2_9: "Add a new center by filling in the form",
    t_ch2_10: "Center name",
    d_ch2_10: "Enter center name",
    t_ch2_11: "Center address",
    d_ch2_11: "Enter center address",
    t_ch2_12: "Center abbreviation",
    d_ch2_12: "Enter center abbreviation",
    t_ch2_13: "Create center",
    d_ch2_13: "Now click on 'save'",
    t_ch2_14: "Center details",
    d_ch2_14: "In this map, you can view the center details you entered",
    t_ch2_15: "Profiles",
    d_ch2_15: "In this section, you can manage profiles",
    t_ch2_16: "Profile creation",
    d_ch2_16: "Add a profile for your investigators by filling in the form.",
    t_ch2_17: "Profile name",
    d_ch2_17: "Enter profile name",
    t_ch2_18: "Create profile",
    d_ch2_18: "Click on 'save'",
    t_ch2_19: "Edit profile",
    d_ch2_19: "Edit your profile by clicking once on the corresponding input",
    t_ch2_20: "",
    d_ch2_20: "Click once on the profile name",
    t_ch2_21: "",
    d_ch2_21: "Enter the desired profile name",
    t_ch2_22: "",
    d_ch2_22: "Save your changes",
    t_ch2_23: "Manage members",
    d_ch2_23:
      "To add study members, access the 'Members' section and follow the instructions to include new arcs or investigators",
    t_ch2_24: "Add ARC",
    d_ch2_24:
      "Visit the 'My Team' tab to access the list and details of all your team members.",
    t_ch2_25: "Add an investigator",
    d_ch2_25:
      "Access the 'Investigator' tab to view details and manage members of the investigation team",
    t_ch2_26: "Add a sponsor",
    d_ch2_26:
      "Visit the 'Sponsor' tab to access details and manage members of the study sponsor team.",
    t_ch2_27: "Invitations Sponsor",
    d_ch2_27:
      "Explore the 'Invitations' tab to manage invitations to study members and track their participation status.",
    t_ch2_28: "New Investigator",
    d_ch2_28: "To add an investigator, click on the 'Add Investigator' button",
    t_ch2_29: "New investigator",
    d_ch2_29:
      "Use the form to add an investigator by filling in the required information and clicking on the add button.",
    t_ch2_30: "First name",
    d_ch2_30: "Enter the investigator's first name in the field provided",
    t_ch2_31: "Lastname",
    d_ch2_31: "Enter the lastname of the investigator in the field provided",
    t_ch2_32: "E-mail",
    d_ch2_32: "Enter the investigator's e-mail address in the dedicated field",
    t_ch2_33: "Profile",
    d_ch2_33: "Select profile from drop-down list",
    t_ch2_34: "Center",
    d_ch2_34: "Select center from drop-down list",
    t_ch2_35: "Save",
    d_ch2_35: "Click on the 'Save' button to save the information",
    t_ch2_36: "Visits & forms",
    d_ch2_36:
      "Our platform simplifies the process into three key sections: Create customized forms, plan and manage your visits efficiently ,analyze collected data and events",
    t_ch2_37: "Visit forms",
    d_ch2_37:
      "Optimize your visit process with our intuitive and organized form",
    t_ch2_38: "Recurring event",
    d_ch2_38: "Explore recurring events by clicking on the dedicated tab",
    t_ch2_39: "Create Form",
    d_ch2_39: "Create your form by clicking on the 'Create Form' button",
    t_ch2_40: "Create Form",
    d_ch2_40:
      "A modal is displayed, allowing you to fill in the fields required to add your form.",
    t_ch2_41: "Form name",
    d_ch2_41: "Name your form (Visit)",
    t_ch2_42: "Form description",
    d_ch2_42: "Specify the description of your form (Visit)",
    t_ch2_43: "Profile attached",
    d_ch2_43: "Select the attached profile from the drop-down list",
    t_ch2_44: "Save form",
    d_ch2_44: "Save your form by clicking on the 'Create form' button",

    //step 3
    t_ch3_1: "Page Configuration",
    d_ch3_1:
      "Customize the first section of your form (defaultly titled 'Page 1') by adjusting the name and settings according to your preferences.",
    t_ch3_2: "Page Configuration",
    d_ch3_2:
      "Clicking on the tab (+) allows you to easily add the desired number of sections for optimal customization.",
    t_ch3_3: "Page Configuration",
    d_ch3_3:
      "By clicking on this configuration icon, you can easily set up the necessary configurations for your section according to your needs.",
    t_ch3_4: "Page Configuration",
    d_ch3_4:
      "After clicking on the configuration icon, a modal appears with default configurations, allowing you to make desired changes effortlessly.",
    t_ch3_5: "Page Name",
    d_ch3_5: "Enter the desired name for your page.",
    t_ch3_6: "Conditional Logic",
    d_ch3_6: "Enable or disable conditional logic for your page.",
    t_ch3_7: "Save",
    d_ch3_7:
      "To save the changes made to the settings, click the 'Save' button.",
    t_ch3_8: "Section Order",
    d_ch3_8:
      "Customize the order of pages according to your needs by clicking on this icon.",
    t_ch3_9: "Toolbox",
    d_ch3_9:
      "This left section contains all the fields necessary to customize your form. Drag the desired field into the page and make modifications as needed.",
    t_ch3_10: "Input Fields",
    d_ch3_10:
      "The 'Input Fields' section in a form represents the dedicated areas where users can enter and submit specific information.",
    t_ch3_11: "Informational Fields",
    d_ch3_11:
      "'Informational Fields' refer to areas intended for input or display of information in a form or interface.",
    t_ch3_12: "",
    d_ch3_12:
      "In this toolbox section, you will find elements such as title, comment, separator, and image that you can add to your customized form.",
    t_ch3_13: "Title",
    d_ch3_13:
      "Drag and drop the title field into the empty space of the page to continue the demonstration.",
    t_ch3_14: "",
    d_ch3_14:
      "In this text content, you can write the desired title or text and apply the text style of your choice.",
    t_ch3_15: "",
    d_ch3_15: "Click 'Save' to save your text.",
    t_ch3_16: "Input Field",
    d_ch3_16:
      "Add an input field to your form by dragging it into the page, then customize it as needed.",
    t_ch3_17: "Input Field",
    d_ch3_17:
      "Drag and drop the input field into the empty space of the page to continue the demonstration.",
    t_ch3_18: "Input Field Modal",
    d_ch3_18:
      "Easily configure your field by accessing the modal, where you can make necessary adjustments.",
    t_ch3_19: "Field Title",
    d_ch3_19:
      "Enter the title corresponding to the field you want to integrate into your form.",
    t_ch3_20: "Placeholder",
    d_ch3_20: "Please enter the field placeholder text.",
    t_ch3_21: "Configure Unit of Measurement",
    d_ch3_21:
      "Choose to enable or disable adding a unit of measurement for this field, offering in-depth customization according to your specific needs.",
    t_ch3_22: "Configure Unit of Measurement",
    d_ch3_22: "Enable the unit of measurement to continue the demonstration.",
    t_ch3_23: "Configure Unit of Measurement",
    d_ch3_23: "Please enter the unit of measurement for the input field.",
    t_ch3_24: "Field Settings",
    d_ch3_24: "Configure field settings by adjusting specific parameters.",
    t_ch3_25: "Enable/Disable Required Field",
    d_ch3_25:
      "Set whether the field is required or optional by selecting your preferences.",
    t_ch3_26: "Error Message",
    d_ch3_26:
      "Enter the error message text that will appear if the field input requirement is activated.",
    t_ch3_27: "Field Exclusion",
    d_ch3_27:
      "Enable or disable field exclusion according to your preferences. Exclusion from a clinical trial excludes certain participants due to predefined criteria such as specific medical conditions or other characteristics interfering with the trial's objectives or participant safety.",
    t_ch3_28: "Advanced Settings",
    d_ch3_28: "",
    t_ch3_29: "Advanced Settings",
    d_ch3_29: "To configure advanced settings, click on the icon (+).",
    t_ch3_30: "Advanced Settings",
    d_ch3_30:
      "A modal appears to allow you to configure your advanced settings.",
    t_ch3_31: "",
    d_ch3_31: "",
    t_ch3_32: "",
    d_ch3_32: "",
    t_ch3_33: "",
    d_ch3_33: "",
    t_ch3_34: "",
    d_ch3_34: "",
    t_ch3_35: "",
    d_ch3_35: "",
    t_ch3_36: "",
    d_ch3_36: "",
  },
};
