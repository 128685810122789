import {
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { MdErrorOutline } from "react-icons/md";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { userAtom } from "../../../state/auth";
import { FormGroup } from "./FormGroup";
import { formulaValues } from "../../../state/FormGeneratorState/fomulaValuesAtom";

const _ = require("lodash");
export const CalculatedInput = (props) => {
  console.log("🚀 ~ CalculatedInput ~ props:", props)
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessages,
    otherProps,
  } = useField(props);

  const { required, name, calculationConfig, form, dependencys,decimalPlaces } = props;

  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const [isValidation, setIsValidation] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const [user] = useAtom(userAtom);
  const [formulaValuesAtom, setFormulaValuesAtom] = useAtom(
    formulaValues
  ) as any;
  const formGroupProps = {
    errorMessage,
    helper: isTouched ? helper : "",
    id,
    isRequired: !!required,
    label,
    showError,
    name,

    ...rest,
  };

  function extractExpression2(arr: any[]) {
    console.log(
      "🚀 ~ file: CalculatedInput.tsx:51 ~ extractExpression2 ~ arr:",
      arr
    );
    let expression = "";
    if (form) {
      for (const item of arr) {
        console.log(
          "🚀 ~ file: CalculatedInput.tsx:60 ~ extractExpression2 ~ item:",
          item
        );
        if (item.isOperator || item.value === "(" || item.value === ")") {
          expression += item.value;
        } else {
          let value = item.value;
          if (isNaN(Number(value))) {
            value =
              form.values && form.values[`${item.value}`]
                ? form.values[`${item.value}`]
                : undefined;
          }
          if (!value) {
            let findedItem: any;
            if (formulaValuesAtom && formulaValuesAtom.formulaValuesMap) {
              formulaValuesAtom.formulaValuesMap.map((fv: any) => {
                if (item.value === fv.key) {
                  findedItem = fv;
                  console.log(
                    "🚀 ~ file: CalculatedInput.tsx:65 ~ formulaValuesAtom.formulaValuesMap.map ~ findedItem:",
                    findedItem
                  );
                }
              });
              value = findedItem?.value;
            }
          }
          if (!value) {
            expression = "";
            break;
          }
          if (item.type === "DatePicker" && value) {
            const formattedDate = new Date(value).toISOString();
            const oneDayInMilliseconds = 1000*60*60*24;
            value = Math.round(Date.parse(formattedDate)/oneDayInMilliseconds);
          }
          console.log(
            "🚀 ~ file: CalculatedInput.tsx:90 ~ extractExpression2 ~ value:",
            value
          );
          expression += value;
        }
      }
    }

    try {
      // check if the expression is valid
      eval(expression);
      return expression;
    } catch (e) {
      console.error(`Invalid expression: ${expression}`);
      return null;
    }
  }


function calculateExpression(expression: string) {
  let result = eval(expression);
  if (result === null || isNaN(result)) {
      return null;
  }
  const resultString = result.toString();
  if (!resultString.includes('.')) {
      return resultString;
  }
  const [wholePart, decimalPart] = resultString.split('.');
  if (decimalPart.length <= props.decimalPlaces) {
      return resultString;
  }
  return parseFloat(result.toFixed(props.decimalPlaces)).toString();
}
  function extractExpression(arr: any[]) {
    console.log(
      "🚀 ~ file: CalculatedInput.tsx:89 ~ extractExpression ~ arr:",
      arr
    );
    let expression = "";

    if (form) {
      for (const item of arr) {
        if (item.isOperator || item.value === "(" || item.value === ")") {
          expression += item.value;
        } else {
          let value = item.value;
          if (isNaN(Number(value))) {
            value =
              form.values && form.values[`${item.value}`]
                ? form.values[`${item.value}`]
                : undefined;
          }
          expression += value;
        }
      }
    }
    try {
      // check if the expression is valid
      eval(expression);
      return expression;
    } catch (e) {
      console.error(`Invalid expression: ${expression}`);
      return null;
    }
  }
  useEffect(() => {
    const expression = extractExpression2(calculationConfig);
    console.log(
      "🚀 ~ file: CalculatedInput.tsx:94 ~ useEffect ~ expression",
      expression
    );
    if (expression && expression !== "") {
      if (value !== calculateExpression(expression)) {
        setValue(calculateExpression(expression));
        setIsTouched(true);

        setIsValidation(false);
        if (props?.onSumbit && user?.type === "writer")
          props?.onSumbit({
            fieldName: name,
            value: calculateExpression(expression),
            isValid: isValid,
          });
      }
    }
  }, [dependencys, formulaValuesAtom]);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);
  useEffect(() => {
    console.log(
      "🚀 ~ file: CalculatedInput.tsx:112 ~ CalculatedInput ~ formulaValuesAtom:",
      formulaValuesAtom
    );
  }, [formulaValuesAtom]);

  return (
    <FormGroup {...formGroupProps}>
      {/* {user?.type !== "writer" && !props?.isPreview && (
        <p className="text-xs text-red-300">
          {" "}
          {!isValid && !isValidation && errorMessages && errorMessages[0]}
        </p>
      )} */}
      <InputGroup>
        {rest.hasUnit && rest.hasUnit?.active && (
          <InputRightElement className="p-0.5 text-xs " p={0.5} fontSize={12}>
            {rest.hasUnit.unit}
          </InputRightElement>
        )}

        <Input
          borderWidth={props?.readOnly ? 2 : 1}
          disabled={true}
          _disabled={{ textColor: "black" }}
          type={"text"}
          id={id}
          value={value ?? ""}
          fontSize="14px"
          _invalid={{ borderColor: "Red" }}
          className=" focus:border-blue-500 focus:shadow-sm w-full "
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : undefined}
          placeholder={placeholder}
        />
      </InputGroup>
      {!props?.isPreview && user?.type === "writer" && (
        <div className="absolute -left-8 top-1/2">
          {" "}
          {isValidation && (
            <p>
              <Spinner size="sm" flex="none" color="blue.200" />
            </p>
          )}
          <p>
            {" "}
            {isValid && !isValidation && (
              <HiCheckCircle color="#67d428" width={20} height={20} />
            )}
            {!isValid && !isValidation && (
              <MdErrorOutline color="#ff0000" width={20} height={20} />
            )}
          </p>
        </div>
      )}
      {children}
    </FormGroup>
  );
};
