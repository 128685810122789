import { Redirect, RouteProps } from "react-router";
import Signin from "../pages/Signin";
import MyComponent from "../pages/signup";
import ConfirmEmail from "../pages/ConfirmEmail";

import ForgetPassword from "../pages/ForgetPassword";
import InvitationPage from "../pages/InvaitationPage";
import MultiFactorAuthentication from "../pages/MultiFactorAuthentication";
import EproForms from "../pages/Epro";
import PublicParticipantRoom from "../pages/Econsult/SimplePeer";
import ThanksPageForm from "../pages/ThanksPageForm";
const forceRedirect = () => {
  switch (window.location.origin) {
    case process.env.REACT_APP_KATOMI_DOCTOR_ENTRY_URL:
      return doctorGuestRoutes;
    default:
      return guestRoutes;
  }
};

const guestRoutes: RouteProps[] = [
  {
    path: "/login",
    component: Signin,
    exact: true,
  },

  {
    path: "/confirm-email/:email",
    component: ConfirmEmail,
    exact: true,
  },
  {
    path: "/epro/:formId/:tenantId/:token",
    component: EproForms,
    exact: true,
  },

  {
    path: "/verify-user",
    component: MultiFactorAuthentication,
    exact: true,
  },
  {
    path: "/register",
    component: MyComponent,
    exact: true,
  },
  {
    path: "/invitationPage/:tenantId/:studyId",
    component: InvitationPage,
    exact: true,
  },

  {
    path: "/forgetPassword",
    component: ForgetPassword,
    exact: true,
  },
  {
    path: "/eConsult/:studyId/:eConsultId/:participantId/:tenantId/:token",
    component: PublicParticipantRoom,
    exact: true,
  },
  {
    path: "/thankyou",
    component: ThanksPageForm,
    exact: true,
  },

  { render: () => <Redirect to="/login" /> },
];

const doctorGuestRoutes: RouteProps[] = [
  {
    path: "/login",
    component: Signin,
    exact: true,
  },
  {
    path: "/confirm-email/:email",
    component: ConfirmEmail,
    exact: true,
  },
  {
    path: "/register",
    component: MyComponent,
    exact: true,
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
    exact: true,
  },
  { render: () => <Redirect to="/login" /> },
];

export default forceRedirect();
