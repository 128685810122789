import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { getDashboardData_01 } from "../../api/study";
import ProgressRing from "../../components/ProgressRing";
import moment from "../../helper/moment";
import { testModeAtom } from "../../state/testModeAtom";

const DynamicCard = () => {
  const { t } = useTranslation() as any;
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({}) as any;
  const [testMode] = useAtom(testModeAtom);
  const params = useParams() as any;
  const { studyId } = params;
  useEffect(() => {
    setIsLoading(true);
    getDashboardData_01(studyId, testMode).then((result) => {
      if (result && result?.status === "success") {
        setStats(result?.response);

        // setOptionStat(dropDownOptions);
        // if (dropDownOptions?.length > 0) {
        //   setSelectedOptionFirst(dropDownOptions[0]?.value);
        // }
        setIsLoading(false);
      }
    });
  }, [studyId, testMode]);
  return isLoading ? (
    <div className="flex gap-9  ">
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-4 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-4 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-4 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-9  ">
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-4">
        <AiOutlineUserAdd className="color-primary-blue" size={50} />
        <div className="">
          <p className="font-bold mb-2">
            <span className="color-primary-blue font-bold text-lg">
              {stats?.participant?.participantNumber
                ? stats?.participant?.participantNumber
                : ""}
            </span>
            {"  "}
            {t("missingWording.card1")}
          </p>

          {stats?.participant?.participantNumber !== 0 ? (
            <p className="font-bold mb-2 color-primary-grey text-xs">
              {t("missingWording.subCard1")}{" "}
              <span className="color-primary-blue text-xs">
                {stats?.participant?.lastCreatedAt?.createdAt
                  ? moment(stats?.participant?.lastCreatedAt?.createdAt).format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </span>
            </p>
          ) : null}
        </div>
      </div>

      <div
        className="
        flex items-center bg-white shadow-lg rounded-lg pr-7 flex-1"
      >
        <div className="">
          <ProgressRing
            percent={stats?.percentageExitParticipant || 0}
            borderColor="color-secondary-lila-pastelPink"
            size={80}
          />{" "}
        </div>
        <p className="ml-3 font-bold">{t("missingWording.card7")}</p>
      </div>
      <div
        className="
        flex items-center bg-white shadow-lg rounded-lg pr-7 flex-1"
      >
        <div className="">
          <ProgressRing
            percent={stats?.percentageInactiveInvestigators || 0}
            borderColor="color-secondary-lila"
            size={80}
          />{" "}
        </div>
        <p className="ml-3 font-bold">{t("missingWording.card8")}</p>
      </div>
    </div>
  );
};
export default DynamicCard;
