export const Visit = {
  fr: {
    Visit: "Visites",
    NewVisit: "Nouvelle visite",
    VisitStudy: "Aucune visite pour cette étude",
  },
  en: {
    Visit: "Visits",
    NewVisit: "New visit",
    VisitStudy: "No visit for this study",
  },
};
