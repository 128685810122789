import React, { useEffect, useRef } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  stream: MediaStream;
  videoProps: React.MediaHTMLAttributes<HTMLVideoElement>;
  labelProps?: React.HTMLAttributes<HTMLParagraphElement>;
}

const RemoteVideoStream = ({
  label,
  stream,
  videoProps,
  labelProps,
  ...containerProps
}: Props) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.srcObject = stream;
  }, [stream]);

  return (
    <div {...containerProps}>
      <video ref={ref} {...videoProps} />
      <p {...labelProps}>
        {label}
      </p>
    </div>
  );
};

export default RemoteVideoStream;
