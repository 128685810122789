import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getStudyRoles } from "../../api/study";
import { duplicateSurvey } from "../../api/survey";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import makeToast from "../../components/Snackbar";
import { addSharedObjectToFront } from "../../components/CreationSteps";

type Props = any;
const DuplicationModal: React.FC<Props> = ({
  visteList,
  surveyDetails,
  setShowDuplicationModal,
  setRefresh,
  randomConfig,
}) => {
  console.log("🚀 ~ randomConfig:", randomConfig);
  const params: any = useParams();
  const { studyId } = params;
  const { t } = useTranslation();
  const [step] = useState(1);
  const [type] = useState("Classic");
  const [name, setName] = useState(
    `${surveyDetails?.name} ${t("missingWording.copy")}`
  );
  const [isShared, setIsShared] = useState<boolean>(true);
  const [associatedGroup, setAssociatedGroup] = useState(null) as any;
  const [associatedGroupError, setAssociatedGroupError] = useState("");

  const [desc, setDesc] = useState("");
  const [role, setRole] = useState("");
  const [dependency, setDependency] = useState("");
  const [groups, setGroups] = useState(
    randomConfig
      ? randomConfig.groups.map((g: any) => {
          return {
            label: `${g?.groupName}`,
            value: g?._id,
          };
        })
      : [{ label: t("missingWording.inpXAn"), value: "null" }]
  ) as any[];
  const [listRolesProps, setListRolesProps]: any[] = useState([]);

  async function fetchMyAPI(): Promise<void> {
    // setLoading(true)
    const roles = await getStudyRoles(studyId);
    setListRolesProps(
      roles
        ? roles.roles?.map((r) => {
            return { value: r?._id, label: r?.name };
          })
        : []
    );
  }

  useEffect(() => {
    fetchMyAPI();
  }, []);
  useEffect(() => {
    if (randomConfig) {
      setGroups(
        randomConfig.groups.map((g: any) => {
          return {
            label: `${g?.groupName}`,
            value: g?._id,
          };
        })
      );
    }
  }, [randomConfig]);
  const duplicateSurveyCall = async () => {
    try {
      if (role === "") {
        return makeToast("warning", t("missingWording.fdt1"));
      }
      if (name === "") {
        return makeToast("warning", t("missingWording.fdt2"));
      }

      let payload = {
        ePro: false,
        roleId: role,
        type: type,
        dependency: dependency,
        name: name,
        description: desc,
        surveyId: surveyDetails?._id,
        associatedGroup: randomConfig ? associatedGroup : null,
        shared: randomConfig ? isShared : true,
      };
      const duplictatedFom = await duplicateSurvey(studyId, payload);

      if (duplictatedFom && duplictatedFom?.newSurvey) {
        makeToast("success", t("missingWording.fdt3"));
        setShowDuplicationModal(false);
        setRefresh((refresh) => !refresh);
      } else {
        makeToast("error", "");
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  return (
    <>
      {type !== "" && step == 1 && (
        <>
          <div className="flex-auto py-7 px-8 min-w-full	">
            <div className="flex flex-wrap items-baseline">
              <h1 className="mb-10 text-center">
                {t("missingWording.dupVisit")} {surveyDetails?.name}
              </h1>
            </div>

            <div className="">
              <Input
                id="name"
                className="w-full"
                placeholder={t("missingWording.visitName")}
                labelText={t("missingWording.visitName")}
                type="text"
                value={name}
                onBlur={(e) => setName(e.target.value)}
                onChange={(e) => setName(e.target.value)}
                inlineLabel={false}
                onfocus={true}
                style={{ maxHeight: "50px" }}
              />
            </div>
            <div className="">
              <Input
                id="desc"
                className="w-full"
                placeholder={t("missingWording.fdi1")}
                labelText={t("missingWording.fdi1")}
                type="text"
                style={{ maxHeight: "50px" }}
                onBlur={(e) => setDesc(e.target.value)}
                onChange={(e) => setDesc(e.target.value)}
                inlineLabel={false}
                onfocus={true}
              />
            </div>
            <div className=" mb-3">
              <SelectComp
                id="roleInvestigateur"
                onChange={(e) => {
                  setRole(e.target.value);
                  return;
                }}
                //   disabled={!listRolesProps || listRolesProps.length === 0}
                className="min-w-full"
                text={
                  listRolesProps && listRolesProps.length > 0
                    ? `${t("missingWording.fdi2")}`
                    : `${t("missingWording.fdi3")}`
                }
                options={listRolesProps}
              ></SelectComp>
            </div>
            {visteList.length > 0 && (
              <div className="mb-3">
                <SelectComp
                  id="dependency"
                  onChange={(e) => {
                    setDependency(e.target.value);
                    return;
                  }}
                  disabled={!visteList || visteList.length === 0}
                  className="min-w-full"
                  text={
                    visteList.length > 0
                      ? `${t("missingWording.fdi4")}`
                      : `${t("missingWording.fdi5")}`
                  }
                  options={visteList?.map((e) => {
                    return { value: e?._id, label: e?.name };
                  })}
                ></SelectComp>
              </div>
            )}
            {randomConfig && randomConfig?.groups && (
              <div className="mx-4 my-3 items-center  ">
                <SelectComp
                  id="associatedGroup"
                  onChange={(e) => {
                    setAssociatedGroupError("");
                    if (e.target.value === "shared" || e.target.value === "") {
                      setIsShared(true);
                      setAssociatedGroup(null);
                    } else {
                      setIsShared(false);
                      setAssociatedGroup(e.target.value);
                    }
                    return;
                  }}
                  // labelText={t("missingWording.inpXALabel")}
                  disabled={!groups || groups.length === 0}
                  className="min-w-full mb-8"
                  text={`${groups.length > 0
                    ? t("missingWording.inpXA")
                    : t("missingWording.inpXAn")}`}
                  options={addSharedObjectToFront(groups, t)}
                ></SelectComp>
                {associatedGroupError && (
                  <p className="text-red-300 text-sm ml-4 mt-3">
                    {associatedGroupError}
                  </p>
                )}
              </div>
            )}
            <div className="flex  justify-between mt-8">
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setShowDuplicationModal(false)}
              >
                {t("Investigator.Cancel")}
              </Button>
              <Button
                disabled={name === "" || role === ""}
                status="primary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                onClick={async () => {
                  await duplicateSurveyCall();
                }}
              >
                {t("missingWording.duplicate")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DuplicationModal;
