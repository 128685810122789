import moment from "../../helper/moment";
import history from "../../history";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import Button from "../../components/ButtonUI";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { useTranslation } from "react-i18next";

type Props = {
  patientId: string;
  progress: any;
  motif: string;
  date: any;
  studyId: string;
  patientPublicId: string;
  eConsultId: any;
  eConsult: any;
};

// patientId
const EconsultSoonCard = (props: Props) => {
  const {
    patientPublicId,
    progress,
    motif,
    date,
    studyId,
    eConsultId,
    eConsult,
    patientId,
  } = props;
  const [user] = useAtom(userAtom);
  const { t } = useTranslation();
  const _calculParticipantProgress = (p: any) => {
    return p ? Math.round(parseFloat(p)) : 0;
  };

  return (
    <div className="bg-white shadow-xl rounded-xl w-full pt-3 pb-5 ">
      <div className="p-3 flex gap-7">
        <div className=" rounded-full bg-yellow-200 md:w-16 xs:w-10 md:h-16 xs:h-10">
          {"male" === "male" ? (
            <ManPatient className="md:w-16 xs:w-10" />
          ) : (
            <WomanPatient className="md:w-16 xs:w-10" />
          )}
        </div>
        <div className=" w-full">
          <div className="">
            {" "}
            {user?.type === "writer" ? (
              <>
                {" "}
                Participant <span className="font-bold">{patientPublicId}</span>
              </>
            ) : (
              <>
                Dr. <span className="font-bold">{patientPublicId}</span>
              </>
            )}
          </div>
          {user?.type === "writer" && (
            <div className="w-full flex gap-3 items-center">
              {" "}
              {progress ? Math.round(parseFloat(progress)) : 0}%{" "}
              <div className="w-full md:block xs:hidden bg-gray-300 rounded-full dark:bg-gray-700  h-2   ">
                <div
                  className={
                    " text-xs font-medium text-white text-center p-0.5 leading-none h-2 rounded-full  " +
                    (_calculParticipantProgress(progress) > 0
                      ? "bg-blue-600 relative "
                      : "")
                  }
                  style={{
                    width: `${
                      _calculParticipantProgress(progress) > 0
                        ? _calculParticipantProgress(progress)
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          )}
          <div className="mt-5 bg-gray-100 max-w-max px-3 rounded-3xl">
            <div className="">
              <span className="font-bold text-xs" style={{ color: "#9FA3AF" }}>
                {motif}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mx-4">
        <p className="">
          {t("eConsult.planned")}{" "}
          <span className="font-bold">{moment(date).format("HH:mm")}</span>
        </p>
        <Button
          width="135px"
          height="35px"
          onClick={() =>
            history.push({
              pathname: `/eConsult/${studyId}/${eConsultId}/${
                patientId || user?._id
              }`,
              state: {
                eConsult: eConsult,
              },
            })
          }
        >
          {t("eConsult.start")}{" "}
        </Button>
      </div>
    </div>
  );
};

export default EconsultSoonCard;
