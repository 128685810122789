import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiDownload } from "react-icons/bi";

import jwt from "jsonwebtoken";
import {
  RiUserFollowLine,
  RiUserSharedLine,
  RiUserStarLine,
  RiUserUnfollowLine,
} from "react-icons/ri";
import { useParams } from "react-router-dom";
import TestModeTogButton from "../../components/TestModeShared";

import { resendInvitation } from "../../api/eproForms";
import { downloadEProFormPdf, downloadParticipantPdf } from "../../api/study";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import makeToast from "../../components/Snackbar";
import Tooltip from "../../components/toolTip";
import history from "../../history";
import { participantAtom } from "../../state/participant";
import { IParticipant } from "../../types/IParticipant";

type Props = {
  data: IParticipant[] | any;
  userType: string;
  currentPage: any;
  setListEproForms: any;
  setCurrentPage: any;
  totalPages: any;
  showName?: boolean;
  hasCenterAcess?: boolean;
  isResponsable?: boolean;
  loading?: boolean;
};
const Table: React.FC<Props | any> = ({
  data,
  userType,
  showName,
  hasCenterAcess,
  isResponsable,
  setTextSearch,
  loading,
  setListEproForms,
}) => {
  const { t } = useTranslation() as any;
  const params = useParams() as any;

  const [, setParticipant] = useAtom(participantAtom);
  const [searchValue, setSearchValue] = useState("");
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [selectedEproForm, setSelectedEproForm] = useState({}) as any;
  const { studyId } = params;
  const typingTimeoutRef = useRef(null) as any;

  const _handleDownloadPdf = async (eProFormId) => {
    try {
      const blob = await downloadEProFormPdf(eProFormId);
      const outputFilename = `${Date.now()}.pdf`;
      const url = URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(
        "🚀 ~ file: index.tsx:73 ~ const_handleDownloadExcel= ~ error",
        error
      );
    }
  };

  const validToken = (token) => {
    if (token) {
      const decodedToken = jwt.decode(token?.split("/")?.at(-1));
      if (decodedToken) {
        console.log(decodedToken.expiresIn);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        // Check if the token has not expired
        if (
          decodedToken.expiresIn &&
          decodedToken.expiresIn > currentTimestamp
        ) {
          return true;
        } else {
          return false;
        }
        // Add more claims as needed
      } else return false;
    } else return false;
  };

  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };

  const _calculParticipantProgress = (p: any) => {
    return p.progress ? Math.round(parseFloat(p.progress)) : 0;
  };
  const rederStatusIcon = (status: String, progress: any) => {
    let statusList = {
      PROGRESS: t("missingWording.Encours"),
      EXIT: t("missingWording.Exclu"),
      CREATED: t("missingWording.Inscrit"),
    };
    switch (status) {
      case "VALID":
        return (
          <Tooltip tooltipText={t("missingWording.acheved")} position={"top"}>
            <RiUserFollowLine
              size={24}
              className="text-green-400 justify-center items-center"
            />
          </Tooltip>
        );
      case "PROGRESS":
        return progress === 100 ? (
          <Tooltip tooltipText={t("missingWording.acheved")} position={"top"}>
            <RiUserFollowLine
              size={24}
              className="text-green-400 justify-center items-center"
            />
          </Tooltip>
        ) : (
          <Tooltip tooltipText={statusList[`${status}`]} position={"top"}>
            <RiUserSharedLine
              size={24}
              className="text-blue-600 justify-center items-center"
            />
          </Tooltip>
        );

      case "EXIT":
        return (
          <Tooltip tooltipText={statusList[`${status}`]} position={"top"}>
            <RiUserUnfollowLine
              size={24}
              className="text-red-500 justify-center items-center"
            />
          </Tooltip>
        );

      case "CREATED":
        return (
          <Tooltip tooltipText={statusList[`${status}`]} position={"top"}>
            <RiUserStarLine
              size={24}
              className="text-yellow-400 justify-center items-center"
            />
          </Tooltip>
        );
    }
  };

  return (
    <div className="flex flex-col xs:text-xs sm:text-sm md:text-sm lg:text-md mt-4">
      <Modal
        className="px-12 py-10 "
        showModal={showModalUpload}
        closeModal={() => setShowModalUpload((prev) => !prev)}
      >
        <div className="px-4">
          <h3>{t("Participant.resendLink")}</h3>
          <div className="flex justify-between  mt-10">
            <div>
              <Button
                status="secondary"
                className="p-2 btn-delete"
                width="100px"
                height="50px"
                onClick={() => {
                  setShowModalUpload((prev) => !prev);
                }}
              >
                {" "}
                Annuler
              </Button>
            </div>
            <div>
              <Button
                className="p-2"
                width="100px"
                height="50px"
                status="secondary"
                onClick={async () => {
                  const response = await resendInvitation({
                    formId: selectedEproForm?._id,
                    email: selectedEproForm?.participantId?.email,
                  });

                  if (response?.status === "success") {
                    makeToast("success", t("Participant.sucessUpdate"));

                    setListEproForms(
                      data.map((el) =>
                        el._id === response?.updatedForm?._id
                          ? response?.updatedForm
                          : el
                      )
                    );
                    setShowModalUpload((prev) => !prev);
                  } else makeToast("error", t("Participant.failedUpdate"));
                }}
              >
                {" "}
                Confirmer
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="overflow-x-auto">
        <div className="flex justify-between py-3 pl-2">
          <div className="relative max-w-xs">
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              onChange={onSearch}
              value={searchValue}
              className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
              placeholder={t("Participant.byFormName")}
              style={{ border: "none" }}
              onKeyDown={handleKeyDown}
            />

            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="p-1.5 w-full inline-block align-middle mt-5">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 xs:text-xs sm:text-sm md:text-sm lg:text-md">
                <tr>
                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.identifiant")}
                  </th>
                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.formName")}
                  </th>
                  {userType === "writer" && showName && (
                    <th
                      scope="col"
                      className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                    >
                      {t("missingWording.name")}
                    </th>
                  )}

                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.Statut")}
                  </th>

                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.LastModif")}
                  </th>

                  {userType !== "participant" && (
                    <th
                      scope="col"
                      className="md:px-6 xs:px-0  py-3 text-xs font-bold text-gray-500 uppercase  "
                    >
                      {t("Participant.Stats")}
                    </th>
                  )}
                  {userType === "writer" && isResponsable && (
                    <th
                      scope="col"
                      className="md:px-6 xs:px-0  py-3 text-xs font-bold text-gray-500 uppercase  "
                    >
                      {t("Participant.createdBy")}
                    </th>
                  )}
                </tr>
              </thead>
              {loading ? (
                <></>
              ) : (
                <>
                  {data.length > 0 && (
                    <tbody className="divide-y divide-gray-200">
                      {data.map((p) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setParticipant(p);

                              if (userType === "participant") {
                                history.push(`/eproForms/${studyId}/${p._id}`);
                              }
                            }}
                          >
                            <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                              {p?.participantId?.publicId}
                            </td>
                            <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                              {p.name}
                            </td>
                            {userType === "writer" && showName && (
                              <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                                {p.firstName + " " + p.lastName}
                              </td>
                            )}

                            <td className="md:px-6 xs:px-0.5 py-4 md:w-full md:text-sm xs:text-xs text-white  text-center flex items-center justify-center">
                              {p?.status === "INIT" ? (
                                !p?.accessToken ||
                                validToken(p?.accessToken) ? (
                                  <span
                                    className="inline-flex items-center rounded-full px-2 py-0.5 text-sm bg-gray-400 justify-center"
                                    style={{ width: "5.2rem" }}
                                  >
                                    {t("Participant.init")}
                                  </span>
                                ) : (
                                  <span
                                    className="inline-flex items-center rounded-full px-2 py-0.5 text-sm bg-red-400 justify-center"
                                    style={{ width: "5.2rem" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedEproForm(p);
                                      setShowModalUpload((prev) => !prev);
                                    }}
                                  >
                                    {t("Participant.expired")}
                                  </span>
                                )
                              ) : p?.status === "PROGRESS" ? (
                                <span
                                  className="inline-flex items-center rounded-full px-2 py-0.5 text-sm bg-yellow-500 justify-center"
                                  style={{ width: "5.2rem" }}
                                >
                                  {t("Participant.enCours")}{" "}
                                </span>
                              ) : (
                                <span
                                  className="inline-flex items-center rounded-full px-2 py-0.5 text-sm bg-green-500 justify-center"
                                  style={{ width: "5.2rem" }}
                                >
                                  {t("Participant.valid")}{" "}
                                </span>
                              )}
                            </td>

                            <td className="md:px-6 xs:px-1 py-4 md:text-sm xs:text-xs text-gray-800 text-center">
                              {moment(p.updatedAt).format("DD-MM-YYYY") +
                                t("DashboardCro.at") +
                                moment(p.updatedAt).format("HH:mm")}
                            </td>

                            {userType !== "participant" &&
                            (p?.status !== "INIT" ||
                              !p?.accessToken ||
                              (p?.status === "INIT" &&
                                validToken(p?.accessToken))) ? (
                              <td className="flex justify-center">
                                <BiDownload
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    _handleDownloadPdf(p?._id);
                                  }}
                                />
                              </td>
                            ) : (
                              userType !== "participant" && (
                                <td className="flex justify-center">__</td>
                              )
                            )}
                            {userType === "writer" && isResponsable && (
                              <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                                {p.createdBy.firstName +
                                  " " +
                                  p.createdBy.lastName}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </>
              )}
            </table>

            {(data.length === 0 || !data || loading) && (
              <div className="flex justify-center items-center w-full mt-8 mb-8">
                {loading ? (
                  <p className="flexCenter  menuInput">
                    {t("missingWording.Loading")}...
                  </p>
                ) : (
                  <p className="menuInput">{t("Participant.aucunEpro")}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
