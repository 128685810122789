import Fuse from "fuse.js";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUserDelete } from "react-icons/ai";
import { FaUserShield } from "react-icons/fa";
import { getStudyWriters } from "../../../api/study";
import { getWritersOutStudy } from "../../../api/writer";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";
import Modal from "../../../components/ModalUI";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import Tooltip from "../../../components/toolTip";
import { listInvestigatorAtom } from "../../../state/listInvstigateurs";
import AddInvestigator from "./AddInvestigator";
import MassiveInvitationForm from "./MassiveInvitation";
import DeleteWriterModal from "./deleteWriterModal";
import ResponsableWriterModal from "./responsableWriterModal";
import "./styles.scss";
import { userAtom } from "../../../state/auth";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

type Props = {
  studyId: string;
  modalAddConfirm: boolean;
  setModalAddConfirm: React.Dispatch<React.SetStateAction<string>>;
};

const MesInvestigators: React.FC<Props | any> = ({
  studyId,
  modalAddConfirm,
  setModalAddConfirm,
  modalMassiveInvit,
  setModalMassiveInvit,
}) => {
  const [modalDelete, setModalDelete] = useState(false);

  const [data] = useState<any>(null);
  const [selectedDoctor, setSelectedDoctor] = useState() as any;
  const [search] = useState(false);
  const [loading] = useState(false);
  const [totalResult] = useState(0);
  const [, setFilteredData] = React.useState<any>([]);
  const [strFilter, setStrFilter] = React.useState<string>("");
  const [listWriter, setListWriter] = useAtom(listInvestigatorAtom);
  const [listWriterExist, setListWriterExist] = React.useState<any>([]);
  const [loadList, setLoadList] = React.useState<any>(false);
  const [modalInvResponsable, setModalInvResponsable] = useState(false);
  const [user] = useAtom(userAtom);
  const { t } = useTranslation() as any;

  const handleListFiltering = () => {
    const fuse = new Fuse(listWriter, {
      threshold: 0,
      shouldSort: true,
      keys: ["slug", "firstName", "lastName"],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };

  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, data]);

  async function fetchMyAPI(): Promise<void> {
    let response: any = await getStudyWriters(studyId);

    let res = await getWritersOutStudy(studyId);

    if (!response) {
      makeToast("error", `${t("Investigator.LoadEch")}`);
    }
    setListWriter(response);
    if (res) {
      setListWriterExist(res);
    }
  }

  useEffect(() => {
    fetchMyAPI();
  }, [loadList]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className=" my-patients mt-12">
        <div className={`patient-list flex flex-wrap justify-between w-full"`}>
          {listWriter && listWriter.length !== 0 ? (
            listWriter
              .sort(
                (a: any, b: any) =>
                  Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
              )
              .filter((x) => x?.writerId?.activated)
              .map((e, i) => {
                return (
                  <div
                    className={`card-patient justify-between bg-white p-4 relative ${
                      selectedDoctor === e?.writerId?._id
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedDoctor(e);
                    }}
                  >
                    <div className="flex patient-info">
                      <div
                        className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                        style={{
                          backgroundColor: colorAvatar[i % colorAvatar.length],
                          cursor: "pointer",
                        }}
                      >
                        {e?.gender === "male" ? (
                          <ManPatient />
                        ) : (
                          <WomanPatient />
                        )}
                      </div>
                      <div className="details " style={{ cursor: "pointer" }}>
                        <div className="flex-grow ">
                          <p
                            className="card-title capitalize "
                            style={{ cursor: "pointer" }}
                          >
                            {e.writerId.firstName} {e.writerId.lastName}
                          </p>
                          <p className="card-subtitle flex flex-row items-center gap-x-8">
                            <span>{e.writerId.email}</span>{" "}
                            {/* <p className="text-xs rounded-lg bg-gray-200 px-2 py-1 ">
                              {e.roleId?.name}
                            </p> */}
                          </p>
                        </div>
                        <div className="flex flex-row gap-x-2 text-xs items-center ">
                          <p className="text-xs rounded-lg bg-gray-200 px-2 py-1 ">
                            {e.roleId?.name}
                          </p>
                          <p className="rounded-lg bg-gray-200 px-2 py-1 ">
                            Centre : {e.centerId?.name}
                          </p>
                        </div>
                      </div>
                    {user?.type === 'cro' &&   <div className="flex md:flex-row xs:flex-col md:gap-x-2 xs:gap-y-2 absolute right-0">
                        <Tooltip
                          tooltipText={`${
                            e.isResponsable
                              ? t("ArcPage.RetireResponsable")
                              : t("ArcPage.AddResponsable")
                          }`}
                          position={"bottomLeft"}
                          clsText={{ fontSize: "10px", padding: "5" }}
                        >
                          <FaUserShield
                            className={
                              e.isResponsable
                                ? "text-green-400 cursor-pointer mr-4 p-1 border border-green-400 rounded-full"
                                : "text-gray-400 cursor-pointer mr-4 p-1 border border-gray-400 rounded-full"
                            }
                            onClick={() => {
                              setSelectedDoctor(e?._id);
                              setModalInvResponsable(true);
                              // setModalArcAdmin(true);
                            }}
                            size="28"
                          />
                        </Tooltip>
                        <Tooltip
                          tooltipText={`${t("Investigator.Delete")}`}
                          position={"bottomLeft"}
                          clsText={{ fontSize: "10px", padding: "5" }}
                        >
                          <AiOutlineUserDelete
                            className="text-red-400 cursor-pointer mr-4 p-1 border border-red-400 rounded-full"
                            onClick={() => {
                              setSelectedDoctor(e);
                              setModalDelete(true);
                            }}
                            size="28"
                          />
                        </Tooltip>
                      </div>}
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("SideBar.NoinesvtagotrFound")}</h3>
            </div>
          )}
        </div>

        <Modal
          closeModal={() => setModalAddConfirm(false)}
          showModal={modalAddConfirm}
          className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
        >
          <AddInvestigator
            setOpenModal={setModalAddConfirm}
            setLoadList={setLoadList}
            loadList={loadList}
            investigateurs={
              listWriterExist && listWriterExist?.length > 0
                ? listWriterExist.map((writer) => {
                    return {
                      writerId: writer._id,
                      email: writer.email,
                      firstName: ` ${writer.firstName}`,
                      lastName: `${writer.lastName} `,
                    };
                  })
                : []
            }
            studyId={studyId}
          />
        </Modal>
        <Modal
          closeModal={() => setModalMassiveInvit(false)}
          showModal={modalMassiveInvit}
          className="md:p-10 xs:p-5 md:m-16 xs:m-6 "
        >
          <MassiveInvitationForm studyId={studyId} />
        </Modal>
        {modalDelete && (
          <DeleteWriterModal
            setDeleteArcModal={setModalDelete}
            setShouldUpdate={setLoadList}
            writerId={selectedDoctor?.writerId?._id}
            writresConfigId={selectedDoctor?._id}
            studyId={studyId}
          />
        )}
        {modalInvResponsable && (
          <ResponsableWriterModal
            setDeleteArcModal={setModalInvResponsable}
            setShouldUpdate={setLoadList}
            writerId={selectedDoctor?.writerId}
            writresConfigId={selectedDoctor}
            studyId={studyId}
          />
        )}
      </div>
    </>
  );
};
export default MesInvestigators;
