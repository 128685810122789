import Fuse from "fuse.js";
import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStudiesForWriter } from "../../api/writer";
import Input from "../../components/FloatInput";
import Pagination from "../../components/PaginationTable";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { SelectedStudyAtom } from "../../state/selectedStudyAtom";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import { studyWriterConfigAtom } from "../../state/studyWriterConfig";
import Tooltip from "../../components/toolTip";
import { AiFillLock } from "react-icons/ai";
// import useDrivejs from "../../hooks/driverjs";
import Modal from "../../components/ModalUI";
import Button from "../../components/ButtonUI";
import { Socket, io } from "socket.io-client";
import { socketAtom } from "../../state/socket";
import { BASE_URL } from "../../config/api";
import { centerAcessAtom } from "../../state/centerAcessAtom";

const DashboardWriter = () => {
  const { t } = useTranslation() as any;
  const [studiesList, setStudiesList] = useState([]) as any[];
  const [studiesConfigList, setStudiesConfigList] = useState([]) as any[];
  const [step, setStep] = useState(0);
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [projectsListFiltred, setProjectsListFiltered] = useState<any>([]);
  const [roleList, setRoleList] = useState<any[]>([]);
  const [user] = useAtom(userAtom);
  const [, setStudyWriterConfig] = useAtom(studyWriterConfigAtom);
  const [, setSelectedStudyBoolean] = useAtom(SelectedStudyAtom);
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [modalStartTuto, setModalStartTuto] = useState(false);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [dateNow] = useState(moment());
  const typingTimeoutRef = useRef(null) as any;
  const [searchValue, setSearchValue] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [accessCenter,setAccessCenter] = useAtom(centerAcessAtom)
  const menu = [t("SideBar.ActiveStudy")];
  // const {} = useDrivejs({
  //   stepNumber: 0,
  // });
  // const updateFirstLogin = (start) => {
  //   if (socket) {
  //     // socket.emit("updateFirstLoginWriter", { skip: !start });
  //     // if (start) socket.emit("tutoStart", {});
  //   }
  // };
  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);

  // useEffect(() => {
    // if (!socket) {
    //   const mySocket: Socket = io(BASE_URL, {
    //     // forceNew: true,
    //     reconnectionAttempts: 100,
    //     timeout: 10000,
    //     transports: ["websocket"],
    //     auth: {
    //       token: localStorage.getItem("token"),
    //       tenantFormReact: localStorage.getItem("tenantId"),
    //     },
    //   });

    //   setSocket(mySocket);
    // } else {
    //   if (!socket.connected) socket.connect();
    //   socket.emit("checkFirstLoginWriter", {});
    //   socket.on("updateStartTourWriter", () => {
    //     socket.emit("tutoStartWriter", {});
    //   });
    //   socket.on("checkFirstLoginResponseWriter", (res) => {
    //     console.log("🚀 ~ file: index.tsx:178 ~ socket.on ~ res:", res);
    //     if (res.firstVist) {
    //       setModalStartTuto(true);
    //     }
    //   });

      // return () => {
        // if (socket) {
        //   socket.disconnect();
        //   socket.removeAllListeners("");
        //   socket.close();
        //   console.log("[DEBUG] Socket was closed!");
        // }
      // };
    // }
  // }, [socket]);
  const fetchStudies = async () => {
    const res = await getStudiesForWriter(user?._id, page, 8, textSearch);
    setStudiesConfigList(res?.writerPopulated?.studies);
    setTotalResult(res?.writerPopulated?.count);
    return res;
  };

  useEffect(() => {
    setPath("/");
    setStudySelectedId(null);

    fetchStudies();
    setRoleList(user?.roles);
    setStudiesList(user?.studiesConfig?.map((sc: any) => sc.studyId));
  }, [page, textSearch]);
  useEffect(() => {
    if (
      user?.type !== "cro" &&
      user?.firstLogin &&
      user?.activated &&
      !user?.invited
    ) {
      return history.push("/firstLogin");
    }
  }, []);
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };
  // const onSearch = (e) => {
  //   const value = e.target.value;
  //   setSearchValue(value);
  //   const fuse = new Fuse(studiesList, {
  //     threshold: 0.1,
  //     shouldSort: true,
  //     includeMatches: true,
  //     keys: ["name"],
  //   });
  //   const newList = fuse.search(value).map((item) => item.item);
  //   setProjectsListFiltered(newList);
  // };
  const renderDivs = (datahlist) => {
    return datahlist && datahlist.length > 0 ? (
      <div>
        <div className="flex justify-between cursor-pointer relative xs:text-xs sm:text-sm md:text-sm lg:text-md mb-4  font-semibold">
          <div className="flex items-center w-1/3">
            <div>
              <div>
                <p className=" xs:px-4 xs:py-2 md:px-6 md:py-4 capitalize font-bold"></p>
              </div>
            </div>
            <div className="capitalize xs:ml-4 md:ml-12 cursor-pointer">
              {t("missingWording.Sname")}
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3 xs:ml-2 md:ml-10">
            <p>{t("missingWording.profil")}</p>
          </div>
          <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3 xs:ml-2 md:ml-10">
            <p>{t("missingWording.Cname")}</p>
          </div>
          <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3 xs:ml-2 md:ml-10">
            <p> {t("missingWording.MAJ")}</p>
          </div>
        </div>
        {datahlist?.map((p) => {
          return (
            <>
              <div
                id={p?.studyId?._id}
                className="flex justify-between cursor-pointer relative xs:text-xs sm:text-sm md:text-sm lg:text-md StudyExple"
                onClick={() => {
                  history.push(`/participant/${p?.studyId._id}`);
                  setSelectedStudyBoolean(true);
                  setStudyWriterConfig(p);
                  setAccessCenter((perv)=>p.isResponsable ? true : false)
                  setStudySelectedId(p?.studyId?._id);
                }}
              >
                <div className="flex items-center w-1/3">
                  <div>
                    <div>
                      <p className="boxName xs:px-4 xs:py-2 md:px-6 md:py-4 capitalize font-bold">
                        {p?.studyId?.name[0]}
                      </p>
                    </div>
                  </div>

                  <div className="capitalize xs:ml-4 md:ml-12 cursor-pointer relative flex">
                    {p?.studyId?.name}
                    {p?.studyId?.status === "END" && (
                      <div className="">
                        <Tooltip
                          tooltipText={`${t("missingWording.toolTipStudyEnd")}`}
                          position={"left"}
                          clsText={{ width: "240px" }}
                        >
                          <AiFillLock
                            color="#000"
                            className="mx-2 cursor-pointer w-5 h-5"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3 xs:ml-2 md:ml-10">
                  <p>{p?.roleId?.name}</p>
                </div>
                <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3 xs:ml-2 md:ml-10">
                  <p>{p?.centerId?.name}</p>
                </div>
                <div className="flex items-center justify-center w-1/3 sm:p-1 xs:p-3 xs:ml-2 md:ml-10">
                  <p>
                    {" "}
                    {moment(p?.createdAt).format("DD-MM-YYYY") +
                      t("DashboardCro.at") +
                      moment(p?.createdAt).format("HH:mm")}
                  </p>
                </div>
              </div>
              <div className="line"></div>
            </>
          );
        })}
        <div>
          {totalResult > 8 && (
            <Pagination
              entriesName={`${t("missingWording.paginationS")}`}
              totalResults={totalResult}
              resultsPerPage={8}
              currentPage={page}
              onPageChange={function (page: number): void {
                setPage(page);
              }}
            />
          )}
        </div>
      </div>
    ) : (
      <p className="flexCenter mt-16 menuInput">
        {t("missingWording.Loading")}...
      </p>
    );
  };

  return (
    <>
      <Modal
        showModal={modalStartTuto}
        closeModal={() => setModalStartTuto(false)}
        className="px-16 py-10 studyAdingModal"
      >
        <p className="text-center font-semibold mb-5">
          {t("DashboardCro.bienvenuTuto")}
        </p>

        <div className=" w-full flex gap-16 justify-center items-center p-4 ">
          <Button
            status="secondary"
            width="200px"
            height="50px"
            className="px-6 py-2"
            onClick={() => {
              setModalStartTuto(false);
              // updateFirstLogin(false);
            }}
          >
            {t("DashboardCro.Cancel")}
          </Button>
          <Button
            width="200px"
            height="50px"
            className=" px-6 py-2"
            onClick={() => {
              // updateFirstLogin(true);
              setModalStartTuto(false);
            }}
          >
            {t("DashboardCro.start")}
          </Button>
        </div>
      </Modal>
      <header className="flex justify-between items-center w-full flex-wrap">
        <div className="title-container  relative">
          <h1 className="pageTitle mb-2 pb-2">{t("DashboardCro.title")}</h1>
          <h3 className="card-subtitle">
            {dateNow.format("DD-MM-YYYY") +
              t("DashboardCro.at") +
              dateNow.format("HH:mm")}
          </h3>
          <div className="relative max-w-xs">
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              onChange={onSearch}
              value={searchValue}
              className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
              placeholder={t("SideBar.FilterStudy")}
              style={{ border: "none" }}
              onKeyDown={handleKeyDown}
            />

            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
        </div>
      </header>
      <div className="flex mt-16">
        <div className="menu">
          {menu.map((m, idx) => {
            return (
              <div onClick={() => setStep(idx)} className="cursor-pointer">
                <p className={`${step === idx ? "selected" : "unselected"}`}>
                  <span className="capitalize">{m}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {step === 0 && (
        <>
          <div className="mt-8 Studies_studiesSection">
            {studiesConfigList && studiesConfigList.length > 0 ? (
              renderDivs(studiesConfigList)
            ) : (
              <p>{t("DashboardCro.noActiveStudy")}</p>
            )}
          </div>
          {process.env.NODE_ENV === "development" && (
            <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 fixed bottom-0 left-0 z-50">
              <Button
                status="primary"
                // onClick={() => socket.emit("resetChallenge1Writer", {})}
                className="text-xs px-2 py-1"
              >
                Start Tour
              </Button>
              <Button
                status="primary"
                onClick={() => {
                  // socket.emit("IniTutoWriter", {});
                  window.location.reload();
                }}
                className="text-xs px-2 py-1"
              >
                Init
              </Button>
            </div>
          )}
        </>
      )}
      {step === 1 && (
        <div>
          <p className="flexCenter mt-16">{t("missingWording.noArchive")} </p>
        </div>
      )}
    </>
  );
};

export default DashboardWriter;
