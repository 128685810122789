import moment from "moment";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import { getSenderType } from "../../../../helper";
import Button from "../../../ButtonUI";
import SelectComp from "../../../Select";
import makeToast from "../../../Snackbar";
const QueryHasDataModal = (props) => {
  const {
    setShowQueryHasData,
    setSelectedElment,
    participant,
    selectedElment,
    queries,
    isWriter,
    setSelectedStatusQuery,
    selectedStatusQuery,

    setTexteMsg,
    loadingQueryHasData,
    textMesg,
    updateQueryMessage,
    setShouldUpdate
  } = props;
  const { t } = useTranslation();
  const localStatusList = [
    {
      label: t("missingWording.open"),
      value: "OPEN",
    },
    {
      label: t("missingWording.closed"),
      value: "CLOSED",
    },
  ];
  return (
    <>
      {" "}
      <Button
        status="icon-secondary"
        onClick={() => {
          setShowQueryHasData(false);
          setSelectedElment();
        }}
        className="absolute right-1 top-1"
      >
        <Close />
      </Button>
      <div className="space-y-4" style={{}}>
        <div className="flex flex-col border-2 rounded-3xl mt-8 p-4">
          <div className="flex">
            <div className="w-64">
              <p className="subTitle"> Participant :</p>
            </div>
            <div>
              <p>{participant?.publicId}</p>
            </div>
          </div>
          <div className="flex mt-4 mb-4">
            <div className="w-64">
              <p className="subTitle">{t("Survey.FieldName")} :</p>
            </div>
            <div>
              <p>{selectedElment ? selectedElment.label : "N/A"}</p>
            </div>
          </div>
          <div className="flex">
            <div className="w-64">
              <p className="subTitle">{t("Survey.valField")} :</p>
            </div>
            <div>
              <p>
                {selectedElment &&
                  (selectedElment.value !== null
                    ? selectedElment.value
                    : "N/A")}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full space-y-10">
          <div className="flex flex-col">
            {queries[0]?.messages.map((e, idx) => {
              return (
                <>
                  <div className="relative">
                    <div className="mt-1">
                      {e.isSystemGenerated ? (
                        <div>{e?.messageContent?.replace(/\|\|/g, ",")}</div>
                      ) : (
                        <div>{e?.messageContent}</div>
                      )}
                    </div>
                    <div className="flex justify-between  ">
                      <div className="flex flex-row justify-center p-1.5 bg-gray-100 rounded-lg text-xs ">
                        {queries[0].isSystemGenerated && e.isSystemGenerated ? (
                          <p className="text-xs font-semibold ">
                            {t("missingWording.frp1")}
                          </p>
                        ) : (
                          <p className="text-xs font-semibold capitalize">
                            {getSenderType(e?.sentFrom?.type,t)
                              ? getSenderType(e?.sentFrom?.type,t)
                              : "CRO"}{" "}
                            : {e?.sentFrom?.firstName} {e?.sentFrom?.lastName}
                          </p>
                        )}
                        <p className="text-xs mx-2 ">
                          {" "}
                          {t("missingWording.le") +
                            moment(e?.createdAt).format("DD-MM-YYYY") +
                            t("DashboardCro.at") +
                            moment(e?.createdAt).format("HH:mm")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* {idx !== messagesQueryExample.length - 1 && ( */}
                  <div
                    className="h-1 w-full mt-6 mb-6"
                    style={{ backgroundColor: "#4565f4" }}
                  ></div>
                </>
              );
            })}
          </div>
          {!isWriter && (
            <div className="flex flex-col">
              <div>
                <p>{t("missingWording.frp2")}</p>
              </div>
              <div>
                <SelectComp
                  onChange={(e) => setSelectedStatusQuery(e.target.value)}
                  className="w-full"
                  value={selectedStatusQuery}
                  setText={false}
                  options={localStatusList}
                ></SelectComp>
              </div>
            </div>
          )}
          <div className="flex flex-col">
            <div>
              <div>
                <textarea
                  name="remark"
                  rows={4}
                  placeholder={t("Survey.remarque")}
                  className="border-2 rounded-3xl p-4 w-full"
                  onChange={(e) => setTexteMsg(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div>
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="float-left mb-4 btn-delete"
              onClick={() => {
                setShowQueryHasData(false);
                setSelectedElment();
              }}
            >
              {t("missingWording.cancel")}
            </Button>
            {loadingQueryHasData ? (
              <div className="flex flex-row-reverse">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            ) : (
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                onClick={async () => {
                 try {
                  console.log(selectedElment)
                  let values = {
                    toUserId: participant.creratedBy,
                    textMessage: textMesg,
                    status: selectedStatusQuery,
                  };
                  const res = await updateQueryMessage(queries[0]?._id, {
                    resolved: { ...values },
                  });
                  if (res) {
                   setShouldUpdate((up) => !up);
                    makeToast(
                      "success",
                      selectedStatusQuery === "CLOSED"
                        ? t("missingWording.frt5")
                        : t("missingWording.frt6")
                    );
                    setShowQueryHasData(false);
                    setSelectedElment();
                  }
                 } catch (error) {
                  makeToast(
                    "warning",
                  ''
                  );
                 }
                }}
              >
                {t("Survey.Save")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default QueryHasDataModal;
