import React from "react";
import * as Yup from "yup";
import { useAtom } from "jotai";

import { userAtom } from "../../../state/auth";
import { logout } from "../../../api/auth";

import Button from "../../../components/ButtonUI";
import StripePaymentMethod from "../../../components/StripePaymentMethod";
import { FormikWizard } from "../../../components/FormikWizard";

import { ReactComponent as Pay } from "../../../assets/illustrations/pay.svg";
import "../../Signin/stylesSignin.scss";
import { addOrModifyProfileInfo } from "../../../api/cro";
interface Props {
  setOpenModal: Function;
}

export const SubscriptionModal: React.FC<Props> = ({ setOpenModal }) => {
  const [user, setUser] = useAtom(userAtom);

  const ProgressBar = (progress: number) => {
    const progressPercentage = (100 / 1) * (progress + 1);
    return (
      <div className=" h-2 w-full bg-gray-300 border rounded-md">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full ${
            progressPercentage < 75 ? "bg-indigo-500" : "bg-green-300"
          }`}
        ></div>
      </div>
    );
  };
  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/";
      localStorage.clear();
    } catch (error) {
      window.location.href = "/";
      localStorage.clear();
      console.error(error);
    }
  };
  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden  bg-opacity-modal flexCenter ">
      <div className="absolute z-40 top-8 flexColCenter firstModal ">
        <h2>Bienvenue sur K-search 👋 </h2>
        <div className="m-2 w-full">
          <ul className="stepper" data-mdb-stepper="stepper">
            <FormikWizard
              initialValues={{
                subscriptionId: "",
                email: user?.email,
              }}
              onSubmit={async (values) => {
                const { email, subscriptionId } = values;
                const updateProfile = await addOrModifyProfileInfo({
                  stripeSubscriptionId: subscriptionId,
                });
                if (updateProfile) {
                  setUser(updateProfile);
                  setOpenModal(false);
                }
              }}
              validateOnNext
              activeStepIndex={0}
              steps={[
                {
                  component: ({
                    errors,
                    values,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) =>
                    values.subscriptionId === "" ? (
                      <>
                        <StripePaymentMethod
                          setFieldValue={setFieldValue}
                          auth={true}
                          values={values}
                          handleSubmitGlobal={handleSubmit}
                        />
                      </>
                    ) : (
                      <div>
                        <Pay
                          className="border-4 border-green-300 bg-gray-100 rounded-full p-5 mx-auto my-2"
                          width={200}
                          height={200}
                        />
                        <p className="flexCenter font-bold text-lg text-green-500 ">
                          Paiement réussi
                        </p>
                      </div>
                    ),
                  validationSchema: Yup.object().shape({
                    subscriptionId: Yup.string().required("required"),
                  }),
                },
              ]}
            >
              {({
                renderComponent,
                handlePrev,
                handleNext,
                isNextDisabled,
                isPrevDisabled,
                isLastStep,
                currentStepIndex,
                values,
              }) => (
                <>
                  <div className="wrapper option-1 option-1-1">
                    {currentStepIndex
                      ? ProgressBar(currentStepIndex)
                      : ProgressBar(0)}
                    {}
                    <ol className="c-stepper">
                      <li className="c-stepper__item">
                        <h3
                          className={`c-stepper__title ${
                            currentStepIndex == 0
                              ? "text-indigo-500"
                              : "text-gray-300"
                          }`}
                        >
                          Abonnement
                        </h3>
                        <p className="c-stepper__desc mb-3"></p>
                      </li>
                    </ol>
                    {renderComponent()}
                  </div>

                  {!isPrevDisabled && (
                    <Button
                      status="primary"
                      className="float-right flexCenter mx- 5 my-5"
                      width="100px"
                      height="50px"
                      type="button"
                      onClick={handlePrev}
                      hidden={isPrevDisabled}
                    >
                      Précédent
                    </Button>
                  )}

                  <Button
                    status="secondary"
                    className="float-left flexCenter my-5"
                    width="100px"
                    height="50px"
                    type="button"
                    onClick={handleLogout}
                  >
                    Annuler
                  </Button>
                </>
              )}
            </FormikWizard>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
