import {
  InputGroup,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { MdErrorOutline } from "react-icons/md";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { userAtom } from "../../../../state/auth";
import { FormGroup } from "../FormGroup";
import { visteAtom } from "../../../../state/visteAtom";

const FieldRadio: React.FunctionComponent<any> = (props) => {
  let { name, error, options, direction, inputName, required, ...rest } = props;

  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);



  const [isTouched, setIsTouched] = useState(true);
  const [isValidation, setIsValidation] = useState(false);
  const [user] = useAtom(userAtom);
  const showError = !isValid && (isTouched || isSubmitted);
  const { children, label, type, placeholder, helper, ...resta } = otherProps;
  const [formData,] = useAtom(visteAtom);
  function checkInputEquality(inputValue, sectionsArray) {

    return sectionsArray.some((section) => {
      return (
        section?.conditionLogic?.conditions?.some(
          (condition) =>
            section?.status !== "INIT" && condition?.fieldToCompare === inputValue
        ) ||
        section?.fields?.some((field) =>
          field?.conditionLogic?.conditions.some(
            (condition) =>
              field?.value && condition?.fieldToCompare === inputValue
          )
        )
      );
    });
  }
  
  useEffect(() => {
    setIsTouched(true);
  }, [resetKey]);
  useEffect(() => {
    if (rest.value) {
      setValue(rest.value);
    }
    // return () => setValue(null);
  }, [props.value]);


 
  const formGroupProps = {
    errorMessage,
    helper: isTouched ? helper : "",
    id,
    isRequired: required,
    label,

    showError,
    name,

    ...resta,
  };

  const handleChange = (value: string) => {
    if (options) {
      let completeValue = options.find((f: any) => f.value === value);
      if((formData && formData.sections && !checkInputEquality(name,formData.sections) && user?.type === 'writer')|| props.isPreview){
        setValue(completeValue.value);
      }
   
      
      if (props?.onSumbit)
      props.onSumbit({
        fieldName: name,
        value: completeValue.value,
        isValid: true,
      });
    }
  };
  // console.log("showError",showError,"errorMessage",errorMessage,"isValid",isValid)
  return (
    <FormGroup className="mt-3" {...formGroupProps}>
      <InputGroup>
        <RadioGroup

          {...rest}
          value={value && (value.value || value)}
          onChange={(e: string) => {
            setIsTouched(true)
            handleChange(e);
            setIsValidation(true);
          
          }}
          
          onBlur={(e) => {
           
         
            setIsValidation(false);
          }}
          className={isValid ? " px-2 py-1  " : "px-2 py-1 "}
        >
          <Stack
            className={
              props.disabled ? "pointer-events-none cursor-not-allowed" : ""
            }
            direction={direction || "row"}
          >
            {options &&
              options.map((item: any, index: number) => (
                <React.Fragment key={`${index}`}>
                  <Radio
                    _checked={{ bg: "blue" }}
                    key={index}
                    value={item.value}
                    onClick={(e)=> handleChange(item.value)}
                    isDisabled={props.disabled ? true : false}
                    _disabled={{ _checked: { background: "blue" } }}
                  >
                    <p style={{ fontSize: "14px" }}>{item.label}</p>
                  </Radio>
                  <span style={{ marginRight: "2rem" }} />
                </React.Fragment>
              ))}
          </Stack>
        </RadioGroup>
       
      </InputGroup>
      {!props?.isPreview && user?.type === "writer" && (
        <div className="absolute -left-8 top-1/2">
          {" "}
          {isValidation && (
            <p>
              <Spinner size="sm" flex="none" color="blue.200" />
            </p>
          )}
          <p>
            {" "}
            {isValid && !isValidation && (
              <HiCheckCircle color="#67d428" width={20} height={20} />
            )}
            {!isValid && !isValidation && (
              <MdErrorOutline color="#ff0000" width={20} height={20} />
            )}
          </p>
        </div>
      )}

      {children}
    </FormGroup>
  );
};

export default FieldRadio;
