import { useState, useEffect } from "react";
import MesArcs from "../MyArc";
import MesInvestigators from "../MyInvestigator";
import Button from "../../../components/ButtonUI";
import { useTranslation } from "react-i18next";
import MesSponsors from "../MySponsor";
import { userAtom } from "../../../state/auth";
import { useAtom } from "jotai";
import MesInvitations from "../Invitations";
import Modal from "../../../components/ModalUI";
import Tooltip from "../../../components/toolTip";
import { HiOutlineClipboard, HiOutlineClipboardCheck } from "react-icons/hi";

const MemberStudy = ({
  values,
  studyId,
  studyConfig,
  setUpdateStudy,
  openAddInvestigateurTuto,
  readOnly,
}) => {
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [step, setStep] = useState(0);
  const [modalAfectation, setModalAfectation] = useState(false);
  const [modalMagicLink, setModalMagicLink] = useState(false);
  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [modalMassiveInvit, setModalMassiveInvit] = useState(false);
  const [modalAddSponsor, setModalAddSponsor] = useState(false);
  const [tuto, setTuto] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const urlToCopy = `${process.env.REACT_APP_URL}invitationPage/${user?.tenantId}/${studyId}`;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlToCopy);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };
  const rolesRes =
    user?.type === "cro"
      ? [t("SideBar.team"), t("SideBar.Investigateur"), "Invitations"]
      : user?.type === "arc"
      ? [t("SideBar.Investigateur"), "Invitations"]
      : [t("SideBar.Investigateur"), "Invitations"];

  useEffect(() => {
    if (openAddInvestigateurTuto && tuto) {
      setStep(1);
      setModalAddConfirm(true);
      setTuto(false);
    }
  }, [openAddInvestigateurTuto]);

  return (
    <>
      <div className="flex justify-between">
        <div className="menu cursor-pointer">
          {rolesRes.map((m, idx) => {
            return (
              <div id={`tabAddRole${idx}`} onClick={() => setStep(idx)}>
                {" "}
                <p className={`${step === idx ? "selected" : "unselected"}`}>
                  {" "}
                  <span className="capitalize">{m}</span>{" "}
                </p>{" "}
              </div>
            );
          })}
        </div>
        {user?.type === "cro" ? (
          <>
            {" "}
            {step === 0 ? (
              <div className="flex flex-row-reverse items-center relative">
                <div className=" flex max-w-max items-center space-x-2 ">
                  <div className="relative">
                    <Button
                      disabled={studyConfig.status === "END"}
                      status="secondary"
                      onClick={() => setModalAfectation(true)}
                      style={{
                        width: "168px",
                        height: "50px",
                      }}
                    >
                      {t("SideBar.AddUser")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : step === 1 ? (
              <div className="flex flex-row-reverse justify-between items-center ml-2">
                <div className="flex max-w-max items-center space-x-2 ">
                  <div className="relative">
                    <Button
                      disabled={studyConfig.status === "END"}
                      status="secondary"
                      onClick={() => setModalAddConfirm(true)}
                      style={{
                        width: "168px",
                        height: "50px",
                      }}
                      id="addInvesbtn"
                    >
                      {t("SideBar.AddInves")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              // step === 2 ? (
              //   <div className="flex flex-row-reverse justify-between items-center ml-2">
              //     {/* <div className="flex max-w-max items-center space-x-2 ">
              //       <div className="relative">
              //         <Button
              //           disabled={studyConfig.status === "END"}
              //           status="secondary"
              //           onClick={() => setModalAddSponsor(true)}
              //           style={{
              //             width: "168px",
              //             height: "50px",
              //           }}
              //         >
              //           {"+ Sponsor"}
              //         </Button>
              //       </div>
              //     </div> */}
              //   </div>
              // ) :
              <div className=" flex max-w-max items-center space-x-2 ">
                <div className="relative">
                  <Button
                    disabled={studyConfig.status === "END"}
                    status="icon-secondary"
                    onClick={() => setModalMagicLink(true)}
                    className="px-4 py-2.5 text-center bg-gray-200 text-sm font-semibold"
                    style={{ width: "200px" }}
                  >
                    {"✨ " + t("missingWording.invitLink")}
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {step === 0 ? (
              <div className="flex flex-row-reverse justify-between items-center ml-2">
                <div className="flex max-w-max items-center space-x-2 ">
                  <div className="relative">
                    <Button
                      disabled={studyConfig.status === "END"}
                      status="secondary"
                      onClick={() => setModalAddConfirm(true)}
                      style={{
                        width: "168px",
                        height: "50px",
                      }}
                      id="addInvesbtn"
                    >
                      {t("SideBar.AddInves")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-row-reverse items-center relative">
                <div className=" flex max-w-max items-center space-x-2 ">
                  <div className="relative">
                    <Button
                      status="icon-secondary"
                      onClick={() => setModalMagicLink(true)}
                      className="px-4 py-2.5 text-center bg-gray-200 text-sm font-semibold"
                      style={{ width: "200px" }}
                    >
                      {"✨ " + t("missingWording.invitLink")}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {console.log("[DEBUG] user type is : ", user?.type)}
      {user?.type === "cro" ? (
        <>
          {step === 0 ? (
            <MesArcs
              studyId={studyId}
              modalAfectation={modalAfectation}
              setModalAfectation={setModalAfectation}
              studyConfig={studyConfig}
              setUpdateStudy={setUpdateStudy}
            />
          ) : step === 1 ? (
            <MesInvestigators
              studyId={studyId}
              modalAddConfirm={modalAddConfirm}
              setModalAddConfirm={setModalAddConfirm}
              modalMassiveInvit={modalMassiveInvit}
              setModalMassiveInvit={setModalMassiveInvit}
            />
          ) : step === 2 ? (
            // <MesSponsors
            //   studyId={studyId}
            //   modalAfectation={modalAddSponsor}
            //   setModalAfectation={setModalAddSponsor}
            //   studyConfig={studyConfig}
            //   setUpdateStudy={setUpdateStudy}
            // />
            <MesInvitations studyId={studyId} />
          ) : (
            <MesInvitations studyId={studyId} />
          )}
        </>
      ) : user?.type === "sponsor" ? (
        <>
          {step === 0 ? (
            <MesInvestigators
              studyId={studyId}
              modalAddConfirm={modalAddConfirm}
              setModalAddConfirm={setModalAddConfirm}
              modalMassiveInvit={modalMassiveInvit}
              setModalMassiveInvit={setModalMassiveInvit}
            />
          ) : (
            <MesInvitations studyId={studyId} />
          )}
        </>
      ) : (
        <>
          {step === 0 ? (
            <MesInvestigators
              studyId={studyId}
              modalAddConfirm={modalAddConfirm}
              setModalAddConfirm={setModalAddConfirm}
              modalMassiveInvit={modalMassiveInvit}
              setModalMassiveInvit={setModalMassiveInvit}
            />
          ) : step === 1 ? (
            // <MesSponsors
            //   studyId={studyId}
            //   modalAfectation={modalAddSponsor}
            //   setModalAfectation={setModalAddSponsor}
            //   studyConfig={studyConfig}
            //   setUpdateStudy={setUpdateStudy}
            // />
            <MesInvitations studyId={studyId} />
          ) : (
            <MesInvitations studyId={studyId} />
          )}
        </>
      )}
      <Modal
        className="px-14 py-16 "
        closeModal={() => setModalMagicLink(false)}
        showModal={modalMagicLink}
      >
        <div
          className="flex gap-x-0.5   items-center "
          style={{ minWidth: "26rem" }}
        >
          <input
            type="text"
            disabled
            className=" w-full md:w-screen text-xs md:text-sm border rounded-lg  py-3 px-1 "
            value={urlToCopy}
          />
          <div className="ml-8">
            {" "}
            {copySuccess ? (
              <Tooltip
                clsText={{ minWidth: "120px", textAlign: "center" }}
                tooltipText={t('missingWording.linkCopied')}
                position={"left"}
              >
                <HiOutlineClipboardCheck
                  className="text-green-500"
                  size={26}
                  onClick={copyToClipboard}
                />
              </Tooltip>
            ) : (
              <Tooltip
                tooltipText={t('missingWording.copyLink')}
                clsText={{ minWidth: "120px", textAlign: "center" }}
                position={"left"}
              >
                <HiOutlineClipboard size={26} onClick={copyToClipboard} />
              </Tooltip>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MemberStudy;
