import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  addEproFormToStudy,
  addFormToStudy,
  getStudyRoles,
} from "../../api/study";
import { formAtom } from "../../state/formAtom";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import makeToast from "../../components/Snackbar";


export function addSharedObjectToFront(inputArray, t) {
  // Create the object to be added
  const sharedObject = { label: t("missingWording.inpXAn"), value: "shared" };

  // Clone the input array to avoid modifying the original array
  const newArray = [...inputArray];

  // Add the sharedObject to the beginning of the newArray
  newArray.unshift(sharedObject);

  // Return the modified array
  return newArray;
}
type Props = any;
const AddEproForm: React.FC<Props> = ({
  visteList,
  randomConfig,
  setCreationModalActive,
}) => {
  const { t } = useTranslation() as any;
  const params: any = useParams();
  const { studyId } = params;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [associatedGroup, setAssociatedGroup] = useState(null) as any;
  const [isShared, setIsShared] = useState<boolean>(true);

  const [groups, setGroups] = useState(
    randomConfig
      ? randomConfig.groups.map((g: any) => {
          return {
            label: `${g?.groupName}`,
            value: g?._id,
          };
        })
      : [{ label: t("missingWording.inpXAn"), value: "null" }]
  ) as any[];
  const [dependency, setDependency] = useState("");
  const [form, setForm] = useAtom(formAtom);
  const [loading, setLoading] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState("") as any;
  const [nameError, setNameError] = useState("");
  const [associatedGroupError, setAssociatedGroupError] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (randomConfig) {
      setGroups(
        randomConfig.groups.map((g: any) => {
          return {
            label: `${g?.groupName}`,
            value: g?._id,
          };
        })
      );
    }
  }, [randomConfig]);

  useEffect(() => {
    let obj = {
      name: name,
      description: desc,
      externelResources: {},
      sections: selectedFormData
        ? selectedFormData?.el?.sections
        : [{ task_data: [], stepNbr: 0, sectionName: "Page 1" }],
      dependency:
        dependency !== "" && dependency !== "ON_CREATION" ? dependency : null,
      associatedGroup: randomConfig ? associatedGroup : null,
      shared: randomConfig ? isShared : true,
    };
    setForm(obj);
  }, [name, desc, dependency, selectedFormData, associatedGroup,    isShared,]);
  const createSurveyEpro = async () => {
    try {
      console.log("🚀 ~ file: index.tsx:116 ~ createSurvey ~ form:", form);

      setLoading(true);

      if (name === "") {
        setLoading(false);
        return makeToast("warning", t("missingWording.t2"));
      }
      const newSurvey = await addEproFormToStudy(studyId, {
        ...form,
        studyId: studyId,
      });
      if (newSurvey && newSurvey.data?.newSurvey) {
        setLoading(false);
        makeToast("success", t("missingWording.t3"));
        history.push(`/study/${studyId}/${newSurvey.data?.newSurvey._id}`);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      Promise.reject(error);
    }
  };
  return (
    <>
      <h3 className="font-bold text-2xl text-black text-center my-10 mx-6 px-24 ">
        {t("missingWording.p1")}
      </h3>
      <div className="my-3 mx-4 items-center  ">
        <Input
          id="name"
          className="w-full"
          placeholder={t("missingWording.inp1")}
          labelText={t("missingWording.inp1")}
          type="text"
          onBlur={(e) => {
            setName(e.target.value);
            if (e.target.value === "") {
              setNameError(t("ArcPage.verifName"));
            } else {
              setNameError("");
            }
          }}
          onChange={(e) => {
            setNameError("");
            setName(e.target.value);
          }}
          inlineLabel={false}
          onfocus={true}
          style={{ maxHeight: "50px" }}
        />
        {nameError && <p className="text-red-300  text-sm ml-4">{nameError}</p>}
      </div>
      <div className="mx-4 my-3 items-center  ">
        <Input
          id="desc"
          className="w-full"
          placeholder={t("missingWording.inp2")}
          labelText={t("missingWording.inp2")}
          type="text"
          style={{ maxHeight: "50px" }}
          onBlur={(e) => setDesc(e.target.value)}
          onChange={(e) => setDesc(e.target.value)}
          inlineLabel={false}
          onfocus={true}
        />
      </div>

      {visteList.length > 0 && (
        <div className="mx-4  items-center  ">
          <SelectComp
            id="dependency"
            onChange={(e) => {
              setDependency(e.target.value);
              return;
            }}
            disabled={!visteList || visteList.length === 0}
            className="min-w-full "
            text={
              visteList.length > 0
                ? t("missingWording.inp4Epro")
                : t("missingWording.inp4n")
            }
            options={[
              {
                label: t("missingWording.onCreationEpro"),
                value: "ON_CREATION",
              },
              ...visteList?.map((e) => {
                return { value: e?._id, label: e?.name };
              }),
            ]}
          ></SelectComp>
        </div>
      )}

      {randomConfig && randomConfig?.groups && (
        <div className="mx-4 my-3 items-center  ">
          <SelectComp
            id="associatedGroup"
            onChange={(e) => {
              setAssociatedGroupError("");
              if (e.target.value === "shared" || e.target.value === "") {
                setIsShared(true);
                setAssociatedGroup(null);
              } else {
                setIsShared(false);
                setAssociatedGroup(e.target.value);
              }
              return;
            }}
            // labelText={t("missingWording.inpXALabel")}
            disabled={!groups || groups.length === 0}
            className="min-w-full mb-8"
            text={
              groups.length > 0
                ? t("missingWording.inpXA")
                : t("missingWording.inpXAn")
            }
            options={addSharedObjectToFront(groups, t)}
          ></SelectComp>
          {associatedGroupError && (
            <p className="text-red-300 text-sm ml-4 mt-3">
              {associatedGroupError}
            </p>
          )}
        </div>
      )}
      <div className=" w-full flex justify-between items-center p-4 ">
        <Button
          status="secondary"
          width="100px"
          height="50px"
          className="btn-delete px-6 py-2"
          onClick={() => setCreationModalActive(false)}
        >
          {t("Profile.Cancel")}
        </Button>
        <Button
          status="secondary"
          width="100px"
          height="50px"
          className="px-6 py-2 "
          onClick={async () => {
            let hasError = false;
            if (name === "") {
              setNameError(t("ArcPage.verifFormName"));
              hasError = true;
            } else {
              setNameError("");
            }
            if (!isShared && (!associatedGroup || associatedGroup === "")) {
              setAssociatedGroupError(t("ArcPage.verifAssociatedGrp"));
              hasError = true;
            } else {
              setAssociatedGroupError("");
            }

            if (!hasError) {
              await createSurveyEpro();
            }
          }}
        >
          {t("missingWording.createButton")}
        </Button>
      </div>
    </>
  );
};

export default AddEproForm;
