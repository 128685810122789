import { CREATE_TRANSLATION, GET_TRANSLATION } from "../config/api";
import { get, post } from "../helper/apiMethods";

export const createTranslation = async (values, surveyId) => {
  try {
    const res = await post(CREATE_TRANSLATION + `/${surveyId}`, values);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTranslationBySurveyId = async (surveyId, language) => {
  try {
    const res = await get(`${GET_TRANSLATION}/${surveyId}/${language}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
