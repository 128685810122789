import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiSend } from "react-icons/fi";
import { BiFileFind } from "react-icons/bi";
import { GoIssueClosed, GoIssueReopened } from "react-icons/go";
import { SiConvertio, SiOpsgenie, SiProbot } from "react-icons/si";
import { seeQueryMsgs, updateQuery } from "../../../api/queries";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import moment from "../../../helper/moment";
import history from "../../../history";
import { userAtom } from "../../../state/auth";
import { default as Button, default as ButtonUI } from "../../ButtonUI";
import Modal from "../../ModalUI";
import SelectComp from "../../Select";
import makeToast from "../../Snackbar";
import Tooltip from "../../toolTip";
import Progress from "../../NProgress";
import { renderNumberOfMsgNotSee } from "..";
const QueryModal: React.FC<any> = ({
  selectedStatusQuery,
  setSelectedStatusQuery,
  setShouldUpdate,
  setTypeQuery,
  selctedQuery,
  setSelectedQuery,
  setTextMsg,
  textMsg,
}) => {
  const { t } = useTranslation();
  const [user] = useAtom(userAtom);
  const updateQueryMessage = async (queryId, values) => {
    try {
      const res = await updateQuery(queryId, values);
      return res && res?.data ? res.data.queryUpdated : false;
    } catch (error) {
      return false;
    }
  };
  const queryMessageSeen = async (queryId) => {
    try {
      const res = await seeQueryMsgs(queryId);
      return res && res?.data ? res.data.queryUpdated : false;
    } catch (error) {
      return false;
    }
  };
  useEffect(() => {
    console.log("open");
    if (selctedQuery && renderNumberOfMsgNotSee(selctedQuery.messages,user?._id) > 0) queryMessageSeen(selctedQuery?._id);
    return () => {
      console.log("Cloased");
    };
  }, []);
  return (
    <div className=" mb-4 text-sm " style={{ width: "100%" }}>
      <div className="flex justify-between mt-8">
        <div className="mb-8 ">
          <h3> {t("missingWording.query")}</h3>
          <span
            onClick={() => {
              localStorage.setItem(
                "queryField",
                `${selctedQuery?.formDataId?._id}||${selctedQuery?.fieldId}||${selctedQuery?.sectionNbr}||${selctedQuery?.onRefModel}`
              );
              history.push(
                `/participant/${selctedQuery?.studyId}/${selctedQuery?.participantId?._id}`
              );
            }}
            className="text-xs text-blue-600 cursor-pointer flex gap-x-2 mt-2"
          >
            {t("missingWording.displayField")} <BiFileFind size={16} />
          </span>
        </div>
      </div>
      <div className="h-fit overflow-y-auto px-2">
        <div className="rounded-lg border	text border-teal-400 px-4 py-4  ">
          <div className="flex">
            <div className="w-1/2">
              <p>{t("Query.IdntChamp")}</p>
            </div>
            <div>
              {selctedQuery?.formDataId?.name +
                " --- " +
                selctedQuery?.formDataId?.sections[selctedQuery?.sectionNbr]
                  .sectionName}
            </div>
          </div>
          <div className="flex my-4">
            <div className="w-1/2">
              <p>{t("Query.InDntChampT")}</p>
            </div>
            <div>{selctedQuery.fieldName}</div>
          </div>

          <div className="flex  mt-4">
            <div className="w-1/2">
              <p>{t("Query.Createur")}</p>
            </div>
            <div>
              {selctedQuery?.isSystemGenerated
                ? t("missingWording.system")
                : "ARC"}
            </div>
          </div>
          <div className="flex items-center mb-2">
            <div className="md:w-1/2 xs:w-36 mt-4">
              <p>{t("missingWording.statut")}</p>
            </div>
            <div>
              {selctedQuery.status === "OPEN" && user?.type !== "writer" ? (
                <div className="mt-2">
                  <SelectComp
                    value={selectedStatusQuery}
                    onChange={(e) => {
                      setSelectedStatusQuery(e.target.value);
                    }}
                    className="text-sm -ml-2"
                    text={""}
                    options={[
                      { value: "OPEN", label: `${t("Query.Open")}` },
                      { value: "CLOSED", label: `${t("Query.Closed")}` },
                    ]}
                    disabled={selctedQuery.status === "CLOSED"}
                    style={{ height: "32px", width: "120px" }}
                  ></SelectComp>
                </div>
              ) : (
                <div className="mt-4">
                  {selctedQuery.status === "OPEN"
                    ? t("Query.Open")
                    : t("Query.Closed")}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="h-auto  ">
          <div
            className="min-w-full border rounded-lg mt-8 p-2  max-h-max 
            flex-col-reverse overflow-y-auto "
            style={{ minHeight: "150px", maxHeight: "160px" }}
          >
            {selctedQuery?.messages?.map((m: any) => {
              return m?.onModelFrom?.toLowerCase() === user?.type ||
                (user?.type === "cro" && m?.onModelFrom === "Arc") ? (
                <div className="flex flex-row-reverse w-full mt-2 mb-2">
                  <p
                    className=" px-4 py-2  max-w-max rounded-3xl flex gap-x-1"
                    style={{ color: "white", backgroundColor: "#4565f6" }}
                  >
                    {" "}
                    <p>
                      {" "}
                      {m?.isSystemGenerated && (
                        <SiProbot className="text-white mr-2 -ml-1" />
                      )}
                    </p>
                    {m?.messageContent?.replace(/\|\|/g, ",")}
                  </p>
                </div>
              ) : (
                <div className="flex  w-full mt-2 mb-2">
                  <p
                    className="bg-grey-500 px-4 py-2 bg-gray-300  max-w-max  rounded-3xl flex gap-x-1"
                    style={{ color: "black" }}
                  >
                    {" "}
                    <p>
                      {" "}
                      {m?.isSystemGenerated && (
                        <SiProbot className="text-white" />
                      )}
                    </p>
                    {m?.messageContent?.replace(/\|\|/g, ",")}
                  </p>
                </div>
              );
            })}
          </div>
          {selctedQuery?.status === "OPEN" && (
            <div className="flex w-full items-center">
              <div className="flex w-full  mt-2 p-2 justify-start border-2 border-neutral-500 rounded-lg">
                <textarea
                  name="name"
                  placeholder={`${t("Query.MessageInput")}`}
                  className="w-full"
                  onChange={(e) => setTextMsg(e?.target?.value)}
                  value={textMsg || ""}
                />
                <div className="mt-4 cursor-pointer">
                  <button
                    onClick={async () => {
                      let values = {
                        toUserId:
                          selctedQuery?.messages[
                            selctedQuery?.messages.length - 1
                          ]?.sentFrom !== user?._id
                            ? selctedQuery?.messages[
                                selctedQuery?.messages.length - 1
                              ]?.sentFrom
                            : selctedQuery?.messages.filter(
                                (item) => item.sentFrom !== user?._id
                              )[0].sentFrom,
                        textMessage: textMsg,
                        status: selectedStatusQuery,
                      };
                      const res: any = await updateQueryMessage(
                        selctedQuery?._id,
                        {
                          resolved: { ...values },
                        }
                      );

                      if (res) {
                        setShouldUpdate((up) => !up);
                        setSelectedQuery((prev) => {
                          return {
                            ...prev,
                            messages: res.messages,
                            status: res.status,
                          };
                        });
                        makeToast("success", t("missingWording.pft6"));
                        if (res?.status === "CLOSED") {
                          setTypeQuery("CLOSED");
                          setSelectedQuery();
                        }
                      }
                    }}
                    className={
                      textMsg && textMsg.length > 0
                        ? "border text-blue-600 border-blue-600 rounded-full p-2 self-center"
                        : "border text-gray-600 border-gray-600 rounded-full p-2 self-center"
                    }
                  >
                    <FiSend size={20} className="" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex w-full relative justify-center mt-2">
          {user?.type !== "writer" && selctedQuery?.status === "OPEN" && (
            <Button
              status="primary"
              className="px-2  py-2 mt-2 w-1/2"
              onClick={async () => {
                let values = {
                  toUserId:
                    selctedQuery?.messages[selctedQuery?.messages.length - 1]
                      ?.sentFrom,
                  textMessage: textMsg,
                  status: selectedStatusQuery,
                };
                const res: any = await updateQueryMessage(selctedQuery?._id, {
                  resolved: { ...values },
                });
                if (res) {
                  setShouldUpdate((up) => !up);
                  setSelectedQuery((prev) => {
                    return {
                      ...prev,
                      messages: res.messages,
                      status: res.status,
                    };
                  });
                  makeToast("success", t("missingWording.pft5"));
                  if (res?.status === "CLOSED") {
                    setTypeQuery("CLOSED");
                    setSelectedQuery();
                  }
                }
              }}
            >
              {t("missingWording.save")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QueryModal;
