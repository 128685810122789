import Icon from "../../../components/Icon";
import history from "../../../history";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { eproformAtom } from "../../../state/eproForm";
import { useTranslation } from "react-i18next";
import { getEproFormadataById, submitEproForm } from "../../../api/eproForms";
import { useForm } from "../../../hooks/FormGeneratorHooks";
import { ChakraProvider, Heading } from "@chakra-ui/react";
import {
  MultiStepsLayout,
  PageHeader,
} from "../../../components/SurveyGenerator/FormBuilder/Lyaout";
import StepperWrapper from "../../../components/SurveyGenerator/FormBuilder/StepperWrapper";
import { IntlProvider } from "react-intl";
import { default as frMsg } from "../../../config/constants/locales/fr-fr.json";
import {
  Formiz,
  FormizStep,
} from "../../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { resolver } from "../../../helper/FormGeneratorHelper/conditionalLogic";
import FormRenderer from "../../../components/SurveyGenerator/FormRenderer";
import { useEffect } from "react";
import Spinner from "../../../components/Spinner";
import { userAtom } from "../../../state/auth";
import makeToast from "../../../components/Snackbar";

const MyEproForms = () => {
  const params = useParams() as any;
  const { studyId, eproFormId } = params;
  const [user] = useAtom(userAtom) as any;
  console.log("🚀 ~ file: index.tsx:32 ~ MyEproForms ~ user:", user);
  const [eproForm, setEproForm] = useAtom(eproformAtom) as any;
  const { t } = useTranslation() as any;
  const form = useForm();

  const getEproFormById = async () => {
    try {
      //setLoading(true);
      const res = await getEproFormadataById(eproFormId, user?.tenantId);
      console.log("🚀 ~ file: index.tsx:35 ~ getEproFormById ~ res:", res);
      if (res) {
        setEproForm(res);
        //setLoading(false);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  useEffect(() => {
    getEproFormById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (values) => {
    try {
      let reska: any[] = eproForm?.sections;
      reska.map((section) => {
          section.fields.map((field) => {
            if (values.hasOwnProperty(`${field.field_name}`) && field.type === "Checkboxes" &&  Array.isArray(values[`${field.field_name}`]))
              values[`${field.field_name}`] =  values[`${field.field_name}`].join("||")
               
            section.status = "VALID_BY_WRITER";
          });
        });
  
      const res = await submitEproForm(eproFormId, user?.tenantId, null, values);
      if (res) {
        makeToast("success", t("missingWording.submitForm"));
      }
    } catch (error) {}
  };


  console.log("EPRO", eproForm);

  return !eproForm ? (
    <Spinner />
  ) : (
    <>
      <div className="icon link cursor-pointer flex items-center ">
        <Icon
          onClick={() => {
            history.push(`/eproForms/${studyId}`);
            setEproForm(null);
          }}
          name="direction-gauche"
          width={50}
          height={50}
          isIcon={true}
        />
        <div className="mb-2">
          <p
            className=""
            onClick={() => {
              history.push(`/eproForms/${studyId}`);
              setEproForm(null);
            }}
          >
            {t("missingWording.back")}
          </p>
        </div>
      </div>
      <ChakraProvider>
        <IntlProvider locale={"fr"} defaultLocale="fr" messages={frMsg}>
          <Formiz
            connect={form}
            key={form.resetKey}
            onValidSubmit={handleSubmit}
          >
            <div className="flex  h-full w-full mt-4 relative justify-center">
              <MultiStepsLayout submitLabel={t("missingWording.submitLabel")}>
                <PageHeader setShouldUpdate={undefined} isPreview={true}>
                  {eproForm?.name ? eproForm?.name : "#Titre de questionnaire"}
                  <Heading fontSize="lg" mb={4} mt={4}>
                    {eproForm?.description && (
                      <>
                        <ArrowRightIcon
                          height={"12px"}
                          width={"12px"}
                          mr={2}
                          mb={1}
                          color={"#4565f6"}
                        />
                        <>{eproForm?.description}</>
                      </>
                    )}
                  </Heading>
                </PageHeader>
                <div className="border  shadow-lg  p-4 w-full rounded-lg mb-4">
                  <StepperWrapper
                    title="Dots stepper"
                    dir="row"
                  ></StepperWrapper>

                  {eproForm.sections.map((step, i) => {
                    return (
                      <FormizStep
                        label={step.sectionName}
                        name={`${step.sectionName + "-" + step.stepNbr}`}
                        isEnabled={
                          step.conditionLogic && step.conditionLogic.status
                            ? resolver(step.conditionLogic, form.values)
                            : true
                        }
                      >
                        <FormRenderer
                          key={i}
                          elements={step.fields}
                          values={form.values}
                          form={form}
                          isPreview={true}
                          isEpro={true}
                          S
                        ></FormRenderer>
                      </FormizStep>
                    );
                  })}
                </div>
              </MultiStepsLayout>
            </div>
          </Formiz>
        </IntlProvider>
      </ChakraProvider>
    </>
  );
};
export default MyEproForms;
