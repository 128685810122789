/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getStudyConfig } from "../../../api/study";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import { studyWriterConfigAtom } from "../../../state/studyWriterConfig";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ParticipantFilter from "../../../components/ParticipantFilterInput";
import Input from "../../../components/Input";
import Button from "../../../components/ButtonUI";
import { updateEconsultByWriter } from "../../../api/study";
import { useFormik } from "formik";
import makeToast from "../../../components/Snackbar";

const EditEconsultModal = ({
  closeModal,
  refresh,
  eConsultId,
  motif,
  date,
  ParticipantId,
  setSeeMore,
}) => {
  const params = useParams() as any;
  const [listParticipants, setListParticipants] = useState([]) as any[];
  const [user] = useAtom(userAtom);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const { t } = useTranslation() as any;
  const [filter] = useState(studyWriterConfig?.isResponsable ? "byMe" : "byMe");
  const { studyId } = params;
  const now = new Date(date);
  const offsetMinutes = now.getTimezoneOffset();
  const adjustedDate = new Date(now.getTime() - offsetMinutes * 60000);

  const formattedDate = adjustedDate.toISOString().slice(0, 16);
  const formik = useFormik({
    initialValues: {
      dateConsultation: formattedDate,
      motif: motif,
      participantId: ParticipantId,
      studyId: studyId,
    },
    onSubmit: async (values) => {
      if (!values.dateConsultation)
        return makeToast("warning", t("eConsult.missdate"));
      if (!values.motif) return makeToast("warning", t("eConsult.missMotif"));
      if (!values.participantId)
        return makeToast("warning", t("eConsult.missparticipant"));
      const res = await updateEconsultByWriter(eConsultId, values);
      makeToast("success", t("eConsult.successEdit"));
      setSeeMore(false);
      if (res) {
        closeModal();
        refresh();
      }
    },
  });
  async function fetchStudyParticipants(): Promise<any> {
    try {
      let type = user?.type;
      setLoading(true);
      //  if (studyId && studyId !== null) setStudySelectedId(studyId);
      if (type === "writer") {
        let res = await getStudyConfig(
          studyId,
          page,
          10,
          filter,
          textSearch,
          false
        );
        if (res) {
          console.log(
            "🚀 ~ file: AddEconsultModal.tsx:49 ~ fetchStudyParticipants ~ res?.participants?.participants:",
            res?.participants?.participants
          );

          //   setNbrOfSkeletonsToDisplay(0);
          setListParticipants(res?.participants?.participants);
          setStudyWriterConfig(res?.studyConfig);
          setTotalResult(res?.participants?.count);
          setLoading(false);
        } else {
          setStudyWriterConfig(null);
          setLoading(false);
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:30 ~ fetchStudyConfig ~ error", error);
    }
  }

  useEffect(() => {
    if ((textSearch && textSearch.length > 2) || textSearch === "")
      fetchStudyParticipants();
    // else fetchStudyParticipants();
  }, [page, filter, textSearch]);

  return (
    <div className="">
      <h1 className="text-center mt-5 mb-6">{t("eConsult.EditEconsult")}</h1>{" "}
      <div className="flex gap-x-4">
        <div className="flex flex-col justify-center w-1/2">
          <label htmlFor="dateConsultation" className="text-sm px-2">
            {t("eConsult.selectDate")}
          </label>
          <input
            id="dateConsultation"
            name="dateConsultation"
            className="h-12 border w-full rounded px-4 my-2"
            type="datetime-local"
            onChange={formik.handleChange}
            value={formik.values.dateConsultation}
          />
        </div>
        <div className="flex flex-col justify-center w-1/2">
          {" "}
          <label htmlFor="dateInput" className="text-sm px-2">
            {t("eConsult.motif")}
          </label>
          <Input
            placeholder="Type le motif de la teleconsultation"
            id="motif"
            name="motif"
            className="h-12 border w-full rounded px-4 my-2 text-sm"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.motif}
          />
        </div>
      </div>
      {/* <Input /> */}
      <ParticipantFilter
        loading={loading}
        participants={listParticipants}
        totalResult={totalResult}
        page={page}
        setPage={setPage}
        setTextSearch={setTextSearch}
        setFieldValue={formik.setFieldValue}
        participantId={ParticipantId}
      />
      <div className="flex justify-between mt-10 pr-8 pl-8">
        <Button
          status="secondary"
          width="150px"
          height="50px"
          className="float-left mb-4 "
          onClick={closeModal}
        >
          {t("missingWording.cancel")}
        </Button>
        <Button
          status="primary"
          width="150px"
          height="50px"
          className="float-right mb-4"
          type="submit"
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          {t("missingWording.flb2")}
        </Button>
      </div>
    </div>
  );
};
export default EditEconsultModal;
