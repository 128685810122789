import React, { useState, useEffect } from "react";
import './styles.scss'
const ProgressBar = ({ progress, duration }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const progressWidth = (progress / 100) * 100;

    setWidth(progressWidth);

    return () => {};
  }, [progress]);

  return (
    <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
      <div className="animate-pulse h-full bg-blue-500" style={{ width: `${width}%` }}></div>
    </div>
  );
};

export default ProgressBar;
