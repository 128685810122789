/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Socket, io } from "socket.io-client";
import { Field, FieldArray, useFormik } from "formik";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { updateStudy } from "../../../api/study";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import makeToast from "../../../components/Snackbar";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import Tooltip from "../../../components/toolTip";
import { BASE_URL } from "../../../config/api";
import { tutorialModeAtom } from "../../../state/tutorialMode";
import { socketAtom } from "../../../state/socket";

function EditStudy({
  initialValues,
  studyId,
  setUpdateStudy,
  readOnly,
  isProduction = false,
}) {
  const { t } = useTranslation() as any;
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const validObj = Yup.object().shape({
    name: Yup.string().required(t("missingWording.requiredOrg")),
    description: Yup.string(),
  });
  const checkChange = (obj1: any, obj2: any) => {
    let res = false;
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        res = true;
      }
    }
    return res;
  };

  const onSubmit = async (values) => {
    try {
      const res = await updateStudy(values, studyId);
      if (res && res.study) {
        makeToast("success", `${t("DashboardCro.editSuccessStuy")}`);
        setUpdateStudy((up) => !up);
      } else {
        makeToast("error", `${t("DashboardCro.errorH")}`);
      }
    } catch (err) {
      console.log("[error] add Patient -on Submit :", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: initialValues.name,
      description: initialValues.description || "",
      centerAcess: initialValues.centerAcess,
      anonym: initialValues.anonym,
      randomisation: initialValues.randomisation ?? false,
      ePro: initialValues.ePro ?? false,
      eConsult: initialValues.eConsult ?? false,
      privateCenter: initialValues.privateCenter ?? false,
      groups: [],
    },
    onSubmit,
    validationSchema: validObj,
  });

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;
  const activeAnonymisationChange = (checked) => {
    setFieldValue("anonym", checked);
  };
  const activeCenterAccesChange = (checked) => {
    setFieldValue("centerAcess", checked);
  };
  const activeRandomisationChange = (checked) => {
    if (isProduction) {
      return null;
    } else setFieldValue("randomisation", checked);
  };
  const activeEproConfigChange = (checked) => {
    setFieldValue("ePro", checked);
    if (!checked && values.eConsult) setFieldValue("eConsult", false);
  };

  const activeEconsultConfigChange = (checked) => {
    setFieldValue("eConsult", checked);
  };
  const privateCenterAccesChange = (checked) => {
    setFieldValue("privateCenter", checked);
  };
  useEffect(() => {
    if (!socket) {
      const mySocket: Socket = io(BASE_URL, {
        // forceNew: true,
        reconnectionAttempts: 100,
        timeout: 10000,
        transports: ["websocket"],
        auth: {
          token: localStorage.getItem("token"),
        },
      });

      setSocket(mySocket);
    } else {
      if (!socket.connected) socket.connect();

      return () => {
        if (socket) {
          socket.disconnect();
          socket.removeAllListeners("");
          socket.close();
          console.log("[DEBUG] Socket was closed!");
        }
      };
    }
  }, [socket]);
  return (
    <div className="mt-8">
      <div className="flex  flex-row w-full items-center justify-between">
        <div className="w-72 EditStudy_nameSection ">
          <div>
            <Input
              id="name"
              placeholder={t("DashboardCro.Name")}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              inlineLabel={false}
              onfocus={true}
              className="w-full -mb-2 "
              labelText={t("DashboardCro.Netu")}
            />
            <span className="text-xs text-red-300 ml-4 ">{errors.name}</span>
          </div>
          <Input
            id="description"
            placeholder="..."
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            inlineLabel={false}
            onfocus={true}
            labelText={t("DashboardCro.dStudy")}
          />
        </div>
        <div className="h-44 w-0.5 bg-gray-300"></div>
        <div className="w-1/2 flex flex-col gap-y-6 mb-4 ">
          <div className="flex flex-row items-center  EditStudy_parm1">
            <label className="w-5/6 ">
              <Tooltip
                tooltipText={t("missingWording.anonymDef")}
                position={"top"}
                clsText={{ minWidth: "120px", maxWidth: "280px" }}
              >
                <label className="w-5/6 font-semibold">
                  {" "}
                  {t("missingWording.anonymizationConfig")}
                </label>
              </Tooltip>
            </label>

            <ToggleSwitch
              id="anonym"
              disabled={false}
              small
              checked={values.anonym}
              onChange={activeAnonymisationChange}
              optionLabels={["Disable", "Enable"]}
            />
          </div>
          <div className="flex flex-row  items-center EditStudy_parm3 ">
            <label className="w-5/6 ">
              {" "}
              <Tooltip
                tooltipText={t("missingWording.acessParticipantDef")}
                clsText={{ minWidth: "120px", maxWidth: "280px" }}
                position={"top"}
              >
                <label className="w-5/6 font-semibold">
                  {" "}
                  {t("missingWording.accessConfig")}{" "}
                </label>
              </Tooltip>
            </label>

            <ToggleSwitch
              id="centerAcess"
              disabled={false}
              small
              checked={values.centerAcess}
              onChange={activeCenterAccesChange}
              optionLabels={["Disable", "Enable"]}
            />
          </div>
          <div className="flex flex-row  items-center EditStudy_parm3 ">
            <label className="w-5/6 ">
              {" "}
              <Tooltip
                tooltipText={t("missingWording.privateCentertDef")}
                clsText={{ minWidth: "120px", maxWidth: "280px" }}
                position={"top"}
              >
                <label className="w-5/6 font-semibold">
                  {" "}
                  {t("missingWording.privateCenter")}{" "}
                </label>
              </Tooltip>
            </label>

            <ToggleSwitch
              id="privateCenter"
              disabled={false}
              small
              checked={values.privateCenter}
              onChange={privateCenterAccesChange}
              optionLabels={["Disable", "Enable"]}
            />
          </div>

          {isProduction ? (
            <div
              className={`${
                isProduction && "cursor-not-allowed"
              } flex flex-row  items-center EditStudy_parm2 `}
            >
              <label className="w-5/6 ">
                <Tooltip
                  clsText={{ width: "280px" }}
                  tooltipText={t("missingWording.noRandoModif")}
                  position={"top"}
                >
                  <label className="w-5/6 font-semibold text-gray-400">
                    {t("missingWording.randomize")}
                  </label>{" "}
                </Tooltip>
              </label>

              <ToggleSwitch
                id="randomisation"
                disabled={true}
                small
                isProduction={isProduction}
                checked={values.randomisation}
                onChange={activeRandomisationChange}
                optionLabels={["Disable", "Enable"]}
              />
            </div>
          ) : (
            <div
              className={`${
                isProduction && "cursor-not-allowed "
              } flex flex-row  items-center EditStudy_parm2 `}
            >
              {" "}
              <label className="w-5/6 ">
                <Tooltip
                  tooltipText={t("missingWording.randomDef")}
                  clsText={{ minWidth: "120px", maxWidth: "280px" }}
                  position={"top"}
                >
                  <label className="w-5/6 font-semibold">
                    {" "}
                    {t("missingWording.randomize")}
                  </label>
                </Tooltip>
              </label>
              <ToggleSwitch
                id="randomisation"
                disabled={false}
                small
                isProduction={isProduction}
                checked={values.randomisation}
                onChange={activeRandomisationChange}
                optionLabels={["Disable", "Enable"]}
              />
            </div>
          )}

          <div className="flex flex-row  items-center EditStudy_parm4  ">
            <label className="w-5/6 ">
              {" "}
              <Tooltip
                tooltipText={t("missingWording.eProDef")}
                clsText={{ minWidth: "120px", maxWidth: "280px" }}
                position={"top"}
              >
                <label className="w-5/6 font-semibold">
                  {" "}
                  {t("missingWording.eProConfig")}{" "}
                </label>
              </Tooltip>
            </label>
            <ToggleSwitch
              id="ePro"
              disabled={false}
              small
              checked={values.ePro}
              onChange={activeEproConfigChange}
              optionLabels={["Disable", "Enable"]}
            />
          </div>
          {values.ePro && (
            <div className="flex flex-row   items-center EditStudy_parm4  ">
              <label className="w-5/6 ">
                {" "}
                <Tooltip
                  tooltipText={t("eConsult.eConsultDef")}
                  clsText={{ minWidth: "120px", maxWidth: "280px" }}
                  position={"top"}
                >
                  <label className="w-5/6 font-semibold">
                    {" "}
                    {t("eConsult.eConsult")}{" "}
                  </label>
                </Tooltip>
              </label>
              <ToggleSwitch
                id="eConsult"
                disabled={false}
                small
                checked={values.eConsult}
                onChange={activeEconsultConfigChange}
                optionLabels={["Disable", "Enable"]}
              />
            </div>
          )}
        </div>
      </div>

      <Button
        status="primary"
        width="150px"
        height="50px"
        className="float-right mt-16 mb-4"
        type="submit"
        disabled={!checkChange(initialValues, formik.values) || readOnly}
        onClick={() => {
          handleSubmit();
        }}
      >
        {t("DashboardCro.Save")}
      </Button>
      {process.env.NODE_ENV === "development" && (
        <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 fixed bottom-0 left-0 z-50  ">
          <Button
            status="primary"
            onClick={() => socket.emit("resetChallenge2", {})}
            className="text-xs px-2 py-1"
          >
            Start Tour
          </Button>
        </div>
      )}
    </div>
  );
}

export default EditStudy;
