import axios from "axios";
import { GET_PUBLIC_STUDY, WRITER_PUBLIC_REGISTER_URL } from "../config/api";
export async function getPublicStudy({ studyId, tenantId}) {
  try {
    const res = (await axios.get(
      `${GET_PUBLIC_STUDY}${tenantId}/${studyId}`
    )) as any;
if (res && res.data)
 return res.data;
 else return null
  } catch (error) {
    return Promise.reject(error);
  }
}
export const addPublicWriter = async (values, tenantId, studyId) => {
    let res;
    try {
    
      let payload = { ...values };
      res = await  axios.post(
        `${WRITER_PUBLIC_REGISTER_URL}/${tenantId}/${studyId}`,
        payload
      );
  
      return res;
    } catch (error) {
        Promise.reject(error);
      return res 
    }
  };
