/* eslint-disable react-hooks/exhaustive-deps */
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getEconsultByParticipant, getEconsultByWriter } from "../../api/study";
import Button from "../../components/ButtonUI";
import MyModal from "../../components/New-Modal";
import { userAtom } from "../../state/auth";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import EconsultCard from "./EconsultCard";
import EconsultSoonCard from "./EconsultSoonCard";
import AddEconsultModal from "./Modals/AddEconsultModal";
import "./styles.scss";
import { studyParticipantConfigAtom } from "../../state/studyParticipantConfig";

export const ParticipantEconsult = ({}) => {
  const params = useParams() as any;
  const { studyId } = params;
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [newEconsultModal, setNewEconsultModal] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [eConsultObject, setEConsultObject] = useState() as any;
  const [studyParticipantConfig, setStudyParticipantConfig] = useAtom(
    studyParticipantConfigAtom
  );

  const getEconsultByParticpantCall = async () => {
    try {
      let res: any = [];
      setLoading(true);
      switch (user?.type) {
        default:
          res = await getEconsultByParticipant({ studyId: studyId });
          if (res) {
            setEConsultObject(res);
            setStudyParticipantConfig(res?.participantStudy);
          }
          setLoading(false);
          break;
      }
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ file: index.tsx:22 ~ fetchListEvents ~ error", error);
    }
  };
  useEffect(() => {
    if (studyId) setStudySelectedId(studyId);
    getEconsultByParticpantCall();
  }, [shouldUpdate]);

  return (
    <>
      <header className="flex justify-between items-center w-full flex-wrap ">
        <div className="title-container">
          <h1 className="pageTitle">{t("eConsult.eConsultTitle")}</h1>
        </div>
      </header>
      {seeMore ? (
        <div className="mt-16 flex flex-wrap justify-between  md:flex-row flex-col ">
          <div className="w-full md:w-4/12">
            {/* Card next e-consult */}
            {(eConsultObject?.soonConsults || [])[0] && (
              <div>
                <p className="font-bold my-5">{t("eConsult.nexteConsult")}</p>
                <EconsultSoonCard
                  studyId={studyId}
                  eConsultId={(eConsultObject?.soonConsults || [])[0]?._id}
                  eConsult={(eConsultObject?.soonConsults || [])[0]}
                  patientPublicId={
                    (eConsultObject?.soonConsults || [])[0]?.writer?.firstName
                  }
                  patientId={
                    (eConsultObject?.soonConsults || [])[0]?.participant?._id
                  }
                  progress={
                    (eConsultObject?.soonConsults || [])[0]?.participant
                      ?.progress
                  }
                  motif={(eConsultObject?.soonConsults || [])[0]?.motif}
                  date={
                    (eConsultObject?.soonConsults || [])[0]?.dateConsultation
                  }
                />
              </div>
            )}
            {/* History e-consult */}
            <div className="">
              <p className="font-bold mt-5">{t("eConsult.historyeConsult")}</p>
              <p className="my-0.5 max-w-max">
                <span
                  className="text-blue-400 cursor-pointer text-sm"
                  onClick={() => setSeeMore((prev) => !prev)}
                >
                  {t("eConsult.seeLess")}
                </span>
              </p>
              <div className="h-screen overflow-y-auto  pr-5 flex flex-col gap-6 ">
                {eConsultObject?.pastConsults?.map((el) => (
                  <EconsultCard
                    patientPublicId={el?.writer?.firstName}
                    motif={el?.motif}
                    date={el?.dateConsultation}
                    patientId={el?.participant?._id}
                    eConsultId={el?._id}
                    setShouldUpdate={setShouldUpdate}
                    status={el?.status}
                    setSeeMore={setSeeMore}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:w-7/12 ">
            {/* Today e-consult */}
            <div className="">
              <p className="font-bold my-5">{t("eConsult.today")}</p>
              {!_.isEmpty(eConsultObject?.todayConsults) ? (
                <div className="max-h-96 overflow-y-auto pr-5 flex flex-col gap-6 mb-5">
                  {eConsultObject?.todayConsults?.map((el) => (
                    <EconsultCard
                      patientPublicId={el?.writer?.firstName}
                      motif={el?.motif}
                      date={el?.dateConsultation}
                      patientId={el?.participant?._id}
                      eConsultId={el?._id}
                      setShouldUpdate={setShouldUpdate}
                      status={el?.status}
                      setSeeMore={setSeeMore}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.noTodayeconsult")}
                  </span>
                </div>
              )}
            </div>
            <div className="">
              <p className="font-bold my-5">{t("eConsult.thisWeek")}</p>
              {/* this week e-consult */}
              {!_.isEmpty(eConsultObject?.thisWeekConsults) ? (
                <div className="max-h-96 overflow-y-auto pr-5 flex flex-col gap-6 mb-5">
                  {eConsultObject?.thisWeekConsults?.map((el) => (
                    <EconsultCard
                      patientPublicId={el?.writer?.firstName}
                      motif={el?.motif}
                      date={el?.dateConsultation}
                      patientId={""}
                      eConsultId={""}
                      setShouldUpdate={undefined}
                      status={el?.status}
                      setSeeMore={setSeeMore}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.NoWeekEconsult")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8 flex flex-wrap justify-between md:flex-row flex-col ">
          <div className="w-full md:w-4/12 ">
            {/* Card next e-consult */}
            {(eConsultObject?.soonConsults || [])[0] && (
              <div>
                <p className="font-bold my-5">{t("eConsult.nexteConsult")}</p>
                <EconsultSoonCard
                  studyId={studyId}
                  eConsultId={(eConsultObject?.soonConsults || [])[0]?._id}
                  eConsult={(eConsultObject?.soonConsults || [])[0]}
                  patientPublicId={
                    (eConsultObject?.soonConsults || [])[0]?.writer?.firstName
                  }
                  patientId={
                    (eConsultObject?.soonConsults || [])[0]?.participant?._id
                  }
                  progress={
                    (eConsultObject?.soonConsults || [])[0]?.participant
                      ?.progress
                  }
                  motif={(eConsultObject?.soonConsults || [])[0]?.motif}
                  date={
                    (eConsultObject?.soonConsults || [])[0]?.dateConsultation
                  }
                />
              </div>
            )}
            {/* History e-consult */}
            <div className="">
              <p className="font-bold my-5">{t("eConsult.historyeConsult")}</p>
              {!_.isEmpty(eConsultObject?.pastConsults) ? (
                <>
                  <div className=" pr-5 flex flex-col gap-6 mb-5">
                    {eConsultObject?.pastConsults?.slice(0, 3)?.map((el) => (
                      <EconsultCard
                        patientPublicId={el?.writer?.firstName}
                        motif={el?.motif}
                        date={el?.dateConsultation}
                        patientId={el?.participant?._id}
                        eConsultId={el?._id}
                        setShouldUpdate={setShouldUpdate}
                        status={el?.status}
                        setSeeMore={setSeeMore}
                      />
                    ))}
                  </div>
                  <p className=" max-w-max">
                    <span
                      className="text-blue-400 cursor-pointer text-sm"
                      onClick={() => setSeeMore((prev) => !prev)}
                    >
                      {t("eConsult.seeMore")}
                    </span>
                  </p>
                </>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.noPastEconsult")}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-7/12">
            {/* Today e-consult */}
            <div className="">
              <p className="font-bold my-5">{t("eConsult.today")}</p>
              {!_.isEmpty(eConsultObject?.todayConsults) ? (
                <div className="max-h-80 overflow-y-auto pr-5 flex flex-col gap-6 mb-5">
                  {eConsultObject?.todayConsults?.map((el) => (
                    <EconsultCard
                      patientPublicId={el?.writer?.firstName}
                      motif={el?.motif}
                      date={el?.dateConsultation}
                      patientId={el?.participant?._id}
                      eConsultId={el?._id}
                      setShouldUpdate={setShouldUpdate}
                      status={el?.status}
                      setSeeMore={setSeeMore}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.noTodayeconsult")}
                  </span>
                </div>
              )}
            </div>
            <div className="">
              <p className="font-bold my-5">{t("eConsult.thisWeek")}</p>
              {/* this week e-consult */}
              {!_.isEmpty(eConsultObject?.thisWeekConsults) ? (
                <div className="max-h-80 overflow-y-auto pr-5 flex flex-col gap-6 mb-5">
                  {eConsultObject?.thisWeekConsults?.map((el) => (
                    <EconsultCard
                      patientPublicId={el?.writer?.firstName}
                      motif={el?.motif}
                      date={el?.dateConsultation}
                      patientId={el?.participant?._id}
                      eConsultId={el?._id}
                      setShouldUpdate={setShouldUpdate}
                      status={el?.status}
                      setSeeMore={setSeeMore}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.NoWeekEconsult")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <MyModal showModal={newEconsultModal} closeModal={setNewEconsultModal}>
        <AddEconsultModal
          closeModal={() => setNewEconsultModal(false)}
          refresh={() => setShouldUpdate((up) => !up)}
          setSeeMore={setSeeMore}
        />
      </MyModal>
    </>
  );
};
