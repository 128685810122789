import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStudyCenters } from "../../../api/study";
import Modal from "../../../components/ModalUI";
import { listCenterAtom } from "../../../state/listCenter";
import EditableCard from "./CardCenter";
import DeleteCentreModal from "./Modals/deleteCentreModal";

const Centers: React.FC<any> = ({ studyId ,readOnly,centerTuto}) => {
  const [listCenters, setListCenters] = useAtom(listCenterAtom);
  console.log("🚀 ~ file: index.tsx:12 ~ listCenters:", listCenters)
  const [shouldUpdate, setShouldUpdate] = useState(false);
  // const [listCenters, setListCenters] = useState([]);
  const [deleteCentreModalState, setDeleteCentreModalState] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState("") as any;
  const [selectedCenterName, setSelectedCenterName] = useState("") ;
  const { t } = useTranslation() as any;
  

  async function fetchMyAPI(): Promise<void> {
    const centers = await getStudyCenters(studyId);
    setListCenters(centers);
  }

  useEffect(() => {
    fetchMyAPI();
  }, [shouldUpdate]);
 
  return (
    <div className="w-full centresEtude ">
      <div className="flex md:flex-row xs:flex-col relative w-full gap-x-2">
        <div className="md:w-2/5 xs:w-full md:px-3 md:mx-2 xs:px-3 xs:mx-0 EditStudy_centerPanel">
          <h3 className="text-center">{t("missingWording.newCenter")}</h3>
          
        { !readOnly && <EditableCard
            key={2}
            centerDetails={null}
            isAdding={true}
            deleteCentreModalState={deleteCentreModalState}
            setDeleteCentreModalState={setDeleteCentreModalState}
            setSelectedCenter={setSelectedCenter}
            newCenterId={selectedCenter}
            setListCenters={setListCenters}
            studyId={studyId}
            centerTuto={centerTuto}
            setSelectedCenterName={setSelectedCenterName}
          />}
        </div>
        <div className="md:w-3/5 xs:w-full xs:mt-4 md:mt-0  ">
          <h3 className="text-center">{t("missingWording.listOfCenter")}</h3>
          <div
            className={` flex flex-wrap max-h-screen min-h-96  w-full border-l-4  justify-center px-6 overflow-y-scroll`}
          >
            {listCenters.length > 0 ? (
              listCenters.map((c: any, i: number) => (
                <EditableCard
                  key={i}
                  isAdding={false}
                  centerDetails={c}
                  deleteCentreModalState={deleteCentreModalState}
                  setDeleteCentreModalState={setDeleteCentreModalState}
                  setSelectedCenter={setSelectedCenter}
                  newCenterId={selectedCenter}
                  setListCenters={setListCenters}
                  studyId={studyId}
                  setSelectedCenterName={setSelectedCenterName}
                />
              ))
            ) : (
              <div className="w-full flexCenter mt-24">
                <h3>{t("Centre.NoCenterStudy")}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal showModal={deleteCentreModalState} className="px-10 py-10">
        <DeleteCentreModal
          setDeleteCentreModal={setDeleteCentreModalState}
          setShouldUpdate={setShouldUpdate}
          centreId={selectedCenter}
          studyId={studyId}
          deleteCentreModal
          centreName={selectedCenterName}
        />
      </Modal>
    </div>
  );
};

export default Centers;
