import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const tutorialModeAtom = atomWithStorage("tutorialMode", true); // Initial value is false
export const createTutoFormAtom = atom(false); // Initial value is false
export const pageSettingTutoAtom = atom(false);

export const closePageSettingTutoAtom = atom(false);

export const driveJsEventListnerAtom = atom(null);
