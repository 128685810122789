import { useEffect, useRef } from "react";
import SignaturePad from "react-signature-canvas";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";
import { useField } from "../../../hooks/FormGeneratorHooks";

const Signature = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const signRef: any = useRef({});
  useEffect(() => {
    if (value && signRef && signRef.current.isEmpty()) {
      signRef.current.clear();
      signRef.current.fromDataURL(value);
    }
  }, [value]);

  const clear = () => {
    signRef.current.clear();
    setValue(null);
  };

  const onSignatureEnd = (e) => {
    setValue(signRef.current.toDataURL("image/png"));
  };

  return (
    <div>
      <p style={{ fontSize: "16px" }} className=" my-2">
        {props.label}
      </p>
      <div
        style={{
          width: `${props.width ? props.width : "80"}px`,
          height: `${props.height ? props.height : "40"}px`,
          border: "1px solid #aaa",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <SignaturePad
          disabled={props.readOnly ? props.readOnly : false}
          ref={signRef}
          onEnd={(e) => {
            onSignatureEnd(e);
          }}
          canvasProps={{
            width: props.width ? props.width : "80",
            height: props.height ? props.height : "40",
          }}
        ></SignaturePad>
        <Button
          type="button"
          onClick={clear}
          style={{ position: "absolute", right: 0, bottom: 0 }}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </div>
    </div>
  );
};

Signature.defaultProps = {
  width: 300,
  height: 150,
};
Signature.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default Signature;
