import { Form, Formik } from "formik";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  createParicipant,
  getRandomisationConfig,
  getStudyCenterInfo,
  getStudyCenters,
} from "../../../api/study";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import SelectComp from "../../../components/Select";
import makeToast from "../../../components/Snackbar";
import { userAtom } from "../../../state/auth";
import { studyWriterConfigAtom } from "../../../state/studyWriterConfig";
import Spinner from "../../../components/Spinner";
import { testModeAtom } from "../../../state/testModeAtom";
import { socketAtom } from "../../../state/socket";

type Props = {
  action: Function | any;
  handleLoadingInclusion?: Function;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  participantsCount: number;
};

export const AddParticipant: React.FC<Props | any> = ({
  setOpenModal,
  action,
  handleLoadingInclusion,
  participantsCount,
}) => {
  const [user] = useAtom(userAtom);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );
  const params = useParams() as any;
  const { studyId } = params;
  const [loadStudyInfos, setLoadStudyInfos] = useState(false);
  const [shouldUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listCenters, setListCenters] = useState([]) as any[];
  const [listGroups, setListGroups] = useState([]) as any[];
  const [participantCountByCenter, setParticipantCountByCenter] = useState(0);
  const { t } = useTranslation() as any;
  const [randomConfig, setRandomConfig] = useState() as any;
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const [socket] = useAtom(socketAtom) as any;

  const _getCenter = () => {
    const newCount: number = participantCountByCenter + 1;
    let string = testMode
      ? "Test" + newCount.toString().padStart(4, "0")
      : newCount.toString().padStart(4, "0");
    return loadStudyInfos
      ? "..."
      : studyWriterConfig?.centerId?.abreviation + string;
  };
  async function fetchMyAPI(): Promise<void> {
    setLoadStudyInfos(true);
    const countParticipantByCenter = await getStudyCenterInfo(
      studyId,
      studyWriterConfig?.centerId._id,
      testMode
    );
    if (
      countParticipantByCenter &&
      (countParticipantByCenter.participantCount ||
        countParticipantByCenter.participantCount == 0)
    ) {
      setLoadStudyInfos(false);
    }
    setParticipantCountByCenter(countParticipantByCenter.participantCount);
    setListCenters(
      studyWriterConfig?.centerId
        ? [
            {
              label: studyWriterConfig?.centerId.name,
              value: studyWriterConfig?.centerId._id,
            },
          ]
        : []
    );
  }
  async function fetchMyAPI2(): Promise<void> {
    try {
      const randomisationConfig = await getRandomisationConfig(
        studyWriterConfig?.studyId?.randomisationConfig
      );
      setRandomConfig(randomisationConfig?.config);
      console.log(
        "🚀 ~ file: index.tsx:88 ~ fetchMyAPI2 ~ randomisationConfig:",
        randomisationConfig
      );
    } catch (error) {
      makeToast("error", "err");
    }
  }

  useEffect(() => {
    fetchMyAPI();
    if (studyWriterConfig?.studyId?.randomisationConfig) fetchMyAPI2();
  }, [shouldUpdate]);

  useEffect(() => {
    if (randomConfig && !randomConfig.auto && randomConfig.onCreation) {
      const formattedGroups = randomConfig.groups.map((group) => ({
        label: group.groupName,
        value: group._id,
      }));
      setListGroups(formattedGroups);
    } else {
      setListGroups([]);
    }
  }, [randomConfig]);

  return (
    <div id="createParticipantModal">
      <Formik
        initialValues={{
          publicId: _getCenter() || "",
          centerId: studyWriterConfig?.centerId._id || "",
          groupId: "",
          studyId: studyId,
          organisationId: user?.organisationId,
          email: "",
          lastName: "",
          firstName: "",
          testMode: testMode,
        }}
        onSubmit={async (values) => {
          try {
            if (isSubmitting) {
              return;
            } else {
              setIsSubmitting(true);

              const res = await createParicipant(values, socket);
              debugger;

              if (!socket) {
                if (res) {
                  makeToast("success", `${t("Participant.successAdd")}`);
                  setIsSubmitting(false);
                  setOpenModal(false);
                  action();
                } else {
                  setIsSubmitting(false);
                  makeToast("warning", `${t("Participant.errorAdd")}`);
                }
              } else {
                setIsSubmitting(false);
                makeToast("info", `${t("Participant.socketSend")}`);
                setOpenModal(false);

                if (handleLoadingInclusion) handleLoadingInclusion();
              }
            }
          } catch (err) {
            console.log("[error] add Patient -on Submit :", err);
          }
        }}
      >
        {(props) => {
          const {
            values,
            isValid,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <Form>
              <div className="flex flex-row  w-96  justify-center items-baseline">
                <h1 className="mb-10">{t("Participant.addParti")} </h1>{" "}
                {testMode && (
                  <p className="mx-3 px-2 mb-5 bg-green-300 text-xs w-32 h-5 rounded-lg text-center">
                    {" "}
                    {t("missingWording.testMode")}
                  </p>
                )}
              </div>
              <div className="">
                <label className="font-semibold p-2">
                  {t("Participant.Center")}
                </label>
                <SelectComp
                  id="centerId"
                  onChange={(e) => {
                    // handleChange(e.target.value);
                    setFieldValue("centerId", e.target.value);
                    setFieldValue("publicId", _getCenter());
                  }}
                  value={loadStudyInfos ? "..." : values.centerId}
                  className="min-w-full mb-8"
                  text={"..."}
                  options={listCenters}
                  disabled={studyWriterConfig?.centerId._id ? true : false}
                ></SelectComp>

                {studyWriterConfig?.studyId?.randomisationConfig &&
                  randomConfig &&
                  !randomConfig.auto &&
                  randomConfig.onCreation && (
                    <div>
                      <label className="font-semibold p-2">
                        {t("Participant.Group")}
                      </label>
                      <SelectComp
                        id="groupId"
                        onChange={(e) => {
                          // handleChange(e.target.value);
                          setFieldValue("groupId", e.target.value);
                        }}
                        value={values.groupId}
                        className="min-w-full mb-8"
                        text={t("Participant.selectGroup")}
                        options={listGroups}
                      ></SelectComp>
                    </div>
                  )}
                <label className="font-semibold p-2">
                  {t("Participant.IdntfPublic")}
                </label>
                <Input
                  id="publicId"
                  placeholder={t("Participant.IdntfPublic")}
                  disabled
                  value={_getCenter()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  className="w-full mb-8"
                  labelText={t("Participant.IdntfPublic")}
                />
                {studyWriterConfig?.studyId.ePro === true && (
                  <>
                    <label className="font-semibold p-2">{"Email"}</label>
                    <Input
                      id="email"
                      placeholder={"email"}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inlineLabel={false}
                      onfocus={true}
                      className="w-full mb-2"
                      // labelText={'First Name'}
                    />
                  </>
                )}
                {studyWriterConfig?.studyId.anonym === false && (
                  <>
                    <label className="font-semibold p-2">
                      {t("missingWording.nLastName")}
                    </label>
                    <Input
                      id="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inlineLabel={false}
                      onfocus={true}
                      className="w-full mb-8"
                      // labelText={'Last Name'}
                    />
                    <label className="font-semibold p-2">
                      {t("missingWording.nFirstName")}
                    </label>
                    <Input
                      id="firstName"
                      placeholder={t("Participant.IdntfPublic")}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inlineLabel={false}
                      onfocus={true}
                      className="w-full mb-2"
                      // labelText={'First Name'}
                    />
                  </>
                )}
              </div>
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setOpenModal(false)}
              >
                {t("Participant.Cancel")}
              </Button>

              <Button
                id="SaveFormbtn"
                status="secondary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                type="submit"
                disabled={isSubmitting || !isValid}
                onClick={() => {
                  if (isSubmitting) {
                    return;
                  }

                  handleSubmit();
                }}
              >
                {t("Participant.Success")}
              </Button>

              {/* </form> */}
            </Form>
            
          );
        }}
      </Formik>
    </div>

    // </ChakraProvider>
  );
};

export default AddParticipant;
