export const Participant = {
  fr: {
    IDpublic: "Identifiant",
    Statut: "Statut",
    Progression: "Progression",
    LastModif: "Dernière modification",
    aucunPart: "Vous n'avez aucun participant",
    aucunEpro: "Vous n'avez aucun formulaire ePRO",
    successAdd: "La création du participant a été effectuée avec succès",
    errorAdd:
      "Une erreur est survenue lors de l'ajout du participant. Merci de réessayer ultérieurement.",
    surveyError:
      "Aucun formulaire configuré pour cette l'étude. Merci de contacter le responsable concerné.",
    successAdds: "La création des participants a été effectuée avec succès",
    errorAdds:
      "Une erreur est survenue lors de l'ajout des participants. Merci de réessayer ultérieurement.",
    socketSend: "Demande d'inclusion d'un nouveau participant envoyée.",
    socketSends: "Demande d'inclusion de nouveaux participants envoyée.",
    addParti: "Nouveau participant",
    Center: "Centre",
    ListCenter: "Liste des centres",
    IdntfPublic: "Identifiant public",
    Cancel: "Annuler",
    Success: "Enregistrer",
    selectGroup: "Veuillez sélectionner un groupe",
    Stats: "Exporter PDF",
    createdBy: "Créé par",
    inpMs: "Par centre ou par identifiant",
    Group: "Groupe",
    init: "En attente",
    expired: "expiré",
    enCours: "En cours",
    valid: "Validé",
    resendLink: "Vous souhaitez envoyer un nouveau lien au participant ?",
    sucessUpdate: "mise à jour fait avec succès",
    failedUpdate: "Une erreur est survenu. Merci de réessayer plus tard.",
    byFormName: "Par nom du formulaire",
    formName: "Nom ",
    verifFile: "Verifier votre fichier",
    eproCreationTitle: "Ajouter à partir du fichier",
    eproImportOnMass: "Importer vos participants en masse",
    eproImportFile: "Télécharger un fichier",
    eproSelectFile: "Veuillez sélectionner un fichier xlsx ou csv",
    eproImportedParticipant: "participants importés",
    BulkCreation: "Importer",
    fullName: "Nom complet",
    group: "Groupe",

    mail: "E-mail",
    sampleFileExel: "Exemple de fichier",
    sampletemplateExel: "Modèle à remplir",
    identifiant: "IDENTIFIANT",
  },
  en: {
    identifiant: "IDENTIFIER",

    formName: "Name",
    sampleFileExel: "Sample file",
    sampletemplateExel: "Fill-in template",
    byFormName: "By form Name",
    surveyError:
      "No form configured for this study. Please contact the person in charge.",
    failedUpdate: "An error has occurred. Please try again later.",
    sucessUpdate: "successfully updated",
    resendLink: " Want to send a new link to the participant ?",
    inpMs: "By center or by ID",
    Stats: "PDF Export",
    IDpublic: "ID public ",
    Statut: "Status",
    Progression: "Progression",
    LastModif: "Last update",
    aucunPart: "You have no participants",
    aucunEpro: "You have no ePRO forms",
    createdBy: "Created by",
    init: "Waiting",
    expired: "expired",
    enCours: "Progress",
    valid: "Valid",
    selectGroup: "Please select a group",
    Group: "Group",
    successAdd: "Participant added successfully",
    errorAdd: "Unknown error. Failed to add participant.",
    successAdds: "Participants have been added successfully",
    errorAdds: "Unknown error. Failed to add participants.",
    socketSend: "Participant inclusion request sent.",
    socketSends: "Participants inclusion request sent.",
    addParti: "New participant",
    Center: "Center",
    ListCenter: "List of centers",
    IdntfPublic: "Public ID",
    Cancel: "Cancel",
    Success: "Save",
    verifFile: "Check your file",
    eproCreationTitle: "Add from file",
    eproImportOnMass: "Import your participants in bluk",
    eproImportFile: "Upload file",
    eproSelectFile: "Please select an xlsx or csv file",
    eproImportedParticipant: "imported participants",
    BulkCreation: "Import",
    fullName: "Full name",
    group: "Group",
    mail: "E-mail",
  },
};
