import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { Router } from "react-router-dom";
import { verify } from "./api/auth";
import LiveChat from "./components/LiveChat";
import Navigator from "./components/Navigator";
import makeToast from "./components/Snackbar";
import Spinner from "./components/Spinner";
import { Client } from "./helper/apiMethods";
import history from "./history";
import "./i18n";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { userAtom } from "./state/auth";
import "./theme.scss";
import { User, UserRole } from "./types/user";

const App: React.FC = () => {
  const [loading, setlLading] = useState(false);
  const { t } = useTranslation() as any;

  const [user, setUser] = useAtom<User | any>(userAtom) as any;

  useEffect(() => {
    let local = localStorage.getItem("i18nextLng");
    
    if (!local) {
      let lang = navigator.language;
    

      if (lang === "fr-FR" || lang === "fr") {
        localStorage.setItem("local", "fr");
         i18n.changeLanguage('fr');
      } else {
        localStorage.setItem("local", "en");
         i18n.changeLanguage('en');
      }
    }else{
      localStorage.setItem("local", i18n?.resolvedLanguage);
    }
    setlLading(true);
    if (!user) {
      verify()
        .then((res) => {
          setUser({ ...res?.user });
          setlLading(false);
          if (res?.user?.tenantId === "cro") {
            history.push("/add/organisation");
          }
          if (
            res?.user?.type !== "cro" &&
            res?.user?.firstLogin &&
            !res?.user?.invited
          ) {
            history.push("/firstLogin");
          }
        })
        .catch((_) => {
          setlLading(false);
        });
    } else {
      if (user?.type !== "cro" && user?.firstLogin && !user?.invited) {
        history.push("/firstLogin");
      } else {
        setlLading(false);
        return;
      }
    }
  }, [setUser]);
  useEffect(() => {
    if (user && user.lang) {
      i18n.changeLanguage(user?.lang);
      localStorage.setItem("local", user?.lang);
    }
  }, [user]);

  Client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 401"
      ) {
        const flag = localStorage.getItem("token") ? true : false;
        if (flag) makeToast("info", t("missingWording.sessionStatus"));
        setUser({});
        setlLading(false);
      } else if (
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 402"
      ) {
        history.push("/profile");
        makeToast("warning",t('missingWording.unpaidInvoice'))
      }
      // Do something with the response error
      return Promise.reject(error);
    }
  );
  Client.interceptors.request.use(
    function (request) {
      const tenant = localStorage.getItem("tenantId");
      if (tenant) {
        request.headers.tenantId = tenant;
      }
      // Do something with the response data

      return request;
    },
    function (error) {
      if (
        JSON.parse(JSON.stringify(error)).message ===
        "Request failed with status code 401"
      ) {
        const flag = localStorage.getItem("token") ? true : false;
      }
      // Do something with the response error
      return Promise.reject(error);
    }
  );

  return !user ? (
    <Spinner />
  ) : (
    <>
      <Router history={history}>
        <Navigator
          subscription={!user?.freeAccount && !user?.stripeSubscriptionId}
          role={user?.type || UserRole.Guest}
        />
      </Router>
      <LiveChat />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
