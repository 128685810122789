import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { forgetPassword, resetPassword } from "../../api/auth";
import voir from "../../assets/images/voir.png";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import makeToast from "../../components/Snackbar";
import history from "../../history";
import "./styles.scss";

const ForgetPassword: React.FC = () => {
  const [counter, setCounter] = useState(10);
  const [interval, SetInterval] = useState() as any;
  const [codeSended, setCodeSended] = useState(false);
  const [passwordFormType1, setPasswordFormType1] = useState("password");
  const [passwordFormType2, setPasswordFormType2] = useState("password");
  const { t } = useTranslation();

  useEffect(() => {
    if (counter > 0) {
      SetInterval(
        setInterval(() => {
          setCounter(counter - 1);
        }, 1000)
      );
      return () => clearInterval(interval);
    } else {
      SetInterval(null);
      clearInterval(interval);
    }
  }, [counter]);
  return (
    <div className="login min-h-screen flexCenter forgetPassword">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-login-Container mt-4 ">
        <div className="flexColCenter">
          <h1 className="title flexCenter text-center">
            {t("missingWording.fph1")}
          </h1>
          <h3 className="text-sm text-white text-center -mt-7 mb-5 max-w-md">
            {!codeSended ? t("missingWording.fph2") : t("missingWording.fph3")}
          </h3>
        </div>

        <div className="bg-white rounded-2xl p-5 form-forget-password flexCenter ">
          {/* start */}
          <Formik
            initialValues={{
              email: "",
              verificationCode: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={async (values) => {
              try {
                if (!codeSended) {
                  /**
                   * Send Code de verication by email
                   */
                  const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!validEmailRegex.test(values.email))
                    return makeToast("warning", t("missingWording.fpt1"));
                  const response = await forgetPassword({
                    email: values.email,
                  });
                  console.log(
                    "🚀 ~ file: index.tsx:67 ~ onSubmit={ ~ response:",
                    response
                  );
                  if (response.status === "success") {
                    makeToast("success", t("missingWording.fpt3"));
                    setCodeSended(true);
                  } else {
                    switch (response.error) {
                      case "NOTFOUND":
                        makeToast("error", t("missingWording.fpt201"));
                        break;
                      case "UNCONFIRMED":
                        makeToast("warning", t("missingWording.fpt202"));
                        history.push(`/confirm-email/${values.email}`);
                        break;
                    }
                  }

                
                } else {
                  /**
                   * Verif code sent && reset password
                   */

                  const regexPassword =
                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

                  if (!regexPassword.test(values.password))
                    return makeToast("warning", t("missingWording.fpt4"));
                  if (values.password !== values.confirmPassword)
                    return makeToast("warning", t("missingWording.fpt5"));
                  const resetResponse = await resetPassword({
                    email: values.email,
                    verificationCode: values.verificationCode,
                    newPassword: values.password,
                  });

                  if (resetResponse.status === "success") {
                    makeToast("success", t("missingWording.fpt6"));
                    history.push("/check/user");
                  } else {
                    makeToast("error", t("missingWording.fpt7"));
                  }
                }
              } catch (error) {
                makeToast("warning", t("missingWording.fpt7"));
              }
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <>
                  <form onSubmit={handleSubmit} className="w-11/12">
                    {codeSended ? (
                      <>
                        <Input
                          id="verificationCode"
                          placeholder={t("missingWording.fpi1")}
                          labelText={t("missingWording.fpi1")}
                          type="text"
                          value={values.verificationCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inlineLabel={false}
                          onfocus={true}
                        />
                        <div className="relative">
                          <Input
                            className="pr-10"
                            id="password"
                            type={passwordFormType1}
                            labelText={t("missingWording.fpi2")}
                            placeholder={t("missingWording.fpi2")}
                            onChange={handleChange}
                            value={values.password}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                          />
                          <img
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType1(
                                passwordFormType1 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                        <div className="relative">
                          <Input
                            className="pr-10"
                            id="confirmPassword"
                            type={passwordFormType2}
                            labelText={t("missingWording.fpi3")}
                            placeholder={t("missingWording.fpi3")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inlineLabel={false}
                            onfocus={true}
                          />
                          <img
                            src={voir}
                            alt="voir"
                            className="absolute right-3 top-6 w-4 h-3 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              setPasswordFormType2(
                                passwordFormType2 === "password"
                                  ? "text"
                                  : "password"
                              );
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <Input
                        id="email"
                        placeholder={t("missingWording.fpi4")}
                        labelText={t("missingWording.fpi4")}
                        type="text"
                        // error={errors.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inlineLabel={false}
                        onfocus={true}
                      />
                    )}

                    <div className="flexRowCenter">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        status="primary"
                        className="btn mt-3"
                        width="250px"
                        height="40px"
                      >
                        {codeSended
                          ? t("missingWording.fph1")
                          : t("missingWording.flb2")}
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>

          {/* bloc  */}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
