import React, { useState } from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { testModeAtom } from "../../state/testModeAtom";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

const TestModeTogButton = () => {
  const { t } = useTranslation();
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const handleTestModeChange = () => {
    setTestMode((prevValue) => !prevValue);
  };
  return (
    <div className="absolute right-4 flex flex-row items-center text-sm font-semibold mt-4 TestMode_param">
      {t("missingWording.testMode")}
      <ToggleSwitch
        id="testMode"
        disabled={false}
        small
        checked={testMode}
        onChange={handleTestModeChange}
      />
    </div>
  );
};

export default TestModeTogButton;
