import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex, FormLabel, IconButton, Stack } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import Button from "../../../ButtonUI";
import Tooltip from "../../../toolTip";
import { FieldSelect } from "../../Elements/FieldSelect";
const DEFAULT_VALUES = {
  collection: [{ label: "Default label" }],
};

function createEmptyArray(length: number): string[] {
  return Array.from({ length }, () => "");
}
export const RepeaterAssociatedSurvey = (props: any) => {
  const { affectationNbr, selectOptions, disabled } = props;
  const [initialData, setInitialData] = useState([]) as any[];

  const collection = useRepeater({
    name: "asocitedForms",
    form: props.form,
    initialValues: props.value,
  });
  useEffect(() => {
    setInitialData(createEmptyArray(affectationNbr));
  }, [affectationNbr]);
  return (
    <Stack
      justifyContent={"flex-start "}
      spacing={2}
      align="start"
      className="mb-4 w-full"
    >
      <Flex className="w-full mb-4" align="center"  justify="space-between">
        <FormLabel className="cssFormLabel w-96">
          {" "}
          <span style={{ fontSize: "16px" }} className="font-bold ">
            {t("missingWording.switchForms")}
          </span>
        </FormLabel>

        <Tooltip
          tooltipText={
            t("Study.toolTipAddFormButton") +
            " " +
            affectationNbr +
            " " +
            t("Study.forms")
          }
          position={"top"}
        >
          <Button
            status="secondary"
            type="button"
            disabled={affectationNbr <= collection.length || disabled}
            className="md:w-auto md:text-sm sm:px-4  xs:w-16 xs:text-xs xs:truncate xs:px-0"
            height="40px"
            onClick={() => {
              console.log("X1", collection.length);
              collection.append();
              console.log("X2", collection.length);
            }}
          >
            {" "}
            {t("missingWording.addAssocitedForm")}
          </Button>
        </Tooltip>
      </Flex>

      <Box className="max-h-56 w-96 overflow-y-auto px-4 " >
        {collection.keys.map((key, index) => (
          <Stack
            key={key}
            direction="row"
            spacing="4"
           
           
            align="center"
            // mb="4"
            className="w-ful"
            data-test={`repeater-item[${index}]`}
          >
            
            <Box flex="1">
            <label className="text-sm text-center ">
              {t("Study.resagmnetFormLabel")}
            </label>
              <FieldSelect
                id={`asocitedForms[${index}]`}
                name={`asocitedForms[${index}]`}
                placeholder={
                  selectOptions && selectOptions.length == 0
                    ? t("missingWording.noAssocitedForms")
                    : ""
                }
                // value={form.values.startFromSurveyId ?? selectedOption}
                // onChange={(e) => form.setFieldsValues({startFromSurveyId:e.target.value})}
                readOnly={disabled}
                className=" bg-white  "
                options={selectOptions}
              ></FieldSelect>
            </Box>

            <Box>
              <IconButton
                aria-label="Delete"
                background={"#ffffff"}
                disabled={disabled}
                className=" mt-1.5  "
                icon={<DeleteIcon />}
                onClick={() => collection.remove(index)}
              />
            </Box>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};
