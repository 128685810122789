import { Box, Flex } from "@chakra-ui/react";
import { UserRole } from "../../../../types/user";
import { userAtom } from "../../../../state/auth";
import { useAtom } from "jotai";

export const PageLayout = ({ children, isEproActive }) => {
  const [user] = useAtom(userAtom) as any;
  let className = "bg-white rounded-lg shadow-md formDetails";
  if (isEproActive || user?.type === "participant") {
    className += " max-w-5xl";
  }
  return (
    <Flex
      flex="1"
      minH="100vh"
      flexWrap="nowrap"
      flexDirection={{ base: "column", lg: "row" }}
      className={className}
    >
      <Box flex="1" position="relative">
        <Box
          top={{ lg: 0 }}
          left={{ lg: 0 }}
          right={{ lg: 0 }}
          bottom={{ lg: 0 }}
          className="h-full"
          overflow="auto"
          p={{ base: 4, lg: 8 }}
        >
          {children}
        </Box>
      </Box>
    </Flex>
  );
};
