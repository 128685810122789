import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { removeArcFromStudy } from "../../../../api/cro";
import { deleteStudyCenters } from "../../../../api/study";
import Button from "../../../../components/ButtonUI";
import makeToast from "../../../../components/Snackbar";

interface Props {
  setDeleteArcModal: Function;
  setShouldUpdate: Function;
  arcId: string;
  studyId: string;
}

export const DeleteArcModal: React.FC<Props> = ({
  setDeleteArcModal,
  arcId,
  studyId,
  setShouldUpdate,
}) => {
  const { t } = useTranslation() as any;

  return (
    <div className="px-4 ">
      <h3 className="mb-4">{t("ArcPage.deleteUserStudy")}</h3>
      <div className=" flex justify-between mt-6 buttons">
        <Button
          status="secondary"
          width={"100px"}
          height={"50px"}
          onClick={async () => {
            setDeleteArcModal(false);
          }}
        >
          {t("ArcPage.Cancel")}
        </Button>
        <Button
          status="secondary"
          width={"100px"}
          className="border-red-300 text-red-300 hover:border-red-300 hover:text-red-300"
          height={"50px"}
          onClick={async () => {
            let response: any = await removeArcFromStudy(studyId, {
              arcId: arcId,
            });
            if (response) {
              makeToast("success", `${t("ArcPage.deleteSS")}`);
              setShouldUpdate((up: boolean) => !up);
            }
            setDeleteArcModal(false);
          }}
        >
          {t("ArcPage.Delete")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteArcModal;
