import { useAtom } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveRouteAtom } from "../../state/route";
import {
  asyncGetExportsList,
  listExportsAtom,
  exportPage,
  totalResults,
} from "../../state/listExports";
import Tooltip from "../../components/toolTip";
import { ReactComponent as XlsxFileIcon } from "../../assets/icons/xlsx-file.svg";
import { ReactComponent as CsvFileIcon } from "../../assets/icons/csv-file.svg";
import { ReactComponent as PdfFileIcon } from "../../assets/icons/pdf-file2-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/icon-telecharger.svg";
import { ReactComponent as InProgressIcon } from "../../assets/icons/icon-horaire.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/cloud-error.svg";
import { BsFillArchiveFill } from "react-icons/bs";
import "./styles.scss";
import { downloadExport } from "../../api/study";
import Pagination from "../../components/PaginationTable";
import { MdSimCardAlert } from "react-icons/md";
const ICON_COLORS = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

function _getFileItemIcon(fileType: string) {
  switch (fileType) {
    case "excel":
      return <XlsxFileIcon />;
    case "XLSX":
      return <XlsxFileIcon />;
    case "CSV":
      return <CsvFileIcon />;
    case "PDF":
      return <PdfFileIcon />;
    default:
      return null;
  }
}

const Exports = () => {
  const { t } = useTranslation() as any;
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [listExports] = useAtom(listExportsAtom);
  const [totalCount] = useAtom(totalResults);
  const [page, setPage] = useAtom(exportPage);
  const [, getExportsList] = useAtom(asyncGetExportsList);
  // const [page, setPage] = useState(1);

  useEffect(() => {
    setPath("/exports");
  }, [setPath]);

  useEffect(() => {
    getExportsList();
    console.log("[DEBUG] exports - getting exports");
  }, [getExportsList, page]);

  const handleFileDownload = async (item) => {
    if (item.status !== "success") return;

    try {
      const response = await downloadExport(item.s3ObjectUrl);

      console.log("[DEBUG] blob response -", response);

      const splitRemoteFilePath = item.s3ObjectUrl.split("/");
      const filename = splitRemoteFilePath[splitRemoteFilePath.length - 1];

      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="mx-16 my-patients">
        <header className="flex justify-between items-center w-full flex-wrap">
          <div className="title-container">
            <h1 className="pageTitle">{t("ArcPage.exportsList")}</h1>
            {/* <h3 className="card-subtitle"></h3> */}
          </div>
          {/* <div className="flex max-w-max items-center space-x-2 ">
            <div className="relative">
              <Button
                status="secondary"
                onClick={() => setModalAddConfirm(true)}
                className="btnAddPatient"
              >
                {t("ArcPage.addUser")}
              </Button>
            </div>
          </div> */}
        </header>

        <div className={`patient-list flex flex-wrap justify-between w-full"`}>
          {listExports && listExports.length !== 0 ? (
            listExports.map((item: any, i) => {
              return (
                <div
                  className={`card-patient justify-between bg-white  ${"foo"}`}
                  style={
                    item.status === "success"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed", opacity: 0.6 }
                  }
                  onClick={() => handleFileDownload(item)}
                >
                  <div className="flex patient-info w-full">
                    <div
                      className="rounded-full h-12 w-12 flexCenter mx-5 my-auto p-1"
                      style={{
                        backgroundColor: ICON_COLORS[i % ICON_COLORS.length],
                      }}
                    >
                      {_getFileItemIcon(item.type)}
                    </div>
                    <div className="details">
                      <div className="flex-grow ">
                        <span className=" flex flex-row gap-x-2 items-center">
                          <p className="card-title capitalize">
                            {item?.studyId?.name}
                          </p>
                          {item.archived && (
                            <BsFillArchiveFill
                              size={16}
                              className="text-yellow-500"
                            />
                          )}
                        </span>
                        <p className="card-subtitle">
                          {t("ArcPage.exported")}{" "}
                          <span>
                            {moment(item.createdAt).format("DD/MM/YYYY") +
                              t("DashboardCro.at") +
                              moment(item.createdAt).format("HH:mm")}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="h-12 w-12 flexCenter justify-self-end mx-5 my-auto ml-auto p-1">
                      {item.status === "success" ? (
                        <Tooltip
                          tooltipText={t("ArcPage.download")}
                          position={"top"}
                        >
                          <DownloadIcon />
                        </Tooltip>
                      ) : item.status === "pending" ? (
                        <Tooltip
                          tooltipText={t("ArcPage.inProgress")}
                          position={"top"}
                        >
                          <InProgressIcon />
                        </Tooltip>
                      ) : item.errorMessage === "Empty file" ? (
                        <Tooltip
                          tooltipText={t("ArcPage.emtyFile")}
                          position={"top"}
                        >
                          <MdSimCardAlert size={32} />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          tooltipText={t("ArcPage.cloudError")}
                          position={"top"}
                        >
                          <ErrorIcon />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("ArcPage.noExportsFound")}</h3>
            </div>
          )}
        </div>
        <div className="flex items-center ml-4 justify-center">
          {totalCount > 10 && (
            <Pagination
              totalResults={totalCount}
              resultsPerPage={10}
              currentPage={page}
              onPageChange={function (page: number): void {
                setPage(page);
                localStorage.setItem("paginate", page.toString());
              }}
              entriesName={"Exports"}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default Exports;
