import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFileDone, AiOutlineFileSync } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { getDashboardData_02 } from "../../api/study";
import ProgressRing from "../../components/ProgressRing";
import { testModeAtom } from "../../state/testModeAtom";
const SecondCards = ({ shouldUpdate }) => {
  const { t } = useTranslation() as any;
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({}) as any;
  const [testMode, ] = useAtom(testModeAtom);
  const params = useParams() as any;
  const { studyId } = params;
  useEffect(() => {
    setIsLoading(true);
    getDashboardData_02(studyId, testMode).then((result) => {
      if (result && result?.status === "success") {
        setStats(result?.response);

        // setOptionStat(dropDownOptions);
        // if (dropDownOptions?.length > 0) {
        //   setSelectedOptionFirst(dropDownOptions[0]?.value);
        // }
        setIsLoading(false);
      }
    });
  }, [studyId, shouldUpdate, testMode]);
  return isLoading ? (
    <div className="flex gap-9 mt-2 ">
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-3 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-3 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-3 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-3 animate-pulse">
        <div className="bg-gray-300 rounded-full h-16 w-16"></div>
        <div className="flex-1">
          <div className="bg-gray-300 h-4 w-2/3 mb-2"></div>
          <div className="bg-gray-300 h-4 w-1/2"></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-10 p-2 ">
      {/* percentageClosedQueries */}

      {/* percentageExitParticipant*/}
      {/* Second card */}
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-4">
        <AiOutlineFileDone className="color-primary-blue" size={50} />
        <div className="">
          <p className="font-bold mb-2">
            <span className="color-primary-blue font-bold text-lg">
              {stats?.finalizedParticipants}
            </span>{" "}
            {typeof stats?.finalizedParticipants === "number" &&
            stats?.finalizedParticipants > 1
              ? t("missingWording.card2")
              : t("missingWording.card2single")}
          </p>
        </div>
      </div>

      {/* Third card */}
      <div className="bg-white shadow-lg rounded-lg flex gap-3 flex-1 w-72 p-4">
        <AiOutlineFileSync className="color-primary-blue" size={50} />
        <div className="">
          <p className="font-bold mb-2">
            <span className="color-primary-blue font-bold text-lg">
              {stats?.inProgressParticipants}
            </span>{" "}
            {typeof stats?.inProgressParticipants === "number" &&
            stats?.inProgressParticipants > 1
              ? t("missingWording.card3")
              : t("missingWording.card3single")}
          </p>
        </div>
      </div>
      <div
        className="
        flex items-center bg-white shadow-lg rounded-lg pr-7 flex-1"
      >
        <div className="">
          <ProgressRing
            percent={stats?.percentageClosedQueries || 0}
            borderColor="color-primary-blue"
            size={80}
          />{" "}
        </div>
        <p className="ml-3 font-bold">{t("missingWording.card6")}</p>
      </div>
      {/* nbrActiveFormData*/}
      <div
        className="
        flex items-center bg-white shadow-lg rounded-lg pr-7 flex-1"
      >
        <div className="">
          <ProgressRing
            percent={stats?.nbrActiveFormData || 0 || 0}
            borderColor="color-primary-grey-title"
            size={80}
            showPercent={false}
          />{" "}
        </div>
        <p className="ml-3 font-bold">{t("missingWording.card9")}</p>
      </div>
    </div>
  );
};
export default SecondCards;
