import { Redirect, RouteProps } from "react-router";
import DashboardWriter from "../pages/DashboardWriter";
import Events from "../pages/Events";
import FirstLogin from "../pages/FirstLogin";

import MyParticipant from "../pages/MyParticipant";
import MyParticipantForms from "../pages/MyParticipant/ParticipantForms";
import Profile from "../pages/Profile";
import Query from "../pages/Query";
import WatingPage from "../pages/WatingPage";
import WorkSpaceSelectionPage from "../pages/WorkSpaceSelection";
import Econsult from "../pages/Econsult";
import EconsultTeleconsultation from "../pages/Econsult/SimplePeer";
const writerRoutes: RouteProps[] = [
  {
    path: "/",
    component: DashboardWriter,
    exact: true,
  },
  {
    path: "/work-spaces",
    component: WorkSpaceSelectionPage,
    exact: true,
  },
  {
    path: "/participant/:studyId",
    component: MyParticipant,
    exact: true,
  },
  {
    path: "/participant/:studyId/:participantId",
    component: MyParticipantForms,
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/query/:studyId",
    component: Query,
    exact: true,
  },
  {
    path: "/events/:studyId",
    component: Events,
    exact: true,
  },
  {
    path: "/eConsult/:studyId",
    component: Econsult,
    exact: true,
  },
  {
    path: "/eConsult/:studyId/:eConsultId/:participantId",
    component: EconsultTeleconsultation,
    exact: true,
  },
  {
    path: "/firstLogin",
    component: FirstLogin,
    exact: true,
  },
  {
    path: "/waiting",
    component: WatingPage,
    exact: true,
  },
];

writerRoutes.push({ render: () => <Redirect to="/" /> });

export default writerRoutes;
