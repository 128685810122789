import React, { useState } from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { centerAcessAtom } from "../../state/centerAcessAtom";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

const AccessCenterButton = () => {
  const { t } = useTranslation();
  const [centerAcess, setCenterAcess] = useAtom(centerAcessAtom);
  const handleAcessCentereChange = (checked) => {
    setCenterAcess((prevValue) => !prevValue);
    // props.filterAction(checked)
  };
  return (
    <div className="absolute  mb-4  right-4 flex flex-row items-center text-sm font-semibold mt-7 ">
{t('missingWording.accesCenter')}
      <ToggleSwitch
        id="centerAcess"
        disabled={false}
        small
        checked={centerAcess}
        onChange={handleAcessCentereChange}
      />
    </div>
  );
};

export default AccessCenterButton;
