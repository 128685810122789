import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { userAtom } from "../../../../state/auth";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { FormGroup } from "../FormGroup";
import { HStack, InputGroup, Spinner, Stack } from "@chakra-ui/react";
import { HiCheckCircle } from "react-icons/hi";
import { MdErrorOutline } from "react-icons/md";

function FieldCheckbox(props) {
  const [selected, setSelected] = useState([]) as any[];
  const [isValidation, setIsValidation] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [isTouched, setIsTouched] = useState(false);
  const [editMode, setEditMode] = useState(false);

  function compareArrays(arr1, arr2) {
    const arr1InArr2 = arr1.every((item) => arr2.includes(item));
    const arr2InArr1 = arr2.every((item) => arr1.includes(item));

    return arr1InArr2 && arr2InArr1;
  }

  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    errors,
    valueDebounced,
    setValue,
    value,

    otherProps,
  } = useField(props);

  // Generate options dynamically from props.options
  const options = props.options.map((option) => ({
    label: option.label,
    value: option.value,
  }));
  const { children, label, type, placeholder, direction, helper, ...resta } =
    otherProps;
  const showError = !isValid;
  const formGroupProps = {
    errorMessage,
    helper: "",
    id,
    isRequired: props.required ? true : false,
    label: props.label,
    showError: showError,
    name: props.name,

    ...resta,
  };

  // Check conditions based on props.conditionLogic

  const checkIfValid = (submittedValue) => {
    try {
      let x: any[] = [];
      props.validations.map((v) => {
        x.push(v.rule(submittedValue));
      });
      console.log("🚀 ~ file: index.tsx:357CC ~ FieldCheckbox ~ props:", x);

      return x.every((e) => e === true) ? true : false;
    } catch (error) {}
  };
  // Handle checkbox selection
  function handleSelection(event) {
    const { checked, value } = event.target;

    if (checked) {
      setSelected([...selected, value]);
      //props.onSubmit([...selected, value]);
    } else {
      setSelected(selected.filter((v) => v !== value));
      //props.onSubmit(selected.filter((v) => v !== value));
    }
  }

  useEffect(() => {
    if (props.value && typeof props.value === "string") {
      let x = true;
      let res = props.value.split("||");
      setSelected(res);
    }
  }, [props.value]);

  useEffect(() => {
    if (!selected || selected.length === 0) setValue(null);

    // setIsTouched((prev) => !prev);
    setValue(selected);
 
  }, [selected]);

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup
        className={
          props.disabled ? "pointer-events-none cursor-not-allowed" : ""
        }
        direction={direction || "row"}
        onMouseLeave={() => {
          const flag: boolean = compareArrays(
            selected,
            props.value ? props.value.split("||") : []
          );
          if (props?.onSumbit && editMode && !flag)
            props.onSumbit({
              fieldName: props.name,
              value: selected.join("||"),
              isValid: checkIfValid(selected.join("||")),
            });
          setEditMode(false);
        }}
        onChange={(e) => setEditMode(true)}
      >
        <Stack>
          <div>
            {options.map((option, idx) => (
              <HStack>
                <input
                  type="checkbox"
                  name={props.name}
                  value={option.value}
                  onChange={handleSelection}
                  checked={selected.includes(option.value)}
                  disabled={props.disabled}
                  required={props.required}
                />

                <label key={option.value}>{option.label}</label>
              </HStack>
            ))}
          </div>
        </Stack>
      </InputGroup>
      {!props?.isPreview && user?.type === "writer" && (
        <div className="absolute -left-8 top-1/2">
          {" "}
          {isValidation && (
            <p>
              <Spinner size="sm" flex="none" color="blue.200" />
            </p>
          )}
          <p>
            {" "}
            {isValid && !isValidation && (
              <HiCheckCircle color="#67d428" width={20} height={20} />
            )}
            {!isValid && !isValidation && (
              <MdErrorOutline color="#ff0000" width={20} height={20} />
            )}
          </p>
        </div>
      )}
      {children}
    </FormGroup>
  );
}

export default FieldCheckbox;
