import React from "react";
import { useTranslation } from "react-i18next";

type PaginationProps = {
  totalResults: number;
  resultsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  entriesName: string;
};

const Pagination: React.FC<PaginationProps> = ({
  totalResults,
  resultsPerPage,
  currentPage,
  onPageChange,
  entriesName,
}) => {
  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const { t } = useTranslation();

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const goToPreviousPage = () => {
    if (!isFirstPage) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (!isLastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const skipTheNextPage = () => {
    let skipValue = 1;
    if (totalResults > 20 && totalResults <= 100) {
      skipValue = 2;
    } else if (totalResults > 100 && totalResults <= 300) {
      skipValue = 5;
    } else if (totalResults > 300) {
      skipValue = 10;
    }
    if (!isLastPage && currentPage < totalPages - skipValue) {
      onPageChange(currentPage + skipValue);
    }
  };
  const skipThePreviousPage = () => {
    let skipValue = 1;
    if (totalResults > 20 && totalResults <= 100) {
      skipValue = 2;
    } else if (totalResults > 100 && totalResults <= 300) {
      skipValue = 5;
    } else if (totalResults > 300) {
      skipValue = 10;
    }
    if (!isFirstPage && currentPage > skipValue) {
      onPageChange(currentPage - skipValue);
    }
  };
  return (
    <div className="flex flex-col items-center ">
      <div className="inline-flex mt-2 xs:mt-0 ">
        {/* {totalResults > 20 && (
          <button
            className="inline-flex items-center px-4 py-2 text-sm font-semibold text-black bg-gray-200    border-gray-700 rounded-r dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={skipThePreviousPage}
          >
            <div className="transition transform hover:translate-x-1 motion-reduce:transition-none motion-reduce:hover:transform-none flex flex-col items-center">
              <svg
                width={22}
                height={22}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 6L13.2571 12.1714L22 18.3429V6Z" fill="#000000" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 12.1714V18.3429H2V6.34286H4V12.1714ZM4 12.1714L12.7429 6V18.3429L4 12.1714Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </button>
        )} */}
        <button
          className={`${
            isFirstPage ? "cursor-not-allowed" : "cursor-pointer"
          }  inline-flex items-center px-4 py-2 text-sm font-semibold text-black bg-gray-200 rounded-l hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
          onClick={goToPreviousPage}
          disabled={isFirstPage}
        >
          <div
            className={`flex flex-row ${
              isFirstPage
                ? ""
                : "transition transform hover:-translate-x-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
            }`}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            {t("Survey.Previous")}
          </div>
        </button>
        <div className="text-center flex flex-row items-center  bg-gray-200 px-2">
          {" "}
          <span className="text-sm text-gray-700 dark:text-gray-400">
            {t("missingWording.pagination1")}
            <span className="font-semibold text-gray-900 dark:text-white">
              {(currentPage - 1) * resultsPerPage + 1}
            </span>{" "}
            {t("missingWording.pagination2")}
            <span className="font-semibold text-gray-900 dark:text-white">
              {Math.min(currentPage * resultsPerPage, totalResults)}
            </span>{" "}
            {t("missingWording.pagination3")}
            <span className="font-semibold text-gray-900 dark:text-white">
              {totalResults}
            </span>{" "}
            {entriesName}
          </span>
        </div>

        <button
          className="inline-flex items-center px-4 py-2 text-sm font-semibold text-black bg-gray-200    border-gray-700 rounded-r dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={goToNextPage}
        >
          <div className="transition transform hover:translate-x-1 motion-reduce:transition-none motion-reduce:hover:transform-none flex flex-row">
            {t("Survey.Next")}
            <svg
              aria-hidden="true"
              className="w-5 h-5 ml-2 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </button>
        {/* {totalResults > 20 && (
          <button
            className="inline-flex items-center px-4 py-2 text-sm font-semibold text-black bg-gray-200    border-gray-700 rounded-r dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={skipTheNextPage}
          >
            <div className="transition transform hover:translate-x-1 motion-reduce:transition-none motion-reduce:hover:transform-none flex flex-col items-center">
              <svg
                height={22}
                width={22}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m2 18.343 8.743-6.172L2 6v12.343Z" fill="#000" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="m20 12.171-8.743 6.172V6L20 12.171Zm0 0V6h2v12h-2v-5.829Z"
                  fill="#000"
                />
              </svg>
            </div>
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Pagination;
