import classNames from "classnames";
import React from "react";

interface ProgressRingProps {
  percent: number;
  borderColor: string;
  size?: number;
  showPercent?: boolean;
}

const ProgressRing: React.FC<ProgressRingProps> = (
  props: ProgressRingProps
) => {
  const { percent = 0, borderColor, size, showPercent = true } = props;

  // Calculate the tier based on percent
  let tier: string;
  if (percent >= 1 && percent <= 500) {
    tier = "tier1";
  } else if (percent >= 501 && percent <= 2000) {
    tier = "tier2";
  } else if (percent >= 2001 && percent <= 5000) {
    tier = "tier3";
  } else {
    tier = "tier3";
  }
  const tierPercentages = {
    tier1: percent >= 500 ? 100 : (percent / 500) * 100,
    tier2: percent > 2000 ? 100 : ((percent - 500) / 1500) * 100,
    tier3: percent > 5000 ? 100 : ((percent - 2000) / 3000) * 100,
  };

  // Set initial percentage here
  const circumference: number = 188; // Set circumference value here

  const progressOffset: number = showPercent
    ? circumference - (percent / 100) * circumference
    : (circumference * (100 - tierPercentages[tier])) / 100;

  return (
    <div className="inline-flex items-center justify-center overflow-hidden rounded-full bottom-5 left-5">
      <svg className="w-20 h-20">
        <circle
          className="text-gray-300"
          strokeWidth={5}
          stroke="currentColor"
          fill="transparent"
          r={30}
          cx={40}
          cy={40}
        />
        <circle
          className={borderColor}
          strokeWidth={5}
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={30}
          cx={40}
          cy={40}
        />
      </svg>
      {showPercent && (
        <span
          className={classNames("absolute text-xl ", borderColor)}
        >{`${Math.round(percent)}%`}</span>
      )}
      {!showPercent && (
        <span
          className={classNames("absolute text-xl ", borderColor)}
        >{`${Math.round(percent)}`}</span>
      )}
    </div>
  );
};

export default ProgressRing;
