import { chakra, Image } from "@chakra-ui/react";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { FcDocument } from "react-icons/fc";
import { FormGroup } from "./FormGroup";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { visteAtom } from "../../../state/visteAtom";
import { FiUploadCloud, FiFileMinus, FiDelete } from "react-icons/fi";
import Modal from "../../ModalUI";
import Button from "../../ButtonUI";
import makeToast from "../../Snackbar";
import Spinner from "../../Spinner";
import { useTranslation } from "react-i18next";
import { KATOMI_API_ENDPOINT } from "../../../config/api";
import { deleteMedia, getFileMediaApi } from "../../../api/study";
import { socketAtom } from "../../../state/socket";
import { userAtom } from "../../../state/auth";
import { useParams } from "react-router-dom";
export const FieldUpload = (props) => {
  console.log("🚀 ~ file: FieldUpload.tsx:21 ~ props:", props)
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name, stepNbr, formId, isPreview, isEpro } = props;
  const params = useParams() as any;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const showError = !isValid && isSubmitted;
  const [previewImage, setPreviewImage] = useState(null) as any;
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileValue, setFileValue] = useState(null) as any;
  const [vistForm, setVistForm] = useAtom(visteAtom);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null) as any;
  const { t } = useTranslation() as any;
  const maxSize = parseFloat(props.maxSize) || 5;
  const [socket] = useAtom(socketAtom) as any;
  const [user] = useAtom(userAtom) as any;
  const { tenantId } = params;
  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };
  const pattern =
    /tenant_(.*?)\/study_(.*?)\/formAssests\/PId_(.*?)\/form_(.*?)\/(.*?)\/(.*?)_(.*?)\.(.*?)$/;
  const secondPattern = /\/(\d+_.*?)$/;
  // Function to extract information from the string
  function extractInformationFromString(string) {
    const matches = string.match(pattern);
    const secondaryMatches = string.match(secondPattern);
    if (matches) {
      const [
        ,
        tenant,
        studyId,
        participantId,
        formId,
        fieldName,
        timestamp,
        sanitizedFileName,
        fileExtension,
      ] = matches;
      return {
        tenant,
        studyId,
        participantId,
        formId,
        fieldName,
        timestamp,
        sanitizedFileName,
        fileExtension,
        fullInfo:
          secondaryMatches && secondaryMatches.length > 1
            ? secondaryMatches[1]
            : null,
      };
    }
    return null;
  }

  const getFileMedia: any = async (params: any) => {
    try {
      const { value } = params;
      const urlExtractResult = extractInformationFromString(value);
      const result = `${KATOMI_API_ENDPOINT}/media/formData/${urlExtractResult?.tenant}/${vistForm.studyId}/${urlExtractResult?.participantId}/${formId}/${name}/${urlExtractResult?.fullInfo}`;
      setUrl(result);
      console.log("🚀 ~ file: FieldUpload.tsx:107 ~ constgetFileMedia:any= ~ result:", result)
      setValue(value);
      return result;
    } catch (error) {
      console.log(
        "🚀 ~ file: FieldUpload.tsx:54 ~ getFileMedia ~ error:",
        error
      );
    }
  };
  const handleChange = ({ target }) => {
    const file = target.files[0];
    let maximumSize = maxSize * 1024 * 1024;

    if (!file) {
      setValue(null);
      setFileValue(null);
      return;
    }

    if (file.size > maximumSize) {
      // Limit the file size to 1MB (you can adjust the size as needed)
      setFileSizeError(true);
      setPreviewImage();
      setValue(null);
      setFileValue(null);
      return;
    } else {
      setFileSizeError(false);
    }

    setPreviewImage(URL.createObjectURL(file));
    setFileValue(file);
    setShowModalUpload(true);
    if (isPreview) setValue(file);
  };

  const handleSubmit = async () => {
    // Perform your backend submission here using the `value` object
    try {
      if (isEpro) {
        let formData: any = new FormData();
        formData.append("file", fileValue);
        formData.append("stepNbr", stepNbr);
        formData.append("formId", formId);
        formData.append("fieldName", name);
        formData.append("studyId", vistForm.studyId);
        formData.append("tenant", tenantId);
        setLoading(true);
        const response = await fetch(
          `${KATOMI_API_ENDPOINT}/media/eproFormData/${formId}`,
          {
            method: "POST",
            body: formData,
            // headers: {
            //   Authorization: `${token}`, // Replace <your_token_here> with your actual token
            //   // Other headers if needed
            // },
          }
        );
        console.log("🚀 ~ file: FieldUpload.tsx:166 ~ handleSubmit ~ response:", response)
        if (!response.ok) {
          if(response.status === 403){
            setValue(null);
            setLoading(false);
            return makeToast("warning", `${t("missingWording.failUploadLimitToast")}`);
              
          }else 
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        // setVistForm(data.result);
        console.log("🚀 ~ file: FieldUpload.tsx:223 ~ handleSubmit ~ data:", data)
        setValue(data.filePath);
        makeToast("success", `${t("missingWording.succesUploadToast")}`);
        setLoading(false);
      } else {
        let token = await localStorage.getItem("token");

        // Example: Send the file as FormData

        if (!token) return;
        if (isPreview) setShowModalUpload(false);
        if (!!stepNbr || !formId || !fileValue) {
          setShowModalUpload(false);
          return;
        }

        let formData: any = new FormData();
        formData.append("file", fileValue);
        formData.append("stepNbr", stepNbr);
        formData.append("formId", formId);
        formData.append("fieldName", name);
        formData.append("studyId", vistForm.studyId);
        setLoading(true);
        // Send the formData to your backend using fetch, axios, or any other library
       

        const response = await fetch(
          `${KATOMI_API_ENDPOINT}/media/formData/${formId}`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `${token}`, // Replace <your_token_here> with your actual token
              // Other headers if needed
            },
          }
        );
        

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("🚀 ~ file: FieldUpload.tsx:223 ~ handleSubmit ~ data:", data)
        setVistForm(data.result);
        setValue(data.filePath);

        socket.emit("updateFormPerField", {
          roomId: props.formId,
          message: {
            fieldName: name,
            value: data.filePath,
            isValid: true,
            stepNbr: props.stepNbr,
          },
          userId: user?._id,
          sharedCalculVars: false,
          sharedStatsVars: false,
        });

        makeToast("success", `${t("missingWording.succesUploadToast")}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: FieldUpload.tsx:245 ~ handleSubmit ~ error:",
        error
      );
      makeToast("error", `${t("missingWording.failUploadToast")}`);
      setLoading(false);
      // Handle errors
    }

    setShowModalUpload(false);
  };

  const handleDelete = async () => {
    // Perform your backend submission here using the `value` object

    if (isPreview) {
      setPreviewImage();
      setFileValue(null);
      setValue(null);
    } else {
      setShowModalDelete(true);
    }
    // Handle the response from the backend
  };

  useEffect(() => {
    getFileMedia({ value: props.value });
  }, [props]);
  return (
    <>
      <FormGroup {...formGroupProps}>
        <chakra.label
          position="relative"
          bg="gray.50"
          alignItems="center"
          px="4"
          py="2"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          className="relative"
          color="gray.600"
          transition="0.2s"
          _hover={{
            bg: "brand.50",
            borderColor: "brand.600",
          }}
          _focusWithin={{
            bg: "brand.50",
            borderColor: "brand.600",
          }}
        >
          {props.fileType === "image" ? (
            <chakra.input
              opacity={0}
              position="absolute"
              accept={props.accept || "image/*"}
              top={0}
              left={0}
              width={0}
              type="file"
              multiple={props.Multiple ? props.Multiple : false}
              name={name}
              onChange={handleChange}
              disabled={props.readOnly ? props.readOnly : false}
            />
          ) : (
            <chakra.input
              opacity={0}
              position="absolute"
              // accept={props.accept}
              top={0}
              left={0}
              width={0}
              type="file"
              multiple={props.Multiple ? props.Multiple : false}
              name={name}
              onChange={handleChange}
              disabled={props.readOnly ? props.readOnly : false}
            />
          )}
          <AttachmentIcon mr="0" />
          {!value &&
            `${
              props.fileType === "image"
                ? `${t("missingWording.imgUpload")}`
                : `${t("missingWording.fileUpload")}`
            }`}
        </chakra.label>
        {fileValue && (
          <DeleteIcon
            color="red.300"
            size={18}
            className="absolute right-0 top-0  "
            onClick={() => {
              handleDelete();
            }}
          />
        )}

        {previewImage && !url && (
          <>
            {props.fileType === "image" ? (
              <Image
                src={previewImage}
                alt="Selected Image"
                maxH="300px"
                objectFit="contain"
                className="relative"
                mt={2}
              />
            ) : (
              <div className=" flex flex-col gap-2-2  mt-8">
                <pre className="truncate">{fileValue.name}</pre>
                <FcDocument size={86} />
              </div>
            )}
          </>
        )}
        {props.value && url && (
          <>
            {props.fileType === "image" ? (
              <Image
                src={`${url}`}
                alt="Selected Image"
                maxH="300px"
                objectFit="contain"
                className="relative"
                mt={2}
              />
            ) : (
              <div className=" flex flex-col gap-2-2  mt-8">
                <pre className="truncate">{fileValue?.name}</pre>
                <FcDocument size={86} />
              </div>
            )}
          </>
        )}
        {fileSizeError && (
          <chakra.p color="red.500" fontSize="sm" mt={2}>
            {t("missingWording.sizeLimit")}
          </chakra.p>
        )}
      </FormGroup>
      <Modal
        className="px-16 pt-10 pb-6"
        // closeModal={() => {
        //   setFileValue(null);
        //   setPreviewImage();

        //   setShowModalUpload(false);
        //   if(isPreview)  setValue(null);
        // }}
        showModal={showModalUpload}
      >
        {loading ? (
          <Spinner classNames="max-w-16 max-h-24" />
        ) : (
          <div>
            <h3 className="text-lg mb-2">
              {t("missingWording.modalUploadMsg")}
            </h3>
            {previewImage && (
              <>
                {props.fileType === "image" ? (
                  <Image
                    src={previewImage}
                    alt="Selected Image"
                    maxH="300px"
                    objectFit="contain"
                    className="relative"
                    mt={2}
                  />
                ) : (
                  <pre className="truncate mt-3 text-base">
                    {fileValue.name}
                  </pre>
                )}
              </>
            )}
            <div className="flex justify-between  my-4">
              <Button
                status="secondary"
                className="px-4 py-1  mx-2 w-56"
                onClick={() => {
                  setFileValue(null);
                  setPreviewImage();
                  setShowModalUpload(false);
                  if (isPreview) setValue(null);
                }}
              >
                {t("missingWording.cancel")}
              </Button>
              {fileValue && (
                <Button
                  status="secondary"
                  className="border px-4 py-1 flex justify-center gap-x-2 items-center mx-2 w-56"
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("missingWording.upload")} <FiUploadCloud size={24} />
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        className="px-16 pt-10 pb-6"
        // closeModal={() => {
        //   setFileValue(null);
        //   setPreviewImage();

        //   setShowModalUpload(false);
        //   if(isPreview)  setValue(null);
        // }}
        showModal={showModalDelete}
      >
        {loading ? (
          <Spinner classNames="max-w-16 max-h-24" />
        ) : (
          <div>
            <h3 className="text-lg mb-2">
              {" "}
              {t("missingWording.modalDeleteMsg")}
            </h3>
            {/* {previewImage && (
              <>
                {props.fileType === "image" ? (
                  <Image
                    src={previewImage}
                    alt="Selected Image"
                    maxH="300px"
                    objectFit="contain"
                    className="relative"
                    mt={2}
                  />
                ) : (
                  <pre className="truncate mt-3 text-base">
                    {fileValue.name}
                  </pre>
                )}
              </>
            )} */}
            <div className="flex justify-between  my-4">
              <Button
                status="secondary"
                className="px-4 py-1  mx-2 w-56"
                onClick={() => {
                  setShowModalDelete(false);
                }}
              >
                {t("missingWording.cancel")}
              </Button>
              {fileValue && (
                <Button
                  status="secondary"
                  className="border px-4 py-1 flex justify-center gap-x-2 items-center mx-2 w-56 text-red-400 border-red-400 hover:text-red-500 hover:border-red-500"
                  type="button"
                  onClick={async () => {
                    setPreviewImage();
                    setFileValue(null);
                    setValue(null);
                    setShowModalDelete(false);
                    let payload = {
                      stepNbr: stepNbr,
                      formId: formId,
                      fieldName: name,
                    };
                    try {
                      const res = await deleteMedia(payload);
                      socket.emit("updateFormPerField", {
                        roomId: props.formId,
                        message: {
                          fieldName: name,
                          value: null,
                          isValid: true,
                          stepNbr: props.stepNbr,
                        },
                        userId: user?._id,
                        sharedCalculVars: false,
                        sharedStatsVars: false,
                      });
                      setVistForm(res.result);
                      makeToast("success", t("missingWording.fileRmoved"));
                    } catch (error) {
                      console.log(
                        "🚀 ~ file: FieldUpload.tsx:443 ~ onClick={ ~ error:",
                        error
                      );
                      makeToast(
                        "error",
                        t("missingWording.fileRmovedError")
                      );
                    }
                  }}
                >
                  {t("missingWording.delete")} <FiFileMinus size={24} />
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
