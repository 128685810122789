export const eConsult = {
  fr: {
    addEconsult: "Ajouter une e-Consultation",
    EditEconsult: "Modifier e-Consultation",

    participant: "Participant:",
    motif: "Motif de l'e-Consultation",
    selectDate: "Sélectionnez la date et l'heure :",
    eConsult: "e-Consultation",
    eConsultTitle: "e-Consultations",
    eConsultDef: "Effectuer des consultations médicales à distance entre des professionnels de la santé et des participants, souvent par vidéoconférence.",
    nexteConsult: "Prochaine e-Consultation",
    historyeConsult: "Mes e-Consultations passées",
    today: "Aujourd’hui",
    thisWeek: "Cette semaine",
    seeMore: "Voir plus",
    seeLess: "Voir moins",
    back: "Retour",
    NeweConslt: "Nouvelle  e-Consultation",
    noPastEconsult: "pas de e-Consultation passé",
    noTodayeconsult: "pas de e-Consultation aujourd'hui",
    NoWeekEconsult: "pas de e-Consultation cette semaine",
    tapez: "Tapez pour filtrer par email ou par ID public",
    total: "Total des résultats:",
    load: "Chargement...",
    search: "par ID public ou motif",
    cancel: "Annuler",
    patientForms: "Formulaires du patient",
    leave: "Sortir",
    leavecall: "Voulez-vous quitter cet appel ?",
    absParticipent: "Marquer le participant comme absent ?",
    planned: "e-Consultation prévue à",
    start: "Démarrer",
    placeHolderTeleConsult: "Motif de la e-Consultation",
    missMotif: "Merci de préciser le motif de e-Consultation.",
    missparticipant: "Merci de préciser le participant de e-Consultation.",
    missdate: "Merci de préciser la date de e-Consultation.",
    successAdd: " L'ajout de la e-Consultation a été effectué avec succès.",
    successEdit:
      "La mise à jour de la e-Consultation a été effectué avec succès.",
    edit: "Editer",
    realised: "Réalisé",
    canceled: "Annulé",
    waiting: "En attente",
    patientAbsent: "patient absent",
    unrealised: "Non réalisé",
  },
  en: {
    realised: "Realized",
    canceled: "Canceled",
    waiting: "Waiting",
    patientAbsent: "Patient absent",
    unrealised: "Unrealised",
    successAdd:
      "The addition of e-Consultation has been successfully completed.",
    successEdit: "The e-Consultation update has been successfully completed.",
    missMotif: "Please specify the motif for e-consultation.",
    missparticipant: "Please specify the paticipant for e-consultation.",
    missdate: "Please specify the Date for e-consultation.",
    placeHolderTeleConsult: "Motif of e-Consultation",
    EditEconsult: "Edit e-Consultation",

    planned: "e-Consultation planned for",
    start: "Start",
    addEconsult: "Add a e-Consultation ",
    participant: "Participant:",
    motif: "Subject of e-Consultation:",
    selectDate: "Select date & time:",
    eConsult: "e-Consultation",
    eConsultTitle: "My e-Consultations",
    eConsultDef: "Remote medical consultations between healthcare professionals and participants, often via videoconferencing.",
    nexteConsult: "Next e-Consultation",
    historyeConsult: "My past e-Consultations",
    today: "Today",
    thisWeek: "This week",
    seeMore: "See more",
    seeLess: "See less",
    back: "Back",
    NeweConslt: "New  e-Consultation",
    noPastEconsult: "no past e-Consultation",
    noTodayeconsult: "no e-Consultation today",
    NoWeekEconsult: "no e-Consultation this week",
    tapez: "Type to filter by email or public ID",
    total: "Total Results:",
    load: "Loading...",
    patientForms: "Patient Forms",
    search: "by public ID or subject",
    cancel: "Cancel",
    leave: "leave",
    edit: "Edit",
    leavecall: "Do you want leave this call ?",
    absParticipent: "Mark the participant as absent ?",
  },
};
