export const BASE_URL = `${process.env.REACT_APP_KATOMI_API_URL}`;

export const KATOMI_API_ENDPOINT = `${process.env.REACT_APP_KATOMI_API_URL}v${process.env.REACT_APP_KATOMI_API_VERSION}`;

//AUTH
export const USER_VERIFY_URL = `${KATOMI_API_ENDPOINT}/auth`;
export const CONFIRM_EMAIL = `${USER_VERIFY_URL}/confirm-email`;
export const USER_UPDATE_PASSWORD = `${USER_VERIFY_URL}/update-password`;
export const REFRESH_TOKEN = `${USER_VERIFY_URL}/refresh-token`;
export const GET_USER_BY_ID = `${USER_VERIFY_URL}/get-user-byId`;
export const FORGET_PASSWORD = `${USER_VERIFY_URL}/forget-password`;
export const RESET_PASSWORD = `${USER_VERIFY_URL}/reset-password`;
export const RESEND_CONFIRMATION_CODE = `${USER_VERIFY_URL}/resendConfirmationCode`;
export const ASSOCIATE_USER_TO_AUTHENTICATOR = `${USER_VERIFY_URL}/associate-user-to-authenticator`;
export const VERIFY_USER_SOFTWARE = `${USER_VERIFY_URL}/verify-software-token`;
export const VERIFY_USER_MFA = `${USER_VERIFY_URL}/verify-mfa`;
export const ACTIVATE_MFA_USER = `${USER_VERIFY_URL}/activate-mfa`;

//payments
export const PAYMENTS_ENDPOINT = `${KATOMI_API_ENDPOINT}/payments`;
export const PAYMENTS_CREATE_SUBSCRIPTION_URL = `${PAYMENTS_ENDPOINT}/subscription`;
export const PAYMENTS_CREATE_CUSTOMER_ID = `${PAYMENTS_ENDPOINT}/createCustomerId`;
export const PAYMENTS_CREATE_CUSTOMER_ID2 = `${PAYMENTS_ENDPOINT}/createCustomerId2`;
export const PAYMENTS_VERIFY_SUBSCRIPTION_URL = `${PAYMENTS_ENDPOINT}/verifyingSubscription`;
export const PAYMENTS_GET_PAYMENT_METHODS = `${PAYMENTS_ENDPOINT}/payment-methods`;
export const PAYMENTS_GET_INVOICES = `${PAYMENTS_ENDPOINT}/invoices`;

//USER
export const USER_URL = `${KATOMI_API_ENDPOINT}/user`;
export const UPDATE_DATA__USER = `${USER_URL}/updateUser`;
export const GET_DATA_USER = `${USER_URL}/getUserInfo`;
export const UPDATE_LANG__USER = `${USER_URL}/updateUserLang`;
export const CRO_UPDATE_DATA = `${USER_URL}/updateData`;

//AGENDA ENDPOINTS
const AGENDA_ENDPOINT = `${KATOMI_API_ENDPOINT}/agenda`;
export const DOCTOR_ADD_INDISPONIBILITE = `${AGENDA_ENDPOINT}/indisponibilite`;
export const DOCTOR_GET_ALL_BOOKING = `${AGENDA_ENDPOINT}/all-booking`;
export const DOCTOR_RESET_AGENDA = `${AGENDA_ENDPOINT}/reset-agenda`;
export const DELETE_TIME_SLOT_WITH_ID = `${AGENDA_ENDPOINT}/deleteTimeSlot`;

//PATIENT ENDPOINTS
const PATIENT_ENDPOINT = `${KATOMI_API_ENDPOINT}/patients`;
export const PATIENT_LOGOUT_URL = `${PATIENT_ENDPOINT}/auth/logout`;
export const PATIENT_GET_MY_DOCTORS = `${PATIENT_ENDPOINT}/myDoctors`;
export const PATIENT_GET_FILTRED_DOCTORS = `${PATIENT_ENDPOINT}/getSpecialists`;
export const PATIENT_GET_UPCOMING_CONSULTATION = `${PATIENT_ENDPOINT}/getUpcomingConsultation`;
export const PATIENT_OLD_DONE_CONSULTATION = `${PATIENT_ENDPOINT}/getOlDDoneConsultation`;
export const PATIENT_GET_DOCTOR_SPECIALITY_LIST = `${PATIENT_ENDPOINT}/allSpeciality`;
export const DOCTOR_ADD_PATIENT_URL = `${PATIENT_ENDPOINT}/add`;
export const DOCTOR_GET_ALL_PATIENTS_URL = `${PATIENT_ENDPOINT}/patients`;
export const DOCTOR_GET_PATIENT_BY_ID_URL = `${PATIENT_ENDPOINT}/`;
export const DOCTOR_EDIT_PATIENT_BY_ID_URL = `${PATIENT_ENDPOINT}/edit`;
export const DOCTOR_GET_PATIENT_BY_ID_OBSERVATIONS_URL = `${PATIENT_ENDPOINT}/observations`;
export const PATIENT_SIGNUP = `${PATIENT_ENDPOINT}/signup`;
export const PATIENT_LOGIN = `${PATIENT_ENDPOINT}/login`;
export const PATIENT_VERIFY_SMS = `${PATIENT_ENDPOINT}/verify`;
export const PATIENT_SIGNUP_PATIENTELO = `${PATIENT_ENDPOINT}/addPatientPatientelo`;
export const PATIENT_GET_TREATING_DOCTOR = `${PATIENT_ENDPOINT}/my-treating-doctor`;
export const PATIENT_GET_ALL_DOCUMENT = `${PATIENT_ENDPOINT}/get-all-documents`;
export const PATIENT_BOOK_MEETING = `${PATIENT_ENDPOINT}/book-meeting`;
export const PATIENT_ME = `${PATIENT_ENDPOINT}/me`;
export const PATIENT_UPDATE_PROFILE = `${PATIENT_ENDPOINT}/update-patient`;
export const PATIENT_SEARCH = `${PATIENT_ENDPOINT}/search`;
export const PATIENT_SEARCH_OTHER_DOCTORS = `${PATIENT_ENDPOINT}/getOtherDoctors`;
export const PATIENT_SET_STRIPE_CUSTOMER = `${PATIENT_ENDPOINT}/setStripeCustomer`;
export const PATIENT_SETUP_INTENT = `${PATIENT_ENDPOINT}/setup-intent`;

// AUTH ENDPOINTS
const AUTH_ENDPOINT = `${KATOMI_API_ENDPOINT}/auth`;
export const AUTH_REGISTER_URL = `${AUTH_ENDPOINT}/login`;

// CRO ENDPOINTS
export const STUDY_ENDPOINT = `${KATOMI_API_ENDPOINT}/study`;
export const GET_ALL_EPRO_FORMS_PAGINATION = `${STUDY_ENDPOINT}/getAllEproFormsPagination`;
export const RANDOM_GONFIG_ENDPOINT = `${KATOMI_API_ENDPOINT}/randomisationConfig`;
export const ARCHIVE_STUDY = `${STUDY_ENDPOINT}/archiveStudy`;
export const GET_NBR_SHAREDSTATSVALUES = `${STUDY_ENDPOINT}/getNbrOfSharedStatsValues`;
export const ENDING_STUDY = `${STUDY_ENDPOINT}/end/endingStudy`;
export const EVENT_ENDPOINT = `${KATOMI_API_ENDPOINT}/eventConfig`;
export const PARTICIPANT_ENDPOINT = `${KATOMI_API_ENDPOINT}/participant`;
export const PARTICIPANT_RANDO_ENDPOINT = `${PARTICIPANT_ENDPOINT}/firstRandomisation`;
export const PARTICIPANT_NEXT_RANDO_ENDPOINT = `${PARTICIPANT_ENDPOINT}/nextRandomisation`;
export const DOWNLOAD_EXCEL_BY_PARTICIPANT_RESULT = `${PARTICIPANT_ENDPOINT}/downloadParticipantExcel`;
export const PARTICIPANT_STUDIES_URL = `${PARTICIPANT_ENDPOINT}/studyByParticipant`;
export const STUDY_WRITERS_CONFIG_URL = `${KATOMI_API_ENDPOINT}/writresConfig`;
export const STUDY_WRITERS_CONFIG_GET_URL = `${KATOMI_API_ENDPOINT}/writresConfig/getConfig`;
export const ADD_CENTERS_URL = `${STUDY_ENDPOINT}/addCenter`;
export const GET_SURVEY = `${STUDY_ENDPOINT}/survery`;
export const GET_CENTERS_URL = `${STUDY_ENDPOINT}/getCenters`;
export const GET_CENTER_INFO_URL = `${STUDY_ENDPOINT}/infos`;
export const ADD_ROLES_URL = `${STUDY_ENDPOINT}/addRole`;
export const GET_ROLES_URL = `${STUDY_ENDPOINT}/getRoles`;
export const GET_MEMBERS_URL = `${STUDY_ENDPOINT}/members`;
export const GET_SPONSORS_URL = `${STUDY_ENDPOINT}/getSp/`;
export const GET_SURVEYS_URL = `${STUDY_ENDPOINT}/forms`;
export const GET_SURVEYS_EPRO_URL = `${STUDY_ENDPOINT}/eproForms`;
export const GET_TEMPLATE_SURVEYS_URL = `${STUDY_ENDPOINT}/allTemplateForms`;
export const GET_EVENT_URL = `${STUDY_ENDPOINT}/eventsConfig`;
export const GET_STUDY_WRITERS_URL = `${STUDY_ENDPOINT}/writers`;
export const WRITER_STUSIES_URL = `${STUDY_ENDPOINT}/studyByWriter`;
export const CREATE_SURVEYS_URL = `${STUDY_ENDPOINT}/addForm`;
export const CREATE_EPRO_SURVEYS_URL = `${STUDY_ENDPOINT}/addEproForm`;
export const CREATE_EVENTS_URL = `${EVENT_ENDPOINT}/newEventConfig`;
export const DUPLICATE_SURVEYS_URL = `${STUDY_ENDPOINT}/duplicate`;
export const SAVE_SURVEY_AS_TEMPLATE = `${STUDY_ENDPOINT}/saveSurveyAsTemplate`;
export const SET_ARC_ADMIN_STUSIES_URL = `${STUDY_ENDPOINT}/arcAdmin`;
export const SET_INVS_RESPONSABLE_STUSIES_URL = `${STUDY_ENDPOINT}/writerResponsable`;
export const SET_ARC_ADMIN_STUSIES_URL_EXISTARC = `${STUDY_ENDPOINT}/arcAdminExist`;
export const REMOVE_ARCADMIN_TO_STUDY_URL = `${STUDY_ENDPOINT}/removeArcAdmin`;
export const SET_ARC_TO_STUDY_URL = `${STUDY_ENDPOINT}/arcToStudy`;
export const SET_WRITER_TO_STUDY_URL = `${STUDY_ENDPOINT}/writerToStudy`;
export const REMOVE_ARC_TO_STUDY_URL = `${STUDY_ENDPOINT}/removeArcFromStudy`;
export const REMOVE_WRITER_TO_STUDY_URL = `${STUDY_ENDPOINT}/removeWriterFromStudy`;
export const CRO_ENDPOINT = `${KATOMI_API_ENDPOINT}/user`;
export const ORG_ENDPOINT = `${KATOMI_API_ENDPOINT}/organisation`;
export const CRO_REGISTER_URL = `${CRO_ENDPOINT}/cro/register`;
export const ARC_REGISTER_URL = `${CRO_ENDPOINT}/arc/register`;
export const CHECK_CRO_UNPAID_INVOICES = `${CRO_ENDPOINT}/checkCroUnpaidInvoices`;
export const SPONSOR_REGISTER_URL = `${CRO_ENDPOINT}/sponsor/register`;
export const ACCEPT_REGISTER_URL = `${STUDY_ENDPOINT}/sponsor/acceptInvitation`;
export const CHECK_UNPAID_INVOICES = `${CRO_ENDPOINT}/check-unpaid-invoices`;
export const CRO_UPDATE_PROFILE_URL = `${CRO_ENDPOINT}`;

export const WRITER_LIST_URL = `${CRO_ENDPOINT}/writer/`;
export const WRITER_REGISTER_URL = `${CRO_ENDPOINT}/writer/register`;
export const WRITER_PUBLIC_REGISTER_URL = `${CRO_ENDPOINT}/writer/publicCreate`;
export const MASS_WRITER_REGISTER_URL = `${CRO_ENDPOINT}/writers`;
export const ORG_CREATE_URL = `${ORG_ENDPOINT}/create`;
export const CRO_LOGIN_URL = `${CRO_ENDPOINT}/create`;
export const CRO_STUSIES_URL = `${STUDY_ENDPOINT}`;
export const CRO_ARCHIVED_STUSIES_URL = `${STUDY_ENDPOINT}/archivedStudy`;
export const STUSIE_CREATE_URL = `${STUDY_ENDPOINT}/create`;
export const ARC_LIST_URL = `${CRO_ENDPOINT}/arc/getAll`;
export const DELETE_CENTERS_URL = `${STUDY_ENDPOINT}/deleteCentre`;
export const DELETE_ROLES_URL = `${STUDY_ENDPOINT}/deleteRole`;
export const CREATE_UPDATE_CENTRE_URL = `${STUDY_ENDPOINT}/centers/updateOrCreateStudy`;
export const CREATE_UPDATE_ROLE_URL = `${STUDY_ENDPOINT}/roles/updateOrCreateRole`;
export const PARTICIPANT_CREATE_URL = `${PARTICIPANT_ENDPOINT}/create`;
// DOCTORS ENDPOINTS
const DOCTOR_ENDPOINT = `${KATOMI_API_ENDPOINT}/doctors`;
export const DOCTOR_LOGOUT_URL = `${AUTH_ENDPOINT}/logout`;
export const DOCTOR_REGISTER_URL = `${DOCTOR_ENDPOINT}/auth/register`;
export const DOCTOR_LOGIN_URL = `${DOCTOR_ENDPOINT}/auth/login`;
export const DOCTOR_VERIFY_URL = `${DOCTOR_ENDPOINT}/auth`;
export const DOCTOR_PRE_REGISTER_URL = `${DOCTOR_ENDPOINT}/preRegisterDoctor`;
export const DOCTOR_GET_MY_PATIENTS2 = `${DOCTOR_ENDPOINT}/myPatients2`;
export const DOCTOR_GET_ALL_SPECIALIST = `${DOCTOR_ENDPOINT}/`;
export const DOCTOR_GET_PATIENT_WITH_ID = `${DOCTOR_ENDPOINT}/myPatient`;
export const DOCTOR_ADD_CURSUS = `${DOCTOR_ENDPOINT}/addCursus`;
export const DOCTOR_ADD_MODIFY_TARIFS = `${DOCTOR_ENDPOINT}/addOrModifyTarifs`;
export const DOCTOR_ADD_MODIFY_DESCRIPTION = `${DOCTOR_ENDPOINT}/addOrModifyDescription`;
export const DOCTOR_ADD_MODIFY_SITES = `${DOCTOR_ENDPOINT}/addOrModifySites`;
export const DOCTOR_ADD_EXPERTISE = `${DOCTOR_ENDPOINT}/addExpertise`;
export const DOCTOR_ADD_MODIFY_LANGUE = `${DOCTOR_ENDPOINT}/addOrModifyLangues`;
export const DOCTOR_GET_MY_PATIENTS = `${DOCTOR_ENDPOINT}/myPatients`;
export const DOCTOR_GET_MY_PATIENTS_FILTRED = `${DOCTOR_ENDPOINT}/myPatientsFilter`;
export const DOCTOR_DELETE_EXPERTISE = `${DOCTOR_ENDPOINT}/deleteExpertise`;
export const DOCTOR_FORGET_PASSWORD = `${DOCTOR_ENDPOINT}/forgetPassword`;
export const DOCTOR_UPDATE_INFO = `${DOCTOR_ENDPOINT}/updatePatientInfo`;
export const DOCTOR_UPDATE_INS_DATA = `${DOCTOR_ENDPOINT}/updateINSDataByDoctor`;
export const DOCTOR_GET_OPEN_TIME_SLOTS = `${DOCTOR_ENDPOINT}/open-time-slots`;
export const DOCTOR_GET_OPEN_TIME_SLOTS_WITH_CONSULTATION = `${DOCTOR_ENDPOINT}/open-time-slots-and-consultaion`;
export const DOCTOR_ADD_MODIFY_LOCATION = `${DOCTOR_ENDPOINT}/addOrModifyLocation`;
export const DOCTOR_SET_AGENDA_CONFIG = `${DOCTOR_ENDPOINT}/agenda-config`;
export const DOCTOR_GET_CONTRIES = `${DOCTOR_ENDPOINT}/getContriesList`;
export const DOCTOR_UPDATE_DATA = `${DOCTOR_ENDPOINT}/updateData`;
export const DOCTOR_Add_SPECIALIST = `${DOCTOR_ENDPOINT}/inviteNewDoctorWithMail`;
export const DOCTOR_CANCEL_CONSULTATION_BETWEEN_TIME_RANGE = `${DOCTOR_ENDPOINT}/doctorCancelConsultationBetweenTimeRange`;
export const DOCTOR_ADD_EXIST_PATIENT = `${DOCTOR_ENDPOINT}/add-exist-patient`;
export const DOCTOR_GET_ALL_PATIENT_NAME = `${DOCTOR_ENDPOINT}/getPatientsName`;
export const CHECK_CONSULTATION_BETWEEN_TIME_RANGE = `${DOCTOR_ENDPOINT}/checkConsultationBetweenTimeRange`;
export const UPDATE_BILL = `${DOCTOR_ENDPOINT}/updateBill`;
export const DOCTOR_SEEN_NOTIFICATION = `${DOCTOR_ENDPOINT}/notification`;
export const GET_ALL_MATRICULE_INS = `${DOCTOR_ENDPOINT}/getPatientsMaticuleINS`;

// SURVEY ENDPOINT
export const GET_SURVEY_BY_PATIENTID = `${PATIENT_ENDPOINT}/getAllSurveyByPatient`;
export const GET_ALL_SURVEY_DOCTOR = `${DOCTOR_ENDPOINT}/getAllSurvey`;
export const DOCTOR_ASSIGN_SURVEY = `${DOCTOR_ENDPOINT}/doctorAssignSurvey`;
export const PATIENT_RESPONSE_SURVEY = `${PATIENT_ENDPOINT}/updateSurvey`;
export const DOWNLOAD_SURVEY_RESULT = `${PATIENT_ENDPOINT}/downloadSurveyResult`;
export const DOCTOR_GET_ALL_SURVEYID = `${DOCTOR_ENDPOINT}/getAllPatientBySurveyId`;
export const DOCTOR_CREATE_SURVEY = `${DOCTOR_ENDPOINT}/createSurvey`;
export const DOCTOR_UPDATE_SURVEY = `study/updateSurvey`;
export const UPDATE_SURVEY_URL = `study/updateSurveyInformation`;
export const UPDATE_SURVEY_ORDER_URL = `study/order/updateSurveyOder`;
export const DELETE_SURVEY_URL = `study/deleteSurveyById`;
export const SURVEY_GET_STATS = `${DOCTOR_ENDPOINT}/stats`;
export const GET_ALL_DOCTOR_SURVEY = `${DOCTOR_ENDPOINT}/gatAllSurverysDoctors`;
export const FORM_DATA_URL = `${KATOMI_API_ENDPOINT}/formData/`;
export const EVENT_DATA_URL = `${KATOMI_API_ENDPOINT}/participantEvent/`;
export const GET_QUERIES_URL = `${KATOMI_API_ENDPOINT}/query/`;
export const UPDATE_QUERY_ID_URL = `${KATOMI_API_ENDPOINT}/query/update/`;
export const UPDATE_MSG_VIEW_URL = `${KATOMI_API_ENDPOINT}/query/seeQueryMsgs/`;
export const UPDATE_MESSAGE_QUERY = `${KATOMI_API_ENDPOINT}/query/`;

export const GET_QUERY_ARC_LIST = `${GET_QUERIES_URL}queryARC`;
export const GET_EVENTS_ARC_LIST = `${STUDY_ENDPOINT}/getAllEvents`;
export const GET_QUERY_WRITER_LIST = `${GET_QUERIES_URL}queryWriter`;
export const DOWNLOAD_STUDY_RESULT = `${STUDY_ENDPOINT}/downloadAllStudydata`;
export const GET_EXPORTS_LIST = `${STUDY_ENDPOINT}/exports`;
export const DOWNLOAD_EXPORT_FILE = `${STUDY_ENDPOINT}/download-export`;
export const UPDATE_STATUS_QUERY = `${GET_QUERIES_URL}status`;
export const GET_PUBLIC_STUDY = `${STUDY_ENDPOINT}/getPublicStudy/`;
//MEDIA endpoint
export const MEDIA_URL = `${KATOMI_API_ENDPOINT}/media`;
export const DELETE_MEDIA_URL = `${KATOMI_API_ENDPOINT}/media/deleteMedia`;
//traduction endpoint
export const TRANSLATION_ENDPOINT = `${KATOMI_API_ENDPOINT}/translation`;
export const CREATE_TRANSLATION = `${TRANSLATION_ENDPOINT}/createTranslation`;
export const GET_TRANSLATION = `${TRANSLATION_ENDPOINT}/getTranslation`;

// statsValues endpoint
export const STATS_URL = `${KATOMI_API_ENDPOINT}/statsValues`;
export const GET_ALL_STATS_DATA = `${STATS_URL}/getData`;
export const GET_ALL_DASHBOARD_DATA = `${STATS_URL}/getDashboardStats`;
export const GET_ALL_DASHBOARD_DATA_01 = `${STATS_URL}/getDashboardFirstStats`;
export const GET_ALL_DASHBOARD_DATA_02 = `${STATS_URL}/getDashboardSecondStats`;
export const GET_ALL_DASHBOARD_DATA_03 = `${STATS_URL}/getDashboardThirdStats`;
//epro endpoint
export const EPRO_URL = `${KATOMI_API_ENDPOINT}/epro`;
export const GET_ALL_EPROFRORM_DATA = `${EPRO_URL}/getEproForm`;
export const RESEND_ACCESS_TOEKN = `${EPRO_URL}/sendNewAccessToken`;
export const GET_ALL_EPROFRORM_BY_STUDYID_DATA = `${EPRO_URL}/getEproFormByStudyId`;

//ECONSULT
export const CREATE_ECONSULT_URL = `${KATOMI_API_ENDPOINT}/eConsult`;
export const GET_ECONSULT_URL_BYWRITER = `${CREATE_ECONSULT_URL}/getEconsultByWriter/`;
export const GET_PAST_ECONSULT_URL_BYWRITER = `${CREATE_ECONSULT_URL}/getPastEconsultByWriter/`;
export const UPDATE_ECONSULT_BY_WRITER = `${CREATE_ECONSULT_URL}/updateEconsultByWriter/`;
export const GET_ECONSULT_URL_BYPARTICIPANT = `${CREATE_ECONSULT_URL}/getEconsultByParticipant/`;
