import { Stack, IconButton, Box, FormLabel } from "@chakra-ui/react";
import ButtonUI from "../../../ButtonUI";
import { FieldInput } from "../../Elements/FieldInput";
import { DeleteIcon } from "@chakra-ui/icons";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import { t } from "i18next";
import SelectComp from "../../../Select";
import obj from "../../../../config/constants/choices";
import {transformObjectToArray} from '../../../../helper/FormGeneratorHelper/Utils'
const DEFAULT_VALUES = {
  collection: [{ label: "Default label" }],
};

export const Repeater = (props) => {
  const collection = useRepeater({
    name: "options",
    form: props.form,
    initialValues: props.value,
    
  });

  return (
    <Stack
      justifyContent={"flex-start "}
      spacing={2}
      align="start"
      className="border p-4 rounded-lg w-full mb-6 "
    >
      <FormLabel>
        {" "}
        <span style={{ fontSize: "16px" }} className="font-bold ">
         {t('missingWording.choixs')}
        </span>
      </FormLabel>
      <SelectComp options={transformObjectToArray(obj)}  onChange={(e:any) => {
              collection.set(e.target.value.split(',').map((s)=> {
                return {label:s,value:s}
              }))
                return;
              }}/>

    
      <Box>
        {collection.keys.map((key, index) => (
          <Stack
            key={key}
            direction="row"
            spacing="4"
            mb="6"
            data-test={`repeater-item[${index}]`}
          >
            <Box flex="1">
              <FieldInput
                value={props.value[index]?.label}
                name={`options[${index}].label`}
                defaultValue={DEFAULT_VALUES.collection[index]?.label}
                label={`${t('missingWording.choix')+" "+(index + 1)  }`}
                required="Ce champ est requis"
                m="0"
                isPreview={true}
              />
            </Box>

            <Box pt="1.75rem">
              <IconButton
                aria-label="Delete"
                icon={
                  <DeleteIcon
                    className="
                    w-10 h-10"
                  />
                }
                onClick={() => collection.remove(index)}
              />
            </Box>
          </Stack>
        ))}
      </Box>

      <Box>
        {collection.length <= 20 && (
          <ButtonUI
            status="secondary"
            className="p-2 text-xs w-48"
            type="button"
            onClick={() => collection.append()}
          >
            {" "}
            {t('missingWording.addChoice')}
          </ButtonUI>
        )}
      </Box>
    </Stack>
  );
};
