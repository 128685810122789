import { GET_QUERIES_URL, UPDATE_STATUS_QUERY,GET_QUERY_ARC_LIST, GET_QUERY_WRITER_LIST, UPDATE_MESSAGE_QUERY, UPDATE_QUERY_ID_URL, UPDATE_MSG_VIEW_URL,} from "../config/api";
  import { get, post } from "../helper/apiMethods";
  export const getQueriesPerField = async (fieldId,participantId) => {
    try {
      const res = await get(`${GET_QUERIES_URL}${participantId}/${fieldId}`);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  export const updateQuery = async (queryId,values) => {
    try {
      const res = await post(`${UPDATE_QUERY_ID_URL}${queryId}`,values);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  export const seeQueryMsgs = async (queryId) => {
    try {
      const res = await post(`${UPDATE_MSG_VIEW_URL}${queryId}`,null);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const updateQueryStatus = async (queryId,values) => {
    try {
      const res = await post(`${UPDATE_STATUS_QUERY}/${queryId}`,values);
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateQueryMesssage = async (queryId,values) => {
    try {
      const res = await post(`${UPDATE_MESSAGE_QUERY}${queryId}`,values);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const getQuerysARC = async (studyId,page,limit,typeQuery,pubId,testMode) => {
    try {
      const res = await get(`${GET_QUERY_ARC_LIST}/${studyId}?page=${page}&limit=${limit}&status=${typeQuery}&pubId=${pubId}&testMode=${testMode}`);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const getQuerysWriter = async (studyId,centreId,roleId,page,limit,filter,status,searchText,testMode) => {
    try {
      
      const res = await get(`${GET_QUERY_WRITER_LIST}/${studyId}/${centreId}/${roleId}/${filter}?page=${page}&limit=${limit}&status=${status}&pubId=${searchText}&testMode=${testMode}`);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };