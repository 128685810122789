import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { duplicateSurvey } from "../../api/survey";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import makeToast from "../../components/Snackbar";

type Props = any;
const DuplicationEproModal: React.FC<Props> = ({
  visteList,
  surveyDetails,
  setShowDuplicationModal,
  setRefresh,
}) => {
  const params: any = useParams();
  const { studyId } = params;
  const { t } = useTranslation();
  const [step] = useState(1);
  const [type] = useState("Classic");
  const [name, setName] = useState(
    `${surveyDetails?.name} ${t("missingWording.copy")}`
  );
  const [desc, setDesc] = useState("");
  const [dependency, setDependency] = useState("");
  const duplicateSurveyCall = async () => {
    try {
      if (name === "") {
        return makeToast("warning", t("missingWording.fdt2"));
      }

      let payload = {
        ePro: true,
        type: type,
        dependency: dependency,
        name: name,
        description: desc,
        surveyId: surveyDetails?._id,
      };
      const duplictatedFom = await duplicateSurvey(studyId, payload);

      if (duplictatedFom && duplictatedFom?.newSurvey) {
        makeToast("success", t("missingWording.fdt3"));
        setShowDuplicationModal(false);
        setRefresh((refresh) => !refresh);
      } else {
        makeToast("error", "");
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  return (
    <>
      {type !== "" && step == 1 && (
        <>
          <div className="flex-auto py-7 px-8 min-w-full	">
            <div className="flex flex-wrap items-baseline">
              <h1 className="mb-10 text-center">
                {t("missingWording.dupVisit")} {surveyDetails?.name}
              </h1>
            </div>

            <div className="">
              <Input
                id="name"
                className="w-full"
                placeholder={t("missingWording.visitName")}
                labelText={t("missingWording.visitName")}
                type="text"
                value={name}
                onBlur={(e) => setName(e.target.value)}
                onChange={(e) => setName(e.target.value)}
                inlineLabel={false}
                onfocus={true}
                style={{ maxHeight: "50px" }}
              />
            </div>
            <div className="">
              <Input
                id="desc"
                className="w-full"
                placeholder={t("missingWording.fdi1")}
                labelText={t("missingWording.fdi1")}
                type="text"
                style={{ maxHeight: "50px" }}
                onBlur={(e) => setDesc(e.target.value)}
                onChange={(e) => setDesc(e.target.value)}
                inlineLabel={false}
                onfocus={true}
              />
            </div>

            {visteList.length > 0 && (
              <div className="mb-3">
                <SelectComp
                  id="dependency"
                  onChange={(e) => {
                    setDependency(e.target.value);
                    return;
                  }}
                  disabled={!visteList || visteList.length === 0}
                  className="min-w-full"
                  text={
                    visteList.length > 0
                      ? `${t("missingWording.fdi4")}`
                      : `${t("missingWording.fdi5")}`
                  }
                  options={visteList?.map((e) => {
                    return { value: e?._id, label: e?.name };
                  })}
                ></SelectComp>
              </div>
            )}
            <div className="flex  justify-between mt-8">
              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setShowDuplicationModal(false)}
              >
                {t("Investigator.Cancel")}
              </Button>
              <Button
                disabled={name === ""}
                status="primary"
                width="100px"
                height="50px"
                className="float-right mb-4"
                onClick={async () => {
                  await duplicateSurveyCall();
                }}
              >
                {t("missingWording.duplicate")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DuplicationEproModal;
