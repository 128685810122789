

interface Props {
  classNames?: string;
  nhs?: boolean;
  wh?:boolean
}
const index: React.FC<Props> = ({ classNames, nhs,wh }) => {
  return (
   <div className={`${classNames} ${!nhs ? "h-screen" : ""} flexCenter `}>
     <div className={`animate-spin rounded-full border-t-2 border-b-2 border-purple-500 ${wh ? 'h-16 w-16' :' h-32 w-32'}`}></div>
   </div>
    // <Progress isAnimating={true} key={0} />
  );
};

export default index;
