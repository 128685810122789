export const ThanksPage = {
    fr: {
     thankYou:"Merci",
     msg01:"Nous vous remercions d'avoir répondu à ce formulaire et pour votre participation à cette étude.",
        msg02:""
     
    },
    en: {
      thankYou:"Thank you",
      msg01:"Thank you for completing this form and for your participation in this study.",
        msg02:""
  }
}
  