import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { confirmEmail, resendVerificationCode } from "../../api/auth";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import history from "../../history";
import makeToast from "../../components/Snackbar";
interface SigninProps {
  email: string;
  signupMode: boolean;
}

const Signin: React.FC<SigninProps> = ({ email, signupMode }) => {
  console.log("🚀 ~ file: index.tsx:16 ~ signupMode:", signupMode);
  const { t } = useTranslation() as any;
  const params = useParams() as any;
  const [counter, setCounter] = useState(60);
  const [interval, SetInterval] = useState() as any;
  const _resendConfirmationEmail = async () => {
    let propMail = email || params.email;
    if (counter > 0) return;
    try {
      //GET EMAIL ADRESS
      if (!propMail) {
        return makeToast("error", t("SignIn.ErrorSendingCode"));
      }
      const res = await resendVerificationCode({ email: propMail });
      if (res) makeToast("success", t("SignIn.EmailCodeSended"));
      setCounter(60);
    } catch (error) {
      makeToast("error", t("SignIn.ErrorSendingCode"));
      console.error("[error] Signin - onSubmit :", error);
    }
  };
  useEffect(() => {
    if (counter > 0) {
      SetInterval(
        setInterval(() => {
          setCounter(counter - 1);
        }, 1000)
      );
      return () => clearInterval(interval);
    } else {
      SetInterval(null);
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);
  useEffect(() => {
    if (!email) {
      _resendConfirmationEmail();
    }
  }, []);

  const onSubmit = async (values) => {
    let propMail = email || params.email;
    console.log("🚀 ~ file: index.tsx:57 ~ onSubmit ~ propMail:", propMail);
    try {
      const { code } = values;
      const response = await confirmEmail({ email: propMail, code });
      if (response.response === "SUCCESS") {
        makeToast("success", t("SignIn.compteActivated"));
        history.push(`/check/user`);
      } else makeToast("warning", t("SignIn.IncorrectCode"));
    } catch (error) {
      makeToast("error", t("SignIn.ErrorSendingCode"));
      console.error("[error] Signin - onSubmit :", error);
    }
  };

  return signupMode ? (
    <div className="formLogin mt-5">
      <Formik initialValues={{ code: "" }} onSubmit={onSubmit}>
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <>
              <form onSubmit={handleSubmit} className="form-login">
                <h3 className="title flexRowCenter">{t("SignIn.CheckMail")}</h3>
                <h3 className="text-sm text-black text-center mb-5">
                  {t("SignIn.EnterVerificationCode")}
                </h3>{" "}
                <div className="flexRowCenter">
                  <Input
                    id="code"
                    placeholder={t("SignIn.VerificationCode")}
                    labelText={t("SignIn.VerificationCode")}
                    type="text"
                    value={values.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    style={{
                      width: "350px",
                    }}
                  />
                </div>
                <div className="flexRowCenter">
                  <Button
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    status="primary"
                    className="px-4 py-2"
                  >
                    {t("SignIn.Confirm")}
                  </Button>
                </div>
                <p className="text-center">
                  <Button
                    status="secondary"
                    key={"xds102"}
                    onClick={_resendConfirmationEmail}
                    type="button"
                    className="card-subtitle text-center mt-3 px-3 py-1"
                  >
                    <span
                      className="cursor-pointer"
                      style={{ color: counter > 0 ? "" : "black" }}
                    >
                      {t("SignIn.ResendVerificationCode")} ({counter})
                    </span>
                  </Button>
                </p>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div
      className="formLogin mt-5"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Formik initialValues={{ code: "" }} onSubmit={onSubmit}>
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <>
              <form onSubmit={handleSubmit} className="form-login">
                <h3 className="title flexRowCenter">{t("SignIn.CheckMail")}</h3>
                <h3 className="text-sm text-black text-center mb-5">
                  {t("SignIn.EnterVerificationCode")}
                </h3>{" "}
                <div className="flexRowCenter">
                  <Input
                    id="code"
                    placeholder={t("SignIn.VerificationCode")}
                    labelText={t("SignIn.VerificationCode")}
                    type="text"
                    value={values.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    style={{
                      width: "350px",
                    }}
                  />
                </div>
                <div className="flexRowCenter">
                  <Button
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    status="primary"
                    className="px-4 py-2"
                  >
                    {t("SignIn.Confirm")}
                  </Button>
                </div>
                <p className="text-center">
                  <Button
                    status="secondary"
                    key={"xds102"}
                    onClick={_resendConfirmationEmail}
                    type="button"
                    className="card-subtitle text-center mt-3 px-3 py-1"
                  >
                    <span
                      className="cursor-pointer"
                      style={{ color: counter > 0 ? "" : "black" }}
                    >
                      {t("SignIn.ResendVerificationCode")} ({counter})
                    </span>
                  </Button>
                </p>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Signin;
