import {
  Box,
  FormLabel,
  IconButton,
  InputGroup,
  Stack,
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useForm } from "../../../hooks/FormGeneratorHooks";
import { useRepeater } from "../../../hooks/FormGeneratorHooks/useRepeater";
import ButtonUI from "../../ButtonUI";
import { FieldInput } from "./FieldInput";
import { socketAtom } from "../../../state/socket";
import { useAtom } from "jotai";
import { userAtom } from "../../../state/auth";
import { User } from "../../../types/user";
import FieldRadio from "./FieldRadio";
import FieldCheckbox from "./FieldCheckBoxs";
export const RepeaterField = (props) => {
  const [socket] = useAtom(socketAtom) as any;

  const [user] = useAtom<User | null>(userAtom);
  const { required, name, isPreview,isEvent } = props;
  console.log("🚀 ~ props:", props)
  const [onChange, setOnChange] = useState(false);
  const [objValue, setobjValue] = useState(null) as any;
  const form = useForm();
  const collection = useRepeater({
    name: name,
    form: props.form,
    initialValues:
      props.value && typeof props.value === "string"
        ? JSON.parse(props.value)
        : props.form && props.form.values && props.form.values[`${name}`]
        ? props.form.values[`${name}`]
        : [],
        
  });

  useEffect(() => {
    if (props.value && typeof props.value === "string") {
      setobjValue(JSON.parse(props.value));
    } else {
     setobjValue(null)
    }
  }, [props.value]);

  useEffect(() => {
    if (objValue)
      collection.set(
        props.value && typeof props.value === "string"
          ? JSON.parse(props.value)
          : props.form && props.form.values && props.form.values[`${name}`]
          ? props.form.values[`${name}`]
          : []
      );
  }, [objValue]);

  useEffect(() => {
    if (props.form && form) {
      let strigifiedValue = JSON.stringify(form.values[`${name}`]);
      if (props?.onSumbit && form.values[`${name}`]) {
        console.log(
          "🚀 ~ file: RepeaterField.tsx:58 ~ useEffect ~ props.form._id:",
          props.formDataId
        );

        if(socket) {
          socket.emit( isEvent ? "updateEventFormFields":"updateFormPerField", isEvent ? {
            eventId: props.formDataId,
            message: {
              fieldName: name,
              value: strigifiedValue,
              isValid: true,
              stepNbr: props.stepNbr,
              sharedCalculVars: false,
              sharedStatsVars: false,
            },
            userId: user?._id,
          } :{
            roomId: props.formDataId,
            message: {
              fieldName: name,
              value: strigifiedValue,
              isValid: true,
              stepNbr: props.stepNbr,
              sharedCalculVars: false,
              sharedStatsVars: false,
            },
            userId: user?._id,
          });
        }
      }
    }
  }, [onChange]);

  return (
    <>
     
      <InputGroup>
        <Stack
          justifyContent={"flex-start "}
          spacing={2}
          align="start"
          className=" py-2 px-2 rounded-lg w-full mb-6 "
        >
          <FormLabel>
            <span style={{ fontSize: "16px" }}>{props.label}</span>
          </FormLabel>

          <Box>
            {collection.keys.map((key, index) => (
              <Stack
                key={key}
                direction="row"
                spacing="4"
                mb="6"
                data-test={`repeater-item[${index}]`}
              >
                <Box flex="1" className="flex flex-row gap-x-2 justify-center items-center">
                  {props.childFieldsConfig.fields.map((_field: any, i) => {
                    switch (_field.type) {
                      case 'radio':
                       return ( <FieldRadio
                        label={`${_field.name}`}
                        key={`${name}[${index}].${_field.name}`}
                        name={`${name}[${index}].${_field.name}`}
                     
                        disabled={!props.form || props.readOnly}
                        required={
                          _field.required ? "This field is required" : false
                        }
                    
                        value={
                          objValue && objValue[index]
                            ? objValue[index][`${_field.name}`]
                            : null
                        }
                        isPreview={true}
                        options={[{value:'yes',label:'Oui'},{value:'no',label:'Non'}]}
                      />);
                       
                      case 'checkBox':
                     return ( <FieldCheckbox
                      label={`${_field.name}`}
                      key={`${name}[${index}].${_field.name}`}
                      name={`${name}[${index}].${_field.name}`}
                   
                      disabled={!props.form || props.readOnly}
                      required={
                        _field.required ? "This field is required" : false
                      }
                      direction='column'
                      value={
                          objValue && objValue[index]
                            ? objValue[index][`${_field.name}`]
                            : null
                        }
                      isPreview={true}
                      options={[{value:'yes',label:'Oui'},{value:'no',label:'Non'}]}
                    
                    />);
                       
                      default:
                     return (
                      <FieldInput
                        onBlur={(e) => setOnChange((prev) => !prev)}
                        value={
                          objValue && objValue[index]
                            ? objValue[index][`${_field.name}`]
                            : null
                        }
                        name={`${name}[${index}].${_field.name}`}
                        type={_field.type}
                        // defaultValue={DEFAULT_VALUES.collection[index]?.label}
                        label={`${_field.name}`}
                        required={
                          _field.required ? "This field is required" : false
                        }
                        readOnly={!props.form || props.readOnly}
                        m="0"
                        isPreview={true}
                      />
                    );
                    }
                
                  })}
                </Box>

                <Box pt="1.75rem">
                  <IconButton
                    aria-label="Delete"
                    disabled={!props.form || props.readOnly}
                    icon={
                      <DeleteIcon
                        className="
                    w-10 h-10"
                      />
                    }
                    onClick={() => {
                      if(!props.form || props.readOnly) return
                      collection.remove(index);
                      console.log(
                        "🚀 ~ file: RepeaterField.tsx:173 ~ onClick={async ~ index:",
                        collection.length
                      );
                      if (collection.length === 1) {
                        if (props.form && form) {
                          if (props?.onSumbit && form.values[`${name}`])
                            setTimeout(() => {
                              let strigifiedValue = null;
                              props?.onSumbit({
                                fieldName: name,
                                value: strigifiedValue,
                                isValid: true,
                              });
                            }, 400);
                        }
                        return;
                      }
                      console.log(
                        "🚀 ~ file: RepeaterField.tsx:170 ~ props.form.values:",
                        form.values
                      );

                      setTimeout(() => {
                        setOnChange((prev) => !prev);
                      }, 400);
                    }}
                  />
                </Box>
              </Stack>
            ))}
          </Box>

          <Box>
            {collection.length <= 20 && (
              <ButtonUI
                status="primary"
                className="px-5 py-2 text-lg font-bold"
                type="button"
                disabled={!props.form || props.readOnly}
                onClick={() => {
                  collection.append();
                  setOnChange((prev) => !prev);
                }}
              >
                {" + "}
              </ButtonUI>
            )}
          </Box>
        </Stack>
      </InputGroup>
      {!props?.isPreview && (
        <div className="absolute -left-8 top-1/2">
          <p></p>
        </div>
      )}
    </>
  );
};
