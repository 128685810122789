import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import { archiveStudyApi } from "../../../api/study";
import { StudySelectedIdAtom } from "../../../state/studySelectedIdAtom";
import { useAtom } from "jotai";
import makeToast from "../../../components/Snackbar";

type Props = {
  selectedStudy: any;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  participantsCount: number;
  setStep:any
};

export const ArchivageModal: React.FC<Props | any> = ({
  setOpenModal,
  selectedStudy,
  setStep
}) => {
  const { t } = useTranslation() as any;
  const [studySelectedId,setStudySelectedId ] = useAtom(StudySelectedIdAtom);
  const archiveStudyFunc =async () => {
    try {
      const res:any = await archiveStudyApi(studySelectedId);
      console.log("🚀 ~ file: archivageAlert.tsx:22 ~ archiveStudyFunc ~ res:", res)
      if (res && res.message ==='Archived'){
        makeToast('success',t('missingWording.successArchivage'))
        setOpenModal(false)
        setStudySelectedId(null)
        setStep('1')
      }else {
      makeToast('error',t('missingWording.FailArchivage'))

      }

    } catch (error) {
      makeToast('error',t('missingWording.FailArchivage'))
      console.log("🚀 ~ file: archivageAlert.tsx:23 ~ archiveStudyFunc ~ error:", error)
      
    }
  }
  return (
    <div className="px-8">
      <h1 className="mb-4">
      {t("missingWording.ArchiveMsg1")}
       </h1>
      <h3 className="mb-8">
      {t("missingWording.ArchiveMsg2")}

      </h3>
      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-left  btn-delete"
        onClick={() => setOpenModal(false)}
      >
        {t("Participant.Cancel")}
      </Button>

      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-right "
        type="submit"
        onClick={async () => {
          archiveStudyFunc()
          
        }}
      >
        {t("missingWording.confirm")}
      </Button>
    </div>
  );
};

export default ArchivageModal;
