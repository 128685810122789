import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setExistWriterToStudy } from "../../../../api/cro";
import { getStudyRoles } from "../../../../api/study";
import { addWriter } from "../../../../api/writer";
import Button from "../../../../components/ButtonUI";
import InvestigatorAutocompleteInput from "../../../../components/InvestigatorAutocompleteInput";
import SelectComp from "../../../../components/Select";
import makeToast from "../../../../components/Snackbar";
import { loadLocaleLang } from "../../../../helper";
import AddInvestigatorSchema from "./AddInvestigatorSchema";

const AddInvestigator = ({
  setOpenModal,
  setLoadList,
  loadList,
  investigateurs,
  studyId,
}: any) => {
  console.log("🚀 ~ file: index.tsx:21 ~ investigateurs:", investigateurs)
  const [listRolesProps, setListRolesProps] = useState<Array<[]> | any>([]);
  const [listCentersProps, setListCentersProps] = useState<Array<[]> | any>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean | any>(false);

  async function fetchMyAPI(): Promise<void> {
    // setLoading(true)
    const roles = await getStudyRoles(studyId);
    setListRolesProps(
      roles && roles.roles
        ? roles.roles.map((r) => {
            return { value: r?._id, label: r?.name };
          })
        : []
    );
    setListCentersProps(
      roles && roles.centers
        ? roles.centers.map((r) => {
            return { value: r?._id, label: r?.name };
          })
        : []
    );
  }
  useEffect(() => {
    fetchMyAPI();
  }, []);
  const { t } = useTranslation() as any;
  return (
    <div id="AddModalInvestigateur">
      <Formik
        initialValues={{
          writerId: "",
          firstName: "",
          lastName: "",
          email: "",
          roleInvestigateur: "",
          centerId: "",
          studyId: studyId,
          lang: loadLocaleLang(),
          // rememberMe: "no",
        }}
        onSubmit={async (values) => {
          setLoadingSubmit(true);
          try {
            if (!values.email) {
              setLoadingSubmit(false);
              return makeToast("warning", `${t("Investigator.P1")}`);
            }
            if (!values.roleInvestigateur) {
              setLoadingSubmit(false);

              return makeToast("warning", `${t("Investigator.P2")}`);
            }
            if (!values.centerId) {
              setLoadingSubmit(false);

              return makeToast("warning", `${t("Investigator.P3")}`);
            }
            if (!values.firstName) {
              setLoadingSubmit(false);
              return makeToast("warning", `${t("Investigator.P4")}`);
            }
            if (!values.lastName) {
              setLoadingSubmit(false);
              return makeToast("warning", `${t("Investigator.P5")}`);
            }
            if (!values.writerId || values.writerId === "") {
              const res = await addWriter(values);
              if (res) {
                makeToast("success", `${t("Investigator.P6")}`);
                setOpenModal(false);
                setLoadList(!loadList);
              } else {
                makeToast("error", `${t("Investigator.P7")}`);
              }
              setLoadingSubmit(false);
            } else {
              const res = await setExistWriterToStudy(studyId, values);
              if (res) {
                setLoadList(!loadList);
                makeToast("success", `${t("Investigator.P21")}`);
                // setModalAfectation(false);
                setOpenModal(false);
              }
            }
          } catch (err) {
            console.log("[error] add Patient -on Submit :", err);
          }
        }}
        validationSchema={AddInvestigatorSchema}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            touched,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="flex-auto py-7 px-8 min-w-full	"
            >
              <div className="flex flex-wrap items-baseline">
                <h1 className="mb-10">{t("Investigator.P8")}</h1>
              </div>
              <div className="mb-0">
                <InvestigatorAutocompleteInput
                  touched={touched}
                  investigators={investigateurs}
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  addMargin={false}
                />
              </div>
              <div className="mb-4">
                <SelectComp
                  id="roleInvestigateur"
                  onChange={handleChange}
                  className="min-w-full"
                  text={t("Investigator.P17")}
                  options={listRolesProps}
                ></SelectComp>
                {errors.roleInvestigateur && touched.roleInvestigateur && (
                  <p className="text-red-300  text-sm ml-4 mt-3">
                    {t("Investigator.P18")}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <SelectComp
                  id="centerId"
                  onChange={handleChange}
                  className="min-w-full "
                  text={t("Investigator.P19")}
                  options={listCentersProps}
                ></SelectComp>
                {errors.centerId && touched.centerId && (
                  <p className="text-red-300  text-sm ml-4 mt-3">
                    {t("Investigator.P20")}
                  </p>
                )}
              </div>

              <Button
                status="secondary"
                width="100px"
                height="50px"
                className="float-left mb-4 btn-delete"
                onClick={() => setOpenModal(false)}
              >
                {t("Investigator.Cancel")}
              </Button>
              {loadingSubmit ? (
                <div className="flex flex-row-reverse">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
                </div>
              ) : (
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-right mb-4"
                  onClick={() => {
                    handleSubmit();
                  }}
                  id="saveAddInvestigateur"
                >
                  {t("Investigator.save")}
                </Button>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddInvestigator;
