import { useTranslation } from "react-i18next";
import { updateFormInformation } from "../../api/survey";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import makeToast from "../../components/Snackbar";
import { Formik } from "formik";

type Props = any;
const SaveSurveyAsTemplateModal: React.FC<Props> = ({
  surveyDetails,
  setShowSaveAsTemplateModal,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const getChange = (newValues, oldValues) => {
    if (
      newValues.name === oldValues.name &&
      newValues.description === oldValues.description &&
      newValues.roleInvestigateur === oldValues.roleId?._id
    ) {
      if (
        oldValues.dependency &&
        oldValues.dependency.length > 0 &&
        oldValues.dependency[0] === newValues.dependency
      ) {
        return true;
      } else if (
        oldValues.dependency &&
        oldValues.dependency.length === 0 &&
        newValues.dependency === ""
      ) {
        return true;
      } else return false;
    } else return false;
  };
  const formatPayload = (newValues, oldValues) => {
    if (getChange(newValues, oldValues)) {
      return null;
    } else {
      let obj: any = {};
      if (newValues.name !== oldValues.name) {
        obj["name"] = newValues.name;
      }
      if (newValues.description !== oldValues.description) {
        obj["description"] = newValues.description;
      }
      if (newValues.roleInvestigateur !== oldValues.roleId?._id) {
        obj["roleId"] = newValues.roleInvestigateur;
      }
      if (
        (oldValues.dependency &&
          oldValues.dependency.length > 0 &&
          oldValues.dependency[0] !== newValues.dependency) ||
        (oldValues.dependency &&
          oldValues.dependency.length === 0 &&
          newValues.dependency !== "")
      ) {
        obj["dependency"] = newValues.dependency;
      }

      return obj;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          name: surveyDetails?.el?.name,
          description: surveyDetails?.el?.description,
        }}
        onSubmit={async (values) => {

        }}
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;

          return (
            <form
              onSubmit={handleSubmit}
              className="bg-white  w-full flex flex-col"
            >
              <h1 className="font-bold text-center mb-10  ">
                {t("missingWording.edit")} {surveyDetails?.el?.name}
              </h1>
              <div className="mx-4 items-center my-3  ">
                <Input
                  id="name"
                  className="w-full"
                  placeholder={t("missingWording.templateName")}
                  labelText={t("missingWording.templateName")}
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                  style={{ maxHeight: "50px" }}
                />
              </div>
              <div className="mx-4  items-center my-3  ">
                <Input
                  id="description"
                  className="w-full"
                  placeholder={t("missingWording.templateDescription")}
                  labelText={t("missingWording.templateDescription")}
                  type="text"
                  style={{ maxHeight: "50px" }}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inlineLabel={false}
                  onfocus={true}
                />
              </div>
          <div className="flex justify-between mt-4 "> <Button
          status="secondary"
          width={"100px"}
          height={"50px"}
          className="btn-delete"
          onClick={() => setShowSaveAsTemplateModal(false)}
        >
          {t("ArcPage.Cancel")}
        </Button>   <Button
                disabled={values.name === ""}
                status="primary"
                type="submit"
                width={"100px"}
                height={"50px"}
                className="px-2"
                // className="flex-none px-6 py-2 mt-5 mb-2"
                onClick={async () => {
                  try {
                    const res = await updateFormInformation(
                      formatPayload(values, surveyDetails),
                      surveyDetails?.el?._id
                    );
                    if (res) {
                      setRefresh((r) => !r);
                      makeToast("success", t("missingWording.updateTemplate"));
                      setShowSaveAsTemplateModal(false);
                    }
                  } catch (err) {
                    makeToast("error", t("missingWording.pht2"));
                  }
                }}
              >
                {t("missingWording.save")}
              </Button></div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default SaveSurveyAsTemplateModal;
