import { useFormik } from "formik";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { createStudy } from "../../../api/study";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import makeToast from "../../../components/Snackbar";
import { userAtom } from "../../../state/auth";

function AddStudy({ setOpenModal, action, studyTutorial }) {
  const [user] = useAtom(userAtom);
  const { t } = useTranslation() as any;
  const validObj = Yup.object().shape({
    name: Yup.string().required(t("missingWording.requiredOrg")),
    description: Yup.string(),
    croId: Yup.string(),
    organisationId: Yup.string(),
  });

  const onSubmit = async (values) => {
    try {
      const res = await createStudy(values);
      if (res && res.study) {
        makeToast("success", `${t("DashboardCro.AddSuccessStuy")}`);
        setOpenModal(false);
        action(res.study._id);
      } else {
        makeToast("error", `${t("DashboardCro.errorH")}`);
      }
    } catch (err) {
      console.log("[error] add Patient -on Submit :", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      centerAcess: false,
      croId: user?._id,
      organisationId: user?.organisationId?._id,
      anonym: true,
    },
    onSubmit,
    validationSchema: validObj,
  });

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (studyTutorial === "name") setFieldValue("name", "Etude Demo");
    else if (studyTutorial === "description")
      setFieldValue("description", "Lorem ipsum dolaren ipuim");
    else if (studyTutorial === "saveBtn") {
      debugger;
      handleSubmit();
    }
  }, [studyTutorial]);
  return (
    <div>
      <div className="flex flex-wrap justify-center ">
        <h1 className="mb-10 ">{t("DashboardCro.NewStudyModalTitle")}</h1>
      </div>
      <div className="flex gap-y-2 flex-col ">
        <div className="">
          <Input
            id="name"
            placeholder={t("DashboardCro.Name")}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            inlineLabel={false}
            onfocus={true}
            className="w-full -mb-2  "
            labelText={t("DashboardCro.Netu")}
          />
          <span className="text-xs text-red-300 ml-4 ">{errors.name}</span>
        </div>
        <Input
          id="description"
          placeholder="..."
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          inlineLabel={false}
          onfocus={true}
          labelText={t("DashboardCro.dStudy")}
        />
      </div>
      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-left mb-4 mt-6 btn-delete"
        onClick={() => setOpenModal(false)}
      >
        {t("DashboardCro.Cancel")}
      </Button>
      <Button
        status="secondary"
        id="butnSave"
        width="100px"
        height="50px"
        className="float-right mb-4 mt-6"
        disabled={isSubmitting}
        type="submit"
        onClick={() => {
          handleSubmit();
        }}
      >
        {t("DashboardCro.Save")}
      </Button>
    </div>
  );
}

export default AddStudy;
