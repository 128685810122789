import { Form, useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getStudyRoles } from "../../api/study";
import { updateFormInformation } from "../../api/survey";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import makeToast from "../../components/Snackbar";
import { addSharedObjectToFront } from "../../components/CreationSteps";
import { object } from "prop-types";

type Props = {
  survey: any;
  closeModal: any;
  visteList: any[];
  setRefresh: any;
  randomConfig: any;
};
const AssignModal = (props: Props) => {
  const [listRolesProps, setListRolesProps]: any[] = React.useState([]);
  const { t } = useTranslation();
  const { survey, closeModal, visteList, setRefresh, randomConfig } = props;
  const [groups, setGroups] = useState(
    randomConfig
      ? randomConfig.groups.map((g: any) => {
          return {
            label: `${g?.groupName}`,
            value: g?._id,
          };
        })
      : [{ label: "Shared", value: "shared" }]
  ) as any[];
  const [isShared, setIsShared] = useState<boolean>(true);

  const getChange = (newValues, oldValues) => {
    if (
      newValues.name === oldValues.name &&
      newValues.description === oldValues.description &&
      newValues.roleInvestigateur === oldValues.roleId?._id &&
      newValues.associatedGroup === oldValues.associatedGroup
    ) {
      if (
        oldValues.dependency &&
        oldValues.dependency.length > 0 &&
        oldValues.dependency[0] === newValues.dependency
      ) {
        return true;
      } else if (
        oldValues.dependency &&
        oldValues.dependency.length === 0 &&
        newValues.dependency === ""
      ) {
        return true;
      } else return false;
    } else return false;
  };
  const formatPayload = (newValues, oldValues) => {
    if (getChange(newValues, oldValues)) {
      return null;
    } else {
      let obj: any = {};
      if (newValues.name !== oldValues.name) {
        obj["name"] = newValues.name;
      }
      if (newValues.description !== oldValues.description) {
        obj["description"] = newValues.description;
      }
      if (newValues.roleInvestigateur !== oldValues.roleId?._id) {
        obj["roleId"] = newValues.roleInvestigateur;
      }
      if (
        (oldValues.dependency &&
          oldValues.dependency.length > 0 &&
          oldValues.dependency[0] !== newValues.dependency) ||
        (oldValues.dependency &&
          oldValues.dependency.length === 0 &&
          newValues.dependency !== "")
      ) {
        obj["dependency"] = newValues.dependency;
      }
      if (newValues.associatedGroup !== oldValues.associatedGroup) {
        obj["associatedGroup"] = newValues.associatedGroup;
        if(oldValues.shared && newValues.associatedGroup && (newValues.associatedGroup!== null || newValues.associatedGroup !=="" || newValues.associatedGroup !== "shared") ){
          obj['shared'] = false
        }
        if((!oldValues.shared || oldValues.associatedGroup) &&(!newValues.associatedGroup || newValues.associatedGroup === "" || newValues.associatedGroup === "shared") ){
          obj['shared'] = true
        }
      }

      return obj;
    }
  };
  const handleSubmit = async (values, survey) => {
    console.log("4ZAER?", formatPayload(values, survey));
    const res = await updateFormInformation(
      formatPayload(values, survey),
      survey._id
    );
    if (res) {
      setRefresh((r) => !r);
      makeToast("success", t("missingWording.fet1"));
      closeModal();
    } else {
      makeToast("error", t("missingWording.pht2"));
    }
  };
  const params: any = useParams();
  const { studyId } = params;
  async function fetchMyAPI(): Promise<void> {
    const roles = await getStudyRoles(studyId);
    setListRolesProps(
      roles
        ? roles.roles.map((r) => {
            return { value: r?._id, label: r?.name };
          })
        : []
    );
  }
  React.useEffect(() => {
    fetchMyAPI();
  }, []);
  const formik = useFormik({
    initialValues: {
      name: survey.name,
      description: survey.description,
      roleInvestigateur: survey.roleId?._id,
      dependency:
        survey.dependency && survey.dependency.length > 0
          ? survey.dependency[0]
          : "",
      associatedGroup: survey.associatedGroup ?? null,
      shared: randomConfig ? isShared : true,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <Form className="bg-white p-6 w-full flex flex-col ">
      <h3 className="font-bold text-2xl text-black text-center my-10 mx-6 px-24 ">
        {t("missingWording.visitInfos")}
      </h3>
      <div className=" mx-4 items-center my-3  ">
        <Input
          id="name"
          className="w-full"
          placeholder={t("missingWording.visitName")}
          labelText={t("missingWording.visitName")}
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inlineLabel={false}
          onfocus={true}
          style={{ maxHeight: "50px" }}
        />
      </div>
      <div className="mx-4  items-center my-3  ">
        <Input
          id="description"
          className="w-full"
          placeholder={t("missingWording.fdi1")}
          labelText={t("missingWording.fdi1")}
          type="text"
          style={{ maxHeight: "50px" }}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inlineLabel={false}
          onfocus={true}
        />
      </div>
      <div className="mx-4  items-center -mt-1 my-3 ">
        <label>{t("missingWording.fel1")}</label>
        <SelectComp
          id="roleInvestigateur"
          value={formik.values.roleInvestigateur}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!listRolesProps || listRolesProps.length === 0}
          className="min-w-full "
          text={
            listRolesProps.length > 0
              ? `${t("missingWording.fdi2")}`
              : `${t("missingWording.fdi3")}`
          }
          options={listRolesProps}
        ></SelectComp>
      </div>
      {visteList.length > 0 && (
        <div className="mx-4  items-center mt-2 my-3">
          <label>{t("missingWording.fdi4")}</label>
          <SelectComp
            id="dependency"
            value={formik.values.dependency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={!visteList || visteList.length === 0}
            className="min-w-full "
            text={
              visteList.length > 0
                ? `${t("missingWording.fdi4")}`
                : `${t("missingWording.fdi5")}`
            }
            options={visteList
              ?.filter((x: any) => x._id !== survey._id)
              .map((e) => {
                return { value: e?._id, label: e?.name };
              })}
          ></SelectComp>
        </div>
      )}
      {randomConfig && randomConfig?.groups && (
        <div className="mx-4  items-center mt-2 my-3 ">
          <label>{t("missingWording.inpXALabel")}</label>
          <SelectComp
            id="associatedGroup"
            value={formik.values.associatedGroup}
            onChange={(e) => {
              console.log(
                "🚀 ~ file: index.tsx:266 ~ e.target.value:",
                e.target.value
              );

              if (e.target.value === "shared" || e.target.value === "") {
                setIsShared(true);
                formik.setFieldValue("associatedGroup", null);
              } else {
                setIsShared(false);
                formik.setFieldValue("associatedGroup", e.target.value);
              }
              return;
            }}
            disabled={!groups || groups.length === 0}
            className="min-w-full mb-8"
            text={`${
              groups.length > 0
                ? t("missingWording.inpXA")
                : t("missingWording.inpXAn")
            }`}
            options={addSharedObjectToFront(groups, t)}
          ></SelectComp>
        </div>
      )}

      <Button
        disabled={
          formik.values.name === "" ||
          formik.values.roleInvestigateur === "" ||
          getChange(formik.values, survey)
        }
        status="primary"
        className="px-6 py-2 mt-5 mb-2"
        onClick={async () => {
          handleSubmit(formik.values, survey);
        }}
      >
        {t("missingWording.save")}
      </Button>
    </Form>
  );
};

export default AssignModal;
