export const SignUp = {
  fr: {
    register: "S'inscrire",
    im: "Je suis",
    nFirstName:"Prénom",
    nLastName:"Nom",
    doctor: "une CRO",
    patient: "a patient",
    email: "Email",
    psw: "Mot de passe",
    voir: "voir",
    fillName: "Veuillez remplir votre prénom",
    fillLastName: "Veuillez remplir votre nom",
    fillEmail: "Veuillez remplir votre email",
    fillPSW: "Veuillez remplir votre password",
    fillConfirmedPSW: "Veuillez comfirmer votre mot de passe",
    fillNmInscr: "Veuillez remplir votre numéro d'inscription ",
    filladdress: "Veuillez remplir votre adresse ",
    fillCity: "Veuillez remplir votre ville",
    fillPostalCode: "Veuillez remplir votre code postal",
    fillPhone: "Veuillez remplir votre numéro de téléphone",
    fillBirthDate: "Veuillez remplir votre date de naissance",
    fillPays: "Veuillez remplir votre pays",
    fillSexe: "Veuillez sélectionner votre sexe",
    CGU: "Veuillez accepter les conditions générales d'utilisation",
    fillValidEmail: "Veuillez entrer un email valide",
    pswConstraint:
      "Le mot de passe doit contenir au minimum 12 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre",
    pswConfirmedConstraint: "Les mots de passe ne correspondent pas",
    existdoctorwithemail: "Un médecin existe avec ce mail",
    existdoctorwithPhoneNumber: "Un médecin existe avec ce numéro de téléphone",
    existPatientwithemail: "Un patient existe avec ce mail",
    existPatientwithPhoneNumber:
      "Un patient existe avec ce numéro de téléphone",
    birthDate: "Date de naissance (JJ/MM/AAAA)",
    SuccessMsg: "Votre inscription a été effectuée avec succès",
    COU: "Consulter les Conditions Générales d'Utilisation",
    maxSize: "Taille max (8 mo)",
    acceptedForm: "Formats acceptés (.png , .jpg , jpeg , .webp)",
    BusinessCard: " Carte professionnelle ",
    numberPhone: "Saisissez  votre numéro de téléphone",
    postalCode: "Saisissez votre code postal",
    city: "Saisissez votre ville",
    sexe: "Saisissez votre sexe",
    name: "Saisissez votre prénom",
    lastName: "Saisissez votre nom",
    registerNumber: "Votre numéro d'inscription professionnel",
    registerNumberRPPS: "Votre numéro d'inscription (RPPS,Adeli,...)",
    confirmPSW: "Confirmer votre mot de passe",
    speciality: "Saisissez votre spécialité",
    country: " Saisissez votre pays",
    address: " Saisissez votre adresse professionnelle",
    Error: "Une erreur s'est produite, Veuillez réessayer plus tard",
    SocialSecurityNumber: "Votre numéro de sécurité sociale",
    croExist:"Un CRO existe avec ce mail",
    WriterExist:"Un investigateur existe avec ce mail",
    verIdnt:"Veuillez vérifier vos identifiants et votre connexion",
  },
  en: {
    nFirstName:"First name",
    nLastName:"Last name",
    verIdnt: "Please verify your credentials and login",
    croExist: "A CRO exists with this mail",
    WriterExist:"A investigator exists with this mail",
    register: "Register",
    im: "I'm",
    doctor: "a CRO Manager",
    patient: "a patient",
    email: "Email",
    psw: "Password",
    voir: "see",
    fillName: "Please fill in your first name",
    fillLastName: "Please fill in your last name",
    fillEmail: "Please fill in your email",
    fillPSW: "Please fill in your password",
    fillConfirmedPSW: "Please fill in your confirmed password",
    fillNmInscr: "Please fill in your registration number",
    filladdress: "Veuillez remplir votre address ",
    fillCity: "Please fill in your city",
    fillPostalCode: "Please fill your postal code",
    fillPhone: "Please fill your phone number",
    fillBirthDate: "Please fill in your date of birth",
    fillPays: "Veuillez remplir your votre pays",
    fillSexe: "Please fill your sexe",
    CGU: "Please accept the terms of use",
    fillValidEmail: "Please enter a valid email",
    pswConstraint:
      "The password must contain at least 12 characters, namely: at least one lowercase letter and one uppercase letter, one special character and one number",
    pswConfirmedConstraint: "Passwords do not match",
    existdoctorwithemail: "A doctor exists with this email",
    existdoctorwithPhoneNumber: "A doctor exists with this phone number",
    existPatientwithemail: "A patient exists with this email",
    existPatientwithPhoneNumber: "A patient exists with this phone number",
    birthDate: "BirthDate (JJ/MM/AAAA)",

    SuccessMsg: "Your registration has been done successfully",
    COU: "Consult the General Conditions of Use",
    maxSize: "max size (8 mo)",
    acceptedForm: "Formats accepted (.png , .jpg , jpeg , .webp)",
    BusinessCard: " Business card ",

    numberPhone: "Enter your phone number",
    postalCode: "Enter your postal code",
    city: "Enter your city",
    sexe: "Enter your sexe",
    name: "Enter your first name",
    lastName: "Enter your last name",
    registerNumber: "Your professional registration number",
    registerNumberRPPS: "Your registration number (RPPS,Adeli,...)",
    confirmPSW: "Confirm your password",
    speciality: "Enter your specialty",
    country: " Enter your country",
    address: "Enter your address",
    Error: "An error has occurred, please try again later",
    SocialSecurityNumber: "Your social security number",
  },
};
