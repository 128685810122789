import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteStudyRoles } from "../../../../api/study";
import Button from "../../../../components/ButtonUI";
import makeToast from "../../../../components/Snackbar";
import Input from "../../../../components/Input";

interface Props {
  deleteRoleModal: boolean;
  setDeleteRoleModal: Function;
  setShouldUpdate: Function;
  roleId: string;
  studyId: string;
  hasWriter: boolean;
  roleName: string;
 
}

export const DeleteRoleModal: React.FC<Props> = ({
  setDeleteRoleModal,
  hasWriter,
  roleId,
  studyId,
  setShouldUpdate,
  roleName,
}) => {
  const { t } = useTranslation() as any;
  const [stateRoleName, setStateRoleName] = useState("");
  return (
  
       <div className="px-8 py-4"> <h3 className="mb-4">{t("Role.Srole")}</h3>
       {hasWriter && (
         <h3 className="mb-4 text-sm ">
          {t("Role.ExRole")}
         </h3>
       )}
        <Input
        labelProps={{
          className: "color-primary-grey-title text-sm font-semibold",
        }}
        autoFocus={true}
        //  labelText={t("Centre.nameCenter")}
        labelText={`${t("missingWording.centerDelete_1") }"${roleName}" ${t("missingWording.centerDelete_1_1")}`}
        className=" mt-1 w-full border border-gray-300 hover:border-gray-400 focus:border-gray-400 rounded-md shadow-sm px-2 py-2"
        placeholder=""
        onChange={(e) => setStateRoleName(e.target.value)}
      />
       <div className="flex justify-between mt-8 ">
         <Button
           status="secondary"
           width={"119px"}
           height={"50px"}
           onClick={async () => {
             setDeleteRoleModal(false);
           }}
         >
           {t("Role.Cancel")}
         </Button>
         <Button
           status="secondary"
           width={"119px"}
            className={`${
            roleName === stateRoleName
              ? "border-red-300 text-red-300 hover:border-red-400 hover:text-red-400"
              : "border-gray-400 text-gray-400 hover:border-gray-500 hover:text-gray-500"
          }`}
        
           height={"50px"}
           onClick={async () => {
                        if (roleName !== stateRoleName) {
              if (stateRoleName === "" || !stateRoleName)
                makeToast(
                  "warning",
                  `${t("missingWording.profilDelete_2") }`
                );
              else
                makeToast("warning",  `${t("missingWording.profilDelete_3") }`);
              return null;
            }
             let response: any = await deleteStudyRoles(studyId, roleId);
             if (response) {
               makeToast("success", `${t("Role.MsgDelete")}`);
               setShouldUpdate((up: boolean) => !up);
             }
             setDeleteRoleModal(false);
           }}
   
         >
           {t("Role.Delete")}
         </Button>
       </div></div>

  );
};

export default DeleteRoleModal;
