import MultiSelectReact from "@yana4961/react-multiselect";
import "@yana4961/react-multiselect/dist/index.css";
import { useAtom } from "jotai";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUserDelete } from "react-icons/ai";
import { FaUserShield } from "react-icons/fa";
import { getAllArc } from "../../../api/arc";
import {
  ignoreArcAdmin,
  setArcAdminExist,
  setArcToStudy,
} from "../../../api/cro";
import { getStudyMembers } from "../../../api/study";
import arrowDown from "../../../assets/icons/arrow.svg";
import checkMark from "../../../assets/icons/checked.svg";
import { ReactComponent as WomanPatient } from "../../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../../assets/illustrations/illustration-homme-patient.svg";
import Button from "../../../components/ButtonUI";
import Modal from "../../../components/ModalUI";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import Tooltip from "../../../components/toolTip";
import { checkArcAdminWithStudyId } from "../../../helper";
import { listMemberAtom } from "../../../state/listMember";
import DeleteArcModal from "./modal/deleteArcModal";
import "./styles.scss";
const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];

type Props = {
  studyId: string;
  modalAfectation: boolean;
  setModalAfectation: React.Dispatch<React.SetStateAction<string>>;
  studyConfig: any;
  setUpdateStudy: any;
};
const MesArcs: React.FC<Props | any> = ({
  studyId,
  modalAfectation,
  setModalAfectation,
}) => {
  const [modalAdd, setModalAdd] = useState(false);
  const [modalArcAdmin, setModalArcAdmin] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState() as any;
  const [loading] = useState(false);
  const [listAllArc, setListAllArc] = React.useState<any>([]);
  const [loadList, setLoadList] = React.useState<any>(false);
  const [arcsStudy, setArcsStudy] = useAtom(listMemberAtom);
  const [selectedIds, setSelectedIds] = useState([]); // ids marked resources
  const { t } = useTranslation() as any;

  // Event triggered after change marked options,
  // if checked options equal selectedResource,
  // no changes have occurred, the event will not be triggered
  const filterResource = (resultChecked) => {
    setSelectedIds(resultChecked);
  };

  async function fetchMyAPI(): Promise<void> {
    let v1 = await getStudyMembers(studyId);
    if (!v1) {
      return makeToast("error", `${t("ArcPage.listBug")}`);
    }

    setArcsStudy(v1);
  }

  async function fetchAllArcs(): Promise<void> {
    let response: any = await getAllArc();
    if (!response) {
      return makeToast("error", `${t("ArcPage.listBug")}`);
    }
    setListAllArc(_.differenceBy(response, arcsStudy, "_id"));
  }

  useEffect(() => {
    if (modalAfectation) fetchAllArcs();
  }, [modalAfectation]);

  useEffect(() => {
    fetchMyAPI();
  }, [loadList]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className=" my-patients mt-12">
        <div
          className={`patient-list flex flex-wrap gap-y-10 justify-between w-full"`}
        >
          {arcsStudy && arcsStudy.length !== 0 ? (
            arcsStudy
              .sort(
                (a: any, b: any) =>
                  Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
              )
              .map((e, i) => {
                return (
                  <div
                    className={`card-patient justify-between bg-white p-4 relative ${
                      selectedDoctor?._id === e?._id
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedDoctor(e);
                    }}
                  >
                    <div className="flex patient-info">
                      <div
                        className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                        style={{
                          backgroundColor: colorAvatar[i % colorAvatar.length],
                          cursor: "pointer",
                        }}
                      >
                        {e?.gender === "male" ? (
                          <ManPatient />
                        ) : (
                          <WomanPatient />
                        )}
                      </div>
                      <div className="details" style={{ cursor: "pointer" }}>
                        <div className="flex-grow ">
                          <p
                            className="card-title capitalize"
                            style={{ cursor: "pointer" }}
                          >
                            {e.firstName} {e.lastName}
                          </p>
                          <p className="card-subtitle">
                            <span>{e.email}</span>
                          </p>
                        </div>
                      </div>
                      <div className=" flex md:flex-row xs:flex-col md:gap-x-2 xs:gap-y-2 absolute right-0 ">
                        <Tooltip
                          tooltipText={
                            checkArcAdminWithStudyId(studyId, e?.studiesAdmin)
                              ? `${t("ArcPage.RetireAdmins")}`
                              : `${t("ArcPage.DesignAdmin")}`
                          }
                          position={"bottomLeft"}
                          clsText={{ fontSize: "10px", padding: "5" }}
                        >
                          <FaUserShield
                            className={
                              checkArcAdminWithStudyId(studyId, e?.studiesAdmin)
                                ? "text-green-400 cursor-pointer mr-4 p-1 border border-green-400 rounded-full"
                                : "text-gray-400 cursor-pointer mr-4 p-1 border border-gray-400 rounded-full"
                            }
                            onClick={() => {
                              setSelectedDoctor(e?._id);
                              setModalArcAdmin(true);
                            }}
                            size="28"
                          />
                        </Tooltip>
                        <Tooltip
                          tooltipText={`${t("Investigator.Delete")}`}
                          position={"bottomLeft"}
                          clsText={{ fontSize: "10px", padding: "5" }}
                        >
                          <AiOutlineUserDelete
                            className="text-red-400 cursor-pointer mr-4 p-1 border border-red-400 rounded-full"
                            onClick={() => setModalAdd(true)}
                            size="28"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("SideBar.NoMemberFound")}</h3>
            </div>
          )}
        </div>

        <Modal
          closeModal={() => setModalAdd(false)}
          showModal={modalAdd}
          className="px-10 py-16"
        >
          <DeleteArcModal
            setDeleteArcModal={setModalAdd}
            setShouldUpdate={setLoadList}
            arcId={selectedDoctor?._id}
            studyId={studyId}
          />
        </Modal>

        <Modal
          closeModal={() => {
            setModalAfectation(false);
            setSelectedIds([]);
          }}
          showModal={modalAfectation}
          className="md:p-10 xs:p-5 md:mx-16 xs:m-6 "
        >
          <div className="flex-auto py-7  min-w-full	">
            <h1 className="mb-4 text-center">{t("Investigator.P80")}</h1>
            <h3 className=" p-2 mb-4  ">{t("SideBar.MemberToEquipe")}</h3>
            <div className="xs:w-auto md:w-full mb-12">
              <MultiSelectReact
                fetchAllResources={() =>
                  console.log("Event triggered after changing options")
                }
                resources={listAllArc.map((arc) => {
                  return {
                    id: arc._id,
                    name: `${arc.lastName} ${arc.firstName}`,
                  };
                })}
                filterResource={filterResource}
                withCheckBox
                id={"MultiSelectExample"}
                selectedResource={selectedIds} // ids marked resources
                textChoose={`${t("ArcPage.pleaseSelect")}`} // string for empty mulltiselect choice
                arrowDown={arrowDown} // this for set arrow for right side in input
                checkMark={checkMark} // img for marked selected checkboxes options, X by default
                numShowPreview={3} // how many preview boxes show
                classes={[
                  "hoverBg",
                  "checkedBg",
                  "bgWithoutPadding",
                  "withoutShadow",
                  "withBorder",
                  "borderRadius3",
                ]}
                fields={{ uniqId: "id", displayed: "name" }} // sets fields 'uniqId' as value option and 'displayed' as displayed value option from 'resources' objects
              />
            </div>
            <div className="mx-4 mt-16 flex justify-between ">
              <Button
                type="button"
                status="secondary"
                width="100px"
                height="50px"
                className="p-2 btn-delete"
                onClick={() => setModalAfectation(false)}
              >
                {t("SideBar.Cancel")}
              </Button>

              <Button
                type="button"
                status="secondary"
                width="100px"
                height="50px"
                className="p-2"
                onClick={async () => {
                  let res = await setArcToStudy(studyId, {
                    arcsId: selectedIds,
                  });
                  if (res) {
                    makeToast("success", `${t("ArcPage.AffectSuccess")}`);
                    setModalAfectation(false);
                    setLoadList((load) => !load);
                  }
                }}
              >
                {t("SideBar.Add")}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          closeModal={() => {
            setModalArcAdmin(false);
            setSelectedDoctor();
          }}
          showModal={modalArcAdmin}
          className="px-12 py-10 "
        >
          <div className="px-4">
            <h3 className="mb-10">
              {t("ArcPage.StudyQuest")}{" "}
              {checkArcAdminWithStudyId(studyId, selectedDoctor?.studiesAdmin)
                ? `${t("ArcPage.retireAds")}`
                : `${t("ArcPage.des")}`}
              {`${
                selectedDoctor?.gender === "male"
                  ? ` ${t("ArcPage.Mr")} `
                  : ` ${t("ArcPage.Ms")} `
              }`}{" "}
              {`${selectedDoctor?.firstName} `} {`${t("ArcPage.asAdmin")}`} ?
            </h3>
            <div className="flex justify-between ">
              <div>
                {" "}
                <Button
                  type="button"
                  status="secondary"
                  className="p-2 btn-delete"
                  width="100px"
                  height="50px"
                  onClick={() => {
                    setModalArcAdmin(false);
                    setSelectedDoctor();
                  }}
                >
                  {t("SideBar.Cancel")}
                </Button>
              </div>
              <div>
                {" "}
                <Button
                  type="button"
                  status="secondary"
                  className="p-2"
                  width="100px"
                  height="50px"
                  onClick={async () => {
                    let res: any;
                    const flag = checkArcAdminWithStudyId(
                      studyId,
                      selectedDoctor?.studiesAdmin
                    );
                    if (!flag) {
                      res = await setArcAdminExist(studyId, {
                        arcId: selectedDoctor?._id,
                      });
                      if (res) {
                        makeToast("success", `${t("ArcPage.affectStudy")}`);
                      }
                    } else {
                      res = await ignoreArcAdmin(studyId, {
                        arcId: selectedDoctor?._id,
                      });
                      if (res) {
                        makeToast("success", `${t("ArcPage.MajSuccssArc")}`);
                      }
                    }
                    setModalArcAdmin(false);
                    setSelectedDoctor();
                    setLoadList((load) => !load);
                    // setUpdateStudy((s) => !s);
                  }}
                >
                  {checkArcAdminWithStudyId(
                    studyId,
                    selectedDoctor?.studiesAdmin
                  )
                    ? `${t("ArcPage.Retire")}`
                    : `${t("ArcPage.des")}`}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default MesArcs;
