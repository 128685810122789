import {
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { MdErrorOutline } from "react-icons/md";
import { useField } from "../../../hooks/FormGeneratorHooks";
import { visteAtom } from "../../../state/visteAtom";
import { FormGroup } from "./FormGroup";
import { userAtom } from "../../../state/auth";
export const FieldPhoneInput = (props) => {
  
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    isValidating,
    resetKey,
    setValue,
    value,
    errorMessages,

    otherProps,
  } = useField(props);

  const { required, name } = props;
  const [user] = useAtom(userAtom);
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const [isValidation, setIsValidation] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper: isTouched ? helper : "",
    id,
    isRequired: !!required,
    label,
    showError,
    name,

    ...rest,
  };
  console.log("🚀 ~ file: FieldPhoneNumber.tsx:56 ~ FieldPhoneInput ~ formGroupProps.rest:", rest)

  return (
    <FormGroup {...formGroupProps}>
      

      <InputGroup>
        {rest.hasprefix && (
          <InputLeftElement className="p-0.5 text-sm " p={0.5} fontSize={12}>
            {rest?.hasprefix?.prefix}
          </InputLeftElement>
        )}

        <Input
          borderWidth={props.readOnly ? 2 : 1}
          disabled={props.readOnly ? props.readOnly : false}
          type={'number'}
          id={id}
          value={value ?? ""}
          onChange={(e) => {
            setIsValidation(true);
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            setIsTouched(true);
            setIsValidation(false);
            if (props?.onSumbit)
              props?.onSumbit({
                fieldName: name,
                value: e.target.value,
                isValid: isValid,
              });
            //setIsTouched(false);
          }}
          fontSize="14px"
          _invalid={{ borderColor: "Red" }}
          className=" focus:border-blue-500 focus:shadow-sm w-full "
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : undefined}
          placeholder={placeholder}
          
        />
      </InputGroup>
      {!props?.isPreview && user?.type === "writer" && (
        <div className="absolute -left-8 top-1/2">
          {" "}
          {isValidation && (
            <p>
              <Spinner size="sm" flex="none" color="blue.200" />
            </p>
          )}
          <p>
            {" "}
            {isValid && !isValidation && (
          <HiCheckCircle color="#67d428" width={20} height={20} />
            )}
            {!isValid && !isValidation && (
              <MdErrorOutline color="#ff0000" width={20} height={20} />
            )}
          </p>
        </div>
      )}
      {children}
    </FormGroup>
  );
};
