import React from "react";
import { useTranslation } from "react-i18next";
import { deleteSurveyById } from "../../api/survey";
import Button from "../../components/ButtonUI";
import makeToast from "../../components/Snackbar";

export const DeleteSurveyModal: React.FC<any> = ({
  surveyDetails,
  setShouldUpdate,
  closeModal,
}) => {
  const { t } = useTranslation() as any;

  return (
    <div className="px-4 ">
      <h3 className=" mb-8 ">{t("missingWording.deleteTemplate")}</h3>
      <div className="flex justify-between  ">
        <Button
          status="secondary"
          width={"100px"}
          height={"50px"}
      
          onClick={async () => {
            closeModal();
          }}
        >
          {t("ArcPage.Cancel")}
        </Button>
        <Button
          status="secondary"
          className="btn-delete"
          width={"100px"}
          height={"50px"}
          onClick={async () => {
            let response: any = await deleteSurveyById(surveyDetails?.el?._id);
            if (response) {
              makeToast("success", t("missingWording.fddt1"));
              setShouldUpdate((up: boolean) => !up);
              closeModal();
            } else {
              makeToast("error", t("missingWording.fddt2"));
            }
          }}
        >
          {t("ArcPage.Delete")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteSurveyModal;
