import Fuse from "fuse.js";
import { useAtom } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllArc } from "../../api/arc";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import ArcInfo from "../../components/SharedArcDetails";
import makeToast from "../../components/Snackbar";
import { ActiveRouteAtom } from "../../state/route";
import AddArc from "./AddARC/index";
import ConfirmAddDoctor from "./ConfirmModalAddDoctor";
import "./styles.scss";

const colorAvatar = [
  "#ccd2ff",
  "#d5f0f7",
  "#ffecba",
  "#fff7ab",
  "#ffccd2",
  "#ffe2c8",
];
const MesArcs = () => {
  const { t } = useTranslation() as any;
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [data] = useState<any>(null);
  const [selectedArc, setSelectedArc] = useState<any>("");
  const [, setFilteredData] = React.useState<any>([]);
  const [strFilter] = React.useState<string>("");
  const [listArc, setListArc] = React.useState<any>([]);
  const [colorIndex, setColorIndex] = useState(1);
  const [modal, setModal] = useState(false);

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const handleListFiltering = () => {
    const fuse = new Fuse(data, {
      threshold: 0,
      shouldSort: true,
      keys: ["slug", "firstName", "lastName"],
    });
    const newList = fuse.search(strFilter).map((item) => {
      return item.item;
    });
    setFilteredData(newList);
  };

  React.useEffect(() => {
    if (strFilter !== "") {
      handleListFiltering();
    }
  }, [strFilter, data]);

  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      let response: any = await getAllArc();
      setListArc(response);

      if (!response) {
        makeToast("error", t("missingWording.loadFail"));
      }
      setListArc(response);
    }
    fetchMyAPI();
  }, [shouldUpdate]);
  useEffect(() => {
    setPath("/mesArc");
  }, []);
  return (
    <>
      <div className="mx-16 my-patients">
        <header className="flex justify-between items-center w-full flex-wrap">
          <div className="title-container">
            <h1 className="pageTitle">{t("ArcPage.team")}</h1>
            <h3 className="card-subtitle"></h3>
          </div>
          <div className="flex max-w-max items-center space-x-2 ">
            <div className="relative">
              <Button
                status="secondary"
                onClick={() => setModalAddConfirm(true)}
                className="btnAddPatient"
              >
                {t("ArcPage.addUser")}
              </Button>
            </div>
          </div>
        </header>

        <div className={`patient-list flex flex-wrap justify-between w-full"`}>
          {listArc && listArc.length !== 0 ? (
            listArc.map((e: any, i) => {
              return (
                <div
                  className={`card-patient justify-between bg-white  ${
                    selectedArc === e?._id
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedArc(e);
                    setModal(!modal);
                    setColorIndex(i);
                  }}
                >
                  <div className="flex patient-info">
                    <div
                      className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                      style={{
                        backgroundColor: colorAvatar[i % colorAvatar.length],
                        cursor: "pointer",
                      }}
                    >
                      {e?.gender === "male" ? <ManPatient /> : <WomanPatient />}
                    </div>
                    <div className="details" style={{ cursor: "pointer" }}>
                      <div className="flex-grow ">
                        <p
                          className="card-title capitalize"
                          style={{ cursor: "pointer" }}
                        >
                          {e.firstName} {e.lastName}
                        </p>
                        <p className="card-subtitle">
                          {t("ArcPage.lastAct")}{" "}
                          <span>
                            {moment(e.updatedAt).format("DD-MM-YYYY") +
                              t("DashboardCro.at") +
                              moment(e.updatedAt).format("HH:mm")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("ArcPage.NoArc")}</h3>
            </div>
          )}
        </div>
        {modal && (
          <ArcInfo
            openModal={modal}
            setOpenModal={() => {
              setModal(false);
              setSelectedArc("");
            }}
            arcData={selectedArc}
            colorIndex={colorIndex}
          />
        )}
        {true && (
          <Modal
            closeModal={() => setModalAddConfirm(false)}
            showModal={modalAddConfirm}
            className="px-10 py-16"
          >
            <ConfirmAddDoctor
              openModal={modalAddConfirm}
              setOpenModal={setModalAddConfirm}
              setOpenModalAddDoctor={setModalAdd}
            />
          </Modal>
        )}
        <Modal
          closeModal={() => setModalAdd(false)}
          showModal={modalAdd}
          className="px-4 py-6"
        >
          <AddArc
            setOpenModal={setModalAdd}
            setShouldUpdate={setShouldUpdate}
          />
        </Modal>
      </div>
    </>
  );
};
export default MesArcs;
