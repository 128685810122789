import { Redirect, RouteProps } from "react-router";
import MyEproForms from "../pages/MyEPRO/EproForms";
//import Profile from "../pages/Profile";
import FirstLogin from "../pages/FirstLogin";
import DashboardPARTICIPANT from "../pages/DashboardPARTICIPANT";
import MyEPRO from "../pages/MyEPRO";
import WorkSpaceSelectionPage from "../pages/WorkSpaceSelection";
import EconsultTeleconsultation from "../pages/Econsult/SimplePeer";
import Econsult from "../pages/Econsult";
import { ParticipantEconsult } from "../pages/Econsult/ParticipantEconsultPage";

const participantRoutes: RouteProps[] = [
  {
    path: "/",
    component: DashboardPARTICIPANT,
    exact: true,
  },
  {
    path: "/eproForms/:studyId",
    component: MyEPRO,
    exact: true,
  },
  {
    path: "/eproForms/:studyId/:eproFormId",
    component: MyEproForms,
    exact: true,
  },

  // {
  //   path: "/profile",
  //   component: Profile,
  //   exact: true,
  // },
  {
    path: "/work-spaces",
    component: WorkSpaceSelectionPage,
    exact: true,
  },
  {
    path: "/eConsult/:studyId/:eConsultId/:participantId",
    component: EconsultTeleconsultation,
    exact: true,
  },
  {
    path: "/eConsult/:studyId",
    component: ParticipantEconsult,
    exact: true,
  },
  {
    path: "/firstLogin",
    component: FirstLogin,
    exact: true,
  },
];

participantRoutes.push({ render: () => <Redirect to="/" /> });

export default participantRoutes;
