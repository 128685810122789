import { ModalFooter } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ReactComponent as Survery } from "../../../assets/illustrations/survey.svg";
import Button from "../../ButtonUI";
import { BsFileEarmarkText } from "react-icons/bs";
// import arrayMove from 'array-move';

const SortableListSection = (props) => {
  const moveArrayItem = (array: any[], from: number, to: number) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
    return array;
  };

  const [items, setItems] = useState(props.list);
  const [payload, setPayload] = useState<any>();
  const [isSetup, setIsSetup] = useState(false);
  const { t } = useTranslation();
  const SortableItemx = SortableElement(({ value, index }) => (
    <div
      className={`relative cursor-move rounded-lg my-2  py-1 shadow-md hover:shadow-lg  w-full  justify-between bg-gray-50  z-50`}
      style={{ zIndex: 1500 }}
    >
      <div className="flex">
        <div
          className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
          style={{
            backgroundColor: "#4565f6",
          }}
        >
          <BsFileEarmarkText size={24} color="white" />
        </div>
        <div className="details">
          <div className="flex-grow ">
            <p className="card-title capitalize">{value?.sectionName}</p>
            <p className="card-subtitle">
              <span>
                <p className="box w-16 text-center text-xs mt-1">
                  {parseInt(value?.stepNbr) + 1}
                </p>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  ));

  const SortableContainerx = SortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let sortedArray = moveArrayItem(items, oldIndex, newIndex);
    let outputArray: any[] = [];

    setItems((items) => sortedArray);
    sortedArray.map((el, i) => {
      el.stepNbr = i;
      outputArray.push({ ...el });
    });

    setPayload(outputArray);
    setIsSetup((i) => !i);
  };

  return (
    <div>
      <div className="max-h-96 overflow-y-auto p-5  ">
        <SortableContainerx lockAxis="y" onSortEnd={onSortEnd}>
          {items.map((value, index) => (
            <SortableItemx key={`item-${value}`} index={index} value={value} />
          ))}
        </SortableContainerx>
      </div>

      <ModalFooter>
        <Button
          status="secondary"
          width="130px"
          height="50px"
          className=" float-right  py-2  mb-4 btn-delete "
          onClick={() => props.closeModal()}
        >
          {t("missingWording.cancel")}
        </Button>
        <Button
          status="primary"
          disabled={!payload}
          type="submit"
          width="130px"
          height="50px"
          className="ml-3 px-4 mb-4 py-2  float-left   "
          onClick={async () => {
            if (payload) {
              setPayload(null);
              props.editStepOrder(payload);
              props.closeModal();
            }
          }}
        >
          {t("missingWording.save")}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default SortableListSection;
