/* eslint-disable react-hooks/exhaustive-deps */
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getAllArchivedStudies, getAllStudies } from "../../api/cro";
import { downloadExcel } from "../../api/study";
import { Socket, io } from "socket.io-client";
import Button from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import Progress from "../../components/NProgress";
import makeToast from "../../components/Snackbar";
import RenderDivs from "../../components/StudyList";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { socketAtom } from "../../state/socket";
import { driverAtom } from "../../state/driverJs";
import { ActiveRouteAtom } from "../../state/route";
import { SelectedStudyAtom } from "../../state/selectedStudyAtom";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import AddStudy from "./AddStudy";
import AnonymazationModal from "./AddStudy/AnomysationModal";
import { tutorialModeAtom } from "../../state/tutorialMode";
// import useDrivejs from "../../hooks/driverjs";
import { BASE_URL } from "../../config/api";

const DashboardCro = () => {
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const { t } = useTranslation() as any;
  const menu = [t("SideBar.ActiveStudy"), t("SideBar.Archive")];
  const [user] = useAtom(userAtom);
  const [, setSelectedStudyBoolean] = useAtom(SelectedStudyAtom);
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [dateNow] = useState(moment());
  const [studiesList, setStudiesList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalAddConfirm, setModalAddConfirm] = useState(false);
  const [modalStartTuto, setModalStartTuto] = useState(false);
  const [step, setStep] = useState("0");
  const [createdStudy, setCreatedStudy] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [projectsListFiltred, setProjectsListFiltered] = useState<any>([]);
  const history = useHistory();
  // Pagintaion State
  const [page, setPage] = useState(1);
  const [, setTotalPage] = useState(1);
  const [update, setUpdate] = useState(false);
  // const [driverConfig, setDriverConfig] = useAtom(driverAtom);
  const [studyTutorial, setStudyTutorial] = useState("");
  // const [startTour, setStartTour] = useAtom<Boolean>(tutorialModeAtom) as any;
  // const { setStartTour } = useDrivejs({
  //   stepNumber: 0,
  //   setStudyTutorial: setStudyTutorial,
  // });

  const [totalResult, setTotalResult] = useState(0);
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [loading, setLoading] = useState(false);
  const typingTimeoutRef = useRef(null) as any;
  const isDevEnvironment = process.env.NODE_ENV === 'development';
  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);
  const _handleDownloadExcel = async (studyId, type) => {
    try {
      await downloadExcel(studyId, type);
      makeToast("success", t("DashboardCro.downloadEXCELSuccess"));
      history.push("/exports");
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        if (
          errorMessage === "Study already has a pending export being processed."
        ) {
          makeToast("warning", t("DashboardCro.downloadEXCELPending"));
          history.push("/exports");
        } else {
          makeToast("error", t("DashboardCro.downloadEXCELError"));
        }
        // TODO: Handle the specific error message as needed
      } else {
        console.log("Generic Error:", error);
        // TODO: Handle the generic error case
        makeToast("error", t("DashboardCro.downloadEXCELError"));
      }
    }
  };

  const fetchStudies = async (step: any) => {
    console.log("🚀 ~ file: index.tsx:72 ~ fetchStudies ~ step:", step);

    setLoading(true);
    const res =
      step === "1"
        ? await getAllArchivedStudies(page, 8, textSearch)
        : await getAllStudies(page, 8, textSearch);
    console.log("🚀 ~ file: index.tsx:74 ~ fetchStudies ~ res:", res);
    if (res && res?.studies) {
      setStudiesList(res?.studies);
      setTotalResult(res?.count);
      setTotalPage(Math.ceil(res.length / 10) - 1);
      setLoading(false);
    } else {
      setLoading(false);
    }
    return res;
  };

  useEffect(() => {
    setStudySelectedId(null);

    if (user?.tenantId === "cro" && user?.type === "cro")
      history.push("/add/organisation");
    else {
      fetchStudies(step);
    }
  }, [update, page, step, textSearch]);
  useEffect(() => {
    localStorage.setItem("step", "0");
    setPath("/");
  }, []);
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };

  const updateFirstLogin = (start) => {
    if (socket) {
      socket.emit("updateFirstLogin", { skip: !start });
      // if (start) socket.emit("tutoStart", {});
    }
  };

  // useEffect(() => {
  //   if (!socket) {
  //     const mySocket: Socket = io(BASE_URL, {
  //       // forceNew: true,
  //       reconnectionAttempts: 100,
  //       timeout: 10000,
  //       transports: ["websocket"],
  //       auth: {
  //         token: localStorage.getItem("token"),
  //       },
  //     });

  //     setSocket(mySocket);
  //   } else {
  //     if (!socket.connected) socket.connect();
  //     socket.emit("checkFirstLogin", {});
  //     socket.on("updateStartTour", () => {
  //       socket.emit("tutoStart", {})
  //     })
  //     socket.on("checkFirstLoginResponse", (res) => {
  //       console.log("🚀 ~ file: index.tsx:178 ~ socket.on ~ res:", res);
  //       if (res.firstVist) {
  //         setModalStartTuto(true);
  //       }
  //     });

  //     return () => {
  //       if (socket) {
  //         socket.disconnect();
  //         socket.removeAllListeners("");
  //         socket.close();
  //         console.log("[DEBUG] Socket was closed!");
  //       }
  //     };
  //   }
  // }, [socket]);

  return (
    <>
      <Progress isAnimating={loading} key={0} />
      <Modal
        showModal={showAddModal}
        closeModal={() => setShowAddModal(false)}
        className="px-16 py-16 studyAdingModal"
      >
        <AddStudy
          setOpenModal={setShowAddModal}
          action={(id: string) => {
            setModalAddConfirm(true);
            setCreatedStudy(id);
            setUpdate(true);
          }}
          studyTutorial={studyTutorial}
        />
      </Modal>

      <Modal
        showModal={modalStartTuto}
        closeModal={() => setModalStartTuto(false)}
        className="px-16 py-10 studyAdingModal"
      >
        <p className="text-center font-semibold mb-5">
          {t("DashboardCro.bienvenuTuto")}
        </p>

        <div className=" w-full flex gap-16 justify-center items-center p-4 ">
          <Button
            status="secondary"
            width="200px"
            height="50px"
            className="px-6 py-2"
            onClick={() => {
              setModalStartTuto(false);
              updateFirstLogin(false);
            }}
          >
            {t("DashboardCro.Cancel")}
          </Button>
          <Button
            width="200px"
            height="50px"
            className=" px-6 py-2"
            onClick={() => {
              updateFirstLogin(true);
              setModalStartTuto(false);
            }}
          >
            {t("DashboardCro.start")}
          </Button>
        </div>
      </Modal>
      <div className="dashboard-doctor w-full">
        <header className="flex justify-between  pl-4   w-full ">
          <div className="mb-4 lg:mb-auto relative">
            <h1>{t("DashboardCro.title")}</h1>{" "}
            <h3 className="card-subtitle">
              {t("DashboardCro.WeAreOn")}{" "}
              {dateNow.format("DD-MM-YYYY") +
                t("DashboardCro.at") +
                dateNow.format("HH:mm")}
            </h3>
            {/* <div className="mt-1 flex flex-col  gap-x-2  left-0">
              <Input
                type={"text"}
                className="rounded-lg min-w-max max-h-3"
                labelText={`🔎 ${t("SideBar.FilterStudy")}`}
                name="filtrer"
                onChange={onSearch}
                style={{ width: "300px" }}
              />
            </div> */}
            <div className="relative max-w-xs">
              <input
                type="text"
                name="hs-table-search"
                id="hs-table-search"
                onChange={onSearch}
                value={searchValue}
                className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
                placeholder={t("SideBar.FilterStudy")}
                style={{ border: "none" }}
                onKeyDown={handleKeyDown}
              />

              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <svg
                  className="h-3.5 w-3.5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
            </div>
          </div>
          {user?.type === "cro" && (
            <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 ">
              <Button
                status="secondary"
                onClick={() => setShowAddModal(true)}
                className="md:w-auto md:text-base  sm:px-4  xs:w-16 xs:text-xs xs:truncate xs:px-0 addStudyButton"
                height="50px"
              >
                {t("DashboardCro.NewStudy")}
              </Button>
            </div>
          )}
        </header>
        <main></main>
      </div>

      <div className="flex mt-16 relative ">
        <div className="menu">
          {menu.map((m, idx) => {
            return (
              <div
                onClick={() => {
                  setStep(idx.toString());
                  setSearchValue("");
                }}
                className="cursor-pointer"
              >
                <p
                  className={`${
                    step === idx.toString() ? "selected" : "unselected"
                  }`}
                >
                  <span className="capitalize">{m}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {step === "0" && (
          <>
            {step === "0" && loading ? (
              <p className="flexCenter mt-16 menuInput">
                {t("missingWording.Loading")}...
              </p>
            ) : (
              <div className="mt-8">
                {studiesList && studiesList.length > 0 ? (
                  <RenderDivs
                    datahlist={studiesList}
                    setStudySelectedId={setStudySelectedId}
                    setSelectedStudyBoolean={setSelectedStudyBoolean}
                    setPage={setPage}
                    totalResult={totalResult}
                    page={page}
                    handleDownloadExcel={_handleDownloadExcel}
                    setStep={setStep}
                    setUpdate={setUpdate}
                  />
                ) : (
                  <p className="flexCenter mt-16">
                    {t("DashboardCro.noActiveStudy")}
                  </p>
                )}

                {/* {true && (
                  <ConfirmModal
                    openModal={modalAddConfirm}
                    setOpenModal={setModalAddConfirm}
                    studyId={createdStudy}
                  />
                )} */}
                {modalAddConfirm && (
                  <AnonymazationModal
                    openModal={modalAddConfirm}
                    setOpenModal={setModalAddConfirm}
                    studyId={createdStudy}
                  />
                )}
              </div>
            )}
          </>
        )}
        {step === "1" && (
          <div>
            <>
              {step === "1" && loading ? (
                <p className="flexCenter mt-16 menuInput">
                  {t("missingWording.Loading")}...
                </p>
              ) : (
                <div className="mt-8">
                  {studiesList && studiesList.length > 0 ? (
                    <RenderDivs
                      isDisabled={true}
                      datahlist={studiesList}
                      setStudySelectedId={setStudySelectedId}
                      setSelectedStudyBoolean={setSelectedStudyBoolean}
                      setPage={setPage}
                      totalResult={totalResult}
                      page={page}
                      handleDownloadExcel={_handleDownloadExcel}
                      setStep={setStep}
                    />
                  ) : (
                    <p className="flexCenter mt-16">
                      {t("DashboardCro.noArchiveStudy")}
                    </p>
                  )}
                </div>
              )}
            </>
          </div>
        )}
        {isDevEnvironment && 
        <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 fixed bottom-0 left-0 z-50">
          <Button
            status="primary"
            // onClick={() => socket.emit("resetChallenge1", {})}
            className="text-xs px-2 py-1"
          >
            Start Tour
          </Button>
          <Button
            status="primary"
            onClick={() => {
              // socket.emit("IniTuto", {}) 
              window.location.reload()}}
            className="text-xs px-2 py-1"
          >
            Init
          </Button>
        </div>}
      </div>
    </>
  );
};

export default DashboardCro;
