import {
  isEmail,
  isInRangeNumber,
  isLength,
  isMaxLength,
  isMinLength,
  isNotEmptyString,
  isNumber,
  isPattern,
  isPercentage,
  isRequired,
  onlyFutureDate,
  dateRange,
  onlyPastDate,
  minInfuturYears,
  maxInfuturYears,
  maxInPastYears,
  minInPastYears,
  isNotEmptyArray,
  isRegExp,
} from "../../hooks/FormGeneratorHooks/validitions";
import { default as enMsg } from "../../config/constants/locales/en-us.json";
import { default as frMsg } from "../../config/constants/locales/fr-fr.json";
const isMobile = (breakPoints) => {
  return breakPoints.xs || (breakPoints.sm && !breakPoints.md);
};
const checkIfMultiple = (currentCondition: Partial<any>): boolean => {
  const multipleInput: string[] = [
    "Checkboxes",
    "RadioButtons",
    "Tags",
    "Dropdown",
  ];

  const type =
    currentCondition?.target?.type !== undefined
      ? currentCondition?.target?.type
      : "text_area";
  if (multipleInput.includes(type)) {
    return true;
  } else return false;
};
const validitionRules = (rulesTabs,dateFormat=null,isCheckBox=false) => {
 
  try {
    let result: any[] = [];
    if (rulesTabs) {
      rulesTabs.map((rule: any) => {
        switch (Object.entries(rule)[1][0]) {
          case "required":
            if (rule.required)
              result.push({
                rule: isCheckBox ? isRequired() : isRequired(),
                message: Object.entries(rule)[0][1],
              });
            break;
          case "isPattern":
          
            result.push({
              rule: isPattern(rule.valuePat),
              message: Object.entries(rule)[0][1],
            });
            break;
            
          case "isLength":
            result.push({
              rule: isLength(rule.Strlength),
              message: Object.entries(rule)[0][1],
            });
            break;
            case "isRegExp":
            
            result.push({
              rule: isRegExp(rule.valuePat),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "isMinLength":
            result.push({
              rule: isMinLength(rule.minLength),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMaxLength":
            result.push({
              rule: isMaxLength(rule.maxLength),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMinNumber":
            result.push({
              rule: isInRangeNumber(rule.inf, 999999999999999999999999),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isMaxNumber":
            result.push({
              rule: isInRangeNumber(0, rule.sup),
              message: Object.entries(rule)[0][1],
            });

            break;

          case "isNotEmptyString":
            result.push({
              rule: isNotEmptyString(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isNumber":
            result.push({
              rule: isNumber(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isEmail":
            result.push({
              rule: isEmail(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "isPercentage":
            result.push({
              rule: isPercentage(),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "onlyFutureDate":
            result.push({
              rule: onlyFutureDate(),
              message: Object.entries(rule)[0][1],
            });
            break;

          case "onlyPastDate":
            result.push({
              rule: onlyPastDate(),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "dateRange":

          
            
            result.push({
              rule: dateRange(rule.minDate, rule.maxDate,dateFormat),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "minInfuturYears":
       
            result.push({
              rule: minInfuturYears(rule.minYearsFutur),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "maxInfuturYears":

            result.push({
              rule: maxInfuturYears(rule.maxYearsFutur),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "maxInPastYears":

            result.push({
              rule: maxInPastYears(rule.maxYearsPast),
              message: Object.entries(rule)[0][1],
            });
            break;
          case "minInPastYears":

            result.push({
              rule: minInPastYears(rule.minYearsPast),
              message: Object.entries(rule)[0][1],
            });
            break;

          default:
            break;
        }
      });
    }
    return result;
  } catch (error) {
    console.error(error);
  }
};
const loadLocaleData=()=> {
  let locale =  localStorage.getItem("i18nextLng")
 let  msg = locale==="fr" ?   frMsg:enMsg
  switch (locale) {
    case "fr":
      return  msg;
    default:
      return  msg;
  }
}



const formatingRulesLocal =  (rule: any) => {
  let msgs :any =loadLocaleData()
switch (rule.rule) {
    
  case "isPattern":
    let m =  `${msgs.isPattern}  ${rule.parms.valuePat}`
    return {
      message: `${m}`,
      isPattern: true,
      valuePat: rule.parms.valuePat,
    };
    case "isRegExp":
      let msg =  `isRegExp  ${rule.parms.valuePat}`
      return {
        message: `${msg}`,
        isRegExp: true,
        valuePat: rule.parms.valuePat,
      };
  case "isLength":
    return {
      message: `${msgs.isLength} ${rule.parms.islength} ${msgs.carcs}`,
      isLength: true,
      Strlength: rule.parms.islength,
    };
  case "isMinLength":
    return {
      message: ` ${msgs.isMinLength} ${rule.parms.minLength}  ${msgs.carcs}`,
      isMinLength: true,
      minLength: rule.parms.minLength,
    };
  case "isMaxLength":
    return {
      message: `${msgs.isMaxLength} ${rule.parms.maxLength}  ${msgs.carcs}`,
      isMaxLength: true,
      maxLength: rule.parms.maxLength,
    };
  case "isMinNumber":
    return {
      message: `${msgs.isMinNumber} ${rule.parms.inf} `,
      isMinNumber: true,
      inf: rule.parms.inf,
    };
  case "isMaxNumber":
    return {
      message: `${msgs.isMaxNumber} ${rule.parms.sup} `,
      isMaxNumber: true,
      sup: rule.parms.sup,
    };
  case "isNotEmptyString":
    return { message: `${msgs.isNotEmptyString} `, isNotEmptyString: true };
  case "isNumber":
    return {
      message: `${msgs.isNumber} `,
      isNumber: true,
    };
  case "isEmail":
    return {
      message: `${msgs.isEmail} `,
      isEmail: true,
    };
  case "isPercentage":
    return {
      message: `${msgs.isPercentage}`,
      isPercentage: true,
    };
  case "onlyFutureDate":
    return {
      message: `${msgs.onlyFutureDate}`,
      onlyFutureDate: true,
    };
  case "onlyPastDate":
    return {
      message: `${msgs.onlyPastDate}`,
      onlyPastDate: true,
    };
  case "dateRange":
    return {
      message: `${msgs.dateRange} ${rule.parms.minDate} et ${rule.parms.maxDate}.  `,
      dateRange: true,
      minDate: rule.parms.minDate,
      maxDate: rule.parms.maxDate,
    };
  case "minInfuturYears":
    return {
      message: `${msgs.minInfuturYears}  ${rule.parms.minYearsFutur} ${msgs.yearsFuture}   `,
      minInfuturYears: true,
      minYearsFutur: rule.parms.minYearsFutur,
    };
  case "minInPastYears":
    return {
      message: `${msgs.minInPastYears}  ${rule.parms.minYearsPast}  ${msgs.yearsPast} `,
      minInPastYears: true,
      minYearsPast: rule.parms.minYearsPast,
    };
  case "maxInfuturYears":
    return {
      message: `${msgs.maxInfuturYears} ${rule.parms.maxYearsFutur}  ${msgs.yearsFuture}`,
      maxInfuturYears: true,
      maxYearsFutur: rule.parms.maxYearsFutur,
    };
  case "maxInPastYears":
    return {
      message: `${msgs.maxInPastYears} ${rule.parms.maxYearsPast}  ${msgs.yearsPast}`,
      maxInPastYears: true,
      maxYearsPast: rule.parms.maxYearsPast,
    };
  default:
    break;
}

  
};
const rules = (type) => {
  let msgs :any =loadLocaleData()

  let textRules = [
    { value: "isPattern", label: `${ msgs.isPatternLabel}` },
    { value: "isNumber", label: `${msgs.isNumberLabel}` },
    {
      value: "isLength",
      label: `${msgs.isLengthLabel}`,
    },
    { value: "isMinLength", label: `${msgs.isMinLengthLabel}`},
    { value: "isMaxLength", label: `${msgs.isMaxLengthLabel}` },
    { value: "isEmail", label:`${msgs.isEmailLabel}` },
    { value: "isRegExp", label:`RegExp` },
  ];
  let numberRules = [
    { value: "isMinNumber", label:`${msgs.isMinNumberLabel}` },
    { value: "isMaxNumber", label: `${msgs.isMaxNumberLabel}` },
    { value: "isPercentage", label:`${msgs.isPercentageLabel}` },
  ];
  let dateRules = [
    { value: "onlyFutureDate", label: `${msgs.onlyFutureDateLabel}` },
    { value: "onlyPastDate", label: `${msgs.onlyPastDateLabel}` },
    { value: "dateRange", label: `${msgs.dateRangeLabel}` },
    { value: "minInfuturYears", label: `${msgs.minInfuturYearsLabel}` },
    { value: "minInPastYears", label: `${msgs.minInPastYearsLabel}` },
    { value: "maxInfuturYears", label: `${msgs.maxInfuturYearsLabel}` },
    { value: "maxInPastYears", label: `${msgs.maxInPastYearsLabel}` },
  ];

  switch (type) {
    case "TextInput":
    case "TextArea":
      return  textRules;

    case "NumberInput":
      return  numberRules;
    case "DatePicker":
      return  dateRules;
    default:
      return  textRules;
  }
};

const formatingRules = (rule: any) => {
  switch (rule.rule) {
    case "isPattern":
      return {
        message: `Ce champ doit contenir le mot ${rule.parms.valuePat}`,
        isPattern: true,
        valuePat: rule.parms.valuePat,
      };
      case "isRegExp":
        return {
          message: `Ce champ doit contenir le mot ${rule.parms.valuePat}`,
          isRegExp: true,
          valuePat: rule.parms.valuePat,
        };
    case "isLength":
      return {
        message: `Ce champ doit contenir ${rule.parms.islength} caractéres`,
        isLength: true,
        Strlength: rule.parms.islength,
      };
    case "isMinLength":
      return {
        message: `Ce champ doit contenir au moins ${rule.parms.minLength} caractéres`,
        isMinLength: true,
        minLength: rule.parms.minLength,
      };
    case "isMaxLength":
      return {
        message: `Ce champ doit contenir au plus ${rule.parms.maxLength} caractéres`,
        isMaxLength: true,
        maxLength: rule.parms.maxLength,
      };
    case "isMinNumber":
      return {
        message: `Ce champ doit être supérieur à ${rule.parms.inf} `,
        isMinNumber: true,
        inf: rule.parms.inf,
      };
    case "isMaxNumber":
      return {
        message: `Ce champ doit être inférieur à ${rule.parms.sup} `,
        isMaxNumber: true,
        sup: rule.parms.sup,
      };
    case "isNotEmptyString":
      return { message: `Chaine vide ! `, isNotEmptyString: true };
    case "isNumber":
      return {
        message: `Ce champ doit contenir uniquement des chiffres `,
        isNumber: true,
      };
    case "isEmail":
      return {
        message: `Email invalide ! `,
        isEmail: true,
      };
    case "isPercentage":
      return {
        message: `Ce champ doit contenir que des pourcentages`,
        isPercentage: true,
      };
    case "onlyFutureDate":
      return {
        message: `la date doit être ultérieure.`,
        onlyFutureDate: true,
      };
    case "onlyPastDate":
      return {
        message: `La date doit être dans le passé `,
        onlyPastDate: true,
      };
    case "dateRange":
      return {
        message: `La date doit être comprise entre ${rule.parms.minDate} et ${rule.parms.maxDate}.  `,
        dateRange: true,
        minDate: rule.parms.minDate,
        maxDate: rule.parms.maxDate,
      };
    case "minInfuturYears":
      return {
        message: `La date doit se situer plus de  ${rule.parms.minYearsFutur} ans dans le future. `,
        minInfuturYears: true,
        minYearsFutur: rule.parms.minYearsFutur,
      };
    case "minInPastYears":
      return {
        message: `La date doit se situer plus de  ${rule.parms.minYearsPast} ans dans le passé. `,
        minInPastYears: true,
        minYearsPast: rule.parms.minYearsPast,
      };
    case "maxInfuturYears":
      return {
        message: `La date doit être inférieure à ${rule.parms.maxYearsFutur} ans dans le future.`,
        maxInfuturYears: true,
        maxYearsFutur: rule.parms.maxYearsFutur,
      };
    case "maxInPastYears":
      return {
        message:`La date doit être inférieure à ${rule.parms.maxYearsPast} ans dans le passé.`,
        maxInPastYears: true,
        maxYearsPast: rule.parms.maxYearsPast,
      };
    default:
      break;
  }
};
const convertedChoices = (choices)=>{
  
  return choices.map(choice => ({ label: choice, value: choice }))
}
function transformObjectToArray(obj) {
  const arr :any[]= [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      arr.push({ label: key, value: value });
    }
  }
  return arr;
}
function transformArrayToObject(arr) {
  const resultObject = {};

  arr.forEach((item) => {
    const fieldName = item.field_name;
    const fieldValue = item.value;
    resultObject[fieldName] = fieldValue;
  });
  
  
  return resultObject;
}
const extractFields = (arr) => {
  return arr.reduce((result, obj) => {
    obj.fields.forEach(field => {
      result[field.field_name] = field.value;
    });
    return result;
  }, {});
};

 const updateErrorMessage = (errorMessages, indexToUpdate, newMessage) => {
  if (indexToUpdate < 0 || indexToUpdate >= errorMessages.length) {
    // Handle invalid index
    return errorMessages;
  }

  // Create a new array with the updated message
  const updatedErrorMessages = [...errorMessages];
  updatedErrorMessages[indexToUpdate] = {
    ...updatedErrorMessages[indexToUpdate],
    message: newMessage,
  };

  return updatedErrorMessages;
};
export {
  isMobile,
  checkIfMultiple,
  validitionRules,
  loadLocaleData,
  formatingRules,
  formatingRulesLocal,
  rules,
  convertedChoices,
  transformObjectToArray,
  transformArrayToObject,
  extractFields,updateErrorMessage
};
