import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from "react";
import classNames from "classnames";

import "./styles.scss";
import Icon from "../Icon";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  labelText?: string;
  inlineLabel?: boolean;
  onfocus?: boolean;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  stylesDiv?: string;
  icon?: string;
  iconColor?: string;
  image?: string;
  image2?: string;
  imageCLS?: string;
  isIcon?: boolean;
  right?: boolean;
};

const Input: React.FC<Props> = ({
  error,
  labelText,
  labelProps,
  inlineLabel,
  stylesDiv,
  icon,
  iconColor,
  image,
  image2,
  isIcon,
  right,
  imageCLS,
  ...props
}) => {
  const { className: labelClassName, ...restOfLabelProps } = labelProps!;
  const { className: inputClassName, ...inputProps } = props;

  switch (props.type) {
    case "FullBackgroundCheckbox":
      return (
        <label className="container-checkbox">
          {labelText && labelText}
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
      );
    case "switcher":
      return (
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round"></span>
        </label>
      );
    default:
      return (
        <div
          className={
            props.type === "radio" || props.type === "checkbox"
              ? `flex flex-row-reverse items-center justify-center max-w-max ${stylesDiv}`
              : icon
              ? `iconClass ${stylesDiv}`
              : image && !right
              ? `imgClass ${stylesDiv}`
              : `relative ${stylesDiv}`
          }
        >
          {/* {error ? (
            <p className=" h-6 items-center text-red-500 text-right">
              {error || ""}
            </p>
          ) : null} */}
          {labelText ? (
            <label
              className={classNames(
                `flex items-center`,
                props.type === "radio" && "disabled:text-gray-300",
                labelClassName,
                error ? "text-blue-500" : null
              )}
              htmlFor={inputProps.id}
              {...restOfLabelProps}
            >
              {labelText}{props.required ? <p className="text-red-400 mx-1">*</p> : ""}
            </label>
          ) : null}
          {icon &&
            (isIcon ? (
              <Icon
                name={icon}
                isIcon={true}
                color={iconColor}
                width={15}
                height={15}
              />
            ) : (
              <Icon
                name={icon}
                isIcon={false}
                color={iconColor}
                width={15}
                height={15}
              />
            ))}
          {image ? (
            <div className={`${imageCLS} h-7 ${right ? "imgClassRight" : ""}`}>
              <img
                src={require(`../../assets/images/${image}.png`).default}
                alt="katomi"
              />
            </div>
          ) : null}
          {image2 ? (
            <div className={`leftImage`}>
              <img
                src={require(`../../assets/images/${image2}.png`).default}
                alt="katomi"
              />
            </div>
          ) : null}
          <input
            className={classNames(
              `flex-grow ${
                props.type === "checkbox"
                  ? "flexCenter checkbox"
                  : props.type === "radio"
                  ? "h-5 w-5 flexCenter radio"
                  : "h-12 px-4"
              } ${props.type === "file" ? "" : "border border-gray-200"}`,
              inputClassName,
              error ? "text-blue-500" : null
            )}
            // style={{ border: error && "2px solid red" }}
            {...inputProps}
          />
        </div>
      );
  }
};

Input.defaultProps = {
  labelProps: {},
};

export default Input;
