export const missingWording = {
  fr: {
    loadingParticipants: "La création des participants est en cours...",
    loadingMsg:
      "Ceci peut prendre un certain temps. Nous vous demandons d'être patient. Ne quittez pas la page.",
    submitForm: "Formulaire soumis avec succès",
    anonymDef:
      "Le nom et le prénom du participant ne seront pas enregistrés dans la base de données.",
    randomDef:
      "La randomisation vous permet de classer aléatoirement les participants dans des groupes.",
    acessParticipantDef:
      "Si désactivé, l'investigateur ne peut pas consulter et modifier que les participants qu'il aura lui-même inclus",
    eProDef: "Permet aux participants de remplir des formulaires",
    newEproForm: "Nouveau formulaire ePRO",
    nFirstName: "Prénom",
    nLastName: "Nom",
    formEpro: "Formulaires ePRO",
    noRandoModif:
      "L'étude a commencé et des participants ont déjà été ajoutés. Vous ne pouvez pas modifier ce paramètre.",
    extractData: "Extraction de données",
    extractDataDescrp:
      "Veuillez sélectionner le type de téléchargement que vous souhaitez demander",
    noAssocitedForms: "Aucun formulaire disponible",
    addAssocitedForm: "+ Ajouter un formulaire",
    notAffected: "non affecté",
    sucessRandomConfig: "Votre configuration a été sauvegardée avec succès ",
    name: "nom",
    nbrReafction: "Nombre de réaffectations",
    inpXA: "Sélectionner un groupe associé",
    inpXAn: "Tous les groupes",
    inpXALabel: "Groupe associé",
    noRando: "Pas de randomisation pour cette étude",
    onCreation: "à la création",
    onCreationEpro: "Aucune dépendance",
    switchForms: "Formulaires de réaffectation",
    studyResp: "Responsable d'étude",
    accessConfig: "Accès aux participants ouvert par centre",
    eProConfig: "ePRO",
    anonymizationConfig: "Anonymiser l'étude",
    noAnonymizationConfig: "Ne pas anonymiser l'étude",
    open: "Ouvert",
    sharedCalculVars: "Utiliser comme variable de calcul",
    connected: "Connecté ",
    disconnected: "Disconnected",
    nbrOfparticipant: "Nombre de participants",
    noStats: "Aucune donnée statistique disponible",
    lostWebSocketConnction:
      "Connexion perdue avec le serveur. Veuillez patienter...",
    closed: "Terminée",
    systQuery: "Requête du système",
    arcQuery: "Requête ARC",
    auto: "Auto",
    query: "Requête",
    system: "Système",
    statut: "Statut",
    acheved: "Achevé",
    orderPage: "Editer l'ordre des pages",
    orderVisit: "Editer l'ordre des visites",
    pageName: "Nom de la page",
    condLogic: "Logique conditionnelle",
    fieldToComp: "Champ à comparer",
    value: "Valeur",
    addCondition: "Ajouter une Condition",
    toastSurvey: "Ce module est indisponible sur la version mobile.",
    foundWord: "Mot recherché",
    dateFormat: "DD/MM/YYYY",
    dateStart: "Date de début",
    dateEnd: "Date de fin",
    nbrCarcs: "Nombre de caractères",
    nbrCarcsMin: "Nombre de caractères minimum",
    nbrCarcsMax: " Nombre de caractères maximum",
    min: "Minimum",
    max: "Maximum",
    minFuture: "Minimum d'anneés en future",
    minPast: "Minimum d'anneés dans le passé",
    maxFuture: "Maximum d'anneés en future",
    maxPast: "Maximum d'anneés dans le passé",

    editProp: "Éditer les propriétés",
    unit: "Unité de mesure",
    mathExp: "Experssion Mathématique",
    unitField: "Champ avec unité de mesure",
    unit0: "Unité",
    prefix0: "Préfixe",
    prefix: "Préfixe téléphonique",
    prefixField: "N° de téléphone avec préfixe",
    choixs: "Choix",
    Grp: "Groupe",
    grp: "Liste des groupes",
    addGrp: "+ Ajouter un groupe",
    groupListTitle: "Gestion des groupes",
    choix: "Choix ",
    addChoice: "Ajouter un choix",
    cancel: "Annuler",
    duplicate: "Dupliquer",
    saveAsTemplate: "Enregistrer en tant que modèle",
    traduction: "Traduire",
    loadFail: "Chargement de la liste des utilisateurs à échoué",
    MAJle: "Dernière mise à jour le ",
    successTranslation: "Nouvelle traduction créée.",
    add: "Ajouter",
    copy: "copie",
    template: "modèle",
    validate: "Valider",
    yes: "Oui",
    no: "Non",
    investigator: "Investigateur",
    user: "Utilisateur",
    admin: "Administrateur",
    le: "envoyé le ",
    a: "à ",
    tunisia: "Tunisie",
    france: "France",
    visit: "Visite",
    card1: "participants inclus",
    subCard1: "Dernier participant ajouté le",
    card2: "Saisies finalisées",
    card2single: "Saisie finalisée",
    card3: "Saisies en cours",
    card3single: "Saisie en cours",
    card4: "DERNIÈRES MISES À JOUR",
    timeCard: "à",
    subCard4: "modifié le :",
    updateEmpty: "Liste des dernières mises à jour est vide.",
    card5: "QUERIES EN ATTENTE",
    subCard5: "Liste des queries en attente est vide.",
    card6: "Queries clôturées",
    card7: "Partipants exclus de l'étude",
    card8: "Investigateurs inactifs",
    card9: "Nombre de formulaires actifs",
    emptyStats: " Aucune valeur statistique n'a été configurée",
    nbreOfParticipant: "Nombre des participants",
    Unassigned: "Non renseigné",
    dest: "Distribution des",
    statVarFirst: "Variable 1",
    statVarSecond: "Variable 2",
    participant2: "des participants",
    newCenter: "Nouveau Centre",
    listOfCenter: "Liste des centres",
    listOfProfil: "Liste des profils",
    listOfUser: "Liste des utilisateurs",
    formVisits: "Formulaires de visite ",
    formevents: "Événements récurrents ",
    newForm: "Nouvel événement",
    all: "Tous ",
    back: "Retour",
    events: "Événements",
    eproForms: "Formulaires ePRO",
    event: "Événement",
    adverseevents: "Événement indésirable",
    phoneCallEvent: "Appel téléphonique",
    noEvents: "Vous n'avez aucun événement",
    loading: "chargement",
    Loading: "Chargement",
    noEventsPart: "Aucun événement",
    noeproPart: "Aucun formulaire ePRO",
    role: "Profil chargé ",
    editPageOrder: "Editer l'ordre  ",
    orderSucess: "L'ordre des visites est mis à jour avec succès",
    orderSectionSucess: "L'ordre des pages est mis à jour avec succès",
    editVisitToolTip: "Editer",
    deleteVisitToolTip: "Supprimer",
    displayField: "Consulter le champ sur le formulaire du participant",
    pagination1: "Affichage de ",
    pagination2: "à ",
    pagination3: "sur ",
    paginationS: "Études",
    paginationQ: "Requêtes",

    //creationStep
    t1: "Veuillez sélectionner le profil chargé de la visite",
    t2: "Veuillez saisir le nom de la visite",
    t3: "La création du formulaire a été effectuée avec succès",
    p1: " Créer votre formulaire",
    inp1: "Nom du formulaire",
    inp2: "Description du formulaire",
    inp3: "Sélectionnez le profil chargé de la visite",
    inp5: "Sélectionner un formulaire ",
    inp55: "Sélectionner un modèle de formulaire",
    inp3n: "Veuillez créer des profils",
    inp4: "Dépendance de la visite",
    inp4n: "Pas de visites",
    inp4Epro: "Dépendance du formulaire",
    createButton: "Créer",
    //PageHeader
    pht1: "La validation de cette visite a été effectuée avec succès.",
    pht2: "Une erreur s'est produite !",
    pht3: "L'annulation de la validation de cette visite a été effectuée avec succès.",
    phtSpan: "Visite validée le ",
    phtSpanSgin: "Visite signée le ",
    phtOp1: "Valider la visite",
    phtOp2: "Signer la visite",
    phtOp3: "Bloquer la visite",
    phtOp4: "Débloquer la visite",
    asTemplate: "en tant que modèle",
    phtOp101: "Télécharger toutes les données de l'étude ",
    phtOp202: "Archiver l'étude ",
    phtOp303: "Terminer l'étude ",
    phtSpan2: "Voulez-vous valider la visite suivante",
    sign2: "Voulez-vous signer la visite suivante",
    sign: "Signer",
    sign1: "La signature de la visite a été effectuée avec succès",
    blockMsg: "Visite bloquée avec succès",
    deblockMsg:"Visite débloquée avec succès",
    blockMsg1: "Voulez-vous bloquer la visite suivante",
    blockMsg2: "Voulez-vous  débloquer la visite suivante",
    phtSpan3:
      "NB: Cette visite contient des champs qui ne sont pas encore valides. Êtes-vous sûr de vouloir la valider?",
    phtLabel1: "Raison de validation",
    phtSpan4: "Voulez-vous annuler la validition de la visite suivante",
    phtSpan5:
      "NB : Cette visite est validée par l'investigateur. Etes-vous sûr de vouloir annuler la validation ?",
    //FormRender
    frt1: "La création de la requête s'est effectuée avec succès",
    frt2: "La validation de ce champ a été effectuée avec succès",
    frt3: "Le champ a été bloqué avec succès",
    frt4: "Le champ a été débloqué avec succès",
    frp1: "Généré par le système",
    frp2: "Changer le status",
    frt5: "La requête est terminé avec succès",
    frt6: "Votre message a été envoyé avec succès ",
    //DashboardWriter
    noArchive: "Aucune étude archivée",
    //DragableInterface
    dit1: "Vos changements sont sauvegardés",
    dit2: "Synthèse du questionnaire",
    //FormBuilder
    submitLabel: "Terminer",
    submitLabel2: "Valider",
    edit: "Modifier",
    next: "Suivant",
    //FirstLogin
    flh1: "Changer votre mot de passe temporaire",
    flt1: "Mot de passe  modifié avec succès !",
    flt2: "Confirmation de mot de passe est obligatoire ! ",
    flt3: "Mot de passe est obligatoire !",
    flt4: "Les deux mots de passe doivent être identiques !",
    flt5: "Le changement de votre mot de passe est obligatoire.",
    flt6: "Minimum 8 caractères .",
    flt7: "Votre mot de passe doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.",
    flt8: "Confirmation de mot de passe est obligatoire .",
    flt9: "Mot de passe doit étre identique !",
    fli1: "Mot de passe",
    fli2: "Confirmation de mot de passe",
    flb1: "Se déconnecter",
    flb2: "Confirmer",
    //Utlis
    isPattern: "Ce champ doit contenir le mot",
    isLength: "Ce champ doit contenir",
    carcs: "caractéres",
    isMinLength: "Ce champ doit contenir au moins",
    isMaxLength: "Ce champ doit contenir au plus",
    isMinNumber: "Ce champ doit être supérieur à",
    isMaxNumber: "Ce champ doit être inférieur à",
    isNotEmptyString: "Chaine vide ! ",
    isNumber: "Ce champ doit contenir uniquement des chiffres",
    isEmail: "Email invalide !",
    isPercentage: "Ce champ doit contenir que des pourcentages",
    onlyFutureDate: "la date doit être ultérieure.",
    onlyPastDate: "a date doit être dans le passé",
    dateRange: "La date doit être comprise entre",
    minInfuturYears: "La date doit se situer plus de ",
    minInPastYears: "La date doit se situer plus de ",
    maxInfuturYears: "La date doit être inférieure à ",
    maxInPastYears: "La date doit être inférieure à ",
    isPatternLabel: "Doit contenir un mot précis",
    isLengthLabel: "Doit contenir un nombre de caractères définis",
    isMinLengthLabel: "Doit contenir un minimum de caractères",
    isMaxLengthLabel: "Doit contenir un maximum de caractères",
    isMinNumberLabel: "Doit être supérieur à",
    isMaxNumberLabel: "Doit être inférieur à",
    isNotEmptyStringLabel: "",
    isNumberLabel: "Doit contenir que de chiffres",
    isEmailLabel: "Doit être sous la forme d'un email",
    isPercentageLabel: "Pourcentage",
    onlyFutureDateLabel: "La date doit être ultérieure.",
    onlyPastDateLabel: "La date doit être au passé.",
    dateRangeLabel: "Intervalle de dates",
    minInfuturYearsLabel: "Minimum d'anneés en future",
    minInPastYearsLabel: "Minimum d'anneés dans le passé",
    maxInfuturYearsLabel: "Maximum d'anneés en future",
    maxInPastYearsLabel: "Maximum d'anneés dans le passé",
    sessionStatus: "Session expirée",
    //RulesValidation
    errorMsg: "Message d'erreur",
    fieldParms: "Paramètre du champ",
    required: "Champ obligatoire",
    activer: "Activer",
    desactive: "Désactiver",
    exclusion: "Exclusion (empêche la saisie de données ultérieures)",
    advParms: "Paramètres avancés",
    textSelct: "Veuillez sélectionner un paramètre",
    save: "Enregistrer",
    parmsToolTip: "Ajouter autres paramètres de validations",
    ///
    visitInfos: "Informations de la visite",
    visitName: "Nom de la visite",
    templateName: "Nom du modèle",
    eventName: "Nom de l'événement",
    newEvent: "Créer votre événement",
    dupVisit: " Dupliquer la visite",
    requiredOrg: "Nom de l'étude obligatoire",
    noValidField: "Certains champs ne sont pas valides",
    Inscrit: "Inscrit",
    Exclu: "Exclu",
    Encours: "En cours",
    //Roles
    rt1: "L'ajout du profil a été effectué avec succès.",
    participant: "Participant",
    tableViste: "Visite",
    fieldName: "Champ",

    maj: " Modifié le",
    //StudyConfig
    banner: "Vous n'avez pas encore ajouté de membre à votre équipe",
    bannerClick: " Veuillez cliquer ici pour en ajouter.",
    //ParticipantForms/index
    pft1: "Le formulaire a été soumis avec succès.",
    pft2: "L'événement a été soumis avec succès.",
    pft3: "Cette visite n'est encore disponible",
    pft4: "Cette visite est attribué a un autre profil",
    pft5: "La requête a été mise à jour avec succès",
    pft6: "Votre message a été envoyé avec succès",
    pft7: "Votre email ou mot de passe sont incorrects.",
    pft8: "Votre email ou mot de passe sont incorrects.",
    confirm: "Confirmer",
    fph1: "Réinitialisez votre mot de passe",
    fph2: "Afin de recevoir le lien de réinitialisation de votre mot de passe,veuillez saisir l'adresse e-mail avec laquelle votre compte a été créé.",
    fpt202: "Veuillez d'abord confirmer votre compte",
    fpt201: "Utilisateur n'existe pas",
    fph3: "Saisissez le code de vérification que vous avez reçu par email",
    fpt1: "Veuillez entrer un email valide",
    fpt2: "Veuillez Verifier votre email",
    fpt3: "Un code de confirmation a étè envoyé vers votre compte",
    fpt4: "Le mot de passe doit contenir au minimum 10 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre",
    fpt5: "Les mots de passe ne correspondent pas",
    fpt6: "Votre mot de passe a été réinitialisée",
    fpt7: "Vérifier votre code de vérification",
    fpi1: "Votre code de vérification",
    fpi2: "Votre nouveau mot de passe",
    fpi3: "Confimer votre nouveau mot de passe",
    fpi4: "Votre email",
    //EventForms
    efh1: "Nouvel événement",
    efl1: "Veuillez préciser le type d'événement",
    efl2: "Type d'événement",
    eft1: "Événement créé avec succès",
    //EventConfig
    ect1: "Veuillez sélectionner le type de l'événement",
    ect2: "Veuillez saisir le nom l'événement",
    ect3: "La création du l'événement a été effectué avec succès",
    ecl1: "Sélectionnez le type de l'événement",
    //Edit/Duplicate/Delting Surveys
    fdt1: "Veuillez sélectionner le profil chargé de la visite",
    fdt2: "Veuillez saisir le nom de la visite",
    fdt3: "La création du formulaire a été effectuée avec succès",
    fdi1: "Description de la visite",
    templateDescription: "Description du modèle",
    fdi2: "Sélectionnez le profil chargé de la visite",
    fdi3: "Veuillez créer des profils",
    fdi4: "Dépendances de la visite",
    fdi5: "Pas de visites",
    fddh: "Voulez-vous supprimer ce formulaire de cette étude ?",
    deleteTemplate: "Voulez-vous supprimer cet modèle ?",
    addLang: "Veuillez sélectionner une langue",
    fddt1: "Le formulaire a été supprimé avec succès.",
    fddt2: "La suppression du formulaire a échoué !",
    fet1: "Formulaire mis à jour avec succès ",
    updateTemplate: "Votre modèle a été mis à jour avec succès",
    fel1: "Profil chargé de la visite",
    dwt: "L'nvestigateur a été supprimé avec succès.",
    resInvToast:
      "L'nvestigateur a été ajouté à la liste des responsables avec succès.",
    resNoInvToast:
      "L'nvestigateur a été supprimé à la liste des responsables avec succès.",
    REP: "Configuration des champs répétitifs",
    invalidForm: "Formulaire invalide",
    fieldConfig_fname: "Nom du champ",
    fieldConfig_ftype: "Type de champ ",
    fieldConfig_required: "Obligatoire",
    fieldConfig_number: "Champ numérique",
    fieldConfig_text: "Champ de texte",
    fieldConfig_date: "Date",
    fieldConfig_time: "Temps",
    addField: "Ajouter un champ",
    //UploadField Wording
    sizeLimit: "La taille du fichier dépasse la limite.",
    modalUploadMsg: "Souhaitez-vous télécharger le fichier suivant ?",
    upload: "Télécharger",
    modalDeleteMsg: "Souhaitez-vous supprimer le fichier suivant ?",
    delete: "Supprimer",
    succesUploadToast: "Fichier téléchargé avec succès",
    failUploadToast: "Échec du téléchargement",
    failUploadLimitToast:
      "Échec du téléchargement,vous avez dépassé la limite de téléchargement",
    imgUpload: "Charger une image",
    fileUpload: "Charger un fichier",
    invitLink: "Lien d'invitation",
    ArchiveMsg1: "Êtes-vous sûr de vouloir archiver cette étude ?",
    ArchiveMsg2:
      "Les données de l'étude seront exportées vers un fichier Excel. Votre étude sera ensuite archivée et vous ne pourrez plus y avoir accès.",
    successArchivage: "L'étude a été archivée avec succès",
    endStudyMsg1: "Êtes-vous sûr de vouloir terminer cette étude ?",
    endStudyMsg2: "Votre étude sera en mode lecture uniquement .",
    successEnding: "Étude achevée avec succès",
    FailArchivage: "L'archivage de l'étude a échoué ",
    FailEnding: "La clôture de l'étude a échoué",
    testMode: "Mode de test",
    browse: "Parcourir",
    configure: "Configurer",
    actionsPlus: "Plus d'actions",
    toolTipStudyEnd: "Cette étude est terminée ,elle est en lecture seule  ",
    bannerEnd: "cette étude est clôturée, elle est en mode lecture seule ",
    bannerEndClick: " quitter",
    Sname: "Nom de l'étude",
    profil: "Profil",
    Cname: "Nom du centre",
    MAJ: "Dernière mise à jour",
    anonymMsg:
      "Aucune donnée d'identification ne sera enregistré dans la base de donnée .",
    randomize: "Randomiser",
    selectOrg: "Sélectionner votre organistation",
    workSpaces: "Espaces de travail disponibles",
    organisation: "Organisation",
    hasDependecy:
      "Attention, vous êtes sur le point de supprimer ce formulaire, qui est utilisé comme dépendance par un ou plusieurs autres formulaires. Êtes-vous sûr de vouloir procéder à la suppression ?",
    centerDelete_1: "Tapez ",
    centerDelete_1_1: "pour confirmer la suppression !",
    centerDelete_2: "Tapez le nom du center pour confirmer la suppression !",
    centerDelete_3: "Vous avez saisi un nom de centre erroné",
    profilDelete_2: "Tapez le nom du profil pour confirmer la suppression !",
    profilDelete_3: "Vous avez saisi un nom de profil erroné",
    formDelete_2: "Tapez le nom du formulaire pour confirmer la suppression !",
    formDelete_3: "Vous avez saisi un nom de formulaire erroné",
    privateCenter: "Intégrer des centres d'investigation de ville",
    //Invitations
    confirmInvitMsg2:
      "Êtes-vous sûr de vouloir accepter les enregistrements des invitations sélectionnées ?",
    accept: "Accepter",
    confirmInvitMsg1:
      "Êtes-vous sûr de vouloir accepter l'enregistrement de cet investigateur",
    cancelInvit: "Refuser l'inscription",
    acceptlInvit: "Accepter l'inscription",
    center: "Centre ",
    acceptSelction: "Accepter la sélection",
    privateCentertDef:
    "Si désactivé,on peut pas intégrer des centres d'investigation de ville",
    copyLink:"Copier le lien",
    linkCopied:"Lien copié",
    accesCenter:"Tous les participants du centre",
    queriesCenterAceess:"Toutes les requêtes du centre",
    freeze:"Bloquer",
    freezed:"Bloqué",
    history:"Historique",
    queries:"Requêtes",
    sdv:"SDV",
    dejaSubmit:"Formulaire soumis. Si nécessaire, modifier les valeurs pour déverrouiller le bouton.",
    unpaidInvoice:"Une ou plusieurs factures impayées existent sur Stripe. Veuillez payer la facture pour continuer à utiliser votre compte.",
    fileRmoved:"Fichier supprimé avec succès",
    fileRmovedError:"Une erreur s'est produite lors de la suppression d'un fichier",
    invisible:"Ce champ est masqué",
  },
  en: {
    invisible: "This field is hidden",
    fileRmovedError: "An error occurred while deleting a file",
    fileRmoved: "File successfully deleted",
    dejaSubmit: "Form submitted. Update values to unlock button if needed.",

    freeze: "Freeze",
    freezed: "Freezed",
    history: "History",
    queries: "Queries",
    sdv: "SDV",
    accesCenter: "All participants of the center",
    queriesCenterAceess: "All queries of the center",
    confirmInvitMsg2:
      "Are you sure you want to accept the selected invitation records?",
    accept: "Accept",
    confirmInvitMsg1:
      "Are you sure you want to accept this investigator's record?",
    cancelInvit: "Decline Registration",
    acceptlInvit: "Accept Registration",
    center: "Center",
    acceptSelction: "Accept Selection",
    privateCenter: "Allow independant investigator centers",
    centerDelete_1: "Type ",
    centerDelete_1_1: "to confirm deletion !",
    centerDelete_2: "Type the center name to confirm the deletion !",
    centerDelete_3: "You have entered the wrong center name",
    profileDelete_2: "Type profile name to confirm deletion !",
    profilDelete_3: "You have entered the wrong profile name",
    formDelete_2: "Type the name of the form to confirm the deletion !",
    formDelete_3: "You have entered the wrong form name",

    organisation: "Organization",
    hasDependecy:
      "Warning, you are about to delete this form, which is used as a dependency by one or more other forms. Are you sure you want to proceed with the deletion?",
    selectOrg: "Select your organization",
    workSpaces: "Available workspaces",
    loadingParticipants: "Participants are being created...",
    loadingMsg:
      "This may take some time. Please be patient. Please do not leave the page.",
    failUploadLimitToast:
      "Download failed, you have exceeded the download limit",
    submitForm: "Form successfully submitted",
    anonymDef:
      "The participant's first and last name will not be recorded in the database.",
    randomDef:
      "The randomization allows you to randomly allocate participants into groups.",
    acessParticipantDef:
      "If disabled, the investigator can only consult and modify the participants that he himself has included",
    eProDef: "Enables participants to fill out forms",
    newEproForm: "New ePRO form",
    nFirstName: "First name",
    nLastName: "Last name",
    formEpro: "ePRO forms",
    noRandoModif:
      "The study has started and participants have already been added. You cannot change this setting.",
    extractData: "Data extraction",
    extractDataDescrp: "Please select the type of download you wish to request",
    noAssocitedForms: "No form available yet",
    sucessRandomConfig: "Your configuration has been saved successfully ",
    notAffected: "not assigned",
    addAssocitedForm: "+ Add a form",
    name: "name",
    inpXALabel: "Associated group",
    inpXA: "Select an associated group",
    inpXAn: "All groups",
    nbrReafction: "Number of reassignments",
    noRando: "No Randomisation for this study",
    randomize: "Randomize",
    onCreation: "at creation",
    onCreationEpro: "No dependencie",
    switchForms: "Reassignment forms",
    studyResp: "Study manager",
    accessConfig: "Access to participants open by center",
    eProConfig: "ePRO",
    anonymizationConfig: "Study anonymization",
    noAnonymizationConfig: "Do not anonymize the study",
    anonymMsg: "No identification data will be stored in the database. ",
    card1: "participants included",
    subCard1: "Last participant added on",
    card2: "Forms in progress",
    card2single: "Form in progress",
    card3: "Filled forms",
    card3single: "Filled form",
    card4: "LAST UPDATES",
    timeCard: "at",
    subCard4: "Modified on :",
    updateEmpty: "The list of recent updates is empty.",
    card5: "PENDING QUERIES",
    subCard5: "The list of pending queries is empty.",
    eproForms: "ePRO forms",
    noeproPart: "No ePRO forms",
    card6: "Closed queries",
    card7: "Participants excluded from the study",
    card8: "Inactive investigators",
    card9: "Number of active forms",
    emptyStats: "No statistical values have been configured.",
    nbreOfParticipant: "Number of participants",
    Unassigned: "Unspecified",
    dest: "Distribution of",
    participant2: "among participants",
    statVarFirst: "Variable 1",
    statVarSecond: "Variable 2",
    Sname: "Study name",
    profil: "Profile",
    Cname: "Center name",
    confirm: "Confirm",
    MAJ: "Latest update",
    addLang: "Please select a language",
    toolTipStudyEnd: "This study is now closed, it is in read-only mode ",
    bannerEnd: "This study is now closed, it is in read-only mode ",
    bannerEndClick: " quit ",
    actionsPlus: "More actions",
    nbrOfparticipant: "Number of participants",
    browse: "Browse",
    configure: "Configure",
    endStudyMsg1: "Are you sure you want to end this study?",
    endStudyMsg2: "Your study will be in read mode only .",
    successEnding: "Study successfully ended",
    FailEnding: "Study closing has failed",
    fieldConfig_number: "Number input",
    sharedCalculVars: "Use as a calculation variable",
    connected: "Connected",
    disconnected: "Disconnected",
    lostWebSocketConnction: "Connection lost with the server. Please wait...",
    fieldConfig_text: "Text input",
    fieldConfig_date: "Date input",
    fieldConfig_time: "Time input",
    testMode: "Test mode",
    saveAsTemplate: "Save as template",
    traduction: "Translate",
    template: "template",
    templateName: "Template name",
    templateDescription: "Template description",
    save: "Save",
    edit: "Edit",
    inp5: "Select a template",
    inp55: "Select a form template",
    asTemplate: "as template",
    deleteTemplate: "Do you want to delete this template?",
    FailArchivage: "Study archiving failed ",
    successArchivage: "The study was successfully archived",
    ArchiveMsg1: "Are you sure you want to archive this study ?",
    mathExp: "Mathematical Expersion",
    ArchiveMsg2:
      "The study data will be exported to an Excel file, and your study will then be archived and you will not be able to access it.",
    invitLink: "Invitation link",
    sizeLimit: "File size exceeds the limit.",
    modalUploadMsg: "Would you like to upolad the following file ?",
    upload: "Upload",
    modalDeleteMsg: "Would you like to delete the following file ?",
    delete: "Delete",
    succesUploadToast: "File successfully uploaded",
    failUploadToast: "Upload failed",
    imgUpload: "Upload image",
    fileUpload: "Upload file",
    invalidForm: "Invalid form",
    successTranslation: "New translation created.",
    resInvToast:
      "The nvestigator has been added to the responsible list successfully.",
    resNoInvToast:
      "The nvestigator has been successfully removed from the list of responsibles.",
    open: "Open",
    closed: "Closed",
    systQuery: "System query",
    arcQuery: "ARC query",
    auto: "Auto",
    query: "Query",
    system: "System",
    statut: "Status",
    acheved: "Achieved",
    pageName: "Page Name",
    condLogic: "Conditional Logic",
    fieldToComp: "Field to Compare",
    value: "Value",
    addCondition: "Add Condition",
    toastSurvey: "This module is unavailable on mobile version.",
    foundWord: "Searched Word",
    dateFormat: "DD-MM-YYYY",
    dateStart: "Start date",
    dateEnd: "End date",
    nbrCarcs: "Number of Characters",
    nbrCarcsMin: "Minimum Number of Characters",
    nbrCarcsMax: "Maximum Number of Characters",
    min: "Minimum",
    max: "Maximum",
    noStats: "No statistical data available",
    minFuture: "Minimum Years in the Future",
    minPast: "Minimum Years in the Past",
    maxFuture: "Maximum Years in the Future",
    maxPast: "Maximum Years in the Past",
    cancel: "Cancel",
    duplicate: "Duplicate",
    loadFail: "Failed user list loading.",
    MAJle: "Last updated on ",
    validate: "Validate",
    add: "Add",
    yes: "Yes",
    no: "No",
    investigator: "Investigator",
    user: "User",
    admin: "Admin",
    le: "sent on ",
    a: "at ",
    tunisia: "Tunisia",
    france: "France",
    visit: "Visit",
    newCenter: "New Center",
    listOfCenter: "List of Centers",
    listOfProfil: "List of profiles",
    listOfUser: "List of users",
    formVisits: "Visit forms",
    formevents: "Recurrent events",
    newForm: "New event",
    all: "All ",
    back: "Back",
    events: "Events",
    event: "Event",
    adverseevents: "Adverse event",
    phoneCallEvent: "Phone call",
    noEvents: "You have no events",
    loading: "loading",
    Loading: "Loading",
    noEventsPart: "No events",
    editProp: "Edit properties",
    unit: "Measurement unit",
    prefix: "Phone prefix",
    unitField: "Field with measurement unit",
    prefixField: "Phone with Prefix",
    unit0: "Unit",
    prefix0: "Prefix",
    choixs: "Choices",
    choix: "Choice",
    addChoice: "Add a choice",
    role: "Profile responsible ",
    orderPage: "Edit the order of pages",
    orderVisit: "Edit order of visits",
    editPageOrder: "Edit order ",
    orderSucess: "Order of visits is successfully updated",
    orderSectionSucess: "Order of pages is successfully updated",
    editVisitToolTip: "Edit",
    deleteVisitToolTip: "Delete",
    displayField: "Check the field on the participant's form",
    pagination1: "Showing ",
    pagination2: "to ",
    pagination3: "of ",
    paginationS: "Studies",
    paginationQ: "Queries",
    paginationP: "Participants",
    grp: "List of groups",
    Grp: "Group",
    addGrp: "+ Add a group",
    groupListTitle: "Groups management",

    //creationStep
    t1: "Please select the profile responsible for the visit",
    t2: "Please enter the name of the visit",
    t3: "Successful creation of the form",
    p1: "Create your form",
    inp1: "Name of the form",
    inp2: "Description of the form",
    inp3: "Select the profile responsible for the visit",
    inp3n: "Please create profiles",
    inp4: "Dependencie of the visit",
    inp4Epro: "Dependencie of the form",
    inp4n: "No visits",
    createButton: "Create",
    //PageHeader
    pht1: "The validation of this visit was successful.",
    pht2: "An error has occurred !",
    pht3: "The validation of this visit was successfully cancelled.",
    phtSpan: "Visit validated on ",
    phtSpanSgin: "Visit signed on ",
    phtOp1: "Validate the visit",
    phtOp2: "Sign the visit",
    phtOp3: "Block the visit",
    phtOp101: "Download all study data",
    phtOp202: "Archive study",
    phtOp303: "End study",
    phtSpan2: "Do you want to validate the following visit",
    sign2: "Do you want to sign the following visit",
    sign: "Sign",
    sign1: "This visit has been successfully signed.",

    phtSpan3:
      "NB: This visit contains fields that are not yet valid. Are you sure you want to validate it?",
    phtLabel1: "Reason of validation",
    phtSpan4: "Do you want to cancel the validation for the following visit",
    phtSpan5:
      "NB: This visit is validated by the investigator. Are you sure you want to cancel the validation ?",
    //FormRender
    frt1: "The query was created successfully",
    frt2: "This field has been validated successfully  ",
    frt3: "The field has been blocked successfully",
    frt4: "The field has been unblocked successfully",
    frp1: "Generated by system",
    frp2: "Edit status",
    frt5: "The query was successfully completed ",
    frt6: "Your message has been sent successfully ",
    //DashboardWriter
    noArchive: "No archived studies",
    //DragableInterface
    dit1: "Your changes are saved successfully",
    dit2: "Summary of the survey",
    //FormBuilder
    submitLabel: "Finish",
    submitLabel2: "Validate",
    next: "Next",
    //FirstLogin
    flh1: "Change your temporary password",
    flt1: "Password updated successfully !",
    flt2: "Password confirmation is required !",
    flt3: "Password is required !",
    flt4: "Both passwords must be identical !",
    flt5: "Changing your password is mandatory.",
    flt6: "At least 8 characters.",
    flt7: "Your password must contain 8 characters, one upper case, one lower case, one number and one special character.",
    flt8: "Password confirmation is required .",
    flt9: "Password must be identical!",
    fli1: "Password",
    fli2: "Password Confirmation",
    flb1: "Logout",
    flb2: "Confirm",
    //Utlis
    isPattern: "This field must contain the word",
    isLength: "This field must contain",
    carcs: "characters",
    isMinLength: "This field must contain at least",
    isMaxLength: "This field must contain at most",
    isMinNumber: "This field must be greater than",
    isMaxNumber: "This field must be less than",
    isNotEmptyString: "Empty string!",
    isNumber: "This field must contain only numbers",
    isEmail: "Invalid email!",
    isPercentage: "This field must only contain percentages",
    onlyFutureDate: "The date must be in the future",
    onlyPastDate: "The date must be in the past",
    dateRange: "The date must be between",
    minInfuturYears: "The date must be in the future for at least",
    minInPastYears: "Please choose a date that is at least",
    maxInfuturYears: "The date must be in the future for at most",
    maxInPastYears: "The date must be in the past for at most",
    isPatternLabel: "Must contain a specific word",
    isLengthLabel: "Must contain a defined number of characters",
    isMinLengthLabel: "Must contain a minimum number of characters",
    isMaxLengthLabel: "Must contain a maximum number of characters",
    isMinNumberLabel: "Must be greater than",
    isMaxNumberLabel: "Must be less than",
    isNotEmptyStringLabel: "",
    isNumberLabel: "Must contain only numbers",
    isEmailLabel: "Must be in the form of an email",
    isPercentageLabel: "Percentage",
    onlyFutureDateLabel: "The date must be in the future",
    onlyPastDateLabel: "The date must be in the past",
    dateRangeLabel: "Date range",
    minInfuturYearsLabel: "Minimum number of future years",
    minInPastYearsLabel: "Minimum number of past years",
    maxInfuturYearsLabel: "Maximum number of future years",
    maxInPastYearsLabel: "Maximum number of past years",
    sessionStatus: "Expired session",
    //RulesValidation
    errorMsg: "Error message",
    fieldParms: "Field parameters",
    required: "Required field",
    activer: "Activate",
    desactive: "Deactivate",
    exclusion: "Exclusion (prevents future data entry)",
    advParms: "Advanced parameters",
    textSelct: "Please select a parameter",
    parmsToolTip: "Add more validation parameters",
    ///
    visitInfos: "Visit informations",
    visitName: "Visit name",
    eventName: "Event name",
    newEvent: "Create a new event",
    dupVisit: "Duplicate the visit",
    requiredOrg: "Name of the study required",
    noValidField: "Some fields are not valid",
    Inscrit: "Registered",
    Exclu: "Excluded",
    Encours: "In progress",
    //Roles
    rt1: "The profile has been added successfully.",
    participant: "Participant",
    tableViste: "Visit",
    fieldName: "Field",
    maj: "Updated on",
    //StudyConfig
    banner: "You have not yet added a member to your team.",
    bannerClick: "Please click here to add .",
    //ParticipantForms/index
    pft1: "The form was submitted successfully.",
    updateTemplate: "Your template has been successfully updated.",
    pft2: "Event has been submitted successfully.",
    pft3: "This visit is not yet available",
    pft4: "This visit is assigned to another profile",
    pft5: "The query has been successfully updated",
    pft6: "Your message was sent successfully",
    pft7: "Your email or password is incorrect.",
    pft8: "Your email or password is incorrect.",
    fph1: "Reset your password",
    fph2: "In order to receive the password reset link, please enter the email address with which your account was created.",
    fph3: "Enter the verification code you received by email",
    fpt202: "Please confirm your account first",
    fpt201: "User does not exist",
    fpt1: "Please enter a valid email",
    fpt2: "Please verify your email",
    fpt3: "A confirmation code has been sent to your account",
    fpt4: "The password must contain at least 10 characters, including: at least one lowercase letter and one uppercase letter, a special character, and a number",
    fpt5: "The passwords do not match",
    fpt6: "Your password has been reset",
    fpt7: "Verify your verification code",
    fpi1: "Your verification code",
    fpi2: "Your new password",
    fpi3: "Confirm your new password",
    fpi4: "Your email",
    //EventForms
    efh1: "New event",
    efl1: "Please specify the type of event",
    efl2: "Event type",
    eft1: "Event created successfully",
    //EventConfig
    ect1: "Please select the type of event",
    ect2: "Please enter the name of the event",
    ect3: "The creation of the event was successful",
    ecl1: "Select the type of event",
    //Edit/Duplicate/Delting Surveys
    fdt1: "Please select the profile responsible for the visit",
    fdt2: "Please enter the name of the visit",
    fdt3: "The creation of the form was successful",
    fdi1: "Visit description",
    fdi2: "Select the profile responsible for the visit",
    fdi3: "Please create profiles",
    fdi4: "Visit dependencies",
    fdi5: "No visits",
    fddh: "Do you want to delete this form from this study?",
    fddt1: "The form was deleted successfully.",
    fddt2: "Deleting the form failed!",
    fet1: "Form updated successfully",
    fel1: "Profile responsible for the visit",
    dwt: "The investigator was deleted successfully.",
    REP: "Repeating field configuration",
    fieldConfig_fname: "Field name",
    fieldConfig_ftype: "Field type ",
    fieldConfig_required: "Required",
    addField: "Add field",
    privateCentertDef:
      "If deactivated, we can't integrate city investigation centers",
    unpaidInvoice:
      "One or more unpaid invoices exist on Stripe. Please pay the invoice to continue using your account.",
  },

  copyLink: "Copy link",
  linkCopied: "Link copied",
};
