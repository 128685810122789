import classNames from "classnames";
import { useAtom } from "jotai";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { screenBreakpoints } from "../../config/theme";
import history from "../../history";
import { userAtom } from "../../state/auth";
import { ActiveRouteAtom } from "../../state/route";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import Icon from "../Icon";
import Logout from "../Logout";
import "../SideBar/styles.scss";
import { ReactComponent as WomanPatient } from "../../assets/illustrations/illustration-femme-patient.svg";
import { ReactComponent as ManPatient } from "../../assets/illustrations/illustration-homme-patient.svg";
import { useTranslation } from "react-i18next";
import { checkCroUnpaidInvoices } from "../../api/arc";

export const SiderBarARC = () => {
  const [user] = useAtom(userAtom) as any;
  const [path, setPath] = useAtom(ActiveRouteAtom);
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const [studySelectedId, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const location = useLocation();
  const { t, i18n } = useTranslation() as any;

  //const [hasCroUnpaidInvoices, setCroHasUnpaidInvoices] = useState(false);

  // const checkCroUnpaid = async () => {
  //   try {
  //     const res = await checkCroUnpaidInvoices();
  //     setCroHasUnpaidInvoices(res.hasUnpaidInvoices);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   checkCroUnpaid();
  //   if (hasCroUnpaidInvoices) {
  //     history.push("/profile");
  //   }
  // }, [hasCroUnpaidInvoices]);
  const arcMenus = [
    {
      icon: "studies",
      text: t("SideBar.title"),
      url: "/",
    },
    {
      icon: "exports",
      text: t("SideBar.exports"),
      url: "/exports",
    },
    {
      icon: "monCompte",
      text: t("SideBar.monCompte"),
      url: "/profile",
    },
  ];
  const [menus, setMenu] = useState(arcMenus) as any;
  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });
  const menuCroSelectedStudy = (studyId) =>
    user.type === "cro" || user.type === "arc"
      ? [
          {
            icon: "dashboard",
            text: t("SideBar.Dashboard"),
            url: `/dashboard/${studyId}`,
          },
          {
            icon: "participants",
            text: t("SideBar.Participants"),
            url: `/participant/${studyId}`,
          },
          {
            icon: "query",
            text: t("SideBar.Queries"),
            url: `/query/${studyId}`,
          },
          {
            icon: "calendar",
            text: t("missingWording.events"),
            url: `/events/${studyId}`,
          },
          {
            icon: "eproform",
            text: t("SideBar.EproForms"),
            url: `/eproForms/${studyId}`,
          },
        ]
      : [
          {
            icon: "participants",
            text: t("SideBar.Participants"),
            url: `/participant/${studyId}`,
          },
        ];

  useEffect(() => {
    let routeArray = location.pathname.split("/").filter((e) => e && e !== "");
    if (_.isEmpty(routeArray)) {
      setMenu(arcMenus);
    } else if (routeArray.length === 2 && routeArray[0] === "dashboard") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "participant") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "query") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 3 && routeArray[0] === "participant") {
      setMenu([]);
    } else if (routeArray.length === 2 && routeArray[0] === "events") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else if (routeArray.length === 2 && routeArray[0] === "eproForms") {
      setMenu(menuCroSelectedStudy(routeArray[1]));
    } else {
      setMenu(arcMenus);
    }
  }, [location, i18n.language]);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    setMenuToggleIsActive(window.innerWidth <= screenBreakpoints.md);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);
  useEffect(() => {
    if (forceMenuOpen) {
      setForceMenuOpen(false);
    }
  }, [path]);

  return !_.isEmpty(menus) ? (
    <div className="sidebar-doctor sidebar z-40 ">
      <div className="flex justify-between md:hidden absolute mobile-menu-burger">
        <button
          className="p-4 focus:outline-none"
          onClick={() => {
            setForceMenuOpen(!forceMenuOpen);
          }}
        >
          <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
        </button>
      </div>
      <div
        className={`swipe-events-container ${
          !menuToggleIsActive || !forceMenuOpen ? "" : "swipeable-fullscreen"
        }`}
        {...swipeEventsHandlers}
      />
      <div
        className={`sidebar-container  transform md:relative md:translate-x-0 transition duration-200 ease-in-out ${
          menuToggleIsActive ? "mobile-sidebar" : "bg-transparent"
        } ${menuToggleIsActive && !forceMenuOpen ? "-translate-x-full" : ""}`}
      >
        <div className="relative">
          <div className="flex items-center space-x-3 relative notification ">
            {user?.business_photo ? (
              <div className="avatar-doctor1 relative"></div>
            ) : (
              <div className="avatar-doctor1 relative">
                {user?.gender === "female" ? (
                  <WomanPatient className=" rounded-full  bg-pink-200 w-3/4 h-3/4" />
                ) : (
                  <ManPatient className=" rounded-full bg-yellow-100 w-3/4 h-3/4" />
                )}
              </div>
            )}

            <div>
              <span className="menu capitalize">
                {user?.firstName} {user?.lastName}
              </span>
              <p className="capitalize docter-center">
                {user?.organisationId?.name}
              </p>
            </div>
          </div>
          {studySelectedId && (
            <div className="icon link cursor-pointer flex items-center">
              <Icon
                name="direction-gauche"
                width={50}
                height={50}
                isIcon={true}
                onClick={() => {
                  setStudySelectedId(null);
                  history.push("/");
                }}
              />
              <div className="mb-2">
                <p
                  className=""
                  onClick={() => {
                    setStudySelectedId(null);
                    history.push("/");
                  }}
                >
                  {t("missingWording.back")}
                </p>
              </div>
            </div>
          )}
          <nav className={classNames("mt-3 ")}>
            {menus.map((menu) => {
              return (
                <div
                  className={
                    // hasCroUnpaidInvoices
                    // ? "cursor-not-allowed"
                    // :
                    path === menu.url ? "selected-menu " : ""
                  }
                >
                  <Link
                    to={menu.url}
                    className={classNames(
                      "flex items-center space-x-3 md:py-3 -py-1"
                      // ,hasCroUnpaidInvoices && "pointer-events-none"
                    )}
                    onClick={() => {
                      setPath(menu.url);
                    }}
                  >
                    <div className="w-12 h-12 flexCenter ">
                      {menu.icon === "template" ? (
                        <img
                          src={require("../../assets/templates.png").default}
                          width={25}
                          alt="template"
                        />
                      ) : menu.icon === "list" ? (
                        <img
                          src={require("../../assets/list.svg").default}
                          width={25}
                          alt="template"
                        />
                      ) : (
                        <Icon isIcon={true} name={menu.icon} width={32} height={32} />
                      )}
                    </div>
                    <span className="my-auto menu">{menu.text}</span>
                  </Link>
                </div>
              );
            })}
            <Logout />
          </nav>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SiderBarARC;
