import { useState, useRef } from "react";
import classNames from "classnames";
import moment from "moment";
import { FaEllipsisV } from "react-icons/fa";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { updateEconsultByWriter } from "../../api/study";
import EditEconsultModal from "./Modals/EditEconsultModal";
import MyModal from "../../components/New-Modal";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import makeToast from "../../components/Snackbar";
import { useTranslation } from "react-i18next";
type Props = {
  patientPublicId: string;
  patientId: string;
  date: any;
  motif: string;
  eConsultId: string;
  setShouldUpdate: any;
  setSeeMore: any;
  status: any;
};

const EconsultCard = (props: Props) => {
  const {
    patientPublicId,
    patientId,
    motif,
    date,
    eConsultId,
    setShouldUpdate,
    setSeeMore,
    status,
  } = props;

  const [user] = useAtom(userAtom);
  const [option, setOption] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const ref = useRef() as any;
  const { t } = useTranslation() as any;
  useOnClickOutside(ref, () => setOption(false));
  const handleStatus = (s) => {
    switch (s) {
      case "realised":
        return t("eConsult.realised");

      case "waiting":
        return t("eConsult.waiting");

      case "canceled":
        return t("eConsult.canceled");

      case "patientAbsent":
        return t("eConsult.patientAbsent");

      case "unrealised":
        return t("eConsult.unrealised");

      default:
        return t("eConsult.waiting");
    }
  };

  return (
    <div className="bg-white shadow-xl rounded-xl flex justify-between px-4 py-5 w-full ">
      <div className="">
        <div className="">
          {user?.type === "writer" ? (
            <>
              {" "}
              Participant <span className="font-bold">{patientPublicId}</span>
            </>
          ) : (
            <>
              Dr. <span className="font-bold">{patientPublicId}</span>
            </>
          )}
        </div>
        <div className="flex gap-2">
          <div className="mt-1 bg-gray-100 max-w-max px-3 rounded-3xl">
            <span className="font-bold text-xs" style={{ color: "#9FA3AF" }}>
              {motif}
            </span>
          </div>

          <div
            className={classNames(
              "mt-1 bg-gray-100 max-w-max px-3 rounded-3xl",
              status === "realised"
                ? "bg-green-100"
                : status === "waiting"
                ? "bg-gray-100"
                : status === "canceled"
                ? "bg-red-100"
                : status === "patientAbsent"
                ? "bg-yellow-100"
                : status === "unrealised"
                ? "bg-yellow-100"
                : "bg-gray-100"
            )}
          >
            <span
              className="font-bold text-xs"
              style={{
                color:
                  status === "realised"
                    ? "#34c7a4"
                    : status === "waiting"
                    ? "#9FA3AF"
                    : status === "canceled"
                    ? "#ef4444"
                    : status === "patientAbsent"
                    ? "#2c2a2a"
                    : status === "unrealised"
                    ? "#2c2a2a"
                    : "#9FA3AF",
              }}
            >
              {handleStatus(status)}
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="text-blue-500 font-semibold ">
          {moment(date).format("DD/MM/YYYY HH:mm")}
        </div>
        {user?.type === "writer" && (
          <div className="relative">
            <FaEllipsisV
              className="-mt-3 ml-2 cursor-pointer"
              onClick={() => {
                setOption((prev) => !prev);
              }}
            />
            <div
              className={classNames(
                "absolute right-0 z-10 w-32 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                option ? "" : "hidden"
              )}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex={-1}
              ref={ref}
            >
              <ul>
                <li
                  className="font-medium hover:bg-gray-100 cursor-pointer ml-2"
                  onClick={() => setEditModal(true)}
                >
                  {t("eConsult.edit")}
                </li>
                <li
                  className="font-medium hover:bg-gray-100 cursor-pointer ml-2"
                  onClick={async () => {
                    await updateEconsultByWriter(eConsultId, {
                      status: "canceled",
                    });
                    makeToast("success", t("eConsult.successEdit"));
                    setOption(false);
                    setShouldUpdate((prev) => !prev);
                  }}
                >
                  {t("eConsult.cancel")}
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <MyModal showModal={editModal} closeModal={setEditModal}>
        <EditEconsultModal
          closeModal={() => setEditModal(false)}
          refresh={() => setShouldUpdate((prev) => !prev)}
          eConsultId={eConsultId}
          motif={motif}
          date={date}
          ParticipantId={patientId}
          setSeeMore={setSeeMore}
        />
      </MyModal>
    </div>
  );
};

export default EconsultCard;
