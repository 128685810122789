const ACTIONS = {
	JOIN: "join",
	JOINED: "joined",
	DISCONNECTED: "disconnected",
	FORM_CHANGE: "formData_change",
	SYNC_FORM: "sync_form",
	LEAVE: "leave",
	SDV:"sdv_field",
	FREEZE:"freeze_field",
	ADD_QUERY:"add_query",
	VALIDATE:"validate_form"
};

export default ACTIONS