import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { verifyingSubscription } from "../../api/stripe";
import { userAtom } from "../../state/auth";
import Button from "../ButtonUI";
import Spinner from "../Spinner";
import { useTranslation } from "react-i18next";

type Props = {
  userType: any;
  setPriceId: Function;
};
const PricingCard: React.FC<Props> = ({ userType, setPriceId }) => {
  const [user, setUser] = useAtom(userAtom);
  const { t } = useTranslation() as any;
  const [selctedProduct, setSelctedProduct] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [subId, setSubId] = useState();
  useEffect(() => {
    async function fetchMyAPI(): Promise<void> {
      setLoading(true);
      {
        try {
          const sub = await verifyingSubscription({
            subscriptionId: user?.stripeSubscriptionId,
            userId: user?._id,
          });
          console.log(sub);

          if (sub) {
            setSubId(sub.planId);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          Promise.reject(error);
        }
      }
    }
    if (
      user &&
      user?.stripeSubscriptionId &&
      user?.stripeSubscriptionId !== null
    ) {
      fetchMyAPI();
    }
  }, []);

  const reqProducts = [
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T1,
      price: "10",
      limit: true,
    },
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_REQ_T2,
      price: "20",
      limit: false,
    },
  ].filter(function (value) {
    return value.priceId !== subId;
  });

  const expertProducts = [
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T1,
      price: "20",
      limit: true,
    },
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICING_EXP_T2,
      price: "40",
      limit: false,
    },
  ].filter(function (value) {
    return value.priceId !== subId;
  });
  const surgProducts = [
    {
      priceId: process.env.REACT_APP_STRIPE_MONTHLYSUB_PRICE_ID,
      price: "49",
      limit: false,
    },
  ];

  return loading ? (
    <Spinner />
  ) : (
    <div className="flex gap-x-5">
      {surgProducts.map((p, i) => (
        <div
          className={
            selctedProduct === p.priceId
              ? `cursor-pointer card text-center   border shadow-2xl border-indigo-700 border-8`
              : `cursor-pointer card text-center   border border-gray-400 `
          }
          onClick={() => {
            if (!(subId && subId === p.priceId && i == 0)) {
              setSelctedProduct(p.priceId);
              setPriceId(p.priceId);
            }
          }}
        >
          <div className="title">
            {/* <i className="fa fa-rocket" aria-hidden="true"></i> */}
            <h2> {t("SignIn.periodicity")}</h2>
          </div>
          <div className="price">
            <h4>
              <sup>€</sup>
              {p.price}
              <sub>HT</sub>
            </h4>
          </div>
          <div className="option">
            <ul>
              {/* <li>
      <i className="fa fa-check" aria-hidden="true"></i> {p.limit ? "Jusqu'à  10 patients" : "Nombre illimité de patients"}
    </li> */}
              {/* <li>
                <i
                  className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                  aria-hidden="true"
                ></i>
                {p.limit
                  ? "Jusqu'à  10 demandes de téléexpertise"
                  : "Nombre illimité de demandes de téléexpertise"}
              </li>
              <li>
                <i
                  className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                  aria-hidden="true"
                ></i>{" "}
                Accès Web et Mobile
              </li>
              <li>
                <i
                  className="fa fa-check subpixel-antialiased text-justify text-sm mb-4"
                  aria-hidden="true"
                ></i>{" "}
                Support Technique
              </li> */}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingCard;
