import Swal from "sweetalert2";
import "./styles.scss";

const handleMouseInOut: any = (toast) => {
  toast.addEventListener("mouseenter", Swal.stopTimer);
  toast.addEventListener("mouseleave", Swal.resumeTimer);
};

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: false,
  didOpen: handleMouseInOut,
});

const makeToast = (type, msg) => {
  Toast.fire({
    icon: type,
    title: msg,
    showCloseButton: true,
  });

  const closeButton =Swal.getCloseButton();
  if (closeButton) {
    closeButton.style.position = "absolute";
    closeButton.style.top = "-1";
    closeButton.style.right = "-1";
  }
};

export default makeToast;
