import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { addArc } from "../../../api/arc";
import Button from "../../../components/ButtonUI";
import Input from "../../../components/FloatInput";
import makeToast from "../../../components/Snackbar";
import { loadLocaleLang } from "../../../helper";
import ADDCroShcema from "./ADDCroShcema";

function AddArc({ setOpenModal, setShouldUpdate }) {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean | any>(false);
  const { t } = useTranslation() as any;

  return (
    <div className="flex flex-col ">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          lang: loadLocaleLang(),
        }}
        onSubmit={async (values) => {
          setLoadingSubmit(true);
          try {
            if (!values.email) {
              setLoadingSubmit(false);
              return makeToast("warning", `${t("ArcPage.pleaseEmail")}`);
            }
            const res = await addArc(values);
            if (res) {
              makeToast("success", `${t("ArcPage.successAddArc")}`);
              setShouldUpdate((up) => !up);
              setOpenModal(false);
            } else {
              makeToast("error", `${t("ArcPage.emailExist")}`);
            }
            setLoadingSubmit(false);
          } catch (err) {
            makeToast("error", `${t("ArcPage.emailExist")}`);
            console.log("[error] add Patient -on Submit :", err);
          }
        }}
        validationSchema={ADDCroShcema}
      >
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            touched,
          } = props;

          return (
            <form onSubmit={handleSubmit} className="flex-auto py-7 px-8">
              <div className="flex flex-wrap justify-center">
                <h1 className="mb-10 ">{t("ArcPage.addArc")}</h1>
              </div>
              <div className="mb-10">
                <div className="mb-8">
                  <Input
                    id="firstName"
                    placeholder={t("ArcPage.firstName")}
                    //error={values.pricing}

                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    className="w-auto"
                    labelText={t("ArcPage.name")}
                  />
                  {errors.firstName && touched.firstName && (
                    <p className="text-red-300  text-sm ml-4">
                      {t("ArcPage.verifLast")}
                    </p>
                  )}
                </div>
                <div className="mb-8">
                  <Input
                    id="lastName"
                    placeholder={t("ArcPage.lastArc")}
                    //error={values.pricing}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    className="w-auto"
                    labelText={t("ArcPage.lastname")}
                  />
                  {errors.lastName && touched.lastName && (
                    <p className="text-red-300  text-sm ml-4">
                      {t("ArcPage.verifLastname")}
                    </p>
                  )}
                </div>
                <div className="mb-8">
                  <Input
                    id="email"
                    placeholder={t("ArcPage.writeEmail")}
                    //error={values.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inlineLabel={false}
                    onfocus={true}
                    className="w-auto"
                    labelText="Email"
                  />
                  {errors.email && touched.email && (
                    <p className="text-red-300  text-sm ml-4">
                      {t("ArcPage.VerifEmail")}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  className="float-left  btn-delete"
                  onClick={() => setOpenModal(false)}
                >
                  {t("ArcPage.Cancel")}
                </Button>

                <Button
                  status="secondary"
                  width="100px"
                  height="50px"
                  disabled={!isValid || loadingSubmit}
                  className="float-right"
                  onClick={() => handleSubmit()}
                >
                  {t("ArcPage.save")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddArc;
