import _ from "lodash";
const filterFirstBookedTimeSlot = (arr) => {
  let test = false;
  let rslt = [] as any;

  if (!arr || _.isEmpty(arr)) return rslt;
  for (const slot of arr) {
    if (test) rslt.push(slot);
    else if (slot.eventStatus === "open") {
      test = true;
      rslt.push(slot);
    }
  }
  return rslt;
};
const checkArcAdmin = (arcId: any, arcAdmins: any[]) => {
  try {
    const res = arcAdmins.some((arcAdmin) => arcAdmin === arcId);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: index.tsx:38 ~ checkArcAdmin ~ error", error);
    return false;
  }
};
const checkArcAdminWithStudyId = (StudyId: any, arcStudies: any[]) => {
  try {
    console.log(
      "🚀 ~ file: index.ts:28 ~ checkArcAdminWithStudyId ~ arcStudies:",
      arcStudies
    );

    const res = arcStudies.some((studies) => studies === StudyId);
    return res;
  } catch (error) {
    console.log("🚀 ~ file: index.tsx:38 ~ checkArcAdmin ~ error", error);
    return false;
  }
};
function generatePastelColor(): string {
  const hue = Math.floor(Math.random() * 360); // Random hue value between 0 and 360
  const saturation = Math.floor(Math.random() * 21) + 80; // Saturation between 80 and 100
  const lightness = Math.floor(Math.random() * 21) + 70; // Lightness between 70 and 90

  // Convert HSL to RGB
  const h = hue / 360;
  const s = saturation / 100;
  const l = lightness / 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h * 6) % 2) - 1));
  const m = l - c / 2;

  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 1 / 6) {
    r = c;
    g = x;
  } else if (1 / 6 <= h && h < 2 / 6) {
    r = x;
    g = c;
  } else if (2 / 6 <= h && h < 3 / 6) {
    g = c;
    b = x;
  } else if (3 / 6 <= h && h < 4 / 6) {
    g = x;
    b = c;
  } else if (4 / 6 <= h && h < 5 / 6) {
    r = x;
    b = c;
  } else {
    r = c;
    b = x;
  }

  r = Math.floor((r + m) * 255);
  g = Math.floor((g + m) * 255);
  b = Math.floor((b + m) * 255);

  return `rgb(${r}, ${g}, ${b})`;
}
const loadLocaleLang = () => {
  let locale = localStorage.getItem("i18nextLng");
  return locale === "fr" ? locale : "en";
};

const getSenderType = (type, t) => {
  switch (type) {
    case "writer":
      return t("missingWording.investigator");
    case "arc":
      return t("missingWording.user");
    case "cro":
      return t("missingWording.admin");
  }
};

export function findObjectsByDependency(array: any[], tempId: string) {
  let result = [] as any[];

  for (const obj of array) {
    if (obj.dependency.includes(tempId)) {
      result.push(obj);
    }
  }

  return result;
}
function isEConsultPath(pathname: string): boolean {
  // Define a regular expression pattern for matching "/eConsult/:id"
  const eConsultPattern = /^\/eConsult\/[0-9a-fA-F]{24}$/;

  // Use the test method of RegExp to check if the pathname matches the pattern
  return eConsultPattern.test(pathname);
}
export {
  filterFirstBookedTimeSlot,
  checkArcAdmin,
  checkArcAdminWithStudyId,
  loadLocaleLang,
  getSenderType,
  isEConsultPath,
  generatePastelColor
};
