import { Textarea } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import Button from "../../../ButtonUI";
const ConfirmSdvModal = (props) => {
  const {
    setConfirmSdvModal,
    setSelectedElment,
    selectedElment,
    validateFild,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <Button
        status="icon-secondary"
        onClick={() => {
          setConfirmSdvModal(false);
          setSelectedElment();
        }}
        className="absolute right-1 top-1"
      >
        <Close />
      </Button>
      <div className="bg-white mt-8 p-4 xs:w-auto md:w-96" style={{}}>
        <span className="flex justify-center text-lg font-semibold ">
          {" "}
          {t("Survey.validField")}
        </span>
        <div className={!selectedElment?.isValid ? "mb-20 mt-0" : "mb-10 mt-6"}>
          {!selectedElment?.isValid && (
            <>
              <span className="text-sm text-red-400">
                {t("Survey.NbValid")}
              </span>
              <p className="flex flex-col mt-6">
                <Textarea
                  isRequired
                  placeholder={t("Survey.RaisValid")}
                  _placeholder={{ fontSize: "11px" }}
                  // labelText={t("Survey.RaisValid")}
                ></Textarea>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <Button
          status="secondary"
          width="100px"
          height="50px"
          className="float-right mb-4 mx-2"
          onClick={() => {
            setSelectedElment();
            setConfirmSdvModal(false);
          }}
        >
          {t("Survey.Cancel")}
        </Button>
        <Button
          status="primary"
          width="100px"
          height="50px"
          className="float-left mb-4 mx-2"
          onClick={async () => {
            await validateFild(selectedElment, true);
            setSelectedElment();
            setConfirmSdvModal(false);
          }}
        >
          {t("Survey.Confirm")}
        </Button>
      </div>
    </>
  );
};
export default ConfirmSdvModal;
