import React, { DetailedHTMLProps, InputHTMLAttributes, LabelHTMLAttributes } from 'react';

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: string;
  labelText?: string;
};

const Radiobox: React.FC<Props> = ({ error, labelText, ...props }) => {
  const { ...inputProps } = props;

  return (
    <label className='inline-flex items-center' htmlFor={labelText}>
      <input type='radio' name='vehicle' className='w-5 h-5 text-blue-600' {...inputProps} />
      {labelText && <span className='ml-2 text-gray-700'>{labelText}</span>}
    </label>
  );
};

export default Radiobox;
