import { FormLabel, Input } from "@chakra-ui/react";
import moment from "moment";
import DateRangePicker from "../../../DateRangePicker";

const renderValueInput = (choix, setRule, newRule,t) => {
  
  switch (choix.rule) {
    case "isPattern":
      return (
        <>
          <FormLabel className="card-subtitle">{t('missingWording.foundWord')}</FormLabel>
          <Input
            required
            fontSize={"14px"}
            onChange={(e) =>
              setRule({ ...newRule, parms: { valuePat: e.target.value } })
            }
          ></Input>
        </>
      );
      case "isRegExp":
      return (
        <>
          <FormLabel className="card-subtitle">{t('missingWording.foundWord')}</FormLabel>
          <Input
            required
            fontSize={"14px"}
            onChange={(e) =>
              setRule({ ...newRule, parms: { valuePat: e.target.value } })
            }
          ></Input>
        </>
      );
    case "isLength":
      return (
        <>
          <FormLabel className="card-subtitle">{t('missingWording.nbrCarcs')}</FormLabel>
          <Input
            required
            fontSize={"14px"}
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { islength: parseInt(e.target.value) },
              })
            }
            type={"number"}
          ></Input>
        </>
      );
    case "isMinLength":
      return (
        <>
          <FormLabel className="card-subtitle">
           {t('missingWording.nbrCarcsMin')}
          </FormLabel>
          <Input
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { minLength: parseInt(e.target.value) },
              })
            }
            required
            fontSize={"14px"}
            type={"number"}
          ></Input>
        </>
      );
    case "isMaxLength":
      return (
        <>
          <FormLabel className="card-subtitle">
           {t('missingWording.nbrCarcsMax')}
          </FormLabel>
          <Input
            required
            onChange={(e) => {
              setRule({
                ...newRule,
                parms: { maxLength: parseInt(e.target.value) },
              });
            }}
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "isMinNumber":
      return (
        <>
          <FormLabel className="card-subtitle">{t('missingWording.min')}</FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { inf: parseFloat(e.target.value) },
              })
            }
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "isMaxNumber":
      return (
        <>
          <FormLabel className="card-subtitle">{t('missingWording.max')}</FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { sup: parseFloat(e.target.value) },
              })
            }
            fontSize={"14px"}
            type={"number"}
          ></Input>
        </>
      );
    case "dateRange":
      return (
        <>
          <DateRangePicker
            onChange={function (
              startDate: Date | null,
              endDate: Date | null
            ): void {
              setRule({
                ...newRule,
                parms: {
                  minDate: moment(startDate).format(t('missingWording.dateFormat')),
                  maxDate: moment(endDate).format(t('missingWording.dateFormat')),
                },
              });
            }}
          />
        </>
      );
    case "minInfuturYears":
      return (
        <>
          <FormLabel className="card-subtitle">
           {t('missingWording.minFuture')}
          </FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { minYearsFutur: parseInt(e.target.value) },
              })
            }
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "maxInfuturYears":
      return (
        <>
          <FormLabel className="card-subtitle">
          {t('missingWording.maxFuture')}
          </FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { maxYearsFutur: parseInt(e.target.value) },
              })
            }
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "minInPastYears":
      return (
        <>
          <FormLabel className="card-subtitle">
          {t('missingWording.minPast')}
          </FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { minYearsPast: parseInt(e.target.value) },
              })
            }
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );
    case "maxInPastYears":
      return (
        <>
          <FormLabel className="card-subtitle">
           {t('missingWording.maxPast')}
          </FormLabel>
          <Input
            required
            onChange={(e) =>
              setRule({
                ...newRule,
                parms: { maxYearsPast: parseInt(e.target.value) },
              })
            }
            type={"number"}
            fontSize={"14px"}
          ></Input>
        </>
      );

    default:
      return <></>;
  }
};
export default renderValueInput;
