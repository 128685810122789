import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTemplateForms } from "../../api/survey";
import { ActiveRouteAtom } from "../../state/route";
import CardSurvey from "../Forms/SurveyTemplate";
import "./styles.scss";

const Templates = () => {
  const { t } = useTranslation() as any;
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [templateList, setTemplateList] = useState([]) as any;
  const [refresh, setRefresh] = useState(false);

  async function fetchMyAPI(): Promise<void> {
    // setLoading(true)

    const forms = await getTemplateForms();
    setTemplateList(
      forms
        ? forms?.map((el) => {
            return { value: el?._id, label: el?.name, el: el };
          })
        : []
    );
  }

  useEffect(() => {
    fetchMyAPI();
  }, [refresh]);

  useEffect(() => {
    setPath("/templates");
  }, []);
  return (
    <>
      <div className="mx-16 my-patients">
        <header className="flex justify-between items-center w-full flex-wrap">
          <div className="title-container">
            <h1 className="pageTitle">{t("ArcPage.template")}</h1>
          </div>
        </header>

        <div className={`patient-list flex flex-wrap gap-y-10 gap-x-32`}>
          {templateList && templateList.length !== 0 ? (
            templateList.map((el, i) => (
              <CardSurvey
                visteList={templateList}
                patientDetails={el}
                openModal={false}
                surveyDetails={el}
                // user={user}
                i={i}
                setRefresh={setRefresh}
              />
            ))
          ) : (
            <div className="w-full flexCenter mt-24">
              <h3>{t("ArcPage.NoTemplate")}</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Templates;
