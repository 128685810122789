/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import { BASE_URL } from "../../config/api";
import { useParams, Link } from "react-router-dom";
import { FiVideo, FiVideoOff } from "react-icons/fi";
import { AiOutlineAudio, AiOutlineAudioMuted } from "react-icons/ai";
import { isEmpty } from "lodash";
import Peer from "simple-peer";
import styled from "styled-components";
import { useAtom } from "jotai";
import { socketAtom } from "../../state/socket";
import Button from "../../components/ButtonUI";
import MyModal from "../../components/New-Modal";
import { userAtom } from "../../state/auth";
import { useTranslation } from "react-i18next";
import RemoteVideoStream from "./RemoteVideoStream";
import "./styles.scss";
import jwt from "jsonwebtoken";
import { updateEconsultByWriter } from "../../api/study";
import Icon from "../../components/Icon";
import makeToast from "../../components/Snackbar";
import history from "../../history";
import useWebRTC from "../../hooks/useWebRTC";

const Room = (props) => {
  const { t } = useTranslation() as any;
  const [patientAbsent, setPatientAbsent] = useState(false);
  const [user] = useAtom(userAtom) as any;
  const { studyId, eConsultId, participantId, tenantId, token } =
    useParams() as any;
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [modalPopConfirExit, setModalPopConfirExit] = useState(false);

  const [
    localVideoRef,
    remoteCallParticipants,
    handleControlVideo,
    handleControlAudio,
    audioFlag,
    videoFlag,
  ] = useWebRTC({
    wsUrl: BASE_URL,
    localUserProfile: {
      _id: user?._id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      role: user?.role,
    },
    token,
    studyId,
    eConsultId,
    participantId,
    tenantId,
  });

  const [aspectRatio, setAspectRatio] = useState(null);
  const [objectFit, setObjectFit] = useState("contain") as any;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than or equal to a certain threshold (e.g., 768 pixels for tablets)
      setIsMobile(window.innerWidth <= 768);
    };
    // Call handleResize initially and add event listener for resize
    handleResize();
    window.addEventListener("resize", handleResize);
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="-mt-10">
      {user?.type === "writer" ? (
        <div className="mb-5 max-w-max ml-auto">
          <Link
            to={`/participant/${studyId}/${participantId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              status="secondary"
              className="px-4 py-1 h-10 flex items-center ml-auto "
            >
              <span className="mb-0 pb-0">{t("eConsult.patientForms")} </span>
            </Button>
          </Link>
        </div>
      ) : (
        <div className="mt-10"></div>
      )}

      <div>
        <video
          className={`local-video ${
            remoteCallParticipants.length === 0 ? "alone" : ""
          }`}
          muted
          ref={localVideoRef}
          autoPlay
        />
        <div className="remote-videos-grid">
          {remoteCallParticipants.map((callParticipant, index) => (
            <RemoteVideoStream
              className="remote-video-container"
              key={index}
              label={`${callParticipant.user.firstName} ${callParticipant.user.lastName}`}
              stream={callParticipant.stream}
              videoProps={{
                muted: false,
                autoPlay: true,
                className: "remote-video",
              }}
              labelProps={{
                className: "remote-video-label",
              }}
            />
          ))}
          <div className="control-call-container">
            <div
              className="w-14 h-14 rounded-full bg-white shado shadow-black  flexCenter cursor-pointer relative"
              onClick={handleControlVideo}
            >
              {videoFlag ? <FiVideo size={22} /> : <FiVideoOff size={22} />}
            </div>

            <div
              className="w-14 h-14 rounded-full bg-red-500 shadow-black flexCenter cursor-pointer relative"
              onClick={() => setModalPopConfirExit((prev) => !prev)}
            >
              <Icon
                isIcon={true}
                name="tel"
                style={{
                  filter:
                    "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
                }}
              />
            </div>
            <div
              className="w-14 h-14 rounded-full bg-white shado shadow-black  flexCenter cursor-pointer relative"
              onClick={handleControlAudio}
            >
              {audioFlag ? (
                <AiOutlineAudio size={22} />
              ) : (
                <AiOutlineAudioMuted size={22} />
              )}
            </div>
          </div>
        </div>
      </div>
      <MyModal
        showModal={modalPopConfirExit}
        closeModal={setModalPopConfirExit}
      >
        <div className="my-5">
          <h3 className="text-center">{t("eConsult.leavecall")}</h3>
          {user?.type === "writer" && (
            <div className="flex justify-center gap-5 mt-7">
              <input
                type="checkbox"
                name="setPatientAbsent"
                onChange={() => setPatientAbsent((prev) => !prev)}
                checked={patientAbsent}
              />{" "}
              <span>{t("eConsult.absParticipent")}</span>
            </div>
          )}
          <div className="flex justify-center gap-10 mt-7 items-center ">
            <Button
              status="secondary"
              className="px-4 py-1 h-10 flex items-center justify-center "
              width="120px"
              onClick={() => setModalPopConfirExit(false)}
            >
              <span className="mb-0 pb-0">{t("eConsult.cancel")} </span>
            </Button>
            <Button
              status="secondary"
              className="px-4 py-1 h-10 flex items-center justify-center "
              width="120px"
              onClick={async () => {
                if (user?.type === "writer") {
                  await updateEconsultByWriter(eConsultId, {
                    status: patientAbsent ? "patientAbsent" : "realised",
                  });
                }

                window.location.replace(`/eConsult/${studyId}`);
              }}
            >
              <span className="mb-0 pb-0">{t("eConsult.leave")} </span>
            </Button>
          </div>
        </div>
      </MyModal>
    </div>
  );
};

export default Room;
