import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { addWriterMass } from "../../../../api/writer";
type FormValues = {
  emails: string[];
};

const initialValues: FormValues = {
  emails: [
    "nole.anis+000071@gmail.com",
    "nole.anis+000072@gmail.com",
    "nole.anis+000073@gmail.com",
    "nole.anis+000074@gmail.com",
    "nole.anis+000075@gmail.com",
    "nole.anis+000076@gmail.com",
    "nole.anis+000077@gmail.com",
  ],
};

const validationSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.string().email("Invalid email address").required("Email is required")
  ),
});

const MassiveInvitationForm = ({ studyId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const handleSubmit = async (values: FormValues) => {
    console.log("🚀 ~ file: index.tsx:31 ~ handleSubmit ~ values:", values);
    setIsSubmitting(true);
    // make api call to submit the form
    const res = await addWriterMass(values,studyId)
    console.log("🚀 ~ file: index.tsx:35 ~ handleSubmit ~ res:", res)
  };

  return (
    <div className="p-6">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="emails">
              {({ push, remove }) => (
                <>
                  {values.emails.map((email, index) => (
                    <div key={index} className="flex items-center space-x-4">
                      <Field
                        type="email"
                        name={`emails.${index}`}
                        placeholder="Email"
                        className="border rounded px-4 py-2 w-full"
                      />
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => push("")}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Email
                  </button>
                </>
              )}
            </FieldArray>
            <div className="mt-4">
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
              <button
                type="button"
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-4"
                onClick={() => {}}
                disabled={isSubmitting}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MassiveInvitationForm;
