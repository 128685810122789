import { Redirect, RouteProps } from "react-router";
import DashboardARC from "../pages/DashboardARC";
import ConfigStudy from "../pages/StudyConfig";
import MyParticipantForms from "../pages/MyParticipant/ParticipantForms";
import MyParticipant from "../pages/MyParticipant";
import Profile from "../pages/Profile";

import Query from "../pages/Query";
import FirstLogin from "../pages/FirstLogin";
import Dragable from "../pages/DragableInterface";
import Events from "../pages/Events";
import Exports from "../pages/Exports";
import Statistics from "../pages/Statistics";
import MyEPRO from "../pages/MyEPRO";

const arcRoutes: RouteProps[] = [
  {
    path: "/",
    component: DashboardARC,
    exact: true,
  },
  {
    path: "/study/:studyId/:surveryId",
    component: Dragable,
    exact: true,
  },
  {
    path: "/study/:studyId",
    component: ConfigStudy,
    exact: true,
  },
  {
    path: "/events/:studyId",
    component: Events,
    exact: true,
  },
  {
    path: "/participant/:studyId",
    component: MyParticipant,
    exact: true,
  },
  {
    path: "/participant/:studyId/:participantId",
    component: MyParticipantForms,
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/eproForms/:studyId",
    component: MyEPRO,
    exact: true,
  },
  {
    path: "/exports",
    component: Exports,
    exact: true,
  },

  {
    path: "/query/:studyId",
    component: Query,
    exact: true,
  },
  {
    path: "/firstLogin",
    component: FirstLogin,
    exact: true,
  },
  {
    path: "/dashboard/:studyId",
    component: Statistics,
    exact: true,
  },
];

arcRoutes.push({ render: () => <Redirect to="/" /> });

export default arcRoutes;
