export const SideBar = {
  fr: {
    title: "Études",
    team: "CRO",
    template: "Modèles",
    monCompte: "Mon compte",
    ePro: "Formulaires ePRO",
    exports: "Exports",
    exportsList: "Liste des exports",
    signout: "Se déconnecter",
    Dashboard: "Tableau de bord",
    signoutMessage: "Souhaitez-vous vous déconnecter",
    confirm: "Valider",
    Cancel: "Annuler",
    Centre: "Centres d'investigation",
    Role: "Profils",
    Membres: "Équipe",
    rand: "Randomisation",
    VisitezFamille: "Visites & Formulaires",
    Participants: "Participants",
    Queries: "Requêtes",
    EproForms: "Formulaires ePRO",
    studyConfig: "Configuration de l'étude",
    Investigateur: "Investigateurs",
    AddUser: "+ Utilisateur",
    AddInves: "+ Investigateur",
    NoinesvtagotrFound: "Aucun investigateur trouvé",
    NoMemberFound: "Aucun membre trouvé",
    NoinvitationFound: "Aucune invitation trouvée",
    lastName: "Nom",
    Add: "Ajouter",
    MemberToEquipe: "Associez les membres de votre équipe à l'étude",
    SponsorToEquipe: "Impliquez votre sponsor dans l'étude",
    TotalRes: "résultats trouvés",
    InvesEtude: "Faire participer des investigateurs existants à l'étude.",
    ExistInves: "Liste des investigateurs existants",
    CentreList: "Liste des centres",
    RoleList: "Liste des profils",
    ActiveStudy: "études actives",
    Archive: "études archivées",
    FilterStudy: "Filtrer par étude",
    Back: "Retour",
    genralSetup: "Paramétrage",
    eConsult:"e-Consultation"
  },
  en: {
    genralSetup: "General setup",
    Back: "Back",
    title: "Studies",
    ePro: "ePRO Forms",
    team: "CRO",
    Dashboard: "Dashboard",
    exports: "Exports",
    exportsList: "Exports list",
    monCompte: "My account",
    signout: "Sign out",
    signoutMessage: "Would you like to disconnect",
    confirm: "Confirm",
    Cancel: "Cancel",
    Centre: "Investigation centers",
    template: "Templates",
    Role: "Profiles",
    Membres: "Team",
    VisitezFamille: "Visits & Forms",
    Participants: "Participants",
    Queries: "Queries",
    EproForms: "ePRO Forms",
    studyConfig: "Study configuration",
    Investigateur: "Investigators",
    AddUser: "+ User",
    rand: "Randomization",
    AddInves: "+ Investigator",
    NoinesvtagotrFound: "No investigator found",
    NoinvitationFound: "No invitation found",
    NoMemberFound: "No members found",
    lastName: "Last name",
    Add: "Add",
    MemberToEquipe: "Involve your team members in the study",
    SponsorToEquipe: "Involve your Sponsor in the study",
    TotalRes: "results found",
    InvesEtude: "Involve existing investigators in the study.",
    ExistInves: "List of existing investigators",
    CentreList: "List of centers",
    RoleList: "List of roles",
    ActiveStudy: "Active studies",
    Archive: "Archived studies",
    FilterStudy: "Filter by study",
    eConsult:"e-Consultation"
  },
};
