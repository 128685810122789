const obj = {
  OuiNon: ["Oui", "Non"],
  NormalAnormal: ["Normal", "Anormal"],
  positifNegative: ["Positif", "Négatif"],
  positifNegativeWithNA: ["Positif", "Négatif", "N/A"],
  gardes: ["Grade I", "Grade II", "Grade III"],
  scores: ["Score 0-1", "Score 2", "Score 3"],
  Peau: [
    "rougeâtre",
    "peau d’orange",
    " plis",
    "épaississement",
    "croutes",
    "rétraction",
  ],
  T: ["T1", "T2", "T3", "T4"],
  N: ["N0", "Nx", "N1", "N2", "N3"],
  M: ["M0", "M1"],
  presenceAbsence: ["Présence", "Absence"],
  typeChirg: [
    "Mammectomie",
    "Tumorectomie",
    "Curage axillaire",
    "Ganglion sentinelle",
  ],
  indesirable: [
    "Hautement probable",
    "Possible",
    "Sans relation",
    "Probable",
    "Douteux",
    "Non évaluable",
  ],
  BleedingComplications: [
    "no bleeding complications",
    "minor bleeding complications e.g. nose bleeds (BARC 1)",
    "overt bleeding e.g. hemorrhage access site bleeding requiring non-surgical intervention (BARC2)",
    "overt bleeding plus hemoglobin drop of 3 to < 5 g/dL (BARC 3a)",
    "overt bleeding plus hemoglobin drop ≥5 g/dL (BARC 3b)",
    "intracranial hemorrhage (BARC 3c)",
    "CABG-related bleeding (BARC 4)",
    "fatal bleeding (BARC 5a and 5b)",
  ],
  yesNo: ["Yes", "No"],
  DAPTyesNo: ["Yes, ≤6 months administration", "Yes", "No"],
  CCSClassification: [
    "Class I: No angina with ordinary physical activity. Strenuous activity may cause symptoms.",
    "Class II: Angina causes slight limitation on ordinary physical activity.",
    "Class III: Angina causes marked limitation on ordinary physical activity.",
    "Class IV: Angina occurs with any physical activity and may be present at rest.",
  ],
  followUpTypes: [
    "telephone interview",
    "cathlab visit",
    "hospital visit",
    "not done",
  ],
  months: [
    "1 month",
    "2 months",
    "3 months",
    "4 months",
    "5 months",
    "6 to 9 months",
    "10 to 12 months",
  ],
  patientInterpt: [
    "patient decided him/herself",
    "on physician's advice",
    "due to complications",
  ],
  TargetLesion: [
    "yes acute thrombosis in treated lesion",
    "yes subacute thrombosis in treated lesion",
    "no thrombosis",
  ],
  clopidogrel: [
    "Yes result: non-responder",
    "Yes result: partial responder",
    "No",
  ],
  myocardial: ["Yes  STEMI", "Yes  NSTEMI ", "No"],
  kindOfTest: ["antigen", "PCR (polymerase chain reaction)", "antibody"],
};



export default obj;
