import { Box, ChakraProvider, extendTheme, Heading } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { getSurveyById, updateForm } from "../../api/survey";
import Icon from "../../components/Icon";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import FormBuilder from "../../components/SurveyGenerator/FormBuilder";
import { default as enMsg } from "../../config/constants/locales/en-us.json";
import { default as frMsg } from "../../config/constants/locales/fr-fr.json";
import history from "../../history";
const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    blue: "#4565f6",
  },
});

function Dragable() {
  const params: any = useParams();
  const { surveryId, studyId } = params;
  const { t } = useTranslation();
  const [lang, setLang] = useState("en");
  const [intialForm, setIntialForm] = useState() as any;
  const [loading, setLoading] = useState(true);
  const location = useLocation() as any;
  const translation = location?.state?.translation;
  const getFormData = async () => {
    try {
      const updatedSurvery = await getSurveyById(surveryId);

      if (updatedSurvery) {
        if (updatedSurvery.survey) {
          setIntialForm(updatedSurvery.survey);
          setLoading(false);
        }
      } else setLoading(false);
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  const updateFormData = async (data) => {
    try {
      const updatedSurvery = await updateForm(data, surveryId);

      if (updatedSurvery) {
        if (updatedSurvery.updatedSurvey) {
          setIntialForm(updatedSurvery.updatedSurvey);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);

      Promise.reject(error);
    }
  };
  const onSaveFormData = async (data) => {
    try {
      const updatedSurvery = await updateForm(data, surveryId);

      if (updatedSurvery) {
        setIntialForm(updatedSurvery.updatedSurvey);
        makeToast("success", t("missingWording.dit1"));

        history.push(`/study/${studyId}`);
      } else {
        makeToast("error", t("missingWording.pht2"));
      }
    } catch (error) {
      console.error(error);
      makeToast("error", t("missingWording.pht2"));
      Promise.reject(error);
    }
  };
  useEffect(() => {
    getFormData();
    let local = localStorage.getItem("i18nextLng");

    if (local) {
      setLang(local);
    }
  }, []);

  return !intialForm && loading ? (
    <Spinner />
  ) : (
    <ChakraProvider theme={theme}>
      <div className="App">
        <IntlProvider
          locale={lang}
          defaultLocale="en"
          messages={lang === "fr" ? frMsg : enMsg}
        >
          <div className="icon cursor-pointer flex flex-row ">
            <Icon
              name="direction-gauche"
              width={50}
              height={50}
              isIcon={true}
              onClick={() => history.push(`/study/${studyId}`)}
            />

            <Box mb="6">
              <Heading size="md" alignItems="center">
                {t("missingWording.visit")} : {intialForm?.name}
              </Heading>
              <span className="cursor-pointer p-1 bg-gray-200 mt-1 rounded-lg text-xs">
                {t("missingWording.MAJle")}{" "}
                {moment(intialForm?.updatedAt).format("DD-MM-YYYY") +
                  t("DashboardCro.at") +
                  moment(intialForm?.updatedAt).format("HH:mm")}
              </span>
            </Box>
          </div>
          <FormBuilder
            fields={intialForm ? intialForm.sections[0].task_data : []}
            formSetup={
              intialForm
                ? intialForm
                : {
                    sections: [
                      { task_data: [], stepNbr: 0, sectionName: "Page 1" },
                    ],
                    name: "",
                    description: "",
                  }
            }
            onSave={onSaveFormData}
            onUpdate={updateFormData}
            simulation={false}
            fieldProps={[]}
            formProps={{
              labelAlign: "left",
              colon: true,
              requiredMark: true,
              labelCol: {
                span: 3,
              },
            }}
            showPreviewTab={true}
            surveyId={surveryId}
            translation={translation}
          />
        </IntlProvider>
      </div>
    </ChakraProvider>
  );
}
export default Dragable;
