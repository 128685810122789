import { useEffect, useState } from "react";
import { userAtom } from "../../state/auth";
import { useAtom } from "jotai";
import makeToast from "../../components/Snackbar";
import history from "../../history";
const WatingPage = () => {
const [user, setUser] = useAtom(userAtom);
useEffect(()=>{
    makeToast('warning','Your account is not yet validated by the sponsor of the study.')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    history.push('/login')
},[])
  return (
    <div className="flex h-screen w-full justify-center items-center">
  <div className="p-8 bg-white rounded-lg w-3/4">
    <h3>Your account is not yet validated by the sponsor of the study.</h3>
  </div>
    </div>
  );
};

export default WatingPage;
