import { useAtom } from "jotai";
import { Socket, io } from "socket.io-client";
import { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useIntl } from "react-intl";
import { BASE_URL } from "../../../../config/api";
import {
  SortableContainer,
  SortableElement as SortableElementx,
} from "react-sortable-hoc";
import { elementAtom } from "../../../../state/FormGeneratorState/elmentAtom";
import { elementAtomData } from "../../../../state/FormGeneratorState/elmentAtomdata";
import PropertyEditor from "../PropertyEditor";
import { Formiz } from "../StepperWrapper/Formiz";
import ItemTypes from "../ToolBar/ItemTypes";
import SortableElement from "../ToolBar/SortableElement";
import { driveJsEventListnerAtom } from "../../../../state/tutorialMode";
import makeToast from "../../../Snackbar";
import { socketAtom } from "../../../../state/socket";

export const DropZone = ({
  elements,
  onUpdate,
  formProps,
  surveyId,
  translation,
  ...props
}) => {
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [items, setItems] = useState(elements);
  const moveArrayItem = (array: any[], from: number, to: number) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
    return array;
  };
  const [, setDriveJsEventListner] = useAtom(driveJsEventListnerAtom) as any;
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    let sortedArray = moveArrayItem(items, oldIndex, newIndex);
    let outputArray: any[] = [];
    setItems((items) => sortedArray);
    onUpdate?.(sortedArray);
  };
  const [visible, setVisible] = useState(false);
  const [currentElement, setCurrentElement]: any = useState(null);
  const [, setAtomElment] = useAtom(elementAtom);
  const [, setAtomElmentData] = useAtom(elementAtomData);

  const [currentIndex, setCurrentIndex] = useState(null);
  const intl = useIntl();
  const [{ canDrop, isOver, draggedItem }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => item,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggedItem: monitor.getDropResult(),
    }),
  }));
  const editHandler = (element, index) => {
    // console.log("🚀 ~ file: DropZone.tsx:47 ~ editHandler ~ index:", index);
    setCurrentElement(element);
    setCurrentIndex(index);
    setAtomElment(element.id);
    setAtomElmentData(element);
    setVisible(true);
  };

  useEffect(() => {
    const fc = props.formConfig.sections[props.stepNbr].task_data;

    if (draggedItem && elements && onUpdate)
      if (draggedItem.id) {
        onUpdate([...fc, draggedItem]);
        editHandler(draggedItem, elements.length);
        setCurrentElement(draggedItem);
        setAtomElment(draggedItem.id);
        setAtomElmentData(draggedItem);
      }
  }, [isOver]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      let elems = [...elements];
      [elems[dragIndex], elems[hoverIndex]] = [
        elems[hoverIndex],
        elems[dragIndex],
      ];
      onUpdate(elems);
    },
    [elements, onUpdate]
  );
  useEffect(() => {
    setItems(elements);
  }, [elements]);

  const submitHandler = (values) => {
    const element = { ...currentElement, ...values };
    setItems(items);
    onUpdate?.(items.map((e, i) => (e.id !== element.id ? e : element)));
  };

  const isActive = canDrop && isOver;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "#fafafa";
  } else if (canDrop) {
    backgroundColor = "#fafafa";
  }
  const deleteHandler = (element, index) => {
    onUpdate?.(elements.filter((element, i) => index !== i));
  };
  const SortableItemx = SortableElementx(
    ({ element, index, onMove, onEdit, onDelete, readOnly }) => (
      <SortableElement
        key={element.id}
        index={index}
        element={element}
        onEdit={() => {
          setCurrentElement(element);
          setCurrentIndex(index);
          setAtomElment(element.id);
          setAtomElmentData(element);
          setVisible(true);
        }}
        onDelete={() => {
          setItems((items) => items.filter((el, i) => element.id !== el.id));
          onUpdate?.(items.filter((el, i) => element.id !== el.id));
        }}
        readOnly={true}
        surveyId={surveyId}
        translation={translation}
      />
    )
  );
  const SortableContainerx = SortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  useEffect(() => {
    if (!socket) {
      const mySocket: Socket = io(BASE_URL, {
        // forceNew: true,
        reconnectionAttempts: 100,
        timeout: 10000,
        transports: ["websocket"],
        auth: {
          token: localStorage.getItem("token"),
        },
      });

      setSocket(mySocket);
    } else {
      if (!socket.connected) socket.connect();
      if (
        items.some((el) => el.type === "Header") &&
        localStorage.getItem("currentIndex") === "12"
      ) {
        socket.emit("tutoStart", {});
        setDriveJsEventListner("DRAG_HEADER");
      } else if (
        items.some((el) => el.type === "TextInput") &&
        localStorage.getItem("currentIndex") === "16"
      ) {
        socket.emit("tutoStart", {});
        setDriveJsEventListner("DRAG_TEXT_INPUT");
      }
      // else if (items.some((el) => el.type === "NumberInput")) {
      //   socket.emit("tutoStart", {});
      //   setDriveJsEventListner("DRAG_NUMBER_INPUT");
      // } else if (items.some((el) => el.type === "CalculatedInput")) {
      //   socket.emit("tutoStart", {});
      //   setDriveJsEventListner("DRAG_CACULATED_INPUT");
      // } else if (items.some((el) => el.type === "RepeaterField")) {
      //   socket.emit("tutoStart", {});
      //   setDriveJsEventListner("DRAG_REPEATER_FIELD");
      // }

      socket.on("tutoError", (res) => {
        makeToast("warning", "socket error");
        return;
      });

      return () => {
        if (socket) {
          socket.disconnect();
          socket.removeAllListeners("participants.add.success");
          socket.close();
          console.log("[DEBUG] Socket was closed!");
        }
      };
    }
  }, [socket, items]);

  return (
    <div
      ref={drop}
      className="w-full  rounded-lg py-2 px-2 h-screen border-t border-b border-r  overflow-y-auto   mb-6"
    >
      <Formiz>
        <SortableContainerx lockAxis="y" useDragHandle onSortEnd={onSortEnd}>
          {items.map((element, i) => (
            <SortableItemx
              index={i}
              key={element.id}
              element={element}
              onMove={moveCard}
              onEdit={editHandler}
              onDelete={deleteHandler}
              readOnly={true}
            />
          ))}
        </SortableContainerx>
        {elements.length === 0 && (
          <div
            id="zoneOfDrag"
            className="border-dashed border-2  p-8 rounded-lg "
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              borderColor: "#4565f6",
              backgroundColor: "#f5f5f9",
            }}
          >
            {intl.formatMessage({ id: "dndMsg" })}
          </div>
        )}
      </Formiz>
      <PropertyEditor
        intl={intl}
        show={visible}
        element={currentElement}
        onClose={(e) => setVisible(false)}
        onSubmit={submitHandler}
        elements={elements}
        formSections={props.formConfig.sections}
      ></PropertyEditor>
    </div>
  );
};
