import {
  DELETE_SURVEY_URL,
  DOCTOR_ASSIGN_SURVEY,
  DOCTOR_CREATE_SURVEY,
  DOCTOR_UPDATE_SURVEY,
  DUPLICATE_SURVEYS_URL,
  EVENT_DATA_URL,
  FORM_DATA_URL,
  SAVE_SURVEY_AS_TEMPLATE,
  GET_ALL_SURVEY_DOCTOR,
  GET_SURVEY,
  GET_SURVEY_BY_PATIENTID,
  UPDATE_SURVEY_ORDER_URL,
  UPDATE_SURVEY_URL,
  GET_TEMPLATE_SURVEYS_URL,
} from "../config/api";
import { get, post } from "../helper/apiMethods";

export const createForm = async (values) => {
  try {
    const res = await post(DOCTOR_CREATE_SURVEY, values);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const duplicateSurvey = async (studyId, payload) => {
  try {
    const res = await post(DUPLICATE_SURVEYS_URL + `/${studyId}`, payload);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const saveSurveyAsTemplate = async (payload) => {
  try {
    const res = await post(SAVE_SURVEY_AS_TEMPLATE, payload);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateForm = async (values, formId) => {
  try {
    const res = await post(`${DOCTOR_UPDATE_SURVEY}/${formId}`, values);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateFormInformation = async (values, formId) => {
  try {
    const res = await post(`${UPDATE_SURVEY_URL}/${formId}`, values);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateEproFormInformation = async (values, formId) => {
  try {
    const res = await post(`${UPDATE_SURVEY_URL}/${formId}`, values);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateSurveyOder = async (values) => {
  try {
    const res = await post(`${UPDATE_SURVEY_ORDER_URL}`, values);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateSurveySectionOder = async (values) => {
  try {
    const res = await post(`${UPDATE_SURVEY_ORDER_URL}`, values);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteSurveyById = async (formId) => {
  try {
    const res = await get(`${DELETE_SURVEY_URL}/${formId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getSurveyById = async (formId) => {
  try {
    const res = await get(`${GET_SURVEY}/${formId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSurveyByPatientId = async (payload) => {
  try {
    const res = await post(GET_SURVEY_BY_PATIENTID, payload);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllSurveyDoctor = async () => {
  try {
    const res = await get(GET_ALL_SURVEY_DOCTOR);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const doctorAssignSurvey = async (payload) => {
  try {
    const res = await post(`${DOCTOR_ASSIGN_SURVEY}`, payload);
    return res && res.data ? res.data : null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormadataById = async (formDataId) => {
  try {
    const res = await get(`${FORM_DATA_URL}${formDataId}`);
    return res.formData;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getEventadataById = async (formDataId) => {
  try {
    const res = await get(`${EVENT_DATA_URL}${formDataId}`);
    return res.formData;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTemplateForms = async () => {
  try {
    const res = await get(GET_TEMPLATE_SURVEYS_URL);
    return res?.studie;
  } catch (error) {
    return Promise.reject(error);
  }
};
