import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiDownload, BiX } from "react-icons/bi";
import {
  RiUserFollowLine,
  RiUserSharedLine,
  RiUserStarLine,
  RiUserUnfollowLine,
} from "react-icons/ri";
import { useParams } from "react-router-dom";
import { downloadParticipantPdf } from "../../api/study";
import Tooltip from "../../components/toolTip";
import history from "../../history";
import { participantAtom } from "../../state/participant";
import { IParticipant } from "../../types/IParticipant";

type Props = {
  data: IParticipant[] | any;
  userType: string;
  currentPage: any;
  setCurrentPage: any;
  totalPages: any;
  showName?: boolean;
  hasCenterAcess?: boolean;
  isResponsable?: boolean;
  loading?: boolean;
  nbrOfSkeletonsToDisplay?: number;
};
const Table: React.FC<Props | any> = ({
  data,
  userType,
  showName,
  hasCenterAcess,
  isResponsable,
  setTextSearch,
  loading,
  nbrOfSkeletonsToDisplay,
}) => {
  const { t } = useTranslation() as any;
  const params = useParams() as any;
  const [, setParticipant] = useAtom(participantAtom);
  const [searchValue, setSearchValue] = useState("");
  const { studyId } = params;
  const typingTimeoutRef = useRef(null) as any;

  const _handleDownloadPdf = async (participantId) => {
    try {
      const blob = await downloadParticipantPdf(participantId);
      const outputFilename = `${Date.now()}.pdf`;
      const url = URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(
        "🚀 ~ file: index.tsx:73 ~ const_handleDownloadExcel= ~ error",
        error
      );
    }
  };

  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);

    setSearchValue(value);
    // Set a timeout of 500 milliseconds before triggering the API call
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);
  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };

  const _calculParticipantProgress = (p: any) => {
    return p.progress ? Math.round(parseFloat(p.progress)) : 0;
  };
  const rederStatusIcon = (status: String, progress: any) => {
    let statusList = {
      PROGRESS: t("missingWording.Encours"),
      EXIT: t("missingWording.Exclu"),
      CREATED: t("missingWording.Inscrit"),
    };
    switch (status) {
      case "VALID":
        return (
          <Tooltip tooltipText={t("missingWording.acheved")} position={"top"}>
            <RiUserFollowLine
              size={24}
              className="text-green-400 justify-center items-center"
            />
          </Tooltip>
        );
      case "PROGRESS":
        return progress === 100 ? (
          <Tooltip tooltipText={t("missingWording.acheved")} position={"top"}>
            <RiUserFollowLine
              size={24}
              className="text-green-400 justify-center items-center"
            />
          </Tooltip>
        ) : (
          <Tooltip tooltipText={statusList[`${status}`]} position={"top"}>
            <RiUserSharedLine
              size={24}
              className="text-blue-600 justify-center items-center"
            />
          </Tooltip>
        );

      case "EXIT":
        return (
          <Tooltip tooltipText={statusList[`${status}`]} position={"top"}>
            <RiUserUnfollowLine
              size={24}
              className="text-red-500 justify-center items-center"
            />
          </Tooltip>
        );

      case "CREATED":
        return (
          <Tooltip tooltipText={statusList[`${status}`]} position={"top"}>
            <RiUserStarLine
              size={24}
              className="text-yellow-400 justify-center items-center"
            />
          </Tooltip>
        );
    }
  };

  const renderLoadingSkeletons = () => {
    if (
      typeof nbrOfSkeletonsToDisplay !== "number" ||
      nbrOfSkeletonsToDisplay <= 0
    )
      return [];
    return Array(nbrOfSkeletonsToDisplay).fill(
      <tr>
        <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
          <div className="skeleton-line"></div>
        </td>
        {userType === "writer" && showName && (
          <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
            <div className="skeleton-line"></div>
          </td>
        )}

        <td className="md:px-6 xs:px-0.5 py-4 md:w-full md:text-sm xs:text-xs text-white  text-center flex items-center">
          <div className="skeleton-line"></div>
        </td>
        <td className="md:px-6 xs:px-1 py-4 md:text-sm xs:text-xs text-gray-800 text-center">
          <div className="skeleton-line"></div>
        </td>
        <td className="md:px-6 xs:px-1 py-4 md:text-sm xs:text-xs text-gray-800 text-center">
          <div className="skeleton-line"></div>
        </td>

        {userType !== "writer" && (
          <td className="flex justify-center">
            <div className="skeleton-line"></div>
          </td>
        )}
        {userType === "writer" && isResponsable && (
          <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
            <div className="skeleton-line"></div>
          </td>
        )}
      </tr>
    );
  };

  return (
    <div className="flex flex-col xs:text-xs sm:text-sm md:text-sm lg:text-md mt-4">
      <div className="overflow-x-auto">
        <div className="flex justify-between py-3 pl-2">
          <div className="relative max-w-xs">
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              onChange={onSearch}
              value={searchValue}
              className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
              placeholder={t("Participant.inpMs")}
              style={{ border: "none" }}
              onKeyDown={handleKeyDown}
            />

            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 xs:text-xs sm:text-sm md:text-sm lg:text-md">
                <tr>
                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.IDpublic")}
                  </th>
                  {userType === "writer" && showName && (
                    <th
                      scope="col"
                      className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                    >
                      {t("missingWording.name")}
                    </th>
                  )}

                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.Progression")}
                  </th>
                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("missingWording.Grp")}
                  </th>
                  <th
                    scope="col"
                    className="md:px-6 xs:px-1 py-3 text-xs font-bold  text-gray-500 uppercase "
                  >
                    {t("Participant.LastModif")}
                  </th>

                  {userType !== "writer" && (
                    <th
                      scope="col"
                      className="md:px-6 xs:px-0  py-3 text-xs font-bold text-gray-500 uppercase  "
                    >
                      {t("Participant.Stats")}
                    </th>
                  )}
                  {userType === "writer" && isResponsable && (
                    <th
                      scope="col"
                      className="md:px-6 xs:px-0  py-3 text-xs font-bold text-gray-500 uppercase  "
                    >
                      {t("Participant.createdBy")}
                    </th>
                  )}
                </tr>
              </thead>
              {loading ? (
                <></>
              ) : (
                <>
                  {data.length > 0 && (
                    <tbody className="divide-y divide-gray-200">
                      {renderLoadingSkeletons()}
                      {(typeof nbrOfSkeletonsToDisplay !== "number" ||
                      nbrOfSkeletonsToDisplay <= 0 ||
                      data.length + nbrOfSkeletonsToDisplay <= 10
                        ? data
                        : data.slice(0, data.length - nbrOfSkeletonsToDisplay)
                      ).map((p) => {
                        return (
                          <tr
                            className="cursor-pointer participantExple"
                            onClick={() => {
                              setParticipant(p);

                              userType === "cro"
                                ? history.push(
                                    `/participant/${studyId}/${p._id}`
                                  )
                                : history.push(
                                    `/participant/${studyId}/${p._id}`
                                  );
                            }}
                          >
                            <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                              {p.publicId}
                            </td>
                            {userType === "writer" && showName && (
                              <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                                {!p.firstName && !p.lastName
                                  ? "N/A"
                                  : p.firstName && p.firstName + " "}
                                {p.lastName && p.lastName}
                              </td>
                            )}

                            <td className="md:px-6 xs:px-0.5 py-4 md:w-full md:text-sm xs:text-xs text-white  text-center flex items-center">
                              <p className="text-center  ">
                                {" "}
                                {rederStatusIcon(
                                  p.status,
                                  _calculParticipantProgress(p)
                                )}
                              </p>
                              <span className=" z-20 text-xs text-black p-1">
                                {" "}
                                {_calculParticipantProgress(p) + "%"}
                              </span>
                              <div className="w-full md:block xs:hidden bg-gray-300 rounded-full dark:bg-gray-700  h-2   ">
                                <div
                                  className={
                                    " text-xs font-medium text-white text-center p-0.5 leading-none h-2 rounded-full  " +
                                    (_calculParticipantProgress(p) > 0
                                      ? "bg-blue-600 relative "
                                      : " relative")
                                  }
                                  style={{
                                    width: `${
                                      _calculParticipantProgress(p) > 0
                                        ? _calculParticipantProgress(p)
                                        : 0
                                    }%`,
                                  }}
                                >
                                  {p.status === "EXIT" && (
                                    <BiX
                                      size={16}
                                      className=" text-white font-bold absolute rounded-full bg-gray-300  -top-1 -right-2"
                                    />
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="md:px-6 xs:px-1 py-4 md:text-sm xs:text-xs text-gray-800 text-center">
                              {p?.currentGroup && p.currentGroup?.groupName
                                ? p.currentGroup.groupName
                                : t("missingWording.notAffected")}
                            </td>
                            <td className="md:px-6 xs:px-1 py-4 md:text-sm xs:text-xs text-gray-800 text-center">
                              {moment(p.updatedAt).format("DD-MM-YYYY") +
                                t("DashboardCro.at") +
                                moment(p.updatedAt).format("HH:mm")}
                            </td>

                            {userType !== "writer" && (
                              <td className="flex justify-center">
                                <BiDownload
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    _handleDownloadPdf(p._id);
                                  }}
                                />
                              </td>
                            )}
                            {userType === "writer" && isResponsable && (
                              <td className="md:px-6 xs:px-0.5 py-4   md:text-sm xs:text-xs font-medium text-gray-800 whitespace-nowrap text-center">
                                {p.createdBy.firstName +
                                  " " +
                                  p.createdBy.lastName}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </>
              )}
            </table>

            {(data.length === 0 || !data || loading) && (
              <div className="flex justify-center items-center w-full mt-8 mb-8">
                {loading ? (
                  <p className="flexCenter  menuInput">
                    {t("missingWording.Loading")}...
                  </p>
                ) : (
                  <p className="menuInput">{t("Participant.aucunPart")}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
