import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Pie, Scatter } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { updateQuery } from "../../api/queries";
import { getAllDashboardData } from "../../api/study";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import Stat from "../../assets/statistics.png";
import {
  default as ButtonUI
} from "../../components/ButtonUI";
import Modal from "../../components/ModalUI";
import SelectComp from "../../components/Select";
import Spinner from "../../components/Spinner";
import TestModeTogButton from "../../components/TestModeShared";
import { generatePastelColor } from "../../helper";
import { ActiveRouteAtom } from "../../state/route";
import { testModeAtom } from "../../state/testModeAtom";
import DynamicCard from "./DynamicCard";
import ModalQuery from "./ModalQuery";
import ParticipantList from "./ParticipantList";
import SecondCards from "./SecondCards";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  const params = useParams() as any;
  const { studyId } = params;
  const [selectedOptionSecond, setSelectedOptionSecond] = useState("");
  const [selectedOptionFirst, setSelectedOptionFirst] = useState("");
  const [, setPath] = useAtom(ActiveRouteAtom);
  const [stats, setStats] = useState({}) as any;
  const [optionStat, setOptionStat]: any[] = useState([]);
  const { t } = useTranslation() as any;
  const [selctedQuery, setSelectedQuery] = useState() as any;
  const [selectedStatusQuery, setSelectedStatusQuery] = useState() as any;
  const [textMsg, setTextMsg] = useState() as any;
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null) as any;
  const [testMode,] = useAtom(testModeAtom);
  useEffect(() => {
    setIsLoading(true);
    getAllDashboardData(studyId, testMode).then((result) => {
      if (result && result?.status === "success") {
        setStats(result?.response);

        const dropDownOptions = Object.entries(
          result?.response?.statistics?.availableValues || []
        ).map(([label, type]) => {
          return { label, value: `${label}||${type}` };
        });

        setOptionStat(dropDownOptions);
        if (dropDownOptions?.length > 0) {
          setSelectedOptionFirst(dropDownOptions[0]?.value);
        }
        setIsLoading(false);
      }
    });
  }, [studyId, testMode]);

  useEffect(() => {
    setPath(`/dashboard/${studyId}`);
  }, [setPath, studyId]);

  useEffect(() => {
    if (
      selectedOptionFirst &&
      selectedOptionFirst.length > 0 &&
      stats?.statistics?.dataSets &&
      stats?.statistics?.dataSets.length > 0
    ) {
      const [firstOptionValue, firstOptionType] =
        selectedOptionFirst.split("||");
      const [secondOptionValue, secondOptionType] =
        selectedOptionSecond && selectedOptionSecond.length > 0
          ? selectedOptionSecond.split("||")
          : [null, null];

      if (firstOptionType === "NumberInput") {
        const data: {
          x: string | boolean | number | null;
          y: string | boolean | number | null;
          participant: string;
        }[] = [];

        for (const item of stats.statistics.dataSets) {
          // labels.push(item.participant);
          data.push({
            x: item.stats[firstOptionValue]?.value,
            y: item.stats[
              secondOptionValue ? secondOptionValue : firstOptionValue
            ]?.value,
            participant: item.participant,
          });
        }

        setChartData({
          datasets: [
            {
              label: "Participant",
              data,
              backgroundColor: generatePastelColor(),
            },
          ],
        });
      } else {
        const labels: string[] = [];
        const data: number[] = [];
        const colors: string[] = [];

        for (const item of stats.statistics.dataSets) {
          const label = item.stats[firstOptionValue]?.value || "Non renseigné";
          const labelIndex = labels.indexOf(label);
          if (labelIndex === -1) {
            labels.push(label);
            data.push(1);
            colors.push(generatePastelColor());
          } else {
            data[labelIndex] = data[labelIndex] + 1;
          }
        }

        setChartData({
          labels: labels,
          datasets: [
            {
              data,
              backgroundColor: colors,
            },
          ],
        });
      }
    }
  }, [selectedOptionFirst, selectedOptionSecond, stats]);

  useEffect(() => {
    setSelectedStatusQuery(selctedQuery?.status);
    setTextMsg();
  }, [selctedQuery]);

  const updateQueryMessage = async (queryId, values) => {
    try {
      const res = await updateQuery(queryId, values);
      return res && res?.data ? res.data.queryUpdated : false;
    } catch (error) {
      return false;
    }
  };
  const commonChartOptions: any = {
    responsive: true,
    maintainAspectRatio: true,
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <header className="flex justify-between items-center w-full flex-wrap">
        <div className="title-container">
          <h1 className="pageTitle">{t("SideBar.Dashboard")}</h1>
        </div>
        <TestModeTogButton />
      </header>

      <div className="flex flex-wrap w-full ">
        <div className="mt-10 w-2/3 p-2 ">
          <div style={{ width: "97%" }} className=" ">
            <DynamicCard />

            <div className="mt-10 w-full ">
              {stats?.statistics && stats?.statistics?.dataSets?.length > 0 ? (
                <div className="bg-white shadow-lg rounded-lg  relative">
                  <div className="flex flex-row items-center justify-end pt-4 pr-4">
                    <h4 className="mr-4">
                      {t("missingWording.statVarFirst")}
                      {" : "}
                    </h4>
                    <SelectComp
                      value={selectedOptionFirst}
                      onChange={(e) => {
                        setSelectedOptionFirst(e.target.value);
                        if (e.target.value.split("||")[1] !== "NumberInput")
                          setSelectedOptionSecond("");
                      }}
                      options={optionStat}
                    />
                    <h4 className="ml-8 mr-4">
                      {t("missingWording.statVarSecond")}
                      {" : "}
                    </h4>
                    <SelectComp
                      value={selectedOptionSecond}
                      disabled={
                        selectedOptionFirst.split("||")[1] !== "NumberInput"
                      }
                      onChange={(e) => {
                        setSelectedOptionSecond(e.target.value);
                      }}
                      options={optionStat.filter(
                        (option) =>
                          option.value.split("||")[1] === "NumberInput"
                      )}
                    />
                  </div>

                  <div className="p-4 flex justify-center items-center">
                    {chartData &&
                    selectedOptionFirst.split("||")[1] === "NumberInput" ? (
                      <div
                        style={{
                          width: "85%",
                          height: "421px",
                        }}
                      >
                        <Scatter
                          data={chartData}
                          options={{
                            ...commonChartOptions,
                            plugins: {
                              title: {
                                display: false,
                              },
                              tooltip: {
                                callbacks: {
                                  label: function (context) {
                                    const data: any =
                                      context.dataset.data[context.dataIndex];
                                    return `${data?.participant} - ${
                                      selectedOptionFirst.split("||")[0]
                                    } : ${context.parsed.x}${
                                      selectedOptionSecond &&
                                      selectedOptionSecond.length > 0
                                        ? ` | ${
                                            selectedOptionSecond.split("||")[0]
                                          } : ${context.parsed.y}`
                                        : ""
                                    }`;
                                  },
                                },
                              },
                            },
                            scales: {
                              y: {
                                title: {
                                  font: { weight: "bold" },
                                  display: true,
                                  text: selectedOptionSecond.split("||")[0],
                                },
                                beginAtZero: true,
                              },
                              x: {
                                title: {
                                  font: { weight: "bold" },
                                  display: true,
                                  text: selectedOptionFirst.split("||")[0],
                                },
                                beginAtZero: true,
                              },
                            },
                          }}
                        />
                      </div>
                    ) : chartData ? (
                      <div
                        style={{
                          width: "421px",
                        }}
                      >
                        <Pie
                          data={chartData}
                          options={{
                            ...commonChartOptions,
                            plugins: {
                              title: {
                                display: false,
                              },
                              tooltip: {
                                callbacks: {
                                  label: function (context) {
                                    return context.label;
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="p-6 my-14 bg-white shadow-lg rounded-lg opacity-40 ">
                  <div className="text-center text-black text-xl">
                    {t("missingWording.emptyStats")}
                  </div>
                  <div
                    className="flex justify-center items-center"
                    style={{
                      height: "400px",
                    }}
                  >
                    <img
                      src={Stat}
                      style={{
                        width: "45%",
                        filter: "grayscale(70%)",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Participants and queries  */}
        <ParticipantList
          shouldUpdate={shouldUpdate}
          setSelectedQuery={setSelectedQuery}
        />
      </div>
      <SecondCards shouldUpdate={shouldUpdate} />

      {selctedQuery && (
        <Modal
          showModal={true}
          className=" md:px-10 xs:px-2 py-1 relative overflow-hidden"
          style={{ minWidth: "50%" }}
        >
          <ButtonUI
            status="icon-secondary"
            onClick={() => setSelectedQuery()}
            className="absolute right-2 top-2  cursor-pointer"
          >
            <Close />
          </ButtonUI>

          <ModalQuery
            selctedQuery={selctedQuery}
            setSelectedStatusQuery={setSelectedStatusQuery}
            selectedStatusQuery={selectedStatusQuery}
            textMsg={textMsg}
            setTextMsg={setTextMsg}
            setSelectedQuery={setSelectedQuery}
            updateQueryMessage={updateQueryMessage}
            setShouldUpdate={setShouldUpdate}
          />
        </Modal>
      )}
    </>
  );
};

export default Statistics;
