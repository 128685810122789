import * as Yup from "yup";
export const validation = (t:any)=>{
    return Yup.object({
        newPassword: Yup.string()
          .required(`${t("missingWording.flt5")}`)
          .min(8, `${t("missingWording.flt6")}`)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            `${t("missingWording.flt7")}`
          ),
        newPasswordConfirm: Yup.string()
          .required(`${t("missingWording.flt8")}`)
          .oneOf(
            [Yup.ref("newPassword"), null],
            `${t("missingWording.flt9")}`
          ),
      })
} 