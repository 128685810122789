import { ArrowRightIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/react";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { getEventadataById } from "../../api/survey";
import Spinner from "../../components/Spinner";
import {
  MultiStepsLayout,
  PageHeader
} from "../../components/SurveyGenerator/FormBuilder/Lyaout";
import StepperWrapper from "../../components/SurveyGenerator/FormBuilder/StepperWrapper";
import {
  Formiz,
  FormizStep
} from "../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import FormRenderer from "../../components/SurveyGenerator/FormRenderer";
import { default as enMsg } from "../../config/constants/locales/en-us.json";
import { default as frMsg } from "../../config/constants/locales/fr-fr.json";
import { resolver } from "../../helper/FormGeneratorHelper/conditionalLogic";
import { useForm } from "../../hooks/FormGeneratorHooks";
import { userAtom } from "../../state/auth";
import { User } from "../../types/user";
import { BASE_URL } from "../../config/api";
import { io, Socket } from "socket.io-client";
import { socketAtom } from "../../state/socket";
type Props = any;
const DisplayEvent: React.FC<Props> = ({
  onChange,
  onSubmit,
  formConfig,
  initialField,
  ...props
}) => {
  const [lang, setLang] = useState("fr");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const form = useForm();
  const [user] = useAtom<User | null>(userAtom);
  const [formData, setFormDataEvent] = useState(props.data);
 
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [connected, setConnected] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    setDataLoader(true);
    setTimeout(() => {
      setDataLoader(false);
    }, 500);
  }, [props.data._id, formData?._id]);

  const handleSubmit = (values) => {
    switch (user?.type) {
      case "writer":
        let res: any[] = formData?.sections;
        res.map((section) => {
          section.fields.map((field) => {
            if (values.hasOwnProperty(`${field.field_name}`))
              field.value = values[`${field.field_name}`];
            section.status = "VALID_BY_WRITER";

            if(field.type ==='RepeaterField'){
              field.value =JSON.stringify( values[`${field.field_name}`])
            }
          });
        });
        onSubmit(res);
        break;
      default:
        break;
    }
  };

  const fetchMyAPI = async () => {
    try {
      const res = await getEventadataById(props.data._id);
      if (res) {
        setFormDataEvent(res);
      } else return;
    } catch (error) {}
  };
  // useEffect(() => {
  //   // fetchMyAPI();
  //   props.setShouldUpdate((up) => !up);
  // }, [shouldUpdate, setShouldUpdate, props.data._id, formData?._id]);

  useEffect(() => {
    let local = localStorage.getItem("i18nextLng");
    if (local) {
      setLang(local);
    }
  }, []);
  useEffect(() => {
    const mySocket: Socket = io(BASE_URL, {
      forceNew: true,
      reconnectionAttempts: 100,
      timeout: 10000,
      transports: ["websocket"],
      auth: {
        token: localStorage.getItem("token"),
        tenantFormReact: localStorage.getItem("tenantId"),
      },
    });
    mySocket.emit('joinEvent', {
      eventId: `${props.data._id}`,
      userId: user?._id,
      tenantId: user?.tenantId,
      tenantFormReact: localStorage.getItem("tenantId"),
    });

    // Listen for 'formData' event from the backend and update the state
    mySocket.on("eventData", (formData) => {
      console.log("🚀 ~ mySocket formData:", formData);

      setFormDataEvent(formData);
    });

    mySocket.on("userCountUpdate", (count) => {
      console.log(
        "🚀 ~ file: DisplayForm.tsx:123 ~ mySocket.on ~ count:",
        count
      );
    });
    // Listen for the 'message' event

    setSocket(mySocket);

    mySocket.on("connect", () => {
      setConnected(true);
    });

    mySocket.on("disconnect", () => {
      setConnected(false);
    });
    // Clean up the socket connection on component unmount
    return () => {
   
      // mySocket.emit("userLeave", { roomId: `${props.data._id}` });
      // mySocket.emit("userLeave", { roomId: `${props.data._id}` });
      mySocket.disconnect();
 
      mySocket.close();
      
      setFormDataEvent(null);
    };

    // Clean up the socket connection on component unmount
  }, [props.data._id]);

  return !form || dataLoader ? (
    <Spinner />
  ) : (
    <IntlProvider
      locale={"fr"}
      defaultLocale="en"
      messages={lang === "fr" ? frMsg : enMsg}
    >
      <Formiz
        connect={form}
        onValidSubmit={
          user?.type === "writer" ? handleSubmit : (v) => console.log(v)
        }
        //onInvalid={() => console.log("onInvalid form", form.isStepValid)}
      >
        <MultiStepsLayout
          submitLabel={
            user?.type !== "writer"
              ? t("missingWording.submitLabel2")
              : t("missingWording.submitLabel")
          }
        >
          <PageHeader isEvent={true} setShouldUpdate={setShouldUpdate}>
            {formData?.name ? formData?.name : "#Titre de questionnaire"}
            <Heading fontSize="lg" mb={4} mt={4}>
              {formData?.description && (
                <>
                  <ArrowRightIcon
                    height={"12px"}
                    width={"12px"}
                    mr={2}
                    mb={1}
                    color={"#4565f6"}
                  />
                  <>{formData?.description}</>
                </>
              )}
            </Heading>
          </PageHeader>
          <>
            <div className="   p-4 w-full rounded-lg mb-4">
              <StepperWrapper title="Dots stepper" />

              {formData?.sections.map((step, i) => {
                return (
                  <FormizStep
                    label={step.sectionName}
                    name={`${step.sectionName + "-" + step.stepNbr}`}
                    isEnabled={
                      step.conditionLogic && step.conditionLogic.status
                        ? user?.type === "writer"
                          ? resolver(step.conditionLogic, form.values)
                          : true
                        : true
                    }
                  >
                    <FormRenderer
                      formDataId={formData?._id ?? props?.data?._id}
                      key={i}
                      stepNbr={step.stepNbr}
                      elements={step.fields}
                      values={form.values}
                      form={form}
                      isEvent={true}
                      fieldOnSubmit={true}
                      setShouldUpdate={setShouldUpdate}
                    ></FormRenderer>
                  </FormizStep>
                );
              })}
            </div>
          </>
        </MultiStepsLayout>
      </Formiz>
    </IntlProvider>
  );
};

export default DisplayEvent;
