import React from "react";
import { useTranslation } from "react-i18next";
import { removeWriterFromStudy } from "../../../api/cro";
import Button from "../../../components/ButtonUI";
import makeToast from "../../../components/Snackbar";

interface Props {
  setDeleteArcModal: Function;
  setShouldUpdate: Function;
  writerId: string;
  studyId: string;
  writresConfigId: string;
}

export const DeleteWriterModal: React.FC<Props> = ({
  setDeleteArcModal,
  writerId,
  writresConfigId,
  studyId,
  setShouldUpdate,
}) => {
  const { t } = useTranslation() as any;

  return (
    <div className="fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto bg-opacity-modal flexCenter">
      <div className="absolute z-40 top-1/3 flexColCenter confirmAddPatient">
        <h4 className="text-lg mb-4">{t("ArcPage.deleteInvistStudy")}</h4>
        <div className="flexCenter mt-6 buttons">
          <Button
            status="secondary"
            width={"100px"}
            height={"50px"}
            className="btn-delete"
            onClick={async () => {
              setDeleteArcModal(false);
            }}
          >
            {t("ArcPage.Cancel")}
          </Button>
          <Button
            status="secondary"
            width={"100px"}
            height={"50px"}
            onClick={async () => {
              let response: any = await removeWriterFromStudy(studyId, {
                writerId: writerId,
                writerConfigId: writresConfigId,
              });
              if (response) {
                makeToast("success", t("missingWording.dwt"));
                setShouldUpdate((up: boolean) => !up);
              }
              setDeleteArcModal(false);
            }}
          >
            {t("ArcPage.Delete")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteWriterModal;
