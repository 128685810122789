import { Redirect, RouteProps } from "react-router";
import DashboardCro from "../pages/DashboardCRO";
import Dragable from "../pages/DragableInterface";
import Events from "../pages/Events";
import FirstLogin from "../pages/FirstLogin";
import Arc from "../pages/MyArc";
import MyParticipant from "../pages/MyParticipant";
import MyParticipantForms from "../pages/MyParticipant/ParticipantForms";
import AddOrganisation from "../pages/Organisation/add";
import Profile from "../pages/Profile";
import Query from "../pages/Query";
import ConfigStudy from "../pages/StudyConfig";
import InvitationPage from "../pages/InvaitationPage";
const croRoutes: RouteProps[] = [
  {
    path: "/add/organisation",
    component: AddOrganisation,
    exact: true,
  },
  {
    path: "/",
    component: DashboardCro,
    exact: true,
  },

  {
    path: "/mesArc",
    component: Arc,
    exact: true,
  },
  {
    path: "/study/:studyId",
    component: ConfigStudy,
    exact: true,
  },
  {
    path: "/events/:studyId",
    component: Events,
    exact: true,
  },
  {
    path: "/study/:studyId/:surveryId",
    component: Dragable,
    exact: true,
  },
  {
    path: "/profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/participant/:studyId",
    component: MyParticipant,
    exact: true,
  },
  {
    path: "/participant/:studyId/:participantId",
    component: MyParticipantForms,
    exact: true,
  },
  {
    path: "/query/:studyId",
    component: Query,
    exact: true,
  },
  {
    path: "/firstLogin",
    component: FirstLogin,
    exact: true,
  }
];

croRoutes.push({ render: () => <Redirect to="/" /> });

export default croRoutes;
