import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/ButtonUI";
import { userAtom } from "../../../state/auth";
import SelectComp from "../../../components/Select";
import { StudySelectedIdAtom } from "../../../state/studySelectedIdAtom";

type Props = {
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  downloadApi:any
};

export const DownloadModal: React.FC<Props | any> = ({
  setOpenModal,
  downloadApi,
}) => {
  const [user] = useAtom(userAtom);
  const [studySelectedId, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const [shouldUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [extention, setExtention] = useState("");
  const { t } = useTranslation() as any;
  const _handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      await downloadApi(studySelectedId,extention)

    } catch (error) {
      console.log(
        "🚀 ~ file: downloadSelectorModal.tsx:24 ~ handleSubmit ~ error:",
        error
      );
    }
  };
  useEffect(() => {}, [shouldUpdate]);
  return (
    <div className="px-6 ">
      <h1 className="mb-4  ">
      {t("missingWording.extractData")}
      </h1>
      <h3 className="mb-8 ">{t("missingWording.extractDataDescrp")}</h3>
      <div className="flex  mb-16 items-center justify-around  gap-x-4 ">
        <SelectComp
          onChange={(e) => {
            setExtention(e.target.value);
            e.preventDefault();
            e.stopPropagation();
            return;
          }}
          className="w-72 text-center"
          labelProps={{
            className: "text-base font-semibold text-gray-400 -mb-1",
          }}
          value={extention}
          labelText={""}
          text={"Download type "}
          options={[
            { label: "XLSX", value: "XLSX" },
            // { label: "CSV", value: "CSV" },
            { label: "PDF", value: "PDF" },
          ]}
        />
      </div>

      <Button
        status="secondary"
        width="100px"
        height="50px"
        className="float-left btn-delete"
        onClick={() => setOpenModal(false)}
      >
        {t("Participant.Cancel")}
      </Button>

      <Button
        status="primary"
        width="100px"
        height="50px"
        className="float-right"
        type="submit"
        disabled={!extention || extention === ""}
        onClick={() => {
          if (isSubmitting) {
            return;
          }

          _handleSubmit()
        }}
      >
        {t("missingWording.confirm")}
      </Button>
    </div>

    // </ChakraProvider>
  );
};

export default DownloadModal;
