import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteStudyCenters } from "../../../../api/study";
import Button from "../../../../components/ButtonUI";
import makeToast from "../../../../components/Snackbar";
import Input from "../../../../components/Input";

interface Props {
  deleteCentreModal: boolean;
  setDeleteCentreModal: Function;
  setShouldUpdate: Function;
  centreId: string;
  studyId: string;
  centreName: string;
}

export const DeleteCentreModal: React.FC<Props> = ({
  deleteCentreModal,
  setDeleteCentreModal,
  centreId,
  studyId,
  setShouldUpdate,
  centreName,
}) => {
  const { t } = useTranslation() as any;
  const [stateCenterName, setStateCenterName] = useState("");
  return (
    <div className="px-8 py-4">
      <h3 className=" mb-4 font-semibold">
        {t("Centre.deleteCenter")}
        <h3 className="mb-4 text-sm mt-4 text-red-400 font-semibold">
          {t("Centre.ExCenter")}
        </h3>
      </h3>
      <Input
        labelProps={{
          className: "color-primary-grey-title text-sm font-semibold",
        }}
        autoFocus={true}
        //  labelText={t("Centre.nameCenter")}
        labelText={`${t("missingWording.centerDelete_1") }"${centreName}" ${t("missingWording.centerDelete_1_1")} ` }
        className=" mt-1 w-full border border-gray-300 hover:border-gray-400 focus:border-gray-400 rounded-md shadow-sm px-2 py-2"
        placeholder=""
        onChange={(e) => setStateCenterName(e.target.value)}
      />
      <div className="flex justify-between mt-6 ">
        <Button
          status="secondary"
          width={"119px"}
          height={"50px"}
          onClick={async () => {
            setDeleteCentreModal(false);
          }}
        >
          {t("Centre.cancel")}
        </Button>
        <Button
          status="secondary"
          width={"119px"}
          className={`${
            centreName === stateCenterName
              ? "border-red-300 text-red-300 hover:border-red-400 hover:text-red-400"
              : "border-gray-400 text-gray-400 hover:border-gray-500 hover:text-gray-500"
          }`}
          height={"50px"}
          onClick={async () => {
            if (centreName !== stateCenterName) {
              if (stateCenterName === "" || !stateCenterName)
                makeToast(
                  "warning",
                 `${t("missingWording.centerDelete_2") }`
                );
              else
                makeToast("warning",   `${t("missingWording.centerDelete_3") }`);
              return null;
            }
            let response: any = await deleteStudyCenters(studyId, centreId);
            if (response) {
              makeToast("success", `${t("Centre.deleteMess")}`);
              setShouldUpdate((up: boolean) => !up);
            }
            setDeleteCentreModal(false);
          }}
        >
          {t("Centre.delete")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteCentreModal;
