export const Query = {
  fr: {
    NoQueryFound: "Aucune requête n'est trouvé.",
    Open: "Ouvert",
    Resolved: "Résolu",
    TitleQuery: "Requêtes",
    NameVisit: "Nom de la visite",
    Participant: "Participant",
    NameChamp: "Nom du champ",
    Status: "Statut",
    Visualiser: "Visualiser",
    Message: "Vous n'avez aucune requête à traiter",
    Waiting:"En attente",
    Closed:"Terminée",
    MessageInput:"Envoyez votre message...",
    IdntChamp:"Position du champ",
    InDntChampT:"Intitulé du champ",
    Idnt:"Identifiant",
    ChampReq:"Ce champ est requis.",
    Createur:"Créateur",
    AddQuer:"Ajouter un requête",
    QueryMessSend:"Query message à été envoyer",
    All: "Toutes",
    searchby: "Par identifiant"
  },
  en: {
    NoQueryFound: "No queries found",
    Open: "Open",
    Resolved: "Resolved",
    TitleQuery: "Queries",
    NameVisit: "Name of the visit",
    Participant: "Participant",
    NameChamp: "Name of the field",
    Status: "Status",
    Visualiser: "View",
    Message: "You have no query to process",
    Waiting:"Waiting",
    Closed:"Closed",
    MessageInput:"Send your message...",
    IdntChamp: "Field identifier",
    InDntChampT: "Field identity",
    Idnt: "Identifier",
    ChampReq: "This field is required.",
    Createur: "Creator",
    AddQuer:"Add a query",
    QueryMessSend:"Query message to be sent",
    All: "All",
    searchby: "By ID"
  },
};
