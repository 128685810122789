import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addEventoStudy } from "../../api/study";
import Button from "../../components/ButtonUI";
import Input from "../../components/FloatInput";
import SelectComp from "../../components/Select";
import makeToast from "../../components/Snackbar";
import { getTemplateForms } from "../../api/survey";

type Props = any;
const EventConfig: React.FC<Props> = ({ setRefresh, closeModal }) => {
  const params: any = useParams();
  const { studyId } = params;
  const [step] = useState(1);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const { t } = useTranslation() as any;
  const [nameError, setNameError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [template, setTemplate] = useState([]) as any;
  const [selectedFormTemplate, setSelectedFormTemplate] = useState("") as any;
  const createEventConfig = async () => {
    try {
      if (type === "") {
        return makeToast("warning", t("missingWording.ect1"));
      }
      if (name === "") {
        return makeToast("warning", t("missingWording.ect2"));
      }

      const newEventConfig = await addEventoStudy({
        name: name,
        type: type,
        studyId: studyId,
        sections: selectedFormTemplate
        ? selectedFormTemplate?.el?.sections
        : [{ task_data: [], stepNbr: 0, sectionName: "Page 1" }],
      });

      if (newEventConfig && newEventConfig.data?.newEventConfig) {
        makeToast("success", t("missingWording.ect3"));
        closeModal();
        setRefresh((r) => !r);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };
  const fetchTemplates = async ()=>{
    try {
      const forms = await getTemplateForms();
      setTemplate(
        forms
          ? forms?.map((el) => {
              return { value: el?._id, label: el?.name, el: el };
            })
          : []
      );
    } catch (error) {
      console.log("🚀 ~ fetchTemplates ~ error:", error)
      
    }
  }
  useEffect(()=>{
    fetchTemplates()
  },[])
  return (
    <>
      {step == 1 && (
        <>
          <h3 className="font-bold text-2xl text-black text-center my-10 mx-6 px-24 ">
            {t("missingWording.newEvent")}
          </h3>
          <div className="my-3 mx-4 items-center  ">
            <Input
              id="name"
              className="w-full"
              placeholder={t("missingWording.eventName")}
              labelText={t("missingWording.eventName")}
              type="text"
              onBlur={(e) => {
                setName(e.target.value);
                if (e.target.value === "") {
                  setNameError(t("ArcPage.verifName"));
                } else {
                  setNameError("");
                }
              }}
              onChange={(e) => {
                setNameError("");
                setName(e.target.value);
              }}
              inlineLabel={false}
              onfocus={true}
              style={{ maxHeight: "50px" }}
            />
            {nameError && (
              <p className="text-red-300  text-sm ml-4">{nameError}</p>
            )}
          </div>

          <div className="mx-4 my-3 items-center  ">
            <SelectComp
              id="type"
              onChange={(e) => {
                setTypeError("");
                setType(e.target.value);
                return;
              }}
              className="min-w-full"
              text={`${t("missingWording.ecl1")}`}
              options={[
                {
                  label: t("missingWording.adverseevents"),
                  value: "ADVERSE",
                },
                {
                  label: t("missingWording.phoneCallEvent"),
                  value: "CALL",
                },
              ]}
            ></SelectComp>
            {typeError && (
              <p className="text-red-300  text-sm ml-4 mt-3">{typeError}</p>
            )}
          </div>
          <div className="mx-4 my-3 items-center  ">
          {template.length > 0 && (
              <SelectComp
                onChange={(e) => {
                  // setFormData(e.target.value);
                  const selectedTemplate = template.find(
                    (el) => el.value === e.target.value
                  );
                  setSelectedFormTemplate(selectedTemplate);
                }}
                disabled={!template || template.length === 0}
                className="min-w-full "
                text={template.length > 0 && t("missingWording.inp55")}
                options={template}
              ></SelectComp>
          )}
          
          </div>
          <div className=" w-full flex justify-between items-center p-4 ">
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="btn-delete px-6 py-2"
              onClick={closeModal}
            >
              {t("Profile.Cancel")}
            </Button>{" "}
            <Button
              // disabled={name === "" || type === ""}
              width="100px"
              height="50px"
              status="secondary"
              className="px-6 py-2"
              onClick={async () => {
                let hasError = false;

                if (name === "") {
                  setNameError(t("ArcPage.verifEventName"));
                  hasError = true;
                } else {
                  setNameError("");
                }

                if (type === "") {
                  setTypeError(t("ArcPage.verifType"));
                  hasError = true;
                } else {
                  setTypeError("");
                }

                if (!hasError) {
                  await createEventConfig();
                }
              }}
            >
              {t("missingWording.createButton")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default EventConfig;
