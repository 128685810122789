export const ArcPage = {
  fr: {
    team: "Mon équipe",
    template: "Mes modèles",
    exports: "Exports",
    exportsList: "Liste des exports",
    exported: "Export du",
    download: "Télécharger",
    inProgress: "Traitement en cours... Cela peut prendre plusieurs minutes.",
    cloudError:
      "Une erreur est survenu lors du traitement de cet extraction. Merci de réessayer plus tard.",
    addUser: "+ Ajouter un utilisateur",
    lastAct: "Dernières activités le",
    NoArc: "Aucun utilisateur trouvé",
    NoTemplate: "Aucun modèle trouvé",
    noExportsFound: "Aucune exportation trouvée",
    ActDes: "Activation/Désactivation du compte",
    StatusAccount: "Status du compte :",
    MemberSince: "Membre depuis :",
    NewAcc: "Êtes-vous sûr de vouloir créer un nouveau compte utilisateur ?",
    Cancel: "Annuler",
    AddArc: "+ Créer",
    Confirm: "Confirmer",
    pleaseEmail: "Veuillez saisir votre Email",
    successAddArc: "L'ajout d'utilisateur a été effectué avec succès. ",
    emailExist: "Email existe déjà",
    addArc: "Ajouter un utilisateur",
    nameArc: "Ecrivez le nom ",
    lastArc: "Ecrivez le prénom ",
    verifLast: "Merci de vérifier le prénom.",
    verifLastname: "Merci de vérifier le nom.",
    writeEmail: "Ecrivez votre email ...",
    VerifEmail: "Merci de vérifier l'email.",
    save: "Enregistrer",
    verifName: "Merci de vérifier le nom.",
    verifFormName: "Merci de vérifier le nom du formulaire.",
    verifRole: " Merci de sélectionner le profil chargé de la viste.",
    verifAssociatedGrp: "Merci de vérifier le groupe associé.",
    verifEventName: "Merci de vérifier le nom de l'événement.",
    verifType: "Merci de vérifier le type d'événement.",
    name: "Nom",
    lastname: "Prénom",

    listBug: "Chargement de la liste des utilisateurs à échoué",
    Delete: "Supprimer",
    pleaseSelect: "Veuillez sélectionner",

    deleteUserStudy: "Voulez-vous retirer cet utilisateur de cette étude ?",
    deleteInvistStudy: "Voulez-vous retirer cet investigateur de cette étude ?",
    deleteSS: "L'utilisateur a été supprimé avec succès",
    writerResponsable1: " Voulez-vous désigner M.",
    writerResponsable2: " comme investigateur responsable sur le centre ",
    writerResponsable3: " et le profil ",

    writerResponsable01: "Êtes-vous sûr de vouloir retirer ",
    writerResponsable02: "  de la liste des invistigateurs responsables ? ",

    writeName: "Ecrivez votre nom",
    yourName: "Votre nom",
    writeLast: "Ecrivez votre prénom ...",
    yourLast: "Votre prénom",
    yourEmail: "Votre email",

    activate: "Activé",
    desactivate: "Désactiver",
    RetireResponsable: "Retirer de la liste des responsables",
    RetireAdmins: "Retirer de la liste des admins",
    DesignAdmin: "Désigner comme un administrateur",
    AddResponsable: "Désigner comme un responsable",
    AffectSuccess: "L'ajout d'utilisateur a été effectué avec succès",
    retireAds: "retirer",
    restOf: " de la liste des administrateurs",
    des: "Affecter",
    MsgEtu: "de cette étude ",
    Mr: "M. ",
    Ms: "Mme ",
    affectStudy: "L'affectation d'administrateur  a été effectué avec succès",
    MajSuccssArc:
      "La révocation de l'affectation d'administrateur a été effectuée avec succès",
    Retire: "Retirer",
    StudyQuest: "Êtes-vous sûr de vouloir",
    asAdmin: "de la liste des administrateurs",
    firstName: "Prénom",
    emtyFile: "Votre étude ne présente pas encore de participants.",
  },
  en: {
    emtyFile: "Your study does not yet have any participants.",
    template: "Templates",
    asAdmin: "as administrator",
    retireAds: "remove from the list of directors",
    des: "Assign",
    MsgEtu: "of this study ",
    Mr: "Mr. ",
    Ms: "Ms ",
    affectStudy: "Successful assignment of administrator to the study",
    ShiftArc: "Successfully updated the study's ARCs",
    Retire: "Remove",
    StudyQuest: "Are you sure you want to",
    AddResponsable: "Designate as responsible",
    Confirm: "Confirm",
    AffectSuccess: "Successful assignment of ARCs to the study",
    RetireAdmins: "Remove from the list of admins",
    RetireResponsable: "Remove from the list of responsibles",
    DesignAdmin: "Designate as administrator",
    MajSuccssArc:
      "The administrator's assignment has been successfully revoked",
    activate: "Activated",
    desactivate: "Deactivate",
    NoTemplate: "No template found",
    writeName: "Write your name",
    yourName: "Your name",
    writeLast: "Write your first name ...",
    yourLast: "Your first name",
    yourEmail: "Your email",
    deleteInvistStudy:
      "Do you want to remove this investigator from this study?",
    deleteSS: "User is successfully removed",
    writerResponsable1: "Do you want to appoint mr ",
    writerResponsable2: "  as a responsible investigator on the center ",
    writerResponsable3: " and profile ",
    writerResponsable01: "Are you sure you want to remove ",
    writerResponsable02: "  from the list of responsible invistigators ? ",
    deleteUserStudy: "Do you want to delete this user from the study ?",
    listBug: "Load list of ARCs to fail.",
    Delete: "Delete",
    pleaseSelect: "Please select",
    firstName: "First name",
    team: "My team",
    exports: "Exports",
    exportsList: "Exports list",
    exported: "Exported on",
    download: "Download",
    inProgress: "Operation is in progress... This can take several minutes.",
    cloudError:
      "The data's extraction has encountered an error. Please try again later.",
    addUser: "+ Add user",
    lastAct: "Last activities on",
    NoArc: "No user found",
    noExportsFound: "No exports found",
    ActDes: "Account activation/ desactivation",
    StatusAccount: "Account status:",
    MemberSince: "Member since:",
    NewAcc: "Are you sure you want to create a new user account?",
    Cancel: "Cancel",
    AddArc: "+ Create",
    pleaseEmail: "Please enter your Email",
    successAddArc: "ARC added successfully",
    emailExist: "Email already exists",
    addArc: "Add an user",
    nameArc: "Write the name of the ARC ...",
    lastArc: "Write the first name of the CRA ...",
    verifLast: "Please check the first name.",
    writeEmail: "Write your email ...",
    VerifEmail: "Please check the email.",
    save: "Save",
    verifName: "Please check the name.",
    verifFormName: "Please check the form name.",
    verifRole: "Please select the profile in charge of the visit.",
    verifAssociatedGrp: "Please check the associated group.",
    verifEventName: "Please check the event name.",
    verifType: "Please check the event type.",
    name: "Name",
    lastname: "Last name",
    verifLastname: "Please check the last name.",
  },
};
