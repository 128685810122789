import cities from "../../../../config/constants/cities";

const FormItems = (intl) => {
  return [
    {
      key: "Header",
      name: intl.formatMessage({ id: "header-text" }),
      icon: "fas fa-heading",
      fieldProps: {
        content: intl.formatMessage({ id: "header-content" }),
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "Paragraph",
      name: intl.formatMessage({ id: "paragraph" }),
      icon: "fas fa-paragraph",
      fieldProps: {
        content: intl.formatMessage({ id: "paragraph-content" }),
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "LineBreak",
      name: intl.formatMessage({ id: "line-break" }),
      icon: "fas fa-arrows-alt-h",
      fieldProps: {
        content: "***",
      },
      conditionLogic: {
        status: false,
        action: "S",
        operator: "all",
        conditions: [],
      },
    },
    {
      key: "Image",
      name: intl.formatMessage({ id: "image" }),
      icon: "far fa-image",
      fieldProps: {
        field_name: "image_",
        src: "",
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
      },
    },
    {
      key: "RepeaterField",
      name: intl.formatMessage({ id: "RepeaterField" }),
      icon: "fas fa-redo-alt",
      info: "Création d'un ou plusieurs champs répétitifs",
      fieldProps: {
        field_name: "repeater_input_",
        label: intl.formatMessage({ id: "RepeaterField" }),
        maxRepeats: null,

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        childFieldsConfig: { fields: [] },
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    {
      key: "TextInput",
      name: intl.formatMessage({ id: "text-input" }),
      icon: "fas fa-font",
      info: "Création d'un champ de texte",
      fieldProps: {
        field_name: "text_input_",
        label: intl.formatMessage({ id: "text-input" }),
        placeholder: "",
        hasUnit: { active: false, unit: "" },

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    {
      key: "PhoneNumber",
      name: intl.formatMessage({ id: "phone-number" }),
      icon: "fas fa-phone",
      info: "Création d'un champ de texte",
      fieldProps: {
        field_name: "phone_number_",
        label: intl.formatMessage({ id: "phone-number" }),
        placeholder: "",
        hasprefix: { active: true, prefix: "" },

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    {
      key: "NumberInput",
      name: intl.formatMessage({ id: "number-input" }),
      icon: "fas fa-plus",
      info: "Création d’un champ numérique. Seules des valeurs numériques peuvent être saisies.",

      fieldProps: {
        field_name: "number_input_",
        label: intl.formatMessage({ id: "number-input" }),
        placeholder: "123...",
        hasUnit: { active: false, unit: "" },
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        sharedCalculVars: false,
        sharedStatsVars: false,
      },
    },
    {
      key: "CalculatedInput",
      name: intl.formatMessage({ id: "calcul-input" }),
      icon: "fas fa-calculator",
      info: "Création d'un champ pré-calculé en fonction des valeurs d'autres champs numériques.",

      fieldProps: {
        field_name: "number_input_calculated_",
        label: intl.formatMessage({ id: "calcul-input" }),
        placeholder: "",
        hasUnit: { active: false, unit: "" },
        calculationConfig: [],
        decimalPlaces:2,
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        sharedCalculVars: false,
      },
    },
    {
      key: "TextArea",
      name: intl.formatMessage({ id: "TextArea" }),
      icon: "fas fa-text-height",
      info: "Création d’une zone de texte.",
      fieldProps: {
        field_name: "text_area_",
        label: intl.formatMessage({ id: "TextArea" }),
        placeholder: "",
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    {
      key: "Dropdown",
      name: intl.formatMessage({ id: "dropdown" }),
      icon: "far fa-caret-square-down",
      info: "Création d’une liste déroulante comprenant de multiples options de réponse. L’utilisateur ne peut sélectionner qu’une seule option de réponse.",

      fieldProps: {
        label: intl.formatMessage({ id: "dropdown" }),
        Multiple: false,
        options: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
        ],
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        field_name: "dropdown_",
      },
    },
    {
      key: "Checkboxes",
      name: intl.formatMessage({ id: "checkboxes" }),
      icon: "far fa-check-square",
      info: "Création d'une liste de cases à cocher avec plusieurs options de réponse. l'utilisateur peut sélectionner plusieurs options de réponse (contrairement au bouton radio)",

      fieldProps: {
        field_name: "checkboxes_",
        label: intl.formatMessage({ id: "checkboxes" }),
        direction: "colum",
        options: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
        ],
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    {
      key: "RadioButtons",
      name: intl.formatMessage({ id: "RadioButtons" }),

      icon: "far fa-dot-circle",
      info: "Création d'une liste de boutons radio, plusieurs options de réponse peuvent être créées mais l'utilisateur ne peut pas en sélectionner une seule.",

      fieldProps: {
        field_name: "radiobuttons_",
        label: intl.formatMessage({ id: "RadioButtons" }),
        direction: "colum",
        options: [
          { label: "Option 1", value: "Option 1" },
          { label: "Option 2", value: "Option 2" },
        ],

        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        sharedStatsVars: false,
      },
    },
    // {
    //   key: "Cities",
    //   name: intl.formatMessage({ id: "Cities" }),

    //   icon: "far fa-dot-circle",
    //   info: "Création d'une liste de boutons radio, plusieurs options de réponse peuvent être créées mais l'utilisateur ne peut pas en sélectionner une seule.",

    //   fieldProps: {
    //     field_name: "radiobuttonsCites_",
    //     label: intl.formatMessage({ id: "Cities" }),
    //     direction: "column",
    //     options: cities,

    //     rules: [
    //       {
    //         required: false,
    //         message: intl.formatMessage({ id: "validation-msg-Req" }),

    //       },
    //     ],

    //     conditionLogic: {
    //       status: false,
    //       action: "S",
    //       operator: "all",
    //       conditions: [],
    //     },
    //     isVisible: true,
    //     exclude: false,
    //   },
    // },
    // {
    //   key: "TableField",
    //   name: intl.formatMessage({ id: "TableField" }),
    //   icon: "fas fa-table",
    //   info: "Création d'un groupe de questions basées sur des choix sous la forme d'une liste de boutons radio, des options de réponse multiples peuvent être créées mais l'utilisateur ne peut en sélectionner qu'une seule.",

    //   fieldProps: {
    //     field_name: "tablefield_",
    //     label: intl.formatMessage({ id: "TableField" }),
    //     lignes: [{ label: "Question 1" }],
    //     options: [
    //       { label: "Colonne 1", value: "Colonne 1" },
    //       { label: "Colonne 2", value: "Colonne 2" },
    //       { label: "Colonne 3", value: "Colonne 3" },
    //     ],

    //     rules: [
    //       {
    //         required: false,
    //         message: intl.formatMessage({ id: "validation-msg-Req" }),
    //       },
    //     ],

    //     conditionLogic: {
    //       status: false,
    //       action: "S",
    //       operator: "all",
    //       conditions: [],
    //     },
    //     isVisible: true,
    //     exclude: false,
    //   },
    // },
    {
      key: "File",
      name: intl.formatMessage({ id: "photo" }),
      icon: "fa fa-upload",
      info: " Importer une image ou une fichier",
      fieldProps: {
        field_name: "file_",
        label: "File",
        maxSize: 5,
        fileType: "image",
        accept: ".jpg, .jpeg, .png",
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    {
      key: "DatePicker",
      name: intl.formatMessage({ id: "date" }),
      icon: "far fa-calendar-alt",
      info: "Création d’un champ date",
      fieldProps: {
        field_name: "date_picker_",
        label: intl.formatMessage({ id: "date" }),
        format: "DD/MM/YYYY",
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        sharedCalculVars: false,

        // allowClear: true,
      },
    },
    {
      key: "TimePicker",
      name: intl.formatMessage({ id: "time" }),
      icon: "far fa-clock",
      fieldProps: {
        field_name: "time_picker_",
        label: intl.formatMessage({ id: "time" }),
        format: "hh:mm a",
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        sharedCalculVars: false,
      },
    },
    {
      key: "Rating",
      name: intl.formatMessage({ id: "rating" }),
      icon: "fas fa-star",
      fieldProps: {
        field_name: "rating_",
        label: intl.formatMessage({ id: "rating" }),
        // allowClear: true,
        // allowHalf: false,
        count: 5,
        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
      },
    },
    // {
    //   key: "Signature",
    //   name: intl.formatMessage({ id: "signature" }),
    //   icon: "fas fa-pen-square",
    //   fieldProps: {
    //     field_name: "signature_",
    //     label: intl.formatMessage({ id: "signature" }),
    //     rules: [
    //       {
    //         required: false,
    //         message: intl.formatMessage({ id: "validation-msg-Req" }),
    //       },
    //     ],
    //     conditionLogic: {
    //       status: false,
    //       action: "S",
    //       operator: "all",
    //       conditions: [],
    //     },
    //     isVisible: true,
    //     exclude: false,
    //   },
    // },
    {
      key: "Range",
      name: intl.formatMessage({ id: "range" }),
      icon: "fas fa-sliders-h",
      info: "création d'une échelle numérique, les limites de l'échelle peuvent être fixées par des valeurs minimales et maximales, l'intervalle entre deux graduations est personnalisable.",
      fieldProps: {
        field_name: "range_",
        label: intl.formatMessage({ id: "range" }),
        step: 1,
        min: 1,
        max: 5,
        minLabel: intl.formatMessage({ id: "Easy" }),
        maxLabel: intl.formatMessage({ id: "Difficult" }),
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [],
        },
        isVisible: true,
        exclude: false,
        sharedCalculVars: false,
      },
    },
  ];
};
const CustomItems = (intl) => {
  return [
    {
      key: "ident",
      custom: true,
      name: "Identité",
      icon: "fas fa-user-tie",
      fieldProps: {
        field_name: "text_input_",
        label: "Identité",

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },

        fullName: true,
        email: true,
        numTel: true,
        numSociale: true,
      },
    },

    {
      key: "fullAdress",
      custom: true,
      name: "Adresse",
      icon: "fas fa-map-marked",
      fieldProps: {
        field_name: "text_input_",
        label: "Adresse complète",

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
        pays: true,
        ville: true,
        zipCode: true,
        adresse: true,
        departement: false,
      },
    },
    {
      key: "Email",
      name: "Email",
      custom: true,
      icon: "fas fa-at",
      fieldProps: {
        field_name: "email_",
        label: "Email",
        placeholder: "jhon@doe.com",

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
          {
            type: "email",
            message: "email non valide",
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },

    {
      key: "captcha",
      custom: true,
      name: "Captcha",
      icon: "fas fa-shield-alt",
      fieldProps: {
        field_name: "captcha_input_",

        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },
  ];
};

export { CustomItems, FormItems };
