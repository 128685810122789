import { useState } from "react";
import { verifyMFA } from "../../api/auth";
import makeToast from "../../components/Snackbar";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../state/auth";
import { MdPhoneAndroid } from "react-icons/md";
import "./styles.scss";

const MultiFactorAuthentication: React.FC = () => {
  const [mfaCode, setMfaCode] = useState("");
  const location = useLocation() as any;
  const email = location?.state?.email || "";
  const session = location?.state?.session || "";

  const handleMfaCodeSubmission = async () => {
    try {
      const res = await verifyMFA({ email, mfaCode, session });
      console.log("MFA verification response:", res);
      if (res.status === "success") {
        makeToast("success", "MFA code verified successfully!");
        if (res?.result?.AuthenticationResult?.AccessToken) {
          localStorage.setItem(
            "token",
            res?.result?.AuthenticationResult?.AccessToken
          );
        }
        if (res?.result?.AuthenticationResult?.RefreshToken) {
          localStorage.setItem(
            "refreshToken",
            res?.result?.AuthenticationResult?.RefreshToken
          );
        }
        window.location.reload();
      }
    } catch (error) {
      makeToast("error", "Error verifying MFA code, please try again.");
      console.error("MFA verification error:", error);
    }
  };
  return (
    <div className="login min-h-screen flexCenter forgetPassword">
      <div className="absolute inset-0 katomiWterMark" />
      <div className="auth-login-Container">
        <h1 className="h1 title flexRowCenter">Two-factor Authentication</h1>

        <div className="form-container">
          <h3 className="auth-code-title">Authentication Code (6 digits)</h3>
          <input
            type="text"
            id="mfaCode"
            name="mfaCode"
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            required
            className="auth-input"
          />
          <button
            onClick={() => handleMfaCodeSubmission()}
            className="auth-button"
          >
            Valider
          </button>

          <div className="instruction-container">
            <div className="icon-container">
              <MdPhoneAndroid className="mx-2  mt-1 w-5 h-5" />
            </div>
            <div className="instruction-text">
              Open the two-factor authentication app on your device to obtain a
              code and verify your identity.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiFactorAuthentication;
