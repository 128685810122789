import classNames from "classnames";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { getAllArc } from "../../api/arc";
import { retriveStudy } from "../../api/study";
import { FormikWizard } from "../../components/FormikWizard";
import Icon from "../../components/Icon";
import makeToast from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import { screenBreakpoints } from "../../config/theme";
import { checkArcAdmin } from "../../helper";
import history from "../../history";
import { userAtom } from "../../state/auth";
import Forms from "../Forms";
import Centers from "./Centers";
import Roles from "./Roles";
import MemberStudy from "./studyMember";
import "./styles.scss";
import EditStudy from "./StudyEdit";
import RandomisationConfig from "./RandomisationConfig";
import { ReactComponent as ListIcon } from "../../assets/list.svg";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { driverAtom } from "../../state/driverJs";
import { tutorialModeAtom } from "../../state/tutorialMode";
import Modal from "../../components/ModalUI";

// import useDrivejs from "../../hooks/driverjs/index";

const ConfigStudy: React.FC = () => {
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [updateStudy, setUpdateStudy] = useState(false);
  const [studyConfig, setStudyConfig] = useState() as any;
  const [showBanner, setShowBanner] = useState(false);
  const [stepNum, setStepNum] = useState(0);
  const params: any = useParams();
  const { studyId } = params;
  const { t } = useTranslation() as any;
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
const [randomisationModal,setRandomisationModal]  = useState(false);
  const [menuToggleIsActive, setMenuToggleIsActive] = useState(
    window.innerWidth <= screenBreakpoints.md
  );
  const [, setWindowWidth] = useState<number>(window.innerWidth);

  // const {
  //   startTour,
  //   setStartTour,
  //   createTutoForm,
  //   setCreateTutoForm,
  //   centerTuto,
  //   setCenterTuto,
  //   roleTuto,
  //   setRoleTuto,
  //   openAddInvestigateurTuto,
  //   SetOpenAddInvestigateurTuto,
  //   openTutoFormModal,
  //   setOpenTutoFormModal,
  //   driverConfig,
  //   setDriverConfig,
  // } = useDrivejs({ stepNumber: 1, loading });

  const swipeEventsHandlers = useSwipeable({
    onSwipedLeft: () => {
      setForceMenuOpen(false);
    },
    onSwipedRight: () => {
      setForceMenuOpen(true);
    },
  });
  const menu =
    user?.type === "cro" || user?.type === "arc"
      ? [
          {title: t("SideBar.genralSetup"), icon: "studyconfig"},

          {title: t("SideBar.Centre"), icon: "invcenter"},
          {title: t("SideBar.Role"), icon: "profil"},
          {title: t("SideBar.Membres"), icon: "team"},
          {title: t("SideBar.VisitezFamille"), icon: "visitforms"},
          {title: t("SideBar.rand"), icon: "randomization"},
        ]
      : [
          // "General setup",
          // {title: t("SideBar.Centre"), icon: "invcenter"},
          // {title: t("SideBar.Role"), icon: "profil"},
          {title: t("SideBar.Membres"), icon: "team"},
          // {title: t("SideBar.VisitezFamille"), icon: "visitforms"},
        ];
  async function fetchMyAPI(): Promise<void> {
    try {
      setLoading(true);

      const study = await retriveStudy(studyId);

      if (!study) {
        makeToast("error", t("Role.CErr"));
        setLoading(false);
        history.push("/");
      } else {
        if (study?.arcs.length > 0) {
          setShowBanner(false);
        } else {
          let response: any = await getAllArc();
          if (response && response.length > 0) {
            setShowBanner(false);
          } else {
            setShowBanner(true);
          }
        }
        if (
          user?.type === "cro" ||
          user?.type === "sponsor" ||
          (user?.type === "arc" && checkArcAdmin(user?._id, study?.arcAdmins))
        ) {
          setStudyConfig(study);

          setLoading(false);
        } else {
          makeToast("error", t("Role.CErr"));
          setLoading(false);
          history.push("/");
        }
      }
    } catch (error) {
      makeToast("error", t("Role.CErr"));
      setLoading(false);
      history.push("/");
    }
  }
  useEffect(() => {
    fetchMyAPI();
  }, [updateStudy]);




  return loading ? (
    <Spinner />
  ) : (
    <div className="configMenu">
      <FormikWizard
        initialValues={{}}
        onSubmit={async (values) => {
          console.log(values);
        }}
        validateOnNext
        activeStepIndex={0}
        steps={
          user?.type === "cro" || user?.type === "arc"
            ? [
                {
                  component: ({}) => (
                    <EditStudy
                      studyId={studyId}
                      isProduction={studyConfig?.flag}
                      initialValues={{
                        name: studyConfig?.name,
                        description: studyConfig?.description || "",
                        centerAcess: studyConfig?.centerAcess,
                        anonym: studyConfig?.anonym,
                        randomisation: studyConfig?.randomisation,
                        eConsult: studyConfig?.eConsult ?? false,
                        privateCenter: studyConfig?.privateCenter ?? false,
                        ePro: studyConfig?.ePro ?? false,
                      }}
                      setUpdateStudy={setUpdateStudy}
                      readOnly={studyConfig?.status === "END"}
                    />
                  ),
                  validationSchema: null,
                },

                {
                  component: ({}) => (
                    <Centers
                      studyId={studyId}
                      readOnly={studyConfig.status === "END"}
                      centerTuto={null}
                    />
                  ),
                  validationSchema: null,
                },
                {
                  component: ({}) => (
                    <Roles
                      studyId={studyId}
                      readOnly={studyConfig.status === "END"}
                      roleTuto={null}
                    />
                  ),
                  validationSchema: null,
                },
                {
                  component: ({ values }) => (
                    <MemberStudy
                      values={values}
                      studyId={studyId}
                      studyConfig={studyConfig}
                      setUpdateStudy={setUpdateStudy}
                      openAddInvestigateurTuto={null}
                      readOnly={studyConfig.status === "END"}
                    />
                  ),
                  validationSchema: null,
                },
                {
                  component: ({}) => (
                    <Forms
                      studyConfig={studyConfig}
                      readOnly={studyConfig.status === "END"}
                      openTutoFormModal={null}
                    />
                  ),
                  validationSchema: null,
                },
                {
                  component: ({}) => (
                    <RandomisationConfig
                      studyConfig={studyConfig}
                      random={studyConfig?.randomisation}
              
                    />
                  ),
                  validationSchema: null,
                },
              ]
            : [
                {
                  component: ({ values }) => (
                    <MemberStudy
                      values={values}
                      studyId={studyId}
                      studyConfig={studyConfig}
                      setUpdateStudy={setUpdateStudy}
                      openAddInvestigateurTuto={null}
                      readOnly={studyConfig?.status === "END"}
                    />
                  ),
                  validationSchema: null,
                },
              ]
        }
      >
        {({ renderComponent, goToStep, currentStepIndex, values }) => {
          let lastStep: number = parseInt(localStorage.getItem("step") || "0");
          goToStep(lastStep);
          return (
            <>
              <div className="flex justify-between md:hidden absolute mobile-menu-burger">
                <button
                  className="p-4 focus:outline-none"
                  onClick={() => {
                    setForceMenuOpen(!forceMenuOpen);
                  }}
                >
                  <Icon isIcon={true} name={forceMenuOpen ? "close" : "menu"} />
                </button>
              </div>
              <div className="flex flex-row ">
                {(forceMenuOpen || !menuToggleIsActive) && (
                  <div
                    id={"EditStudy_configMenu"}
                    className={`menuContainer flex flex-col ${
                      !menuToggleIsActive || !forceMenuOpen
                        ? ""
                        : "swipeable-fullscreen"
                    }`}
                    {...swipeEventsHandlers}
                  >
                    <div className="icon link cursor-pointer flex items-center">
                      <div>
                        <Icon
                          name="direction-gauche"
                          width={50}
                          height={50}
                          isIcon={true}
                          onClick={() => {
                            history.push("/dashboard");
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <p
                          className=""
                          onClick={() => {
                            history.push("/dashboard");
                          }}
                        >
                          {t("SideBar.Back")}
                        </p>
                      </div>
                    </div>
                    <nav className={classNames("mt-3 mr-16")}>
                      {menu.map(({title: m, icon}, idx) => {
                        return (
                          <>
                            <div
                              id={`EditStudy_configMenu_${idx}`}
                              className={classNames(
                                `${
                                  currentStepIndex === idx
                                    ? "selected-menu"
                                    : ""
                                } flex items-center py-3   ${
                                  m === t("SideBar.rand") &&
                                  !studyConfig?.randomisation
                                    ? "text-gray-400 cursor-not-allowed"
                                    : "cursor-pointer"
                                }`
                              )}
                              onClick={() => {
                                if (
                                  m === t("SideBar.rand") &&
                                  !studyConfig?.randomisation
                                )
                                  return null;
                                // const oldStep = localStorage.getItem('step')
                                // if(oldStep === '5'){
                                //   setRandomisationModal(true)
                                //   return null
                                // }
                                localStorage.setItem("step", idx.toString());
                                goToStep(idx);
                                setStepNum(idx);
                              }}
                            >
                              <div
                                className={`w-12 h-12 flexCenter ${
                                  m === t("SideBar.rand") &&
                                  !studyConfig?.randomisation
                                    ? "text-gray-400"
                                    : ""
                                }`}
                              >
                                <Icon width={32} isIcon={true} name={icon} />
                              </div>
                              <div>
                                <span className="my-auto text-menu">{m}</span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </nav>
                  </div>
                )}
                <div className={`w-full ${forceMenuOpen && "hidden"}`}>
                  <div className="w-full">
                    <h2 className="text-4xl">
                      {t("SideBar.studyConfig")} {studyConfig?.name}
                    </h2>
                    {showBanner && (
                      <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2  ">
                        <span className="warn warning ">
                          ⚠️{t("missingWording.banner")}
                          <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => history.push("/mesArc")}
                          >
                            {t("missingWording.bannerClick")}
                          </span>
                        </span>
                      </div>
                    )}
                    {studyConfig?.status === "END" && (
                      <div className="w-full  flex items-center alert-strip text-sm text-gray-500 my-2  ">
                        <span className="warn warning ">
                          ⚠️{t("missingWording.bannerEnd")}.
                        </span>
                      </div>
                    )}

                    <div
                      className={`pt-16 self-center justify-center  min-h-96 ${
                        forceMenuOpen && "hidden"
                      }`}
                    >
                      {renderComponent()}
                    </div>
                  </div>
                </div>
              </div>

            </>
          );
        }}
      </FormikWizard>
    </div>
  );
};

export default ConfigStudy;
