import moment from "moment";
import { useTranslation } from "react-i18next";

import Spinner from "../../../Spinner";
const HistoryFieldModal = (props) => {
  const {
    setSelectedElment,
    setShowHistory,
    loadingHistory,
    selectedElment,
    history,
  } = props;
  console.log(
    "🚀 ~ file: HistoryField.tsx:13 ~ HistoryFieldModal ~ history:",
    history
  );
  const { t } = useTranslation();
  return (
    <div className="space-y-4 p-4 h-96 mt-4 " style={{}}>
      <div>
        <h2 className=" text-lg font-semibold">{t("Survey.history")}</h2>
      </div>

      {loadingHistory || !selectedElment ? (
        <Spinner classNames="pt-14" nhs />
      ) : (
        <div className="overflow-y-auto h-full px-1  ">
          <div className="flex flex-col gap-y-3 mb-10 ">
            {selectedElment && history && history.length > 0 ? (
              history
                .slice()
                .sort((a, b) => moment(b.timeStamp).diff(moment(a.timeStamp)))
                .map((h) => (
                  <div className=" rounded-lg border p-2 flex flex-col space-y-2 w-full">
                    <div className="flex justify-between w-full">
                      <div className="">
                        <p className="subTitle">{t("Survey.modiInves")}</p>
                      </div>
                      <div>
                        <p className="text-sm">{h?.writenBy?.firstName}</p>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="">
                        <p className="subTitle">{t("Survey.AncVal")}</p>
                      </div>
                      <div>
                        <p className="text-sm">{h?.value ? h.value : "N/A"}</p>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="">
                        <p className="subTitle">{t("Survey.DateModif")}</p>
                      </div>
                      <div>
                        <p className="text-sm">
                          {moment(h?.timeStamp).format("DD-MM-YYYY") +
                            t("DashboardCro.at") +
                            moment(h?.timeStamp).format("HH:mm")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <>
                <p>{t("Survey.ChamHis")}</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default HistoryFieldModal;
