import { useState, useRef } from "react";
import * as Dnd from "react-dnd";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

import ButtonUI from "../../../ButtonUI";
import { FormElement } from "../../Elements";

import ItemTypes from "./ItemTypes";
import { SortableHandle } from "react-sortable-hoc";
import classes from "./css/sortable-elements.module.css";
const SortableElement = ({
  element,
  index,
  onEdit,
  onDelete,
  readOnly,
  surveyId,
  translation,
}) => {
  const ref: any = useRef(null);
  const [showAction, setShowAction] = useState(false);
  const DragHandle = SortableHandle(() => (
    <div className={classes.sorter}>
      <i
        className="fas fa-grip-vertical"
        style={{ margin: "10px", color: "#ccc" }}
      ></i>
    </div>
  ));
  const [{ handlerId }, drop] = Dnd.useDrop({
    accept: ItemTypes.BOX,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
  });

  const editHandler = () => {
    console.log(element, index);
    if (onEdit) onEdit(element, index);
  };
  const deleteHandler = () => {
    console.log(element, index);
    if (onDelete) onDelete();
  };

  return (
    <div
      // ref={preview}
      className={
        "flex flex-start shadow mb-1 mt-5 relative border border-blue-400  border-dashed rounded-lg hover:border-white hover:bg-gray-100 p-4  "
      }
      data-handler-id={handlerId}
      onMouseOver={() => setShowAction(true)}
      onMouseOut={() => setShowAction(false)}
    >
      {" "}
      <DragHandle />
      <div className={classes.formitem}>
        <FormElement
          form={undefined}
          readOnly={readOnly}
          element={element}
          isPreview={true}
          surveyId={surveyId}
          translation={translation}
        />
      </div>
      <div
        className={classes.action + " absolute"}
        style={{ display: showAction ? "block " : "none" }}
      >
        <ButtonUI
          status="icon-primary"
          className="mx-2  h-10 w-10 "
          onClick={editHandler}
        >
          <EditIcon color="white" />
        </ButtonUI>
        <ButtonUI
          status="icon-secondary"
          className="mx-2 h-10 w-10"
          onClick={deleteHandler}
        >
          <DeleteIcon color="red.300" />
        </ButtonUI>
      </div>
    </div>
  );
};
export default SortableElement;
function sortableHandle(arg0: () => JSX.Element) {
  throw new Error("Function not implemented.");
}
