import { ChakraProvider, Container, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getRandomisationConfig,
  getStudyForms,
  updateRandomisationConfig,
} from "../../../api/study";
import Button from "../../../components/ButtonUI";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import { FieldSelect } from "../../../components/SurveyGenerator/Elements/FieldSelect";
import { SwitchInput } from "../../../components/SurveyGenerator/Elements/FieldSwitcher";
import { RepeaterAssociatedSurvey } from "../../../components/SurveyGenerator/FormBuilder/PropertyEditor/RepeaterAssociatedSurvey";
import { RepeaterGroups } from "../../../components/SurveyGenerator/FormBuilder/PropertyEditor/RepeaterGroups";
import {
  Formiz,
  FormizStep,
} from "../../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import Tooltip from "../../../components/toolTip";
import { useField, useForm } from "../../../hooks/FormGeneratorHooks";

export const MyField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } =
    useField(props);

  const { label } = props;
  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  console.log("🚀 ~ file: index.tsx:39 ~ MyField ~ showError:", showError);

  return (
    <div className={`demo-form-group ${showError ? "is-error" : ""} w-full`}>
      <label className="demo-label" htmlFor={id}>
        {label}
      </label>
      <input
        className={`${
          (showError || errorMessage) && " border border-red-300"
        } text-sm w-full `}
        name="affectationNbr"
        style={{
          height: "40px",
          borderRadius: "5px",

          textAlign: "center",
          color: "rgb(69, 101, 246)",
        }}
        type="number"
        id={id}
        value={value ?? ""}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setIsTouched(true)}
      />
    </div>
  );
};

const RandomisationConfig: React.FC<any> = ({ random, studyConfig }) => {
  const { t } = useTranslation() as any;
  const [loading, setLoading] = useState(false);
  const [surveyList, setSurveyList] = useState([] as any[]);
  const [randomConfig, setRandomConfig] = useState() as any;
  const [isStudyOnProd, setIsStudyOnProd] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]) as any;
  const form = useForm();
  // const groupNames = randomConfig?.groups?.map((group) => group) || [];

  const initialValues = {
    auto: randomConfig?.auto || false,
    switching: randomConfig?.switching || false,
    groups: randomConfig?.groups?.map((group) => group.groupName),
    startFromSurveyId: randomConfig?.onCreation
      ? "ON_CREATION"
      : randomConfig?.startFromSurveyId ?? "ON_CREATION",
    affectationNbr: randomConfig?.affectationNbr,
    asocitedForms: randomConfig?.asocitedForms,
  };

  const handleSubmit = async () => {
    try {
      if (!form.isValid) {
        return null;
      }
      const res = await updateRandomisationConfig(
        form.values,
        randomConfig?._id
      );
      makeToast("success", `${t("missingWording.sucessRandomConfig")}`);
      console.log("Update response:", res);
    } catch (error) {
      console.error("Error updating randomisation configuration:", error);
    }
  };
  const getAllSurveys = async () => {
    try {
      setLoading(true);
      const res = await getStudyForms(studyConfig._id);
      if (res) {
        setSurveyList(res);
        setLoading(false);
      }
    } catch (error) {
      makeToast("error", "err");
      setLoading(false);
    }
  };
  async function fetchMyAPI(): Promise<void> {
    try {
      setLoading(true);
      const randomisationConfig = await getRandomisationConfig(
        studyConfig.randomisationConfig
      );
      if (randomisationConfig && randomisationConfig.config) {
        setRandomConfig(randomisationConfig.config);
        setLoading(false);
        if (randomisationConfig.flag)
          setIsStudyOnProd(randomisationConfig.flag);
      } else {
        setRandomConfig(null);
        setLoading(false);
      }
    } catch (error) {
      makeToast("error", "err");
      setLoading(false);
    }
  }
  useEffect(() => {
    console.log(
      "🚀 ~ file: index.tsx:60 ~ random, studyConfig:",
      random,
      studyConfig
    );

    if (random) fetchMyAPI();
  }, []);

  useEffect(() => {
    getAllSurveys();
  }, []);

  useEffect(() => {
    const options = surveyList.map((survey) => ({
      value: survey._id,
      label: survey.name,
      shared: survey.shared,
    }));
    options.unshift({
      value: "ON_CREATION",
      label: t("missingWording.onCreation"),
      shared: true,
    });

    setSelectOptions(options);
  }, [surveyList]);

 

  return random && randomConfig && !loading ? (
    <ChakraProvider resetCSS={false}>
      <Container minHeight="100vh" style={{ maxWidth: "100%" }} >
        <Formiz connect={form} initialValues={initialValues}>
          <FormizStep name="step_0">
            <div className="flex flex-row justify-start  mt-8 w-full  ">
              <RepeaterGroups
                key={"groups"}
                name="groups"
                form={form}
                value={initialValues.groups ?? []}
                withOption={false}
                disabled={isStudyOnProd}
              ></RepeaterGroups>

              <div className="h-auto w-0.5 bg-gray-300 mx-6"></div>

              <div className="w-full ">

              <h2>{t("Study.configAssignments")}</h2>
                <div className="flex flex-row items-center justify-between w-full">
                  <label className="w-96 font-semibold">
                    {t("Study.assignFirstGrp")}
                  </label>
                  <FieldSelect
                    id="startFromSurveyId"
                    name="startFromSurveyId"
                    value={initialValues.startFromSurveyId}
                    style={{ maxWidth: "160px" }}
                    className="bg-white mt-5 w-1/2 "
                    options={selectOptions.filter((so) => so.shared)}
                    readOnly={isStudyOnProd}
                  ></FieldSelect>
                </div>{" "}
                <div className=" flex mb-2 ">
                  <div className="flex flex-col  justify-start gap-y-4  w-full ">
                    <div className="flex flex-row items-center justify-between  w-full mb-4">
                      <Tooltip
                        tooltipText={`${t("Study.tooltipAuto")}`}
                        position={"top"}
                        clsText={{ width: "480px" }}
                      >
                        <label
                          className="w-96  font-semibold"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {t("Study.auto")}
                        </label>
                      </Tooltip>

                      <div className="w-1/3"></div>
                      <SwitchInput
                        id="auto"
                        name="auto"
                        className="cssFieldSelect  justify-end	 "
                        value={initialValues.auto}
                        addMargin={false}
                        disabled={isStudyOnProd}
                        justifyContent
                      ></SwitchInput>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full mb-4">
                      <Tooltip
                        tooltipText={`${t("Study.tooltipSwitchable")}`}
                        position={"top"}
                        clsText={{ width: "480px" }}
                      >
                        <label
                          className="w-96  font-semibold"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {t("Study.switchable")}{" "}
                        </label>
                      </Tooltip>
                      <div className="w-1/3"></div>
                      <SwitchInput
                        justifyContent
                        id="switching"
                        className="cssFieldSelect justify-end	 "
                        name="switching"
                        value={initialValues.switching}
                        addMargin={false}
                        disabled={isStudyOnProd}
                      ></SwitchInput>
                    </div>
           
                  </div>
                </div>
                {form.values.switching && form.values.groups?.length > 1 && (
                  <RepeaterAssociatedSurvey
                    key={"asocitedForms"}
                    name="asocitedForms"
                    form={form}
                    value={initialValues.asocitedForms}
                    withOption={false}
                    disabled={isStudyOnProd}
                    affectationNbr={form?.values?.groups?.length >1 ?  form?.values?.groups?.length : form?.values?.groups?.length -1 }
                    selectOptions={selectOptions.filter(
                      (so) => so.value !== "ON_CREATION"
                    )}
                  ></RepeaterAssociatedSurvey>
                )}
              </div>
            </div>

            <Button
              status="primary"
              width="150px"
              height="50px"
              className="fixed bottom-0 mb-4 right-4"
              onClick={handleSubmit}
              disabled={isStudyOnProd}
            >
              {t("Study.save")}
            </Button>
          </FormizStep>
        </Formiz>
      </Container>
    </ChakraProvider>
  ) : loading ? (
    <Spinner />
  ) : !random ? (
    <h3 className="text-center"> {t("missingWording.noRando")}</h3>
  ) : (
    <></>
  );
};

export default RandomisationConfig;
