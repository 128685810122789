import { useAtom } from "jotai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../assets/icons/icon-close.svg";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { default as ButtonUI } from "../ButtonUI";
import Modal from "../ModalUI";

interface TableProps {
  data: any;
  loading: boolean;
  setShouldUpdate: any;
  setTypeQuery: any;
}

const EventTable: React.FC<TableProps> = ({ data, loading }) => {
  const { t } = useTranslation();
  const [selctedEvent, setSelectedQuery] = useState() as any;
  const eventTypes = {
    ADVERSE: t("missingWording.adverseevents"),
    CALL: t("missingWording.phoneCallEvent"),
  };
  return (
    <div className="p-1.5 w-full inline-block align-middle md:text-sm xs:text-xs">
      <div className="overflow-hidden border rounded-lg eventTable">
        <table className="divide-y divide-gray-200 xs:w-auto md:w-full ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase  text-center"
              >
                {t("missingWording.participant")}
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase  text-center"
              >
                {t("missingWording.event")}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase text-center "
              >
                Type
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase text-center "
              >
                Date
              </th>
            </tr>
          </thead>
          {data.length > 0 && (
            <tbody className="divide-y divide-gray-200">
              {data.map((p) => {
                return (
                  <tr
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedQuery(p);
                    }}
                  >
                    <td className="md:px-6 xs:px-1 md:py-4 xs:py-2 xs:text-xs md:text-sm font-medium text-gray-800 text-center ">
                      {p?.participantId?.publicId}
                    </td>
                    <td className="md:px-6 xs:px-1 md:py-4 xs:py-2 xs:text-xs md:text-sm font-medium text-gray-800  text-center">
                      {p?.name}
                    </td>
                    <td className="md:px-6 xs:px-1 md:py-4 xs:py-2 xs:text-xs md:text-sm font-medium text-gray-800 text-center ">
                      {eventTypes[`${p?.type}`]}
                    </td>

                    <td className="md:px-6 xs:px-1 md:py-4 xs:py-2 xs:text-xs md:text-sm font-medium text-gray-800 text-center  ">
                      {moment(p?.createdAt).format("DD-MM-YYYY") +
                        t("DashboardCro.at") +
                        moment(p?.createdAt).format("HH:mm")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div className="flex justify-center items-center w-full mt-8 mb-8">
            <p className="menuInput">{t("missingWording.loading")}...</p>
          </div>
        )}
        {!loading && data.length === 0 && (
          <div className="flex justify-center items-center w-full mt-8 mb-8">
            <p className="menuInput"> {t("missingWording.noEvents")}</p>
          </div>
        )}
      </div>
      {selctedEvent && (
        <Modal
          showModal={true}
          className="px-10 py-1 relative overflow-y-auto md:max-w-full xs:max-w-sm "
        >
          <div className=" mb-2 ">
            <div className="flex justify-between mt-8">
              <div className="mb-8">
                <h3>{selctedEvent.name}</h3>
              </div>

              <div>
                <ButtonUI
                  status="icon-secondary"
                  onClick={() => setSelectedQuery()}
                  className="absolute right-2 top-2 "
                >
                  <Close />
                </ButtonUI>
              </div>
            </div>
          </div>
          <div className="px-2 py-1 border rounded-lg md:w-full xs:w-80 mb-8">
            <div className="flex flex-col"></div>
            {selctedEvent.sections.map((s) => {
              return (
                <span>
                  <p className="font-semibold mt-2"> {s.sectionName}</p>
                  <ul className="flex flex-col   ">
                    {s.fields.map((f) => {
                      return (
                        <li className="flex flex-row  p-2 border-b box my-1 justify-between ">
                          <p>{f.label}</p>

                          <p className="mx-2 ">{f.value ? f.value : "N/A"}</p>
                        </li>
                      );
                    })}
                  </ul>
                </span>
              );
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EventTable;
