import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBarCRO from "../components/CommunSideBar";

import "./styles-layout-doctor.scss";

const LayoutCRO = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);

  const location = useLocation();

  useEffect(() => {
    console.log(
      "🚀 ~ file: LayoutCro.tsx:16 ~ useEffect ~ location.pathname.slice(0, 7):",
      location.pathname.slice(0, 15)
    );
    if (
      location.pathname.slice(0, 17) === "/add/organisation" ||
      location.pathname.slice(0, 7) === "/study/" ||
      location.pathname.slice(0, 15) === "/InvitationPage" ||
      location.pathname === "/firstLogin" ||
      location.pathname === "/firstLogin" ||
      /^\/eproForms\/\w+\/\w+$/.test(location.pathname) ||
      location.pathname.startsWith("/translate/")
    )
      setShowSideBar(false);
    else setShowSideBar(true);
  }, [location.pathname]);

  return (
    <div className="layout-doctor flex h-full">
      {showSideBar && <SideBarCRO />}
      <div className="w-full md:pl-4">{children}</div>
    </div>
  );
};

export default LayoutCRO;
