import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  createParicipantsEpro,
  getRandomisationConfig,
  getStudyCenterInfo,
} from "../../../api/study";
import Button from "../../../components/ButtonUI";
import makeToast from "../../../components/Snackbar";
import { userAtom } from "../../../state/auth";
import { socketAtom } from "../../../state/socket";
import { studyWriterConfigAtom } from "../../../state/studyWriterConfig";
import { testModeAtom } from "../../../state/testModeAtom";
import "../styles.scss";
import { BsFileEarmarkRuled,BsArrowDown } from "react-icons/bs";
import DynamicTable from "../../../components/DynamicTable";
import Tooltip from "../../../components/toolTip";
const EproInvitationModal: React.FC<any> = ({
  setOpenModal,
  action,
  handleLoadingInclusion,
}) => {
  const [emails, setEmails] = useState([]);
  const [fileState, setFileState] = useState<any>();
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [user] = useAtom(userAtom);
  const [studyWriterConfig] = useAtom(studyWriterConfigAtom);
  const params = useParams() as any;
  const { studyId } = params;
  const [loadStudyInfos, setLoadStudyInfos] = useState(false);
  const [shouldUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [listCenters, setListCenters] = useState([]) as any[];
  const [listGroups, setListGroups] = useState([]) as any[];
  const [participantCountByCenter, setParticipantCountByCenter] = useState(0);
  const { t } = useTranslation() as any;
  const [randomConfig, setRandomConfig] = useState() as any;
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const [socket] = useAtom(socketAtom) as any;
  interface Data {
    email: string;
    firstName?: string;
    lastName?: string;
  }
  function s2ab(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }
  function generateExcelFile(
    anonym: boolean,
    manuelRando: boolean,
    data: Data[]
  ) {
    let columns: string[] = ["email"];

    if (!anonym) {
      columns.push("firstName", "lastName");
    }

    if (manuelRando) {
      // Add additional columns for manual randomization if needed
    }

    const worksheetData = [columns];

    data.forEach((item) => {
      const row: any[] = columns.map((col) => item[col]);
      worksheetData.push(row);
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBlob = new Blob(
      [s2ab(XLSX.write(workbook, { bookType: "xlsx", type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );

    const url = window.URL.createObjectURL(excelBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "example.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  const _getColumnsToDisplay = (anonym, randomManuel) => {
    let columns = ["ID", "E-mail"];
    if (!anonym) columns.push("Full name");
    if (randomManuel) columns.push("Groupe");
    return columns;
  };
  const _getCenter = (i) => {
    const newCount: number = participantCountByCenter + i;
    let string = testMode
      ? "Test" + newCount.toString().padStart(4, "0")
      : newCount.toString().padStart(4, "0");
    return loadStudyInfos
      ? "..."
      : studyWriterConfig?.centerId?.abreviation + string;
  };
  async function fetchMyAPI(): Promise<void> {
    setLoadStudyInfos(true);
    const countParticipantByCenter = await getStudyCenterInfo(
      studyId,
      studyWriterConfig?.centerId._id,
      testMode
    );
    if (
      countParticipantByCenter &&
      (countParticipantByCenter.participantCount ||
        countParticipantByCenter.participantCount == 0)
    ) {
      setLoadStudyInfos(false);
    }
    setParticipantCountByCenter(countParticipantByCenter.participantCount);
    // setListCenters(
    //   studyWriterConfig?.centerId
    //     ? [
    //         {
    //           label: studyWriterConfig?.centerId.name,
    //           value: studyWriterConfig?.centerId._id,
    //         },
    //       ]
    //     : []
    // );
  }
  async function fetchMyAPI2(): Promise<void> {
    try {
      const randomisationConfig = await getRandomisationConfig(
        studyWriterConfig?.studyId?.randomisationConfig
      );
      setRandomConfig(randomisationConfig?.config);
      console.log(
        "🚀 ~ file: index.tsx:88 ~ fetchMyAPI2 ~ randomisationConfig:",
        randomisationConfig
      );
    } catch (error) {
      makeToast("error", "error");
    }
  }

  useEffect(() => {
    fetchMyAPI();
    if (studyWriterConfig?.studyId?.randomisationConfig) fetchMyAPI2();
  }, [shouldUpdate]);

  useEffect(() => {
    if (randomConfig && !randomConfig.auto && randomConfig.onCreation) {
      const formattedGroups = randomConfig.groups.map((group) => ({
        label: group.groupName,
        value: group._id,
      }));
      setListGroups(formattedGroups);
    } else {
      setListGroups([]);
    }
  }, [randomConfig]);
  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      console.log("🚀 ~ file: index.tsx:13 ~ handleFileChange ~ file:", file);

      if (!file) return;
      setFileState(file);
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(sheet);
        console.log(
          "🚀 ~ file: index.tsx:190 ~ reader.onload= ~ excelData:",
          excelData
        );

        // Check for the "email" attribute and validate emails
        const extractedEmails: any = excelData
          .filter(
            (row: any) =>
              row.hasOwnProperty("email") && isValidEmail(row["email"])
          )
          .map((row: any, i: number) => ({
            email: row["email"],
            publicId: _getCenter(i),
            lastName: row["lastName"],
            firstName: row["firstName"],
          }));

        if (!extractedEmails || extractedEmails.length === 0) {
          setEmails([]);
          setFileState(null);
          return makeToast("warning", `${t("Participant.verifFile")}`);
        }
        setEmails(extractedEmails);

        // Invalid emails
        const invalid: any = excelData
          .filter(
            (row: any) =>
              row.hasOwnProperty("email") && !isValidEmail(row["email"])
          )
          .map((row: any) => ({
            email: row["email"],
            lastName: row["lastName"],
            firstName: row["firstName"],
          }));

        setInvalidEmails(invalid);
      };

      reader.readAsBinaryString(file);
    } catch (error) {}
  };
  const isValidEmail = (email) => {
    // Basic email regex validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (values) => {
    try {
      if (isSubmitting) {
        return;
      } else {
        setIsSubmitting(true);
        const res = await createParicipantsEpro(values, socket);
        if (!socket) {
          if (res) {
            makeToast("success", `${t("Participant.successAdds")}`);
            setIsSubmitting(false);
            setOpenModal(false);
            action();
          } else {
            setIsSubmitting(false);
            makeToast("warning", `${t("Participant.errorAdds")}`);
          }
        } else {
          setIsSubmitting(false);
          makeToast("info", `${t("Participant.socketSends")}`);
          setOpenModal(false);

          if (handleLoadingInclusion) handleLoadingInclusion();
        }
      }
    } catch (err) {
      console.log("[error] add Patient -on Submit :", err);
    }
  };
  return (
    <div className="relative">
      <h1 className="mt-2 mb-2 text-center">
        {t("Participant.eproCreationTitle")}
      </h1>
      <h3 className="mt-1 mb-0 text-center">
        {t("Participant.eproImportOnMass")}
      </h3>
      <div className="flex  gap-x-2 w-full mt-1.5  justify-center">
       
        <button
          className="flex flex-col rounded-md  color-primary-grey-title justify-center  items-center gap-x-2  px-4 py-1 text-sm"
          onClick={() => {
            const data: Data[] = [
              { email: "john@example.com", firstName: "John", lastName: "Doe" },
              // { email: "jane@example.com", firstName: "Jane", lastName: "Smith" },
            ];

            generateExcelFile(studyWriterConfig?.studyId.anonym, false, data);
          }}
        >
          
      
           <div className="flex relative items-center justify-center"> <BsFileEarmarkRuled className="text-green-600 " size={28} /><BsArrowDown size={28} className="absolute right-5  text-green-600"/></div>
            {t("Participant.sampletemplateExel")}
          {/* </Tooltip> */}
        </button>{" "}
      </div>

      <div className="w-full h-72 overflow-y-auto container ">
        <div className="flex flex-col gap-y-3  px-16 ">
          <div
            className="custom-file-input border rounded-lg whitespace-nowrap truncate"
            style={{ borderColor: "#4565f6" }}
          >
            <label
              htmlFor="file-input"
              className="custom-file-label bg-gray-200"
            >
              {t("Participant.eproImportFile")}
            </label>
            <input
              id="file-input"
              type="file"
              accept=".xlsx, .csv"
              onChange={handleFileChange}
            />
            <span className="truncate ml-2 text-sm">
              {fileState && fileState.name
                ? fileState.name
                : t("Participant.eproSelectFile")}
            </span>
          </div>
        </div>

        {emails && emails.length > 0 ? (
          <div className="p-2 h-56 overflow-y-auto ">
            <h3 className="text-center mb-2 text-sm ">
              {emails.length} {t("Participant.eproImportedParticipant")}
            </h3>
            <DynamicTable
              data={emails}
              columns={_getColumnsToDisplay(
                studyWriterConfig?.studyId.anonym,
                studyWriterConfig?.studyId?.randomisationConfig &&
                  randomConfig &&
                  !randomConfig.auto &&
                  randomConfig.onCreation
              )}
              listGroups={listGroups}
              onUpdateGroupId={setEmails}
            />
          </div>
        ) : (
          <div className="p-2 h-56 overflow-y-auto ">
            <h3 className="text-center mb-2 text-sm ">
              {0} {t("Participant.eproImportedParticipant")}
            </h3>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-4 mb-1">
        <Button
          status="secondary"
          className="px-2 py-2 w-36 btn-delete"
          onClick={() => setOpenModal(false)}
        >
          {t("Participant.Cancel")}
        </Button>
        <Button
          onClick={() =>
            handleSubmit({
              centerId: studyWriterConfig?.centerId._id || "",
              studyId: studyId,
              organisationId: user?.organisationId,
              testMode: testMode,
              participantsData: emails,
            })
          }
          status="primary"
          className="px-2 py-2 w-36"
        >
          {t("Participant.Success")}
        </Button>
      </div>
    </div>
  );
};

export default EproInvitationModal;
