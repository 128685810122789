import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { addPublicWriter, getPublicStudy } from "../../api/invitation";
import Spinner from "../../components/Spinner";
import makeToast from "../../components/Snackbar";
import history from "../../history";
import { useTranslation } from "react-i18next";
import voir from "../../assets/images/voir.png";
import Button from "../../components/ButtonUI";
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is Required"),
  lastName: Yup.string().required("This field is Required"),

  centerType: Yup.string().required("This field is Required"),
  centerId: Yup.string().when("centerType", {
    is: "public",
    then: Yup.string().required("This field is Required"),
  }),
  centerName: Yup.string().when("centerType", {
    is: "private",
    then: Yup.string().required("This field is Required"),
  }),
  profile: Yup.string().required("This field is Required"),
  password: Yup.string()
    .required("This field is Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters including at least one uppercase letter, one lowercase letter, one digit, and one special character"
    ),
  confirmPassword: Yup.string()
    .required("This field is Required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});
const InvitationPage = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const { studyId, tenantId } = params;
  const [studyOptions, setStudyOptions] = useState() as any;
  const [passwordFormType, setPasswordFormType] = useState("password") as any;
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async (values: any) => {
    setSubmitting(true);
    let privateC = values.centerType === "private" ? true : false;
    // if(!privateC && (!values.centerId || values.centerId === "")){
    //   return makeToast('warr','Please select a center')

    // }
    const payload = {
      roleId: values.profile,
      lastName: values.lastName,
      firstName: values.firstName,
      centerId: values.centerId,
      privateCenter: privateC,
      password: values.password,
      email: values.email,
      centerName: values.centerName,
    };
    console.log("🚀 ~ file: index.tsx:35 ~ handleSubmit ~ payload:", payload);
    const res = await addPublicWriter(payload, tenantId, studyId);
    console.log("🚀 ~ file: index.tsx:41 ~ handleSubmit ~ res:", res);

    if (res && res.data) {
      makeToast("success", `${t("SignUp.SuccessMsg")}`);
      history.push({
        pathname: `/confirm-email/${payload.email}`,
        state: { password: payload.password },
      });
      setSubmitting(false);
    } else {
      makeToast("warning", `${t("SignUp.WriterExist")}`);
      // history.push('/')
      setSubmitting(false);
    }
  };

  const centerTypeOptions = [
    { value: "public", label: "Public Center" },
    { value: "private", label: "Private Center" },
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    centerType: "public",
    centerId: "",
    profile: "",
    centerName: "",
    email: "",
  };

  const fetchStudyInfos = async (studyId, tenantId) => {
    try {
      setIsLoading(true);
      const res = await getPublicStudy({ studyId, tenantId });
      console.log("🚀 ~ file: index.tsx:61 ~ fetchStudyInfos ~ res:", res);

      if (res) {
        if (res.msg === "EXPIRED") {
          setIsLoading(false);
          makeToast("warning", "Expired invitation");
          history.push("/");
        } else {
          setStudyOptions(res);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("🚀 ~ file: index.tsx:49 ~ fetchStudyInfos ~ error:", error);
    }
  };
  useEffect(() => {
    fetchStudyInfos(studyId, tenantId);
  }, []);
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="bg-white py-24 rounded-lg shadow-md ">
      <h1 className="text-center py-8">
        Invitation to the {`${studyOptions?.name}`} study{" "}
      </h1>
      <div className="flex justify-center items-center mt-8   ">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, isValid }) => (
            <div className="w-full max-w-md md:px-1 px-16  ">
              <div className="mb-8">
                <label
                  htmlFor="firstName"
                  className=" text-gray-700 font-bold mb-2 flex"
                >
                  First Name<p className="text-red-400 ml-1 text-sm">*</p>
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="Enter your first name"
                  className="  border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                />
                <ErrorMessage
                  name="firstName"
                  className="text-red-400 text-sm mt-1"
                  component="div"
                />
              </div>

              <div className="mb-8">
                <label
                  htmlFor="lastName"
                  className="flex text-gray-700 font-bold mb-2"
                >
                  Last Name<p className="text-red-400 ml-1 text-sm">*</p>
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Enter your last name"
                  className="sborder border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                />
                <ErrorMessage
                  name="lastName"
                  className="text-red-400 text-sm mt-1"
                  component="div"
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="email"
                  className="flex text-gray-700 font-bold mb-2"
                >
                  Email<p className="text-red-400 ml-1 text-sm">*</p>
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-500 leading-tight  focus:shadow-outline"
                />
                <ErrorMessage
                  name="email"
                  className="text-red-400 text-sm mt-1"
                  component="div"
                />
              </div>

            {studyOptions?.privateCenter &&   <div className="mb-8">
                <div className="flex text-gray-700 font-bold mb-2">
                  Center Type<p className="text-red-400 ml-1 text-sm">*</p>
                </div>
                {centerTypeOptions.map((option) => (
                  <label
                    key={option.value}
                    className="inline-flex items-center"
                  >
                    <Field
                      type="radio"
                      name="centerType"
                      value={option.value}
                      className="form-radio h-5 w-5 text-gray-600 ml-2"
                    />
                    <span className="ml-2">{option.label}</span>
                  </label>
                ))}
                <ErrorMessage
                  name="centerType"
                  className="text-red-400 text-sm mt-1"
                  component="div"
                />
              </div>}
              {values.centerType === "public" &&
                studyOptions &&
                studyOptions.centers && (
                  <div className="mb-8">
                    <label
                      htmlFor="centerId"
                      className="flex text-gray-700 font-bold mb-2"
                    >
                      Center Name <p className="text-red-400 ml-1 text-sm">*</p>
                    </label>
                    <Field
                      id="centerId"
                      name="centerId"
                      as="select"
                      placeholder="Select a center"
                      className="relative border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                    >
                      <option value="" disabled hidden>
                        Select a center
                      </option>
                      <option value="" disabled>
                        Select a center
                      </option>
                      {studyOptions.centers.map((option) => (
                        <option
                          className="bg-gray-300"
                          key={option._id}
                          value={option._id}
                        >
                          {option.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="centerId"
                      className="text-red-400 text-sm mt-1"
                      component="div"
                    />
                  </div>
                )}
              {values.centerType === "private" && studyOptions && (
                <div className="mb-8">
                  <label
                    htmlFor="firstName"
                    className=" text-gray-700 font-bold mb-2 flex"
                  >
                    Center Name<p className="text-red-400 ml-1 text-sm">*</p>
                  </label>
                  <Field
                    id="centerName"
                    name="centerName"
                    type="text"
                    placeholder="Enter your center name"
                    className="  border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                  />
                  <ErrorMessage
                    name="centerName"
                    className="text-red-400 text-sm mt-1"
                    component="div"
                  />
                </div>
              )}

              {studyOptions && studyOptions.roles && (
                <div className="mb-8">
                  <label
                    htmlFor="profile"
                    className="flex text-gray-700 font-bold mb-2"
                  >
                    Profile<p className="text-red-400 ml-1 text-sm">*</p>
                  </label>
                  <Field
                    id="profile"
                    name="profile"
                    as="select"
                    placeholder="Select a profile"
                    className="border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                  >
                    <option value="" disabled>
                      Select a profile
                    </option>
                    {studyOptions.roles.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="profile"
                    className="text-red-400 text-sm mt-1"
                    component="div"
                  />
                </div>
              )}

              <div className="mb-8 relative">
                <label
                  htmlFor="password"
                  className=" text-gray-700 font-bold mb-2 flex"
                >
                  Password<p className="text-red-400 ml-1 text-sm">*</p>
                </label>
                <Field
                  id="password"
                  name="password"
                  type={passwordFormType}
                  placeholder="Enter your password"
                  className="  border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                />

                <ErrorMessage
                  name="password"
                  className="text-red-400 text-sm mt-1"
                  component="div"
                />
                <img
                  src={voir}
                  alt="voir"
                  className="absolute right-3 top-14 w-4 h-3 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setPasswordFormType(
                      passwordFormType === "password" ? "text" : "password"
                    );
                  }}
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="confirmPassword"
                  className=" text-gray-700 font-bold mb-2 flex"
                >
                  Confirm Password<p className="text-red-400 ml-1 text-sm">*</p>
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm your password"
                  className="  border border-gray-200 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm w-full p-3 h-14   shadow appearance-none  py-2 px-3 text-gray-700 leading-tight  focus:shadow-outline"
                />
                <ErrorMessage
                  name="confirmPassword"
                  className="text-red-400 text-sm mt-1"
                  component="div"
                />
              </div>

              <Button
                status={"primary"}
                onClick={() => {
                  handleSubmit(values);
                }}
                disabled={!isValid || isSubmitting || submitting}
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Sign up
              </Button>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default InvitationPage;
