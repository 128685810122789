/* eslint-disable react-hooks/exhaustive-deps */
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getEconsultByWriter, getPastEconsultByWriter } from "../../api/study";
import Button from "../../components/ButtonUI";
import MyModal from "../../components/New-Modal";
import { userAtom } from "../../state/auth";
import { StudySelectedIdAtom } from "../../state/studySelectedIdAtom";
import EconsultCard from "./EconsultCard";
import EconsultSoonCard from "./EconsultSoonCard";
import AddEconsultModal from "./Modals/AddEconsultModal";
import "./styles.scss";
import Pagination from "../../components/PaginationTable";

export const Econsult = ({}) => {
  const params = useParams() as any;

  const { studyId } = params;
  const typingTimeoutRef = useRef(null) as any;
  const [totalResult, setTotalResult] = useState(0);
  const [, setStudySelectedId] = useAtom(StudySelectedIdAtom);
  const { t } = useTranslation() as any;
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [newEconsultModal, setNewEconsultModal] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [eConsultList, setEConsultList] = useState() as any;
  const [eConsultPastList, setEConsultPastList] = useState() as any;
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [textSearch, setTextSearch] = useState("");

  const onSearch = (e) => {
    const value: string = e.target.value.toLowerCase().trim();
    clearTimeout(typingTimeoutRef.current);
    setSearchValue(value);
    typingTimeoutRef.current = setTimeout(() => {
      if (value.length >= 1) {
        setTextSearch(value);
      } else {
        setTextSearch("");
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);
  const getEconsultByWriterCall = async () => {
    try {
      let res: any = [];
      setLoading(true);
      switch (user?.type) {
        default:
          res = await getEconsultByWriter({ studyId: studyId });
          if (res) {
            setEConsultList(res);
          }
          setLoading(false);
          break;
      }
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ file: index.tsx:22 ~ fetchListEvents ~ error", error);
    }
  };
  const fetchMorePastEconsults = async () => {
    try {
      setLoading(true);
      const res = await getPastEconsultByWriter(
        { studyId: studyId },
        page,
        limit,
        textSearch
      );
      setEConsultPastList(res.pastConsults);
      setTotalResult(res.pastConsultsListCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching more past eConsults:", error);
    }
  };
  useEffect(() => {
    if (studyId) setStudySelectedId(studyId);
    getEconsultByWriterCall();
  }, [shouldUpdate]);
  useEffect(() => {
    fetchMorePastEconsults();
  }, [page, textSearch]);

  const handleKeyDown = (event) => {
    const value: string = event.target.value.toLowerCase().trim();
    if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
      if (value.length >= 1) {
        setTextSearch(value);
        setSearchValue(value);
      } else {
        setSearchValue("");
        setTextSearch("");
      }
    }
  };
  return (
    <>
      <header className="flex md:justify-between md:items-center w-full flex-wrap md:flex-row flex-col gap-7">
        <div className="title-container">
          <h1 className="pageTitle">{t("eConsult.eConsultTitle")}</h1>
        </div>
        <div className="flex gap-4">
          {seeMore && (
            <Button
              status="secondary"
              className="px-4 py-1 h-10 flex items-center ml-auto"
              onClick={() => {
                setSeeMore((prev) => !prev);
              }}
            >
              <span className="mb-0 pb-0">{t("eConsult.back")} </span>
            </Button>
          )}

          <Button
            status="secondary"
            className="px-4 py-1 h-10 flex items-center ml-auto"
            onClick={() => setNewEconsultModal(true)}
          >
            <span className="mb-0 pb-0">{t("eConsult.NeweConslt")} </span>
          </Button>
        </div>
      </header>
      {seeMore ? (
        <div className="mt-16 flex flex-wrap justify-between ">
          <div className="w-full">
            <div className="">
              <div className="flex items-center gap-6">
                <div className="flex justify-between py-3 pl-2">
                  <div className="relative max-w-md">
                    <input
                      type="text"
                      name="hs-table-search"
                      id="hs-table-search"
                      onChange={onSearch}
                      value={searchValue}
                      className="block w-full p-3 pl-10 rounded-md border-none ring-none text-xs "
                      placeholder={t("eConsult.search")}
                      style={{ border: "none" }}
                      onKeyDown={handleKeyDown}
                    />

                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <svg
                        className="h-3.5 w-3.5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              {!_.isEmpty(eConsultPastList) ? (
                <>
                  <div className=" pr-5 flex flex-col gap-6 mb-5">
                    {eConsultPastList?.map((el) => (
                      <EconsultCard
                        patientPublicId={el?.participant?.publicId}
                        patientId={el?.participant}
                        motif={el?.motif}
                        date={el?.dateConsultation}
                        status={el?.status}
                        eConsultId={el._id}
                        setShouldUpdate={setShouldUpdate}
                        setSeeMore={setSeeMore}
                      />
                    ))}
                    {totalResult > limit && (
                      <Pagination
                        totalResults={totalResult}
                        resultsPerPage={limit}
                        currentPage={page}
                        onPageChange={function (page: number): void {
                          setPage(page);
                          localStorage.setItem("paginate", page.toString());
                        }}
                        entriesName={"e-Consult"}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.noPastEconsult")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8 flex flex-wrap md:justify-between md:flex-row flex-col md">
          <div className="w-full md:w-4/12">
            {/* Card next e-consult */}
            {(eConsultList?.soonConsults || [])[0] && (
              <div>
                <p className="font-bold my-5">{t("eConsult.nexteConsult")}</p>
                <EconsultSoonCard
                  studyId={studyId}
                  eConsultId={(eConsultList?.soonConsults || [])[0]?._id}
                  eConsult={(eConsultList?.soonConsults || [])[0]}
                  patientPublicId={
                    (eConsultList?.soonConsults || [])[0]?.participant?.publicId
                  }
                  patientId={
                    (eConsultList?.soonConsults || [])[0]?.participant?._id
                  }
                  progress={
                    (eConsultList?.soonConsults || [])[0]?.participant?.progress
                  }
                  motif={(eConsultList?.soonConsults || [])[0]?.motif}
                  date={(eConsultList?.soonConsults || [])[0]?.dateConsultation}
                />
              </div>
            )}
            {/* History e-consult */}
            <div className="">
              <p className="font-bold my-5">{t("eConsult.historyeConsult")}</p>
              {!_.isEmpty(eConsultList?.pastConsults) ? (
                <>
                  <div className=" pr-5 flex flex-col gap-6 mb-5">
                    {eConsultList?.pastConsults?.slice(0, 3)?.map((el) => (
                      <EconsultCard
                        patientPublicId={el?.participant?.publicId}
                        patientId={el?.participant}
                        motif={el?.motif}
                        date={el?.dateConsultation}
                        eConsultId={el._id}
                        setShouldUpdate={setShouldUpdate}
                        setSeeMore={setSeeMore}
                        status={el?.status}
                      />
                    ))}
                  </div>
                  <div className="w-full flex flex-row-reverse">
                    <p
                      className="max-w-max text-blue-400 cursor-pointer text-sm pr-8"
                      onClick={() => {
                        fetchMorePastEconsults();
                        setSeeMore((prev) => !prev);
                      }}
                    >
                      {t("eConsult.seeMore")}
                    </p>
                  </div>
                </>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.noPastEconsult")}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-7/12 ">
            {/* Today e-consult */}
            <div className="">
              <p className="font-bold my-5">{t("eConsult.today")}</p>
              {!_.isEmpty(eConsultList?.todayConsults) ? (
                <div className="max-h-80 overflow-y-auto pr-5 flex flex-col gap-6 mb-5">
                  {eConsultList?.todayConsults?.map((el) => (
                    <EconsultCard
                      patientPublicId={el?.participant?.publicId}
                      patientId={el?.participant}
                      motif={el?.motif}
                      date={el?.dateConsultation}
                      eConsultId={el._id}
                      setShouldUpdate={setShouldUpdate}
                      setSeeMore={setSeeMore}
                      status={el?.status}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.noTodayeconsult")}
                  </span>
                </div>
              )}
            </div>
            <div className="">
              <p className="font-bold my-5">{t("eConsult.thisWeek")}</p>
              {/* this week e-consult */}
              {!_.isEmpty(eConsultList?.thisWeekConsults) ? (
                <div className="max-h-80 overflow-y-auto pr-5 flex flex-col gap-6 mb-5">
                  {eConsultList?.thisWeekConsults?.map((el) => (
                    <EconsultCard
                      patientPublicId={el?.participant?.publicId}
                      patientId={el?.participant}
                      motif={el?.motif}
                      date={el?.dateConsultation}
                      eConsultId={el._id}
                      setShouldUpdate={setShouldUpdate}
                      setSeeMore={setSeeMore}
                      status={el?.status}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white pr-5 flex flex-col mb-5 py-2 rounded-xl text-center">
                  <span className="text-gray-400 text-xs">
                    {t("eConsult.NoWeekEconsult")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <MyModal showModal={newEconsultModal} closeModal={setNewEconsultModal}>
        <AddEconsultModal
          closeModal={() => setNewEconsultModal(false)}
          refresh={() => setShouldUpdate((up) => !up)}
          setSeeMore={setSeeMore}
        />
      </MyModal>
    </>
  );
};

export default Econsult;
