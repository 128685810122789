import { useAtom } from "jotai";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AiFillCheckCircle,
  AiFillEyeInvisible,
  AiFillLock,
  AiOutlineCheckCircle,
  AiOutlineHistory,
  AiOutlineUnlock,
} from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { getQueriesPerField } from "../../../api/queries";
import { getHistoryPerField, sdvField } from "../../../api/study";
import {
  resolver,
  resolverDisablingField,
} from "../../../helper/FormGeneratorHelper/conditionalLogic";
import { userAtom } from "../../../state/auth";
import { participantAtom } from "../../../state/participant";
import { visteAtom } from "../../../state/visteAtom";
import { User } from "../../../types/user";
import Button from "../../ButtonUI";
import Modal from "../../ModalUI";
import makeToast from "../../Snackbar";
import { FormElement } from "../Elements";
import {
  ConfirmSdvModal,
  HistoryFieldModal,
  NewQueryFieldModal,
  QueryHasDataModal,
} from "./Modals";

import { ReactComponent as Close } from "../../../assets/icons/icon-close.svg";
import "./styles.scss";
import { socketAtom } from "../../../state/socket";
import { studyWriterConfigAtom } from "../../../state/studyWriterConfig";
import { getTranslationBySurveyId } from "../../../api/translation";
import Tooltip from "../../toolTip";

interface queryBody {
  creratedBy: String;
  to: String;
  userType: String;
  textMessage: String;
  studyId: String;
  participantId: String;
  formDataId: String;
  roleId: String;
  centerId: String;
  fieldId: String;
  fieldName: String;
  byArcToWriterId: String;
}
const FormRenderer = ({
  formDataId = null,
  surveyId = null,
  elements,
  form,
  isPreview = false,
  isEvent = false,
  isExit = false,
  isEpro = false,
  readOnly = false,
  ...props
}) => {
  console.log("🚀 ~ file: index.tsx:68 ~ elements:", elements);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );
  const { t } = useTranslation() as any;
  const [formValue, setFormValue] = useState(props.values);
  const [selectedElment, setSelectedElment] = useState() as any;
  const [loadingHistory, setLoadingHistory] = useState(false) as any;
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState([]) as any[];
  const [queries, setQueries] = useState([]) as any[];
  const [showQuery, setShowQuery] = useState(false);
  const [participant] = useAtom(participantAtom);
  const [loadingQuery] = useState(false);
  const [loadingQueryHasData] = useState(false);
  const [showQueryHasData, setShowQueryHasData] = useState(false);
  const [confirmSdvModal, setConfirmSdvModal] = useState(false);
  const [selectedStatusQuery, setSelectedStatusQuery] = useState() as any;
  const [textMesg, setTexteMsg] = useState("");
  const params: any = useParams();
  const { studyId, participantId } = params;
  const [formData, setFormData] = useAtom(visteAtom);
  const [user] = useAtom<User | null>(userAtom) as any;
  const [socket] = useAtom(socketAtom) as any;
  const [translation, setTranslation] = useState() as any;
  const { type } = user;
  const isWriter = type === "writer";
  const isSponsor = type === "sponsor";
  const [formTemplateId, setFormTemplatedId] = useState() as any;

  const languageGroup = (
    localStorage.getItem("i18nextLng") || navigator.language
  )
    .toString()
    .startsWith("fr")
    ? "Français"
    : "Anglais";

  // const getFormTemplateById = async () => {
  //   try {
  //     const res = await getFormadataById(formDataId);
  //     setFormTemplatedId(res.formTemplateId);
  //   } catch (error) {
  //     console.error(error);
  //     Promise.reject(error);
  //   }
  // };
  useEffect(() => {
    if (formData && formData?.formTemplateId)
      setFormTemplatedId(formData?.formTemplateId);
  }, [formData]);

  const getTranslationFormData = async () => {
    try {
      let translatedSurvery;
      if (user?.type !== "cro") {
        translatedSurvery = await getTranslationBySurveyId(
          formTemplateId,
          languageGroup
        );
      } else {
        translatedSurvery = await getTranslationBySurveyId(
          formTemplateId,
          languageGroup
        );
        console.log(
          "🚀 ~ file: index.tsx:118 ~ getTranslationFormData ~ translatedSurvery:",
          translatedSurvery
        );
      }

      if (translatedSurvery && translatedSurvery.translation) {
        setTranslation(translatedSurvery?.translation[0]);
      } else {
        setTranslation(null);
      }
    } catch (error) {
      console.error(error);
      Promise.reject(error);
    }
  };

  useEffect(() => {
    setFormValue(props.values);
  }, [props.values]);
  const fetchHistorty = async () => {
    setLoadingHistory(true);
    const res = await getHistoryPerField(
      { fieldName: selectedElment?.field_name, stepNbr: props?.stepNbr },
      formDataId,
      isEvent
    );

    if (res) setHistory(res?.newForm);
    setTimeout(() => setLoadingHistory(false), 500);
  };

  const hasOpenQueries = (element) => {
    console.log(
      "🚀 ~ file: index.tsx:107 ~ hasOpenQueries ~ element:",
      element
    );
    const openQueries = (element) => element.status === "OPEN";
    return element?.queries?.some(openQueries);
  };
  const countOpenQueries = (element) => {
    const openQueries = (element) => element.status === "OPEN";
    let res = element.queries.filter(openQueries);
    return res.length;
  };

  useEffect(() => {
    if (selectedElment && showHistory) {
      fetchHistorty();
    }
  }, [selectedElment]);

  const addQueryToField = async (element: any, formDataId: any) => {
    console.log("🚀 ~ addQueryToField ~ element:", element);
    try {
      if (!textMesg || textMesg === "") return;
      let queryObject: queryBody = {
        centerId: participant?.centerId,
        creratedBy: user._id,
        fieldId: element.field_name,
        fieldName: element.label,
        formDataId: formDataId || "",
        participantId: participantId,
        roleId: !isEvent ? formData.roleId : null,
        studyId: studyId,
        byArcToWriterId: element?.updatedBy,
        to: element?.updatedBy,
        textMessage: textMesg,
        userType: type,
      };
      const payload = {
        stepNbr: props?.stepNbr,
        fieldName: element.field_name,
        queryBody: queryObject,
      };
      console.log("🚀 this done from react payload:", payload);

      if (socket) {
        socket.emit("AddingQuery", { roomId: formDataId, payload, isEvent });
      }
      makeToast("success", t("missingWording.frt1"));
      setShowQuery(false);
      // setFormData(res?.newForm);
      // props.setShouldUpdate((up) => !up);
      setTexteMsg("");
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:86 ~ addQueryPerField ~ error", error);
    }
  };
  const getQueriesField = async (fieldId, participantId) => {
    try {
      const res = await getQueriesPerField(fieldId, participantId);
      setQueries(res?.queryList.filter((elm) => elm.status === "OPEN"));
      setShowQueryHasData(true);
      setSelectedStatusQuery(
        res?.queryList.filter((elm) => elm.status === "OPEN")[0].status
      );
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:123 ~ getQueriesField ~ error", error);
    }
  };

  const updateQueryMessage = async (queryId, values) => {
    try {
      // const res = await updateQuery(queryId, values);
      if (socket) {
        socket.emit("updateQuery", {
          roomId: formDataId,
          message: { ...values, queryId },
        });
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const validateField = async (element, sdv) => {
    try {
      const res = await sdvField(
        {
          fieldName: element.field_name,
          stepNbr: props?.stepNbr,
          value: sdv,
        },
        formDataId
      );
      if (res && res?.newForm) {
        setFormData(res?.newForm);
        socket.emit("SdvField", {
          roomId: formDataId,
          message: {
            fieldName: element.field_name,
            stepNbr: props?.stepNbr,
            value: sdv,
          },
        });
        makeToast("success", t("missingWording.frt2"));
      }
      props.setShouldUpdate((up) => !up);
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:177 ~ validateFild ~ error", error);
    }
  };
  const intl = useIntl();

  // useEffect(() => {
  //   getFormTemplateById();
  // }, []);

  useEffect(() => {
    if (formTemplateId !== undefined) {
      getTranslationFormData();
    } else {
      setTranslation(null);
    }
  }, [formTemplateId]);

  return (
    <div className="bg-white md:p-4 xs:p-1 rounded-lg md:m-1 xs:m-0 xs:text-xs md:text-sm ">
      {elements?.length > 0 ? (
        elements.map((element) => {
          
          return element.type !== "LineBreak" ? (
            resolver(element.conditionLogic, formValue) && (
              <div
                className="flex items-center  rounded-lg md:p-2 xs:p-0.5 mt-5 firstField"
                onClick={() => setSelectedElment(element)}
              >
                <FormElement
                  formValue={formValue}
                  form={form}
                  key={element.id}
                  element={element}
                  isEvent={isEvent}
                  readOnly={
                    (!isWriter && !isPreview) ||
                    (type &&
                      type === "writer" &&
                      (element.freeze || element.sdv)) ||
                    (formData?.status === "APPROVED" && !isPreview) ||
                    (formData?.status === "LOCKED" && !isPreview) ||
                    (isExit && !element.isExitReason) ||
                    (studyWriterConfig &&
                      studyWriterConfig.studyId &&
                      studyWriterConfig.studyId.status === "END") ||
                    resolverDisablingField(element.conditionLogic, formValue) ||
                    readOnly
                      ? true
                      : false
                  }
                  formDataId={formDataId}
                  stepNbr={props?.stepNbr}
                  setShouldUpdate={props.setShouldUpdate}
                  fieldOnSubmit={
                    props?.fieldOnSubmit && !element.freeze
                      ? props?.fieldOnSubmit
                      : false
                  }
                  isPreview={isPreview}
                  sharedCalculVars={element.sharedCalculVars ?? false}
                  sharedStatsVars={element.sharedStatsVars ?? false}
                  translation={translation}
                  isEpro={isEpro}
                 
                />
                {formData?.status !== "LOCKED" && (
                  <div className="ml-2">
                    {!isWriter &&
                      !isSponsor &&
                     ( !element?.content && element?.type !=='Image' )&&
                      element?.isVisible &&
                      !isPreview && (
                        <div className="flex md:flex-row xs:flex-col xs:items-baseline xs:gap-y-2 md:gap-y-0">
                          {!isWriter &&
                            !element?.content &&
                            element?.isVisible &&
                            !isPreview && (
                              <Tooltip
                                clsText={{
                                  minWidth: "20px",
                                  maxWidth: "120px",
                                  minHeight: "1.6rem",
                                  maxHeight: "2.2rem",
                                  fontSize: "11px",
                                }}
                                tooltipText={t("missingWording.freeze")}
                                position={"top"}
                              >
                                <p
                                  className="cursor-pointer"
                                  onClick={async () => {
                                    try {
                                      socket.emit("freezField", {
                                        roomId: formDataId,
                                        message: {
                                          fieldName: element.field_name,
                                          stepNbr: props?.stepNbr,
                                          value: !element.freeze,
                                          formDataId,
                                          isEvent,
                                        },
                                      });

                                      props.setShouldUpdate((up) => !up);
                                      makeToast(
                                        "success",
                                        !element.freeze
                                          ? t("missingWording.frt3")
                                          : t("missingWording.frt4")
                                      );
                                    } catch (error) {
                                      console.log(
                                        "🚀Error index.tsx:300",
                                        error
                                      );
                                    }
                                  }}
                                >
                                  {element.freeze ? (
                                    <AiFillLock
                                      color="#faad14"
                                      width={20}
                                      height={20}
                                      size={22}
                                    />
                                  ) : (
                                    <AiOutlineUnlock
                                      color="#faad14"
                                      width={20}
                                      height={20}
                                      size={22}
                                    />
                                  )}
                                </p>
                              </Tooltip>
                            )}

                          {isWriter && (
                            <Tooltip
                              clsText={{
                                minWidth: "20px",
                                maxWidth: "120px",
                                minHeight: "1.6rem",
                                maxHeight: "2.2rem",
                                fontSize: "11px",
                              }}
                              tooltipText={t("missingWording.freezed")}
                              position={"top"}
                            >
                              <p className="cursor-not-allowed">
                                {element.freeze && (
                                  <AiFillLock
                                    color="#faad14"
                                    width={20}
                                    height={20}
                                    size={22}
                                  />
                                )}
                              </p>
                            </Tooltip>
                          )}
                          <Tooltip
                            clsText={{
                              minWidth: "20px",
                              maxWidth: "120px",
                              minHeight: "1.6rem",
                              maxHeight: "2.2rem",
                              fontSize: "11px",
                            }}
                            tooltipText={t("missingWording.history")}
                            position={"top"}
                          >
                            <div
                              onClick={() => {
                                setShowHistory(true);
                                setSelectedElment(element);
                              }}
                              className="ml-4 cursor-pointer "
                            >
                              <AiOutlineHistory
                                color="#faad14"
                                width={20}
                                height={20}
                                size={22}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip
                            clsText={{
                              minWidth: "20px",
                              maxWidth: "120px",
                              minHeight: "1.6rem",
                              maxHeight: "2.2rem",
                              fontSize: "11px",
                            }}
                            tooltipText={t("missingWording.queries")}
                            position={"top"}
                          >
                            <div
                              onClick={() => {
                                setSelectedElment(element);
                                if (false) return;

                                if (!isPreview) {
                                  if (hasOpenQueries(element))
                                    getQueriesField(
                                      element.field_name,
                                      participantId
                                    );
                                  else setShowQuery(true);
                                }
                              }}
                              className={`mr-4 ml-4  relative 
                        ${false ? "cursor-not-allowed" : "cursor-pointer"}`}
                            >
                              {!isPreview && hasOpenQueries(element) && (
                                <span
                                  style={{ fontSize: "10px" }}
                                  className="right-0 -top-2 md:absolute xs:relative inline-block py-0.5 px-0.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded ml-2"
                                >
                                  {countOpenQueries(element)}
                                </span>
                              )}
                              <div>
                                <BsQuestionCircle
                                  color={`
                          ${false ? "#8a8993" : "#faad14"}`}
                                  width={20}
                                  height={20}
                                  size={22}
                                />
                              </div>
                            </div>
                          </Tooltip>
                          {!element?.sdv &&
                            !(formData?.status === "APPROVED") &&
                            !isEvent && (
                              <div
                                onClick={() => setConfirmSdvModal(true)}
                                className={
                                  hasOpenQueries(element)
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }
                              >
                                <Tooltip
                                  clsText={{
                                    minWidth: "20px",
                                    maxWidth: "120px",
                                    minHeight: "1.6rem",
                                    maxHeight: "2.2rem",
                                    fontSize: "11px",
                                  }}
                                  tooltipText={t("missingWording.sdv")}
                                  position={"top"}
                                >
                                  <div>
                                    <AiOutlineCheckCircle
                                      color={
                                        hasOpenQueries(element)
                                          ? "#8a8993"
                                          : "#4bb640"
                                      }
                                      width={20}
                                      height={20}
                                      size={22}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                                {(element?.sdv || formData?.status === "APPROVED") && (
                      <div className="cursor-help">
                        <Tooltip
                          clsText={{
                            minWidth: "20px",
                            maxWidth: "120px",
                            minHeight: "1.6rem",
                            maxHeight: "2.2rem",
                            fontSize: "11px",
                          }}
                          tooltipText={t("missingWording.freeze")}
                          position={"top"}
                        >
                          <div>
                            <AiFillCheckCircle
                              color={"#4bb640"}
                              width={20}
                              height={20}
                              size={22}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                        </div>
                      )}
                
                    {!isWriter &&
                      !element?.content &&
                      !element?.isVisible &&
                      !isPreview && (
                        <div className="cursor-help">
                          <Tooltip
                            clsText={{
                         
                              fontSize: "11px",
                            }}
                            tooltipText={t("missingWording.invisible")}
                            position={"top"}
                          >
                            <div>
                              <AiFillEyeInvisible
                                color="#b5b5b5"
                                width={25}
                                height={25}
                                size={22}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                    {type && type === "writer" && hasOpenQueries(element) && (
                      <div className="flex">
                        <div
                          onClick={() => {
                            setSelectedElment(element);
                            if (hasOpenQueries(element))
                              getQueriesField(
                                element.field_name,
                                participantId
                              );
                            else setShowQuery(true);
                          }}
                          className="mr-4 ml-4 cursor-pointer relative"
                        >
                          {hasOpenQueries(element) && (
                            <span
                              style={{ fontSize: "10px" }}
                              className=" right-0 -top-2 absolute inline-block py-0.5 px-0.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded ml-2"
                            >
                              {countOpenQueries(element)}
                            </span>
                          )}
                          <div>
                            <BsQuestionCircle
                              color="#faad14"
                              width={20}
                              height={20}
                              size={22}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          ) : (
            <FormElement
              form={form}
              key={element.id}
              element={element}
              readOnly={(!isWriter && !isPreview) || readOnly ? true : false}
              formDataId={formDataId}
              isEvent={isEvent}
              stepNbr={props?.stepNbr}
              setShouldUpdate={props.setShouldUpdate}
              fieldOnSubmit={
                props?.fieldOnSubmit ? props?.fieldOnSubmit : false
              }
              isPreview={isPreview}
              sharedCalculVars={element.sharedCalculVars ?? false}
              sharedStatsVars={element.sharedStatsVars ?? false}
              translation={translation}
              isEpro={isEpro}
              
            />
          );
        })
      ) : (
        <span className="text-gray-400 flexCenter ">
          {intl.formatMessage({ id: "noItmesMsg" })}{" "}
        </span>
      )}

      <Modal
        showModal={showHistory}
        className={"p-3 overflow-y-hidden"}
        closeModal={() => {
          setShowHistory(false);
          setSelectedElment();
        }}
      >
        <Button
          status="icon-secondary"
          onClick={() => {
            setShowHistory(false);
            setSelectedElment();
          }}
          className="absolute right-1 top-1"
        >
          <Close />
        </Button>
        <HistoryFieldModal
          setSelectedElment={setSelectedElment}
          setShowHistory={setShowHistory}
          loadingHistory={loadingHistory}
          selectedElment={selectedElment}
          history={history}
        />
      </Modal>
      <Modal
        showModal={showQuery}
        className={"p-3 "}
        closeModal={() => setShowQuery(false)}
      >
        <NewQueryFieldModal
          setShowQuery={setShowQuery}
          selectedElment={selectedElment}
          setTexteMsg={setTexteMsg}
          loadingQuery={loadingQuery}
          setSelectedElment={setSelectedElment}
          participant={participant}
          formDataId={formDataId}
          addQueryToField={addQueryToField}
        />
      </Modal>
      <Modal
        showModal={showQueryHasData}
        className={"p-6"}
        closeModal={() => {
          setShowQueryHasData(false);
          setSelectedElment();
        }}
      >
        <QueryHasDataModal
          setShowQueryHasData={setShowQueryHasData}
          setSelectedElment={setSelectedElment}
          participant={participant}
          selectedElment={selectedElment}
          queries={queries}
          isWriter={isWriter}
          setSelectedStatusQuery={setSelectedStatusQuery}
          selectedStatusQuery={selectedStatusQuery}
          setTexteMsg={setTexteMsg}
          loadingQueryHasData={loadingQueryHasData}
          textMesg={textMesg}
          updateQueryMessage={updateQueryMessage}
          setShouldUpdate={props.setShouldUpdate}
        />
      </Modal>
      <Modal
        showModal={confirmSdvModal}
        className={"p-6 relative"}
        closeModal={() => {
          setConfirmSdvModal(false);
          setSelectedElment();
        }}
      >
        <ConfirmSdvModal
          setConfirmSdvModal={setConfirmSdvModal}
          setSelectedElment={setSelectedElment}
          selectedElment={selectedElment}
          validateFild={validateField}
        />
      </Modal>
    </div>
  );
};

export default FormRenderer;
