import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { addPaymentMethod } from "../../api/stripe";

import "./styles.scss";
import { useState } from "react";
import makeToast from "../Snackbar";
import Button from "../ButtonUI";
import { useTranslation } from "react-i18next";

const StripePaymentMethodForm = ({
  onSave,
  setPaymentMethodModalIsVisible,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [sendLoad, setSendLoad] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { t, i18n } = useTranslation() as any;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    try {
      setSendLoad(true);
      setDisabled(true);
      let { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
      });

      if (error) throw error;

      const resp = await addPaymentMethod(paymentMethod);
      console.log("[debug] payment method - ", { resp });

      setSendLoad(false);
      makeToast("success", "Moyen de paiement modifié avec succès. ");

      onSave();
    } catch (error) {
      console.error(error);
      makeToast("error", "Veuillez vérifier vos informations. ");
      setSendLoad(false);
      setDisabled(false);
    }
  };

  return (
    <div className="flex-auto py-7 px-8 min-w-full	">
      <div className="flex flex-wrap items-baseline">
        <h1 className="mb-10 text-center">
          Ajouter un nouveau moyen de paiement
        </h1>
      </div>
      <div id="stripe-payment-method">
        <>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  color: "#424770",
                  fontFamily: '"Ubuntu", sans-serif',
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {/* <button
            className="buttonPay"
            type="submit"
            disabled={!stripe || !elements || disabled}
            onClick={handleSubmit}
          >
            {" "}
            {!sendLoad ? (
              "Enregistrer"
            ) : (
              <p className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 self-center border-purple-500 mx-auto"></p>
            )}
          </button> */}
          <div className="mt-12">
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="float-left mb-4 btn-delete"
              onClick={() => setPaymentMethodModalIsVisible(false)}
            >
              {t("Investigator.Cancel")}
            </Button>
            <Button
              status="secondary"
              width="100px"
              height="50px"
              className="float-right mb-4"
              type="submit"
              disabled={!stripe || !elements || disabled}
              onClick={handleSubmit}
            >
              {!sendLoad ? (
                t("Investigator.save")
              ) : (
                <p className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 self-center border-purple-500 mx-auto"></p>
              )}
            </Button>
          </div>
        </>
      </div>
    </div>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!, {
  locale: "fr",
});

const StripePaymentMethodChange = ({
  onSave,
  setPaymentMethodModalIsVisible,
}) => (
  <Elements stripe={stripePromise}>
    <StripePaymentMethodForm
      onSave={onSave}
      setPaymentMethodModalIsVisible={setPaymentMethodModalIsVisible}
    />
  </Elements>
);

export default StripePaymentMethodChange;
