export const Profile = {
  fr: {
    admin: "Administrateur",
    investigateur: "Investigateur",
    FailUpdateData: "Un erreur est survenu lors la mise à jour !",
    SuccessUpdateData: "Mise à jour effectué avec succès",
    TitleDesc: "Informations du Compte",
    TitleSec: "Sécurité",
    successUpdatePicture:
      "La mise à jour de votre photo de profil a été effectuée avec succès",
    ConfirmDeletePicture:
      "Voulez vous vraiment supprimer votre photo de profil",
    setPaymentAccount:
      "Vous devez configurer votre compte de paiement pour naviguer dans la plateforme",
    ConfigParameter:
      "Merci de bien vouloir ajouter vos paramètres de consultation",
    Dr: "DR",
    Description: "Description",
    SpokenLanguages: "LANGUES PARLÉES",
    Add: "+ Ajouter",
    Update: "Modifier",
    confirm: "Valider",
    Cancel: "Annuler",
    Delete: "Supprimer",
    Save: "Enregistrer",
    Subscription: "Abonnement",
    LevelInProgress: "Palier en cours :",
    patientNumber: "Nombre de patient enregistré :",
    patientNumberByMonth: "Nombre de patient mensuel :",
    lvlPrice: "Tarif de palier :",
    TND: "TND",
    perMonth: "/mois",
    //AddCursus
    CURSUS: "CURSUS",
    fillAll: "Tous les champs sont obligatoires",
    successAddCursus: "Cursus universitaire ajouté avec succès.",
    failAddCursus: "Ajout cursus universitaire a échouhé.",
    universityCurriculum: "Cursus universitaire",
    DegreeName: "Nom du diplôme",
    UniversityName: "Nom de l'université",
    AcademicYear: "Année universitaire",
    NoCurriculum: "Vous n'avez pas encore ajouté votre cursus universitaire",
    //AddExpertise
    EXPERTISES: "EXPERTISES",
    fillExpertise: "Veuillez saisir votre expertise",
    successAddExpertise: "Expertise ajoutée avec succès.",
    failedAddExpertise: "L'ajout de l'expertise a échouhé.",
    AddExpertise: "Ajouter un expertise",
    WriteURExpertise: "Ecrivez votre expertise ...",
    SuccessDeleteExpertise: "Expertise est supprimée avec succès.",
    FailDeleteExpertise: "Suppression d'expertise a échouhé.",

    //Add Language
    SuccessAddLanguage: "Langues modifiée avec succès.",
    FailedAddLanguage: "Ajout langues a échouhé.",
    AddLanguage: " Ajouter une langue",
    //Add Presentation
    SuccessAddPresentation: "Présentation ajoutée avec succès.",
    FailedAddPresentation: "L'ajout de présentation a échouhé.",
    Presentation: "Présentation",
    WriteDescription: "Ecrivez votre description ...",
    NoPresentation: "Vous n'avez pas encore ajoutée votre présentation.",
    //Add Consultation Location // Address
    FillAllField: "Veuillez remplir tous les champs",
    SuccessAddConsultation:
      "La mise à jour de vos consultations a été effectué avec succès",
    FailedUpdateConsultatio: "La mise à jour de vos consultations  a échouhé.",
    Consultations: "Consultations",
    MyConsultations: "Mes consultations",
    ConsultationName: "Nom du consultation",
    ConsultationAddress: "Adresse du consultation",
    ConsultationCity: "Ville du consultation",
    ConsultationPostalCode: "code postal du consultation",
    ConsultationPhone: "téléphone du consultation",
    //Edit Data
    SuccessChange: "Votre changement a été sauvegardé avec succès",
    name: "Prénom",
    lastName: "Nom de famille",
    DNaiss: "Date de naissance",
    SCountry: "Pays",
    //Card Pro

    SuccessAddCardPro: "carte professionnelle ajoutée avec succès.",
    uploadBusinessCard:
      "Glissez ou déposez votre carte professionnelle ou cliquez ici.",
    ConfirmDeleteProCard:
      "Voulez vous vraiment supprimer votre carte professionnelle",
    CardPro: "Carte pro",
    CardProfessional: "Carte professionnelle",

    //Edit signature
    Signature: "Signature",
    uploadSignature: "Glissez ou déposez votre signature ou cliquez ici.",
    SuccessAddSignature: "Signature ajoutée avec succès.",
    ConfirmDeleteSignature: " Voulez vous vraiment supprimer votre signature",
    //Edit site
    SITESAndNETWORKS: "SITES & RÉSEAUX",
    SuccessUpdate: "Réseaux sociaux modifiés avec succès.",
    FailedUpdate: "Réseaux sociaux a échouhé.",
    updateSocialNetwork: "Modifier vos réseaux sociaux",
    //Edit speciality
    updateSpeciality: "Modifiez votre spécialité",
    SuccessUpdateSpeciality: "Spécialité modifiée avec succès",
    // TARIFS & PAIEMENTS
    PRICESANDPAYMENTS: "TARIFS & PAIEMENTS",
    SuccessModificationPrice: "Tarifs modifiés avec succès.",
    FailedModificationPrice: "Modification tarifs a échouhé.",
    EditAddPrice: "Modifier/Ajouter vos tarifs",
    Currency: "Devise",
    Stripe: "Create my Stripe Express account",
    NormalConsultation: "Consultation normal",
    NightConsultation: "Consultation pendant la nuit",
    NormalConsultationPrice: "Prix consultation normal",
    weekendConsultation: "Consultation pendant le weekend et jours fériés",
    NightConsultationPrice: "Prix consultation du nuit",
    WeekEndConsultationPrice: "Prix consultation pendant le weekEnd",
    OtherConsultationType: "Autre type de consultation",
    ConsultationType: "Type de consultation",
    ConsultationPrice: "Prix consultation",

    //LOCATION
    Name: "Nom",
    Address: "Adresse",
    City: "Ville",
    Phone: "Tel",
    Type: "type",
    Color: "Couleur",

    Office: "Cabinet",
    Clinical: "Clinique",
    Hospital: "Hôpital",

    Country: "Pays",
    ModalUpdateProfile: "Modifier vos données",
    birthDate: "Date de naissance",
    //MFA
    Mfa: "Authentification à multiples facteurs",
    mtp: "Mots de passes à usage unique :",
    inst1:
      "Ouvrez depuis votre smartphone le générateur de mots de passe de votre choix (Google Authenticator, Microsoft Authenticator, LastPass...)",
    inst2:
      "Ajoutez un nouveau compte sur votre application et choisissez l'option vous permettant de scanner un QRCode",
    inst3: "Scannez le QRCode",
    inst4:
      "Saisissez le mot de passe affiché sur votre générateur des mots de passes afin de confirmer l'activation de l'authentification à multiple facteur",
    placeholderCode: "Entrer le code",
    mfaDisabled: "MFA désactivé avec succès !",
    mfaDisabledErr: "Erreur lors de la désactivation de MFA",
    mfaEnabledErr: "Erreur lors de l'activation de MFA",
    mfaEnabledSucc: "Code valide! ,MFA activé avec succès !",
    montant: "Montant",
    paied: "Payée",
    free: "Gratuit",
    statut: "Statut",
  },
  en: {
    admin: "Admin",
    investigateur: "Investigator",
    birthDate: "Date of birth",
    ModalUpdateProfile: "Update personel information",
    SuccessUpdateData: "Succes update information",
    FailUpdateData: "Un error has occured while updating information !",
    Country: "Country",
    successUpdatePicture: "Your profile photo has been updated successfully",
    ConfirmDeletePicture: "Do you really want to delete your profile photo",
    TitleDesc: "Account Information",
    TitleSec: "Security",
    setPaymentAccount:
      "You need to set up your payment account to navigate the platform",
    ConfigParameter: "Please add your consultation parameters",
    Delete: "Delete",
    SpokenLanguages: "SPOKEN LANGUAGES",
    Add: "+ Add",
    Update: "Update",
    confirm: "Confirm",
    Cancel: "Cancel",
    Save: "Save",
    Dr: "DR",
    Description: "Description",
    LevelInProgress: "Level in progress :",
    patientNumber: "Number of patients registered :",
    patientNumberByMonth: "Monthly patient count :",
    lvlPrice: "Level price :",
    Subscription: "Subscription",
    TND: "TND",
    perMonth: "/moonth",
    CURSUS: "CURRICULUM",
    DNaiss: "Birth Date",

    //AddCursus
    fillAll: "Please fill in all required fields",
    successAddCursus: "University curriculum successfully added.",
    failAddCursus: "Addition university curriculum failed.",
    universityCurriculum: "University curriculum",
    DegreeName: "Degree Name",
    UniversityName: "University Name",
    AcademicYear: "Academic Year",
    NoCurriculum: "You have not yet added your university curriculum",
    //AddExpertise
    fillExpertise: "Please enter your expertise",
    successAddExpertise: "Expertise successfully added.",
    failedAddExpertise: "The addition of expertise failed.",
    AddExpertise: "Add expertise",
    WriteURExpertise: "Write your expertise ...",
    SuccessDeleteExpertise: "Expertise successfully deleted.",
    FailDeleteExpertise: "Delete expertise failed.",
    EXPERTISES: "EXPERTISES",
    //Add Language
    SuccessAddLanguage: "Languages modified successfully.",
    FailedAddLanguage: "Adding languages has failed.",
    AddLanguage: "Add language",
    //Add Presentation
    SuccessAddPresentation: "Presentation successfully added.",
    FailedAddPresentation: "The addition of presentation failed",
    Presentation: "Presentation",
    WriteDescription: "Write your description ...",
    NoPresentation: "You haven't added your presentation yet.",
    //Add Consultation Location // Address
    FillAllField: "Please fill in all fields",
    SuccessAddConsultation: "Your consultations have been successfully updated",
    FailedUpdateConsultatio: "The update of your consultations has failed.",
    Consultations: "Consultations",
    MyConsultations: "My Consultations",
    ConsultationName: "Consultation name",
    ConsultationAddress: "Consultation address",
    ConsultationCity: "Consultation city",
    ConsultationPostalCode: "Consultation postal code",
    ConsultationPhone: "Consultation phone",
    //Edit Data
    SuccessChange: "Your change has been saved successfully",
    name: "First name",
    lastName: "Last name",
    // CardPro
    SuccessAddCardPro: "Business card successfully added.",
    uploadBusinessCard: "Drag or drop your business card or click here.",
    ConfirmDeleteProCard: "Do you really want to delete your professional card",
    CardPro: "Pro card",
    CardProfessional: "Professional card",

    //Edit signature
    Signature: "Signature",
    uploadSignature: "Drag or drop your signature or click here.",
    SuccessAddSignature: "Signature added successfully.",
    ConfirmDeleteSignature: "Do you really want to delete your signature",

    //Edit site

    SITESAndNETWORKS: "SITES & NETWORKS",
    SuccessUpdate: "Social networks successfully modified.",
    FailedUpdate: "Update Social Media has failed.",
    updateSocialNetwork: "Change your social networks",
    //Edit updateSpeciality
    updateSpeciality: "Change your specialty",
    SuccessUpdated: "Specialty updated successfully",
    // TARIFS & PAIEMENTS
    PRICESANDPAYMENTS: "PRICES & PAYMENTS",
    SuccessModificationPrice: "price updated successfully.",
    FailedModificationPrice: "Update price failed",
    EditAddPrice: "Edit/Add your prices",
    Currency: "Currency",
    NormalConsultation: "Normal consultation",
    NightConsultation: "Night consultation",
    NormalConsultationPrice: "Normal consultation price",
    weekendConsultation: "Consultation during weekends and public holidays",
    Stripe: "Créer mon compte Stripe Express",

    NightConsultationPrice: "Night consultation price",
    WeekEndConsultationPrice: "WeekEnd consultation price",
    OtherConsultationType: "Other consultation type",
    ConsultationType: "Consultation type",
    ConsultationPrice: "Consultation price",
    //LOCATION
    Name: "Name",
    Address: "Address",
    City: "City",
    Phone: "Phone",
    Type: "Type",
    Color: "Color",

    Office: "Office",
    Clinical: "Clinical",
    Hospital: "Hospital",

    //MFA
    Mfa: "Multi-Factor-Authentication",
    mtp: "One-time password",
    inst1:
      "Open the password generator of your choice on your smartphone (Google Authenticator, Microsoft Authenticator, LastPass...)",
    inst2:
      "Add a new account in your application and choose the option allowing you to scan a QR Code.",
    inst3: "Scan the following QR Code:",
    inst4:
      "Enter the password displayed on your application to confirm the successful completion of the process.",
    placeholderCode: "Enter  the Code",
    mfaDisabled: " MFA disabled successfully!",
    mfaDisabledErr: "Error while disabling MFA",
    mfaEnabledErr: "Error while enabling MFA",
    mfaEnabledSucc: "Code valid! MFA enabled successfully!",
    montant: "Amount",
    paied: "Paid",
    free: "Free",
    statut: "Status",
  },
};
