import { ArrowRightIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  ChakraProvider,
  Heading,
  extendTheme,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiOutlineForm } from "react-icons/ai";
import { FcLeave } from "react-icons/fc";
import {
  MdAdd,
  MdExpandLess,
  MdExpandMore,
  MdPhoneInTalk,
} from "react-icons/md";
import { RiHeartPulseFill } from "react-icons/ri";
import { IntlProvider } from "react-intl";
import { useParams } from "react-router-dom";
import {
  getParticipantByStudyId,
  getParticipantByStudyId2,
  getRandomisationConfig,
  getStudyConfig,
  nextRandomizeParticipant,
  randomizeParticipant,
  updateFomDataSection,
} from "../../../api/study";
import { ReactComponent as Survery } from "../../../assets/illustrations/survey.svg";
import Button from "../../../components/ButtonUI";
import Icon from "../../../components/Icon";
import Modal from "../../../components/ModalUI";
import MyModal from "../../../components/New-Modal";
import SelectComp from "../../../components/Select";
import makeToast from "../../../components/Snackbar";
import Spinner from "../../../components/Spinner";
import {
  MultiStepsLayout,
  PageHeader,
} from "../../../components/SurveyGenerator/FormBuilder/Lyaout";
import StepperWrapper from "../../../components/SurveyGenerator/FormBuilder/StepperWrapper";
import {
  Formiz,
  FormizStep,
} from "../../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import FormRenderer from "../../../components/SurveyGenerator/FormRenderer";
import { default as frMsg } from "../../../config/constants/locales/fr-fr.json";
import { extractFields } from "../../../helper/FormGeneratorHelper/Utils";
import { resolver } from "../../../helper/FormGeneratorHelper/conditionalLogic";
import history from "../../../history";
import { useForm } from "../../../hooks/FormGeneratorHooks";
import { selectedSectionAtom } from "../../../state/SelectedSection";
import { userAtom } from "../../../state/auth";
import { participantAtom } from "../../../state/participant";
import { socketAtom } from "../../../state/socket";
import { studyWriterConfigAtom } from "../../../state/studyWriterConfig";
import { testModeAtom } from "../../../state/testModeAtom";
import { visteAtom } from "../../../state/visteAtom";
import DisplayEvent from "../../DragableInterface/DisplayEvent";
import DisplayForm from "../../DragableInterface/DisplayForm";
import EventForm from "./EventForms";
import "./styles.scss";

const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    blue: "#4565f6",
  },
});

const MyParticipantForms = () => {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [randomModal, setRandomModal] = useState(false);
  const [nextRandomModal, setNextRandomModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [isEpro, setIsEpro] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [selectedEvent, setSelectedEvent] = useState() as any;
  const [selectedEproForm, setSelectedEproForm] = useState() as any;
  const [selectedGroupId, setSelectedGroupId] = useState() as any;
  const [selectedSection, setSelectedSection] = useAtom(selectedSectionAtom);
  const [participant, setParticipant] = useAtom(participantAtom);
  const [selectedPreview, setSelectedPreview] = useState() as any;
  const [addEventModal, setAddEventModal] = useState(false);
  const [formData, setFormData] = useAtom(visteAtom);
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [randomConfig, setRandomConfig] = useState() as any;
  const [listGroups, setListGroups] = useState([]) as any[];
  const params = useParams() as any;
  const { t } = useTranslation() as any;
  const form = useForm();
  const [isEditing, setIsEditing] = useState(false);
  // useEffect(() => {

  // }, [isEditing]);
  const eventTypes = {
    ADVERSE: t("missingWording.adverseevents"),
    CALL: t("missingWording.phoneCallEvent"),
  };
  const [user] = useAtom(userAtom);
  const [studyWriterConfig, setStudyWriterConfig] = useAtom(
    studyWriterConfigAtom
  );
  const { studyId, participantId } = params;
  async function fetchMyAPI(): Promise<void> {
    const participant =
      user?.type !== "writer"
        ? await getParticipantByStudyId(studyId, participantId)
        : await getParticipantByStudyId2(
            studyId,
            participantId,
            studyWriterConfig?.roleId?._id
          );
    setParticipant(participant);
    const localQueryField = localStorage.getItem("queryField");
    if (localQueryField) {
      let wordArray = localQueryField.split("||");
      const myIndex = {};
      if (participant && participant?.forms) {
        for (const obj of participant?.forms) {
          myIndex[obj._id] = obj;
        }

        // Retrieve the object with _id = 2 from the index
        const myObject = myIndex[wordArray[0]];

        if (wordArray[3] === "ParticipantEvent") {
          setLoading(true);
          setIsEvent(true);
          setIsEpro(false);

          setActiveSection("EVENT");
          setTimeout(() => {
            setSelectedEvent(myObject);
            setLoading(false);
          }, 200);
        } else {
          setLoading(true);
          setIsEvent(false);
          setIsEpro(false);
          setActiveSection("FORM_DATA");
          setTimeout(() => {
            setSelectedPreview(myObject);
            setSelectedSection(myObject?.sections[wordArray[2]]);

            setLoading(false);
          }, 200);
        }

        //! if myObject undifined go check ParticipantEvent
        //TODO if myObject undifined go check ParticipantEvent

        setTimeout(() => {
          localStorage.removeItem("queryField");
        }, 200);
      }
    }
  }
  async function refreshParticipantForms(): Promise<any> {
    try {
      setShouldUpdate((up) => !up);
      await fetchStudyParticipants();
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:30 ~ fetchStudyConfig ~ error", error);
    }
  }

  async function fetchStudyParticipants(): Promise<any> {
    try {
      let type = user?.type;
      setLoading(true);
      if (type === "writer") {
        let res = await getStudyConfig(studyId, 1, 10, "byMe", "", testMode);
        if (res) {
          setStudyWriterConfig(res?.studyConfig);
          setLoading(false);
        } else {
          setStudyWriterConfig(null);
          setLoading(false);
        }
      } else {
        setStudyWriterConfig(null);

        setLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:30 ~ fetchStudyConfig ~ error", error);
    }
  }
  useEffect(() => {
    fetchMyAPI();
  }, [shouldUpdate, setShouldUpdate]);

  useEffect(() => {
    if (user?.type === "writer") fetchStudyParticipants();
  }, []);
  useEffect(() => {
    if (socket) {
      socket.on("REFRESH", (formData) => {
        fetchMyAPI();
      });
      socket.on("READY_TO_RANDOMIZE", (formData) => {
        if (
          participant?.randomisationHistory &&
          participant?.randomisationHistory.length === 0 &&
          !participant?.currentGroup
        )
          setRandomModal(true);
      });
      socket.on("SECONDERY_RANDOMIZE", (commingFormData) => {
        if (
          participant?.randomisationHistory &&
          participant?.randomisationHistory.length > 0 &&
          participant?.randomisationHistory.length <=
            randomConfig?.asocitedForms.length &&
          selectedPreview.shouldRandomize
        ) {
          setNextRandomModal(true);
        }
      });
    }
  }, [socket]);
  const checkIsAavaible = (forms, surveyTemId) => {
    const arr = forms?.filter((f) => f?.formTemplateId === surveyTemId);
    return arr[0]?.sections?.every((e) => e?.status === "VALID_BY_WRITER");
  };
  async function fetchMyAPI2(): Promise<void> {
    try {
      const randomisationConfig = await getRandomisationConfig(
        studyWriterConfig?.studyId?.randomisationConfig
      );
      setRandomConfig(randomisationConfig?.config);
    } catch (error) {
      makeToast("error", "err");
    }
  }

  useEffect(() => {
    if (studyWriterConfig?.studyId?.randomisationConfig) fetchMyAPI2();
  }, []);

  useEffect(() => {
    if (randomConfig && !randomConfig.auto) {
      const formattedGroups = randomConfig.groups.map((group) => ({
        label: group.groupName,
        value: group._id,
      }));

      setListGroups(formattedGroups);
    } else {
      setListGroups([]);
    }
  }, [randomModal, nextRandomModal]);

  return (
    <>
      <div className="icon link cursor-pointer flex items-center">
        <Icon
          onClick={() => {
            user?.type === "writer"
              ? history.push(`/participant/${studyId}`)
              : history.push(`/participant/${studyId}`);
            setParticipant(null);
          }}
          name="direction-gauche"
          width={50}
          height={50}
          isIcon={true}
        />
        <div className="mb-2">
          <p
            className=""
            onClick={() => {
              user?.type === "writer"
                ? history.push(`/participant/${studyId}`)
                : history.push(`/participant/${studyId}`);
              setParticipant(null);
            }}
          >
            {t("missingWording.back")}
          </p>
        </div>
      </div>
      {process.env.NODE_ENV === "development" && (
        <div className="flex max-w-max  items-center flex-wrap xs:mt-12 md:mt-1 fixed bottom-0 left-0 z-50  ">
          <Button
            status="primary"
            // onClick={() => socket.emit("resetChallenge3Writer", {})}
            className="text-xs px-2 py-1"
          >
            Start Tour
          </Button>
        </div>
      )}
      {participant?._id ? (
        <div className="flex flex-1 md:flex-row xs:flex-col h-full gap-x-12 mt-4 relative ">
          <MyModal
            showModal={randomModal}
            closeModal={() => setRandomModal(false)}
          >
            <div className="px-5 py-5">
              <div className="mt-2 mb-10">
                <h2 className=" text-lg text-center font-semibold">
                  {t("Survey.randomize")}
                </h2>
              </div>
              <h3 style={{ fontSize: "18px" }}>
                {t("Survey.randomize01")}{" "}
                {randomConfig?.auto && t("Survey.randomize03")}{" "}
                {t("Survey.randomize02")}{" "}
              </h3>
              <h3 className="mb-6" style={{ fontSize: "18px" }}>
                {" "}
                {t("Survey.randomize04")}
              </h3>

              {randomConfig && !randomConfig?.auto && (
                <div>
                  <SelectComp
                    id="groupId"
                    onChange={(e) => {
                      setSelectedGroupId(e.target.value);
                    }}
                    className="min-w-full mb-8"
                    text={t("Participant.selectGroup")}
                    options={listGroups}
                  ></SelectComp>
                </div>
              )}
              <div className="mt-8 flex flex-row justify-between">
                <Button
                  status="secondary"
                  width="106px"
                  height="50px"
                  className="px-4 py-2 mt-8 btn-delete"
                  onClick={async () => {
                    setRandomModal(false);
                  }}
                >
                  {t("missingWording.cancel")}
                </Button>
                <Button
                  status="primary"
                  className="px-4 py-2 mt-8"
                  width="106px"
                  height="50px"
                  disabled={!randomConfig?.auto && !selectedGroupId}
                  onClick={async () => {
                    const res = await randomizeParticipant({
                      participantId: participant._id,
                      studyId,
                      groupId: selectedGroupId,
                    });
                    refreshParticipantForms();
                    setRandomModal(false);
                  }}
                >
                  {t("missingWording.confirm")}
                </Button>
              </div>
            </div>
          </MyModal>
          <MyModal
            showModal={nextRandomModal}
            closeModal={() => setNextRandomModal(false)}
          >
            <div className="px-5 py-5">
              <div className="mt-2 mb-10">
                <h2 className=" text-lg text-center font-semibold">
                  {t("Survey.randomize")}
                </h2>
              </div>
              <h3 style={{ fontSize: "18px" }}>
                {t("Survey.randomize01")}{" "}
                {randomConfig?.auto && t("Survey.randomize03")}{" "}
                {t("Survey.randomize02")}{" "}
              </h3>
              <h3 className="mb-6" style={{ fontSize: "18px" }}>
                {" "}
                {t("Survey.randomize04")}
              </h3>

              {randomConfig && !randomConfig?.auto && (
                <div>
                  <SelectComp
                    id="groupId"
                    onChange={(e) => {
                      setSelectedGroupId(e.target.value);
                    }}
                    className="min-w-full mb-8"
                    text={t("Participant.selectGroup")}
                    options={listGroups}
                  ></SelectComp>
                </div>
              )}
              <div className="mt-8 flex flex-row justify-between">
                <Button
                  status="secondary"
                  width="106px"
                  height="50px"
                  className="px-4 py-2 mt-8 btn-delete"
                  onClick={async () => {
                    setNextRandomModal(false);
                  }}
                >
                  {t("missingWording.cancel")}
                </Button>
                <Button
                  status="primary"
                  width="106px"
                  height="50px"
                  className="px-4 py-2 mt-8"
                  disabled={!randomConfig?.auto && !selectedGroupId}
                  onClick={async () => {
                    const res = await nextRandomizeParticipant({
                      participantId: participant._id,
                      studyId,
                      groupId: selectedGroupId,
                      formDataId: selectedPreview._id ?? null,
                    });
                    setNextRandomModal(false);
                    refreshParticipantForms();

                    setSelectedPreview();
                  }}
                >
                  {t("missingWording.confirm")}
                </Button>
              </div>
            </div>
          </MyModal>
          <div className="sm:w-1/4 ">
            <div className=" rounded-lg md:h-screen xs:h-auto  ">
              <Accordion
                overflowX={"hidden"}
                className=" rounded-lg  border  "
                style={{
                  maxHeight: "55vh",
                  backgroundColor: "white",
                  overflowY: "auto",
                }}
                allowToggle
                id="ParticipantSideBar"
              >
                <h1
                  className="p-4 md:font-bold md:text-base xs:text-xs xs:font-semibold bg-gray-100 rounded-lg text-gray-500 "
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Participant {participant?.publicId}
                </h1>

                {participant?.forms
                  ?.filter((f) =>
                    user?.type === "writer"
                      ? studyWriterConfig?.roleId?._id === f?.roleId
                      : true
                  )
                  .map((f, i) => {
                    return (
                      ((user?.type === "writer" &&
                        (f?.dependency?.length === 0 ||
                          checkIsAavaible(
                            participant?.forms,
                            f?.dependency[0]
                          ))) ||
                        user?.type !== "writer") && (
                        <AccordionItem
                          border={"none"}
                          backgroundColor={"white"}
                          className="  border-gray-300"
                          onClick={() => {
                            setIsEvent(false);
                            setIsEpro(false);
                            setActiveSection("FORM_DATA");
                            if (user?.type === "writer") {
                              if (f?._id === selectedPreview?._id) {
                                return;
                              }
                              if (
                                (f?.dependency?.length === 0 ||
                                  checkIsAavaible(
                                    participant?.forms,
                                    f?.dependency[0]
                                  )) &&
                                studyWriterConfig?.roleId?._id === f?.roleId
                              ) {
                                setLoading(true);
                                setTimeout(() => {
                                  setSelectedPreview(f);
                                  setLoading(false);
                                }, 200);
                                setSelectedSection(f.sections[0]);
                              } else {
                                if (
                                  studyWriterConfig?.roleId?._id === f?.roleId
                                ) {
                                  makeToast(
                                    "warning",
                                    t("missingWording.pft3")
                                  );
                                } else {
                                  makeToast(
                                    "warning",
                                    t("missingWording.pft4")
                                  );
                                  setSelectedPreview();
                                }

                                return;
                              }
                            } else {
                              if (f?._id === selectedPreview?._id) {
                                return;
                              }
                              setLoading(true);
                              setTimeout(() => {
                                setSelectedPreview(f);
                                setLoading(false);
                              }, 500);
                              setSelectedSection(f.sections[0]);
                            }
                          }}
                        >
                          {({ isExpanded }) => (
                            <>
                              <AccordionButton
                                rounded="lg"
                                _hover={{}}
                                _focus={{}}
                                className="justify-between  shadow-md "
                              >
                                <Box
                                  className={
                                    "text-md p-2 flex items-center  justify-start relative formsExple" +
                                    `${
                                      isExpanded
                                        ? "font-bold rounded-2xl  text-blue-600"
                                        : " font-bold"
                                    }`
                                  }
                                  flex="1"
                                  textAlign="left"
                                  color={isExpanded ? "blue" : ""}
                                  onClick={() => setIsEvent(false)}
                                >
                                  <Survery
                                    width={48}
                                    className="mr-5 p-1 rounded-full bg-blue-100 shadow-md"
                                  />
                                  {/* <p
                                    className={`w-3 h-3 rounded-full mx-2 ${
                                      f?.status === "INIT"
                                        ? "bg-gray-400"
                                        : f?.status === "PROGRESS"
                                        ? " bg-yellow-500"
                                        : "bg-green-500"
                                    }`}
                                  ></p> */}
                                  <div>
                                    <p
                                      className={
                                        studyWriterConfig?.roleId?._id ===
                                        f?.roleId
                                          ? "titleMenuForm"
                                          : "text-gray-500 "
                                      }
                                    >
                                      {f?.name}
                                    </p>
                                  </div>
                                </Box>
                                {f?.status && f.status === "APPROVED" && (
                                  <div className="mx-4">
                                    <AiFillCheckCircle
                                      color={"#4bb640"}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}

                                {isExpanded ? (
                                  <MdExpandLess size={28} />
                                ) : (
                                  <MdExpandMore size={28} />
                                )}
                              </AccordionButton>
                              <AccordionPanel className="relative w-96" pb={4}>
                                {formData &&
                                  formData?.sections?.map((s, idx) => {
                                    return resolver(
                                      s.conditionLogic,
                                      extractFields(formData.sections)
                                    ) ? (
                                      <p
                                        className={`py-2 px-2 my-1  border-b font-normal flex items-center gap-x-2 ${
                                          selectedSection?.stepNbr === s.stepNbr
                                            ? " text-blue-600 "
                                            : ""
                                        } ${
                                          (user?.type === "writer" &&
                                            studyWriterConfig?.roleId?._id ===
                                              f?.roleId) ||
                                          user?.type !== "writer"
                                            ? "cursor-pointer"
                                            : "cursor-not-allowed"
                                        }`}
                                        onClick={() => {
                                          setSelectedSection(s);
                                          setActiveSection("FORM_DATA");
                                        }}
                                      >
                                        <p
                                          className={`w-3 h-3 rounded-full ${
                                            s.status === "INIT"
                                              ? "bg-gray-400"
                                              : s.status === "PROGRESS"
                                              ? " bg-yellow-500"
                                              : "bg-green-500"
                                          }`}
                                        ></p>
                                        {s.sectionName}
                                      </p>
                                    ) : (
                                      <></>
                                    );
                                  })}
                              </AccordionPanel>
                            </>
                          )}
                        </AccordionItem>
                      )
                    );
                  })}
              </Accordion>
              <Accordion
                overflowX={"hidden"}
                className=" rounded-lg  border mt-6  "
                defaultIndex={[0]}
                style={{
                  maxHeight: "55vh",
                  backgroundColor: "white",
                  overflowY: "auto",
                }}
                allowMultiple
                id="EventSideBar"
              >
                <AccordionItem
                  border={"none"}
                  backgroundColor={"white"}
                  className="  border-gray-300 "
                  onClick={() => {
                    setIsEvent(true);
                    setIsEpro(false);
                    setActiveSection("EVENT");
                  }}
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        rounded="lg"
                        _hover={{}}
                        _focus={{}}
                        className="justify-between  shadow-md  "
                        onClick={() => {
                          setIsEvent(true);
                          setActiveSection("EVENT");
                        }}
                      >
                        <Box
                          className={
                            "text-md p-2 flex items-center  justify-start relative " +
                            `${
                              isExpanded
                                ? "font-bold rounded-2xl  text-blue-600"
                                : " font-bold"
                            }`
                          }
                          flex="1"
                          textAlign="left"
                          color={isExpanded ? "blue" : ""}
                        >
                          <FcLeave
                            size={"48"}
                            className="mr-5 p-1 rounded-full bg-yellow-100 shadow-md"
                          />

                          <div className="flex flex-row items-center justify-center">
                            <p className="capitalize text-gray-500">
                              {t("missingWording.events")}
                            </p>
                            {user?.type === "writer" && (
                              <MdAdd
                                id="addEvent"
                                className="ml-2 px-1  rounded-lg bg-gray-100 border w-6 h-6 hover:bg-gray-200 "
                                onClick={() => setAddEventModal(true)}
                              />
                            )}
                          </div>
                        </Box>

                        {isExpanded ? (
                          <MdExpandLess size={28} />
                        ) : (
                          <MdExpandMore size={28} />
                        )}
                      </AccordionButton>
                      <AccordionPanel className="relative" pb={4}>
                        {participant?.events &&
                        participant?.events.length > 0 ? (
                          <>
                            {" "}
                            {participant?.events.map((s, idx) => {
                              return (
                                <p
                                  className={`py-2 px-2 my-1  border-b font-normal flex flex-row cursor-pointer ${
                                    selectedEvent?._id === s?._id
                                      ? "titleMenuForm border-blue-600 "
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setLoading(true);
                                    setActiveSection("EVENT");
                                    setIsEvent(true);
                                    setTimeout(() => {
                                      setSelectedEvent(s);
                                      setLoading(false);
                                    }, 200);
                                  }}
                                >
                                  <p className={` mx-2  `}>
                                    {eventTypes[`${s.type}`] ===
                                    eventTypes.CALL ? (
                                      <MdPhoneInTalk
                                        size={24}
                                        className={`${
                                          s.status === "PROGRESS"
                                            ? "text-yellow-500"
                                            : s.status === "VALID_BY_WRITER"
                                            ? "text-green-500"
                                            : "text-red-600"
                                        }  p-0.5 rounded-full bg-gray-200 `}
                                      />
                                    ) : (
                                      <RiHeartPulseFill
                                        size={24}
                                        className={`${
                                          s.status === "PROGRESS"
                                            ? "text-yellow-500"
                                            : s.status === "VALID_BY_WRITER"
                                            ? "text-green-500"
                                            : "text-red-600"
                                        }  p-0.5 rounded-full bg-gray-200 `}
                                      />
                                    )}
                                  </p>

                                  <p className={`w-full mx-2 `}>
                                    {s.name}{" "}
                                    <p className="text-xs ">
                                      {moment(s.createdAt).format("DD-MM-YYYY")}
                                    </p>
                                  </p>
                                </p>
                              );
                            })}
                          </>
                        ) : (
                          <p className="text-center text-gray-500 p-2 mx-4 my-2 ">
                            {t("missingWording.noEventsPart")}{" "}
                          </p>
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              <Accordion
                overflowX={"hidden"}
                className=" rounded-lg  border mt-6  "
                defaultIndex={[0]}
                style={{
                  maxHeight: "55vh",
                  backgroundColor: "white",
                  overflowY: "auto",
                }}
                allowMultiple
              >
                <AccordionItem
                  border={"none"}
                  backgroundColor={"white"}
                  className="  border-gray-300 "
                  onClick={() => {
                    setIsEpro(true);
                    setIsEvent(false);
                    setActiveSection("EPRO");
                  }}
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        rounded="lg"
                        _hover={{}}
                        _focus={{}}
                        className="justify-between  shadow-md  "
                        onClick={() => {
                          setIsEpro(true);
                          setActiveSection("EPRO");
                        }}
                      >
                        <Box
                          className={
                            "text-md p-2 flex items-center  justify-start relative " +
                            `${
                              isExpanded
                                ? "font-bold rounded-2xl  text-blue-600"
                                : " font-bold"
                            }`
                          }
                          flex="1"
                          textAlign="left"
                          color={isExpanded ? "blue" : ""}
                        >
                          <FcLeave
                            size={"48"}
                            className="mr-5 p-1 rounded-full bg-yellow-100 shadow-md"
                          />

                          <div className="flex flex-row items-center justify-center">
                            <p className="capitalize text-gray-500">
                              {t("missingWording.eproForms")}
                            </p>
                          </div>
                        </Box>

                        {isExpanded ? (
                          <MdExpandLess size={28} />
                        ) : (
                          <MdExpandMore size={28} />
                        )}
                      </AccordionButton>
                      <AccordionPanel className="relative" pb={4}>
                        {participant?.eProForms &&
                        participant?.eProForms.length > 0 ? (
                          <>
                            {" "}
                            {participant?.eProForms.map((s, idx) => {
                              return (
                                <p
                                  className={`py-2 px-2 my-1  border-b font-normal flex flex-row cursor-pointer ${
                                    selectedEproForm?._id === s?._id
                                      ? "titleMenuForm border-blue-600 "
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setLoading(true);
                                    setTimeout(() => {
                                      setSelectedEproForm(s);
                                      setIsEpro(true);
                                      setActiveSection("EPRO");
                                      setLoading(false);
                                    }, 200);
                                  }}
                                >
                                  <p className={` mx-2  `}>
                                    <AiOutlineForm
                                      size={24}
                                      className={`${
                                        s.status === "PROGRESS"
                                          ? "text-yellow-500"
                                          : s.status === "VALID_BY_WRITER"
                                          ? "text-green-500"
                                          : "text-red-600"
                                      }  p-0.5 rounded-full bg-gray-200 `}
                                    />
                                  </p>

                                  <p className={`w-full mx-2 `}>
                                    {s.name}{" "}
                                    <p className="text-xs ">
                                      {moment(s.createdAt).format("DD-MM-YYYY")}
                                    </p>
                                  </p>
                                </p>
                              );
                            })}
                          </>
                        ) : (
                          <p className="text-center text-gray-500 p-2 mx-4 my-2 ">
                            {t("missingWording.noeproPart")}{" "}
                          </p>
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div className="md:w-5/6 xs:w-full ">
            <ChakraProvider theme={theme}>
              {selectedPreview?._id && activeSection === "FORM_DATA" && (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <DisplayForm
                      onChange={(e) => console.log(e)}
                      setIsEditing={setIsEditing}
                      isEditing={isEditing}
                      onSubmit={async (v) => {
                        if (socket) {
                          socket.emit("SUBMIT_FORM", {
                            roomId: selectedPreview._id,
                            status: "VALID_BY_WRITER",
                          });
                        }

                        if (socket) {
                          makeToast("success", t("missingWording.pft1"));
                          setIsEditing(false);
                        } else {
                          makeToast("success", t("missingWording.pht2"));
                        }
                      }}
                      isExit={participant?.status === "EXIT"}
                      isExitReason={participant?.forms.find(
                        (f) => f.isExitReason
                      )}
                      setSelectedPreview={setSelectedPreview}
                      formConfig={selectedPreview}
                      initialField={selectedPreview?.sections}
                      data={selectedPreview}
                      setShouldUpdate={setShouldUpdate}
                      isEvent={false}
                    />
                  )}
                </>
              )}
              {selectedEvent?._id && activeSection === "EVENT" && (
                <>
                  {false ? (
                    <Spinner />
                  ) : (
                    <DisplayEvent
                      onChange={(e) => console.log(e)}
                      onSubmit={async (v) => {
                        let paload = { sections: v };
                        const res = await updateFomDataSection(
                          paload,
                          selectedEvent._id,
                          isEvent
                        );
                        if (res) {
                          makeToast("success", t("missingWording.pft2"));

                          setSelectedPreview();
                        } else {
                          makeToast("success", t("missingWording.pht2"));
                        }
                      }}
                      setSelectedPreview={selectedEvent}
                      formConfig={selectedEvent?.formTemplateId}
                      initialField={selectedEvent?.sections}
                      data={selectedEvent}
                      setShouldUpdate={setShouldUpdate}
                    />
                  )}
                </>
              )}
              {selectedEproForm?._id && activeSection === "EPRO" && (
                <>
                  {false ? (
                    <Spinner />
                  ) : (
                    <IntlProvider
                      locale={"fr"}
                      defaultLocale="fr"
                      messages={frMsg}
                    >
                      <Formiz connect={form} key={form.resetKey}>
                        <MultiStepsLayout
                          submitLabel={t("missingWording.submitLabel")}
                          isEpro={isEpro}
                        >
                          <PageHeader
                            setShouldUpdate={undefined}
                            isPreview={true}
                          >
                            {selectedEproForm?.name
                              ? selectedEproForm?.name
                              : "#Titre de questionnaire"}
                            <Heading fontSize="lg" mb={4} mt={4}>
                              {selectedEproForm?.description && (
                                <>
                                  <ArrowRightIcon
                                    height={"12px"}
                                    width={"12px"}
                                    mr={2}
                                    mb={1}
                                    color={"#4565f6"}
                                  />
                                  <>{selectedEproForm?.description}</>
                                </>
                              )}
                            </Heading>
                          </PageHeader>
                          <div className="border  shadow-lg  p-4 w-full rounded-lg mb-4">
                            <StepperWrapper
                              title="Dots stepper"
                              dir="row"
                            ></StepperWrapper>

                            {selectedEproForm?.sections?.map((step, i) => {
                              return (
                                <FormizStep
                                  label={step.sectionName}
                                  name={`${
                                    step.sectionName + "-" + step.stepNbr
                                  }`}
                                  isEnabled={
                                    step.conditionLogic &&
                                    step.conditionLogic.status
                                      ? resolver(
                                          step.conditionLogic,
                                          form.values
                                        )
                                      : true
                                  }
                                >
                                  <FormRenderer
                                    key={i}
                                    elements={step.fields}
                                    values={form.values}
                                    form={form}
                                    isPreview={true}
                                    isEpro={true}
                                    formDataId={
                                      formData?._id || selectedEproForm?._id
                                    }
                                    stepNbr={step.stepNbr}
                                    readOnly={user?.type !== "participant"}
                                  ></FormRenderer>
                                </FormizStep>
                              );
                            })}
                          </div>
                        </MultiStepsLayout>
                      </Formiz>
                    </IntlProvider>
                  )}
                </>
              )}
              <Modal
                closeModal={() => setAddEventModal(false)}
                showModal={addEventModal}
              >
                <EventForm
                  studyId={studyId}
                  participantId={participantId}
                  centerId={studyWriterConfig?.centerId?._id}
                  setSelectedEvent={setSelectedEvent}
                  closeModal={() => {
                    setShouldUpdate((up) => !up);
                    setAddEventModal(false);
                  }}
                />
              </Modal>
            </ChakraProvider>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default MyParticipantForms;
