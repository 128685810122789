export const conditionsListExport = (intl) => {
    return [
        {
            label: intl.formatMessage({
                id: "equal",
            }),
            value: "equal",
        },
        {
            label: intl.formatMessage({
                id: "notEqual",
            }),
            value: "not_equal",
        },
        {
            label: intl.formatMessage({
                id: "equal_or_superior",
            }),
            value: "equal_or_superior",
        },
        {
            label: intl.formatMessage({
                id: "equal_or_inferior",
            }),
            value: "equal_or_inferior",
        },
        {
            label: intl.formatMessage({
                id: "pattern",
            }),
            value: "pattern",
        },
        {
            label: intl.formatMessage({
                id: "isNotPattern",
            }),
            value: "isNotPattern",
        },
    ]
};